var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"example-content"}},[(!_vm.$root.spinner)?_vm._t("default",function(){return [_c('div',{staticClass:"col-xs-12 col-lg-12",attrs:{"data-aos":"fade-right"}},[_c('h5',{staticClass:"page-title font-weight-bold text-uppercase"},[_vm._v("\n        "+_vm._s(_vm.service.title)+"\n      ")]),_vm._v(" "),(_vm.service.banner)?_c('div',{staticClass:"pageBanner rounded-10 shadow-sm bg-light mb-3"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$root.baseurl + '/public' + _vm.service.banner),expression:"$root.baseurl + '/public' + service.banner"}],attrs:{"alt":"..."}})]):_vm._e()]),_vm._v(" "),_vm._t("default",function(){return [_c('div',{staticClass:"col-xs-12 col-lg-12",attrs:{"data-aos":"fade-right"}},[_c('p',{staticClass:"text-justify",domProps:{"innerHTML":_vm._s(_vm.service.description)}}),_vm._v(" "),(_vm.service.is_form == 1 && _vm.$route.params.type == 'member')?_vm._t("default",function(){return [_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.clickToCheckLogin(_vm.service.form_link, _vm.service.id)}}},[_vm._v("\n            Get It Now!\n          ")])]}):_vm._e(),_vm._v(" "),(
            _vm.service.transaction_type == 'Paid' &&
            _vm.$route.params.type == 'non_member' &&
            _vm.$route.params.id != 14 &&
            _vm.$route.params.id != 41 &&
            _vm.$route.params.id != 65
          )?_vm._t("default",function(){return [_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
              name: 'NonMemberPayment',
              params: { id: _vm.$route.params.id },
            }}},[_vm._v("Get It Now!")])]}):_vm._e(),_vm._v(" "),(_vm.service.default_form == 1 && _vm.$route.params.type == 'member')?_vm._t("default",function(){return [_c('button',{staticClass:"btn btn-success mt-5",on:{"click":function($event){return _vm.clickToCheckLogin('service-form', _vm.service.id)}}},[_vm._v("\n            Get It Now!\n          ")])]}):_vm._e(),_vm._v(" "),(
            _vm.service.login_api == 1 &&
            _vm.service.api_login_url != null &&
            _vm.$route.params.type == 'member'
          )?_vm._t("default",function(){return [_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.clickToApiLoginCheckLogin(_vm.service.api_login_url)}}},[_vm._v("\n            Get It Now!\n          ")])]}):_vm._e()],2)]})]}):_vm._e(),_vm._v(" "),(_vm.$root.spinner)?_c('spinner'):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }