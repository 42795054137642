<template>
	<div
		class="member-services"
		v-if="
			(!$parent.loading && $root.access && $root.access['representative']) ||
			$root.variable.rep_login ||
			$root.variable.aLogId
		"
	>
		<div class="card shadow-sm mb-3">
			<div class="card-header px-0">
				<div class="d-flex align-items-center justify-content-between">
					<h5 class="text-uppercase font-weight-bold px-3 mb-0">
						Representative Information
					</h5>
				</div>
			</div>
			<div class="card-body">
				<div class="row align-items-center justify-content-end mb-4">
					<div
						v-if="Object.keys(errors).length > 0"
						class="col-lg-12 py-2"
						style="background: #fddede"
					>
						<div class="error">
							<span
								v-for="(err, errIndex) in errors"
								:key="errIndex"
								class="text-danger"
							>
								{{ err[0] }}
								<br />
							</span>
						</div>
					</div>
				</div>
				<form v-on:submit.prevent="submit" id="form">
					<div class="row">
						<div class="col-lg-6 mb-3">
							<fieldset class="h-100 border border-primary p-3">
								<legend class="text-center">
									<small class="font-weight-bold"
										>Primary Representative Information for BASIS</small
									>
								</legend>
								<!-- <div class="form-row mb-3">
                    <label for="comments">
                      Is he/she is a shareholder/owner of the company
                      <span class="text-danger">*</span>
                    </label>
                    <select
                      name="primary[owner]"
                      readonly
                      v-model="data.primary.owner"
                      class="form-control"
                    >
                      <option value>Select</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
								</div>-->
								<div class="form-row mb-3">
									<label for="comments">Name</label>
									<span class="text-danger requierd-star"></span>
									<input
										type="text"
										readonly
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError('data.primary.name'),
											'is-valid': data.primary.name,
										}"
										placeholder="e.g. Jane Doe"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Jane Doe'"
										v-model="data.primary.name"
										name="primary[name]"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.primary.name") }}
									</div>
								</div>
								<div class="form-row mb-3">
									<label for="comments">Designation</label>
									<span class="text-danger requierd-star"></span>
									<input
										type="text"
										readonly
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError(
												'data.primary.designation'
											),
											'is-valid': data.primary.designation,
										}"
										placeholder="e.g. CEO"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. CEO'"
										v-model="data.primary.designation"
										name="primary[designation]"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.primary.designation") }}
									</div>
								</div>
								<div class="form-row mb-3">
									<label for="comments">Email (BASIS Login Email)</label>
									<span class="text-danger requierd-star"></span>
									<input
										type="email"
										readonly
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError('data.primary.email'),
											'is-valid': data.primary.email,
										}"
										placeholder="e.g. demo@mail.com"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. demo@mail.com'"
										v-model="data.primary.email"
										name="primary[email]"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.primary.email") }}
									</div>
								</div>
								<div class="form-row">
									<div class="form-group col-6 mb-3">
										<label for="mobile">
											Mobile
											<small>(Hide from profile)</small>
											<input
												type="checkbox"
												value="1"
												name="primary[hide_mobile]"
												v-model="data.primary.hide_mobile"
											/>
										</label>
										<span class="text-danger requierd-star"></span>
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text" id="direct_phone"
													>+88</span
												>
											</div>
											<input
												type="text"
												readonly
												class="form-control shadow-none"
												:class="{
													'is-invalid': validation.hasError(
														'data.primary.mobile'
													),
													'is-valid': data.primary.mobile,
												}"
												placeholder="e.g. 01xxxxxxxxx"
												onfocus="this.placeholder = ''"
												onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
												v-model="data.primary.mobile"
												id="mobile"
												name="primary[mobile]"
											/>
										</div>
										<div class="invalid-feedback">
											{{ validation.firstError("data.primary.mobile") }}
										</div>
									</div>
									<div class="form-group col-6 mb-3">
										<label for="comments">Direct Phone</label>
										<!-- <span class="text-danger requierd-star"></span> -->
										<input
											type="text"
											class="form-control shadow-none"
											:class="{
												'is-invalid': validation.hasError(
													'data.primary.direct_phone'
												),
												'is-valid': data.primary.direct_phone,
											}"
											placeholder="e.g. 01xxxxxxxxxx"
											onfocus="this.placeholder = ''"
											onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
											v-model="data.primary.direct_phone"
											name="primary[direct_phone]"
										/>
										<div class="invalid-feedback">
											{{ validation.firstError("data.primary.direct_phone") }}
										</div>
									</div>
								</div>
								<div class="form-row">
									<div class="form-group col-6 mb-3">
										<label for="comments">Gender</label>
										<span class="text-danger requierd-star"></span>
										<br />
										<input
											type="radio"
											checked
											value="Male"
											v-model="data.primary.gender"
											:class="{
												'is-invalid': validation.hasError(
													'data.primary.gender'
												),
												'is-valid': data.primary.gender,
											}"
											name="primary[gender]"
										/>
										Male &nbsp;&nbsp;
										<input
											type="radio"
											value="Female"
											:class="{
												'is-invalid': validation.hasError(
													'data.primary.gender'
												),
												'is-valid': data.primary.gender,
											}"
											v-model="data.primary.gender"
											name="primary[gender]"
										/>
										Female
										<div class="invalid-feedback">
											{{ validation.firstError("data.primary.gender") }}
										</div>
									</div>
									<div class="form-group col-6 mb-3">
										<label for="mobile">LinkedIn</label>
										<input
											type="text"
											class="form-control shadow-none"
											:class="{
												'is-invalid': validation.hasError(
													'data.primary.linkedin'
												),
												'is-valid': data.primary.linkedin,
											}"
											placeholder="e.g. http://linkedin.com/yourprofile"
											onfocus="this.placeholder = ''"
											onblur="this.placeholder = 'e.g. http://linkedin.com/yourprofile'"
											v-model="data.primary.linkedin"
											id="linkedin"
											name="primary[linkedin]"
										/>
										<div class="invalid-feedback">
											{{ validation.firstError("data.primary.linkedin") }}
										</div>
									</div>
								</div>
								<div class="form-row">
									<div class="form-group col-6 mb-3">
										<label for="comments">Passport No</label>
										<input
											type="text"
											class="form-control shadow-none"
											:class="{
												'is-invalid': validation.hasError(
													'data.primary.passport'
												),
												'is-valid': data.primary.passport,
											}"
											placeholder="e.g. 01xxxxxxxxx"
											onfocus="this.placeholder = ''"
											onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
											v-model="data.primary.passport"
											name="primary[passport]"
										/>
									</div>
									<div class="form-group col-6 mb-3">
										<label for="comments">Date of Birth</label>
										<input
											type="date"
											class="form-control shadow-none"
											:class="{
												'is-invalid': validation.hasError('data.primary.dob'),
												'is-valid': data.primary.dob,
											}"
											placeholder="e.g. 2022-02-15"
											onfocus="this.placeholder = ''"
											onblur="this.placeholder = 'e.g. 2022-02-15'"
											v-model="data.primary.dob"
											name="primary[dob]"
										/>
									</div>
								</div>
								<div class="form-row">
									<div class="form-group col-6 mb-3">
										<label for="comments">National ID</label>
										<span class="text-danger requierd-star"></span>
										<input
											type="text"
											class="form-control shadow-none"
											:class="{
												'is-invalid': validation.hasError('data.primary.nid'),
												'is-valid': data.primary.nid,
											}"
											placeholder="e.g. 01xxxxxxxxx"
											onfocus="this.placeholder = ''"
											onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
											v-model="data.primary.nid"
											name="primary[nid]"
										/>
										<div class="invalid-feedback">
											{{ validation.firstError("data.primary.nid") }}
										</div>
									</div>
									<div class="form-row col-6">
										<div class="form-group col-3 text-center pt-3">
											<img
												id="displayProfilPhoto"
												v-lazy="
													data.primary.nid_copy || images.nid_copy
														? attachPdf
														: noImage
												"
												height="45px"
												alt="displayProfilPhoto"
											/>
										</div>
										<div class="form-group col-9">
											<label for="comments">NID Copy</label>
											<span class="requierd-star"></span>

											<b-form-file
												v-model="images.nid_copy"
												accept=".pdf"
												:placeholder="
													data.primary.nid_copy
														? 'File attached '
														: 'Choose a pdf file'
												"
												drop-placeholder="Drop file here..."
											></b-form-file>
											<input
												type="hidden"
												:value="data.primary.nid_copy"
												name="old_nid_copy"
											/>
											<small>Smaller than 7mb</small>
											<a
												v-if="data.primary.nid_copy"
												:href="
													$root.baseurl + '/public' + data.primary.nid_copy
												"
												class="float-right"
												target="_blank"
												>View file</a
											>
											<div class="invalid-feedback">
												{{ validation.firstError("images.nid_copy") }}
											</div>
										</div>
									</div>
								</div>
								<div class="form-row" v-if="member.info">
									<slot v-if="member.info.legal_structure == 'Proprietorship'">
										<div class="form-group col-6">
											<label for="comments">Representative TIN Number</label>
											<span class="requierd-star"></span>
											<input
												type="text"
												class="form-control shadow-none"
												:class="{
													'is-invalid': validation.hasError(
														'data.primary.tin_number'
													),
													'is-valid': data.primary.tin_number,
												}"
												placeholder="e.g. 01xxxxxxxxx"
												onfocus="this.placeholder = ''"
												onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
												v-model="data.primary.tin_number"
												name="primary[tin_number]"
											/>
											<div class="invalid-feedback">
												{{ validation.firstError("data.primary.tin_number") }}
											</div>
										</div>
										<div class="form-row col-6">
											<div class="form-group col-3 text-center pt-3">
												<img
													id="displayProfilPhoto"
													v-lazy="
														data.primary.tin_certificate ||
														images.tin_certificate
															? attachPdf
															: noImage
													"
													height="45px"
													alt="displayProfilPhoto"
												/>
											</div>
											<div class="form-group col-9">
												<label for="comments">
													Representative TIN Certificate
													<span class="requierd-star"></span>
												</label>

												<b-form-file
													v-model="images.tin_certificate"
													accept=".pdf"
													:placeholder="
														data.primary.tin_certificate
															? 'File attached '
															: 'Choose a pdf file'
													"
													drop-placeholder="Drop file here..."
												></b-form-file>
												<input
													type="hidden"
													:value="data.primary.tin_certificate"
													name="old_tin_certificate"
												/>
												<small>Smaller than 7mb</small>
												<a
													v-if="data.primary.tin_certificate"
													:href="
														$root.baseurl +
														'/public' +
														data.primary.tin_certificate
													"
													class="float-right"
													target="_blank"
													>View file</a
												>
												<div class="invalid-feedback">
													{{ validation.firstError("images.tin_certificate") }}
												</div>
											</div>
										</div>
									</slot>
								</div>
								<div class="form-row">
									<div class="form-row col-12 col-lg-6 mb-3">
										<div class="form-group col-3 text-center pt-3">
											<img
												id="displayProfilPhoto"
												v-lazy="
													data.primary.cv || images.cv ? attachPdf : noImage
												"
												height="45px"
												alt="displayProfilPhoto"
											/>
										</div>
										<div class="form-group col-9">
											<label for="comments">CV</label>
											<b-form-file
												v-if="data.primary"
												v-model="images.cv"
												accept=".pdf"
												:placeholder="
													data.primary.cv
														? 'File attached '
														: 'Choose a pdf file'
												"
												drop-placeholder="Drop file here..."
												:class="{
													'is-invalid': validation.hasError('images.cv'),
													'is-valid': images.cv,
												}"
											></b-form-file>
											<div class="invalid-feedback">
												{{ validation.firstError("images.cv") }}
											</div>
											<input
												type="hidden"
												:value="data.primary.cv"
												name="old_cv"
											/>
											<small>File size must be smaller than 7mb</small>
											<a
												v-if="data.primary.cv"
												:href="$root.baseurl + '/public' + data.primary.cv"
												class="float-right"
												target="_blank"
												>View file</a
											>
										</div>
									</div>
									<div class="form-row col-12 col-lg-6 mb-3" v-if="member.info">
										<slot
											v-if="member.info.legal_structure != 'Proprietorship'"
										>
											<div class="form-group col-3 text-center pt-3">
												<img
													id="displayProfilPhoto"
													v-lazy="
														data.primary.board_resolution ||
														images.board_resolution
															? attachPdf
															: noImage
													"
													height="45px"
													alt="displayProfilPhoto"
												/>
											</div>
											<div class="form-group col-9">
												<label for="comments">Board Resolution</label>
												<span class="text-danger requierd-star"></span>
												<b-form-file
													v-if="data.primary"
													v-model="images.board_resolution"
													accept=".pdf"
													:placeholder="
														data.primary.board_resolution
															? 'File attached '
															: 'Choose a pdf file'
													"
													drop-placeholder="Drop file here..."
													:class="{
														'is-invalid': validation.hasError(
															'images.board_resolution'
														),
														'is-valid': images.board_resolution,
													}"
												></b-form-file>
												<div class="invalid-feedback">
													{{ validation.firstError("images.board_resolution") }}
												</div>
												<input
													type="hidden"
													:value="data.primary.board_resolution"
													name="old_board_resolution"
												/>
												<small>File size must be smaller than 7mb</small>
												<a
													v-if="data.primary && data.primary.board_resolution"
													:href="
														$root.baseurl +
														'/public' +
														data.primary.board_resolution
													"
													class="float-right"
													target="_blank"
													>View file</a
												>
											</div>
										</slot>
									</div>
								</div>
								<div class="form-row mb-3">
									<div class="form-group col-2 text-center">
										<img
											v-lazy="
												images.image || data.primary.image
													? image_src
													: noAvatar
											"
											class="img-responsive"
											height="60"
											width="100%"
											alt="noAvatar"
										/>
									</div>
									<div class="form-group col-10">
										<label for="comments">Picture</label>
										<span class="text-danger requierd-star"></span>
										<b-form-file
											v-model="images.image"
											size="sm"
											placeholder="e.g. picture.jpg"
											:class="{
												'is-invalid': validation.hasError('images.image'),
												'is-valid': images.image,
											}"
											drop-placeholder="Drop file here..."
										></b-form-file>
										<a
											v-if="data.primary && data.primary.image"
											:href="$root.baseurl + '/public' + data.primary.image"
											class="float-right"
											target="_blank"
											>View Image</a
										>
										<input
											type="hidden"
											:value="data.primary.image"
											name="old_image"
										/>
										<div class="invalid-feedback">
											{{ validation.firstError("images.image") }}
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<!--========== right side ==========-->
						<!--========== right side ==========-->
						<div class="col-lg-6 mb-3">
							<fieldset class="h-100 border border-primary p-3">
								<legend class="text-center">
									<small class="font-weight-bold"
										>Alternative contact for general communications</small
									>
								</legend>
								<!-- <div class="form-row mb-3">
                    <label for="comments">Is he/she is a shareholder/owner of the company</label>
                    <select
                      name="secondary[owner]"
                      readonly
                      v-model="data.secondary.owner"
                      class="form-control"
                    >
                      <option value>Select</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
								</div>-->
								<div class="form-row mb-3">
									<label for="comments">Name</label>
									<input
										type="text"
										class="form-control shadow-none"
										placeholder="e.g. Jane Doe"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Jane Doe'"
										:class="{
											'is-invalid': validation.hasError('data.secondary.name'),
											'is-valid': data.secondary.name,
										}"
										v-model="data.secondary.name"
										name="secondary[name]"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.secondary.name") }}
									</div>
								</div>
								<div class="form-row mb-3">
									<label for="comments">Designation</label>
									<input
										type="text"
										class="form-control shadow-none"
										placeholder="e.g. CEO"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. CEO'"
										:class="{
											'is-invalid': validation.hasError(
												'data.secondary.designation'
											),
											'is-valid': data.secondary.designation,
										}"
										v-model="data.secondary.designation"
										name="secondary[designation]"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.secondary.designation") }}
									</div>
								</div>
								<div class="form-row mb-3">
									<label for="comments">Mobile</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text" id="direct_phone"
												>+88</span
											>
										</div>
										<input
											type="text"
											class="form-control shadow-none"
											placeholder="e.g. 01xxxxxxxx"
											onfocus="this.placeholder = ''"
											onblur="this.placeholder = 'e.g. 01xxxxxxxx'"
											:class="{
												'is-invalid': validation.hasError(
													'data.secondary.mobile'
												),
												'is-valid': data.secondary.mobile,
											}"
											v-model="data.secondary.mobile"
											name="secondary[mobile]"
										/>
									</div>
									<div class="invalid-feedback">
										{{ validation.firstError("data.secondary.mobile") }}
									</div>
								</div>
								<div class="form-row mb-3">
									<label for="comments">Email</label>
									<input
										type="email"
										class="form-control shadow-none"
										placeholder="e.g. demo@mail.com"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. demo@mail.com'"
										:class="{
											'is-invalid': validation.hasError('data.secondary.email'),
											'is-valid': data.secondary.email,
										}"
										v-model="data.secondary.email"
										name="secondary[email]"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.secondary.email") }}
									</div>
								</div>

								<!-- Access Privileged -->
								<div class="w-100 my-2 border-top borrder-primary"></div>
								<h5>Access Privileged</h5>
								<div
									class="col-md-12"
									v-for="(name, key) in $root.variable.alternate_access"
									:key="key"
								>
									<div class="custom-control custom-checkbox">
										<b-form-checkbox
											:name="'secondary[access][' + key + ']'"
											class="col-form-label-sm font-weight-bold mb-0"
											:value="key"
											v-model="data.secondary.access[key]"
											:unchecked-value="false"
											>{{ name }}</b-form-checkbox
										>
									</div>
								</div>
							</fieldset>
						</div>
						<input
							type="hidden"
							name="logged_id"
							v-model="$root.variable.aLogId"
						/>
					</div>

					<div class="row justify-content-center">
						<button class="btn btn-success" type="submit">
							Update Representative Information
						</button>
					</div>
				</form>
			</div>
		</div>
		<!-- <pre>{{ data }}</pre> -->
	</div>
	<div v-else class="row">
		<div class="col-12">
			<div class="card">
				<div v-if="Object.keys($root.access).length > 0" class="card-body">
					<h4 class="text-center text-danger">
						Sorry!! you cannot access this page
					</h4>
				</div>
				<div v-else class="card-body">
					<div
						v-if="!$parent.loading"
						class="
							card-body
							d-flex
							align-items-center
							justify-content-center
							flex-column
						"
					>
						<i
							class="fa fa-spinner fa-spin"
							style="font-size: 24px; color: rgb(0, 127, 62)"
						></i>
						<span class="processing">processing</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import noImage from "../../../../assets/img/no_logo.gif";
	import noAvatar from "../../../../assets/img/male_avatar.png";
	import imagePdfAttach from "../../../../assets/img/PdfAttached.png";
	import Promise from "bluebird";
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	const breadcumb = [
		{
			title: "Representative Information",
		},
	];
	export default {
		name: "RepresentativeForm",
		props: ["member.info"],
		data() {
			return {
				breadcumb: breadcumb,
				breadcrumbTitle: "Update Representative Information",
				noAvatar: this.$root.baseurl + noAvatar,
				noImage: this.$root.baseurl + noImage,
				attachPdf: this.$root.baseurl + imagePdfAttach,
				image_src: null,
				data: {
					primary: { owner: "" },
					secondary: { owner: "", access: {} },
				},
				images: {
					image: null,
					nid_copy: null,
					tin_certificate: null,
					cv: null,
					board_resolution: null,
				},
				member: {
					info: {},
				},
				errors: {},
			};
		},
		watch: {
			"images.image": function (img, oldImg) {
				this.showImage(img);
			},
		},
		methods: {
			submit: function (e) {
				e.preventDefault();
				const config = {
					headers: { "content-type": "multipart/form-data" },
				};
				var form = document.getElementById("form");
				var formData = new FormData(form);

				if (confirm("Are you confirming, is the provided information valid?")) {
					if (this.images.image) {
						formData.append("image", this.images.image);
					} else {
						this.images.image = "";
					}
					if (this.images.tin_certificate) {
						formData.append("tin_certificate", this.images.tin_certificate);
					} else {
						this.images.tin_certificate = "";
					}
					if (this.images.cv) {
						formData.append("cv", this.images.cv);
					} else {
						this.images.cv = "";
					}
					if (this.images.nid_copy) {
						formData.append("nid_copy", this.images.nid_copy);
					} else {
						this.images.nid_copy = "";
					}
					if (this.images.board_resolution) {
						formData.append("board_resolution", this.images.board_resolution);
					} else {
						this.images.board_resolution = "";
					}
					this.$validate().then((res) => {
						if (this.validation.countErrors() > 0) {
							this.$toastr(
								"warning",
								'You need to fill <span class="font-weight-bold">' +
									this.validation.countErrors() +
									" more empty</span> mandatory fields",
								"Empty Mandatory Fields!!"
							);
							console.log(this.validation.allErrors());
						} else {
							if (res) {
								this.scrollTop();
								this.$parent.loading = true;
								axios
									.post("member/RepresentativeInfo", formData, config)
									.then((response) => {
										this.$toastr("success", "Update Successfully", "Success!!");
										location.reload();
									})
									.catch((error) => {
										if (error.response.status === 422) {
											this.errors = error.response.data.errors || {};
											this.focus_field = Object.keys(this.errors)[0];
											this.$parent.loading = false;
										}
									})
									.then((res) => {
										setTimeout(
											() => (this.$parent.loading = false),
											this.$root.minloadtimefd
										);
									});
							}
						}
					});
				}
			},
			remove_readonly() {
				Promise.delay(500).then(function () {
					$("input").each(function () {
						$(this).removeAttr("readonly");
						$(this).removeAttr("disabled");
					});
					$("input").each(function () {
						$(this).removeAttr("readonly");
						$(this).removeAttr("disabled");
					});
				});
			},
			remove_validation() {
				$("input").each(function () {
					$(this).removeClass("is_valid");
				});
			},
			getData() {
				// primary
				axios
					.get("member/get-represent-info/primary")
					.then((response) => {
						this.data.primary = response.data;
						this.image_src = this.$root.baseurl + "/public" + response.data.image;
					})
					.catch((error) => {})
					.then((res) => {
						setTimeout(() => (this.$parent.loading = false), 500);
					});
				// secondary
				axios
					.get("member/get-represent-info/secondary")
					.then((response) => {
						if (Object.keys(response.data).length > 0) {
							this.data.secondary = response.data;
							if (!response.data.access) {
								this.data.secondary.access = {};
							}
						}
					})
					.catch((error) => {})
					.then((res) => {
						setTimeout(() => (this.$parent.loading = false), 500);
					});
			},
		},
		mounted() {
			this.loggedIn();
			this.getData();
			if (this.$root.variable.aLogId) {
				this.remove_readonly();
				this.remove_validation();
			}
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
		updated() {
			if (this.$root.variable.aLogId) {
				this.remove_readonly();
			}
		},
		validators: {
			// "member.info.company_head_phone": function(value = null) {
			//   return Validator.value(value)
			//     .digit()
			//     .regex("01+[0-9]*$", "Must start with 01.")
			//     .minLength(11)
			//     .maxLength(11);
			// },
			// "data.primary.name": function(value = null) {
			//   return Validator.value(value).required("Name is Required");
			// },
			"data.primary.gender": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value).required("Gender is required");
				}
			},
			// "data.primary.mobile": function(value = null) {
			//   return Validator.value(value)
			//     .required("Mobile Number is Required")
			//     .regex("01+[0-9]*$", "Must start with 01.");
			// },
			// "data.primary.email": function(value = null) {
			//   return Validator.value(value)
			//     .required("Email is Required")
			//     .email("Email must be well formatted");
			// },
			// "member.info.phone": function(value = null) {
			//   return Validator.value(value)
			//     .required("Phone Number is Required")
			//     .digit("Phone Number must be digit");
			// },
			// "member.info.website": function(value = null) {
			//   return Validator.value(value)
			//     .required("Website is Required")
			//     .url("Website URL must be well Formatted");
			// },
			// "member.info.other_website": function(value = null) {
			//   return Validator.value(value).url("Website URL must be well Formatted");
			// },
			// "data.primary.designation": function(value = null) {
			//   return Validator.value(value).required("Designation is required");
			// },
			// "data.secondary.name": function(value = null) {
			//   return Validator.value(value).required("Name is required");
			// },
			// "data.secondary.designation": function(value = null) {
			//   return Validator.value(value).required("Designation is required");
			// },
			"data.secondary.mobile": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value)
						.digit()
						.regex("01+[0-9]*$", "Must start with 01.")
						.minLength(11)
						.maxLength(11);
				}
			},
			// "data.secondary.email": function(value = null) {
			//   return Validator.value(value).required("Email is required");
			// },
			"data.primary.linkedin": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value).url();
				} else {
					return Validator.value(value);
				}
			},
			"data.primary.direct_phone": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value).digit();
				} else {
					return Validator.value(value);
				}
			},
			"images.image": function (value = null) {
				if (!this.$root.variable.aLogId) {
					if (this.data.primary && !this.data.primary.image) {
						return Validator.value(value).required("Image is required");
					}
				}
			},
			"images.board_resolution": function (value = null) {
				if (!this.$root.variable.aLogId) {
					if (this.member.info) {
						if (this.member.info.legal_structure != "Proprietorship") {
							if (!this.data.primary.board_resolution) {
								return Validator.value(value)
									.required("Board Resolution is required")
									.custom(function () {
										if (!Validator.isEmpty(value)) {
											var type = value.type;
											if (type == "application/pdf") {
											} else {
												return "PDF format required";
											}
										}
									})
									.custom(function () {
										if (!Validator.isEmpty(value)) {
											var size = value.size;
											if (size > 7168000) {
												return "Pdf must be smaller than 7mb";
											}
										}
									});
							} else {
								return Validator.value(value)
									.custom(function () {
										if (!Validator.isEmpty(value)) {
											var type = value.type;
											if (type == "application/pdf") {
											} else {
												return "PDF format required";
											}
										}
									})
									.custom(function () {
										if (!Validator.isEmpty(value)) {
											var size = value.size;
											if (size > 7168000) {
												return "Pdf must be smaller than 7mb";
											}
										}
									});
							}
						}
					}
				}
			},
			// "images.cv": function(value = null) {
			//   return Validator.value(value).required("CV is required");
			// }
			"data.primary.dob": function (value = null) {
				return Validator.value(value).required("Date of Birth is required");
			},
			"data.primary.nid": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value)
						.required("National ID is required")
						.digit();
				}
			},
			"data.primary.tin_number": function (value = null) {
				if (!this.$root.variable.aLogId) {
					if (this.member.info) {
						if (this.member.info.legal_structure == "Proprietorship") {
							return Validator.value(value).required("TIN number is required");
						}
					}
				}
			},
			"images.tin_certificate": function (value = null) {
				if (!this.$root.variable.aLogId) {
					if (this.member.info) {
						if (this.member.info.legal_structure == "Proprietorship") {
							if (!this.data.primary.tin_certificate) {
								return Validator.value(value)
									.required("TIN Certificate is required")
									.custom(function () {
										if (!Validator.isEmpty(value)) {
											var type = value.type;
											if (type == "application/pdf") {
											} else {
												return "PDF format required";
											}
										}
									})
									.custom(function () {
										if (!Validator.isEmpty(value)) {
											var size = value.size;
											if (size > 7168000) {
												return "Pdf must be smaller than 7mb";
											}
										}
									});
							} else {
								return Validator.value(value)
									.custom(function () {
										if (!Validator.isEmpty(value)) {
											var type = value.type;
											if (type == "application/pdf") {
											} else {
												return "PDF format required";
											}
										}
									})
									.custom(function () {
										if (!Validator.isEmpty(value)) {
											var size = value.size;
											if (size > 7168000) {
												return "Pdf must be smaller than 7mb";
											}
										}
									});
							}
						}
					}
				}
			},
			"images.nid_copy": function (value = null) {
				if (!this.$root.variable.aLogId) {
					if (this.member.info) {
						if (!this.data.primary.nid_copy) {
							return Validator.value(value)
								.required("Nid Copy is required")
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 7168000) {
											return "Pdf must be smaller than 7mb";
										}
									}
								});
						} else {
							return Validator.value(value)
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 7168000) {
											return "Pdf must be smaller than 7mb";
										}
									}
								});
						}
					}
				}
			},
		},
	};
</script>

