<template>
  <div
    v-if="!$parent.loading && member.info && member.info.status == 1"
    class="row"
  >
    <div class="col-lg-12">
      <div class="card shadow-sm">
        <div class="card-header bg-transparent">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">
            Privilege Card
          </h5>
        </div>
        <div class="card-body">
          <div class="row align-items-center justify-content-end mb-4">
            <div
              v-if="Object.keys(errors).length > 0"
              class="col-lg-12 py-2"
              style="background: #fddede"
            >
              <div class="error">
                <span
                  v-for="(err, errIndex) in errors"
                  :key="errIndex"
                  class="text-danger"
                >
                  {{ err[0] }}
                  <br />
                </span>
              </div>
            </div>
          </div>

          <form v-on:submit.prevent="submit" id="form" class="form-row">
            <div class="form-group col-lg-6">
              <label class="control-label">Representative name</label>
              <input
                type="text"
                readonly
                class="form-control shadow-none"
                :class="{
                  'is-invalid': validation.hasError('primary.name'),
                  'is-valid': primary.name,
                }"
                placeholder="e.g. Jane Doe"
                onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'e.g. Jane Doe'"
                v-model="primary.name"
                name="name_representative"
              />
              <div class="invalid-feedback">
                {{ validation.firstError("primary.name") }}
              </div>
            </div>
            <div class="form-group col-lg-6">
              <label class="control-label">Representative Designation</label>
              <input
                type="text"
                readonly
                class="form-control shadow-none"
                :class="{
                  'is-invalid': validation.hasError('primary.designation'),
                  'is-valid': primary.designation,
                }"
                placeholder="e.g. CEO"
                onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'e.g. CEO'"
                v-model="primary.designation"
                name="designation_representative"
              />
              <div class="invalid-feedback">
                {{ validation.firstError("primary.designation") }}
              </div>
            </div>
            <div class="form-group col-lg-4 mb-3">
              <label for="comments">
                Representative Photo
                <span class="text-danger requierd-star"></span>
              </label>
              <b-form-file
                v-model="images.image"
                size="sm"
                accept=".png, .jpg, .jpeg"
                placeholder="e.g. picture.jpg"
                onfocus="e.g. picture.jpg"
                onblur="e.g. picture.jpg"
                :class="{
                  'is-invalid': validation.hasError('images.image'),
                  'is-valid': images.image,
                }"
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <div class="invalid-feedback">
                {{ validation.firstError("images.image") }}
              </div>
            </div>
            <div class="form-group col-lg-4 mb-3">
              <label for="comments">
                Representative signature
                <span class="text-danger requierd-star"></span>
              </label>
              <b-form-file
                v-model="images.sign"
                size="sm"
                accept=".png, .jpg, .jpeg"
                placeholder="e.g. picture.jpg"
                onfocus="e.g. picture.jpg"
                onblur="e.g. picture.jpg"
                :class="{
                  'is-invalid': validation.hasError('images.sign'),
                  'is-valid': images.sign,
                }"
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <div class="invalid-feedback">
                {{ validation.firstError("images.sign") }}
              </div>
            </div>
            <div class="form-group col-lg-4">
              <label class="control-label">Company logo (Ai / Psd file)</label>
              <div class="custom-file b-form-file b-custom-control-sm" id>
                <b-form-file
                  v-model="images.ai"
                  size="sm"
                  accept=".ai, .psd"
                  placeholder="e.g. ai / psd file"
                  :class="{
                    'is-invalid': validation.hasError('images.ai'),
                    'is-valid': images.ai,
                  }"
                  drop-placeholder="Drop file here..."
                ></b-form-file>
                <div class="invalid-feedback">
                  {{ validation.firstError("images.ai") }}
                </div>
              </div>
            </div>
            <div class="form-group col-lg-12 text-center">
              <button name type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <pre>{{primary}}</pre> -->
  </div>
  <div v-else-if="member.info && member.info.status == 0" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! your account is suspend
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noImage from "../../../../../assets/img/male_avatar.png";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);

export default {
  name: "PrivilegeCardForm",
  data() {
    return {
      title: "Account Of Company",
      noImage: this.$root.baseurl + noImage,
      image_src: null,
      sign_src: null,
      primary: {
        service_id: null,
        name: null,
        designaton: null,
      },
      images: { image: null, sign: null, ai: null },
      member: {
        info: null,
      },
      errors: {},
    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      var form = document.getElementById("form");
      var formData = new FormData(form);
      this.$validate().then((res) => {
        if (this.validation.countErrors() > 0) {
          this.$toastr(
            "warning",
            'You need to fill <span class="font-weight-bold">' +
              this.validation.countErrors() +
              " more empty</span> mandatory fields",
            "Empty Mandatory Fields!!"
          );
        } else {
          if (res) {
            this.$parent.loading = true;
            if (this.images.image) {
              formData.append("image_representative", this.images.image);
            }
            if (this.images.sign) {
              formData.append("sign_representative", this.images.sign);
            }
            if (this.images.ai) {
              formData.append("company_logo_ai", this.images.ai);
            }
            formData.append("form_type", this.$route.name);
            formData.append("service_id", this.$route.params.id);

            axios
              .post("/member/store-company-info", formData, config)
              .then((respons) => {
                this.$toastr(
                  "success",
                  "Service Added Successfull",
                  "Success!!"
                );
                this.$router.push({ name: "myService" });
              })
              .catch((error) => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                }
              })
              .then((alw) => {
                this.$parent.loading = false;
              });
          }
        }
      });
    },
  },
  created() {
    this.loggedIn();
    setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);
    // primary
    axios.get("member/get-represent-info/primary").then((response) => {
      this.primary.name = response.data.name;
      this.primary.designation = response.data.designation;
    });
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  validators: {
    "images.image": function (value = null) {
      return Validator.value(value).required("Image is required");
    },
    "images.sign": function (value = null) {
      return Validator.value(value).required(
        "Representative signature is required"
      );
    },
    // "images.ai": function(value = null) {
    //   return Validator.value(value).custom(function() {
    //     if (!Validator.isEmpty(value)) {
    //       var type = value.type;
    //       if (type == "application/postscript" || type == "") {
    //       } else {
    //         return "PDF format required";
    //       }
    //     }
    //   });
    // }
  },
};
</script>

