<template>
  <div class="member-services" v-if="!$root.spinner">
    <div class="row mb-3">
      <div class="col-lg-12">
        <div class="card payment-info shadow-sm mb-3">
          <div class="card-header px-0">
            <h5 class="text-uppercase font-weight-bold px-3 mb-0">Notice</h5>
          </div>
          <div class="card-body" v-if="notice">
            <h3>{{ notice.title }}</h3>
            <p
              v-if="notice.description && notice.description != null && notice.description != 'null'"
              v-html="notice.description"
            ></p>
            <a
              :href="$root.baseurl+'/public'+notice.file"
              target="_blank"
              v-if="notice.file"
              class="btn btn-primary btn-sm mt-5"
            >VIEW FILE</a>
            <a
              :href="$root.baseurl+'/public'+notice.file"
              v-if="notice.file"
              class="btn btn-primary btn-sm mt-5"
              download
            >DOWNLOAD FILE</a>
            <img class="mt-3" v-if="notice.image" :src="$root.baseurl+'/public'+notice.image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "viewNotification",
  props: ["memberInfo"],
  data() {
    return {
      data: {},
      member: {
        info: null
      },
      notice: null,
      errors: {}
    };
  },
  methods: {
    getNotices: function(id) {
      this.$root.spinner = true;
      axios
        .get("/member/get-notification/" + id)
        .then(response => {
          this.notice = response.data;
        })
        .catch(error => {})
        .then(alw => {
          this.$root.spinner = false;
        });
    }
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  mounted() {
    if (this.$route.params.nID) {
      this.getNotices(this.$route.params.nID);
    }
    setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefd);
  }
};
</script>
