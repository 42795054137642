<template>
	<div class="customer-profile">
		<h3 class="my-4">Profile</h3>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label for class="col-form-label-sm m-0">Name</label>
					<div class="loginBox">
						<input
							type="text"
							autocomplete="off"
							name="name"
							v-model="data.name"
							class="form-control form-control-sm"
						/>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label for class="col-form-label-sm m-0">Email</label>
					<div class="loginBox">
						<input
							disabled
							type="email"
							autocomplete="off"
							:value="$root.loggedCustomer.email"
							class="form-control form-control-sm"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label for class="col-form-label-sm m-0">Mobile</label>
					<div class="loginBox">
						<input
							type="text"
							autocomplete="off"
							name="mobile"
							v-model="data.mobile"
							class="form-control form-control-sm"
						/>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label for class="col-form-label-sm m-0">Gender</label>
					<select class="form-control form-control-sm" v-model="data.gender">
						<option disabled value>Please select</option>
						<option value="Male">Male</option>
						<option value="Female">Female</option>
					</select>
				</div>
			</div>
			<button v-on:click="submit()" class="btn btn-primary mt-4 ml-3">Save Change</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Profile",
		data() {
			return {
				data: {
					name: this.$root.loggedCustomer.name,
					email: this.$root.loggedCustomer.email,
					mobile: this.$root.loggedCustomer.mobile,
					gender: this.$root.loggedCustomer.gender
						? this.$root.loggedCustomer.gender
						: "Male"
				}
			};
		},
		methods: {
			submit: function(e) {
				this.$root.spinner = true;
				axios
					.post(
						"ecommerce/profile-update/" + this.$root.loggedCustomer.id,
						this.data
					)
					.then(respons => {
						if (respons.data.code == 200) {
							this.$toastr("success", respons.data.msg, "Success!!");
							this.$root.getEcomCustomer();
							this.$parent.name_length = this.data.name.length;
							this.$router.push({ name: "ecommerce.dashboard" });
						}
					})
					.catch(error => {})
					.then(alw => (this.$root.spinner = false));
			}
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		}
	};
</script>

<style scoped>
</style>
