<template>
	<div class="member-invoice" v-if="!$root.spinner">
		<div class="col-12 form-row m-4 justify-content-center">
			<div class="col-6 col-lg-3 text-lg-center">
				<button @click="printInvoice()" class="btn btn-info">
					<i class="fa fa-download"></i> Download
				</button>
			</div>
			<div class="col-6 col-lg-3 text-center">
				<!-- <button
                  class="btn btn-success"
                  type="button"
                  @click="sslPayment(invoice.id)"
                >
                  Pay Now
				</button>-->

				<router-link
					class="btn btn-success"
					:to="{
						name: 'ecommerce.paymentmethod',
						params: { id: $route.params.id },
					}"
					>Check Out</router-link
				>
			</div>
		</div>
		<!-- Invoice -->
		<div class="invoice-frontend border border-dark" v-if="invoice">
			<img
				v-lazy="$root.baseurl + '/public/images/BASIS_Letterhead.jpg'"
				width="100%"
				height="100%"
				alt="BASIS_Letterhead"
			/>
			<div
				class="invoice-content"
				style="
					position: absolute;
					top: 0;
					left: 0;
					padding: 100px 25px;
					width: 100%;
				"
			>
				<table
					v-if="memberInfo"
					class="invoice-frontend-table"
					width="100%"
					border="0"
					cellspacing="0"
					cellpadding="0"
				>
					<tr>
						<td>
							<p>
								<span v-if="invoice"
									>Date: {{ invoice.date | formatDate("MMMM, DD YYYY") }}</span
								>
							</p>
						</td>
						<td class="text-lg-right">
							<span style="margin-right: 7px" v-if="invoice"
								>Invoice No:{{ invoice.invoice_no }}</span
							>
							<br />
							<img :src="barcode" alt style="width: 237px; height: 30px" />
						</td>
					</tr>
					<tr v-if="memberInfo">
						<td style="vertical-align: top; width: 50%">
							<b class="mb-1">Invoice to:</b>
							<p class="mb-1">{{ invoice.order_shipping_info.name }}</p>
							<p class="mb-1">
								Mobile No: {{ invoice.order_shipping_info.phone }}
							</p>
							<p class="mb-1">Order ID: {{ invoice.order_no }}</p>
						</td>
						<td class="custom-style">
							<b>Delivery Address:</b>
							<p>
								<span v-if="!invoice.collect_from_basis"
									>{{ invoice.order_shipping_info.district_info.name }},</span
								>
								<span v-if="!invoice.collect_from_basis"
									>{{ invoice.order_shipping_info.upazila_info.name }},</span
								>
								{{ invoice.order_shipping_info.shipping_address }}
							</p>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<table width="100%">
								<tr>
									<td>
										<h3
											style="
												border: 1px solid;
												width: 30%;
												text-align: center;
												border-radius: 4px;
												padding: 4px 0px;
												margin: 10px auto;
											"
										>
											Invoice
										</h3>
									</td>
								</tr>
								<tr>
									<td>
										<table
											class="table"
											width="100%"
											border="1"
											cellpadding="0"
											cellspacing="0"
										>
											<thead>
												<tr>
													<th class="text-center">SL No.</th>
													<th class="text-center">Item</th>

													<th class="text-center">Amount (Tk)</th>
													<th class="text-center">Discount (Tk)</th>
													<th class="text-center">Qty</th>
													<th class="text-center">Total</th>
												</tr>
											</thead>
											<tbody v-if="invoice">
												<tr
													v-for="(order, index) in invoice.details"
													:key="index"
												>
													<td class="text-center">{{ index + 1 }}</td>
													<td class="text-center">
														{{ order.product.product_name }}
														<br />
														<span v-if="order.color">{{ order.color }},</span>
														<span v-if="order.size">{{ order.size }}</span>
													</td>

													<td class="text-center">
														{{ order.amount | currency }}
													</td>
													<td class="text-center">
														{{ order.discount_amount | currency }}
													</td>
													<td class="text-center">{{ order.qty }}</td>
													<td class="text-center">
														{{
															(order.total - order.discount_total) | currency
														}}
													</td>
												</tr>

												<tr v-if="invoice.coupon_amount > 0">
													<td class="text-right" colspan="5">
														<b>Coupon=</b>
													</td>
													<td class="text-center">
														<b>{{ invoice.coupon_amount | currency }}</b>
													</td>
												</tr>
												<tr v-if="invoice.shipping_amount > 0">
													<td class="text-right" colspan="5">
														<b>Delivery Charge=</b>
													</td>
													<td class="text-center">
														<b>{{ invoice.shipping_amount | currency }}</b>
													</td>
												</tr>
												<tr>
													<td class="text-right" colspan="5">
														<b>Total=</b>
													</td>
													<td class="text-center">
														<b>{{ invoice.total_amount | currency }}</b>
													</td>
												</tr>
											</tbody>
										</table>
										Inword:
										<b>
											<span style="text-transform: capitalize"
												>{{
													Math.round(invoice.total_amount) | inWords
												}}
												Only</span
											>
										</b>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<br />
							<p class="text-justify mb-3">
								<b>Payment Method:</b>

								Please make payment using the online payment gateway of BASIS
								and Collect your electronically generated money receipt from My
								Orders list.
							</p>
						</td>
					</tr>
				</table>
				<br />
				<br />

				<p
					style="
						font-style: italic;
						text-align: center;
						position: absolute;
						bottom: 45px;
					"
				>
					"This is an electronically generated letter or invoice, no signature
					is required"
				</p>
			</div>
		</div>
		<div class="col-12 form-row m-4 justify-content-center">
			<div class="col-6 col-lg-3 text-lg-center">
				<button @click="printInvoice()" class="btn btn-info">
					<i class="fa fa-download"></i> Download
				</button>
			</div>
			<div class="col-6 col-lg-3 text-center">
				<router-link
					class="btn btn-success"
					:to="{
						name: 'ecommerce.paymentmethod',
						params: { id: $route.params.id },
					}"
					>Check Out</router-link
				>
			</div>
		</div>
	</div>
</template>
<script>
	import sign from "../../../../assets/img/sign/HashimAhmed.png";

	export default {
		data() {
			return {
				sign: this.$root.baseurl + sign,
				invoiceNo: null,
				barcode: null,
				invoice: {
					order_shipping_info: {
						name: "",
						phone: "",
						shipping_address: "",
						division_info: {
							name: "",
						},
						district_info: {
							name: "",
						},
						upazila_info: {
							name: "",
						},
					},
				},
				memberInfo: {},
				total_amount: null,
			};
		},
		methods: {
			sslPayment: function (invoice_id) {
				return false;
				this.$root.spinner = true;
				this.$eventBus.$emit("ecomloading", true);
				axios
					.post("/ssl-payment", {
						type: "member",
						id: this.memberInfo.id,
						invoice_id: invoice_id,
					})
					.then((response) => {
						setTimeout(() => {
							this.$eventBus.$emit("ecomloading", false);
						}, 1000);
						if (response.data.GatewayPageURL) {
							window.location.href = response.data.GatewayPageURL;
						}
					});
			},
			printInvoice() {
				this.$root.spinner = true;
				let url =
					this.$root.baseurl +
					"/ecommerce/generate-ecommerce-invoice/" +
					this.$route.params.id +
					"?type=download";
				window.location.href = url;
				this.$eventBus.$emit("ecomloading", true);
				setTimeout(() => {
					this.$root.spinner = false;
					this.$eventBus.$emit("ecomloading", false);
				}, 2000);
			},
			getInvoice: function () {
				this.$root.spinner = true;
				axios
					.get("/ecommerce/get-order-invoice/" + this.$route.params.id)
					.then((response) => {
						if (Object.keys(response.data).length > 0) {
							this.invoice = response.data.invoice;
							this.barcode = response.data.barcode;
							this.memberInfo = response.data.customer;
						}
					})
					.catch((error) => {})
					.then((always) => {
						this.$root.spinner = false;
					});
			},
		},

		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},

		mounted() {
			this.$eventBus.$emit("ecomloading", true);
			this.getInvoice();
			setTimeout(() => {
				this.$eventBus.$emit("ecomloading", false);
			}, 1000);
		},
	};
</script>
<style>
	.invoice {
		/* height: 200px; */
		width: 680px;
		border: 1px solid #000000;
		margin: auto;
		padding: 20px;
	}
	.invoice-content p {
		font-size: 13px;
		color: #101010;
		font-weight: 500;
	}
	.invoice .invoice-logo {
		width: 100%;
		/* border: 1px solid red; */
	}

	.invoice .invoice-logo img {
		/* height: 100px; */
		width: 100px;
	}

	.invoice .invoice-sec-1 {
		width: 100%;
		/* border: 1px solid red; */
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 60px;
		margin-bottom: 20px;
	}

	.invoice .invoice-sec-1 .invoice-sec-1-ref {
		/* border: 1px solid red; */
		width: 70%;
	}

	.invoice .invoice-sec-1 .invoice-sec-1-date {
		/* border: 1px solid red; */
		width: 30%;
		/* height: 100%;
																																																																																																          display: flex;
																																																																																																          flex-direction: column;
																																																																																																          justify-content: start;
																																																																																																          align-items: flex-start; */
	}

	.invoice .invoice-sec-1 .invoice-sec-1-date p {
		position: relative;
		top: -107px;
		text-align: right;
	}

	.invoice .invoice-sec-1 .to-invoice {
		margin-top: 45px;
		/* padding-left: 42px; */
	}

	.invoice-table {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 40px;
		/* border: 1px solid red; */
	}

	.invoice-table .invoice-table-container {
		width: 100%;
		/* border: 1px solid #000000; */
		margin: auto;
	}

	.invoice-table .invoice-table-container .invoice-table-data {
		display: flex;
		flex-direction: row;
		/* border: 1px solid #000000; */
	}

	.invoice-table .invoice-table-container .invoice-table-data .invoice-table-sl {
		text-align: center;
		width: 20%;
		border: 0.5px solid #000000;
		border-left: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-sl-h {
		border-left: 1px solid #000000 !important;
		border-top: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-desc-h {
		border-top: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-desc {
		text-align: center;
		width: 60%;
		border: 0.5px solid #000000;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-amount-h {
		border-top: 1px solid #000000 !important;
		border-right: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-amount {
		text-align: center;
		width: 20%;
		border: 0.5px solid #000000;
		border-right: 1px solid #000000 !important;
	}

	.invoice-table .invoice-table-container .invoice-table-footer {
		border: 1px solid #000000;
		display: flex;
		flex-direction: row;
		border-top: 0.5px solid #000000 !important;
	}

	.invoice-table .invoice-table-container .invoice-table-footer .invoice-total {
		text-align: center;
		width: 80%;
		/* border: 1px solid #000000; */
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-footer
		.invoice-total-amount {
		text-align: center;
		width: 20%;
		/* border: 1px solid #000000; */
	}

	p {
		padding: 0;
		/* margin: 4px; */
	}

	.invoice .invoice-banner {
		margin: 5px;
		width: 100%;
	}

	.invoice .invoice-banner .banner-d {
		width: 200px;
		border: 2px solid #000000;
		border-radius: 5px;
		margin: auto;
		padding: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.invoice .invoice-banner .banner-d p {
		font-weight: bold;
		margin: 0px;
	}

	.invoice .invoice-declaration {
		text-align: center;
	}

	.invoice .invoice-greeting {
		margin-top: 10px;
		margin-bottom: 60px;
		/* width: 200px; */
		/* border: 1px solid red; */
	}

	.invoice .invoice-greeting p {
		margin: 3px;
	}

	.note {
		padding-left: 20px;
		font-size: 14px;
		text-align: justify;
		margin-top: 8px;
	}

	.note div {
		font-size: 13px;
		margin-bottom: 7px !important;
	}
</style>
