<template>
	<div v-if="!$parent.loading && member.info && member.info.status == 1" class="row">
		<div class="col-lg-9">
			<div class="card shadow-sm">
				<div class="card-header">
					<h4 class="text-center">
						Application For Remittance against Purchase of Software Through
						eDelivery
					</h4>
				</div>
				<div class="card-body">
					<div class="alert alert-danger" role="alert" v-if="!showServiceForm">
						<h4 class="alert-heading">Warning!</h4>
						<p>This service is not availabe</p>
						<hr />
					</div>
					<form @submit.prevent="submit()" v-if="showServiceForm">
						<input type="hidden" v-model="data.service_id" />
						<div class="form-row">
							<div class="col-md-6 mb-3">
								<label for="d_o_application">
									Date of Application
									<span class="text-danger requierd-star"></span>
								</label>
								<div class="input-group">
									<Datepicker
										class="form-control shadow-none"
										:class="{
                      'is-invalid': validation.hasError(
                        'data.date_of_application'
                      ),
                      'is-valid': data.date_of_application,
                    }"
										id="date_of_application"
										placeholder="e.g. 12-12-2022."
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 12-12-2022'"
										v-model="data.date_of_application"
									/>
									<div class="invalid-feedback">{{ validation.firstError("data.date_of_application") }}</div>
								</div>
							</div>

							<div class="col-md-6 mb-3">
								<label for="name_organization">
									Name of the Organization
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<div class="input-group">
									<input
										type="text"
										class="form-control shadow-none"
										id="name_organization"
										placeholder="e.g. Nogor"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Nogor'"
										:class="{
                      'is-invalid': validation.hasError(
                        'data.name_organization'
                      ),
                      'is-valid': data.name_organization,
                    }"
										v-model="data.name_organization"
									/>
									<div class="invalid-feedback">{{ validation.firstError("data.name_organization") }}</div>
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="applicant_name">
									Name of the Applicant
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									:class="{
                    'is-invalid': validation.hasError('data.applicant_name'),
                    'is-valid': data.applicant_name,
                  }"
									id="applicant_name"
									placeholder="e.g. Jane Doe"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. Jane Doe'"
									v-model="data.applicant_name"
								/>
								<small
									v-if="errors && errors.applicant_name"
									class="invalid-feedback"
								>{{ errors.applicant_name[0] }}</small>
								<div class="invalid-feedback">{{ validation.firstError("data.applicant_name") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="designation">
									Designation
									<span class="text-danger requierd-star"></span>
								</label>
								<div class="input-group">
									<input
										type="text"
										class="form-control shadow-none"
										id="designation_representative"
										placeholder="e.g. CEO"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. CEO'"
										:class="{
                      'is-invalid': validation.hasError(
                        'data.designation_representative'
                      ),
                      'is-valid': data.designation_representative,
                    }"
										v-model="data.designation_representative"
									/>
									<div class="invalid-feedback">
										{{
										validation.firstError("data.designation_representative")
										}}
									</div>
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="permanent_address">
									Address
									<span class="text-danger requierd-star"></span>
								</label>
								<textarea
									rows="4"
									v-model="data.permanent_address"
									placeholder="e.g. 221b, baker street, London"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 221b, baker street, london'"
									class="form-control shadow-none"
									:class="{
                    'is-invalid': validation.hasError('data.permanent_address'),
                    'is-valid': data.permanent_address,
                  }"
								></textarea>
								<div class="invalid-feedback">{{ validation.firstError("data.permanent_address") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="cell_phone">
									Phone Number
									<span class="text-danger requierd-star"></span>
								</label>
								<div class="input-group">
									<div class="input-group-prepend">
										<span class="input-group-text" id="inputGroupPrepend31">+88</span>
									</div>
									<input
										type="text"
										class="form-control shadow-none"
										id="phone"
										pattern="[0-9]*"
										placeholder="e.g. 01xxxxxxxxx"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
										:class="{
                      'is-invalid': validation.hasError('data.phone'),
                      'is-valid': data.phone,
                    }"
										v-model="data.phone"
									/>
									<div class="invalid-feedback">{{ validation.firstError("data.phone") }}</div>
								</div>
								<!-- <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend32">+88</span>
                  </div>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    id="land_phone"
                    pattern="[0-9]*"
                    placeholder="e.g. 01xxxxxx"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 01xxxxx'"
                    :class="{'is-invalid': validation.hasError('data.land_phone'), 'is-valid': data.land_phone}"
                    v-model="data.land_phone"
                  />
                  <div class="invalid-feedback">{{ validation.firstError('data.land_phone') }}</div>
                </div>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend33">+88</span>
                  </div>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    id="office_phone"
                    pattern="[0-9]*"
                    placeholder="e.g. 01xxxxxxxx"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 01xxxxxxxx'"
                    :class="{'is-invalid': validation.hasError('data.office_phone'), 'is-valid': data.office_phone}"
                    v-model="data.office_phone"
                  />
                  <div class="invalid-feedback">{{ validation.firstError('data.office_phone') }}</div>
								</div>-->
							</div>
							<div class="col-md-6 mb-3">
								<label for="email">
									Email Address
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="email"
									placeholder="e.g. 01xxxxxxx"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 01xxxxxxx'"
									:class="{
                    'is-invalid': validation.hasError('data.email'),
                    'is-valid': data.email,
                  }"
									v-model="data.email"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.email") }}</div>
							</div>

							<div class="col-md-6 mb-3">
								<label for="trade_license">Trade License Number</label>
								<span class="text-danger requierd-star"></span>
								<input
									type="text"
									class="form-control shadow-none"
									id="trade_license"
									placeholder="e.g. 01169"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 01169'"
									:class="{
                    'is-invalid': validation.hasError('data.trade_license'),
                    'is-valid': data.trade_license,
                  }"
									v-model="data.trade_license"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.trade_license") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="trade_license_validity">
									Validity of Trade License Number
									<span class="text-danger requierd-star"></span>
								</label>
								<div class="input-group">
									<Datepicker
										class="form-control shadow-none"
										:class="{
                      'is-invalid': validation.hasError(
                        'data.trade_license_validity'
                      ),
                      'is-valid': data.trade_license_validity,
                    }"
										id="established_year"
										placeholder="e.g. 12-12-2022."
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 12-12-2022'"
										name="date"
										v-model="data.trade_license_validity"
									/>
									<div class="invalid-feedback">{{ validation.firstError("data.trade_license_validity") }}</div>
								</div>
							</div>

							<div class="col-md-6 mb-3">
								<label for="registration_number">Registration Number</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="registration_number"
									placeholder="e.g. 13245"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 13245'"
									:class="{
                    'is-invalid': validation.hasError(
                      'data.registration_number'
                    ),
                    'is-valid': data.registration_number,
                  }"
									v-model="data.registration_number"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.registration_number") }}</div>
							</div>

							<div class="col-md-12 mb-3">
								<label for="business_sector">
									Business Sector
									<span class="text-danger requierd-star"></span>
								</label>
								<select
									v-model="data.business_sector"
									class="form-control"
									:class="{
                    'is-invalid': validation.hasError('data.business_sector'),
                    'is-valid': data.business_sector,
                  }"
								>
									<option selected value="null">Choose...</option>
									<option>IT</option>
									<option>ITES</option>
									<option>Financial</option>
									<option>Telecom</option>
									<option>Others</option>
								</select>
								<div class="invalid-feedback">{{ validation.firstError("data.business_sector") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="product_name">
									Product Name
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									:class="{
                    'is-invalid': validation.hasError('data.product_name'),
                    'is-valid': data.product_name,
                  }"
									id="product_name"
									placeholder="Product Name"
									v-model="data.product_name"
								/>
								<small
									v-if="errors && errors.product_name"
									class="invalid-feedback"
								>{{ errors.product_name[0] }}</small>
								<div class="invalid-feedback">{{ validation.firstError("data.product_name") }}</div>
							</div>

							<div class="col-md-6 mb-3">
								<label for="product_version">Version of product</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="product_version"
									placeholder="e.g. 1.0"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 1.0'"
									:class="{
                    'is-invalid': validation.hasError('data.product_version'),
                    'is-valid': data.product_version,
                  }"
									v-model="data.product_version"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.product_version") }}</div>
							</div>
							<div class="col-md-12 mb-3">
								<label for="product_category">
									Product Category
									<span class="text-danger requierd-star"></span>
								</label>
								<select
									v-model="data.product_category"
									class="form-control"
									:class="{
                    'is-invalid': validation.hasError('data.product_category'),
                    'is-valid': data.product_category,
                  }"
								>
									<option selected value="null">Choose...</option>
									<option>Operating system</option>
									<option>Database</option>
									<option>Accounting &amp; financial software</option>
									<option>GIS</option>
									<option>Graphics</option>
									<option>Enterprise resource planning (ERP)</option>
									<option>Banking application</option>
									<option>Animation &amp; multimedia</option>
									<option>CAD</option>
									<option>Financial market solution</option>
									<option>Communication software</option>
									<option>Content &amp; document management</option>
									<option>CRM, marketing &amp; sales automation</option>
									<option>Customized software</option>
									<option>Mobile application</option>
									<option>eLearning</option>
									<option>eGovernance</option>
									<option>Business Intelligence</option>
									<option>Office management system</option>
									<option>Hospital management system</option>
									<option>HR &amp; Payroll system</option>
									<option>Education institution management system</option>
									<option>POS, Inventory management system</option>
									<option>Others</option>
								</select>
								<div class="invalid-feedback">{{ validation.firstError("data.product_category") }}</div>
							</div>
							<div class="col-md-12 mb-3">
								<label for="import_purpose">
									Purpose of Import
									<span class="text-danger requierd-star"></span>
								</label>
								<select
									v-model="data.import_purpose"
									class="form-control"
									:class="{
                    'is-invalid': validation.hasError('data.import_purpose'),
                    'is-valid': data.import_purpose,
                  }"
								>
									<option selected value="null">Choose...</option>
									<option>Self use</option>
									<option>Resale</option>
									<option>Educational</option>
									<option>Training</option>
									<option>Demo</option>
									<option>Value added resale</option>
									<option>Others</option>
								</select>
								<div class="invalid-feedback">{{ validation.firstError("data.import_purpose") }}</div>
							</div>
							<div class="col-md-12 mb-3">
								<label for="e_delivery_type">
									Type of Delivered under eDelivery
									<span class="text-danger requierd-star"></span>
								</label>
								<select
									v-model="data.e_delivery_type"
									class="form-control"
									:class="{
                    'is-invalid': validation.hasError('data.e_delivery_type'),
                    'is-valid': data.e_delivery_type,
                  }"
								>
									<option selected value="null">Choose...</option>
									<option>Software License</option>
									<option>AMC</option>
									<option>Up gradation of license / software</option>
									<option>SAAS / cloud hosting of software</option>
									<option>Others</option>
								</select>
								<div class="invalid-feedback">{{ validation.firstError("data.e_delivery_type") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="product_owner_name">
									Product Owners Name
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									:class="{
                    'is-invalid': validation.hasError(
                      'data.product_owner_name'
                    ),
                    'is-valid': data.product_owner_name,
                  }"
									id="product_owner_name"
									placeholder="e.g. Jane Doe"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. Jane Doe'"
									v-model="data.product_owner_name"
								/>
								<small
									v-if="errors && errors.product_owner_name"
									class="invalid-feedback"
								>{{ errors.product_owner_name[0] }}</small>
								<div class="invalid-feedback">{{ validation.firstError("data.product_owner_name") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="product_owner_website">
									Product Owner's Website
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<input
									type="url"
									class="form-control shadow-none"
									id="product_owner_website"
									placeholder="e.g. http://website.com"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. http://website.com'"
									:class="{
                    'is-invalid': validation.hasError(
                      'data.product_owner_website'
                    ),
                    'is-valid': data.product_owner_website,
                  }"
									v-model="data.product_owner_website"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.product_owner_website") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="product_owner_website_page">Product page in Owner's Website</label>
								<input
									type="url"
									class="form-control shadow-none"
									id="product_owner_website_page"
									placeholder="e.g. http://website.com"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. httpl://website.com'"
									:class="{
                    'is-invalid': validation.hasError(
                      'data.product_owner_website_page'
                    ),
                    'is-valid': data.product_owner_website_page,
                  }"
									v-model="data.product_owner_website_page"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.product_owner_website_page") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="partner_name">Distributor's / Partner Name</label>
								<input
									type="text"
									class="form-control shadow-none"
									:class="{
                    'is-invalid': validation.hasError('data.partner_name'),
                    'is-valid': data.partner_name,
                  }"
									id="partner_name"
									placeholder="e.g. Jane Doe"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. Jane Doe'"
									v-model="data.partner_name"
								/>
								<small
									v-if="errors && errors.partner_name"
									class="invalid-feedback"
								>{{ errors.partner_name[0] }}</small>
								<div class="invalid-feedback">{{ validation.firstError("data.partner_name") }}</div>
							</div>
							<div class="col-md-12 mb-3">
								<label for="partner_address">
									Distributor's / Partner address
									<span class="text-danger requierd-star"></span>
								</label>
								<textarea
									rows="5"
									v-model="data.partner_address"
									:class="{
                    'is-invalid': validation.hasError('data.partner_address'),
                    'is-valid': data.partner_address,
                  }"
									placeholder="e.g. 221b, Baker Street, London"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. Nogor'"
									class="form-control shadow-none"
								></textarea>
								<div class="invalid-feedback">{{ validation.firstError("data.partner_address") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="partner_website">
									Distributor's / Partner Website
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="partner_website"
									placeholder="e.g. http://website.com"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. http://website.com'"
									:class="{
                    'is-invalid': validation.hasError('data.partner_website'),
                    'is-valid': data.partner_website,
                  }"
									v-model="data.partner_website"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.partner_website") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="applicant_bank">
									Applicant's Remitting Bank Name
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="applicant_bank"
									placeholder="e.g. Bank ltd"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. Bank ltd'"
									:class="{
                    'is-invalid': validation.hasError('data.applicant_bank'),
                    'is-valid': data.applicant_bank,
                  }"
									v-model="data.applicant_bank"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.applicant_bank") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="branch_name">
									Branch Name
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="branch_name"
									placeholder="e.g. Gulshan"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. Gulshan'"
									:class="{
                    'is-invalid': validation.hasError('data.branch_name'),
                    'is-valid': data.branch_name,
                  }"
									v-model="data.branch_name"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.branch_name") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="branch_location">
									Branch Location
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="branch_location"
									placeholder="e.g. Gulshan"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. GUlshan'"
									:class="{
                    'is-invalid': validation.hasError('data.branch_location'),
                    'is-valid': data.branch_location,
                  }"
									v-model="data.branch_location"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.branch_location") }}</div>
							</div>

							<div class="col-md-12 mb-3">
								<label for="is_confirm"></label>
								<div class="form-group">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											id="is_confirm"
											:class="{
                        'is-invalid': validation.hasError('data.is_confirm'),
                      }"
											v-model="data.is_confirm"
											class="custom-control-input"
											value
										/>
										<label class="custom-control-label" for="is_confirm">
											I confirm that the information provided above, and in any
											attachment, are correct and I understand that any false
											statement or information could result in my application
											being terminated.
										</label>
									</div>
								</div>
								<div class="invalid-feedback">{{ validation.firstError("data.is_confirm") }}</div>
							</div>
						</div>
						<button class="btn btn-primary" type="submit" :disabled="validation.countErrors() > 0">Submit</button>
					</form>
				</div>

				<!-- <pre>{{ memberInfo }}</pre> -->
			</div>
		</div>
		<right-side-rapst></right-side-rapst>
	</div>
	<div v-else-if="member.info && member.info.status == 0" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">Sorry!! your account is suspend</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import RightSideRapst from "../../../../../components/Frontend/partials/member/RightSideRapst";

	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);

	export default {
		name: "AccountOfCompany",
		components: {
			RightSideRapst
		},
		props: ["memberInfo"],
		data() {
			return {
				title: "Account Of Company",
				showServiceForm: false,
				count: 0,
				submitted: false,
				seen: false,
				seen1: false,
				data: {
					name_organization: null,
					business_sector: null,
					product_category: null,
					import_purpose: null,
					e_delivery_type: null,
					payment_method: null,
					service_id: null,
					applicant_name: null,
					phone: null,
					email: null,
					mailing_address: null,
					permanent_address: null,
					date_of_application: new Date().toLocaleString()
				},
				member: {
					info: null
				},
				text: "",
				errors: {}
			};
		},
		methods: {
			submit() {
				this.data.form_type = this.$route.name;
				this.$validate().then(res => {
					if (this.validation.countErrors() > 0) {
						this.$toastr(
							"warning",
							'You need to fill <span class="font-weight-bold">' +
								this.validation.countErrors() +
								" more empty</span> mandatory fields",
							"Empty Mandatory Fields!!"
						);
					} else {
						if (res) {
							this.$parent.loading = true;
							axios
								.post("/member/store-company-info", this.data)
								.then(response => {
									this.$toastr(
										"success",
										"Your information has been submitted, please payment",
										"Success!!"
									);
									if (response.data) {
										localStorage.setItem("recp_id", response.data.recp_id);
										this.$router.push({
											name: "paymentMethod",
											params: {
												id: this.data.service_id,
												amount: response.data.amount
											}
										});
									}
								})
								.catch(error => {
									if (error.response.status === 422) {
										this.errors = error.response.data.errors || {};
										this.focus_field = Object.keys(this.errors)[0];
									}
								})
								.then(alw => {
									this.$parent.loading = false;
								});
						}
					}
				});
			},
			getServiceInfo() {
				axios
					.get("/member/get-serviceinfo/" + this.$route.params.id)
					.then(res => {
						this.showServiceForm = res.data;
					});
			}
		},
		created() {
			this.loggedIn();
			// primary
			axios.get("member/get-represent-info/primary").then(response => {
				this.data.applicant_name = response.data.name;
				this.data.designation_representative = response.data.designation;
				this.data.email = response.data.email;
			});
			setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);
			this.data.service_id = this.$route.params.id;
			this.getServiceInfo();
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
		updated() {
			if (this.count <= 5) {
				if (this.memberInfo) {
					this.data.name_organization = this.memberInfo.company_name;
					this.data.permanent_address = this.memberInfo.address;
					this.count++;
				}
			}
		},
		validators: {
			"data.applicant_name": function(value = null) {
				return Validator.value(value)
					.required("Applicant name is required")
					.minLength(2);
			},
			"data.date_of_application": function(value = null) {
				return Validator.value(value).required("Date of application is required");
			},

			"data.permanent_address": function(value = null) {
				return Validator.value(value).required("Address is required");
			},

			"data.email": function(value = null) {
				return Validator.value(value)
					.required("Email address required")
					.email();
			},
			"data.phone": function(value = null) {
				return Validator.value(value)
					.required("Cell phone number required")
					.digit()
					.regex("01+[0-9]*$", "Must start with 01.")
					.minLength(11)
					.maxLength(11);
			},
			"data.land_phone": function(value = null) {
				// return Validator.value(value)
				//   .required("Land phone number required")
				//   .digit();
			},
			"data.office_phone": function(value = null) {
				// return Validator.value(value)
				//   .required("Office phone number required")
				//   .digit();
			},
			"data.designation_representative": function(value = null) {
				return Validator.value(value).required("Designation required");
			},
			"data.trade_license": function(value = null) {
				return Validator.value(value).required("Trade License Number required");
			},
			"data.trade_license_validity": function(value = null) {
				return Validator.value(value).required(
					"Validity of Trade License Number required"
				);
			},
			// "data.registration_number": function(value = null) {
			// return Validator.value(value)
			//   .required("Registration Number required")
			//   .digit();
			// },
			// "data.product_version": function(value = null) {
			// return Validator.value(value).required("Version of product required");
			// },
			// "data.name_organization": function(value = null) {
			// return Validator.value(value)
			//   .required("Name of organization required")
			//   .minLength(2)
			//   .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.");
			// },
			// "data.product_name": function(value = null) {
			//   return Validator.value(value)
			//     .required("Product Name required")
			//     .minLength(2)
			//     .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.");
			// },

			"data.business_sector": function(value = null) {
				return Validator.value(value).required("Business Sector Required");
			},
			"data.product_category": function(value = null) {
				return Validator.value(value).required("Product Category Required");
			},
			"data.import_purpose": function(value = null) {
				return Validator.value(value).required("Import Purpose Required");
			},
			"data.e_delivery_type": function(value = null) {
				return Validator.value(value).required(
					"Type of Delivered under eDelivery Required"
				);
			},
			// "data.product_owner_name": function(value = null) {
			//   return (
			//     Validator.value(value)
			// .required("Product Owners Name required")
			// .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.")
			//   );
			// },
			// "data.product_owner_website": function(value = null) {
			//   return (
			//     Validator.value(value)
			// .required("Product Owner's Website required")
			//       .url()
			//   );
			// },
			"data.product_owner_website_page": function(value = null) {
				// return Validator.value(value)
				//   .required("Product page in Owner's Website required")
				//   .url();
			},
			"data.partner_name": function(value = null) {
				// return Validator.value(value)
				//   .required("Partner Name required")
				//   .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.");
			},
			"data.partner_address": function(value = null) {
				return Validator.value(value).required(
					"Distributor's / Partner address Address required"
				);
			},
			// "data.partner_website": function(value = null) {
			// return Validator.value(value)
			//   .required("Distributor's / Partner Website required")
			//   .url();
			// },
			// "data.applicant_bank": function(value = null) {
			//   return (
			//     Validator.value(value)
			//       .required("Applicant's Remitting Bank Name required")
			//       .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.")
			//   );
			// },
			// "data.branch_name": function(value = null) {
			//   return (
			//     Validator.value(value)
			//       .required("Branch Name required")
			//       .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.")
			//   );
			// },
			// "data.branch_location": function(value = null) {
			//   return (
			//     Validator.value(value)
			// .required("Branch location required")
			// .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.")
			//   );
			// },
			"data.is_confirm": function(value = null) {
				return Validator.value(value).required("Confirmation is required");
			}
		}
	};
</script>

<style >
	.invalid-feedback {
		display: block !important;
	}
</style>
