<template>
  <div class="col-lg-3">
    <div class="card shadow-sm mb-3">
      <div class="card-header">
        <div class="row bg-ash rounded-sm">
          <div class="col-md-12 px-0">
            <div class="row m-0 header-bg">
              <h6 class="m-auto">Required Attachments</h6>
            </div>
          </div>
          <div class="col-md-12">
            <!-- <p class="font-weight-bold font-size-md mt-1"> For ERQ Account individual must submit the below documents: </p> -->
            <ul class="fontsize-sm pl-3">
              <li>
                <p class="mb-0">Request letter from the remitting bank</p>
              </li>
              <li>
                <p class="mb-0">Company registration documen</p>
              </li>
              <li>
                <p class="mb-0">Trade license</p>
              </li>
              <li>
                <p class="mb-0">
                  Distribution authorization certificate
                  <small>(If applicable)</small>
                </p>
              </li>
              <li>
                <p
                  class="mb-0"
                >Challan / Invoice / Certification of license key issuance / delivery notifications</p>
              </li>
              <li>
                <p class="mb-0">
                  Software product catalogue or brochure
                  <small>(If link to the product information in owner’s website is not available)</small>
                </p>
              </li>
              <li>
                <p class="mb-0">Any other document that applicant thinks to justify</p>
              </li>
              <li>
                <p class="mb-0">Fee payment document (deposit slip) to BASIS account</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightSideRapst"
};
</script>
