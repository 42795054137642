<template>
	<div id="example-content">
		<slot v-if="!$root.spinner">
			<div class="policy-menu row align-items-center justify-content-center">
				<div class="col-lg-5">
					<ul class="nav nav-fill">
						<li class="nav-item">
							<router-link
								:to="{ name: 'policy', params: { type: 'Policies' } }"
								class="nav-link"
								:class="$route.params.type == 'Policies' ? 'active' : ''"
								>Policies</router-link
							>
						</li>
						<li class="nav-item">
							<router-link
								:to="{ name: 'policy', params: { type: 'Acts' } }"
								:class="$route.params.type == 'Acts' ? 'active' : ''"
								class="nav-link"
								>Acts</router-link
							>
						</li>
						<li class="nav-item">
							<router-link
								:to="{ name: 'policy', params: { type: 'Laws' } }"
								:class="$route.params.type == 'Laws' ? 'active' : ''"
								class="nav-link"
								>Laws</router-link
							>
						</li>
					</ul>
				</div>
			</div>
			<div class="basis-member staff">
				<div
					class="accordion"
					id="accordionExample"
					v-if="$route.params.type == 'Policies'"
				>
					<div
						class="card shadow-lg"
						v-for="(ministry, index, key) in $root.variable.ministry"
						:key="index"
					>
						<div class="card-header" :id="'heading' + index">
							<h2 class="mb-0">
								<button
									class="btn btn-link text-dark"
									type="button"
									data-toggle="collapse"
									:data-target="'#collapse' + index"
									:aria-expanded="index == 0 ? 'true' : 'false'"
									:aria-controls="'collapse' + index"
								>
									{{ ministry }}
								</button>
							</h2>
						</div>

						<div
							:id="'collapse' + index"
							:class="key ? 'collapse' : 'collapse'"
							:aria-labelledby="'heading' + index"
							data-parent="#accordionExample"
						>
							<div class="card-body">
								<table class="table table-sm table-bordered table-hover">
									<thead class="bg-success text-light">
										<tr>
											<th class="text-center">Sl.</th>
											<th class="text-center">
												<a>{{ $route.params.type }}</a>
											</th>
											<th class="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										<slot v-for="(policy, index2) in table.datas">
											<tr
												:key="'A' + index2"
												v-if="index == policy.category_id"
											>
												<td class="text-center">{{ index2 + 1 }}</td>
												<td>
													<a class="text-dark">{{ policy.title }}</a>
												</td>
												<td class="text-center">
													<a
														:href="$root.baseurl + '/public' + policy.file"
														target="_blank"
														class="btn btn-sm btn-danger rounded-50 lift"
													>
														<i class="mdi mdi-file-pdf"></i>
													</a>
												</td>
											</tr>
										</slot>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body" v-else>
					<table class="table table-sm table-bordered table-striped">
						<thead class="thead-dark">
							<tr>
								<th class="text-center">Sl.</th>
								<th class="text-center">
									<a>{{ $route.params.type }}</a>
								</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<slot v-if="!$root.spinner">
								<tr v-for="(policy, index2) in table.datas" :key="'A' + index2">
									<td class="text-center" v-if="meta">{{ index2 + 1 }}</td>
									<td>
										<a class="text-dark">{{ policy.title }}</a>
									</td>
									<td>
										<a
											:href="$root.baseurl + '/public' + policy.file"
											target="_blank"
											class="btn btn-sm btn-primary rounded-50"
											>View PDF</a
										>
									</td>
								</tr>
							</slot>
						</tbody>
						<tr v-if="$root.spinner">
							<td colspan="3">
								<spinner />
							</td>
						</tr>
					</table>

					<!-- pagination -->
					<Pagination :url="'get-policy/' + $route.params.type" />
					<!-- /pagination -->
				</div>
			</div>
		</slot>
		<spinner v-if="$root.spinner" />
	</div>
</template>

<script>
	export default {
		name: "Policy",
		data() {
			return {
				breadcrumbTitle: "Policy",
				breadcumb: [{ title: "Policy" }],
				table: {
					datas: [],
				},
				meta: [],
				links: [],
				policies_type: this.$route.params.type,
			};
		},
		methods: {
			asyncData() {
				this.$root.loading = false;
				this.get_data(this.$route.params.type);
			},
			get_data(type = null) {
				this.$root.spinner = true;
				if (type == null) {
					type = this.policies_type;
				}
				axios
					.get("/get-policy/" + type)
					.then((respons) => {
						this.table.datas = respons.data.data;
						this.meta = respons.data.meta;
						this.links = respons.data.links;
						this.breadcrumbTitle = this.$route.params.type;
						this.breadcumb = [{ title: this.$route.params.type }];
						this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
						this.$root.spinner = false;
					})
					.catch((error) => {
						console.log(error);
					})
					.then((res) => {
						this.$root.spinner = false;
					});
			},
		},
		mounted() {},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		watch: {
			$route: {
				handler: "asyncData",
				immediate: true,
			},
			"$route.path": function (val, oldVal) {
				this.get_data(this.$route.params.type);
				this.$root.loading = false;
			},
		},
	};
</script>

<style scoped>
	.sidebar-menu.vue-affix {
		top: 0px !important;
		width: 280px;
		animation: FadeUp 1s ease forwards;
	}
	.sidebar-menu.vue-affix.affix {
		top: 70px !important;
		z-index: 1030;
	}

	@keyframes FadeUp {
		form {
			top: 0;
		}
		to {
			top: 70px !important;
		}
	}
</style>
