<template>
  <div class>
    <div class="card sidebar shadow-sm mb-3">
      <div class="card-header">
        <div class="user">
          <!-- <img
            v-if="regMember && regMember.image !== null && regMember.image !== ''"
            :src="$root.baseurl+'/public'+regMember.logo"
            alt
          />
          <img v-else :src="$root.baseurl+require('./../../../../assets/img/no_logo.gif')" />-->
          <div class="userName py-3 text-center">
            <h2 class="text-uppercase" v-if="regMember">{{ regMember.company_name }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="card sidemenu shadow-sm mb-3">
      <div class="card-body p-0">
        <nav class="navbar navbar-expand-lg p-0">
          <button
            class="navbar-toggler m-2"
            type="button"
            data-toggle="collapse"
            data-target="#dashMenu"
            aria-controls="dashMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="fas fa-bars pr-1"></span>
            Profile Menu
          </button>
          <div class="collapse navbar-collapse" id="dashMenu">
            <ul class="nav flex-column w-100">
              <li class="nav-item">
                <router-link :to="{ name:'reg-member.dashboard'}" class="nav-link">Dashboard</router-link>
              </li>

              <li class="nav-item">
                <router-link
                  :to="{ name:'reg-member.dashboardForm'}"
                  class="nav-link"
                >Registration Form</router-link>
              </li>
              <li
                class="nav-item"
                v-if="$route.params.paymentOption || $root.regMemberData && $root.regMemberData.proceed_to_pay ==1"
              >
                <router-link :to="{ name:'reg-member.pdf'}" class="nav-link">Download PDF</router-link>
              </li>
              <li
                class="nav-item"
                v-if="$route.params.paymentOption || $root.regMemberData && $root.regMemberData.proceed_to_pay ==1"
              >
                <router-link :to="{ name:'reg-member.invoice'}" class="nav-link">Payment Info</router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link
                  :to="{ name:'reg-member.internationa_event_form'}"
                  class="nav-link"
                >International Event</router-link>
              </li>-->
              <li class="nav-item">
                <a href="javascript:void(0)" v-on:click="logout()" class="nav-link">Logout</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <!-- <pre>{{regMember}}</pre> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftSide",
  data() {
    return {
      regMember: {}
    };
  },
  methods: {
    logout: function() {
      this.logoutRegMember();
    },
    getRegMember() {
      axios.get("/reg-member/user").then(response => {
        this.regMember = response.data;
      });
    }
  },
  mounted() {
    this.getRegMember();
  }
};
</script>
