<template>
	<div class="col-md-8 login-page">
		<div class="card-box">
			<div class="row">
				<div class="col-md-6">
					<div class="left-content">
						<img
							class="basis-logo"
							:src="$root.baseurl + '/public/images/logo.svg'"
							alt="basislogo"
						/>
						<h4>Welcome to BASIS DigitalShop</h4>
						<h5>Create your BASIS DigitalShop Account</h5>
						<img
							class="login-image"
							:src="$root.baseurl + '/public/images/ecommerce-login.jpg'"
							alt="basislogo"
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div v-if="!otpSection" class="right-form py-4">
						<h3 class="text-center">Signup here</h3>
						<div class="form-group">
							<label for class="col-form-label-sm m-0">Name</label>
							<div class="loginBox">
								<input
									:class="{
										'is-invalid': validation.hasError('data.name'),
										'is-valid': data.name,
									}"
									type="text"
									autocomplete="off"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'Name'"
									placeholder="Name"
									name="name"
									class="form-control form-control-sm"
									v-model="data.name"
								/>
								<span class="mdi mdi-account"></span>
							</div>
							<div class="invalid-feedback">
								{{ validation.firstError("data.name") }}
							</div>
						</div>
						<div class="form-group">
							<label for class="col-form-label-sm m-0">Mobile</label>
							<div class="loginBox">
								<input
									:class="{
										'is-invalid': validation.hasError('data.mobile'),
										'is-valid': data.mobile,
									}"
									type="number"
									autocomplete="off"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'ie:01**********'"
									placeholder="ie:01**********"
									name="Mobile"
									class="form-control form-control-sm"
									v-model="data.mobile"
								/>
								<span class="mdi mdi-phone"></span>
							</div>
							<div class="invalid-feedback">
								{{ validation.firstError("data.mobile") }}
							</div>
						</div>
						<div class="form-group">
							<label for class="col-form-label-sm m-0">Email</label>
							<div class="loginBox">
								<input
									:class="{
										'is-invalid': validation.hasError('data.email'),
										'is-valid': data.email,
									}"
									type="email"
									autocomplete="off"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'ie: customer@mail.com'"
									placeholder="ie: member@mail.com"
									name="email"
									class="form-control form-control-sm"
									v-model="data.email"
								/>
								<span class="mdi mdi-email"></span>
							</div>
							<div class="invalid-feedback">
								{{ validation.firstError("data.email") }}
							</div>
						</div>
						<div class="form-group">
							<label for class="col-form-label-sm m-0">Password</label>
							<div class="loginBox">
								<input
									:class="{
										'is-invalid': validation.hasError('data.password'),
										'is-valid': data.password,
									}"
									type="password"
									autocomplete="off"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'ie: A-Z, a-z,digit(0-9),special character'"
									placeholder="ie: A-Z, a-z,digit(0-9),special character"
									name="password"
									class="form-control form-control-sm"
									v-model="data.password"
									v-on:keyup.enter="otpMethodSelect"
								/>
								<span class="mdi mdi-shield-lock-outline"></span>
							</div>
							<div class="invalid-feedback">
								{{ validation.firstError("data.password") }}
							</div>
						</div>
						<div
							class="d-flex justify-content-between align-content-center mt-4"
						>
							<button v-on:click="otpMethodSelect()" class="btn btn-primary">
								Submit
							</button>
							<span>
								Already have an account?
								<router-link :to="{ name: 'ecommerce.login' }"
									>Login</router-link
								>
							</span>
						</div>
					</div>
					<div v-if="$root.spinner" class="right-form">
						<spinner />
					</div>
					<!-- OTP VERIFY -->
					<div v-if="otpSection" class="col-md-12 mt-2">
						<fieldset class="border rounded p-3">
							<div class="text-success font-weight-bold">
								Please check your email
							</div>
							<table class="table table-borderless">
								<tr>
									<th>Name:</th>
									<td v-if="data.name">{{ data.name }}</td>
								</tr>
								<tr>
									<th>Email Address:</th>
									<td v-if="data.email">{{ data.email }}</td>
								</tr>
								<tr>
									<td colspan="2">
										<div class="row align-items-center m-0">
											<div class="col-8 p-0">
												<input
													v-model="otp"
													type="text"
													class="form-control form-control-sm"
													placeholder="OTP"
													name="otp"
													v-on:keyup.enter="verifyOtp"
												/>
											</div>
											<div class="col-4 p-0">
												<button
													type="submit"
													class="btn btn-sm btn-primary text-white"
													@click="verifyOtp()"
												>
													<span v-if="spinner">
														<i class="fa fa-spinner fa-spin"></i>
														processing...
													</span>
													<span v-else>Verify OTP</span>
												</button>
											</div>
											<div class="col-12 p-0">
												<a
													@click="otpMethodSelect()"
													href="javascript:void(0);"
													class="btn-custom text-primary font-weight-bold"
												>
													<span v-if="spinner">
														<i class="fa fa-spinner fa-spin"></i>
														processing...
													</span>
													<span v-else>Resend OTP</span>
												</a>
											</div>
										</div>
									</td>
								</tr>
							</table>
						</fieldset>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Promise from "bluebird";
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);

	export default {
		name: "Registration",
		data() {
			return {
				data: {},
				emailVarify: false,
				otpSection: false,
				spinner: false,
				otp: "",
			};
		},
		methods: {
			submit: function () {
				this.scrollTop();
				this.$root.spinner = true;

				this.$validate().then((res) => {
					if (res) {
						axios
							.post("/ecommerce/register", this.data)
							.then((respons) => {
								if (respons.data.code == 202) {
									this.$toastr("success", respons.data.msg, "Success!!");
									window.location.href =
										this.$root.baseurl + "/digitalshop/dashboard";
								} else if (respons.data.code == "401") {
									this.$root.spinner = false;
									this.$toastr("error", respons.data.msg, "Sorry!!");
								}
							})
							.catch((error) => {
								if (error) {
									this.$toastr("error", "Invalid email or password", "Sorry!!");
									this.$root.spinner = false;
								}
							})
							.then((alw) => (this.$root.spinner = false));
					} else {
						this.$root.spinner = false;
					}
				});
			},
			otpMethodSelect() {
				this.$validate().then((res) => {
					if (res) {
						this.otpSection = true;
						this.sendMail();
					}
				});
			},
			sendMail() {
				axios
					.post("/ecommerce/send-otp-mail", {
						email: this.data.email,
						customer: true,
					})
					.then((res) => {
						console.log(res);
						if (res.data.code == 201) {
							this.$toastr(
								"success",
								"A OTP Passcode has been sent to your mail.Please enter the OTP below to verify your Email Address. If you cannot see the email in your inbox, make sure to check your SPAM/Promotions folder",
								"Email Sent!"
							);
						} else {
							this.$toastr("error", res.data.msg, res.data.type);
						}
					})
					.catch((error) => console.log(error))
					.then((alw) => setTimeout(() => (this.spinner = false), 300));
			},
			verifyOtp() {
				axios.post("/ecommerce/otp-check", { otp: this.otp }).then((res) => {
					if (res.data) {
						this.submit();
					} else {
						this.$toastr("warning", "OTP doesn't match", "Sorry!!");
					}
				});
			},
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},

		validators: {
			"data.name": function (value = null) {
				return Validator.value(value).required("Name is required");
			},
			"data.email": function (value = null) {
				var app = this;
				return Validator.value(value)
					.required("Email is required")
					.email()
					.custom(function () {
						if (!Validator.isEmpty(value)) {
							axios
								.post("/ecommerce/email-exist", { email: value })
								.then((respons) => {
									if (respons.data.status) {
										app.emailVarify = true;
									} else {
										app.emailVarify = false;
									}
								});
							return Promise.delay(1200).then(function () {
								if (app.emailVarify) {
									return "Mail already exists";
								}
							});
						}
					});
			},
			"data.mobile": function (value = null) {
				return Validator.value(value)
					.required("Mobile is required")
					.digit()
					.regex("01+[0-9+-]*$", "Must start with 01.")
					.minLength(11)
					.maxLength(11);
			},
			"data.password": function (value = null) {
				return Validator.value(value)
					.required("Password is required")
					.regex("(?=.*?[A-Z])", "at least one uppercase letter required")
					.regex("(?=.*?[a-z])", "at least one lowercase letter required")
					.regex("(?=.*?[0-9])", "at least one digit required")
					.regex("(?=.*?[#?!@$%^&*-])", "at least one special charater required")
					.minLength(6);
			},
		},
	};
</script>
