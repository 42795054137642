<template>
  <div class="memberRegistration py-5">
    <div class="container-wrapper">
      <div class="row justify-content-md-center">
        <div class="col col-lg-8">
          <div class="card login-body shadow-lg rounded-10 py-5">
            <div class="card-body">
              <span class="shape"></span>
              <h4 class="text-center">
                <big>Want to be a members of BASIS</big>
              </h4>
              <h3 class="text-center mb-4">Member Registration</h3>
              <div :class="overlay" v-if="overlay">
                <span class="shape"></span>
                <div
                  v-if="otpsection"
                  class="row w-100 justify-content-center align-items-center"
                >
                  <div class="col-lg-8">
                    <fieldset class="border rounded p-3">
                      <table class="table table-borderless">
                        <tr>
                          <td colspan="3">
                            <img
                              v-lazy="basislogo"
                              class="basis-logo"
                              alt="basis logo"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th width="30%">Company Name</th>
                          <td width="2%">:</td>
                          <td>{{ data.company_name }}</td>
                        </tr>
                        <tr>
                          <th width="30%">Email Address</th>
                          <td width="2%">:</td>
                          <td>{{ data.email }}</td>
                        </tr>
                        <tr>
                          <th width="30%">Mobile No</th>
                          <td width="2%">:</td>
                          <td>
                            {{
                              data.mobile.substring(0, 3) +
                              "******" +
                              data.mobile.substring(9, 11)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <small>Please check your email / mobile</small>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <div class="row align-items-center m-0">
                              <div class="col p-0">
                                <input
                                  v-model="data.otp"
                                  type="text"
                                  class="form-control form-control-sm"
                                  placeholder="OTP"
                                  name="otp"
                                  v-on:keyup.enter="submit"
                                />
                              </div>
                              <div class="col p-0">
                                <button
                                  type="submit"
                                  id="verify-otp"
                                  class="btn btn-sm btn-success text-white"
                                  @click="verify_otp"
                                >
                                  Verify OTP
                                </button>
                              </div>
                              <div class="col p-0">
                                <button
                                  class="btn btn-sm btn-primary text-white"
                                  @click="otp_method_select()"
                                >
                                  <span v-if="spinner">
                                    <i class="fa fa-spinner fa-spin"></i>
                                    processing...
                                  </span>
                                  <span v-else>Resend</span>
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </fieldset>
                  </div>
                </div>
              </div>
              <form
                ref="form"
                autocomplete="off"
                v-on:submit.prevent="otpVerified"
                class="form-row px-3"
                id="form"
              >
                <input type="hidden" v-model="data.id" />
                <div class="col-sm-12 form-group">
                  <div class="row">
                    <div class="col-sm-6">
                      <label for class="col-form-label-sm m-0"
                        >Company Name</label
                      >
                      <div class="loginBox">
                        <input
                          v-model="data.company_name"
                          type="text"
                          autocomplete="off"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'ie: Nogor Solutions Limited'"
                          class="form-control form-control-sm"
                          :class="{
                            'is-invalid':
                              validation.hasError('data.company_name'),
                            'is-valid':
                              !validation.isValidating('data.company_name') &&
                              data.company_name != null,
                          }"
                          placeholder="ie: Nogor Solutions Limited"
                          name="company_name"
                        />
                        <span
                          v-if="validation.isValidating('data.company_name')"
                          class="fa fa-spinner fa-spin text-danger"
                        ></span>
                        <span v-else class="mdi mdi-office-building"></span>
                      </div>
                      <small
                        v-if="errors && errors.company_name"
                        class="text-danger"
                        >{{ errors.company_name[0] }}</small
                      >
                      <div class="invalid-feedback">
                        {{ validation.firstError("data.company_name") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 form-group">
                  <div class="row">
                    <div class="col-sm-6">
                      <label for class="col-form-label-sm m-0">
                        Email (
                        <span class="text-danger">Representative</span>)
                      </label>
                      <div class="loginBox">
                        <input
                          v-model="data.email"
                          type="text"
                          autocomplete="off"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'ie: member@mail.com'"
                          class="form-control form-control-sm"
                          :class="{
                            'is-invalid': validation.hasError('data.email'),
                            'is-valid':
                              !validation.isValidating('data.email') &&
                              data.email != null,
                          }"
                          placeholder="ie: member@mail.com"
                          name="email"
                        />
                        <span
                          v-if="validation.isValidating('data.email')"
                          class="fa fa-spinner fa-spin text-danger"
                        ></span>
                        <span v-else class="mdi mdi-email"></span>
                      </div>
                      <small
                        v-if="errors && errors.email"
                        class="text-danger"
                        >{{ errors.email[0] }}</small
                      >
                      <div class="invalid-feedback">
                        {{ validation.firstError("data.email") }}
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <label for class="col-form-label-sm m-0">
                        Mobile (
                        <span class="text-danger">Representative</span>)
                      </label>
                      <div class="loginBox">
                        <input
                          v-model="data.mobile"
                          type="text"
                          autocomplete="off"
                          id="mobile12"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'ie: 01*********'"
                          class="form-control form-control-sm"
                          :class="{
                            'is-invalid': validation.hasError('data.mobile'),
                            'is-valid':
                              !validation.isValidating('data.mobile') &&
                              data.mobile != null,
                          }"
                          placeholder="ie: 01*********"
                          name="mobile"
                        />
                        <span
                          v-if="validation.isValidating('data.mobile')"
                          class="fa fa-spinner fa-spin text-danger"
                        ></span>
                        <span
                          v-else
                          class="mdi mdi-phone-settings-outline"
                        ></span>
                      </div>
                      <small
                        v-if="errors && errors.mobile"
                        class="text-danger"
                        >{{ errors.mobile[0] }}</small
                      >
                      <div class="invalid-feedback">
                        {{ validation.firstError("data.mobile") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 form-group">
                  <div class="row">
                    <div class="col-sm-6">
                      <label for class="col-form-label-sm m-0">Password</label>
                      <div class="loginBox">
                        <input
                          v-model="data.password"
                          type="password"
                          autocomplete="off"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'ie: A-Z, a-z,digit(0-9),special character'"
                          class="form-control form-control-sm"
                          :class="{
                            'is-invalid': validation.hasError('data.password'),
                            'is-valid': data.password,
                          }"
                          placeholder="ie: A-Z, a-z, digit(0-9),special character"
                          name="password"
                        />
                        <span class="mdi mdi-shield-lock-outline"></span>
                      </div>
                      <small
                        v-if="errors && errors.password"
                        class="text-danger"
                        >{{ errors.password[0] }}</small
                      >
                      <div class="invalid-feedback">
                        {{ validation.firstError("data.password") }}
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <label for class="col-form-label-sm m-0"
                        >Confirm Password</label
                      >
                      <div class="loginBox">
                        <input
                          v-model="data.password_confirmation"
                          type="password"
                          autocomplete="off"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'Re-type your password'"
                          class="form-control form-control-sm"
                          :class="{
                            'is-invalid': validation.hasError(
                              'data.password_confirmation'
                            ),
                            'is-valid': data.password_confirmation,
                          }"
                          placeholder="Re-type your password"
                          name="password_confirmation"
                        />
                        <span class="mdi mdi-shield-lock-outline"></span>
                      </div>
                      <small
                        v-if="errors && errors.password_confirmation"
                        class="text-danger"
                        >{{ errors.password_confirmation[0] }}</small
                      >
                      <div class="invalid-feedback">
                        {{
                          validation.firstError("data.password_confirmation")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 form-group">
                  <div class="row">
                    <div class="custom-control custom-checkbox">
                      <b-form-checkbox
                        class="col-form-label-sm font-weight-bold mb-0"
                        :value="true"
                        :class="{
                          'is-invalid': validation.hasError('data.agree'),
                        }"
                        v-model="data.agree"
                        :unchecked-value="false"
                      >
                        You must agree with above
                        <a
                          target="__blank"
                          :href="
                            $root.baseurl + '/content-details/terms-conditions'
                          "
                          >Terms & Conditions</a
                        >
                      </b-form-checkbox>
                      <div class="invalid-feedback">
                        {{ validation.firstError("data.agree") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 form-group mb-0">
                  <button
                    type="submit"
                    class="btn btn-primary text-white"
                    :disabled="validation.countErrors() > 0"
                  >
                    <span v-if="spinner">
                      <i class="fa fa-spinner fa-spin"></i> processing...
                    </span>
                    <span v-else>Submit</span>
                  </button>
                </div>
                <div class="col-sm-9 form-group">
                  <p class="text-right">
                    If you are already registered ?
                    <router-link
                      to="/reg-member-login"
                      class="btn btn-danger font-weight-bold rounded-50 ml-2"
                      >Login</router-link
                    >
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- <span>{{ timerCount }}</span> -->
        <!-- <pre>{{data.agree}}</pre> -->
      </div>
    </div>
  </div>
</template>

<script>
import Promise from "bluebird";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);

import axios from "axios";
export default {
  name: "MemberRegistration",
  data() {
    return {
      timerCount: 5000,
      basislogo: this.$root.baseurl + "/public/images/logo.svg",
      overlay: "",
      otpsection: true,
      spinner: false,
      otp: 0,
      data: {
        otp: null,
        password: "",
        password_confirmation: "",
        emailVarify: false,
        status: "panding",
        otp_verified: 1,
      },
      extraData: [],
      errors: {},
      focus_field: "",
    };
  },
  methods: {
    otpVerified: function (e) {
      var vm = this;
      e.otp = this.otp;
      e.dataotp = this.data.otp;
      this.submitted = true;
      this.$validate().then(function (success) {
        if (success) {
          if (e.otp != e.dataotp) {
            vm.overlay = "ottp-body";
            vm.otp_method_select();
          }
        }
      });
    },
    verify_otp() {
      axios.post("otp-check", { otp: this.data.otp }).then((res) => {
        if (res.data) {
          this.submit();
        } else {
          this.$toastr("warning", "OTP doesn't match", "Sorry!!");
        }
      });
    },
    submit: function (e) {
      vm = this;
      this.submitted = true;
      this.$validate().then(function (success) {
        if (success) {
          axios
            .post("/RegistrationPost", vm.data)
            .then((response) => {
              vm.$toastr(
                "success",
                "You have successfully registered ",
                "Congratulations!"
              );
              window.location.href = vm.$root.baseurl + "/reg-member/form";
            })
            .catch((error) => {});
        }
      });
    },
    otp_method_select() {
      this.send_sms();
    },
    send_sms() {
      this.spinner = true;
      axios
        .post("send-otp-sms", {
          becomeAmember: "reg",
          contacts: "88" + this.data.mobile,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.send_mail();
            this.$toastr("success", res.data.msg, res.data.type);
          } else {
            this.$toastr("error", res.data.msg, res.data.type);
          }
        })
        .catch((error) => {})
        .then((alw) => setTimeout(() => (this.spinner = false), 300));
    },
    send_mail() {
      this.spinner = true;
      axios
        .post("/send-otp-mail", {
          email: this.data.email,
          becomeAmember: "reg",
        })
        .then((res) => {
          if (res.data.code == 200 || res.data) {
            this.otpmethodsection = false;
            this.otpsection = true;
            this.$toastr(
              "success",
              "A OTP Passcode has been sent to your mail.Please enter the OTP below to verify your Email Address. If you cannot see the email in your inbox, make sure to check your SPAM/Promotions folder",
              "Email Sent!"
            );
          } else {
            this.$toastr("error", res.data.msg, res.data.type);
          }
        })
        .catch((error) => {})
        .then((alw) => setTimeout(() => (this.spinner = false), 300));
    },
  },
  mounted() {
    setTimeout(() => (this.$root.loading = false), this.$root.minloadtimefd);
  },

  validators: {
    "data.company_name": function (value = null) {
      var app = this;
      return Validator.value(value)
        .required("Company Name Required")
        .minLength(2)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            axios
              .post("/reg-member-company-name-validation", {
                company_name: value,
              })
              .then((respons) => {
                if (respons.data.status) {
                  app.data.emailVarify = true;
                } else {
                  app.data.emailVarify = false;
                }
              });
            return Promise.delay(1000).then(function () {
              if (app.data.emailVarify) {
                return "Company Name already exists";
              } else {
              }
            });
          }
        });
    },
    "data.email": function (value = null) {
      var app = this;
      return Validator.value(value)
        .required("Email address required")
        .email()
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            axios
              .post("/reg-member-email-validation", { email: value })
              .then((respons) => {
                if (respons.data.status) {
                  app.data.emailVarify = true;
                } else {
                  app.data.emailVarify = false;
                }
              });
            return Promise.delay(1000).then(function () {
              if (app.data.emailVarify) {
                return "Mail already exists";
              } else {
              }
            });
          }
        });
    },
    "data.mobile": function (value = null) {
      var app = this;
      return Validator.value(value)
        .required("Phone number required")
        .digit()
        .regex("01+[0-9]*$", "Must start with 01.")
        .minLength(11)
        .maxLength(11)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            axios
              .post("/reg-member-mobile-validation", { mobile: value })
              .then((respons) => {
                // console.log(respons.data.status);
                if (respons.data.status) {
                  app.data.emailVarify = true;
                  // console.log(app.data.emailVarify);
                } else {
                  app.data.emailVarify = false;
                }
              });
            //console.log(app.data.emailVarify);
            return Promise.delay(1000).then(function () {
              if (app.data.emailVarify) {
                return "Phone Number already exists";
              } else {
                //console.log("Unique, inside else");
              }
            });
          }
        });
    },
    "data.password": function (value = null) {
      return Validator.value(value)
        .required("Password required")
        .regex("(?=.*?[A-Z])", "at least one uppercase letter required")
        .regex("(?=.*?[a-z])", "at least one lowercase letter required")
        .regex("(?=.*?[0-9])", "at least one digit required")
        .regex("(?=.*?[#?!@$%^&*-])", "at least one special charater required")
        .minLength(6);
    },
    "data.password_confirmation, data.password": function (
      password_confirmation,
      password
    ) {
      return Validator.value(password_confirmation)
        .required("Password confirmation required")
        .match(password);
    },
    "data.agree": function (value = null) {
      return Validator.value(value)
        .required("Agree is required")
        .custom(function () {
          if (value == false) {
            return "You must agree with above terms and conditions";
          }
        });
    },
  },
};
</script>
