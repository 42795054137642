<template>
	<div class="cart-list container-wrapper">
		<div class="row pt-3" v-if="carts && Object.keys(carts).length > 0">
			<div class="col-md-9">
				<div class="product-section-title">
					<h4 class="mb-4 p-2">
						<i class="fa fa-shopping-cart"></i> Cart List
					</h4>
				</div>

				<!-- not login message -->
				<div class="alert alert-danger" role="alert" v-if="notLogin">
					<h4 class="alert-heading">Error!</h4>
					<p>
						You are not login. Please login and try again.
						<router-link
							style="text-decoration: underline"
							class="text-success h5"
							:to="{ name: 'ecommerce.login' }"
						>Login</router-link>
					</p>
					<hr />
				</div>
				<div class="table-responsive">
					<table class="table custom-bg-shadow-redius">
						<thead>
							<tr>
								<th>Image</th>
								<th>Product Name</th>
								<th>Color</th>
								<th>Size</th>
								<th width="15%">Price</th>
								<th width="15%">Quantity</th>
								<th>Total</th>
								<th width="10%">Action</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in carts" :key="index">
								<td>
									<img style="width: 4em" :src="item.image1" alt />
								</td>
								<td class="custom-responsive">
									<router-link
										:to="{
                  name: 'ecommerce.products.details',
                  params: { slug: item.slug },
                 }"
									>
										<b>{{ item.product_name }}</b>
									</router-link>
									<br />
									<small class="text-muted">Product Code: B232</small>
								</td>
								<td>
									<span class="badge badge-info" v-if="item.color_select">{{ item.color_select }}</span>
								</td>
								<td>
									<span class="badge badge-info" v-if="item.size_select">{{ item.size_select }}</span>
								</td>

								<td>৳ {{ item.price | currency }}</td>
								<td class="custom-responsive">
									<div class="input-group w-100">
										<div class="input-group-prepend">
											<span @click="decrement(index, item)" class="input-group-text">
												<i class="fa fa-minus"></i>
											</span>
										</div>
										<input
											type="number"
											min="1"
											class="form-control quantity"
											name="quantity"
											v-model="item.qty"
											aria-label="Amount (to the nearest dollar)"
										/>
										<div class="input-group-append">
											<span @click="increment(index, item)" class="input-group-text">
												<i class="fa fa-plus"></i>
											</span>
										</div>
									</div>
								</td>
								<td>৳ {{ item.totalPrice | currency }}</td>
								<td class="custom-responsive">
									<router-link
										:to="{
                  name: 'ecommerce.products.details',
                  params: { slug: item.slug },
                }"
									>
										<span class="btn btn-info btn-sm">
											<i class="fa fa-eye"></i>
										</span>
									</router-link>
									<a @click.prevent="removeCart(index, item)">
										<span class="btn btn-danger btn-sm">
											<i class="fa fa-trash-alt"></i>
										</span>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="row col-12">
					<div class="col-lg-4">
						<div class="coupon_code">
							<form @submit.prevent="couponApply">
								<div class="form-group">
									<div class="input-group mb-3">
										<input
											type="text"
											class="form-control coupon_code_input"
											required
											name="coupon_code"
											v-model="coupon.coupon_code"
											placeholder="Enter coupon code"
										/>

										<button type="submit" class="coupn_apply_button">Apply</button>
									</div>
								</div>
							</form>
						</div>
						<p>
							<small class="text-danger" v-if="couponErMsg">{{ couponErMsg }}</small>
							<small class="text-success" v-if="couponSucMsg">{{ couponSucMsg }}</small>
						</p>
						<p class="text-danger" v-if="notLogin">Please login to apply the coupon</p>
					</div>
					<div class="col-lg-6 ml-auto text-center text-lg-right">
						<router-link
							:to="{ name: 'ecommerce.home' }"
							class="btn btn-primary shadow-sm rounded-50 text-white"
						>Continue Shopping</router-link>
					</div>
				</div>
			</div>
			<div class="col-lg-3 mt-5 mx-3 mx-lg-0">
				<div class="card border-0 custom-bg-shadow-redius">
					<div class="card-header">
						<h4>Order Summary</h4>
					</div>
					<div class="card-body">
						<table class="order-summary-table">
							<tr>
								<th>Subtotal</th>
								<td>:</td>
								<td>৳ {{ totalPrice | currency }}</td>
							</tr>
							<tr v-if="discountPrice > 0">
								<th>Discount</th>
								<td>:</td>
								<td>৳ {{ discountPrice | currency }}</td>
							</tr>
							<tr v-if="$store.state.couponInfo.total_price > 0">
								<th>Coupon</th>
								<td>:</td>
								<td>৳ {{ $store.state.couponInfo.total_price | currency }}</td>
								<td>
									<button class="btn btn-sm btn-danger" @click="removeCoupon()">X</button>
								</td>
							</tr>
							<tr>
								<td colspan="3">
									<hr />
								</td>
							</tr>
						</table>
						<div class="row" style="color: #ed1c24">
							<div class="col-5 text-right">
								<span>Total :</span>
							</div>
							<div class="col-7">
								<span>
									৳
									{{
									(Number(totalPrice) -
									Number(coupon_total_price) -
									Number(discountPrice))
									| currency
									}}
								</span>
							</div>
						</div>

						<br />
						<router-link
							:to="{ name: 'ecommerce.checkout' }"
							class="btn btn-success px-3 py-2 d-block"
						>PROCEED TO CHECKOUT</router-link>
					</div>
				</div>
			</div>
		</div>
		<EmptyCart list-name="Cart" v-else />
	</div>
</template>

<script>
	import swal from "sweetalert";
	import EmptyCart from "../elements/EmptyCart";
	export default {
		name: "AddToCart",
		components: { EmptyCart },
		data() {
			return {
				carts: [],
				coupon: {
					coupon_code: "",
					total_price: 0.0
				},
				couponErMsg: "",
				couponSucMsg: "",
				coupon_total_price:
					this.$store.state.couponInfo && this.$store.state.couponInfo.total_price
						? this.$store.state.couponInfo.total_price
						: 0,
				notLogin: false,
				stocks: [],
				discounts: []
			};
		},
		methods: {
			getCart() {
				this.carts = this.$store.state.cart;
			},
			increment(index, product) {
				if (this.carts[index]["qty"] > 0) {
					// add to cart
					product.update = "increment";
					this.$store.commit("addToCart", product);
					this.getCart();
				}
			},
			decrement(index, product) {
				if (this.carts[index]["qty"] > 1) {
					// add to cart
					product.update = "decrement";
					this.$store.commit("addToCart", product);
					this.getCart();
				}
			},
			removeCart(index, product) {
				swal({
					title: "Are you sure?",
					text: "Do you want delete from cart list? ",
					icon: "warning",
					buttons: true,
					dangerMode: true
				}).then(willDelete => {
					if (willDelete) {
						this.$store.commit("removeFromCart", product);
					}
				});
			},
			couponApply() {
				if (this.$root.ecomLogged) {
					this.coupon.total_price = this.totalPrice - this.discountPrice;
					axios.post("ecommerce/coupon-apply-process", this.coupon).then(res => {
						//this.coupon_price = res.data;
						if (res.data.status == 200) {
							console.log(res);
							this.couponSucMsg = res.data.message;
							this.couponErMsg = "";
							this.coupon_total_price = res.data.total_coupon_price;
							let coupon = {
								total_price: res.data.total_coupon_price,
								coupon: res.data.coupon,
								coupon_id: res.data.coupon_id
							};
							this.$store.commit("couponInformations", coupon);
							this.coupon.coupon_code = "";
						}
						if (res.data.status == 302) {
							this.couponErMsg = res.data.message;
						}
						if (res.data.status == 301) {
							this.couponErMsg = res.data.message;
						}
						if (res.data.status == 201) {
							this.couponErMsg = res.data.message;
						}
					});
				} else {
					this.notLogin = true;
					this.$toastr("error", "You are not login. Please Login", "Error!!");
				}
			},
			removeCoupon() {
				this.$store.commit("removeCoupntInfo");
				this.coupon_total_price = 0.0;
				this.$toastr("success", "Coupon Removed Successfully", "Success!!");
			},
			priceUpdateToStoreCart() {
				let carts = this.$store.state.cart;

				carts.map(cart => {
					if (
						cart.id != null &&
						cart.color_select != null &&
						cart.size_select != null
					) {
						let stock_match = this.stocks.find(
							stock =>
								stock.product_id == cart.id &&
								stock.color == cart.color_select &&
								stock.size == cart.size_select
						);
						if (stock_match) {
							cart.price = stock_match.sales_price;
							cart.totalPrice = stock_match.sales_price * cart.qty;
						}
						console.log("mathc->product_id,color,size");
					} else if (cart.id != null && cart.color_select != null) {
						let stock_match = this.stocks.find(
							stock =>
								stock.product_id == cart.id && stock.color == cart.color_select
						);
						if (stock_match) {
							cart.price = stock_match.sales_price;
							cart.totalPrice = stock_match.sales_price * cart.qty;
						}
						console.log("mathc->product_id,color");
					} else if (cart.id != null && cart.size_select != null) {
						let stock_match = this.stocks.find(
							stock =>
								stock.product_id == cart.id && stock.size == cart.size_select
						);
						if (stock_match) {
							cart.price = stock_match.sales_price;
							cart.totalPrice = stock_match.sales_price * cart.qty;
						}
						console.log("mathc->product_id,size");
					} else {
						let stock_match = this.stocks.find(
							stock => stock.product_id == cart.id
						);
						if (stock_match) {
							cart.price = stock_match.sales_price;
							cart.totalPrice = stock_match.sales_price * cart.qty;
						}
						console.log("Only Product_id");
					}
					return cart;
				});
			},
			getStockList() {
				axios.get("ecommerce/get-stock-list").then(res => {
					this.stocks = res.data;
				});
			},
			getDiscountList() {
				axios.get("ecommerce/get-discount-list").then(res => {
					this.discounts = res.data;
				});
			}
		},
		mounted() {
			setTimeout(() => {
				this.$eventBus.$emit("ecomloading", false);
			}, 1000);
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		created() {
			this.$eventBus.$emit("ecomloading", true);
			this.getCart();
			this.getStockList();
			this.getDiscountList();
			setTimeout(() => {
				this.priceUpdateToStoreCart();
			}, 3000);
		},
		computed: {
			totalPrice() {
				let total = 0;
				this.$store.state.cart.forEach(cart => {
					total += parseFloat(cart.totalPrice);
				});
				return total ? total.toFixed(2) : 0;
			},
			discountPrice() {
				let total = 0;
				const current_date = new Date().toISOString().split("T")[0];

				this.$store.state.cart.forEach(cart => {
					if (cart.discount && cart.discount_id != null) {
						let discountLatest = this.discounts.find(
							discount =>
								discount.id == cart.discount_id && discount.product_id == cart.id
						);
						if (discountLatest) {
							cart.discount_start_date = discountLatest.start_date;
							cart.discount_end_date = discountLatest.end_date;

							if (discountLatest.discount_type == 1) {
								cart.discount_price = discountLatest.discount;
							}
							if (discountLatest.discount_type == 2) {
								cart.discount_price =
									(discountLatest.discount / 100) * cart.price;
							}

							if (
								cart.discount_start_date <= current_date &&
								cart.discount_end_date >= current_date
							) {
								total += parseFloat(cart.discount_price) * parseInt(cart.qty);
							}
						}
					}
				});
				return total ? total.toFixed(2) : 0;
			}
		}
	};
</script>

<style scoped lang="scss">
	.table {
		color: #495057;
		background: white;
		box-shadow: 0px 0px 3px #3344441f;
	}
	.input-group {
		input {
			border: 1px solid #cccccc;
			background: #ffffff;
			text-align: center;
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type="number"] {
			-moz-appearance: textfield;
		}

		.input-group-prepend,
		.input-group-append {
			.input-group-text {
				background: #ccc;
				color: black;
				border: 0;
				width: 35px;
				cursor: pointer;
			}
		}
	}
	.bg-blue-theme {
		background: #6fccc18c;
	}
	table.order-summary-table {
		border: 0px;
		padding: 0px;
		margin: 0px;
		th,
		td {
			border: 0;
			font-size: 13px;
		}
	}

	.coupon_code {
		input.coupon_code_input {
			border-color: #6dc8be;
			padding: 17px 22px;
			border-radius: 30px;
			font-size: 15px;
			text-align: left;
			&:focus {
				z-index: unset !important;
			}
			&::placeholder {
				text-align: left;
			}
		}
		button.coupn_apply_button {
			background: #6dc8be;
			border: 0px;
			padding: 10px 25px;
			position: absolute;
			right: -14px;
			top: -2px;
			box-shadow: 0px 0px 5px 0px #4444;
			border-radius: 30px;
		}
	}
</style>
