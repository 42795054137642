var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"page-banner py-3"},[_c('div',{staticClass:"container-wrapper"},[_c('span',{staticClass:"shape"}),_vm._v(" "),_c('span',{staticClass:"shape2"}),_vm._v(" "),_c('span',{staticClass:"shape3"}),_vm._v(" "),_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-lg-6"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb justify-content-end bg-transparent mb-0"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{staticClass:"breadcrumb-item",attrs:{"to":"/"}},[_vm._v("Home")])],1),_vm._v(" "),_vm._l((_vm.data),function(route,index){return _c('li',{key:index,staticClass:"breadcrumb-item text-capitalize"},[(
										index ==
											Object.keys(_vm.data).length - 1 ||
										!route.route_name
									)?_c('span',[_vm._v(_vm._s(_vm.getPostBody(route.title, 15)))]):(
										Object.keys(_vm.data).length - 1 ||
										!route.news_type
									)?_c('router-link',{staticClass:"breadcrumb-item",attrs:{"to":{
										name: route.route_name,
										params: { type: route.params },
									}}},[_vm._v(_vm._s(_vm.getPostBody(route.title, 15)))]):_c('router-link',{staticClass:"breadcrumb-item",attrs:{"to":{ name: route.route_name }}},[_vm._v(_vm._s(_vm.getPostBody(route.title, 15)))])],1)})],2)])])])])]),_vm._v(" "),_c('div',{staticClass:"content-body py-4"},[_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('router-view')],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6"},[_c('h3',{staticClass:"mb-0 text-capitalize"},[_vm._v("Career")])])
}]

export { render, staticRenderFns }