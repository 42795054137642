<template>
	<div id="example-content">
		<slot v-if="!$root.spinner">
			<slot v-if="table.datas && Object.keys(table.datas).length > 0">
				<div class="row" v-if="table.datas">
					<div
						class="col-lg-4 mb-3"
						v-for="(data, index) in table.datas"
						:key="index"
					>
						<div class="blog-card card shadow-sm">
							<div class="card-header bg-transparent border-0">
								<span class="shape"></span>
								<div class="event-logo">
									<img :src="basislogo" alt="basis logo" />
								</div>
								<div class="event-title">
									<!-- <h4>Professional</h4> -->
									<h3>{{ data.training_name }}</h3>
								</div>
							</div>
							<div class="card-body py-4">
								<p class="m-0">
									<strong>
										<i class="mdi mdi-calendar-check"></i> Apply last date:
									</strong>
									{{ data.training_last_date | formatDate("Do MMM YYYY") }}
								</p>
								<p class="m-0">
									<strong>
										<i class="mdi mdi-clock-outline"></i> Total Hours:
									</strong>
									{{ data.training_time }}
								</p>
								<p class="m-0">
									<strong>
										<i class="mdi mdi-clock-outline"></i> Training Period:
									</strong>
									{{ data.training_period }}
								</p>
								<p class="m-0">
									<strong>
										<i class="mdi mdi-map-marker"></i>
									</strong>
									{{ data.training_venue }}
								</p>
							</div>
							<div class="card-footer bg-transparent border-0 py-4">
								<div class="row">
									<div class="col-6">
										<big class="text-danger font-weight-bold"
											>Tk {{ data.training_fee_amount | currency }}</big
										>
									</div>
									<div class="col-6 text-right">
										<router-link
											:to="{
												name: 'TrainingSeminarDetails',
												params: { id: data.id, type: 'training' },
											}"
										>
											Details
											<i class="mdi mdi-arrow-right"></i>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- pagination -->
				<Pagination
					:url="'/get-training-seminar/training/' + $route.params.type"
					v-if="!$root.spinner"
				/>
				<!-- /pagination -->
			</slot>
			<div v-else class="text-center">
				<h5>
					<b>No training at this time</b>
				</h5>
			</div>
		</slot>
		<spinner v-if="$root.spinner" />
	</div>
</template>
<script>
	export default {
		name: "Training",
		data() {
			return {
				breadcrumbTitle: "Training",
				breadcumb: [{ title: "Training" }],
				basislogo: this.$root.baseurl + "/public/images/logo.svg",
				table: {
					datas: [],
				},
				pagination: [],
				meta: [],
				links: [],
			};
		},
		methods: {
			get_datas: function () {
				let url = "/get-training-seminar/training/" + this.$route.params.type;
				this.$root.spinner = true;
				axios
					.get(url)
					.then((response) => {
						this.table.datas = response.data.data;
						this.meta = response.data.meta;
						this.links = response.data.links;
						this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
						this.$root.spinner = false;
					})
					.catch((error) => {
						console.log(error);
					});
			},
		},
		mounted() {
			this.get_datas();
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
	};
</script>
