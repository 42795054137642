<template>
  <div id="example-content">
    <slot v-if="!$root.spinner">
      <fieldset
        class="publications-card border mb-3 p-3"
        v-for="(cat, index) in publication"
        :key="index"
      >
        <legend>{{cat.title}}</legend>
        <div class="row">
          <div class="col-lg-3 mb-4" v-for="(item, index1) in cat.publications" :key="index1">
            <a :href="$root.baseurl+'/public'+item.file" target="_blank">
              <div class="card rounded-10">
                <img
                  v-lazy="$root.baseurl+'/public'+item.image"
                  :alt="item.title"
                  style="height:200px;"
                />
                <div class="card-body" style="height: 98px;">
                  <small>{{item.title}}</small>
                </div>
              </div>
            </a>
          </div>
        </div>
      </fieldset>
    </slot>
    <spinner v-if="$root.spinner" />
  </div>
</template>

<script>
export default {
  name: "Publication",
  data() {
    return {
      breadcrumbTitle: "Publication",
      breadcumb: [{ title: "Publication" }],
      publication: {}
    };
  },
  methods: {
    get_data() {
      this.$root.spinner = true;
      axios
        .get("/get-publication")
        .then(respons => {
          this.publication = respons.data;
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
          this.$root.spinner = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  }
};
</script>
