<template>
  <div id="example-content">
    <slot v-if="!$root.spinner">
      <fieldset class="publications-card border mb-3 p-3" v-if="annualReport">
        <div class="row">
          <div class="col-lg-3 mb-4" v-for="(item, index1) in annualReport" :key="index1">
            <a :href="$root.baseurl+'/public'+item.file" target="_blank">
              <div class="card rounded-10">
                <img
                  v-lazy="$root.baseurl+'/public'+item.image"
                  :alt="item.title"
                  style="height:200px;"
                />
                <div class="card-body">
                  <small>{{item.title}}</small>
                </div>
              </div>
            </a>
          </div>
        </div>
      </fieldset>
    </slot>
    <spinner v-if="$root.spinner" />
  </div>
</template>

<script>
export default {
  name: "AnnualReport",
  data() {
    return {
      breadcrumbTitle: "Annual Report",
      breadcumb: [{ title: "Annual Report" }],
      annualReport: {}
    };
  },
  methods: {
    get_data() {
      this.$root.spinner = true;
      axios
        .get("/get-annual-report")
        .then(respons => {
          this.annualReport = respons.data;
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
          this.$root.spinner = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  }
};
</script>
