var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card sidebar shadow-sm mb-3"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"text-center"},[(_vm.$root.variable.aLogId)?_c('p',{staticClass:"font-weight-bold"},[_c('span',{staticClass:"text-danger"},[_vm._v("Login as:")]),_vm._v(" "),_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.$root.variable.aLogName))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"user"},[(_vm.progress)?_c('sup',{staticClass:"badge rounded-50 mt-2",class:_vm.progress.totalProgress >= 50 ? 'badge-success' : 'badge-danger'},[_vm._v(_vm._s(_vm.progress.totalProgress)+"% Completed")]):_vm._e(),_vm._v(" "),(_vm.memberInfo && _vm.memberInfo.logo)?_c('img',{staticClass:"memLogo",attrs:{"src":_vm.$root.baseurl + '/public' + _vm.memberInfo.logo,"alt":"logo"}}):_c('img',{staticClass:"memLogo",attrs:{"src":_vm.$root.baseurl + require('./../../../../assets/img/no_logo.gif')}}),_vm._v(" "),_c('div',{staticClass:"userName py-3"},[(_vm.memberInfo)?_c('h2',{staticClass:"text-uppercase"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.memberInfo.company_name)+"\n\t\t\t\t\t\t")]):_vm._e()])])])]),_vm._v(" "),_c('div',{staticClass:"card sidemenu shadow-sm mb-3"},[_c('div',{staticClass:"card-body p-0"},[_c('nav',{staticClass:"navbar navbar-expand-lg p-0"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"dashMenu"}},[_c('ul',{staticClass:"nav flex-column w-100"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'member-dashboard' }}},[_vm._v("Dashboard")])],1),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login && _vm.access && _vm.access['company'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'EditCompanyInfo' }}},[_vm._v("\n\t\t\t\t\t\t\t\t\tCompany Information\n\t\t\t\t\t\t\t\t\t"),(_vm.progress.single)?_c('sup',{staticClass:"badge rounded-50",class:_vm.progress.single.companyInfo >= 50
												? 'badge-success'
												: 'badge-danger'},[_vm._v(_vm._s(_vm.progress.single.companyInfo)+"%")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login &&
										_vm.access &&
										_vm.access['representative'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'RepresentativeInformation' }}},[_vm._v("\n\t\t\t\t\t\t\t\t\tRepresentative Information\n\t\t\t\t\t\t\t\t\t"),(_vm.progress.single)?_c('sup',{staticClass:"badge rounded-50",class:_vm.progress.single.representative >= 50
												? 'badge-success'
												: 'badge-danger'},[_vm._v(_vm._s(_vm.progress.single.representative)+"%")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login && _vm.access && _vm.access['business'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'BusinessActivityInformation' }}},[_vm._v("\n\t\t\t\t\t\t\t\t\tBusiness Activity Information\n\t\t\t\t\t\t\t\t\t"),(_vm.progress.single)?_c('sup',{staticClass:"badge rounded-50",class:_vm.progress.single.businessActivity >= 50
												? 'badge-success'
												: 'badge-danger'},[_vm._v(_vm._s(_vm.progress.single.businessActivity)+"%")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login && _vm.access && _vm.access['vertical_exp'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'VerticalExperienceInformation' }}},[_vm._v("\n\t\t\t\t\t\t\t\t\tVertical Experience Information\n\t\t\t\t\t\t\t\t\t"),(_vm.progress.single)?_c('sup',{staticClass:"badge rounded-50",class:_vm.progress.single.verticalExpertise >= 50
												? 'badge-success'
												: 'badge-danger'},[_vm._v(_vm._s(_vm.progress.single.verticalExpertise)+"%")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login &&
										_vm.access &&
										_vm.access['product_service'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'ProductsServices' }}},[_vm._v("\n\t\t\t\t\t\t\t\t\tProducts & Services Information\n\t\t\t\t\t\t\t\t\t"),(_vm.progress.single)?_c('sup',{staticClass:"badge rounded-50",class:_vm.progress.single.products >= 50
												? 'badge-success'
												: 'badge-danger'},[_vm._v(_vm._s(_vm.progress.single.products)+"%")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login && _vm.access && _vm.access['compliance'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'ComplianceInfo' }}},[_vm._v("\n\t\t\t\t\t\t\t\t\tCompliance Information\n\t\t\t\t\t\t\t\t\t"),(_vm.progress.single)?_c('sup',{staticClass:"badge rounded-50",class:_vm.progress.single.compliance >= 50
												? 'badge-success'
												: 'badge-danger'},[_vm._v(_vm._s(_vm.progress.single.compliance)+"%")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login && _vm.access && _vm.access['photos_file'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'uploadPhotosFiles' }}},[_vm._v("\n\t\t\t\t\t\t\t\t\tUpload Photos & Files\n\t\t\t\t\t\t\t\t\t"),(_vm.progress.single)?_c('sup',{staticClass:"badge rounded-50",class:_vm.progress.single.photoFiles >= 50
												? 'badge-success'
												: 'badge-danger'},[_vm._v(_vm._s(_vm.progress.single.photoFiles)+"%")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login &&
										_vm.access &&
										_vm.access['change_request'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'changeRequest' }}},[_vm._v("Change Request")])],1):_vm._e(),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login &&
										_vm.access &&
										_vm.access['payment_history'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'PaymentHistory' }}},[_vm._v("Payment History")])],1):_vm._e(),_vm._v(" "),(_vm.$root.variable.aLogId)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'voterInfo' }}},[_vm._v("Voter Information")])],1):_vm._e(),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login &&
										_vm.access &&
										_vm.access['online_helpdesk'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'onlineHelpdesk' }}},[_vm._v("\n\t\t\t\t\t\t\t\t\tOnline Helpdesk\n\t\t\t\t\t\t\t\t\t"),_c('sup',{staticClass:"badge badge-danger rounded-50"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.countHelpDesk)+"\n\t\t\t\t\t\t\t\t\t")])])],1):_vm._e(),_vm._v(" "),(
									_vm.$root.variable.rep_login ||
									_vm.$root.variable.aLogId ||
									(_vm.$root.variable.alt_login && _vm.access && _vm.access['social_links'])
								)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'memberSettings' }}},[_vm._v("Social Links")])],1):_vm._e(),_vm._v(" "),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'changePassword' }}},[_vm._v("Change Password")])],1),_vm._v(" "),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Logout")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler m-2",attrs:{"type":"button","data-toggle":"collapse","data-target":"#dashMenu","aria-controls":"dashMenu","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"fas fa-bars pr-1"}),_vm._v("\n\t\t\t\t\t\tProfile Menu\n\t\t\t\t\t")])
}]

export { render, staticRenderFns }