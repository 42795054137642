<template>
  <div
    v-if="!$parent.loading && member.info && member.info.status == 1"
    class="row"
  >
    <div class="col-lg-12">
      <div class="card shadow-sm">
        <div class="card-header bg-transparent">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">Training</h5>
        </div>
        <div class="card-body">
          <div class="alert alert-danger" role="alert" v-if="!showServiceForm">
            <h4 class="alert-heading">Warning!</h4>
            <p>This service is not availabe</p>
            <hr />
          </div>
          <form
            v-on:submit.prevent="submit"
            id="form"
            class="form-row"
            v-if="showServiceForm"
          >
            <div class="form-group col-lg-4">
              <label class="control-label">
                Training Name:
                <span class="text-danger requierd-star"></span>
              </label>
              <input
                type="text"
                readonly
                v-model="training_name"
                placeholder="Training Name"
                onfocus="Training Name"
                onblur="Training Name"
                class="form-control shadow-none"
              />
              <div class="invalid-feedback">
                {{ validation.firstError("data.training_name") }}
              </div>
            </div>
            <div class="form-group col-lg-12"></div>
            <div class="form-group col-lg-4">
              <label class="control-label">
                Company Name
                <span class="text-danger requierd-star"></span>
              </label>
              <input
                type="text"
                v-if="member.info"
                v-model="member.info.company_name"
                class="form-control"
                readonly
              />
              <div class="invalid-feedback">
                {{ validation.firstError("member.info.company_name") }}
              </div>
            </div>
            <div class="form-group col-lg-4">
              <label class="control-label">
                BASIS Membership ID:
                <span class="text-danger requierd-star"></span>
              </label>
              <input
                type="text"
                v-if="member.info"
                v-model="member.info.membership_no"
                readonly
                placeholder="BASIS Membership ID"
                class="form-control shadow-none"
              />
            </div>
            <div class="col-lg-12"></div>
            <div class="form-group col-lg-4">
              <label class="control-label">
                Participant Name:
                <span class="text-danger requierd-star"></span>
              </label>
              <input
                type="text"
                v-model="data.applicant_name"
                placeholder="Name of Contact Person"
                class="form-control shadow-none"
                :class="{
                  'is-invalid': validation.hasError('data.applicant_name'),
                  'is-valid': data.applicant_name,
                }"
              />
              <div class="invalid-feedback">
                {{ validation.firstError("data.applicant_name") }}
              </div>
            </div>
            <div class="form-group col-lg-4">
              <label class="control-label">
                Participant Email:
                <span class="text-danger requierd-star"></span>
              </label>
              <input
                type="email"
                v-model="data.email"
                placeholder="Participant Email"
                class="form-control shadow-none"
                :class="{
                  'is-invalid': validation.hasError('data.email'),
                  'is-valid': data.email,
                }"
              />
              <div class="invalid-feedback">
                {{ validation.firstError("data.email") }}
              </div>
            </div>
            <div class="form-group col-lg-4">
              <label class="control-label">
                Participant Number:
                <span class="text-danger requierd-star"></span>
              </label>
              <input
                type="number"
                v-model="data.phone"
                placeholder="Participant Number"
                class="form-control shadow-none"
                :class="{
                  'is-invalid': validation.hasError('data.phone'),
                  'is-valid': data.phone,
                }"
              />
              <div class="invalid-feedback">
                {{ validation.firstError("data.phone") }}
              </div>
            </div>
            <div class="form-group col-lg-12 text-center">
              <button name type="submit" class="btn btn-primary">Next</button>
            </div>
          </form>
        </div>
      </div>
      <!-- <pre>{{data}}</pre> -->
    </div>
  </div>
  <div v-else-if="member.info && member.info.status == 0" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! your account is suspend
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  name: "Training",
  data() {
    return {
      declaration: false,
      showServiceForm: false,
      data: { amount: 0 },
      training_name: null,
      errors: {},
      member: {
        info: null,
      },
    };
  },
  methods: {
    submit: function () {
      this.data.form_type = this.$route.name;
      this.$validate().then((res) => {
        if (this.validation.countErrors() > 0) {
          this.$toastr(
            "warning",
            'You need to fill <span class="font-weight-bold">' +
              this.validation.countErrors() +
              " more empty</span> mandatory fields",
            "Empty Mandatory Fields!!"
          );
        } else {
          if (res) {
            this.$parent.loading = true;
            axios
              .post("/member/store-company-info", this.data)
              .then((response) => {
                this.$toastr(
                  "success",
                  "Your information has been submitted, please payment",
                  "Success!!"
                );
                if (response.data) {
                  localStorage.setItem("recp_id", response.data.recp_id);
                  localStorage.setItem("serv_type", "training");
                  this.$router.push({
                    name: "paymentMethod",
                    params: {
                      id: this.data.service_id,
                      amount: this.data.amount,
                    },
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .then((alw) => {
                this.$parent.loading = false;
              });
          }
        }
      });
    },
    getServiceInfo() {
      axios
        .get("/member/get-traininginfo/" + this.$route.params.id)
        .then((res) => {
          this.showServiceForm = res.data;
        });
    },
  },
  created() {
    this.loggedIn();
    setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);
    this.data.service_id = this.$route.params.id;
    axios
      .get("/member/get-training/" + this.$route.params.id)
      .then((response) => {
        this.training_name = response.data.training_name;
        this.data.amount = response.data.training_fee_amount;
      });
    this.getServiceInfo();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  validators: {
    // "data.training_name":function (value = null) {
    //   return Validator.value(value)
    //       .required("Traing Name  is required")
    // },
    "member.info.company_name": function (value = null) {
      return Validator.value(value).required("Company Name  is required");
    },
    "member.info.membership_no": function (value = null) {
      return Validator.value(value).required(
        "BASIS Membership ID  is required"
      );
    },
    "data.applicant_name": function (value = null) {
      return Validator.value(value).required("Participant Name is required");
    },
    "data.email": function (value = null) {
      return Validator.value(value)
        .required("Participant Email is required")
        .email();
    },
    "data.phone": function (value = null) {
      return Validator.value(value)
        .required("Participant Phone is required")
        .digit()
        .regex("01+[0-9]*$", "Must start with 01.")
        .minLength(11)
        .maxLength(11);
    },
  },
};
</script>

