<template>
  <div id="example-content">
    <slot v-if="!$root.spinner">
      <div class="basis-member staff">
        <!--Heading-->
        <h5 class="page-title font-weight-bold text-uppercase">{{ breadcrumbTitle }}</h5>

        <div class="accordion" id="accordionExample">
          <slot v-if="Object.keys(table.datas).length>0">
            <div class="card shadow-lg" v-for="(data ,index,key) in table.datas" :key="index">
              <div class="card-header" :id="'heading'+index">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link text-dark"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapse'+index"
                    aria-expanded="false"
                    :aria-controls="'collapse'+index"
                  >
                    {{ data.title }}
                    <span
                      class="float-right text-primary"
                    >View Member list &nbsp;&nbsp;</span>
                  </button>
                </h2>
              </div>

              <div
                :id="'collapse'+index"
                :class="key?'collapse':'collapse'"
                :aria-labelledby="'heading'+index"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <table class="table table-sm table-bordered table-striped">
                    <!-- <thead class="thead-dark">
                    <tr>
                      <th class="text-center">Sl.</th>
                      <th>Name / </th>
                      <th>Action</th>
                    </tr>
                    </thead>-->
                    <tbody>
                      <slot v-if="!$root.spinner">
                        <tr v-for="(person,index2) in data.description" :key="'A'+index2">
                          <td class="text-center" v-if="meta">{{ index2+1 }}</td>
                          <td>
                            <b>{{ person.name }}</b>
                            <br />
                            {{ person.designation_company }}
                          </td>
                          <td>{{ person.position }}</td>
                        </tr>
                      </slot>
                    </tbody>
                    <tr v-if="$root.spinner">
                      <td colspan="3">
                        <spinner />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </slot>
          <div v-else class="col-12" style="text-align:center;">No record found</div>
        </div>
      </div>
      <!-- pagination -->
      <Pagination v-if="Object.keys(table.datas).length>0" :url="'get-scf/'+$route.params.type" />
      <!-- /pagination -->
    </slot>
    <spinner v-if="$root.spinner" />
  </div>
</template>

<script>
export default {
  name: "Policy",
  data() {
    return {
      breadcrumbTitle: this.$route.params.type,
      breadcumb: [{ title: this.$route.params.type }],
      table: {
        datas: []
      },
      meta: [],
      links: []
    };
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    },
    "$route.path": function(val, oldVal) {
      this.get_data(this.$route.params.type);
      this.$root.loading = false;
    }
  },
  methods: {
    asyncData() {
      this.$root.loading = false;
      this.get_data(this.$route.params.type);
    },
    get_data(type) {
      this.$root.spinner = true;
      axios
        .get("/get-scf/" + type)
        .then(respons => {
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          if (this.$route.params.type == "forums") {
            var title = "Forums & Focus Group";
          } else {
            var title = this.$route.params.type;
          }
          this.breadcrumbTitle = title;
          this.breadcumb = [{ title: title }];
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
          this.$root.spinner = false;
        })
        .catch(error => {
          console.log(error);
        })
        .then(res => {
          this.$root.spinner = false;
        });
    }
  },
  mounted() {},
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  }
};
</script>

<style scoped>
.sidebar-menu.vue-affix {
  top: 0px !important;
  width: 280px;
  animation: FadeUp 1s ease forwards;
}
.sidebar-menu.vue-affix.affix {
  top: 70px !important;
  z-index: 1030;
}

@keyframes FadeUp {
  form {
    top: 0;
  }
  to {
    top: 70px !important;
  }
}
</style>
