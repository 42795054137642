<template>
  <div class="marketPage">
    <PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
    <div class="content-body py-4">
      <div class="container-wrapper">
        <div class="row">
          <div class="col-lg-12">
            <div id="example-content">
              <div class="market-item">
                <div class="row">
                  <slot v-for="(market,index) in table.datas">
                    <router-link
                      v-if="market.title == 'Events' || market.title == 'Awards'"
                      :to="{name:'allEvents', params:{market:'international',type: (market.title == 'Events')?'events':'awards'}}"
                      :key="index"
                      class="col-lg-4"
                      data-aos="fade-up"
                      data-aos-offset="100"
                      :data-aos-duration="500+(index*200)"
                    >
                      <div class="card card-body shadow-sm rounded-10 mb-3">
                        <span class="shape"></span>
                        <h3 class="text-black">{{market.title}}</h3>
                      </div>
                    </router-link>
                    <a
                      v-else-if="market.id == 2"
                      href="http://nbr.gov.bd/nbr-enlisted/eng"
                      target="_blank"
                      :key="index+'a'"
                      class="col-lg-4"
                      data-aos="fade-up"
                      data-aos-offset="100"
                      :data-aos-duration="500+(index*200)"
                    >
                      <div class="card card-body shadow-sm rounded-10 mb-3">
                        <span class="shape"></span>
                        <h3 class="text-black">{{market.title}}</h3>
                      </div>
                    </a>
                    <a
                      v-else-if="market.id == 3"
                      href="https://basisegovhub.info"
                      target="_blank"
                      :key="index+'a'"
                      class="col-lg-4"
                      data-aos="fade-up"
                      data-aos-offset="100"
                      :data-aos-duration="500+(index*200)"
                    >
                      <div class="card card-body shadow-sm rounded-10 mb-3">
                        <span class="shape"></span>
                        <h3 class="text-black">{{market.title}}</h3>
                      </div>
                    </a>
                    <router-link
                      v-else-if="market.title == 'B2B'"
                      to="/b2b"
                      :key="index+'b'"
                      class="col-lg-4"
                      data-aos="fade-up"
                      data-aos-offset="100"
                      :data-aos-duration="500+(index*200)"
                    >
                      <div class="card card-body shadow-sm rounded-10 mb-3">
                        <span class="shape"></span>
                        <h3 class="text-black">{{market.title}}</h3>
                      </div>
                    </router-link>
                    <router-link
                      v-else
                      :to="{ name:'market_detail', params: { id: market.id }}"
                      :key="index+'c'"
                      class="col-lg-4"
                      data-aos="fade-up"
                      data-aos-offset="100"
                      :data-aos-duration="500+(index*200)"
                    >
                      <div class="card card-body shadow-sm rounded-10 mb-3">
                        <span class="shape"></span>
                        <h3 class="text-black">{{market.title}}</h3>
                      </div>
                    </router-link>
                  </slot>
                  <a
                    v-if="this.$route.params.type != 'InternationalMarket'"
                    :href="$root.baseurl+'/public/files/publication/5e125f5ee61760449abd28a25178d046facfec994d97e.pdf'"
                    target="_blank"
                    class="col-lg-4"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    :data-aos-duration="500+(index*200)"
                  >
                    <div class="card card-body shadow-sm rounded-10 mb-3">
                      <span class="shape"></span>
                      <h3 class="text-black">Software and IT Service Catalog</h3>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageBanner from "../../components/elements/PageBanner";
import leftmenu from "../../components/elements/leftmenu";
import axios from "axios";

const routesname = {
  view: "slider_content.show"
};
export default {
  name: "About",
  components: {
    PageBanner,
    leftmenu
  },
  data() {
    return {
      breadcrumbTitle: "",
      breadcumb: [{ title: "" }],
      fields_name: { 0: "Select One", description: "Description" },
      search_data: {
        field_name: 0,
        value: ""
      },
      table: {
        title: "Simple Table",
        routes: routesname,
        datas: []
      },
      pagination: [],
      meta: [],
      links: []
    };
  },
  methods: {
    asyncData() {
      this.get_datas();
    },
    get_datas: function(pageindex) {
      let url = "/get-market/" + this.$route.params.type;
      if (pageindex > 0) {
        url = "/get-market?page=" + pageindex;
      }
      axios
        .get(url)
        .then(respons => {
          if (this.$route.params.type == "InternationalMarket") {
            this.breadcrumbTitle = "International Market";
          } else {
            this.breadcrumbTitle = "Local Market";
          }
          this.breadcumb = [{ title: this.breadcrumbTitle }];
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          this.$parent.debug_data = respons;
        })
        .catch(error => {
          alert(error);
          console.log(error);
        })
        .then(res => {
          setTimeout(
            () => (this.$root.loading = false),
            this.$root.minloadtimefd
          );
        });
    }
  },
  mounted() {},
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    }
  }
};
</script>

<style>
.marketPage .card {
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100px;
  overflow: hidden;
  border: 1px solid #c4ddda;
}
.marketPage .card:hover {
  background: #e2f4f275;
}
</style>
