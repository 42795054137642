<template>
  <div class="pdf-form" v-if="!$root.spinner">
    <div class="form-row col-12">
      <div
        class="form-group col-6 float-left"
        v-if="$route.params.paymentOption || (data && data.proceed_to_pay == 1)"
      >
        <div class="text-left">
          <button
            v-if="data && data.payment_method"
            @click="download"
            class="btn btn-success"
          >
            <span class="mdi mdi-file-pdf"></span> Download PDF
          </button>
        </div>
      </div>
      <div class="form-group col-6 float-right">
        <div
          class="text-right"
          v-if="
            $route.params.paymentOption || (data && data.proceed_to_pay == 1)
          "
        >
          <button @click="payment" class="btn btn-info">
            ৳ Proceed to Payment
          </button>
        </div>
      </div>
    </div>
    <div ref="content" class="member-services">
      <div class="container mt-5">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <h2>
                Bangladesh Association of Software and Information Services
              </h2>
              <h3>Application for Membership</h3>
            </div>

            <br />
            <br />
          </div>
        </div>
        <h4 class="text-color">Company information:</h4>
        <div
          v-if="data"
          class="container pt-5"
          style="border-top: 3px solid gray"
        >
          <div class="row" v-if="data.company_info">
            <div class="col-5 col-lg-5">
              <p>Name of the Company/Organization:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.company_info.company_name }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Compnay Type:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.company_info.company_type }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Year of establishment:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.company_info.established_year }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Membership with other association and chamber:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.company_info.membership_other_association }}</p>
            </div>

            <div class="col-5 col-lg-5">
              <p>Registered address:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.company_info.registered_address }}</p>
            </div>

            <div class="col-5 col-lg-5">
              <p>Postal Code:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.company_info.post_code }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Phone/Mobile:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.company_info.phone }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>District:</p>
            </div>
            <div class="col-7 col-lg-7" v-if="$root.variable.districts">
              <p>{{ $root.variable.districts[data.company_info.district] }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Website:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.company_info.website }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Certificate of Incorporation:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p v-if="data.images && data.images.certificate_at_incorporation">
                Attached
              </p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Memorandum of Articles:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p v-if="data.images && data.images.memo_article">Attached</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Partnership Deed:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p v-if="data.images && data.images.partnership_deed">Attached</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Tin Certificate:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p v-if="data.images && data.images.tin_certificate_company">
                Attached
              </p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Trade License:</p>
            </div>
            <div class="col-7 col-lg-7" v-if="data.images">
              <p
                v-for="(trade, index) in data.images.trade_license"
                :key="index"
              >
                <span v-if="trade">Attached {{ index + 1 }} license</span>
              </p>
            </div>
            <div class="col-5 col-lg-5">
              <p>BIN Number:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.company_info.bin_number }}</p>
            </div>
          </div>

          <div class="container mt-5">
            <h4 class="text-color">Shareholder Information:</h4>
          </div>

          <div
            v-if="
              (data.shareholder &&
                data.company_info.company_type == 'Partnership') ||
              data.company_info.company_type == 'Limited Company'
            "
            class="container pt-5"
            style="border-top: 3px solid gray"
          >
            <div class="row">
              <div class="col-5 col-lg-5">
                <p>Shareholder Name:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.shareholder_primary.name }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Percentage(%):</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.shareholder_primary.percent }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Is shareholder company listed in stockexchange?:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.shareholder_primary.stock_exchange }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Country name of the stock exchange:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.shareholder_primary.country }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-5 col-lg-5">
                <p>Any Women Shareholder?:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.company_info.women_shareholder }}</p>
              </div>
            </div>
            <slot v-if="data.company_info.women_shareholder == 'Yes'">
              <div
                class="row pb-2"
                style="border-bottom: 1px solid #ccc"
                v-for="(share, index) in data.shareholder"
                :key="index"
              >
                <div class="col-5 col-lg-5">
                  <p>Name:</p>
                </div>
                <div class="col-7 col-lg-7">
                  <p>{{ share.name }}</p>
                </div>
                <div class="col-5 col-lg-5">
                  <p>Designation:</p>
                </div>
                <div class="col-7 col-lg-7">
                  <p>{{ share.designation }}</p>
                </div>
                <div class="col-5 col-lg-5">
                  <p>Percent:</p>
                </div>
                <div class="col-7 col-lg-7">
                  <p>{{ share.percent }}</p>
                </div>
              </div>
            </slot>
          </div>

          <div class="container mt-5">
            <h4 class="text-color">Board of Director's:</h4>
          </div>

          <div
            v-if="
              data.board_of_director &&
              data.company_info.company_type == 'Limited Company'
            "
            class="container pt-5"
            style="border-top: 3px solid gray"
          >
            <div
              class="row pb-2"
              style="border-bottom: 1px solid #ccc"
              v-for="(board, index) in data.board_of_director"
              :key="index"
            >
              <div class="col-5 col-lg-5">
                <p>Name:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ board.name }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Designation:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ board.designation }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Email:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ board.email }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Mobile:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ board.mobile }}</p>
              </div>
            </div>
          </div>

          <div class="container mt-5">
            <h4 class="text-color">Organization Head:</h4>
          </div>

          <div class="container pt-5" style="border-top: 3px solid gray">
            <div class="row" v-if="data.organization_head">
              <div class="col-5 col-lg-5">
                <p>Name:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.organization_head.name }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Designation:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.organization_head.designation }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Direct phone:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.organization_head.direct_phone }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Mobile:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.organization_head.mobile }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>E-mail:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.organization_head.email }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>LinkedIn:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.organization_head.linkedin }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>PP size photo:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p v-if="data.images && data.images.org_image">
                  <img
                    v-lazy="$root.baseurl + '/public/' + data.images.org_image"
                    height="80px"
                    width="80px"
                    alt="org_image"
                  />
                </p>
              </div>
            </div>
          </div>
          <h4 class="text-color">BASIS Representative:</h4>
        </div>
        <div class="container pt-5" style="border-top: 3px solid gray">
          <div class="row" v-if="data.representative">
            <div class="col-5 col-lg-5">
              <p>Name:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.representative.name }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Designation:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.representative.designation }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Direct phone:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.representative.direct_phone }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Mobile:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.representative.mobile }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>E-mail:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.representative.email }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>LinkedIn:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.representative.linkedin }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>National ID no:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.representative.nid }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Tax Identification no:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.representative.tin }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Passport No:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p>{{ data.representative.passport }}</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>PP size photo:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p v-if="data.images && data.images.rep_image">
                <img
                  v-lazy="$root.baseurl + '/public/' + data.images.rep_image"
                  height="80px"
                  width="80px"
                  alt="rep_image"
                />
              </p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Representative Signature</p>
            </div>
            <div class="col-7 col-lg-7">
              <p v-if="data.images && data.images.representative_sign">
                <img
                  v-lazy="
                    $root.baseurl + '/public/' + data.images.representative_sign
                  "
                  height="40px"
                  width="80px"
                  alt="representative_sign"
                />
              </p>
            </div>
            <div class="col-5 col-lg-5">
              <p>CV in PDF:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p v-if="data.images && data.images.rep_pdf">Attached</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Board Resolution:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p v-if="data.images && data.images.board_resolution">Attached</p>
            </div>
            <div class="col-5 col-lg-5">
              <p>Tin Certificate:</p>
            </div>
            <div class="col-7 col-lg-7">
              <p v-if="data.images && data.images.tin_certificate_rep">
                Attached
              </p>
            </div>
          </div>
          <h4 class="text-color">
            Alternative contact for general communications:
          </h4>
          <div class="container pt-5" style="border-top: 3px solid gray">
            <div class="row" v-if="data.alternative && data.alternative.name">
              <div class="col-5 col-lg-5">
                <p>Name:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.alternative.name }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Designation:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.alternative.designation }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Mobile:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.alternative.phone }}</p>
              </div>
              <div class="col-5 col-lg-5">
                <p>Email:</p>
              </div>
              <div class="col-7 col-lg-7">
                <p>{{ data.alternative.email }}</p>
              </div>
            </div>
            <div class="row" v-else>
              <span class="text-danger"
                >No alternative contact for general communications</span
              >
              <br />
              <br />
            </div>
          </div>
          <h4 class="text-color">Area of business activity:</h4>
          <div class="container pt-1" style="border-top: 3px solid gray">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
              <div class="ml-5">
                <ul v-if="data.business_activity">
                  <li
                    v-for="(activity, index) in data.business_activity
                      .activities"
                    :key="index"
                  >
                    <span v-if="activity_list">{{ activity_list[index] }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <slot v-if="data.business_activity.applying_for == 'Affiliate'">
              <br />
              <br />
              <h4 class="text-color">
                Which of the following category or platform is your main
                activity?
              </h4>
              <div class="container pt-2" style="border-top: 3px solid gray">
                <h5 class="right">
                  {{ data.company_info.affiliate_category }}
                </h5>
              </div>
            </slot>
            <br />
            <br />
            <h4 class="text-color">I am applying for:</h4>
            <div class="container pt-2" style="border-top: 3px solid gray">
              <h5 class="right">{{ data.business_activity.applying_for }}</h5>
              <div class="rt">
                (Accordingly, we are enclosing the required fees.)
              </div>
            </div>
            <br />
            <br />
            <h4 class="text-color">Business Primary Activity:</h4>
            <div class="container pt-2" style="border-top: 3px solid gray">
              <div class="row" v-if="data.primary_activity">
                <div class="col-5 col-lg-5">
                  <p>Priority 1:</p>
                </div>
                <div class="col-7 col-lg-7">
                  <p v-if="activity_list">
                    {{ activity_list[data.primary_activity.priority1] }}
                  </p>
                </div>
                <div class="col-5 col-lg-5">
                  <p>Priority 2:</p>
                </div>
                <div class="col-7 col-lg-7">
                  <p v-if="activity_list">
                    {{ activity_list[data.primary_activity.priority2] }}
                  </p>
                </div>
                <div class="col-5 col-lg-5">
                  <p>Priority 3:</p>
                </div>
                <div class="col-7 col-lg-7">
                  <p v-if="activity_list">
                    {{ activity_list[data.primary_activity.priority3] }}
                  </p>
                </div>
              </div>

              <br />
              <br />
              <p>IT Enabled Service (ITES) provider additional categories:</p>
              <div class="col-lg-6"></div>
              <div class="col-lg-6">
                <div class="ml-5">
                  <ul v-if="data.primary_activity">
                    <li
                      v-for="(category, index) in data.primary_activity
                        .categories"
                      :key="index"
                    >
                      <span v-if="category_list">{{
                        category_list[index]
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <br />
              <h5>Your industry expertise:</h5>
              <br />
              <table>
                <tr>
                  <th>Expertise</th>
                  <th>No of Clients</th>
                  <th>Remarks</th>
                </tr>
                <slot v-if="data.primary_activity.expertise_lists">
                  <tr
                    v-for="(expertise, index) in data.primary_activity
                      .expertise_lists"
                    :key="index"
                  >
                    <td>
                      <span v-if="expertises_lists">{{
                        expertises_lists[expertise.expertiseName]
                      }}</span>
                    </td>
                    <td>{{ expertise.no_of_clients }}</td>
                    <td>{{ expertise.remarks }}</td>
                  </tr>
                </slot>
              </table>
              <br />
              <br />
              <h4 class="text-color">Files & Others:</h4>
              <div class="container pt-2" style="border-top: 3px solid gray">
                <div class="row">
                  <div class="col-5 col-lg-5">
                    <p>
                      Company Profile:
                      <span>
                        <!-- <small>Describing infrastructure, Human Resource, Business Acti vity, C apability and Experience of the Organizi ng/Company</small> -->
                      </span>
                    </p>
                  </div>
                  <div class="col-7 col-lg-7">
                    <p
                      class="ml-5"
                      v-if="data.images && data.images.company_profile_pdf"
                    >
                      Attached
                    </p>
                  </div>

                  <div class="col-5 col-lg-5">
                    <p>Work Order First:</p>
                  </div>
                  <div class="col-7 col-lg-7">
                    <p
                      class="ml-5"
                      v-if="data.images && data.images.work_order_first"
                    >
                      Attached
                    </p>
                  </div>
                  <div class="col-5 col-lg-5">
                    <p>Work Order Second:</p>
                  </div>
                  <div class="col-7 col-lg-7">
                    <p
                      class="ml-5"
                      v-if="data.images && data.images.work_order_second"
                    >
                      Attached
                    </p>
                  </div>
                  <div class="col-5 col-lg-5">
                    <p>Work Order Third:</p>
                  </div>
                  <div class="col-7 col-lg-7">
                    <p
                      class="ml-5"
                      v-if="data.images && data.images.work_order_third"
                    >
                      Attached
                    </p>
                  </div>
                  <!-- <div class="col-5 col-lg-5">
                    <p>Signature Person Name</p>
                  </div>-->
                  <!-- <div class="col-7 col-lg-7">
                    <p class="ml-5">{{data.files_others.signature_person_name}}</p>
                  </div>-->

                  <!-- <div class="col-5 col-lg-5">
                    <p>Signature person Designation</p>
                  </div>
                  <div class="col-7 col-lg-7">
                    <p class="ml-5">{{data.files_others.signature_person_designation}}</p>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row col-12 mt-5">
        <div
          class="form-group col-6 float-left"
          v-if="
            $route.params.paymentOption || (data && data.proceed_to_pay == 1)
          "
        >
          <div class="text-left">
            <button
              v-if="data && data.payment_method"
              @click="download"
              class="btn btn-success"
            >
              <span class="mdi mdi-file-pdf"></span> Download PDF
            </button>
          </div>
        </div>
        <div class="form-group col-6 float-right">
          <div
            class="text-right"
            v-if="
              $route.params.paymentOption || (data && data.proceed_to_pay == 1)
            "
          >
            <button @click="payment" class="btn btn-info">
              ৳ Proceed to Payment
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>{{invoiceNo}}</pre> -->
    <!-- <pre>{{data}}</pre> -->
  </div>
</template>
<script>
import Promise from "bluebird";

export default {
  name: "PDFForm",
  data() {
    return {
      title: "Invoice",
      invoiceNo: null,
      category_list: [],
      expertises_lists: [],
      activity_list: [],
      data: {
        company_info: { district: null },
        organization_head: {},
        representative: {},
        business_activity: { activities: {} },
        primary_activity: {
          categories: {},
        },
        files_others: {},
      },
      category_sorted: [],
      images: {
        org_image: null,
        org_file: null,
        rep_image: null,
        rep_file: null,
        rep_pdf: null,
        repp_file: null,
        company_profile_pdf: null,
        board_resolution: null,
        work_order_first: null,
        work_order_second: null,
        work_order_third: null,
      },
    };
  },
  methods: {
    payment() {
      this.$router.push({
        name: "reg-member.dashboardForm",
        params: { paymentOption: true },
      });
    },
    download() {
      window.location.href = this.$root.baseurl + "/reg-member/membership-form";
    },
    getProfile: function () {
      this.$root.spinner = true;
      axios
        .get("/reg-member/get-profile-info")
        .then((response) => {
          if (Object.keys(response.data).length > 0) {
            this.data = response.data;
            this.$root.spinner = false;
          }
        })
        .catch((error) => {});
    },
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  mounted() {
    this.getProfile();
    // business activity list
    axios.get("/reg-member/getActivityist").then((response) => {
      this.activity_list = response.data;
    });
    axios.get("/reg-member/getCategories").then((response) => {
      this.category_list = response.data;
    });
    // Member Expertise List
    axios.get("/reg-member/getExpertiseList2").then((response) => {
      this.expertises_lists = response.data;
    });
    Promise.delay(1000).then(function () {});
  },
};
</script>
<style>
.basis-text {
  margin-top: 10px;
}

.basis-text p {
  font-size: 18px;
  color: black;
  text-align: justify;
}

.basis-text h4 {
  font-weight: bold;
}

.text-list {
  font-size: 20px;
}

.logo {
  text-align: right;
}

.p2 {
  font-size: 17px;
  columns: #000000;
}

.p6 {
  text-align: center;
}

.text-6 {
  text-align: center;
}

.text-color {
  color: darkgreen;
}

.col-border {
  border-bottom: 2px solid gray;
}

.col-border {
  margin-top: 160px;
}

.information p {
  font-size: 18px;
}

.text-list ul li {
  font-size: 17px;
}

.right {
  text-align: center;
}

.rt {
  text-align: center;
}

.mleft {
  margin-left: 70px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
