<template>
	<div class="ecommerce-header">
		<div
			class="page-banner border-bottom-0"
			style="overflow: unset;background: #fff;box-shadow: 0px 0px 2px 1px #4d4d4d5c;"
		>
			<div class="container-wrapper">
				<div class="row align-items-center">
					<div class="col-lg-3 text-center mb-lg-0 mb-3">
						<router-link :to="{ name: 'ecommerce.home' }">
							<img style="height: 70px;" :src="$root.baseurl + '/public/images/basis-eshop.png'" alt />
						</router-link>
					</div>
					<div class="col-lg-5">
						<form
							style="margin-bottom:3px!important"
							ref="globalSearch"
							id="globalSearch"
							v-on:submit.prevent="globalProductSearch"
							class="ecommerce-global-global-search"
						>
							<input
								v-on:keyup="globalProductSearch()"
								v-model="search_data"
								type="search"
								placeholder="Search"
								name="product_search"
							/>
							<div class="icon">
								<button class="border-0">
									<i class="fa fa-search"></i>
								</button>
							</div>

							<a
								href="javascript:void(0)"
								v-if="search_list"
								@click="search_list = false"
								class="close-search-list"
							>
								<i class="fa fa-times"></i>
							</a>

							<div class="datalist" v-if="search_list">
								<ul
									v-if="
										!searching &&
											search_result &&
											Object.keys(search_result).length > 0
									"
									class="nav flex-column"
								>
									<slot v-if="search_result">
										<li v-for="(result, index) in search_result" :key="index" class="nav-item">
											<router-link
												class="nav-link searchRes"
												target="_blank"
												:to="{
													name: 'ecommerce.products.details',
													params: { slug: result.slug }
												}"
											>
												<div class="search-product d-flex justify-content-between align-content-center">
													<div class="product-details">
														<h5
															v-html="
																$options.filters.highlight(
																	result.product_name,
																	search_data
																)
															"
														></h5>
														<div>{{result.short_description}}</div>
													</div>
													<img :title="result.product_name" v-if="result.image1" :src="result.image1" alt />
												</div>
											</router-link>
										</li>
									</slot>
								</ul>
								<div v-else class="text-center pt-5 mt-5" style="font-size:13px;">No Data Found!!</div>
								<div v-if="searching" class="text-center pt-5 mt-5" style="font-size:13px;">
									<i class="fa fa-circle-o-notch fa-spin" style="font-size:24px"></i>
									<br />Searching!!
								</div>
							</div>
						</form>
					</div>
					<div class="col-lg-4">
						<ul class="right-side-link">
							<li class="d-lg-none d-block">
								<router-link :to="{ name: 'ecommerce.home' }">
									<i class="fa fa-home" style="color:#888888 !important"></i>
									<div class="quantity" v-if="$store.state.countWishList">
										<span>{{ $store.state.countWishList }}</span>
									</div>
								</router-link>
							</li>
							<li>
								<router-link :to="{ name: 'ecommerce.wishlist' }">
									<img :src="$root.baseurl + '/public/images/e-icon/wish-list.png'" alt />
									<div class="quantity" v-if="$store.state.countWishList">
										<span>{{ $store.state.countWishList }}</span>
									</div>
								</router-link>
							</li>
							<li>
								<router-link :to="{ name: 'ecommerce.addtocart' }">
									<img
										:src="
											$root.baseurl + '/public/images/e-icon/add-to-cart.png'
										"
										alt
									/>
									<div class="quantity" v-if="$store.state.cartCount">
										<span>{{ $store.state.cartCount }}</span>
									</div>
								</router-link>
							</li>
							<li>
								<div class="user-dropdown">
									<a class="customer-profile" href="#" role="button">
										<img :src="$root.baseurl + '/public/images/e-icon/cutomer.png'" alt />
									</a>
									<ul v-if="$root.ecomLogged === false" class="dropdown-list">
										<li class="dropdown-item">
											<router-link :to="{ name: 'ecommerce.login' }">
												<i class="fa fa-sign-in"></i> Login
											</router-link>
										</li>
										<li class="dropdown-item">
											<router-link :to="{ name: 'ecommerce.registration' }">
												<i class="fa fa-user"></i> Signup
											</router-link>
										</li>
									</ul>
									<ul v-if="$root.ecomLogged === true" class="dropdown-list">
										<li class="dropdown-item">
											<router-link :to="{ name: 'ecommerce.dashboard' }">
												<i class="fa fa-user-circle-o"></i> My account
											</router-link>
										</li>
										<li class="dropdown-item">
											<router-link :to="{ name: 'ecommerce.myorders' }">
												<i class="fa fa-box-open"></i> My orders
											</router-link>
										</li>
										<li class="dropdown-item">
											<router-link :to="{ name: 'ecommerce.addtocart' }">
												<i class="fa fa-shopping-bag"></i> Cart list
											</router-link>
										</li>
										<li class="dropdown-item">
											<a v-on:click="logout()" href="javascript:void(0)">
												<i class="fa fa-power-off"></i> Logout
											</a>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "EcommerceHeader",
		data() {
			return {
				search_data: null,
				show_search: false,
				search_list: false,
				searching: false,
				search_result: {}
			};
		},
		methods: {
			logout() {
				this.logoutCustomer();
			},
			globalProductSearch: function() {
				this.search_list = true;
				this.searching = true;
				let keyword = this.search_data;
				let route_name = this.$route.name;
				setTimeout(() => {
					axios
						.get("/ecommerce/product-global-search", {
							params: { keyword: keyword }
						})
						.then(response => {
							this.search_result = response.data.data;

							this.searching = false;
						});
				}, 400);
			}
		},
		created() {}
	};
</script>
<style scoped lang="scss">
	.datalist {
		position: absolute;
		top: 100%;
		background: #fff;
		width: 570px;
		height: 400px;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 1rem;
		border-radius: 4px;
		z-index: 123;
		border: 1px solid rgb(109 200 190 / 25%);
		ul li {
			a {
				color: #212529;
				border-bottom: 1px solid #97979744;
				.search-product {
					.product-details {
						p {
							margin: 0;
							padding: 0;
							color: #6c757d;
						}
					}
					img {
						height: 4em;
					}
				}
			}
		}
	}
	a.close-search-list {
		position: absolute;
		right: -87px;
		top: 0;
		margin: 0px 11px;
		font-size: 24px;
		color: red;
		z-index: 12;
	}
</style>
