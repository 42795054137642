<template>
    <div id="app">
        <LoaderFull v-if="$root.loading_full"/>
        <Header/>
        <main class="main">
            <Loader v-if="$root.loading"/>
            <!-- <transition name="fade" mode="out-in" @before-enter="beforeEnter" appear>
            </transition>-->
            <router-view></router-view>
            <!-- <Debug v-if="$root.debug_status" /> -->
        </main>
        <Footer/>
        <Debug v-if="$root.debug_status"/>
    </div>
</template>

<script>
    import LoaderFull from "../components/LoaderFull";
    import Loader from "../components/Loader";
    import Header from "../components/elements/Header";
    import Footer from "../components/elements/Footer";
    import Debug from "../components/Debug";

    export default {
        name: "main-layout",
        components: {
            Header,
            Footer,
            Debug,
            Loader,
            LoaderFull
        },
        created() {
            AOS.init({
                duration: 1200,
                easing: "ease-out-quad",
                startEvent: "load"
            });
        }
    };
</script>

<style scoped>
    main.main {
        position: relative;
    }

    main.main.active .loader {
        transform: translate(0, -40px);
    }
</style>
