<template>
  <div class="becomeMember text-center card card-body border-light shadow-lg h-100">
    <div class="content-header mb-4 mt-3">
      <h1
        class="title text-center"
        :tooltip="'always'"
        :tooltip-placement="['top', 'bottom']"
      >Upcoming Events</h1>
    </div>
    <div class="becomeMember-slider pt-3" v-if="(Object.keys(events).length>0)">
      <div class="carousel-slider">
        <carousel
          :autoplay="false"
          :dots="false"
          :nav="false"
          :margin="15"
          :responsive="{0:{items:1},600:{items:2}}"
        >
          <template slot="prev">
            <span class="prev">
              <i class="fas fa-chevron-left"></i>
            </span>
          </template>
          <div class="items text-left" v-for="(event,event_index) in events" :key="event_index">
            <div class="card border-0">
              <img v-lazy="'public'+event.image" class="d-block w-100" alt="public event image." />
              <div style="height: 0px;" class="ml-3">
                <div
                  v-if="event.location"
                  class="eventDateTime d-flex align-items-center justify-content-between"
                >
                  <span class="date text-danger">
                    <i class="mdi mdi-calendar-month pr-1"></i>
                    {{ event.start_date|formatDate('Do MMM') }} to
                    {{ event.end_date|formatDate('Do MMM YYYY') }}
                  </span>
                  <span class="location">
                    <i class="mdi mdi-map-marker-outline pr-1"></i>
                    {{ event.location }}
                  </span>
                </div>
              </div>
              <div class="card-body">
                <h3>{{ getPostBody(event.title,80) }}</h3>
                <p>{{ getPostBody(event.description,125) }}</p>
              </div>
              <div class="card-footer bg-transparent border-0">
                <router-link
                  :to="{ name:'event.detail', params: { slug: event.slug }}"
                  class="btn btn-sm btn-outline-primary"
                >View More</router-link>
              </div>
            </div>
          </div>
          <template slot="next">
            <span class="next">
              <i class="fas fa-chevron-right"></i>
            </span>
          </template>
        </carousel>
      </div>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
export default {
  name: "event-calender",
  components: { carousel },
  data() {
    return {
      events: [],
      itemsPerRow: 2,
      event1: "/basis/public/images/slider/slide1.jpg",
      event2: "/basis/public/images/slider/slide2.jpg",
      news1: require("../../assets/img/gallery/gallery1.jpeg"),
      news2: require("../../assets/img/gallery/gallery2.jpg"),
      news3: require("../../assets/img/gallery/gallery3.jpg")
    };
  },
  methods: {
    get_data() {
      axios
        .get("/all_layout_data/events")
        .then(respons => {
          this.events = respons.data.events;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    this.get_data();
  }
};
</script>
