<template>
  <div class="formValidation py-5">
    <div class="container-wrapper">
      <div class="card shadow-sm">
        <div class="card-header">
          <h3 class="title m-0">Form Validation</h3>
        </div>
        <div class="card-body">
          <form>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <label for="validationServer01">First name</label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  id="validationServer01"
                  placeholder="First name"
                  value="Mark"
                  required
                />
                <div class="valid-feedback">Looks good!</div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="validationServer02">Last name</label>
                <input
                  type="text"
                  class="form-control shadow-none is-valid"
                  id="validationServer02"
                  placeholder="Last name"
                  value="Otto"
                  required
                />
                <div class="valid-feedback">Looks good!</div>
              </div>
              <div class="col-md-4 align-self-center mb-2">
                <info>
                  <i class="fas fa-info-circle"></i>
                  <span>
                    <i class="fas fa-info pr-2"></i>Hover Me
                  </span>
                </info>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <label for="validationServer02">
                  Email
                  <span class="requierd-star"></span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none is-valid"
                  id="validationServer02"
                  placeholder="Email"
                  value="@example.com"
                  required
                />
                <div class="valid-feedback">Looks good!</div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="password">Password</label>
                <input
                  type="password"
                  class="form-control shadow-none"
                  id="password"
                  placeholder="Password"
                  value="Password"
                  required
                />
                <div class="valid-feedback">Looks good!</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <label for="validationServerUsername">Username</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend3">@</span>
                  </div>
                  <input
                    type="text"
                    class="form-control shadow-none is-invalid"
                    id="validationServerUsername"
                    placeholder="Username"
                    aria-describedby="inputGroupPrepend3"
                    required
                  />
                  <div class="invalid-feedback">Please choose a username.</div>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="validationServerUsername">Phone</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend3">+88</span>
                  </div>
                  <input
                    type="text"
                    class="form-control shadow-none is-invalid"
                    id="validationServerUsername"
                    placeholder="Phone"
                    aria-describedby="inputGroupPrepend3"
                    required
                  />
                  <div class="invalid-feedback">Please choose a Phone Number.</div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3">
                <label for="validationServer03">City</label>
                <input
                  type="text"
                  class="form-control shadow-none is-invalid"
                  id="validationServer03"
                  placeholder="City"
                  required
                />
                <div class="invalid-feedback">Please provide a valid city.</div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="validationServer04">State</label>
                <input
                  type="text"
                  class="form-control shadow-none is-invalid"
                  id="validationServer04"
                  placeholder="State"
                  required
                />
                <div class="invalid-feedback">Please provide a valid state.</div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="validationServer05">Zip</label>
                <input
                  type="text"
                  class="form-control is-invalid"
                  id="validationServer05"
                  placeholder="Zip"
                  required
                />
                <div class="invalid-feedback">Please provide a valid zip.</div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <input
                  class="form-check-input is-invalid"
                  type="checkbox"
                  value
                  id="invalidCheck3"
                  required
                />
                <label class="form-check-label" for="invalidCheck3">Agree to terms and conditions</label>
                <div class="invalid-feedback">You must agree before submitting.</div>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-file">
                <input type="file" class="custom-file-input" id="customFile" />
                <label class="custom-file-label" for="customFile">Choose file</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck1" />
                <label class="custom-control-label" for="customCheck1">Check this custom checkbox</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio1"
                  name="customRadio"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadio1">Toggle this custom radio</label>
              </div>
            </div>
            <div class="form-group">
              <input
                list="browsers"
                class="form-control shadow-none"
                placeholder="Search with History mode"
              />

              <datalist id="browsers">
                <option value="Internet Explorer"></option>
                <option value="Firefox"></option>
                <option value="Chrome"></option>
                <option value="Opera"></option>
                <option value="Safari"></option>
              </datalist>
            </div>
            <div class="form-group">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitch1" />
                <label class="custom-control-label" for="customSwitch1">Toggle this switch element</label>
              </div>
            </div>
            <button class="btn btn-primary" type="submit">Submit form</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
