<template>
  <div class="b2b-page">
    <PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
    <div class="content-body py-4">
      <div class="container-wrapper">
        <div class="row">
          <!-- <div class="col-lg-3">
            <leftmenu />
          </div>-->
          <div class="col-lg-12">
            <div class="b2b-item">
              <div class="row">
                <div class="col-lg-4" v-for="(item, index) in content" :key="index">
                  <router-link :to="{ name:'b2b_detail', params: { slug: item.slug }}">
                    <div class="card card-body shadow-sm mb-3 text-center rounded-10">
                      <span class="shape"></span>
                      <img
                        v-if="item.image"
                        v-lazy="$root.baseurl+'/public'+item.image"
                        class="img-fluid"
                        :alt="item.title"
                      />
                      <h3 class="text-dark">{{item.title}}</h3>
                      <!-- <p>{{ getPostBody(item.description,200) }}</p> -->
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageBanner from "../../components/elements/PageBanner";
import leftmenu from "../../components/elements/leftmenu";
export default {
  name: "About",
  components: {
    PageBanner,
    leftmenu
  },
  data() {
    return {
      breadcrumbTitle: "",
      breadcumb: [],
      content: {},
      leftmenu: {}
    };
  },
  methods: {
    asyncData() {
      this.get_data();
    },
    get_data() {
      axios
        .get("/get-b2b")
        .then(respons => {
          this.content = respons.data;
          this.leftmenu = respons.data.parent_menus;
          this.breadcrumbTitle = "B2B";
          this.breadcumb = "B2B";
        })
        .catch(error => {
          console.log(error);
        })
        .then(res => {
          setTimeout(
            () => (this.$root.loading = false),
            this.$root.minloadtimefd
          );
        });
    }
  },
  mounted() {},
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    }
  }
};
</script>
