<template>
  <div id="example-content">
    <slot v-if="!$root.spinner">
      <div
        class="basis-member"
        :class="$route.params.type == '2' ? 'committee' : 'staff'"
      >
        <h5 class="page-title font-weight-bold text-uppercase mb-4">
          {{ StaffCategory.name }}
        </h5>
        <p class="text-justify" v-html="StaffCategory.description"></p>
        <div class="row justify-content-center">
          <div class="col-md-4" v-if="first">
            <div class="card member-box shadow-lg mb-0">
              <span class="shape"></span>
              <img
                v-lazy="first.FullUrl + first.image"
                class="card-img-top"
                :alt="first.name"
              />
              <div class="card-body">
                <span class="member-degignation" v-if="first.designation">
                  {{ first.designation }}
                </span>
                <h4 class="member-title">{{ first.name }}</h4>
                <small v-if="first.email">
                  <strong>Email:</strong>
                  {{ first.email }}
                </small>
                <br />
                <small v-if="first.mobile">
                  <strong>Phone:</strong>
                  {{ first.mobile }}
                </small> <br/>
                <small v-if="first.company">
                  <strong>Company:</strong>
                  {{ first.company }}
                </small>
              </div>
              <div class="card-footer">
                <div class="social">
                  <big>Follow:</big>
                  <span v-if="first.linkin_profile">
                    <a :href="first.linkin_profile" target="_blank">
                      <!-- <i class="flaticon-linkedin"></i> -->
                      <img v-lazy="linkedin" alt="basis linkedin" />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div
          v-if="
            All &&
            Object.keys(All).length > 0 &&
            All[0].sl_order == 2 &&
            $route.path == '/secretariat'
          "
          class="row justify-content-center"
        >
          <div class="col-md-4" v-if="first">
            <div class="card member-box shadow-lg mb-0">
              <span class="shape"></span>
              <img
                v-lazy="All[0].FullUrl + All[0].image"
                class="card-img-top"
                :alt="All[0].name"
              />
              <div class="card-body">
                <span class="member-degignation" v-if="All[0].designation">
                  {{ All[0].designation }}
                </span>
                <h4 class="member-title">{{ All[0].name }}</h4>
                <small v-if="All[0].email">
                  <strong>Email:</strong>
                  {{ All[0].email }}
                </small>
                <br />
                <small v-if="All[0].mobile">
                  <strong>Phone:</strong>
                  {{ All[0].mobile }}
                </small>
                <small v-if="All[0].company">
                  <strong>Company:</strong>
                  {{ All[0].company }}
                </small>
              </div>
              <div class="card-footer">
                <div class="social">
                  <big>Follow:</big>
                  <span v-if="All[0].linkin_profile">
                    <a :href="All[0].linkin_profile" target="_blank">
                      <!-- <i class="flaticon-linkedin"></i> -->
                      <img v-lazy="linkedin" alt="basis linkedin" />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row" v-if="All" style="margin-top: 20px">
          <slot v-for="(member, index) in All">
            <span
              :key="'AAa' + index"
              v-if="member.sl_order == 2 && $route.path == '/secretariat'"
            ></span>
            <div v-else class="col-md-4" :key="'A' + index">
              <div class="card member-box shadow-lg">
                <span class="shape"></span>
                <img
                  v-lazy="member.FullUrl + member.image"
                  class="card-img-top"
                  :alt="member.name"
                />
                <div
                  style="width: 80px; position: absolute; top: 0; right: 25px"
                  v-if="member.id == 7"
                >
                  <img :src="mourning" alt />
                </div>
                <div class="card-body">
                  <span class="member-degignation" v-if="member.designation">
                    {{ member.designation }}
                  </span>
                  <h4 class="member-title">{{ member.name }}</h4>
                  <small v-if="member.email">
                    <strong>Email:</strong>
                    {{ member.email }}
                  </small>
                  <br v-if="member.mobile" />
                  <small v-if="member.mobile">
                    <strong>Phone:</strong>
                    {{ member.mobile }}
                  </small>
                  <br v-if="member.company" />
                  <small v-if="member.company">
                    <strong>Company:</strong>
                    {{ member.company }}
                  </small>
                </div>
                <div class="card-footer">
                  <div class="social">
                    <big>Follow:</big>
                    <span v-if="member.linkin_profile">
                      <a :href="member.linkin_profile" target="_blank">
                        <!-- <i class="flaticon-linkedin"></i> -->
                        <img v-lazy="linkedin" alt="basis linkedin" />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </slot>
        </div>
        <!-- others team -->
        <div
          class="row justify-content-center"
          v-if="othersTeam"
          style="margin-top: 20px"
        >
          <slot class v-for="(staff, index) in StaffCategories">
            <div :key="'B' + index" class="col-lg-12">
              <h5 class="page-title font-weight-bold text-uppercase">
                {{ staff }}
              </h5>
            </div>
            <slot v-for="(othMember, index1) in othersTeam">
              <div
                class="col-md-4"
                v-if="othMember.staff_category_id == index"
                :key="'C' + index1"
              >
                <div class="card member-box shadow-lg">
                  <span class="shape"></span>
                  <img
                    v-lazy="othMember.FullUrl + othMember.image"
                    class="card-img-top"
                    :alt="othMember.name"
                  />
                  <div class="card-body">
                    <span
                      class="member-degignation"
                      v-if="othMember.designation"
                      >{{ othMember.designation }}</span
                    >
                    <h4 class="member-title">{{ othMember.name }}</h4>
                    <small v-if="othMember.email">
                      <strong>Email:</strong>
                      {{ othMember.email }}
                    </small>
                    <br v-if="othMember.mobile" />
                    <small v-if="othMember.mobile">
                      <strong>Phone:</strong>
                      {{ othMember.mobile }}
                    </small>
                    <br v-if="othMember.company" />
                    <small v-if="othMember.company">
                      <strong>Company:</strong>
                      {{ othMember.company }}
                    </small>
                  </div>
                  <div class="card-footer">
                    <div class="social">
                      <big>Follow:</big>
                      <span v-if="othMember.linkin_profile">
                        <a :href="othMember.linkin_profile" target="_blank">
                          <!-- <i class="flaticon-linkedin"></i> -->
                          <img v-lazy="linkedin" alt="basis linkedin" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </slot>
        </div>
      </div>
    </slot>
    <spinner v-if="$root.spinner" />
  </div>
</template>

<script>
export default {
  name: "EcSecretariat",
  data() {
    return {
      breadcrumbTitle: "",
      breadcumb: [{ title: "" }],
      StaffCategory: {},
      first: {},
      All: {},
      StaffCategories: {},
      othersTeam: {},
      linkedin: this.$root.baseurl + "/public/images/linkedin.png",
      mourning: this.$root.baseurl + "/public/images/mourning.png",
    };
  },
  methods: {
    asyncData() {
      this.get_data();
      this.$root.loading = false;
    },
    get_data() {
      this.$root.spinner = true;
      if (this.$route.name == "executive-council") {
        var id = 1;
      } else {
        var id = 2;
      }
      axios
        .get("/get-ec-secretariat/" + id)
        .then((respons) => {
          this.StaffCategory = respons.data.StaffCategory;
          (this.breadcrumbTitle = this.StaffCategory.name),
            (this.breadcumb = [{ title: this.StaffCategory.name }]),
            (this.first = respons.data.first);
          this.All = respons.data.All;
          this.othersTeam = respons.data.othersTeam;
          this.StaffCategories = respons.data.StaffCategories;
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
          this.$root.spinner = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {},
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true,
    },
  },
};
</script>

<style lang="css">
.page-title {
  font-size: 16px !important;
}
</style>">
