<template>
  <div class="B2BDetail">
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-3">
            <div class="leftside-menu" v-if="leftmenu">
              <div class="card shadow-sm py-4 mb-3 rounded-10">
                <span class="shape"></span>
                <div class="card-header bg-transparent">
                  <h3 class="mb-0">B2B</h3>
                </div>
                <ul class="nav flex-column">
                  <li class="nav-item" v-for="(item, index) in leftmenu" :key="index">
                    <router-link
                      class="nav-link"
                      :to="{ name:'b2b_detail', params: { slug: item.slug }}"
                    >{{item.title}}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
          <div class="col-lg-12">
            <div class="row">
              <slot v-if="content.image">
                <div class="col-xs-12 col-lg-5">
                  <img v-lazy="content.FullUrl+content.image" class="img-fluid" :alt="content.title" />
                </div>
                <div class="col-xs-12 col-lg-7" data-aos="fade-right">
                  <!--Heading-->
                  <h5 class="font-weight-bold text-uppercase">{{content.title}}</h5>
                  <!--Text-->
                  <p class="text-justify" v-html="content.description"></p>
                </div>
              </slot>
              <slot v-else>
                <div class="col-xs-12 col-lg-12" data-aos="fade-right">
                  <h5 class="font-weight-bold text-uppercase">{{content.title}}</h5>
                  <p class="text-justify" v-html="content.description"></p>
                </div>
              </slot>
              <div
                class="col-xs-12 col-lg-12"
                data-aos="fade-right"
                v-if="content.content_file.length > 0"
              >
                <table class="table table-striped table-fixed table-bordered">
                  <thead>
                    <tr class="thead-dark">
                      <th>SL</th>
                      <th>Title</th>
                      <th>File</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(content_file, index) in content.content_file" :key="index">
                      <td>{{index+1}}</td>
                      <td>{{ content_file.title }}</td>
                      <td>
                        <a
                          :href="content_file.FullUrl+ content_file.file"
                          target="_blank"
                          class="nav-link"
                        >
                          <i class="fas fa-file-pdf"></i> View
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "B2BDetail",
  components: {},
  data() {
    return {
      content: {
        content_file: [],
        breadcrumbTitle: "",
        breadcumb: []
      },
      leftmenu: {}
    };
  },
  methods: {
    asyncData() {
      this.get_data();
    },
    get_data() {
      axios
        .get("/get-b2b-detail/" + this.$route.params.slug)
        .then(respons => {
          this.content = respons.data.result;
          this.leftmenu = respons.data.leftmenu;
        })
        .catch(error => {
          console.log(error);
        })
        .then(res => {
          setTimeout(
            () => (this.$root.loading = false),
            this.$root.minloadtimefd
          );
        });
    }
  },
  mounted() {
    this.get_data();
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    }
  }
};
</script>
