var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (!_vm.$parent.loading &&
      _vm.$root.access &&
      Object.keys(_vm.$root.access).length > 0 &&
      _vm.$root.access['photos_file']) ||
    _vm.$root.variable.rep_login ||
    _vm.$root.variable.aLogId
  )?_c('div',{staticClass:"member-services"},[_c('form',{attrs:{"id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"card shadow-sm mb-3"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center justify-content-end mb-4"},[(Object.keys(_vm.errors).length > 0)?_c('div',{staticClass:"col-lg-12 py-2",staticStyle:{"background":"#fddede"}},[_c('div',{staticClass:"error"},_vm._l((_vm.errors),function(err,errIndex){return _c('span',{key:errIndex,staticClass:"text-danger"},[_vm._v("\n                "+_vm._s(err[0])+"\n                "),_c('br')])}),0)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-6 mb-3"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-2 text-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                    _vm.company_photo1 || _vm.photosFiles.company_photo1
                      ? _vm.company_photo1_src
                      : _vm.noPdf
                  ),expression:"\n                    company_photo1 || photosFiles.company_photo1\n                      ? company_photo1_src\n                      : noPdf\n                  "}],staticClass:"img-responsive",attrs:{"height":"65","width":"70","alt":"company_photo1"}})]),_vm._v(" "),_c('div',{staticClass:"form-group col-10"},[_vm._m(1),_vm._v(" "),_c('b-form-file',{attrs:{"size":"sm","placeholder":"Choose jpg,jpeg,png file","accept":".jpg, .png","drop-placeholder":"Drop file here..."},model:{value:(_vm.company_photo1),callback:function ($$v) {_vm.company_photo1=$$v},expression:"company_photo1"}}),_vm._v(" "),_c('small',[_vm._v("Resize your photo (width:600px X height:400px)")]),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                  "+_vm._s(_vm.validation.firstError("company_photo1"))+"\n                ")])],1),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"old_company_photo1"},domProps:{"value":_vm.photosFiles.company_photo1}})]),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-2 text-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                    _vm.company_photo2 || _vm.photosFiles.company_photo2
                      ? _vm.company_photo2_src
                      : _vm.noPdf
                  ),expression:"\n                    company_photo2 || photosFiles.company_photo2\n                      ? company_photo2_src\n                      : noPdf\n                  "}],staticClass:"img-responsive",attrs:{"height":"65","width":"70","alt":"company_photo2"}})]),_vm._v(" "),_c('div',{staticClass:"form-group col-10"},[_vm._m(2),_vm._v(" "),_c('b-form-file',{attrs:{"size":"sm","placeholder":"Choose jpg,jpeg,png file","accept":".jpg, .png","drop-placeholder":"Drop file here..."},model:{value:(_vm.company_photo2),callback:function ($$v) {_vm.company_photo2=$$v},expression:"company_photo2"}}),_vm._v(" "),_c('small',[_vm._v("Resize your photo (width:600px X height:400px)")]),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                  "+_vm._s(_vm.validation.firstError("company_photo2"))+"\n                ")])],1),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"old_company_photo2"},domProps:{"value":_vm.photosFiles.company_photo2}})]),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-2 text-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                    _vm.company_photo3 || _vm.photosFiles.company_photo3
                      ? _vm.company_photo3_src
                      : _vm.noPdf
                  ),expression:"\n                    company_photo3 || photosFiles.company_photo3\n                      ? company_photo3_src\n                      : noPdf\n                  "}],staticClass:"img-responsive",attrs:{"height":"65","width":"70","alt":"company_photo3"}})]),_vm._v(" "),_c('div',{staticClass:"form-group col-10"},[_vm._m(3),_vm._v(" "),_c('b-form-file',{attrs:{"size":"sm","placeholder":"Choose jpg,jpeg,png file","accept":".jpg, .png","drop-placeholder":"Drop file here..."},model:{value:(_vm.company_photo3),callback:function ($$v) {_vm.company_photo3=$$v},expression:"company_photo3"}}),_vm._v(" "),_c('small',[_vm._v("Resize your photo (width:600px X height:400px)")]),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                  "+_vm._s(_vm.validation.firstError("company_photo3"))+"\n                ")])],1),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"old_company_photo3"},domProps:{"value":_vm.photosFiles.company_photo3}})])]),_vm._v(" "),_c('div',{staticClass:"col-lg-6 mb-3"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-2 text-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                    _vm.company_photo4 || _vm.photosFiles.company_photo4
                      ? _vm.company_photo4_src
                      : _vm.noPdf
                  ),expression:"\n                    company_photo4 || photosFiles.company_photo4\n                      ? company_photo4_src\n                      : noPdf\n                  "}],staticClass:"img-responsive",attrs:{"height":"65","width":"70","alt":"company_photo4"}})]),_vm._v(" "),_c('div',{staticClass:"form-group col-10"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Company Photo 4")]),_vm._v(" "),_c('b-form-file',{attrs:{"size":"sm","placeholder":"Choose jpg,jpeg,png file","accept":".jpg, .png","drop-placeholder":"Drop file here..."},model:{value:(_vm.company_photo4),callback:function ($$v) {_vm.company_photo4=$$v},expression:"company_photo4"}}),_vm._v(" "),_c('small',[_vm._v("Resize your photo (width:600px X height:400px)")])],1),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.validation.firstError("company_photo4"))+"\n              ")]),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"old_company_photo4"},domProps:{"value":_vm.photosFiles.company_photo4}})]),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-2 text-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                    _vm.company_photo5 || _vm.photosFiles.company_photo5
                      ? _vm.company_photo5_src
                      : _vm.noPdf
                  ),expression:"\n                    company_photo5 || photosFiles.company_photo5\n                      ? company_photo5_src\n                      : noPdf\n                  "}],staticClass:"img-responsive",attrs:{"height":"65","width":"70","alt":"company_photo5"}})]),_vm._v(" "),_c('div',{staticClass:"form-group col-10"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Company Photo 5")]),_vm._v(" "),_c('b-form-file',{attrs:{"size":"sm","placeholder":"Choose jpg,jpeg,png file","accept":".jpg, .png","drop-placeholder":"Drop file here..."},model:{value:(_vm.company_photo5),callback:function ($$v) {_vm.company_photo5=$$v},expression:"company_photo5"}}),_vm._v(" "),_c('small',[_vm._v("Resize your photo (width:600px X height:400px)")])],1),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.validation.firstError("company_photo5"))+"\n              ")]),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"old_company_photo5"},domProps:{"value":_vm.photosFiles.company_photo5}})]),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-2 text-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                    _vm.company_photo6 || _vm.photosFiles.company_photo6
                      ? _vm.company_photo6_src
                      : _vm.noPdf
                  ),expression:"\n                    company_photo6 || photosFiles.company_photo6\n                      ? company_photo6_src\n                      : noPdf\n                  "}],staticClass:"img-responsive",attrs:{"height":"65","width":"70","alt":"company_photo6"}})]),_vm._v(" "),_c('div',{staticClass:"form-group col-10"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Company Photo 6")]),_vm._v(" "),_c('b-form-file',{attrs:{"size":"sm","placeholder":"Choose jpg,jpeg,png file","accept":".jpg, .png","drop-placeholder":"Drop file here..."},model:{value:(_vm.company_photo6),callback:function ($$v) {_vm.company_photo6=$$v},expression:"company_photo6"}}),_vm._v(" "),_c('small',[_vm._v("Resize your photo (width:600px X height:400px)")])],1),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.validation.firstError("company_photo6"))+"\n              ")]),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"old_company_photo6"},domProps:{"value":_vm.photosFiles.company_photo6}})])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"col-lg-12 border border-primary p-3"},[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-row"},[(_vm.member.info && !_vm.cover_photo)?_c('div',{staticClass:"form-group col-2 text-center"},[(_vm.member.info.cover_photo)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                      _vm.member.info.cover_photo
                        ? _vm.$root.baseurl + '/public' + _vm.member.info.cover_photo
                        : _vm.noPdf
                    ),expression:"\n                      member.info.cover_photo\n                        ? $root.baseurl + '/public' + member.info.cover_photo\n                        : noPdf\n                    "}],staticClass:"img-responsive",attrs:{"height":"65","width":"70","alt":"cover_photo"}}):_vm._e()]):_c('div',{staticClass:"form-group col-2 text-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.cover_photo ? _vm.cover_photo_src : _vm.noPdf),expression:"cover_photo ? cover_photo_src : noPdf"}],staticClass:"img-responsive",attrs:{"height":"65","width":"70","alt":"cover_photo"}})]),_vm._v(" "),_c('div',{staticClass:"form-group col-10"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Cover Photo")]),_vm._v(" "),_c('span',{staticClass:"requierd-star"}),_vm._v(" "),_c('b-form-file',{attrs:{"size":"sm","placeholder":"Choose jpg,jpeg,png file","accept":".jpg, .png","drop-placeholder":"Drop file here..."},model:{value:(_vm.cover_photo),callback:function ($$v) {_vm.cover_photo=$$v},expression:"cover_photo"}}),_vm._v(" "),_c('small',[_vm._v("Resize your cover photo (width:800px X\n                    height:240px)")])],1),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                  "+_vm._s(_vm.validation.firstError("cover_photo"))+"\n                ")]),_vm._v(" "),(_vm.member.info)?_c('input',{attrs:{"type":"hidden","name":"old_cover_photo"},domProps:{"value":_vm.member.info.cover_photo}}):_vm._e()])])])])])]),_vm._v(" "),_c('div',{staticClass:"card shadow-sm mb-3"},[_vm._m(5),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-6 mb-3"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-2 text-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                    _vm.representative_sign || _vm.representative.sign
                      ? _vm.sign_src
                      : _vm.noPdf
                  ),expression:"\n                    representative_sign || representative.sign\n                      ? sign_src\n                      : noPdf\n                  "}],staticClass:"img-responsive",attrs:{"height":"65","width":"100%","alt":"representative_sign"}})]),_vm._v(" "),_c('div',{staticClass:"col-10 mb-3"},[_c('label',{attrs:{"for":"representative_sign"}},[_vm._v("Representative Signature")]),_vm._v(" "),_c('div',{staticClass:"loginBox"},[_c('b-form-file',{class:{
                      'is-invalid': _vm.validation.hasError(
                        'representative_sign'
                      ),
                      'is-valid': _vm.representative_sign,
                    },attrs:{"accept":".jpg, .png","placeholder":"Choose jpg or png image","drop-placeholder":"Drop file here...","name":"representative_sign"},model:{value:(_vm.representative_sign),callback:function ($$v) {_vm.representative_sign=$$v},expression:"representative_sign"}}),_vm._v(" "),_c('span',{staticClass:"mdi mdi-file-pdf text-danger"})],1),_vm._v(" "),_c('small',[_vm._v("Size ( W200 X H80)")]),_vm._v(" "),(_vm.representative && _vm.representative.sign)?_c('a',{staticClass:"float-right",attrs:{"href":_vm.$root.baseurl + '/public' + _vm.representative.sign,"target":"_blank"}},[_vm._v("View file")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                  "+_vm._s(_vm.validation.firstError("representative_sign"))+"\n                ")])]),_vm._v(" "),(_vm.representative)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.representative.sign),expression:"representative.sign"}],attrs:{"type":"hidden","name":"old_representative_sign"},domProps:{"value":(_vm.representative.sign)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.representative, "sign", $event.target.value)}}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-row mb-3"},[_c('div',{staticClass:"form-group col-2 text-center"},[(_vm.photosFiles)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                    _vm.printed_brochure || _vm.photosFiles.printed_brochure
                      ? _vm.pdfAttach
                      : _vm.noPdf
                  ),expression:"\n                    printed_brochure || photosFiles.printed_brochure\n                      ? pdfAttach\n                      : noPdf\n                  "}],staticClass:"img-responsive",attrs:{"height":"65","width":"70","alt":"printed_brochure"}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-10 mb-3"},[_c('label',{attrs:{"for":"printed_brochure"}},[_vm._v("Printed brochure (if available)")]),_vm._v(" "),_c('b-form-file',{class:{
                    'is-invalid': _vm.validation.hasError('printed_brochure'),
                    'is-valid': _vm.printed_brochure,
                  },attrs:{"placeholder":"Choose a pdf","accept":".pdf","drop-placeholder":"Drop file here...","name":"printed_brochure"},model:{value:(_vm.printed_brochure),callback:function ($$v) {_vm.printed_brochure=$$v},expression:"printed_brochure"}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.photosFiles.printed_brochure),expression:"photosFiles.printed_brochure"}],attrs:{"type":"hidden","name":"old_printed_brochure"},domProps:{"value":(_vm.photosFiles.printed_brochure)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.photosFiles, "printed_brochure", $event.target.value)}}}),_vm._v(" "),_c('small',[_vm._v("File size must be smaller than 2mb")]),_vm._v(" "),(_vm.photosFiles.printed_brochure)?_c('a',{staticClass:"float-right",attrs:{"href":_vm.$root.baseurl + '/public' + _vm.photosFiles.printed_brochure,"target":"_blank"}},[_vm._v("View file")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                  "+_vm._s(_vm.validation.firstError("printed_brochure"))+"\n                ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"col-lg-6 mb-3"},[_c('div',{staticClass:"form-row mb-3"},[_c('div',{staticClass:"form-group col-2 text-center"},[(_vm.member.info)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                    _vm.company_profile || _vm.member.info.profile ? _vm.pdfAttach : _vm.noPdf
                  ),expression:"\n                    company_profile || member.info.profile ? pdfAttach : noPdf\n                  "}],staticClass:"img-responsive",attrs:{"height":"65","width":"70","alt":"company_profile"}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-10 mb-3"},[_vm._m(6),_vm._v(" "),_c('div',{staticClass:"loginBox"},[_c('b-form-file',{class:{
                      'is-invalid': _vm.validation.hasError('company_profile'),
                      'is-valid': _vm.company_profile,
                    },attrs:{"accept":".pdf","placeholder":_vm.member.info && _vm.member.info.profile
                        ? 'File attached '
                        : 'Choose a pdf file',"drop-placeholder":"Drop file here...","name":"company_profile"},model:{value:(_vm.company_profile),callback:function ($$v) {_vm.company_profile=$$v},expression:"company_profile"}}),_vm._v(" "),_c('span',{staticClass:"mdi mdi-file-pdf text-danger"})],1),_vm._v(" "),_c('small',[_vm._v("Company profile describing infrastructure, human resource,\n                  business activity, capability and experience of the\n                  Organizing/ Company")]),_vm._v(" "),(_vm.member.info && _vm.member.info.profile)?_c('a',{staticClass:"float-right",attrs:{"href":_vm.$root.baseurl + '/public' + _vm.member.info.profile,"target":"_blank"}},[_vm._v("View file")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                  "+_vm._s(_vm.validation.firstError("company_profile"))+"\n                ")])]),_vm._v(" "),(_vm.member.info)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.member.info.company_profile),expression:"member.info.company_profile"}],attrs:{"type":"hidden","name":"old_company_profile"},domProps:{"value":(_vm.member.info.company_profile)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.member.info, "company_profile", $event.target.value)}}}):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"col-lg-12 border border-primary p-3"},[_vm._m(7),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-row col-lg-4 mb-3"},[(_vm.photosFiles)?_c('div',{staticClass:"form-group col-3 text-center pt-3"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                      _vm.photosFiles.work_order_first || _vm.work_order_first
                        ? _vm.attachPdf
                        : _vm.noPdf
                    ),expression:"\n                      photosFiles.work_order_first || work_order_first\n                        ? attachPdf\n                        : noPdf\n                    "}],attrs:{"id":"displayProfilPhoto","height":"45px","alt":"displayProfilPhoto"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group col-9"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Work Order (First)")]),_vm._v(" "),_c('b-form-file',{attrs:{"accept":".pdf","placeholder":"Choose a pdf file","drop-placeholder":"Drop file here..."},model:{value:(_vm.work_order_first),callback:function ($$v) {_vm.work_order_first=$$v},expression:"work_order_first"}}),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"old_work_order_first"},domProps:{"value":_vm.photosFiles.work_order_first}}),_vm._v(" "),_c('small',[_vm._v("File size must be smaller than 2mb")]),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                    "+_vm._s(_vm.validation.firstError("work_order_first"))+"\n                  ")]),_vm._v(" "),(_vm.photosFiles.work_order_first)?_c('a',{staticClass:"float-right",attrs:{"href":_vm.$root.baseurl + '/public' + _vm.photosFiles.work_order_first,"target":"_blank"}},[_vm._v("View file")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"form-row col-lg-4 mb-3"},[(_vm.photosFiles)?_c('div',{staticClass:"form-group col-3 text-center pt-3"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                      _vm.photosFiles.work_order_second || _vm.work_order_second
                        ? _vm.attachPdf
                        : _vm.noPdf
                    ),expression:"\n                      photosFiles.work_order_second || work_order_second\n                        ? attachPdf\n                        : noPdf\n                    "}],attrs:{"id":"displayProfilPhoto","height":"45px","alt":"displayProfilPhoto"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group col-9"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Work Order (Second)")]),_vm._v(" "),_c('b-form-file',{attrs:{"accept":".pdf","placeholder":"Choose a pdf file","drop-placeholder":"Drop file here..."},model:{value:(_vm.work_order_second),callback:function ($$v) {_vm.work_order_second=$$v},expression:"work_order_second"}}),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"old_work_order_second"},domProps:{"value":_vm.photosFiles.work_order_second}}),_vm._v(" "),_c('small',[_vm._v("File size must be smaller than 2mb")]),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                    "+_vm._s(_vm.validation.firstError("work_order_second"))+"\n                  ")]),_vm._v(" "),(_vm.photosFiles.work_order_second)?_c('a',{staticClass:"float-right",attrs:{"href":_vm.$root.baseurl +
                      '/public' +
                      _vm.photosFiles.work_order_second,"target":"_blank"}},[_vm._v("View file")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"form-row col-lg-4 mb-3"},[(_vm.photosFiles)?_c('div',{staticClass:"form-group col-3 text-center pt-3"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                      _vm.photosFiles.work_order_third || _vm.work_order_third
                        ? _vm.attachPdf
                        : _vm.noPdf
                    ),expression:"\n                      photosFiles.work_order_third || work_order_third\n                        ? attachPdf\n                        : noPdf\n                    "}],attrs:{"id":"displayProfilPhoto","height":"45px","alt":"displayProfilPhoto"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group col-9"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Work Order (Third)")]),_vm._v(" "),_c('b-form-file',{attrs:{"accept":".pdf","placeholder":"Choose a pdf file","drop-placeholder":"Drop file here..."},model:{value:(_vm.work_order_third),callback:function ($$v) {_vm.work_order_third=$$v},expression:"work_order_third"}}),_vm._v(" "),_c('small',[_vm._v("File size must be smaller than 2mb")]),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"old_work_order_third"},domProps:{"value":_vm.photosFiles.work_order_third}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                    "+_vm._s(_vm.validation.firstError("work_order_third"))+"\n                  ")]),_vm._v(" "),(_vm.photosFiles.work_order_third)?_c('a',{staticClass:"float-right",attrs:{"href":_vm.$root.baseurl + '/public' + _vm.photosFiles.work_order_third,"target":"_blank"}},[_vm._v("View file")]):_vm._e()],1)])])])]),_vm._v(" "),_vm._m(8)])])])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[(Object.keys(_vm.$root.access).length > 0)?_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"text-center text-danger"},[_vm._v("\n          Sorry!! you cannot access this page\n        ")])]):_c('div',{staticClass:"card-body"},[(!_vm.$parent.loading)?_c('div',{staticClass:"card-body d-flex align-items-center justify-content-center flex-column"},[_c('i',{staticClass:"fa fa-spinner fa-spin",staticStyle:{"font-size":"24px","color":"rgb(0, 127, 62)"}}),_vm._v(" "),_c('span',{staticClass:"processing"},[_vm._v("processing")])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header px-0"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h5',{staticClass:"text-uppercase font-weight-bold px-3 mb-0"},[_vm._v("\n            Upload 6 photos of your company:\n          ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"comments"}},[_vm._v("\n                  Company Photo 1\n                  "),_c('span',{staticClass:"requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"comments"}},[_vm._v("\n                  Company Photo 2\n                  "),_c('span',{staticClass:"requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"comments"}},[_vm._v("\n                  Company Photo 3\n                  "),_c('span',{staticClass:"requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('legend',{staticClass:"w-25"},[_c('small',{staticClass:"font-weight-bold text-uppercase"},[_vm._v("Upload Cover Photo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header px-0"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h5',{staticClass:"px-3 mb-0"},[_vm._v("Files and Others:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"Company_profile"}},[_vm._v("\n                  Company profile (\n                  "),_c('small',[_vm._v("File size must be smaller than 2mb")]),_vm._v(")\n                ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('legend',{staticClass:"w-25"},[_c('small',{staticClass:"font-weight-bold text-uppercase"},[_vm._v("Work Order")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center mt-3"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit"}},[_vm._v("\n            Upload Photos / Files\n          ")])])
}]

export { render, staticRenderFns }