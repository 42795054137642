<template>
	<div
		class="memberInfoCreate"
		v-if="
			(!$parent.loading && $root.access && $root.access['payment_history']) ||
			$root.variable.rep_login ||
			$root.variable.aLogId
		"
	>
		<b-button
			v-b-modal.modal-prevent-closing
			class="btn btn-success btn-sm mr-4"
			>Add your bank details</b-button
		>

		<b-modal
			id="modal-prevent-closing"
			ref="my-modal"
			title="Submit Your Bank Info"
			hide-footer
			@show="resetModal"
			@hidden="resetModal"
		>
			<div class="col-lg-12">
				<form v-on:submit="submit" id="form">
					<input type="hidden" v-model="data.id" />
					<div class="row">
						<div class="col-lg-12 mb-3">
							<label for="bank_name">Bank Name</label>
							<b-form-input
								id="bank_name"
								v-model="bank_name"
								type="text"
								class="form-control form-control-sm"
								placeholder="Bank Name"
								name="bank_name"
								required
							/>
							<small v-if="errors && errors.bank_name" class="text-danger">{{
								errors.bank_name[0]
							}}</small>
						</div>
						<div class="col-lg-12 mb-3">
							<label for="account_name">Account Name</label>
							<b-form-input
								id="account_name"
								v-model="account_name"
								type="text"
								class="form-control form-control-sm"
								placeholder="Account Name"
								name="account_name"
								required
							/>
							<small v-if="errors && errors.account_name" class="text-danger">{{
								errors.account_name[0]
							}}</small>
						</div>
						<div class="col-lg-12 mb-3">
							<label for="account_no">Account Number</label>
							<b-form-input
								id="account_no"
								v-model="account_no"
								type="text"
								class="form-control form-control-sm"
								placeholder="Account Number"
								name="account_no"
								required
							/>
							<small v-if="errors && errors.account_no" class="text-danger">{{
								errors.account_no[0]
							}}</small>
						</div>
						<div class="col-lg-12 mb-3">
							<label for="routing_no">Routing Number</label>
							<b-form-input
								id="routing_no"
								v-model="routing_no"
								type="text"
								class="form-control form-control-sm"
								placeholder="Routing Number"
								name="routing_no"
								required
							/>
							<small v-if="errors && errors.routing_no" class="text-danger">{{
								errors.routing_no[0]
							}}</small>
						</div>
						<div class="col-md-12 mb-3">
							<label for="branch">Branch</label>
							<b-form-input
								id="branch"
								v-model="branch"
								type="text"
								class="form-control form-control"
								placeholder="Branch Name"
								name="branch"
								required
							/>
							<small v-if="errors && errors.branch" class="text-danger">{{
								errors.branch[0]
							}}</small>
						</div>
					</div>
					<b-button
						class="mt-3 modal-button col-lg-12"
						variant="outline-success"
						type="submit"
						>Submit</b-button
					>
				</form>
				<b-button
					class="mt-2 modal-button d-block w-100"
					variant="outline-danger"
					@click="hideModal"
					>Close Me</b-button
				>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import axios from "axios";
	import MemberBankInfoView from "./MemberBankInfoView";
	const base_route = "payment-history";
	export default {
		props: ["memberInfo"],
		components: { MemberBankInfoView },
		data() {
			return {
				txt: "Form",
				base_route: base_route,
				data: {
					text: "",
				},
				errors: {},
				focus_field: "",
				bank_name: "",
				account_name: "",
				account_no: "",
				routing_no: "",
				branch: "",
			};
		},
		methods: {
			resetModal() {
				this.bank_name = "";
				this.account_name = "";
				this.account_no = "";
				this.routing_no = "";
				this.branch = "";
				this.errors = {};
			},
			submit: function (e) {
				e.preventDefault();
				let currentObj = this;
				const config = {
					headers: { "content-type": "multipart/form-data" },
				};
				var form = document.getElementById("form");
				var formData = new FormData(form);

				axios
					.post("/member/payment-history", formData, config)
					.then((res) => {
						this.$parent.ckeckBankInfo();
						if (res) {
							this.$toastr(
								"success",
								"Information Submitted Successfully",
								"Success!!"
							);
						}
					})
					.catch((error) => {
						this.loaded = true;
						if (error.response.status === 422) {
							this.errors = error.response.data.errors || {};
							this.focus_field = Object.keys(this.errors)[0];
						}
					});
			},

			onFileChange(e) {
				let files = e.target.files || e.dataTransfer.files;
				if (!files.length) this.file = e.target.files[0];
			},
			handleSubmit() {
				this.$nextTick(() => {
					this.$bvModal.hide("modal-prevent-closing");
				});
			},
			hideModal() {
				this.$refs["my-modal"].hide();
			},
		},
	};
</script>

<style scoped>
	.btn-warning {
		background: #ffc107;
		color: #007f3d;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 1.1px;
		text-align: center;
		border: 2px solid #ffc107;
		padding: 5px 20px;
		box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
	.btn-success {
		background: #007f3d;
		color: white;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 1.1px;
		text-align: center;
		border: 2px solid #007f3d;
		padding: 5px 20px;
		box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
</style>