<template>
  <!--header-->
  <div class="GlobelSearch">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="container-wrapper">
      <!-- <pre>{{ $root.search_result.data }}</pre> -->
      <!-- v-if="Object.keys($root.search_result.data).length>0" -->
      <ul class="card card-body shadow-lg nav flex-column my-4 p-5">
        <slot v-for="(result,index) in $root.search_result.data">
          <li class="nav-item" :key="index">
            <h3>
              <a :href="result.slug" target="_blank">
                <span v-html="$options.filters.highlight(result.title,$root.search_result.keyword)"></span>
              </a>
              <!-- <router-link
                :to="'/'+result.url"
                v-html="$options.filters.highlight(result.title,$root.search_result.keyword)"
              ></router-link>-->
            </h3>
            <!-- <p
              v-html="$options.filters.highlight(result.searchable.description.substring(0,200),$root.search_result.keyword)"
            ></p>-->
          </li>
        </slot>
      </ul>
      <!-- <div class="card card-body shadow-lg text-center py-5">
        <h3 class="text-danger py-5">Data Not Found</h3>
      </div>-->
    </div>
  </div>
</template>
<script>
import Banner from "./PageBanner";
const breadcumb = [
  {
    title: "Global Search"
  }
];
export default {
  components: {
    Banner
  },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Global Search"
    };
  },
  created() {},
  methods: {},
  mounted() {
    setTimeout(() => (this.$root.loading = false), 200);
  }
};
</script>
<style>
span.highlight {
  background-color: yellow;
  font-weight: bold;
}
</style>