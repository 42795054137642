<template>
	<!--header-->
	<header class="header">
		<!--search-box-->
		<div class="search-box" v-bind:class="show_search ? 'show' : ''">
			<div class="box">
				<form
					ref="searchform2"
					v-on:submit.prevent="searchsubmit"
					id="searchform2"
				>
					<input
						list="browsers"
						v-model="search_data"
						type="search"
						v-on:keyup="searchsubmit()"
						placeholder="What are you looking for?"
					/>
					<span class="mdi mdi-magnify"></span>
					<!--datalist-->
					<div class="datalist" v-if="search_list">
						<slot v-if="!searching">
							<ul
								v-if="search_result && Object.keys(search_result).length > 0"
								class="nav flex-column"
							>
								<slot v-if="search_result">
									<li
										v-for="(result, index) in search_result"
										:key="index"
										class="nav-item"
									>
										<a
											:href="result.url"
											v-if="result.details != 'Main Menu'"
											target="_blank"
											class="nav-link searchRes"
										>
											<h4
												v-html="
													$options.filters.highlight(result.title, search_data)
												"
											></h4>
											<p v-if="result.details" v-html="result.details"></p>
										</a>
										<slot v-else>
											<router-link
												class="nav-link searchRes"
												target="_blank"
												v-if="result.type === 'content'"
												:to="{ name: 'content', params: { slug: result.slug } }"
											>
												<h4
													v-html="
														$options.filters.highlight(
															result.title,
															search_data
														)
													"
												></h4>
												<p v-if="result.details" v-html="result.details"></p>
											</router-link>
											<a
												class="nav-link searchRes"
												target="_blank"
												v-else-if="result.type === 'outside_website'"
												:href="result.url"
											>
												<h4
													v-html="
														$options.filters.highlight(
															result.title,
															search_data
														)
													"
												></h4>
												<p v-if="result.details" v-html="result.details"></p>
											</a>
											<router-link
												class="nav-link searchRes"
												target="_blank"
												v-else-if="result.params !== null"
												:to="{
													name: result.url,
													params: { type: result.params },
												}"
											>
												<h4
													v-html="
														$options.filters.highlight(
															result.title,
															search_data
														)
													"
												></h4>
												<p v-if="result.details" v-html="result.details"></p>
											</router-link>
											<router-link
												class="nav-link searchRes"
												target="_blank"
												v-else
												:to="{ name: result.url }"
											>
												<h4
													v-html="
														$options.filters.highlight(
															result.title,
															search_data
														)
													"
												></h4>
												<p v-if="result.details" v-html="result.details"></p>
											</router-link>
										</slot>
									</li>
								</slot>
							</ul>
							<div v-else class="text-center pt-5 mt-5" style="font-size: 13px">
								No Data Found!!
							</div>
						</slot>
						<div
							v-if="searching"
							class="text-center pt-5 mt-5"
							style="font-size: 13px"
						>
							<i
								class="fa fa-circle-o-notch fa-spin"
								style="font-size: 24px"
							></i>
							<br />Searching!!
						</div>
					</div>
					<!--/datalist-->
				</form>
				<button
					type="button"
					class="close text-light search-box-close"
					@click="search(false)"
				>
					<i class="mdi mdi-close"></i>
				</button>
			</div>
		</div>
		<!--//search-box-->
		<div class="container-wrapper">
			<!--topheader-->
			<div class="top-header">
				<ul class="nav align-items-center justify-content-end">
					<!-- member login & Register -->
					<li class="nav-item">
						<router-link
							v-if="
								$root.memberLoginCheck &&
								Object.keys($root.memberData).length > 0
							"
							:to="{ name: 'member-dashboard' }"
							class="nav-link active"
						>
							<b>{{ $root.memberData.company_name }}</b>
						</router-link>
						<!-- <a
              href="#"
              v-else
              @click="clickToCheckLogin('member-login','')"
              class="nav-link"
						>Member Login</a>-->
						<router-link v-else :to="{ name: 'member-login' }" class="nav-link"
							>Member Login</router-link
						>
					</li>
					<li class="nav-item">
						<router-link
							v-if="
								$root.regMemberLoginCheck &&
								Object.keys($root.regMemberData).length > 0
							"
							:to="{ name: 'reg-member.dashboard' }"
							class="nav-link active"
						>
							<b>{{ $root.regMemberData.company_name }}</b>
						</router-link>
						<router-link
							v-else
							:to="{ name: 'memberRegistration' }"
							class="nav-link"
							>Become a Member</router-link
						>
					</li>
					<!-- member login & Register -->

					<li class="nav-item" v-for="(item, index) in top_bar" :key="index">
						<router-link
							class="nav-link"
							v-if="item.type === 'content'"
							:to="{ name: 'content', params: { slug: item.slug } }"
							>{{ item.title }}</router-link
						>
						<a
							class="nav-link"
							v-else-if="item.type === 'outside_website'"
							:href="item.url"
							target="_blank"
							>{{ item.title }}</a
						>
						<router-link
							class="nav-link"
							v-else-if="item.params !== null"
							:to="{ name: item.url, params: { type: item.params } }"
							>{{ item.title }}</router-link
						>
						<router-link class="nav-link" v-else :to="{ name: item.url }">
							{{ item.title }}
						</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'ecommerce.home' }" class="nav-link">
							<b>DigitalShop</b>
						</router-link>
					</li>
				</ul>
			</div>
			<!--//topheader-->
			<div class="main-header">
				<nav class="navbar navbar-expand-lg p-lg-0">
					<router-link to="/" class="navbar-brand logo">
						<img :src="basislogo" alt="basislogo" />
					</router-link>
					<button
						class="navbar-toggler mMenu"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span class="fas fa-bars"></span>
					</button>

					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item">
								<router-link to="/" class="nav-link">Home</router-link>
							</li>
							<slot v-for="item in menus">
								<slot v-if="item.menu_look_type === 'mega'">
									<li :key="item.id" class="nav-item dropdown">
										<a
											href="#"
											class="nav-link dropdown-toggle"
											id="navbarDropdown"
											role="button"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
											>{{ item.title }}</a
										>
										<div
											:class="item.ClassAdd"
											class="dropDown"
											aria-labelledby="navbarDropdown"
										>
											<div class="row m-0">
												<div
													class="col-lg-4 pr-0"
													v-for="megamenu in item.all_children_menus"
													:key="megamenu.id"
												>
													<h4 class="menu-head">
														<slot v-if="megamenu.id == '51'">
															<router-link
																:to="{
																	name: 'allEvents',
																	params: { market: 'local', type: 'awards' },
																}"
																>{{ megamenu.title }}</router-link
															>
														</slot>
														<slot v-else-if="megamenu.id == '55'">
															<router-link
																:to="{
																	name: 'allEvents',
																	params: { market: 'local', type: 'events' },
																}"
																>{{ megamenu.title }}</router-link
															>
														</slot>
														<slot v-else>
															<span v-if="megamenu.url == '#'">
																{{ megamenu.title }}
															</span>
															<router-link
																v-else-if="megamenu.type === 'content'"
																:to="{
																	name: 'content',
																	params: { slug: megamenu.slug },
																}"
																>{{ megamenu.title }}</router-link
															>
															<router-link
																v-else
																:to="{ name: megamenu.url }"
																>{{ megamenu.title }}</router-link
															>
														</slot>
													</h4>
													<ul>
														<slot v-if="megamenu.id == '51'">
															<li
																v-for="(item, index) in awards"
																:key="index"
																class="subMenu"
															>
																<router-link
																	:to="{
																		name: 'event.detail',
																		params: { slug: item.slug },
																	}"
																>
																	{{ getPostBody(item.title, 70) }}
																</router-link>
															</li>
														</slot>
														<slot v-else-if="megamenu.id == '55'">
															<li
																v-for="(item, index) in events"
																:key="index"
																class="subMenu"
															>
																<router-link
																	:to="{
																		name: 'event.detail',
																		params: { slug: item.slug },
																	}"
																>
																	{{ getPostBody(item.title, 70) }}
																</router-link>
															</li>
														</slot>
														<slot
															v-else
															v-for="sub_menu in megamenu.all_children_menus"
														>
															<li :key="sub_menu._id" class="subMenu">
																<router-link
																	v-if="sub_menu.type === 'content'"
																	:to="{
																		name: 'content',
																		params: { slug: sub_menu.slug },
																	}"
																	>{{ sub_menu.title }}</router-link
																>
																<a
																	v-else-if="
																		sub_menu.type === 'outside_website'
																	"
																	:href="sub_menu.url"
																	target="_blank"
																	>{{ sub_menu.title }}</a
																>
																<router-link
																	v-else-if="sub_menu.params !== null"
																	:to="{
																		name: sub_menu.url,
																		params: { type: sub_menu.params },
																	}"
																	>{{ sub_menu.title }}</router-link
																>
																<router-link
																	v-else
																	:to="{ name: sub_menu.url }"
																	>{{ sub_menu.title }}</router-link
																>
															</li>
														</slot>
													</ul>
												</div>
											</div>
										</div>
									</li>
								</slot>
								<slot v-else>
									<li
										class="nav-item"
										:key="item._id"
										v-bind:class="
											Object.keys(item.all_children_menus).length > 0
												? 'nav-item dropdown'
												: ''
										"
									>
										<slot
											v-if="Object.keys(item.all_children_menus).length > 0"
										>
											<a
												class="nav-link dropdown-toggle"
												href="#"
												id="navbarDropdown"
												role="button"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
												>{{ item.title }}</a
											>
											<div
												class="dropdown-menu"
												aria-labelledby="navbarDropdown"
											>
												<ul>
													<slot v-for="sub_menu in item.all_children_menus">
														<li :key="sub_menu._id" class="subMenu">
															<router-link
																v-if="sub_menu.type === 'content'"
																:to="{
																	name: 'content',
																	params: { slug: sub_menu.slug },
																}"
																>{{ sub_menu.title }}</router-link
															>
															<a
																v-else-if="sub_menu.type === 'outside_website'"
																:href="sub_menu.url"
																target="_blank"
																>{{ sub_menu.title }}</a
															>
															<router-link
																v-else-if="sub_menu.params !== null"
																:to="{
																	name: sub_menu.url,
																	params: { type: sub_menu.params },
																}"
																>{{ sub_menu.title }}</router-link
															>
															<router-link
																v-else
																:to="{ name: sub_menu.url }"
																>{{ sub_menu.title }}</router-link
															>
														</li>
													</slot>
												</ul>
											</div>
										</slot>
										<slot v-else>
											<router-link
												v-if="item.type === 'content'"
												:to="{ name: 'content', params: { slug: item.slug } }"
												class="nav-link"
												>{{ item.title }}</router-link
											>
											<a
												v-else-if="item.type === 'outside_website'"
												:href="item.url"
												class="nav-link"
												target="_blank"
												>{{ item.title }}</a
											>
											<router-link
												v-else
												:to="{ name: item.url }"
												class="nav-link"
												>{{ item.title }}</router-link
											>
										</slot>
									</li>
								</slot>
							</slot>
						</ul>
						<!--mobile search-->
						<div
							class="mobile-search-box"
							v-bind:class="show_search ? 'show' : ''"
						>
							<div class="box">
								<form
									ref="searchform"
									v-on:submit.prevent="searchsubmit"
									id="searchform"
								>
									<div class="input-group mb-2">
										<input
											list="browsers"
											v-model="search_data"
											type="search"
											class="form-control shadow-sm"
											v-on:keyup="searchsubmit()"
											placeholder="What are you looking for?"
										/>
										<div class="input-group-prepend">
											<div class="input-group-text">
												<span class="mdi mdi-magnify"></span>
											</div>
										</div>
									</div>
									<!--datalist-->
									<div class="datalist" v-if="search_list">
										<ul
											v-if="
												!searching &&
												search_result &&
												Object.keys(search_result).length > 0
											"
											class="nav flex-column"
										>
											<slot v-if="search_result">
												<li
													v-for="(result, index) in search_result"
													:key="index"
													class="nav-item"
												>
													<a
														:href="result.url"
														v-if="result.details != 'Main Menu'"
														target="_blank"
														class="nav-link searchRes"
													>
														<h4
															v-html="
																$options.filters.highlight(
																	result.title,
																	search_data
																)
															"
														></h4>
														<p
															v-if="result.details"
															v-html="result.details"
														></p>
													</a>
													<slot v-else>
														<router-link
															class="nav-link searchRes"
															target="_blank"
															v-if="result.type === 'content'"
															:to="{
																name: 'content',
																params: { slug: result.slug },
															}"
														>
															<h4
																v-html="
																	$options.filters.highlight(
																		result.title,
																		search_data
																	)
																"
															></h4>
															<p
																v-if="result.details"
																v-html="result.details"
															></p>
														</router-link>
														<a
															class="nav-link searchRes"
															target="_blank"
															v-else-if="result.type === 'outside_website'"
															:href="result.url"
														>
															<h4
																v-html="
																	$options.filters.highlight(
																		result.title,
																		search_data
																	)
																"
															></h4>
															<p
																v-if="result.details"
																v-html="result.details"
															></p>
														</a>
														<router-link
															class="nav-link searchRes"
															target="_blank"
															v-else-if="result.params !== null"
															:to="{
																name: result.url,
																params: { type: result.params },
															}"
														>
															<h4
																v-html="
																	$options.filters.highlight(
																		result.title,
																		search_data
																	)
																"
															></h4>
															<p
																v-if="result.details"
																v-html="result.details"
															></p>
														</router-link>
														<router-link
															class="nav-link searchRes"
															target="_blank"
															v-else
															:to="{ name: result.url }"
														>
															<h4
																v-html="
																	$options.filters.highlight(
																		result.title,
																		search_data
																	)
																"
															></h4>
															<p
																v-if="result.details"
																v-html="result.details"
															></p>
														</router-link>
													</slot>
												</li>
											</slot>
										</ul>
										<div
											v-else
											class="text-center pt-5 mt-5"
											style="font-size: 13px"
										>
											No Data Found!!
										</div>
										<div
											v-if="searching"
											class="text-center pt-5 mt-5"
											style="font-size: 13px"
										>
											<i
												class="fa fa-circle-o-notch fa-spin"
												style="font-size: 24px"
											></i>
											<br />Searching!!
										</div>
									</div>
									<!--/datalist-->
								</form>
								<button
									type="button"
									class="close text-light search-box-close"
									@click="search(false)"
								>
									<i class="mdi mdi-close"></i>
								</button>
							</div>
						</div>
						<!--/mobile search-->
						<ul class="social-menu navbar-nav">
							<!-- <li class="nav-item"><a href="#" class="nav-link btn btn-login">Member Login</a></li> -->
							<li class="nav-item">
								<a
									href="javascript:void(0)"
									class="nav-link top-search search-box-open text-danger"
									@click="search(true)"
								>
									<i class="flaticon-magnifying-glass"></i>
								</a>
							</li>
							<li
								class="nav-item"
								v-for="(item, index) in $root.socialLink"
								:key="index"
							>
								<a target="_blank" :href="item.url" class="nav-link">
									<p v-html="item.icon"></p>
								</a>
							</li>
							<!-- <li class="nav-item"><a href="#" class="nav-link"><i class=""></i></a></li> -->
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</header>
	<!--//header-->
</template>

<script>
	export default {
		name: "main-header",
		data() {
			return {
				show_search: false,
				search_list: false,
				searching: false,
				search_data: null,
				search_result: {},
				basislogo: this.$root.baseurl + "/public/images/logo.svg",
				menus: {},
				top_bar: {},
				awards: {},
				events: {},
				searchRes: {
					members: [],
					services: [],
					contents: [],
				},
				becomeMember: this.$root.baseurl + "/public/images/becomemember.svg",
			};
		},
		methods: {
			searchsubmit: function () {
				this.search_list = true;
				this.searching = true;
				let keyword = this.search_data;
				let route_name = this.$route.name;
				setTimeout(() => {
					axios
						.get("/globelSearch", { params: { keyword: keyword } })
						.then((response) => {
							this.search_result = response.data.data;
							// if (datas.members) {
							//   this.searchRes.members = datas.members
							// }
							// if (datas.services) {
							//   this.searchRes.services = datas.services;
							// }
							// if (datas.contents) {
							//   this.searchRes.contents = datas.contents;
							// }
							this.searching = false;
						});
				}, 400);

				// if (keyword && route_name != "globelSearch") {
				//   this.$router.push({ name: "globelSearch" });
				// }

				// this.search(false);
			},
			search: function (status) {
				this.show_search = status;
				this.search_data = null;
				this.search_list = false;
			},
			handleSCroll(event) {
				let header = document.querySelector(".header");
				if (window.scrollY > 100 && !header.className.includes("sticky")) {
					header.classList.add("sticky");
				} else if (window.scrollY < 100) {
					header.classList.remove("sticky");
				}
			},
			get_data() {
				axios
					.get("/all_layout_data/topMenu")
					.then((respons) => {
						this.menus = respons.data.header;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			get_topbar() {
				axios
					.get("/all_layout_data/top_bar")
					.then((respons) => {
						this.top_bar = respons.data.top_bar;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			get_events_awards() {
				axios.get("/get-events-awards-menu").then((respons) => {
					this.events = respons.data.events;
					this.awards = respons.data.awards;
				});
			},
			subIsActive(input) {
				const paths = Array.isArray(input) ? input : [input];
				return paths.some((path) => {
					return this.$route.path.indexOf(path) === 0; // current path starts with this path string
				});
			},
		},
		created() {
			window.addEventListener("scroll", this.handleSCroll);
		},
		mounted() {
			this.get_data();
			this.get_topbar();
			this.get_events_awards();
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleSCroll);
		},
		updated() {
			if (this.menus) {
				setTimeout(() => (this.$root.loading_full = false), 350);
			}
		},
	};
</script>
<style>
	.router-link-active {
		color: #6dc8be;
	}
	.parent-active {
		color: #17c4af !important;
	}
	.highlight {
		background-color: yellow;
		font-weight: bold;
	}
	.searchRes {
		min-height: 82px;
	}
</style>