<template>
	<div class="about">
		<!--page-banner-->
		<div class="page-banner py-3">
			<div class="container-wrapper">
				<span class="shape"></span>
				<span class="shape2"></span>
				<span class="shape3"></span>
				<div class="row align-items-center">
					<div class="col-lg-6">
						<h3 class="mb-0 text-capitalize">Career</h3>
						<!-- <h3 class="mb-0 text-capitalize">{{ getPostBody(msg,35) }}</h3> -->
					</div>
					<div class="col-lg-6">
						<nav aria-label="breadcrumb">
							<ol
								class="
									breadcrumb
									justify-content-end
									bg-transparent
									mb-0
								"
							>
								<li class="breadcrumb-item">
									<router-link class="breadcrumb-item" to="/"
										>Home</router-link
									>
								</li>
								<li
									class="breadcrumb-item text-capitalize"
									v-for="(route, index) in data"
									:key="index"
								>
									<span
										v-if="
											index ==
												Object.keys(data).length - 1 ||
											!route.route_name
										"
										>{{
											getPostBody(route.title, 15)
										}}</span
									>
									<router-link
										:to="{
											name: route.route_name,
											params: { type: route.params },
										}"
										class="breadcrumb-item"
										v-else-if="
											Object.keys(data).length - 1 ||
											!route.news_type
										"
										>{{
											getPostBody(route.title, 15)
										}}</router-link
									>
									<router-link
										:to="{ name: route.route_name }"
										class="breadcrumb-item"
										v-else
										>{{
											getPostBody(route.title, 15)
										}}</router-link
									>
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!--page-banner end-->

		<div class="content-body py-4">
			<div class="container-wrapper">
				<div class="row">
					<div class="col-lg-12">
						<!-- <Loading v-if="loading" /> -->
						<router-view></router-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Affix } from "vue-affix";
	import PageBanner from "../../components/elements/PageBanner";
	import leftmenu from "../../components/elements/leftmenu";
	import Loading from "./../../components/Loader";
	export default {
		name: "CareerLayout",
		components: {
			PageBanner,
			Loading,
			Affix,
			leftmenu
		},
		data() {
			return {
				loading: true,
				sec: 1500,
				count: 0,
				content_breadcrumbTitle: "Career",
				content_breadcumb: []
			};
		},
		methods: {
			getStorage: function() {
				var vm = this;
				setTimeout(function() {
					vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
					vm.content_breadcrumbTitle = vm.getLocalStgString(
						"breadcrumbTitle"
					);
				}, 500);
				setTimeout(function() {
					vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
					vm.content_breadcrumbTitle = vm.getLocalStgString(
						"breadcrumbTitle"
					);
				}, 1000);
				setTimeout(function() {
					vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
					vm.content_breadcrumbTitle = vm.getLocalStgString(
						"breadcrumbTitle"
					);
				}, 2000);
				setTimeout(function() {
					vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
					vm.content_breadcrumbTitle = vm.getLocalStgString(
						"breadcrumbTitle"
					);
				}, 3000);
				setTimeout(function() {
					vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
					vm.content_breadcrumbTitle = vm.getLocalStgString(
						"breadcrumbTitle"
					);
				}, 5000);
				setTimeout(function() {
					vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
					vm.content_breadcrumbTitle = vm.getLocalStgString(
						"breadcrumbTitle"
					);
				}, 10000);
				setTimeout(function() {
					vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
					vm.content_breadcrumbTitle = vm.getLocalStgString(
						"breadcrumbTitle"
					);
				}, 15000);
			}
		},
		mounted() {
			this.$root.loading = false;
		},
		watch: {
			$route: {
				handler: "getStorage",
				immediate: true
			}
		}
	};
</script>
