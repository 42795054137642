<template>
  <div class="col-lg-3">
    <div class="card shadow-sm mb-3">
      <div class="card-header">
        <div class="row bg-ash rounded-sm">
          <div class="col-md-12 px-0">
            <div class="row m-0 header-bg">
              <h6 class="m-auto">Check List</h6>
            </div>
          </div>
          <div class="col-md-12">
            <p
              class="font-weight-bold font-size-md mt-1"
            >For the testimonial of BASIS for Virtual Card individual must submit the below documents:</p>
            <ul class="fontsize-sm pl-3">
              <li>
                <p class="mb-0">Duly filled up form</p>
              </li>
              <li>
                <p
                  class="mb-0"
                >Photocopy of all issued National Identification papers (Passport, NID and Driving License)</p>
              </li>
              <li>
                <p class="mb-0">2 copies of passport size signed photo</p>
              </li>
              <li>
                <p class="mb-0">Proof of work relating to mobile apps/games</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow-sm">
      <div class="card-header">
        <div class="row mt-4 bg-ash rounded-sm">
          <div class="col-md-12 px-0">
            <div class="row m-0 header-bg">
              <h6 class="m-auto">Documentation and Instructions</h6>
            </div>

            <div class="row pl-3 m-auto">
              <p
                class="font-weight-bold font-size-md"
              >Application Submission Mode: (you can submit the application through following process)</p>

              <ol class="fontsize-sm pl-3">
                <li>
                  <p
                    class="mb-0"
                  >You can submit the hard copy application along with the necessary documents at BASIS office. (please bring all the original papers along with)</p>
                </li>
                <li>
                  <p class="mb-0">
                    You can email us the form and necessary documents as attachments at
                    <strong>service@basis.org.bd.</strong>
                  </p>
                </li>
                <li>
                  <p
                    class="mb-0"
                  >You can send us the form and necessary documents as attachments by courier.</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightSideVirtualCard"
};
</script>
