<template>
	<div
		v-if="
      (!$parent.loading &&
        member.info &&
        member.info.status == 1 &&
        $root.access &&
        $root.access['online_helpdesk']) ||
      $root.variable.rep_login ||
      $root.variable.aLogId
    "
	>
		<div class="row">
			<div class="col-12">
				<center>
					<h4>
						TICKET ID :
						<span>{{ data.ticket }}</span>
					</h4>
				</center>
				<h6>
					Ticket Status:
					<span v-if="data.status">
						<span v-if="data.status == 'd'" class="badge badge-danger">Closed</span>
						<span v-else class="badge badge-success">Open</span>
					</span>
				</h6>
				<h5>
					Subject :
					<span>{{ data.subject }}</span>
				</h5>
				<h5>
					Topics :
					<span>{{ data.topics }}</span>
				</h5>
				<h5>Message :</h5>
				<p>{{ data.message }}</p>
			</div>
		</div>

		<div
			v-if="data.ticket_reply"
			class="conersation-container"
			id="conersation-container"
			ref="conersation-container"
		>
			<div
				v-for="(message, index) in data.ticket_reply"
				:key="index"
				class="row user-message-demo px-5 my-4 d-flex"
				:class="
          message.member_id ? 'justify-content-end' : 'justify-content-start'
        "
			>
				<div class="col-md-9">
					<div class="row" :class="message.member_id ? 'flex-row-reverse' : ''">
						<div class="col">
							<img
								:src="
                  message.member_id
                    ? $root.baseurl + '/public' + data.member.logo
                    : 'https://image.flaticon.com/icons/svg/1067/1067566.svg'
                "
								height="40px"
								width="40px"
								style="
                  border-radius: 50%;
                  object-fit: contain;
                  border: 0.5px solid #000000;
                "
							/>
							<p style="    font-size: 10px;" v-if="message.member">{{message.member.company_head}}</p>
							<p style="    font-size: 10px;" v-if="message.user">{{message.user.name}}</p>
						</div>
						<div class="col-10">
							<div
								class="width-100"
								:class="
                  message.member_id
                    ? 'talktext-container-user '
                    : 'talktext-container-admin'
                "
							>
								<div class="talktext d-flex justify-content-between">
									<p>{{ message.message }}</p>
								</div>
								<small>
									<p class="mb-0">{{ message.created_at }}</p>
								</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>No Conversation</div>

		<form v-on:submit.prevent="submit" class="row my-1" :class="data.status === 'a' ? '' : 'd-none'">
			<input type="hidden" v-model="storedData.ticket_system_id" />
			<div class="form-group col-12">
				<label for="exampleFormControlTextarea1">Write Message</label>
				<textarea
					class="form-control"
					id="exampleFormControlTextarea1"
					rows="3"
					name="message"
					v-model="storedData.message"
					placeholder="Write your message here"
				></textarea>
				<button type="submit" class="btn btn-primary mt-2">Send</button>
			</div>
		</form>
	</div>
	<div v-else-if="member.info && member.info.status == 0" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">Sorry!! your account is suspend</h4>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="member.info && member.info.status == 2" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">Sorry!! your account is cancel</h4>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="row">
		<div class="col-12">
			<div class="card">
				<div v-if="Object.keys($root.access).length > 0" class="card-body">
					<h4 class="text-center text-danger">Sorry!! you cannot access this page</h4>
				</div>
				<div v-else class="card-body">
					<div
						v-if="!$parent.loading"
						class="card-body d-flex align-items-center justify-content-center flex-column"
					>
						<i class="fa fa-spinner fa-spin" style="font-size: 24px; color: rgb(0, 127, 62)"></i>
						<span class="processing">processing</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	const breadcumb = [
		{
			title: "Online Healpdesk"
		}
	];
	export default {
		name: "onlineHealpdesk",
		data() {
			return {
				breadcumb: breadcumb,
				breadcrumbTitle: "View Ticket",
				data: { ticket: null },
				storedData: { ticket_system_id: "" },
				member: {
					info: {}
				}
			};
		},
		validators: {},
		methods: {
			submit: function() {
				this.$root.spinner = true;
				axios
					.post("/member/ticketReply", this.storedData)
					.then(respons => {
						this.$toastr("success", "Reply Successful", "Success!!");
						this.storedData.message = "";
						this.afterReply(this.storedData.ticket_system_id);
					})
					.catch(error => {
						console.log(error);
					})
					.then(alw => {
						this.$root.spinner = false;
					});
			},
			get_data(id) {
				this.$root.spinner = true;
				axios
					.get("/member/view-ticket" + "/" + id)
					.then(respons => {
						this.data = respons.data;
					})
					.catch(error => {})
					.then(alw => {
						this.$root.spinner = false;
					});
			},
			afterReply(id) {
				axios
					.get("/member/view-ticket" + "/" + id, { params: { type: "view" } })
					.then(respons => {
						this.data = respons.data;
					});
			}
		},
		mounted() {
			this.loggedIn();
			if (this.$route.params.tID) {
				this.get_data(this.$route.params.tID);
				this.storedData.ticket_system_id = this.$route.params.tID;
			}
			setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefd);
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		}
	};
</script>

<style scoped>
	.width-100 {
		width: 100%;
	}
	.talktext-container-admin {
		/* padding: 20px; */
		padding-top: 20px;
		padding-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
		border-radius: 20px 20px 0px 20px;
		/* border: 1px solid red; */
		background: #435f7a;
		color: #ffffff;
	}
	.talktext-container-user {
		/* padding: 20px; */
		padding-top: 20px;
		padding-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
		border-radius: 20px 20px 20px 0px;
		/* border: 1px solid red; */
		background: #e6eaea;
		color: #000000;
	}
	/* .conersation-container {
					  height: 283px;
					  overflow-y: auto;
					  overflow-x: hidden;
					} */
	#conersation-container {
		height: 283px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.conersation-container::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: #f5f5f5;
	}

	.conersation-container::-webkit-scrollbar {
		width: 12px;
		background-color: #f5f5f5;
	}

	.conersation-container::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #ececec;
	}
</style>
