<template>
    <div class="text-center mb-5">
        <img style="width: 25em;" :src="$root.baseurl + '/public/images/e-icon/empty-cart.png'" alt="">
        <h5 class="text-danger">Oops! Your {{ listName }} is empty!</h5>
        <p class="text-muted">
            Looks like you haven't added anything to your cart yet
        </p>
        <router-link class="btn btn-outline-primary mt-3"  :to="{name:'ecommerce.home'}">Shop Now</router-link>
    </div>
</template>

<script>
export default {
    props:['listName'],
    name: "EmptyCart"
}
</script>

<style scoped>

</style>
