<template>
	<!--counter-->
	<div class="num-counter" id="counter">
		<div class="container-wrapper">
			<div class="row justify-content-center">
				<div
					class="col-4 col-sm-4 col-lg-2"
					data-aos="fade-up"
					data-aos-offset="100"
					data-aos-duration="500"
				>
					<router-link class="text-black" :to="{ name: 'member-list' }">
						<div class="box">
							<svg
								version="1.1"
								id="counterIcon"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="278.5 0 443 560"
								enable-background="new 278.5 0 443 560"
								xml:space="preserve"
							>
								<path
									fill="none"
									stroke="#007F3D"
									stroke-width="10"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M664.26,150.2
                                c0-8.477-6.869-15.346-15.345-15.346H352.913c-8.476,0-15.346,6.869-15.346,15.346c0,8.477,6.87,15.345,15.346,15.345h296.002
                                C657.391,165.545,664.26,158.677,664.26,150.2z"
								/>
								<path
									fill="none"
									stroke="#007F3D"
									stroke-width="10"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M623.747,93.59
                                c0-8.477-6.87-15.346-15.348-15.346H393.425c-8.475,0-15.346,6.869-15.346,15.346c0,8.478,6.871,15.345,15.346,15.345h214.977
                                C616.877,108.936,623.747,102.068,623.747,93.59z"
								/>
								<path
									fill="none"
									stroke="#007F3D"
									stroke-width="10"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M298.29,210.649
                                l19.448,208.904c0.979,10.521,9.807,18.564,20.373,18.564h325.603c10.567,0,19.394-8.046,20.373-18.564l19.448-208.904
                                c0.533-5.731-1.371-11.423-5.249-15.677c-3.877-4.253-9.366-6.68-15.123-6.68h-364.5c-5.756,0-11.247,2.427-15.125,6.68
                                C299.661,199.226,297.756,204.918,298.29,210.649z"
								/>
								<path
									fill="none"
									stroke="#007F3D"
									stroke-width="10"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M412.249,374.81
                                c0,11.299,9.161,20.461,20.461,20.461h136.407c0.015-0.002,0.026-0.002,0.04,0c11.301,0,20.462-9.16,20.464-20.461
                                c0-0.569-0.024-1.134-0.07-1.689c-0.896-27.007-23.143-48.701-50.362-48.701h-14.684c15.102-8.346,25.356-24.431,25.356-42.87
                                c0-26.99-21.956-48.947-48.947-48.947c-26.989,0-48.946,21.959-48.946,48.947c0,18.438,10.255,34.524,25.356,42.87H462.64
                                C434.854,324.419,412.249,347.023,412.249,374.81z"
								/>
							</svg>
							<span
								class="count count-num timer count-title count-number"
								:data-to="data.total_member"
								data-speed="1500"
								>{{ data.total_member }}</span
							>
							<h6>Our Members</h6>
						</div>
					</router-link>
				</div>
				<div
					class="col-4 col-sm-4 col-lg-2"
					data-aos="fade-up"
					data-aos-offset="100"
					data-aos-duration="1000"
				>
					<router-link class="text-black" :to="'member-list?type=General'">
						<div class="box">
							<svg
								version="1.1"
								id="counterIcon"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="278.5 0 443 560"
								enable-background="new 278.5 0 443 560"
								xml:space="preserve"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M555.187,258.005c19.991,8.186,39.195,16.056,58.405,23.909
                                c17.305,7.078,34.661,14.035,51.892,21.305c3.133,1.32,6.45,3.324,8.49,5.945c20.112,25.826,34.979,54.217,38.696,87.18
                                c1.258,11.166-0.184,22.643-0.544,33.975c-0.179,5.77-3.069,8.172-9.145,8.004c-13.505-0.373-27.034-0.109-40.551-0.115
                                c-7.65-0.006-7.65-0.016-10.06-9.02c6.318,0,12.325,0,18.33-0.006c8.773,0,17.559-0.203,26.322,0.086
                                c4.632,0.15,6.561-1.133,7.169-6.111c2.739-22.625-2.501-43.779-11.067-64.375c-2.184-5.238-4.957-10.225-7.436-15.338
                                c-9.499-19.584-22.355-34.313-44.773-41.037c-22.314-6.689-43.465-17.268-66.752-26.807c2.427,5.79,4.217,10.215,6.127,14.586
                                c5.214,11.934,10.217,23.969,15.834,35.709c3.168,6.625,3.105,12.416-2.183,17.51c-6.817,6.568-9.18,13.629-7.252,23.432
                                c2.148,10.967-2.281,12.102-9.508,10.328c0-5.121,0.699-10.201-0.163-15c-1.373-7.684-0.088-14.248,5.844-19.34
                                c6.734-5.775,6.347-11.564,2.623-19.188c-7.691-15.721-14.281-31.982-21.375-47.996c-3.794-8.561-14.618-11.823-22.547-7.202
                                c-29.24,17.032-58.641,18.2-88.127,0.53c-4.242-2.543-14.056,0.497-16.303,5.484c-8.369,18.56-16.649,37.166-24.514,55.941
                                c-0.892,2.125,0.136,6.357,1.835,8.045c8.302,8.234,11.545,17.459,8.238,28.947c-0.576,1.996,0.264,4.691,1.145,6.77
                                c7.025,16.561,14.597,32.906,21.182,49.635c2.977,7.566,8.606,4.695,13.552,5.42c4.879,0.713,5.87-2.6,7.034-6.125
                                c8.675-26.277,17.414-52.533,25.803-77.795c-2.52-3.998-4.955-7.096-6.572-10.57c-4.69-10.092-9.118-20.305-13.518-30.529
                                c-2.794-6.496-1.349-9.121,5.337-9.199c14.938-0.17,29.884-0.166,44.825,0c6.387,0.07,8.062,2.666,5.745,8.648
                                c-4.442,11.479-8.994,22.92-13.767,34.26c-1.03,2.438-3.202,4.391-5.148,6.961c8.819,26.471,17.723,53.424,26.936,80.271
                                c0.604,1.762,3.475,3.629,5.479,3.857c4.918,0.559,9.991,0.654,14.907,0.08c6.41-0.746,9.134,2.08,9.793,8.699
                                c-2.276,0.146-4.481,0.422-6.694,0.422c-86.81,0.02-173.62-0.047-260.427,0.139c-6.681,0.016-9.646-1.791-10.746-8.773
                                c-4.417-27.996,1.566-54.107,13.083-79.381c1.667-3.664,3.623-7.191,5.36-10.826c10.373-21.684,24.972-37.379,49.363-45.096
                                c29.704-9.396,58.227-22.536,87.8-34.271c-13.39-15.124-22.248-32.191-27.55-51.185c-0.519-1.853-2.624-3.237-3.921-4.896
                                c-4.367-5.577-9.434-10.763-12.9-16.856c-8.178-14.359-7.671-28.817,1.028-43.113c1.766-2.902,2.664-6.927,2.452-10.338
                                c-1.007-16.117-2.134-32.185,2.158-48.042c2.902-10.717,7.879-19.778,18.885-24.249c1.24-0.504,2.624-2.064,2.887-3.348
                                c4.385-21.442,18.973-33.314,38.342-40.117c18.807-6.607,38.344-9.321,58.099-5.571c34.332,6.516,53.529,29.907,65.778,60.662
                                c3.579,8.988,4.929,18.037,3.62,27.781c-0.736,5.464,0.068,11.319,1.146,16.807c2.543,12.906,4.089,25.885,8.676,38.547
                                c5.716,15.77,0.759,30.674-9.505,43.901c-3.688,4.749-6.973,10.048-9.277,15.582C576.557,231.211,567.528,245.166,555.187,258.005z
                                M505.369,18.121c-13.323,2.994-27.032,4.861-39.877,9.254c-17.122,5.858-29.354,17.113-30.935,36.798
                                c-0.125,1.584-2.073,4.045-3.532,4.341c-13.112,2.67-17.652,12.741-18.788,23.852c-1.361,13.32-0.388,26.866-0.747,40.305
                                c-0.116,4.474,0.133,9.805-2.103,13.23c-9.857,15.089-11,28.708,1.199,42.321c9.617,10.726,15.732,22.517,20.084,35.753
                                c0.443,1.34,1.112,2.609,1.723,3.892c25.128,52.529,70.576,53.912,104.705,32.493c19.858-12.46,31.354-31.752,39.555-53.255
                                c1.379-3.608,3.498-7.19,6.093-10.037c10.754-11.776,15.903-25.384,9.822-40.443c-4.176-10.335-5.623-20.814-7.043-31.494
                                c-1.104-8.392-3.063-16.959-2.311-25.236c0.903-9.9-1.135-18.665-4.743-27.52C564.533,38.179,538.536,19.325,505.369,18.121z
                                M426.125,276.785c-4.144,1.525-6.315,2.278-8.453,3.12c-18.923,7.482-37.389,16.591-56.876,22.054
                                c-23.655,6.631-37.242,22.088-47.519,42.863c-11.855,23.967-19.977,48.531-17.985,75.703c0.446,6.115,2.114,8.908,9.203,8.844
                                c41.715-0.373,83.437-0.188,125.158-0.188c2.253,0,4.505,0,7.76,0c-1.434-3.623-2.314-6.021-3.319-8.369
                                c-6.162-14.355-12.576-28.6-18.376-43.098c-1.586-3.961-2.641-8.971-1.67-12.943c1.928-7.896,0.012-13.846-5.676-19.111
                                c-6.889-6.377-7.349-13.268-3.223-21.756C412.368,309.051,418.643,293.74,426.125,276.785z M473.154,428.727
                                c18.048,0,35.25,0,53.578,0c-8.865-26.602-17.478-52.449-26.09-78.299c-0.487,0.047-0.977,0.1-1.463,0.146
                                C490.563,376.449,481.945,402.322,473.154,428.727z M481.334,304.652c4.321,9.678,8.212,18.395,11.587,25.947
                                c5.229,0,9.262,0,13.843,0c3.192-8.092,6.586-16.729,10.217-25.947C504.715,304.652,493.635,304.652,481.334,304.652z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M566.427,385.783c6.162,0,12.354-0.371,18.481,0.111
                                c5.322,0.424,7.644-1.824,9.087-6.625c3.688-12.234,7.614-24.404,11.75-36.492c0.723-2.115,2.959-3.707,4.5-5.543
                                c1.737,1.873,4.302,3.459,5.073,5.672c4.291,12.281,8.31,24.668,12.063,37.123c1.332,4.406,3.585,5.938,8.143,5.846
                                c13.029-0.26,26.077-0.25,39.114,0.053c2.386,0.055,4.726,1.965,7.09,3.021c-1.355,2.02-2.3,4.588-4.142,5.973
                                c-10.407,7.84-20.925,15.547-31.633,22.969c-3.805,2.639-4.593,5.336-3.139,9.58c4.229,12.313,8.281,24.682,12.099,37.123
                                c0.683,2.227-0.094,4.904-0.197,7.371c-2.555-0.566-5.583-0.441-7.575-1.818c-10.911-7.551-21.653-15.35-32.252-23.326
                                c-3.244-2.439-5.595-2.32-8.752,0.051c-10.418,7.822-20.952,15.5-31.637,22.957c-2.085,1.459-5.039,1.674-7.588,2.463
                                c-0.18-2.809-1.151-5.852-0.399-8.381c3.649-12.238,7.581-24.396,11.786-36.449c1.522-4.348,0.665-6.861-3.11-9.479
                                c-10.703-7.43-21.227-15.127-31.58-23.037c-1.871-1.434-2.584-4.389-3.835-6.637c2.247-0.844,4.46-2.293,6.742-2.406
                                C553.136,385.574,559.784,385.783,566.427,385.783z M631.771,408.305c-6.875,0-12.887,0-19.158,0c0,2.795,0,5.162,0,7.57
                                c2.925,0.391,5.241,0.697,7.5,1c2.063,4.639-0.58,7.23-4.159,6.75c-4.309-0.58-10.122-2.008-12.057-5.092
                                c-2.149-3.428-1.842-9.521-0.452-13.75c1.622-4.916,6.874-6.215,11.971-5.182c2.891,0.588,5.635,1.861,8.565,2.867
                                c2.045-2.508,3.968-4.865,5.879-7.211c-10.68-9.201-26.102-8.641-34.853,0.963c-8.079,8.867-7.627,23.338,0.989,31.48
                                c8.693,8.217,26.543,8.705,34.083,0.826c0.887-0.92,1.575-2.424,1.616-3.686C631.875,419.473,631.771,414.094,631.771,408.305z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M650.715,543.648c-5.461-6.201-10.923-12.398-17.623-19.998
                                c-3.348,9.793-6.023,17.605-8.699,25.422c-0.428,0.016-0.862,0.029-1.291,0.049c-3.99-16.029-7.98-32.053-11.965-48.078
                                c-0.389-0.047-0.776-0.088-1.164-0.133c-3.984,16.023-7.964,32.053-11.942,48.08c-0.422,0.148-0.846,0.297-1.268,0.441
                                c-2.676-7.939-5.353-15.875-8.942-26.533c-6.521,8.148-11.826,14.771-17.126,21.391c-0.665-0.553-1.331-1.105-1.997-1.658
                                c6.128-24.998,12.22-50.008,18.457-74.98c0.418-1.674,1.663-3.461,3.04-4.521c6.551-5.041,13.28-9.846,20.096-14.844
                                c6.928,5.012,13.728,9.725,20.248,14.797c1.674,1.303,3.029,3.609,3.556,5.701c6.19,24.508,12.191,49.066,18.243,73.613
                                C651.798,542.813,651.259,543.23,650.715,543.648z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M505.369,18.121c33.167,1.205,59.164,20.058,73.104,54.254
                                c3.608,8.855,5.646,17.62,4.743,27.52c-0.753,8.276,1.206,16.844,2.311,25.236c1.42,10.68,2.867,21.159,7.043,31.494
                                c6.081,15.06,0.932,28.667-9.822,40.443c-2.595,2.847-4.714,6.429-6.093,10.037c-8.2,21.503-19.696,40.794-39.555,53.255
                                c-34.129,21.419-79.577,20.036-104.705-32.493c-0.611-1.283-1.28-2.552-1.723-3.892c-4.352-13.236-10.468-25.027-20.084-35.753
                                c-12.2-13.613-11.056-27.231-1.199-42.321c2.236-3.426,1.987-8.757,2.103-13.23c0.359-13.439-0.614-26.985,0.747-40.305
                                c1.135-11.11,5.676-21.182,18.788-23.852c1.459-0.295,3.408-2.757,3.532-4.341c1.582-19.685,13.813-30.94,30.935-36.798
                                C478.337,22.982,492.046,21.115,505.369,18.121z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M426.125,276.785c-7.482,16.956-13.757,32.266-20.976,47.12
                                c-4.126,8.488-3.667,15.379,3.223,21.756c5.688,5.266,7.604,11.215,5.676,19.111c-0.97,3.973,0.084,8.982,1.67,12.943
                                c5.8,14.498,12.214,28.742,18.376,43.098c1.005,2.348,1.885,4.746,3.319,8.369c-3.255,0-5.507,0-7.76,0
                                c-41.721,0-83.442-0.186-125.158,0.188c-7.089,0.064-8.757-2.729-9.203-8.844c-1.992-27.172,6.13-51.736,17.985-75.703
                                c10.277-20.775,23.864-36.232,47.519-42.863c19.488-5.463,37.954-14.572,56.876-22.054
                                C419.81,279.063,421.981,278.31,426.125,276.785z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M473.154,428.727c8.791-26.404,17.409-52.277,26.025-78.152
                                c0.486-0.047,0.976-0.1,1.463-0.146c8.612,25.85,17.225,51.697,26.09,78.299C508.404,428.727,491.202,428.727,473.154,428.727z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M481.334,304.652c12.301,0,23.381,0,35.646,0
                                c-3.631,9.219-7.024,17.855-10.217,25.947c-4.581,0-8.614,0-13.843,0C489.546,323.047,485.655,314.33,481.334,304.652z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M631.771,408.305c0,5.789,0.104,11.168-0.075,16.537
                                c-0.041,1.262-0.729,2.766-1.616,3.686c-7.54,7.879-25.39,7.391-34.083-0.826c-8.616-8.143-9.068-22.613-0.989-31.48
                                c8.751-9.604,24.173-10.164,34.853-0.963c-1.911,2.346-3.834,4.703-5.879,7.211c-2.931-1.006-5.675-2.279-8.565-2.867
                                c-5.097-1.033-10.349,0.266-11.971,5.182c-1.39,4.229-1.697,10.322,0.452,13.75c1.935,3.084,7.748,4.512,12.057,5.092
                                c3.579,0.48,6.222-2.111,4.159-6.75c-2.259-0.303-4.575-0.609-7.5-1c0-2.408,0-4.775,0-7.57
                                C618.885,408.305,624.896,408.305,631.771,408.305z"
								/>
							</svg>
							<span
								class="count count-num timer count-title count-number"
								:data-to="data.g_member"
								data-speed="1500"
								>{{ data.g_member }}</span
							>
							<h6>General Members</h6>
						</div>
					</router-link>
				</div>
				<div
					class="col-4 col-sm-4 col-lg-2"
					data-aos="fade-up"
					data-aos-offset="100"
					data-aos-duration="1500"
				>
					<router-link class="text-black" :to="'member-list?type=Associate'">
						<div class="box">
							<svg
								version="1.1"
								id="counterIcon"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="278.5 0 443 560"
								enable-background="new 278.5 0 443 560"
								xml:space="preserve"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M368.902,412.063c-4.595,7.674-8.729,14.154-12.403,20.887
                                c-2.055,3.766-4.646,5.107-8.936,5.064c-19.553-0.215-39.108-0.082-58.664-0.098c-8.079-0.01-9.316-1.506-7.35-9.361
                                c10.843-43.338,26.581-84.852,44.692-125.674c7.691-17.34,18.153-27.774,36.721-33.115c26.27-7.56,51.688-18.075,77.668-27.396
                                c-3.262-8.349-7.253-16.202-9.324-24.535c-2.12-8.538-6.288-15.445-11.117-22.586c-5.322-7.875-9.386-16.76-12.945-25.625
                                c-2.6-6.471-0.768-12.82,4.155-18.276c1.419-1.577,1.885-4.608,1.741-6.905c-1.205-18.992-1.009-37.828,6.202-55.814
                                c2.105-5.25,5.035-10.207,7.959-15.078c9.493-15.816,25.478-19.477,41.896-20.655c22.964-1.645,46.022-1.913,69.039-2.857
                                c7.366-0.3,15.055,0.474,20.467-6.572c0.719-0.933,4.556-0.85,5.72,0.166c6.991,6.098,14.078,12.356,11.146,23.337
                                c-0.271,1.041,0.193,2.573,0.84,3.525c15.199,22.484,14.715,47.086,9.869,72.288c-0.414,2.154-0.934,4.289-1.379,6.438
                                c-0.035,0.186,0.119,0.413,0.188,0.62c9.485,6.827,11.17,11.733,7.615,22.93c-0.994,3.125-1.889,6.471-3.709,9.115
                                c-10.299,14.979-16.439,31.776-22.119,48.827c-1.299,3.888-3.396,7.511-5.365,11.779c14.625,5.338,29.005,10.599,43.388,15.833
                                c16.897,6.152,33.84,12.174,50.66,18.536c2.863,1.085,6.138,3.035,7.619,5.535c22.212,37.563,40.843,76.689,49.534,119.814
                                c1.937,9.629,2.366,19.574,3.373,29.383c0.397,3.846-0.91,6.385-5.41,6.34c-9.866-0.09-19.732-0.025-29.806-0.025
                                c-0.032-6.996,2.941-9.582,9.604-8.896c5.166,0.543,10.441,0.113,17.34,0.113c-1.959-11.576-3.153-22.414-5.682-32.941
                                c-9.146-38.096-25.779-73.107-45.311-106.834c-0.92-1.582-2.474-3.279-4.11-3.885c-24.648-9.104-49.359-18.037-74.505-27.171
                                c-9.286,17.919-18.45,35.613-27.629,53.308c-2.828,5.449-5.778,10.842-8.482,16.352c-2.205,4.484-5.011,4.855-8.97,2.162
                                c-8.284-5.633-16.685-11.1-26.267-17.441c1.459,13.998,2.709,26.551,4.077,39.1c2.397,22.039,4.979,44.063,7.172,66.123
                                c0.333,3.342-1.019,6.852-1.748,11.229c6.19,0,13.295,0,20.397,0c5.714,0,11.459,0.381,17.13-0.105
                                c6.277-0.537,10.215,1.051,10.161,8.609c-1.974,0.098-4.065,0.279-6.158,0.279c-54.932,0.016-109.861-0.064-164.791,0.139
                                c-5.285,0.016-8.471-1.604-11.114-6.088C378.108,425.379,373.618,419.168,368.902,412.063z M500.806,296.363
                                c2.168-2.324,3.965-4.175,5.687-6.091c11.154-12.399,21.744-25.368,33.591-37.065c13.149-12.99,20.591-28.596,25.548-45.947
                                c1.411-4.935,4.115-9.52,6.411-14.176c4.242-8.617,8.923-17.032,12.791-25.809c1.523-3.458,1.446-7.773-4.358-7.967
                                c-5.854-0.193-6.764-3.133-5.557-8.34c1.931-8.315,3.903-16.708,4.704-25.178c1.85-19.475-0.203-38.136-13.873-53.781
                                c-1.154-1.323-1.884-4.879-1.058-5.768c5.504-5.91,0.316-8.659-1.362-10.69c-9.389,1.309-17.275,3.222-25.194,3.37
                                c-25.655,0.475-51.268-0.188-76.816,4.037c-15.636,2.583-25.252,10.987-31.567,24.531c-1.476,3.166-2.747,6.462-3.764,9.805
                                c-5.083,16.698-4.791,33.727-3.221,50.884c0.239,2.607-0.555,6.708-2.329,7.846c-5.719,3.667-6.5,8.633-3.876,13.627
                                c4.955,9.445,10.926,18.352,16.299,27.582c1.926,3.307,3.509,6.84,4.973,10.383c4.582,11.08,6.929,23.829,13.968,32.993
                                C466.709,260.017,484.037,277.565,500.806,296.363z M290.271,429.092c19.673,0,37.425,0.117,55.173-0.188
                                c1.773-0.033,4.011-2.322,5.164-4.121c4.619-7.199,8.812-14.666,13.332-21.938c3.254-5.225,6.278-5.221,9.781-0.041
                                c4.675,6.902,9.458,13.76,13.697,20.932c2.433,4.115,5.322,5.527,10.064,5.488c27.458-0.215,54.918-0.096,82.377-0.117
                                c2.116,0,4.232-0.172,5.249-0.219c-0.435-4.406-1.457-8.311-1.081-12.078c2.336-23.588,5-47.141,7.521-70.715
                                c1.135-10.617,2.189-21.24,3.437-33.377c-9.375,6.209-17.643,11.455-25.652,17.072c-4.587,3.215-7.455,2.367-9.891-2.488
                                c-4.418-8.826-9.043-17.543-13.589-26.305c-7.356-14.167-14.713-28.33-22.238-42.816c-24.626,8.796-48.8,17.263-72.8,26.197
                                c-3.265,1.219-6.907,4.244-8.257,7.353c-12.825,29.536-25.714,59.063-37.479,89.032
                                C299.107,395.984,295.394,412.084,290.271,429.092z M494.056,302.365c-15.415-17.176-30.306-33.828-45.347-50.345
                                c-0.906-0.995-3.206-1.545-4.546-1.175c-3.897,1.078-7.638,2.728-11.843,4.309c11.524,22.208,22.678,43.708,34.062,65.651
                                C475.831,314.506,484.691,308.605,494.056,302.365z M569.552,255.174c-2.328-0.897-4.071-1.313-5.547-2.182
                                c-6.363-3.751-10.644-2.083-15.397,3.625c-11.342,13.622-23.542,26.528-35.361,39.75c-1.679,1.877-3.175,3.912-5.054,6.234
                                c9.432,6.266,18.242,12.119,27.352,18.184C547.047,298.588,558.108,277.257,569.552,255.174z M501.498,354.047
                                c-0.413,0.064-0.821,0.133-1.234,0.199c-2.12,19.367-3.873,38.781-6.543,58.068c-1.003,7.26,1.39,11.053,7.208,13.279
                                c6.804-2.66,8.154-7.146,7.056-13.709c-1.626-9.693-2.157-19.57-3.226-29.367C503.72,373.021,502.586,363.537,501.498,354.047z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M587.971,359.373c1.598-2.756,2.605-6.877,4.955-7.891
                                c2.486-1.074,6.8-0.049,9.337,1.582c6.208,3.986,9.414,3.107,12.833-3.508c1.385-2.688,4.752-5.963,7.264-6.004
                                c2.474-0.037,5.94,3.203,7.319,5.857c3.577,6.91,6.608,7.689,13.295,3.316c2.347-1.533,6.624-2.436,8.787-1.293
                                c2.111,1.117,3.742,5.236,3.681,7.994c-0.21,8.938,1.569,10.691,10.363,10.086c2.576-0.182,6.608,1.346,7.635,3.316
                                c1.186,2.27,0.778,6.523-0.681,8.803c-4.742,7.457-4.067,10.146,3.777,14.057c2.289,1.143,4.994,4.408,4.907,6.604
                                c-0.101,2.586-2.45,6.176-4.823,7.398c-7.994,4.115-8.662,6.449-3.884,13.887c1.476,2.295,2.05,6.709,0.787,8.797
                                c-1.259,2.086-5.41,3.59-8.164,3.396c-7.735-0.543-10.257,1.797-10.054,9.383c0.074,3-1.262,7.512-3.405,8.691
                                c-2.229,1.229-6.787,0.141-9.321-1.496c-6.131-3.961-9.43-3.053-12.826,3.502c-1.359,2.623-4.808,5.746-7.306,5.752
                                c-2.502,0.004-5.947-3.105-7.332-5.725c-3.458-6.545-6.725-7.457-12.956-3.574c-2.563,1.6-7.103,2.736-9.269,1.504
                                c-2.131-1.219-3.37-5.74-3.28-8.756c0.227-7.555-2.398-9.926-10.144-9.303c-2.708,0.215-6.851-1.336-8.1-3.424
                                c-1.253-2.088-0.679-6.475,0.794-8.766c4.784-7.441,4.139-9.818-3.924-13.832c-2.285-1.143-4.93-4.506-4.828-6.727
                                c0.118-2.564,2.543-6.078,4.931-7.297c7.925-4.051,8.548-6.361,3.734-13.916c-1.46-2.287-1.96-6.717-0.675-8.793
                                c1.275-2.07,5.417-3.514,8.164-3.311C587.212,370.248,590.201,367.787,587.971,359.373z M600.927,421.881
                                c5.643,0.639,10.569,2.629,12.968-4.49c1.114-3.32,15.675-3.277,16.85,0.037c2.608,7.377,7.612,5.049,13.182,4.318
                                c-5.166-12.258-9.966-23.965-15.048-35.553c-1.862-4.254-10.638-4.932-12.468-0.863
                                C611.051,397.24,606.186,409.375,600.927,421.881z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M634.579,544.053c-4.08-16.381-7.754-31.111-11.432-45.84
                                c-0.465-0.016-0.927-0.025-1.395-0.041c-3.642,14.656-7.28,29.314-11.396,45.854c-3.051-8.879-5.481-15.943-8.277-24.066
                                c-6.009,5.643-11.597,10.9-18.67,17.555c2.266-9.262,4.058-16.617,5.859-23.971c4.384-17.871,8.658-35.77,13.295-53.576
                                c0.616-2.365,3.096-4.25,4.717-6.357c1.614,1.695,3.664,3.154,4.762,5.141c2.317,4.174,5.266,6.883,10.292,6.861
                                c5.24-0.027,8.472-2.65,10.692-7.26c0.888-1.852,2.822-3.201,4.281-4.779c1.624,1.588,4.248,2.928,4.727,4.807
                                c6.502,25.65,12.703,51.375,17.943,77.75c-5.443-5.189-10.883-10.371-17.06-16.256
                                C640.188,527.797,637.682,535.055,634.579,544.053z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M500.806,296.363c-16.769-18.798-34.097-36.346-49.004-55.754
                                c-7.039-9.164-9.386-21.913-13.968-32.993c-1.464-3.543-3.047-7.076-4.973-10.383c-5.373-9.23-11.344-18.137-16.299-27.582
                                c-2.623-4.994-1.843-9.959,3.876-13.627c1.773-1.137,2.567-5.239,2.329-7.846c-1.57-17.158-1.862-34.186,3.221-50.884
                                c1.017-3.343,2.288-6.639,3.764-9.805c6.314-13.544,15.931-21.948,31.567-24.531c25.549-4.225,51.161-3.563,76.816-4.037
                                c7.919-0.147,15.806-2.061,25.194-3.37c1.679,2.031,6.866,4.78,1.362,10.69c-0.826,0.888-0.097,4.445,1.058,5.768
                                c13.67,15.645,15.723,34.306,13.873,53.781c-0.801,8.469-2.773,16.862-4.704,25.178c-1.207,5.207-0.297,8.147,5.557,8.34
                                c5.805,0.194,5.882,4.509,4.358,7.967c-3.868,8.777-8.549,17.192-12.791,25.809c-2.296,4.656-5,9.241-6.411,14.176
                                c-4.957,17.351-12.398,32.958-25.548,45.947c-11.847,11.698-22.437,24.667-33.591,37.065
                                C504.771,292.188,502.974,294.039,500.806,296.363z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M290.271,429.092c5.124-17.008,8.836-33.107,14.807-48.328
                                c11.765-29.969,24.655-59.496,37.479-89.032c1.35-3.108,4.992-6.134,8.257-7.353c24-8.935,48.174-17.401,72.8-26.197
                                c7.524,14.486,14.882,28.649,22.238,42.816c4.546,8.762,9.171,17.479,13.589,26.305c2.436,4.855,5.304,5.703,9.891,2.488
                                c8.01-5.617,16.277-10.863,25.652-17.072c-1.247,12.137-2.302,22.76-3.437,33.377c-2.521,23.574-5.186,47.127-7.521,70.715
                                c-0.376,3.768,0.646,7.672,1.081,12.078c-1.017,0.047-3.133,0.219-5.249,0.219c-27.459,0.021-54.919-0.098-82.377,0.117
                                c-4.742,0.039-7.632-1.373-10.064-5.488c-4.239-7.172-9.022-14.029-13.697-20.932c-3.503-5.18-6.526-5.184-9.781,0.041
                                c-4.52,7.271-8.712,14.738-13.332,21.938c-1.153,1.799-3.391,4.088-5.164,4.121C327.696,429.209,309.944,429.092,290.271,429.092z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M494.056,302.365c-9.364,6.24-18.225,12.141-27.675,18.439
                                c-11.384-21.943-22.538-43.443-34.062-65.651c4.206-1.581,7.946-3.23,11.843-4.309c1.341-0.37,3.64,0.181,4.546,1.175
                                C463.75,268.537,478.641,285.189,494.056,302.365z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M569.552,255.174c-11.443,22.083-22.505,43.414-34.008,65.611
                                c-9.109-6.064-17.92-11.918-27.352-18.184c1.879-2.322,3.375-4.357,5.054-6.234c11.819-13.222,24.02-26.127,35.361-39.75
                                c4.754-5.708,9.034-7.377,15.397-3.625C565.48,253.861,567.224,254.277,569.552,255.174z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M501.498,354.047c1.088,9.49,2.222,18.975,3.261,28.471
                                c1.068,9.797,1.6,19.674,3.226,29.367c1.099,6.563-0.252,11.049-7.056,13.709c-5.818-2.227-8.211-6.02-7.208-13.279
                                c2.67-19.287,4.423-38.701,6.543-58.068C500.677,354.18,501.085,354.111,501.498,354.047z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									fill="#FFFFFF"
									d="M600.927,421.881c5.259-12.506,10.124-24.641,15.483-36.551
                                c1.83-4.068,10.605-3.391,12.468,0.863c5.082,11.588,9.882,23.295,15.048,35.553c-5.569,0.73-10.573,3.059-13.182-4.318
                                c-1.175-3.314-15.735-3.357-16.85-0.037C611.496,424.51,606.569,422.52,600.927,421.881z M622.327,395.195
                                c-1.872,4.996-3.021,8.08-4.277,11.434c3.28,0,5.679,0,8.746,0C625.385,403.023,624.242,400.105,622.327,395.195z"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M622.327,395.195c1.915,4.91,3.058,7.828,4.469,11.434c-3.067,0-5.466,0-8.746,0
                                C619.306,403.275,620.455,400.191,622.327,395.195z"
								/>
							</svg>
							<span
								class="count count-num timer count-title count-number"
								:data-to="data.a_member"
								data-speed="1500"
								>{{ data.a_member }}</span
							>
							<h6>Associate Members</h6>
						</div>
					</router-link>
				</div>

				<div
					class="col-4 col-sm-4 col-lg-2"
					data-aos="fade-up"
					data-aos-offset="100"
					data-aos-duration="1500"
				>
					<router-link class="text-black" :to="'member-list?type=Affiliate'">
						<div class="box">
							<img
								height="60"
								:src="
									$root.baseurl +
									'/public/images/icon/affiliate-members-counter.svg'
								"
								alt=""
							/>
							<span
								class="count count-num timer count-title count-number"
								:data-to="data.aff_member"
								data-speed="1500"
								>{{ data.aff_member }}</span
							>
							<h6>Affiliate Members</h6>
						</div>
					</router-link>
				</div>

				<div
					class="col-4 col-sm-4 col-lg-2"
					data-aos="fade-up"
					data-aos-offset="100"
					data-aos-duration="1500"
				>
					<router-link
						class="text-black"
						:to="'member-list?type=International'"
					>
						<div class="box">
							<img
								height="60"
								:src="
									$root.baseurl +
									'/public/images/icon/international-member-counter.svg'
								"
								alt=""
							/>
							<span
								class="count count-num timer count-title count-number"
								:data-to="data.int_member"
								data-speed="1500"
								>{{ data.int_member }}</span
							>
							<h6>International Members</h6>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "counter",
		data() {
			return {
				counter_status: false,
				data: {
					total_member: 0,
					g_member: 0,
					a_member: 0,
					int_member: 0,
					aff_member: 0,
				},
			};
		},
		watch: {
			counter_status: function (val, oldval) {
				if (val) {
					$(".count").each(function () {
						$(this)
							.prop("Counter", 0)
							.animate(
								{
									Counter: $(this).data("to"),
								},
								{
									duration: $(this).data("speed"),
									easing: "swing",
									step: function (now) {
										$(this).text(Math.ceil(now));
									},
								}
							);
					});
				}
			},
		},
		methods: {
			updateScroll() {
				let scrollPosition = window.scrollY;
				if (scrollPosition > 115 && scrollPosition < 800) {
					this.counter_status = true;
				} else {
					this.counter_status = false;
				}
			},
			getCountMember() {
				axios.get("/get-count-member").then((response) => {
					this.data.total_member = response.data.total;
					this.data.g_member = response.data.general;
					this.data.a_member = response.data.associate;
					this.data.aff_member = response.data.affiliate;
					this.data.int_member = response.data.international;
					window.addEventListener("scroll", this.updateScroll);
				});
			},
		},

		mounted() {
			// window.addEventListener("scroll", this.updateScroll);
			this.getCountMember();
		},
		destroy() {
			window.removeEventListener("scroll", this.updateScroll);
		},
	};
</script>

<style scoped>
	svg#counterIcon {
		fill: var(--success);
	}
	.num-counter .box span.count-num {
		color: var(--danger);
	}
</style>
