<template>
  <div class="about">
    <PageBanner :msg="content_breadcrumbTitle" :data="content_breadcumb"></PageBanner>
    <div class="content-body py-4">
      <div class="container-wrapper">
        <div class="row">
          <div class="col-lg-3">
            <affix class="sidebar-menu" relative-element-selector="#example-content">
              <leftmenu />
            </affix>
          </div>
          <div class="col-lg-9">
            <Loading v-if="loading" />
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Affix } from "vue-affix";
import PageBanner from "../../components/elements/PageBanner";
import leftmenu from "../../components/elements/leftmenu";
import Loading from "./../../components/Loader";
export default {
  name: "ContentLayout",
  components: {
    PageBanner,
    Loading,
    Affix,
    leftmenu
  },
  data() {
    return {
      loading: true,
      sec: 1500,
      count: 0,
      content_breadcrumbTitle: "",
      content_breadcumb: []
    };
  },
  methods: {
    getStorage: function() {
      var vm = this;
      setTimeout(function() {
        vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
        vm.content_breadcrumbTitle = vm.getLocalStgString("breadcrumbTitle");
      }, 500);
      setTimeout(function() {
        vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
        vm.content_breadcrumbTitle = vm.getLocalStgString("breadcrumbTitle");
      }, 1000);
      setTimeout(function() {
        vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
        vm.content_breadcrumbTitle = vm.getLocalStgString("breadcrumbTitle");
      }, 2000);
      setTimeout(function() {
        vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
        vm.content_breadcrumbTitle = vm.getLocalStgString("breadcrumbTitle");
      }, 3000);
      setTimeout(function() {
        vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
        vm.content_breadcrumbTitle = vm.getLocalStgString("breadcrumbTitle");
      }, 5000);
      setTimeout(function() {
        vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
        vm.content_breadcrumbTitle = vm.getLocalStgString("breadcrumbTitle");
      }, 10000);
      setTimeout(function() {
        vm.content_breadcumb = vm.getLocalStgArr("breadcumb");
        vm.content_breadcrumbTitle = vm.getLocalStgString("breadcrumbTitle");
      }, 15000);
    }
  },
  mounted() {
    this.$root.loading = false;
  },
  watch: {
    $route: {
      handler: "getStorage",
      immediate: true
    }
  }
};
</script>
