<template>
	<div class="col-md-8 login-page">
		<div class="card-box">
			<div class="row">
				<div class="col-md-6">
					<div class="left-content">
						<img class="basis-logo" :src="$root.baseurl + '/public/images/logo.svg'" alt="basislogo" />
						<h4>Welcome to BASIS DigitalShop</h4>

						<img
							class="login-image"
							:src="$root.baseurl + '/public/images/ecommerce-login.jpg'"
							alt="basislogo"
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div v-if="!$root.spinner" class="right-form">
						<!-- LOGIN SECTION -->
						<!-- LOGIN SECTION -->
						<div class="row justify-content-center align-items-center">
							<div class="col-lg-12">
								<h3 class="text-center">Login With BASIS Membership Account.</h3>
								<div class="form-group">
									<label for class="col-form-label-sm m-0">Representative Email</label>
									<div class="loginBox">
										<input
											:class="{
                        'is-invalid': validation.hasError('data.email'),
                        'is-valid': data.email,
                      }"
											type="email"
											autocomplete="off"
											onfocus="this.placeholder = ''"
											onblur="this.placeholder = 'ie: member@email.com'"
											placeholder="ie: member@email.com"
											name="email"
											class="form-control form-control-sm"
											v-model="data.email"
										/>
										<span class="mdi mdi-email"></span>
									</div>
									<div class="invalid-feedback">{{ validation.firstError("data.email") }}</div>
								</div>
								<div class="form-group">
									<label for class="col-form-label-sm m-0">Password</label>
									<div class="loginBox">
										<input
											:class="{
                        'is-invalid': validation.hasError('data.password'),
                        'is-valid': data.password,
                      }"
											type="password"
											autocomplete="off"
											onfocus="this.placeholder = ''"
											onblur="this.placeholder = 'ie: A-Z, a-z,digit(0-9),special character'"
											placeholder="ie: A-Z, a-z,digit(0-9),special character"
											name="password"
											class="form-control form-control-sm"
											v-model="data.password"
											v-on:keyup.enter="submit"
										/>
										<span class="mdi mdi-shield-lock-outline"></span>
									</div>
									<div class="invalid-feedback">{{ validation.firstError("data.password") }}</div>
								</div>

								<div class="row justify-content-between align-items-center mt-5 mx-2">
									<button @click="submit()" class="btn btn-success float-right">
										<span v-if="spinner">
											<i class="fa fa-spinner fa-spin"></i> processing...
										</span>
										<span v-else>Login</span>
									</button>

									<span>
										Create an Account?
										<router-link :to="{ name: 'ecommerce.registration' }">Signup</router-link>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div v-if="$root.spinner" class="right-form">
						<spinner />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		name: "Login",
		data() {
			return {
				data: {},
				user: {},
				spinner: false
			};
		},
		methods: {
			submit: function(e) {
				this.$validate().then(res => {
					if (res) {
						this.$root.spinner = true;
						axios
							.post("/ecommerce/member-login/submit", this.data)
							.then(res => {
								if (res.data.code == 202) {
									this.$toastr("success", res.data.msg, "Success!!");
									window.location.href =
										this.$root.baseurl + "/digitalshop/dashboard";
								} else if (res.data.code == 401) {
									this.$root.spinner = false;
									this.$toastr("error", res.data.msg, "Sorry!!");
								}
							})
							.catch(error => {
								if (error) {
									this.$toastr("error", "Invalid email or password", "Sorry!!");
									this.$root.spinner = false;
								}
							});
					}
				});
			}
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
			if (this.$root.ecomLogged) {
				this.$router.push({ name: "ecommerce.dashboard" });
			}
		},
		validators: {
			"data.email": function(value = null) {
				return Validator.value(value)
					.required("Email is required")
					.email();
			},
			"data.password": function(value = null) {
				return Validator.value(value).required("Password is required");
			}
		}
	};
</script>
