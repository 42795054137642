<template>
  <div class="contact">
    <PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
    <div class="container-wrapper">
      <div class="content-body py-4">
        <div class="row">
          <div class="col-md-9 whats-new-view-body">
            <div v-if="data" class="row">
              <div class="col-md-6 text-center">
                <img
                  v-if="data.type != 'file'"
                  class="img-fluid"
                  v-lazy="$root.baseurl + '/public' + data.image"
                  :alt="data.title"
                />
                <img
                  style="height: 207px"
                  v-else
                  class="big-pdf"
                  v-lazy="$root.baseurl + '/public/images/pdf-ty.png'"
                  :alt="data.title"
                />
                <div class="video-icon" v-if="data.type === 'video'">
                  <i class="mdi mdi-youtube"></i>
                </div>
              </div>
              <div class="col-md-6">
                <h4>{{ data.title }}</h4>
                <span>
                  <i class="mdi mdi-calendar pr-1"></i>
                  {{ data.date | formatDate }}</span
                >
                <br /><br /><br />
                <a
                  download
                  class="btn btn-sm btn-outline-primary"
                  v-if="data.type == 'file'"
                  :href="$root.baseurl + '/public' + data.file"
                >
                  Download
                </a>
              </div>
            </div>
            <br />
            <div v-html="data.description" class="descridivtion"></div>
            <div class="video" v-if="data.type == 'video'">
              <iframe
                width="100%"
                height="350px"
                :src="'https://www.youtube.com/embed/' + data.url"
                :title="data.title"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            </div>
          </div>
          <div class="col-md-3 whats-new-list">
            <div class="card" style="background: #8fd3cc8f">
              <div class="card-header">
                <h4>What's New?</h4>
              </div>
              <div
                v-if="datas && Object.keys(datas).length > 0"
                class="card-body"
              >
                <ul class="list-group list-group-flush">
                  <li
                    v-for="item in datas"
                    :key="item.id"
                    class="list-group-item"
                  >
                    <router-link
                      class=""
                      v-if="item.type != 'external_link'"
                      :to="{ name: 'whatsnewDetails', params: { id: item.id } }"
                    >
                      <div class="item-image">
                        <img
                          class="img-fluid"
                          v-if="item.type != 'file'"
                          v-lazy="$root.baseurl + '/public' + item.image"
                          :alt="item.title"
                        />
                        <img
                          v-else
                          v-lazy="$root.baseurl + '/public/images/pdf-ty.png'"
                          :alt="item.title"
                        />
                      </div>
                      <div class="item-text">
                        <strong>{{ item.title }}</strong>
                      </div>
                    </router-link>
                    <a class="" v-else :href="item.url" target="_blank">
                      <div class="item-image">
                        <img
                          v-lazy="$root.baseurl + '/public' + item.image"
                          :alt="item.title"
                        />
                      </div>
                      <div class="item-text">
                        <strong>{{ item.title }}</strong>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageBanner from "../../components/elements/PageBanner";

const breadcumb = [
  {
    title: "What's New Details",
  },
];
export default {
  name: "WhatsNew",
  components: { PageBanner },

  data() {
    return {
      breadcrumbTitle: "What's New Details",
      breadcumb: [{ title: "What's New Details" }],
      datas: [],
      data: {},
    };
  },
  methods: {
    asyncData() {
      this.get_datas();
      this.$root.loading = false;
    },
    get_datas: function () {
      let url = "/get-whats-new/" + this.$route.params.id;
      this.$root.spinner = true;
      axios
        .get(url, { params: this.search_data })
        .then((res) => {
          this.data = res.data.data;
          this.datas = res.data.datas;
        })
        .catch((error) => console.log(error));
    },
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true,
    },
  },
};
</script>

<style scoped>
.whats-new-list .list-group-flush .list-group-item {
  background: transparent;
  padding: 6px 0px;
}
img.big-image {
  height: 16em;
  width: 100%;
  object-fit: cover;
}
p.description {
  text-align: justify;
}
.item-text strong {
  font-weight: 500;
  color: rgb(0 0 0);
  font-size: 13px;
  font-family: "Raleway";
}
.item-text {
  margin-left: 10px;
}
</style>
