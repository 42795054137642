<template>
	<footer>
		<!--footer-->
		<div class="footer" style="font-family: 'Raleway' !important">
			<div class="container-wrapper">
				<div class="row m-0">
					<div class="col-lg-2 align-self-center p-lg-0">
						<div class="footer-box pl-0">
							<a href="/">
								<img v-lazy="logo" alt="basis logo" />
							</a>
						</div>
					</div>
					<div class="col-lg-10">
						<div class="row">
							<div class="col-lg-3 p-lg-0">
								<div class="footer-box">
									<h4>Important link</h4>
									<ul class="nav flex-column">
										<li class="mb-1" v-for="(item, index) in link" :key="index">
											<a :href="item.external_url" target="_blank" class="text-light">{{ item.title }}</a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-lg-3 p-lg-0">
								<div class="footer-box">
									<h4>Current Projects</h4>
									<ul class="nav flex-column">
										<li class="mb-1" v-for="(item, index) in footer_bottom" :key="index">
											<router-link
												class="text-light"
												v-if="item.type === 'content'"
												:to="{ name: 'content', params: { slug: item.slug } }"
											>{{ item.title }}</router-link>
											<a
												class="text-light"
												v-else-if="item.type === 'outside_website'"
												:href="item.url"
												target="_blank"
											>{{ item.title }}</a>
											<router-link
												class="text-light"
												v-else-if="item.params !== null"
												:to="{ name: item.url, params: { type: item.params } }"
											>{{ item.title }}</router-link>
											<router-link class="text-light" v-else :to="{ name: item.url }">{{ item.title }}</router-link>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-lg-3 p-lg-0">
								<div class="footer-box footer-contact">
									<h4>Location</h4>
									<a
										href="https://www.google.com/maps/place/Bangladesh+Association+of+Software+and+Information+Services+(BASIS)/@23.7508403,90.3911565,17z/data=!3m1!4b1!4m5!3m4!1s0x3755b8a2ac98817b:0xf48ca75c1bdfc0f2!8m2!3d23.7508354!4d90.3933452"
										target="_blank"
										style="text-decoration: underline; color: #fff"
									>
										<p class="d-flex">
											<span class="icon pr-2">
												<i class="mdi mdi-map"></i>
											</span>
											<span class="text">{{ $root.site.address }}</span>
										</p>
									</a>

									<p class="d-flex align-items-start">
										<span class="icon">
											<i class="mdi mdi-phone pr-2"></i>
										</span>
										<span class="text">Phone: {{ $root.site.phone }}</span>
									</p>
									<p class="d-flex align-items-start">
										<span class="icon">
											<i class="mdi mdi-email-outline pr-2"></i>
										</span>
										<span class="text">Email: {{ $root.site.email }}</span>
									</p>
									<ul class="social-menu nav flex-row">
										<li class="nav-item" v-for="(item, index) in $root.socialLink" :key="index">
											<a target="_blank" :href="item.url" class="nav-link">
												<p v-html="item.icon"></p>
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-lg-3 p-lg-0">
								<div class="footer-box">
									<h4>Download</h4>
									<div class="store-icon-image">
										<a href="https://play.google.com/store/apps/details?id=basis.org.bd">
											<img :src="$root.baseurl + '/public/images/googlePlay.png'" alt />
										</a>
										<a href="https://apps.apple.com/us/app/basis-app/id1531075172">
											<img :src="$root.baseurl + '/public/images/appStore.png'" alt />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--//footer-->
		<!--footer-bottom-->
		<div class="footer-bottom">
			<div class="container-wrapper">
				<div class="row align-items-center">
					<div class="col-lg-3">
						<p>&copy; copyright {{ new Date().getFullYear() }} BASIS</p>
					</div>
					<div class="col-lg-5 text-center">
						<div class="d-flex align-items-center justify-content-start">
							<p>We Accept</p>
							<ul class="nav">
								<li>
									<img v-lazy="img1" alt="img1" />
								</li>
								<li>
									<img v-lazy="img2" alt="img2" />
								</li>
								<li>
									<img v-lazy="img3" alt="img3" />
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-4">
						<p>
							Developed by :
							<strong>
								<a href="http://www.nogorsolutions.com/" target="_blank">
									<img v-lazy="nsllogo" alt="Nogor Solutions Ltd" /> Nogor
									Solutions Ltd.
								</a>
							</strong>
						</p>
					</div>
				</div>
			</div>
		</div>
		<!--//footer-bottom-->
		<router-link
			v-if="$route.name === 'home'"
			class="btn btn-whatsnew"
			:to="{ name: 'whatsnew' }"
		>What's new?</router-link>
		<!--back-to-top-->
		<a href="#" class="scroll-top">
			<i class="fa fa-arrow-up"></i>
		</a>
		<!--//back-to-top-->
	</footer>
</template>

<script>
	export default {
		name: "main-footer",
		data() {
			return {
				data: {},
				footer_bottom: {},
				link: {},
				result: {},
				scrolled: false,
				logo: this.$root.baseurl + "/public/images/f-logo.svg",
				nsllogo: this.$root.baseurl + "/public/images/nogor_logo.png",
				img1: this.$root.baseurl + "/public/images/icon/mastercard.svg",
				img2: this.$root.baseurl + "/public/images/icon/amex.svg",
				img3: this.$root.baseurl + "/public/images/icon/visa.svg"
			};
		},
		methods: {
			get_data() {
				axios
					.get("/all_layout_data/footer")
					.then(respons => {
						this.footer_bottom = respons.data.footer_bottom;
						this.link = respons.data.link;
					})
					.catch(error => {
						console.log(error);
					});
			},
			handleSCroll(event) {
				let header = document.querySelector(".scroll-top");
				if (window.scrollY > 400 && !header.className.includes("active")) {
					header.classList.add("active");
				} else if (window.scrollY < 400) {
					header.classList.remove("active");
				}
			}
		},
		mounted() {
			this.get_data();
		},
		created() {
			window.addEventListener("scroll", this.handleSCroll);
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleSCroll);
		}
	};
</script>
<style>
	a.btn.btn-whatsnew {
		position: fixed;
		left: -54px;
		top: 50%;
		background: rgb(109 200 190);
		color: rgb(0 0 0);
		font-size: 18px;
		-webkit-transform: rotate(90deg);
		transform: rotate(-90deg);
		padding: 7px 16px;
		font-weight: 600;
	}
	.store-icon-image img {
		margin-bottom: 23px;
		vertical-align: middle;
		display: inline-block;
	}
</style>
