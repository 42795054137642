<template>
  <div class="member-services" v-if="!$parent.loading">
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">
            Certificate List
          </h5>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date of Delivery</th>
                <th scope="col">Membership No.</th>
                <th scope="col">Company Name</th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <slot v-if="table.datas && Object.keys(table.datas).length > 0">
                <tr v-for="(certif, index) in table.datas" :key="index">
                  <td>
                    {{ certif.deliverd_date | formatDate("Do MMM, YYYY") }}
                  </td>
                  <slot v-if="certif.member">
                    <td>{{ certif.member.membership_id }}</td>
                    <td>{{ certif.member.company_name }}</td>
                  </slot>
                  <td class="text-center" style="width: 200 px">
                    <a
                      href="#"
                      class="btn btn-success btn-sm float-left"
                      v-on:click="downloadCertificate(certif.id)"
                    >
                      <i class="fa fa-download"></i>
                    </a>
                    <router-link
                      v-if="
                        certif.ecourier_count === 0 &&
                        certif.status != 'delivered'
                      "
                      :to="{
                        name: 'certify.eCourier',
                        params: { id: certif.id },
                      }"
                      class="btn btn-info btn-sm ml-3"
                    >
                      <i class="fa fa-cart-arrow-down"></i>
                      Get Home Delivery
                    </router-link>
                    <a
                      v-else
                      href="javascript:void(0)"
                      style="border-radius: 3 px"
                      class="
                        bg-success
                        p-1
                        pl-3
                        pr-3
                        text-white
                        ml-3
                        text-uppercase
                      "
                      @click="
                        (tracking_status = certif.ecourier
                          ? certif.ecourier.tracking_status
                          : []),
                          $bvModal.show('view_track_status')
                      "
                    >
                      {{
                        certif.ecourier ? certif.ecourier.status : certif.status
                      }}
                    </a>
                  </td>
                </tr>
              </slot>
              <tr v-else>
                <td colspan="4" class="text-center font-bold">
                  No certificates yet!!
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- pagination -->
        <Pagination :url="'/member/get-certificate-list'" />
        <!-- /pagination -->
      </div>
    </div>

    <b-modal id="view_track_status" title="Tracking Status">
      <div class="col-12">
        <ul v-if="tracking_status">
          <li v-for="(status, key) in tracking_status" :key="key">
            <small>{{ status.date | formatDate("LLLL") }}</small> -
            <span class="text-success"> {{ status.status }}</span>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Pagination from "../../../components/Pagination";
export default {
  name: "CertificateList",
  props: ["memberInfo"],
  components: { Pagination },
  data() {
    return {
      table: { datas: [] },
      meta: [],
      links: [],
      tracking_status: [],
      member: {
        info: {},
      },
    };
  },
  methods: {
    certificateList: function (pageindex) {
      this.$parent.loading = true;
      let url = "/member/get-certificate-list";
      if (pageindex > 0) {
        url = "/member/get-certificate-list?page=" + pageindex;
      }
      axios
        .get(url)
        .then((response) => {
          this.table.datas = response.data.data;
          this.meta = response.data.meta;
          this.links = response.data.links;
        })
        .catch((error) => {})
        .then((res) => {
          setTimeout(() => (this.$parent.loading = false), 300);
        });
    },
    downloadCertificate(id) {
      window.location.href =
        this.$root.baseurl + "/member/certificate-pdf/" + id + "?onlysign=true";
    },
  },
  mounted() {
    this.loggedIn();
    this.certificateList();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
};
</script>

