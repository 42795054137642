<template>
  <div class="customer-profile">
    <h3 class="my-4">Feedback</h3>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="" class="col-form-label-sm m-0">Subject</label>
          <div class="loginBox">
            <input
              type="text"
              autocomplete="off"
              v-model="data.subject"
              class="form-control form-control-sm"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="" class="col-form-label-sm m-0">Comment</label>
          <div class="loginBox">
            <textarea
              type="text"
              autocomplete="off"
              v-model="data.description"
              name="description"
              class="form-control form-control-sm"
            ></textarea>
          </div>
        </div>
      </div>
      <button v-on:click="submit()" class="btn btn-primary mt-4 ml-3">
        Submit
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        subject: "",
        description: "",
        user_id: "",
      },
    };
  },
  methods: {
    submit: function (e) {
      this.$root.spinner = true;
      axios
        .post("ecommerce/feed-back", this.data)
        .then((respons) => {
          if (respons.data.code == 200) {
            this.$toastr("success", respons.data.msg, "Success!!");
          }
          if (respons.data.code == 202) {
            this.$toastr("error", respons.data.msg, "Error!!");
          }
          this.data = {
            subject: "",
            description: "",
            user_id: this.$root.loggedCustomer.id,
          };
        })
        .catch((error) => {})
        .then((alw) => (this.$root.spinner = false));
    },
  },
  created() {
    if (this.$root.ecomLogged) {
      this.data.user_id = this.$root.loggedCustomer.id;
    }
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
};
</script>

