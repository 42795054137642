<template>
  <div class="about">
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row">
          <slot v-if="content.image">
            <div class="col-xs-12 col-lg-5">
              <img v-lazy="content.FullUrl+content.image" class="img-fluid" :alt="content.title" />
            </div>
            <div class="col-xs-12 col-lg-7" data-aos="fade-right">
              <!--Heading-->
              <h5 class="page-title font-weight-bold text-uppercase">{{content.title}}</h5>
              <!--Text-->
              <p class="text-justify" v-html="content.description"></p>
            </div>
          </slot>
          <slot v-else>
            <div class="col-xs-12 col-lg-12" data-aos="fade-right">
              <h5 class="page-title font-weight-bold text-uppercase">{{content.title}}</h5>
              <p class="text-justify" v-html="content.description"></p>
            </div>
          </slot>
          <div
            class="col-xs-12 col-lg-12"
            data-aos="fade-right"
            v-if="content.content_file.length > 0"
          >
            <table class="table table-sm table-hover table-bordered">
              <thead>
                <tr class="thead-dark">
                  <th>SL</th>
                  <th>Title</th>
                  <th class="text-center">File</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(content_file, index) in content.content_file" :key="index">
                  <td>{{index+1}}</td>
                  <td>{{ content_file.title }}</td>
                  <td class="text-center">
                    <a
                      :href="content_file.FullUrl+ content_file.file"
                      target="_blank"
                      class="btn btn-sm btn-success rounded-50 lift"
                    >
                      <span class="fab fa fa-download"></span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Content Detail",
  components: {},
  data() {
    return {
      content: {
        content_file: [],
        breadcrumbTitle: "",
        breadcumb: []
      }
    };
  },
  methods: {
    asyncData() {
      this.get_data();
    },
    get_data() {
      axios
        .get("/get-content-details/" + this.$route.params.slug)
        .then(respons => {
          this.content = respons.data.result;
          console.log(this.content);
        })
        .catch(error => {
          console.log(error);
        })
        .then(res => {
          setTimeout(
            () => (this.$root.loading = false),
            this.$root.minloadtimefd
          );
        });
    }
  },
  mounted() {},
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    }
  }
};
</script>
