<template id="modal">
	<!-- Modal -->
	<div
		class="modal fade"
		id="exampleModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="exampleModalLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Events Information</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body" v-if="events">
					<div class="row perRow" v-for="(event, index) in events" :key="index">
						<div class="col-lg-3">
							<img
								v-lazy="$root.baseurl + '/public' + event.image"
								class="imgg"
								:alt="event.title"
							/>
						</div>
						<div class="col-lg-9">
							<h5>{{ event.title }}</h5>
							<small>{{ event.start_date }} to {{ event.end_date }}</small>
							<p text-align="justify" v-html="event.description"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	export default {
		name: "home",
		props: ["events"],
	};
</script>

<style>
	.imgg {
		height: 100px;
	}
	.perRow {
		margin-bottom: 20px;
		border-bottom: 1px solid #ccc;
	}
</style>
