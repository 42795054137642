<template>
	<div
		class="member-services"
		v-if="!$parent.loading && member.info && member.info.status == 1"
	>
		<div class="card shadow-sm mb-3">
			<div class="card-header px-0 mt-1">
				<div class="justyfy text-center">
					<h5 class="px-3 mb-0">{{ eventTitle }}</h5>
				</div>
			</div>
			<div class="card-body">
				<form v-on:submit.prevent="submit" id="form">
					<div class="row">
						<div class="col-lg-6">
							<div class="form-row mb-3">
								<label for="comments">
									Company Name
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									v-if="memberInfo"
									v-model="memberInfo.company_name"
									class="form-control"
									readonly
								/>
							</div>
							<div class="form-row mb-3">
								<label for="comments">
									Representative Name
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									v-model="data.applicant_name"
									class="form-control"
									id="formGroup"
									placeholder="Eg: John Doe"
									readonly
								/>
							</div>
						</div>
						<!-- right side -->
						<div class="col-lg-6">
							<div class="form-row mb-3">
								<label for="comments">
									Membership ID
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									v-if="memberInfo"
									v-model="memberInfo.membership_no"
									class="form-control"
									id="inputmembershipid"
									readonly
								/>
							</div>
							<div class="form-row mb-3">
								<label for="comments">Designation</label>
								<span class="text-danger requierd-star"></span>
								<input
									type="text"
									v-model="data.designation_representative"
									class="form-control"
									id="inputdesignation"
									placeholder="Designation"
									readonly
								/>
							</div>
						</div>
					</div>
					<br />
					<button class="btn btn-success text-center" type="submit">
						Next
					</button>
				</form>
			</div>
		</div>
		<!-- <pre>{{data}}</pre> -->
	</div>
	<div v-else-if="member.info && member.info.status == 0" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">
						Sorry!! your account is suspend
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "CommonEventForm",
		props: ["memberInfo"],
		data() {
			return {
				declaration: false,
				eventTitle: "",
				data: {
					amount: 0,
					export_experience_year: "",
					participate_events_year: "",
					total_revenue_year: "",
					local_event_year: "",
					export_earning_year: "",
					participant_one: {},
					participant_second: {},
				},
				errors: {},
				member: {
					info: null,
				},
			};
		},
		methods: {
			submit: function () {
				this.$parent.loading = true;
				axios
					.post("/member/internationalEvent", this.data)
					.then((response) => {
						this.$toastr(
							"success",
							"Your information has been submitted, please payment",
							"Success!!"
						);
						if (response.data) {
							localStorage.setItem("recp_id", response.data.recp_id);
							localStorage.setItem("serv_type", "event");
							localStorage.setItem("service_amount", this.data.amount);
							this.$router.push({
								name: "paymentMethod",
								params: {
									id: this.data.event_id,
									amount: response.data.amount,
								},
							});
						}
					})
					.catch((error) => console.log(error))
					.then((alw) => (this.$parent.loading = false));
			},
		},
		created() {
			this.loggedIn();
			setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);
			axios.get("member/get-represent-info/primary").then((response) => {
				this.data.applicant_name = response.data.name;
				this.data.applicant_mobile = response.data.mobile;
				this.data.designation_representative = response.data.designation;
			});

			axios.get("/member/get-event-info/" + this.$route.params.id).then((res) => {
				this.data.amount = res.data.amount ? res.data.amount : 0;
				this.data.event_id = res.data.id;
				this.eventTitle = res.data.title;
				this.data.event_name = res.data.title;
				this.data.company_name = res.data.company_name;
				this.data.company_address = res.data.address;
				this.data.company_website = res.data.website;
			});
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
	};
</script>

