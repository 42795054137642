<template>
	<div class="col-lg-12">
		<!--Flash Product  -->
		<slot v-for="(category, index) in categories">
			<div :key="index">
				<div
					class="product-section-title d-flex justify-content-between align-items-center"
				>
					<h3
						class="px-2 mb-0"
						v-if="
              category.child_home_page &&
              Object.keys(category.child_home_page).length > 0
            "
					>{{ category.name }}</h3>
					<router-link
						:to="{
              name: 'ecommerce.all-subcategory',
              params: { slug: category.slug },
              query: {
                slug: category.slug,
              },
            }"	v-if="
              category.child_home_page &&
              Object.keys(category.child_home_page).length > 0
            "
						class="view-more"
					>View All</router-link>
				</div>
				<slot
					v-if="
            category.child_home_page &&
            Object.keys(category.child_home_page).length > 0
          "
				>
					<SubCategory :subCategory="category.child_home_page" :category_slug="category.slug" />
					<span></span>
					<!-- slider -->
				</slot>
				<div class="row" v-if="index == 1">
					<Slider />
				</div>
			</div>
		</slot>
	</div>
</template>

<script>
	import Slider from "./Slider";
	import SubCategory from "./SubCategory";

	export default {
		name: "Category_product",
		props: ["categories"],
		components: { SubCategory, Slider },
		data() {
			return {};
		},
		methods: {}
	};
</script>

<style scoped>
</style>
