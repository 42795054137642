<template>
  <!--services-->
  <div class="home-discount" v-if="datas && Object.keys(datas).length > 0">
    <span class="shape1"></span>
    <span class="shape2"></span>
    <div class="container-wrapper">
      <div class="content-header mb-4">
        <h1 class="title text-center">Privilege Service</h1>
      </div>
      <VueSlickCarousel v-bind="settings" v-if="Object.keys(datas).length > 0">
        <div
          v-for="(data, index) in datas"
          class="position-relative mb-5 p-3 align-center"
          :key="index"
        >
          <span class="discountShape test" :class="'bg-discount' + (index + 2)">
            </span>
          <div class="card_body">
            <router-link
              :to="{ name: 'discountServiceDetails', params: { id: data.id } }"
            >
              <img
                :src="$root.baseurl + '/public' + data.company_logo"
                alt=""
              />
              <h4 class="category_name">
                <slot v-if="data.discout_category && data.discout_category.name">
              {{ data.discout_category.name }}
            </slot>
            </h4>
              <div class="company__name" v-if="data.organization_name">
                <h2>{{ data.organization_name }}</h2>
              </div>
            </router-link>
            <div class="discount">
              <h5
                :class="'bg-percentage' + (index + 1)"
                v-if="data.discount_percentage"
              >
                <span class="upto">upto</span>&nbsp;
                <span class="number">{{ data.discount_percentage }}</span>
                <span class="percent">%</span>
              </h5>
            </div>
          </div>
          <span class="rolShape1"></span>
          <span class="rolShape2"></span>
          <span class="rolShape3"></span>
        </div>
      </VueSlickCarousel>

      <div class="row pt-5">
        <div class="col-lg-12 text-center">
          <router-link
            :to="{ name: 'discountServices' }"
            class="btn btn-lg btn-primary rounded-50"
          >
            View All
            <i class="fas fa-long-arrow-alt-right pl-1"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!--services-->
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      datas: {},
      settings: {
        arrows: true,
        dots: true,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 2,
        rows: 1,
      },
    };
  },
  methods: {
    getServices() {
      axios.get("/get-discount-service-home").then((response) => {
        this.datas = response.data.data;
      });
    },
  },
  mounted() {
    setTimeout(() => this.getServices(), 150);
  },
};
</script>
<style>
.slick-prev:before,
.slick-next:before {
  color: #6ec8bf !important;
}
</style>
