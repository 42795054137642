<template>
	<div id="example-content">
		<slot v-if="!$root.spinner">
			<slot v-if="$route.params.type == 'photo'">
				<div class="row gallery photo align-items-center">
					<div class="row col-12" v-if="Object.keys(images).length > 0">
						<div class="col-lg-3" v-for="(image, index) in images" :key="index">
							<div class="card card-gallery shadow-sm">
								<img
									v-lazy="image.src"
									class="img-fluid"
									@click="openGallery(index)"
									:alt="image.caption"
								/>
							</div>
						</div>
						<LightBox
							:images="images"
							ref="lightbox"
							:show-caption="true"
							:show-light-box="false"
							:showThumbs="false"
						></LightBox>
					</div>
					<div v-else class="text-center w-100">No photos uploaded yet</div>
				</div>
			</slot>
			<slot v-else-if="$route.params.type == 'album'">
				<div v-if="Object.keys(images).length > 0" class="row gallery photo">
					<div
						class="col-lg-4 text-center mb-4"
						v-for="(item, index) in images"
						:key="index"
					>
						<router-link
							:to="{
								name: 'photo_details',
								params: { photo_gallery_id: item.id, type: 'photo' },
							}"
						>
							<div class="card shadow-sm mb-3 h-100">
								<div class="card-gallery">
									<img
										v-lazy="item.FullUrl + item.image"
										class="img-fluid"
										:alt="item.title"
									/>
								</div>
								<div class="card-body">
									<h4>{{ getPostBody(item.title, 52) }}</h4>
								</div>
							</div>
						</router-link>
					</div>
				</div>
				<div v-else class="text-center w-100">No albums yet</div>
			</slot>
		</slot>
		<spinner v-if="$root.spinner" />
	</div>
</template>
<script>
	import LightBox from "vue-image-lightbox";
	require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

	export default {
		components: {
			LightBox,
		},
		data() {
			return {
				breadcumb: [],
				breadcrumbTitle: "Photos of",
				images: [],
			};
		},
		methods: {
			asyncData() {
				this.get_datas();
				this.$root.loading = false;
				this.$parent.loading = false;
			},
			get_datas: function () {
				this.$root.spinner = true;
				let url =
					"/get-photo-details/" +
					this.$route.params.photo_gallery_id +
					"/" +
					this.$route.params.type;
				axios
					.get(url)
					.then((respons) => {
						this.images = respons.data.photos;
						this.breadcrumbTitle = respons.data.title;
						this.breadcumb = [
							{
								title: "Photo Gallery",
								route_name: "photogallery",
							},
							{ title: this.breadcrumbTitle },
						];
						this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
						this.$root.spinner = false;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			openGallery(index) {
				this.$refs.lightbox.showImage(index);
			},
		},
		mounted() {},
		watch: {
			$route: {
				handler: "asyncData",
				immediate: true,
			},
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
	};
</script>
<style scoped>
	.image {
		float: left;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		border: 1px solid #ebebeb;
		margin: 5px;
	}
	.card-body {
		padding: 1rem !important;
		max-height: 73px !important;
	}
</style>
