<template>
  <div class="member-services voter-information" v-if="!$parent.loading">
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="row px-4">
          <div class="col-lg-9 my-3">
            <h2 class="text-uppercase font-weight-bold px-3 mb-0 mt-3">
              BASIS Election 2024-26
            </h2>
            <h6 class="text-uppercase font-weight-bold px-3 mb-0">
              Voter information
            </h6>
          </div>
          <div class="col-lg-3">
            <div class="company-name-logo">
              <img :src="$root.baseurl + '/public' + data.logo" alt />
              <div class="invalid-feedback">
                {{ validation.firstError("data.logo") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="col-lg-12 col-12">
          <form v-on:submit.prevent="submit" id="form">
            <div class="repentative-info-box">
              <div class="company-info-box text-center">
                <h6 class>Representative Information</h6>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-3 col-3 rep-image">
                  <img :src="$root.baseurl + '/public' + data.rep_image" alt />
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.rep_image") }}
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <table class="table border-0 voter-info-table">
                    <tr>
                      <th>Representative name</th>
                      <td>:</td>
                      <td>{{ data.rep_name }}</td>
                    </tr>
                    <tr>
                      <th>Designation</th>
                      <td>:</td>
                      <td>{{ data.rep_designation }}</td>
                    </tr>
                    <tr>
                      <th>Email address</th>
                      <td>:</td>
                      <td>{{ data.rep_email }}</td>
                    </tr>
                    <tr>
                      <th>Mobile number</th>
                      <td>:</td>
                      <td>{{ data.rep_mobile }}</td>
                    </tr>
                    <tr>
                      <th>Representative’s TIN no.</th>
                      <td>:</td>
                      <td>{{ data.rep_tin_number }}</td>
                    </tr>
                  </table>
                </div>
                <div v-if="data.status !== 'a'" class="col-lg-3 col-3">
                  <router-link
                    :to="{ name: 'changeRequest' }"
                    class="btn btn-danger rounded-10"
                    style="padding: 10px"
                    >Rep. Change Req.</router-link
                  >
                </div>
                <div v-if="data.status == 'a'" class="col-lg-3 col-3">
                  <img :src="$root.baseurl + '/public/images/voter.jpeg'" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-12">
                <!-- Warning -->
                <div
                  v-if="data.status !== 'a' && !editVoterInfo && !canSubmit"
                  class="alert alert-success mt-4"
                  role="alert"
                >
                  <h4 class="alert-heading text-success">Submitted!</h4>
                  <div
                    class="d-flex justify-content-between alert alert-success"
                  >
                    <p>Do you want to update ?</p>
                    <button
                      @click="editVoterInfo = true"
                      type="button"
                      class="btn btn-primary text-light"
                    >
                      <i class="fa fa-edit"></i> <span>Edit</span>
                    </button>
                  </div>
                </div>
                <div class="company-info-box text-center">
                  <h6 class>Company Information</h6>
                </div>
                <div class="row mb-5">
                  <div class="col-lg-6 col-6">
                    <table class="table border-0 voter-info-table">
                      <tr>
                        <th>Company name</th>
                        <td>:</td>
                        <td>{{ data.company_name }}</td>
                      </tr>
                      <tr>
                        <th>Membership ID</th>
                        <td>:</td>
                        <td>{{ data.membership_no }}</td>
                      </tr>
                      <tr>
                        <th>Membership type</th>
                        <td>:</td>
                        <td>{{ data.membership_type }}</td>
                      </tr>
                      <tr>
                        <th>Office Address</th>
                        <td>:</td>
                        <td>{{ data.address }}</td>
                      </tr>
                      <tr>
                        <div class="invalid-feedback">
                          {{ validation.firstError("data.address") }}
                        </div>
                      </tr>
                    </table>
                  </div>
                  <div class="col-lg-6 col-6">
                    <table class="table voter-info-table">
                      <tr>
                        <th>Company Type</th>
                        <td>:</td>
                        <td>{{ data.company_type }}</td>
                      </tr>
                      <tr>
                        <th>Company TIN No.</th>
                        <td>:</td>
                        <td>{{ data.tin_number }}</td>
                      </tr>
                      <tr>
                        <th>Annual Fee Status</th>
                        <td>:</td>
                        <td>{{ data.annual_payment }}</td>
                      </tr>
                      <tr>
                        <div class="invalid-feedback">
                          {{ validation.firstError("data.annual_payment") }}
                        </div>
                      </tr>
                    </table>
                  </div>
                </div>
                <slot v-if="storeAccess()">
                  <div class="row mb-5 align-items-center">
                    <div class="col-lg-4">
                      <div class="form-group mb-1 pt-3">
                        <label for>
                          Upload Latest Trade License (2023-2024)
                          <span class="requierd-star"></span>
                        </label>
                        <br />
                        <b-form-file
                          id
                          accept=".pdf"
                          placeholder="Choose a updated trade license"
                          drop-placeholder="Drop file here..."
                          ref="pdf1"
                          name="trade_license"
                          v-model="images.trade_license"
                        ></b-form-file>
                        <small>
                          <b
                            >File size must be smaller than 7mb
                            <small>(pdf only)</small></b
                          >
                        </small>
                        <small v-if="data.trade_license">
                          <b>
                            <a
                              target="_blank"
                              :href="
                                $root.baseurl + '/public/' + data.trade_license
                              "
                              >View File</a
                            >
                          </b>
                        </small>
                        <br />
                        <div class="invalid-feedback">
                          {{ validation.firstError("images.trade_license") }}
                        </div>

                        <small
                          v-if="errors && errors.trade_license"
                          class="text-danger"
                          >{{ errors.trade_license[0] }}</small
                        >
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group m-0">
                        <label for>
                          Trade License Validity Date
                          <span class="requierd-star"></span>&nbsp; &nbsp;&nbsp;
                        </label>

                        <Datepicker
                          class="datepicker"
                          placeholder="30-06-2024"
                          v-model="data.validity_date"
                        />
                      </div>
                      <div class="invalid-feedback">
                        {{ validation.firstError("data.validity_date") }}
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="form-group mb-0 pt-4">
                        <label for>
                          Income Tax Certificate/Acknowledgement Receipt
                          (Assessment Year 2023-2024):
                          <span class="requierd-star"></span>
                        </label>
                        <b-form-file
                          id
                          accept=".pdf"
                          placeholder="Choose a income tax  certificate"
                          drop-placeholder="Drop file here..."
                          ref="pdf1"
                          name="income_tax"
                          v-model="images.income_tax"
                        ></b-form-file>
                      </div>
                      <small>
                        <b
                          >File size must be smaller than 7mb
                          <small>(pdf only)</small></b
                        >
                      </small>
                      <small v-if="data.income_tax">
                        <b>
                          <a
                            target="_blank"
                            :href="$root.baseurl + '/public/' + data.income_tax"
                            >View File</a
                          >
                        </b>
                      </small>
                      <br />
                      <div class="invalid-feedback">
                        {{ validation.firstError("images.income_tax") }}
                      </div>
                      <small
                        v-if="errors && errors.income_tax"
                        class="text-danger"
                        >{{ errors.income_tax[0] }}</small
                      >
                    </div>
                  </div>
                  <div class="row mb-5 align-items-center">
                    <input
                      type="checkbox"
                      v-model="data.checked_condition"
                      name="checked_condition"
                      class="statedaretrue"
                      id="statedaretrue"
                    />
                    <label for="statedaretrue"
                      >I hereby declare that the above particulars of facts and
                      information stated correct.</label
                    >
                    <div class="invalid-feedback pl-5">
                      {{ validation.firstError("data.checked_condition") }}
                    </div>
                    <small
                      class="text-danger pl-5"
                      v-if="errors && errors.checked_condition"
                      >{{ errors.checked_condition[0] }}</small
                    >
                  </div>
                  <div class="row">
                    <div class="signature">
                      <img
                        :src="
                          sign_src
                            ? sign_src
                            : $root.baseurl + '/public' + data.rep_sign
                        "
                      />
                      <span>Signature</span>
                    </div>

                    <div class="col-3">
                      <b-form-file
                        id="rep_sign"
                        v-if="!data.rep_sign"
                        accept=".png,.jpg,.jpeg"
                        placeholder="Choose Signature"
                        drop-placeholder="Drop file here..."
                        name="rep_sign"
                        v-model="images.rep_sign"
                      ></b-form-file>
                    </div>
                    <div v-if="!images.rep_sign" class="invalid-feedback pl-4">
                      {{ validation.firstError("data.rep_sign") }}
                    </div>
                  </div>
                </slot>
                <div v-else class="row">
                  <div v-if="data.trade_license" class="w-100">
                    <b>Trade License</b> :
                    <a
                      target="_blank"
                      :href="$root.baseurl + '/public/' + data.trade_license"
                      >View File</a
                    >
                  </div>
                  <div v-if="data.trade_license" class="w-100">
                    <b>Validity Date</b> : {{ data.validity_date }}
                  </div>
                  <div v-if="data.income_tax" class="w-100">
                    <b>Income Tax Certificate </b> :
                    <a
                      target="_blank"
                      :href="$root.baseurl + '/public/' + data.income_tax"
                      >View File</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <br />
            <!-- :disabled="canSubmit" -->
            <button class="btn btn-success" type="submit" v-if="storeAccess()">
              <span v-if="canSubmit">Submit</span>
              <span v-else>Update</span>
            </button>
          </form>
          <div
            class="alert alert-warning mt-3"
            role="alert"
            v-if="voter_eligible == false"
          >
            You are not eligible for voter.
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>
            {{data}}
		</pre>-->
  </div>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);

export default {
  data() {
    return {
      data: { checked_condition: false },
      images: {},
      sign_src: "",
      validity: "",
      canSubmit: false,
      editVoterInfo: false,
      member: {
        info: {},
      },
      errors: {},
      validity_date_check: false,
      voter_eligible: null,
    };
  },
  watch: {
    "images.rep_sign": function (img, oldImg) {
      if (img) {
        this.showImage(img, "sign_src");
      }
    },
  },
  methods: {
    submit() {
      this.$validate().then((res) => {
        // 2021, 26 oct after each member can not submit voter form
        if (this.data.voter_eligible == true) {
          this.voter_eligible = true;
          // setTimeout(() => {
          //   this.voter_eligible = false;
          // }, 3000);
          // return false;
        }

        if (this.data.voter_eligible == false) {
          this.voter_eligible = false;
          return false;
        }

        if (this.validity_date_check == false) {
          this.$toastr(
            "warning",
            "Please upload your latest trade license",
            "Empty Mandatory Fields!!"
          );
          return false;
        }

        // Date validation logic
        var validityDate = new Date(this.data.validity_date);
        var cutoffDate = new Date("2024-06-30");

        if (validityDate <= cutoffDate) {
          this.$toastr(
            "error",
            "Sorry! Your trade license should be valid until June 30, 2024 or above",
            "Invalid Date"
          );
          return false;
        }

        if (this.validation.countErrors() > 0) {
          this.$toastr(
            "warning",
            'You need to fill <span class="font-weight-bold">' +
              this.validation.countErrors() +
              " more empty</span> mandatory fields",
            "Empty Mandatory Fields!!"
          );
          console.log(this.validation.allErrors());
          return false;
        }

        if (res) {
          this.scrollTop();
          this.$parent.loading = true;

          var form = document.getElementById("form");
          var formData = new FormData(form);

          formData.append("data", JSON.stringify(this.data));

          if (this.images.trade_license) {
            formData.append("trade_license", this.images.trade_license);
          } else {
            this.images.trade_license = "";
          }
          if (this.images.income_tax) {
            formData.append("income_tax", this.images.income_tax);
          } else {
            this.images.income_tax = "";
          }
          if (this.images.rep_sign) {
            formData.append("rep_sign", this.images.rep_sign);
          } else {
            this.images.rep_sign = "";
          }
          axios
            .post("/member/voter-info", formData)
            .then((res) => {
              if (res.status == 200) {
                this.$toastr("success", res.data.message, "Success!!");
                this.getData();
                this.errors = {};
                // window.location = this.$root.baseurl + "/member/dashboard";
              }
            })
            .catch((error) => {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors || {};
              }
            })
            .then((alw) => (this.$parent.loading = false));
        }
      });
    },
    getData() {
      axios
        .get("/member/voter-info")
        .then((res) => {
          this.data = res.data.info;
          this.validity = res.data.validity;
          this.canSubmit = res.data.submitted;
          this.validity_date_check = res.data.validity_date_check;
        })
        .catch((error) => {})
        .then((alw) => (this.$parent.loading = false));
    },

    storeAccess() {
      if (this.data.status !== "a" && this.editVoterInfo) {
        return true;
      } else if (this.canSubmit) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    this.loggedIn();
    this.getData();
  },

  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },

  validators: {
    "data.address": function (value = null) {
      return Validator.value(value).required(
        "Update your office address from company information section."
      );
    },
    "data.logo": function (value = null) {
      return Validator.value(value).required(
        "please upload logo from company information section."
      );
    },
    "data.rep_sign": function (value = null) {
      if (!this.images.rep_sign) {
        return Validator.value(value).required(
          'please upload sign from - "upload photos & files section".'
        );
      } else {
        return Validator.value(value);
      }
    },
    "data.rep_image": function (value = null) {
      return Validator.value(value).required(
        "Update Your representative information"
      );
    },
    "data.annual_payment": function (value = null) {
      return Validator.value(value)
        .required("Annual payment is required")
        .custom(function () {
          if (value == "Due") {
            return "Annual payment status must be paid.";
          }
        });
    },

    // "images.income_tax": function (value = null) {
    //   return Validator.value(value).required("Income Tax is required.");
    // },
    // "images.trade_license": function (value = null) {
    //   return Validator.value(value).required("Trade License is required.");
    // },
    "images.income_tax": function (value = null) {
      return Validator.value(value)
        .required("Income Tax is required.")
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "PDF format required";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 7168000) {
              return "PDF must be smaller than 7 MB";
            }
          }
        });
    },
    "images.trade_license": function (value = null) {
      return Validator.value(value)
        .required("Trade License is required.")
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "PDF format required";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 7168000) {
              return "PDF must be smaller than 7 MB";
            }
          }
        });
    },
    "data.validity_date": function (value = null) {
      return Validator.value(value).required("Validity date is required.");
    },
    "data.checked_condition": function (value = null) {
      return Validator.value(value)
        .required("Please check this condition.")
        .custom(function () {
          if (value == false) {
            return "Please check this box if you want to proceed.";
          }
        });
    },
  },
};
</script>

<style lang="scss">
.company-name-logo {
  height: 90px;
  text-align: center;
  img {
    object-fit: contain;
    width: 100%;
    height: 75px;
  }
  h2 {
    font-size: 17px;
    font-weight: 600;
    margin-top: 6px;
  }
}
.repentative-info-box {
  border: 1px solid #dcdcdc;
  padding: 5px;
  border-radius: 18px;
  .rep-image {
    text-align: center;
    img {
      height: 108px;
      width: 107px;
      border: 2px solid #6dc8be;
      border-radius: 8px;
      object-fit: cover;
      margin: 6px 0px;
    }
  }
}
.company-info-box h6 {
  font-size: 21px;
  border: 2px solid #6dc8be;
  display: inline-block;
  margin: 25px 0px;
  padding: 6px 13px;
  border-radius: 11px;
}
.voter-info-table tr {
  th {
    border: 0;
    padding: 4px;
  }

  td {
    border: 0;
    padding: 4px 10px;
  }
}
input#statedaretrue {
  margin-left: 17px;
  margin-right: 16px;
}
.signature {
  margin-left: 13px;
  text-align: center;
  width: 135px;
  img {
    width: 127px;
    height: 60px;
    object-fit: contain;
  }
  span {
    border-top: 1px dashed #6c6b6b;
    display: block;
    font-size: 17px;
    padding: 7px 0px;
  }
}
.datepicker {
  border: 1px solid #444;
  height: 35px;
  border-radius: 5px;
  &::before {
    font-size: 20px;
    top: 4px;
  }
}
</style>


