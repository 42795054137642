<template>
	<div>
		<div
			v-if="$root.ecomLogged && Object.keys($root.loggedCustomer).length > 0"
			class="dashboard-layout container-wrapper"
		>
			<div class="row">
				<LeftmenuBar />
				<div class="col-md-9">
                    <button @click="showSidemenu = !showSidemenu" type="button" title="click to view menu"
                            class="btn btn-secondary  float-right d-block d-lg-none"><i class="fa fa-list-ul"></i></button>
					<router-view></router-view>
				</div>
			</div>
		</div>
		<div v-if="dashboardLoader" class="dashboard-loader">
			<div class="row">
				<div class="col-md-12 my-5">
					<spinner />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import LeftmenuBar from "./LeftmenuBar";
	import Breadcrumb from "../../../../components/Breadcrumb";

	export default {
		name: "Layout",
		data() {
			return {
                showSidemenu:false,
				dashboardLoader: false
			};
		},
        watch: {
            $route: {
                handler: "asyncData",
                immediate: true,
            },
        },
        methods:{
            asyncData(){
                this.showSidemenu = false;
            }
        },
		components: { Breadcrumb, LeftmenuBar },

		beforeCreate: function() {
			this.$root.loading = false;
		},
		created() {
			this.$eventBus.$on("miniLoader", prototype => {
				this.dashboardLoader = prototype;
			});
		},

	};
</script>

<style>
	.dashboard-loader {
		position: fixed;
		height: 100%;
		width: 100%;
		z-index: 1234;
		left: 0;
		top: 0;
		background: #fffffff0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
