<template>
	<div
		class="member-services"
		v-if="
			(!$parent.loading && $root.access && $root.access['payment_history']) ||
			$root.variable.rep_login ||
			$root.variable.aLogId
		"
	>
		<div class="card shadow-sm mb-3">
			<div class="card-header px-0">
				<div class="d-flex align-items-center justify-content-between">
					<h5 class="text-uppercase font-weight-bold px-3 mb-0">
						Payment History
					</h5>
					<span v-if="Object.keys(bankData).length == 0"
						><MemberBankInfo
					/></span>
					<span v-else
						><MemberBankInfoView v-on:memberBankInfo="updateCompleted"
					/></span>
				</div>
			</div>
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-striped">
						<thead>
							<tr>
								<th scope="col">DATE</th>
								<th scope="col">INVOICE ID</th>
								<th scope="col">PURPOSE</th>
								<th scope="col">AMOUNT</th>
								<th scope="col">STATUS</th>
								<th class="text-center" style="width: 210px">ACTION</th>
							</tr>
						</thead>
						<tbody>
							<slot v-if="table.datas">
								<tr v-for="(pay, index) in table.datas" :key="index">
									<td>{{ pay.invoice_date | formatDate("Do MMM, YYYY") }}</td>
									<td>
										BASIS-{{ pay.created_at | formatDate("DDMMYYYY") }}-{{
											pay.invoice_number
										}}
									</td>
									<th>
										<span v-if="pay.account_head">{{
											pay.account_head.name
										}}</span>
										<slot v-else-if="pay.type == 'training'">
											<span v-if="pay.training">{{ pay.training.name }}</span>
										</slot>
										<slot v-else-if="pay.type == 'event'">
											<span v-if="pay.event">{{ pay.event.title }}</span>
										</slot>
										<slot v-else>
											<span v-if="pay.service">
												<slot v-if="pay.service.title">{{
													pay.service.title
												}}</slot>
												<br />
												<small
													v-if="
														pay.service.id == 31 &&
														pay.get_service &&
														pay.get_service.card
													"
													>( {{ pay.get_service.card.title }} )</small
												>
											</span>
										</slot>
									</th>
									<td>{{ pay.amount | currency }}</td>
									<td>
										<span
											v-if="pay.status == 'pending'"
											class="badge badge-warning"
											>pending</span
										>
										<span v-else class="badge badge-success">paid</span>
									</td>
									<td class="text-center">
										<router-link
											:to="{ name: 'memberInvoice', params: { id: pay.id } }"
											class="btn btn-info btn-sm"
											>View Invoice</router-link
										>
										<router-link
											v-if="
												pay.status == 'pending' &&
												member.info &&
												member.info.status == 1
											"
											class="btn btn-success btn-sm"
											:to="{ name: 'paymentOption', params: { id: pay.id } }"
											>Check Out</router-link
										>
										<router-link
											v-else-if="
												pay.account_head &&
												member.info &&
												pay.status == 'pending' &&
												pay.account_head.id == 2 &&
												member.info.status == 2
											"
											class="btn btn-success btn-sm"
											:to="{ name: 'paymentOption', params: { id: pay.id } }"
											>Check Out</router-link
										>
										<router-link
											v-else-if="
												pay.account_head &&
												member.info &&
												pay.status == 'pending' &&
												pay.account_head.id == 2 &&
												member.info.status == 0
											"
											class="btn btn-success btn-sm"
											:to="{ name: 'paymentOption', params: { id: pay.id } }"
											>Check Out</router-link
										>
										<router-link
											v-if="pay.status == 'success'"
											:to="{ name: 'moneyReceipt', params: { id: pay.id } }"
											class="btn btn-success btn-sm"
											>Money Receipts</router-link
										>
									</td>
								</tr>
							</slot>
						</tbody>
					</table>
				</div>
				<!-- pagination -->
				<Pagination :url="'/member/paymentHistory'" />
				<!-- /pagination -->
			</div>
		</div>
	</div>
	<div v-else class="row">
		<div class="col-12">
			<div class="card">
				<div v-if="Object.keys($root.access).length > 0" class="card-body">
					<h4 class="text-center text-danger">
						Sorry!! you cannot access this page
					</h4>
				</div>
				<div v-else class="card-body">
					<div
						v-if="!$parent.loading"
						class="
							card-body
							d-flex
							align-items-center
							justify-content-center
							flex-column
						"
					>
						<i
							class="fa fa-spinner fa-spin"
							style="font-size: 24px; color: rgb(0, 127, 62)"
						></i>
						<span class="processing">processing</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Pagination from "../../../../components/Pagination";
	import SimpleVueValidation from "simple-vue-validator";
	import MemberBankInfo from "./MemberBankInfo";
	import MemberBankInfoView from "./MemberBankInfoView";
	import MemberInfoEdit from "./MemberInfoEdit";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		name: "paymentHistory",
		props: ["memberInfo"],
		components: {
			Pagination,
			MemberBankInfo,
			MemberBankInfoView,
			MemberInfoEdit,
		},
		data() {
			return {
				dueYear: {},
				table: { datas: [] },
				pagination: [],
				meta: [],
				links: [],
				errors: {},
				total_amount: 0,
				member: {
					info: {},
				},
				bankData: {},
			};
		},
		validators: {},
		methods: {
			getPandingDueYear: function (paymentYear, invoice_id) {
				axios
					.post("/member/getPandingDueYear", {
						invoice_date: paymentYear,
						invoice_id: invoice_id,
					})
					.then((res) => {
						if (res.data) {
							this.$toastr(
								"warning",
								"Please pay your previous annual subscription fee first",
								"Sorry!!"
							);
							return false;
						} else {
							this.sslPayment();
						}
					});
			},
			paymentHistory: function (pageindex) {
				this.$parent.loading = true;
				let url = "/member/paymentHistory";
				if (pageindex > 0) {
					url = "/member/paymentHistory?page=" + pageindex;
				}
				axios
					.get(url)
					.then((response) => {
						this.table.datas = response.data.data;
						this.meta = response.data.meta;
						this.links = response.data.links;
					})
					.catch((error) => {})
					.then((res) => {
						setTimeout(
							() => (this.$parent.loading = false),
							this.$root.minloadtimefd
						);
					});
			},
			sslPayment: function (invoice_id) {
				this.$parent.loading = true;
				axios
					.post("/ssl-payment", {
						type: "member",
						id: this.memberInfo.id,
						invoice_id: invoice_id,
					})
					.then((response) => {
						if (response.data.GatewayPageURL) {
							window.location.href = response.data.GatewayPageURL;
						}
					})
					.then((res) => {
						setTimeout(
							() => (this.$parent.loading = false),
							this.$root.minloadtimefd
						);
					});
			},
			ckeckBankInfo: function () {
				axios.get("/member/check-bank-info").then((res) => {
					this.bankData = res.data;
				});
			},
			updateCompleted() {
				this.ckeckBankInfo();
			},
		},
		mounted() {
			this.ckeckBankInfo();
			this.loggedIn();
			this.paymentHistory();
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
	};
</script>

