<template>
	<div class="eventCalendar">
		<link
			href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap"
			rel="stylesheet"
		/>
		<link
			href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
			rel="stylesheet"
		/>
		<link
			rel="stylesheet"
			href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"
		/>
		<div class="card calender-card shadow-lg">
			<!-- <vue-event-calendar
				:title="title"
				:events="eventsCalendar"
				@day-changed="handleDayChanged"
			>
			</vue-event-calendar> -->
			<vue-event-calendar
				:title="title"
				:events="demoEvents"
				@day-changed="handleDayChanged"
			>
			</vue-event-calendar>

			<div class="calander-button">
				<router-link
					:to="{
						name: 'allEventCalender',
					}"
					class="btn btn-sm btn-outline-primary"
					>All Events</router-link
				>
			</div>

			<!-- Modal -->
			<modal ref="modal" :events="events"></modal>
			<!-- <pre>{{ eventsCalendar }}</pre> -->

			<b-modal
				id="modal-1"
				class="custom-modal"
				v-model="eventModelShow"
				hide-footer
				size="lg"
			>
				<h1 class="new-date col-lg-6">
					<b>{{ clickDate | formatDate("Do MMM YYYY") }}</b>
				</h1>
				<!-- <div class="hover-event">
					<div class="row" v-if="events">
						<div
							class="col-lg-12"
							v-for="(event, index) in dateWiseEnents"
							:key="index"
						>
							<div class="d-block w-100">
								<div class="hover-event-item border">
									<h4>
										{{ event.event_name }}
									</h4>
									<ul class="time">
										<li>
											<i class="bi bi-stopwatch"></i>
											<span>{{ event.from_date }} to {{ event.to_date }}</span>
										</li>
										<li class="ml-4">
											<i class="bi bi-calendar3"></i>
											<span>{{ event.from_time }} to {{ event.to_time }}</span>
										</li>
									</ul>
									<ul>
										<li style="list-style: none">
											<i class="bi bi-geo-alt-fill"></i>
											<span>{{ event.location }}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div> -->
				<div class="hover-event">
					<div class="row" v-if="events">
						<div
							class="col-lg-12"
							v-for="(event, index) in dateWiseEnents"
							:key="index"
						>
							<div class="single-item">
								<div class="event-item">
									<a href="javascript:void(0);" class="d-block w-100">
										<div class="row">
											<div class="col-lg-3">
												<div
													class="
														time
														d-flex
														justify-content-center
														align-items-center
													"
												>
													<span
														>{{ event.from_time }} - {{ event.to_time }}</span
													>
												</div>
											</div>
											<div class="col-lg-9">
												<div class="info">
													<h3>
														{{ event.event_name }}
													</h3>
													<ul>
														<li>
															<i
																class="fa fa-map-marker"
																aria-hidden="true"
															></i>
															<span>{{ event.location }}</span>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-modal>
		</div>
	</div>
</template>

<script>
	import "vue-event-calendar/dist/style.css";
	import vueEventCalendar from "vue-event-calendar";
	Vue.use(vueEventCalendar, {
		locale: "en",
	});

	import Modal from "../../components/elements/Modal";

	Vue.component("Modal", {
		template: "#modal",
	});
	import moment from "moment";
	import axios from "axios";

	export default {
		components: {
			Modal,
			moment,
		},
		data() {
			return {
				title: "Event Calendar",
				name: "home",
				itemsPerRow: 2,
				data: {},
				events: {},
				clickDate: "",
				demoEvents: [
					{
						date: "2060/12/30", // Required
						title: "Foo", // Required
					},
					{
						date: "2060/12/30",
						title: "Bar",
					},
				],
				eventsCalendar: [],
				dateWiseEnents: [],
				// eventsBasedCalendar: [],
				month: {},
				eventModelShow: false,
			};
		},

		computed: {},
		methods: {
			getEvents() {
				axios.get("/get-events-calender").then((response) => {
					var Calendar = response.data;
					Calendar.forEach((date) => {
						date.date = moment(date.date).format("Y/M/DD");
					});
					// console.log(Calendar);
					this.eventsCalendar = Calendar;
				});
			},

			// getEventsCalendar() {
			// 	axios.get("/get-events-based-calendar").then((response) => {
			// 		var NewCalendar = response.data;
			// 		NewCalendar.forEach((date) => {
			// 			date.date = moment(date.date).format("Y/M/DD");
			// 		});
			// 		this.eventsCalendar = NewCalendar;
			// 	});
			// },

			handleDayChanged(data) {
				this.clickDate = data.date;

				var split = data.date.split("/");
				var date = split[2] + "-" + split[1] + "-" + split[0];
				// var title = data.event.event_name;
				// var splitTitle = title.split("/");

				this.getEventsBasedCalendar(date);

				// if (splitTitle[1] == "event") {
				// 	this.getEventsBasedCalendar(date);
				// 	this.eventModelShow = true;
				// 	// this.$router.push({
				// 	// 	name: "event.detail",
				// 	// 	params: { slug: splitTitle[0] },
				// 	// });
				// }
				// } else {
				// 	// this.$router.push({
				// 	// 	name: "TrainingSeminarDetails",
				// 	// 	params: { id: splitTitle[0], type: "seminar" },
				// 	// });
				// }
				// this.eventModelShow = true;
			},
			getEventsBasedCalendar(date) {
				axios
					.get("/get-events-based-calendar", { params: date })
					.then((res) => {
						this.dateWiseEnents = res.data ? res.data : "";
						if (res.data.length != 0) {
							this.eventModelShow = true;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			monthList(index) {
				var months = [];
				months.push(moment().month(index).format("MMMM"));
				return month;
			},
			hideModal() {
				this.$refs["modal-1"].hide();
			},
		},
		created() {
			this.getEvents();
			// this.getEventsCalendar();
		},
		mounted() {
			// var contHTML = "";
			// var contHTML2 = "";
			// var contHTML3 = "";
			// var titleHTML = "";
			// $(".cal-header").children(".title").css("display", "none");
			// contHTML += "<div class='calendar-container'>";
			// contHTML += "<header>";
			// contHTML += "<span class='shape'></span>";
			// contHTML += " <div class='content-header mb-4 mt-3'>";
			// contHTML += "<h1 class='title text-light text-center'>Event Calendar</h1>";
			// contHTML += "</div>";
			// contHTML +=
			// 	"<div class='d-flex align-items-center justify-content-between'>";
			// contHTML += "<div>";
			// contHTML += "<div class='day'>05</div>";
			// contHTML += "<div class='month'>December</div>";
			// contHTML += "</div>";
			// contHTML += "<div class='year'>2018</div>";
			// contHTML += "</div>";
			// contHTML += "</header>";
			// contHTML += "</div>";
			// $(".cal-wrapper").prepend(contHTML);
			// var date = $(".cal-header").children(".title").text();
			// var spDate = date.split("/");
			// var monthName = moment()
			// 	.month(parseInt(spDate[0]) - 1)
			// 	.format("MMMM");
			// $(".day").text(moment().format("DD"));
			// $(".month").text(monthName);
			// $(".year").text(spDate[1]);
			// contHTML2 += "<div class='ring-left'></div>";
			// contHTML2 += "<div class='ring-right'></div>";
			// $(".cal-wrapper").children(".cal-body").append(contHTML2);
			// $(".cal-wrapper").children(".cal-body").addClass("calendar-body");
			// // arrow left=======
			// // arrow right=======
			// $(".r")
			// 	.children(".arrow-right")
			// 	.click(function () {
			// 		var date = $(".cal-header").children(".title").text();
			// 		var spDate = date.split("/");
			// 		if (spDate[0] > "12") {
			// 			var month = "01";
			// 			var year = parseInt(spDate[1]);
			// 		} else {
			// 			var month = parseInt(spDate[0]);
			// 			if (parseInt(spDate[0]) <= 9) {
			// 				month = "0" + month;
			// 			}
			// 			var year = spDate[1];
			// 		}
			// 		var monthName = moment()
			// 			.month(parseInt(month) - 1)
			// 			.format("MMMM");
			// 		$(".day").text(moment().format("DD"));
			// 		$(".month").text(monthName);
			// 		$(".year").text(year);
			// 		titleHTML = "";
			// 		$(".event_title").html("");
			// 		setTimeout(function () {
			// 			var allDates = $(".dates").find(".event");
			// 			$.each(allDates, function (key, value) {
			// 				var title = "";
			// 				titleHTML = "";
			// 				var dayD = $(this).find("p").text();
			// 				var fullDate = dayD + "/" + date;
			// 				axios
			// 					.get("/get-event-datewise/", { params: { date: fullDate } })
			// 					.then((response) => {
			// 						$.each(response.data, function (key1, data) {
			// 							title += data.title + ",";
			// 							title += "\n";
			// 						});
			// 						titleHTML =
			// 							"<div class='event_title'><span>" + title + "</span></div>";
			// 						$(this).append(titleHTML);
			// 					});
			// 			});
			// 		}, 100);
			// 	});
			// // arrow left=======
			// // arrow left=======
			// $(".l").click(function () {
			// 	var date = $(".cal-header").children(".title").text();
			// 	var spDate = date.split("/");
			// 	if (spDate[0] === "00") {
			// 		var month = "12";
			// 		var year = parseInt(spDate[1]);
			// 	} else {
			// 		var month = parseInt(spDate[0]);
			// 		if (parseInt(spDate[0]) <= 9) {
			// 			month = "0" + month;
			// 		}
			// 		var year = spDate[1];
			// 	}
			// 	var monthName = moment()
			// 		.month(parseInt(month) - 1)
			// 		.format("MMMM");
			// 	$(".day").text(moment().format("DD"));
			// 	$(".month").text(monthName);
			// 	$(".year").text(year);
			// 	titleHTML = "";
			// 	$(".event_title").html("");
			// 	setTimeout(function () {
			// 		var allDates = $(".dates").find(".event");
			// 		$.each(allDates, function (key, value) {
			// 			var title = "";
			// 			titleHTML = "";
			// 			var dayD = $(this).find("p").text();
			// 			var fullDate = dayD + "/" + date;
			// 			axios
			// 				.get("/get-event-datewise/", { params: { date: fullDate } })
			// 				.then((response) => {
			// 					if (Object.keys(response.data).length > 0) {
			// 						$.each(response.data, function (key1, data) {
			// 							title += data.title + ",";
			// 							title += "\n";
			// 						});
			// 						titleHTML =
			// 							"<div class='event_title'><span>" + title + "</span></div>";
			// 						$(this).append(titleHTML);
			// 					}
			// 				});
			// 		});
			// 	}, 100);
			// });
			// setTimeout(function () {
			// 	var allDates = $(".dates").find(".event");
			// 	$.each(allDates, function (key, value) {
			// 		var title = "";
			// 		titleHTML = "";
			// 		var dayD = $(this).find("p").text();
			// 		var fullDate = dayD + "/" + date;
			// 		axios
			// 			.get("/get-event-datewise/", { params: { date: fullDate } })
			// 			.then((response) => {
			// 				if (Object.keys(response.data).length > 0) {
			// 					$.each(response.data, function (key1, data) {
			// 						title += data.title + ",";
			// 						title += "\n";
			// 					});
			// 					titleHTML =
			// 						"<div class='event_title'><span>" + title + "</span></div>";
			// 					$(this).append(titleHTML);
			// 				}
			// 			});
			// 	});
			// }, 2000);

			var contHTML = "";
			var contHTML2 = "";
			var contHTML3 = "";
			var titleHTML = "";
			$(".cal-header").children(".title").css("display", "none");

			contHTML += "<div class='calendar-container'>";
			contHTML += "<header>";
			contHTML += "<span class='shape'></span>";
			contHTML += " <div class='content-header mb-4 mt-3'>";
			contHTML += "<h1 class='title text-light text-center'>Event Calendar</h1>";
			contHTML += "</div>";
			contHTML +=
				"<div class='d-flex align-items-center justify-content-between'>";
			contHTML += "<div>";
			contHTML += "<div class='day'>05</div>";
			contHTML += "<div class='month'>December</div>";
			contHTML += "</div>";
			contHTML += "<div class='year'>2018</div>";
			contHTML += "</div>";
			contHTML += "</header>";
			contHTML += "</div>";

			$(".cal-wrapper").prepend(contHTML);
			var date = $(".cal-header").children(".title").text();
			var spDate = date.split("/");
			var monthName = moment()
				.month(parseInt(spDate[0]) - 1)
				.format("MMMM");

			$(".day").text(moment().format("DD"));
			$(".month").text(monthName);
			$(".year").text(spDate[1]);
			contHTML2 += "<div class='ring-left'></div>";
			contHTML2 += "<div class='ring-right'></div>";
			$(".cal-wrapper").children(".cal-body").append(contHTML2);
			$(".cal-wrapper").children(".cal-body").addClass("calendar-body");

			// arrow left=======
			// arrow right=======
			$(".r")
				.children(".arrow-right")
				.click(function () {
					var date = $(".cal-header").children(".title").text();
					var spDate = date.split("/");
					if (spDate[0] > "12") {
						var month = "01";
						var year = parseInt(spDate[1]);
					} else {
						var month = parseInt(spDate[0]);
						if (parseInt(spDate[0]) <= 9) {
							month = "0" + month;
						}
						var year = spDate[1];
					}
					var monthName = moment()
						.month(parseInt(month) - 1)
						.format("MMMM");
					$(".day").text(moment().format("DD"));
					$(".month").text(monthName);
					$(".year").text(year);
					titleHTML = "";
					$(".event_title").html("");
					console.log("akjfhnuifdjf");
					setTimeout(function () {
						var allDates = $(".dates").find(".event");
						$.each(allDates, function (key, value) {
							var title = "";
							titleHTML = "";
							var dayD = $(this).find("p").text();
							var fullDate = dayD + "/" + date;
							axios
								.get("/get-events-based-calendar", {
									params: { date: fullDate },
								})
								.then((response) => {
									console.log("daf", response);
									$.each(response.data, function (key1, data) {
										title += data.title + ",";
										title += "\n";
									});
									titleHTML =
										"<div class='event_title'><span>" + title + "</span></div>";
									$(this).append(titleHTML);
								});
						});
					}, 100);
				});
			// arrow left=======
			// arrow left=======
			$(".l").click(function () {
				var date = $(".cal-header").children(".title").text();
				var spDate = date.split("/");
				if (spDate[0] === "00") {
					var month = "12";
					var year = parseInt(spDate[1]);
				} else {
					var month = parseInt(spDate[0]);
					if (parseInt(spDate[0]) <= 9) {
						month = "0" + month;
					}
					var year = spDate[1];
				}
				var monthName = moment()
					.month(parseInt(month) - 1)
					.format("MMMM");
				$(".day").text(moment().format("DD"));
				$(".month").text(monthName);
				$(".year").text(year);
				titleHTML = "";
				$(".event_title").html("");
				setTimeout(function () {
					var allDates = $(".dates").find(".event");
					$.each(allDates, function (key, value) {
						var title = "";
						titleHTML = "";
						var dayD = $(this).find("p").text();
						var fullDate = dayD + "/" + date;
						axios
							.get("/get-event-datewise/", {
								params: { date: fullDate },
							})
							.then((response) => {
								if (Object.keys(response.data).length > 0) {
									$.each(response.data, function (key1, data) {
										title += data.title + ",";
										title += "\n";
									});
									titleHTML =
										"<div class='event_title'><span>" + title + "</span></div>";
									$(this).append(titleHTML);
								}
							});
					});
				}, 100);
			});
			setTimeout(function () {
				var allDates = $(".dates").find(".event");
				$.each(allDates, function (key, value) {
					var title = "";
					titleHTML = "";
					var dayD = $(this).find("p").text();
					var fullDate = dayD + "/" + date;
					axios
						.get("/get-event-datewise/", { params: { date: fullDate } })
						.then((response) => {
							if (Object.keys(response.data).length > 0) {
								$.each(response.data, function (key1, data) {
									title += data.title + ",";
									title += "\n";
								});
								titleHTML =
									"<div class='event_title'><span>" + title + "</span></div>";
								$(this).append(titleHTML);
							}
						});
				});
			}, 2000);
		},
	};
</script>

<style scoped>
	/* event part start */
	/* event part start */

	.new-date {
		text-align: center !important;
		justify-content: center !important;
		font-size: 25px;
		margin-left: auto;
		margin-right: auto;
	}

	.hover-event {
		padding: 20px;
	}

	.hover-event .single-item:last-child {
		margin-bottom: 0px;
	}

	.hover-event .event-item {
		border-radius: 5px;
		box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
		margin-bottom: 20px;
	}

	.hover-event .event-item:last-child {
		margin-bottom: 0;
	}

	.hover-event .date {
		padding-bottom: 14px;
		margin-bottom: 15px;
	}

	.hover-event .date h4 {
		font-size: 24px;
		font-weight: 700;
		color: #070919;
	}

	.hover-event .time {
		background-color: #6dc8be;
		color: #ffffff;
		width: 100%;
		height: 100%;
		text-align: center;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.hover-event .time span {
		font-size: 15px;
		font-weight: 600;
		color: #ffffff;
	}

	.hover-event .info {
		padding: 20px 0;
	}

	.hover-event .info h3 {
		font-size: 20px;
		font-weight: 700;
		color: #6dc8be;
		margin-bottom: 20px;
	}

	.hover-event .info ul {
		list-style-type: none;
	}

	.hover-event .info ul li {
		font-size: 15px;
		font-weight: 400;
		color: #070919;
	}

	.hover-event .info ul li i {
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		border-radius: 50%;
		background-color: #ed1c24;
		color: #fff;
		font-size: 14px;
		margin-right: 8px;
	}

	/* event list part end */
	@media (min-width: 992px) {
		.hover-event .single-item {
			margin-bottom: 24px !important;
		}
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		.col-lg-9,
		.col-lg-3 {
			padding: 24px !important;
		}
		.event-item .info ul li span {
			margin-bottom: 10px;
			display: inline-block;
		}
		.hover-event .single-item {
			margin-bottom: 24px !important;
		}
	}

	@media (min-width: 360px) and (max-width: 767.98px) {
		.col-lg-9,
		.col-lg-3 {
			padding: 24px !important;
		}
		.event-item .info ul li span {
			margin-bottom: 10px;
			display: inline-block;
		}
		.hover-event .single-item {
			margin-bottom: 24px !important;
		}
	}

	/* event part end */
</style>
