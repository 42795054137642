<template>
	<div class="member-invoice" v-if="!$root.spinner">
		<div class="col-12 form-row m-4 justify-content-center">
			<div class="col-3 text-center">
				<button @click="printInvoice(invoice.id)" class="btn btn-info">
					<i class="fa fa-download"></i> Download
				</button>
			</div>
			<div class="col-3 text-center">
				<button
					class="btn btn-success"
					type="button"
					@click="sslPayment(invoice.id)"
				>
					Pay Now
				</button>
			</div>
		</div>
		<!-- Invoice -->
		<div class="invoice-frontend border border-dark" v-if="invoice">
			<img
				v-lazy="$root.baseurl + '/public/images/BASIS_Letterhead.jpg'"
				width="100%"
				height="100%"
				alt="BASIS_Letterhead"
			/>
			<div
				class="invoice-content"
				style="
					position: absolute;
					top: 0;
					left: 0;
					padding: 80px 25px;
					width: 100%;
				"
			>
				<table
					class="invoice-frontend-table"
					width="100%"
					border="0"
					cellspacing="0"
					cellpadding="0"
				>
					<tr>
						<td>
							<p>
								Invoice No:
								<span v-if="invoice"
									>BASIS-{{ invoice.invoice_date | formatDate("DDMMYYYY") }}-N{{
										invoice.invoice_number
									}}</span
								>
							</p>
						</td>
						<td class="text-right">
							Date:
							<span v-if="invoice">{{
								invoice.invoice_date | formatDate("MMMM, DD YYYY")
							}}</span>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<p class="mb-2">To</p>
							<p class="mb-2">
								{{ invoice.company_name ? invoice.company_name : "" }}
							</p>
							<p class="mb-2">
								Attn:
								<b>
									<span>{{
										invoice.contact_person ? invoice.contact_person : ""
									}}</span>
								</b>
							</p>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<table width="100%">
								<tr>
									<td>
										<h3
											style="
												border: 1px solid;
												width: 20%;
												text-align: center;
												border-radius: 4px;
												padding: 4px 0px;
												margin: 10px auto;
											"
										>
											Invoice
										</h3>
									</td>
								</tr>
								<tr>
									<td>
										<table
											class="table"
											width="100%"
											border="1"
											cellpadding="0"
											cellspacing="0"
										>
											<thead>
												<tr>
													<th class="text-center">SL No.</th>
													<th class="text-center">Description</th>
													<th class="text-center">Amount</th>
												</tr>
											</thead>
											<tbody v-if="invoice">
												<tr>
													<td class="text-center">01</td>
													<td class="text-center">
														<span v-if="invoice.service">{{
															invoice.service.title
														}}</span>
													</td>
													<td class="text-center">
														{{ invoice.amount | currency }}
													</td>
												</tr>
												<tr>
													<td class="text-right" colspan="2">
														<b>Total=</b>
													</td>
													<td class="text-center">
														<b>{{ total_amount | currency }}</b>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
								<tr>
									<td v-if="invoice" class="text-left" colspan="3">
										Inword:
										<b>
											<span style="text-transform: capitalize"
												>{{ Math.round(total_amount) | inWords }} Only</span
											>
										</b>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<p class="text-justify mb-3">
								Please make payment in favor of <b>"BASIS"</b> by Cash Deposit
								or Pay Order or Cheque directly to Prime Bank Limited, Kawran
								Bazar branch <b>(Account No- "2113317014064")</b> please take 2
								(two) copies of invoice to the bank and after making successful
								payment, you are requested to send the scanned copy of the same
								with bank seal to <b>payment@basis.org.bd</b> for updating at
								BASIS end <br />
								<br />
								OR
								<br />
								<br />
								You may also pay the fee using the online payment gateway of
								BASIS.
							</p>
						</td>
					</tr>
				</table>
				<br />
				<br />
				<br />
				<br />
				<br />
				<p style="font-style: italic">
					"This is an electronically generated letter or invoice, no signature
					is required"
				</p>
			</div>
		</div>
		<!-- Invoice -->
		<div class="col-12 form-row m-4 justify-content-center">
			<div class="col-3 text-center">
				<button @click="printInvoice(invoice.id)" class="btn btn-info">
					<i class="fa fa-download"></i> Download
				</button>
			</div>
			<div class="col-3 text-center">
				<button
					class="btn btn-success"
					type="button"
					@click="sslPayment(invoice.id)"
				>
					Pay Now
				</button>
			</div>
		</div>
	</div>
</template>
<script>
	import sign from "../../../assets/img/sign/HashimAhmed.png";
	export default {
		name: "Invoice",
		props: ["invoice"],
		data() {
			return {
				sign: this.$root.baseurl + sign,
				invoiceNo: null,
				total_amount: null,
			};
		},
		methods: {
			sslPayment: function (invoice_id) {
				this.$root.spinner = true;
				axios
					.post("/ssl-payment", {
						type: "non-member",
						invoice_id: invoice_id,
					})
					.then((response) => {
						if (response.data.GatewayPageURL) {
							window.location.href = response.data.GatewayPageURL;
						}
					});
			},
			printInvoice(id) {
				if (confirm("Are you sure")) {
					this.$root.spinner = true;
					window.location.href =
						this.$root.baseurl + "/non-member-invoice-pdf/" + id;
					setTimeout(() => {
						this.$root.spinner = false;
					}, 200);
				}
			},
		},
		created() {
			this.total_amount = this.invoice.amount;
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
	};
</script>
<style>
	.invoice {
		/* height: 200px; */
		width: 660px;
		border: 1px solid #000000;
		margin: auto;
		padding: 20px;
	}

	.invoice .invoice-logo {
		width: 100%;
		/* border: 1px solid red; */
	}

	.invoice .invoice-logo img {
		/* height: 100px; */
		width: 100px;
	}

	.invoice .invoice-sec-1 {
		width: 100%;
		/* border: 1px solid red; */
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 60px;
		margin-bottom: 20px;
	}

	.invoice .invoice-sec-1 .invoice-sec-1-ref {
		/* border: 1px solid red; */
		width: 70%;
	}

	.invoice .invoice-sec-1 .invoice-sec-1-date {
		/* border: 1px solid red; */
		width: 30%;
		/* height: 100%;
	        display: flex;
	        flex-direction: column;
	        justify-content: start;
	        align-items: flex-start; */
	}

	.invoice .invoice-sec-1 .invoice-sec-1-date p {
		position: relative;
		top: -107px;
		text-align: right;
	}

	.invoice .invoice-sec-1 .to-invoice {
		margin-top: 45px;
		/* padding-left: 42px; */
	}

	.invoice-table {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 40px;
		/* border: 1px solid red; */
	}

	.invoice-table .invoice-table-container {
		width: 100%;
		/* border: 1px solid #000000; */
		margin: auto;
	}

	.invoice-table .invoice-table-container .invoice-table-data {
		display: flex;
		flex-direction: row;
		/* border: 1px solid #000000; */
	}

	.invoice-table .invoice-table-container .invoice-table-data .invoice-table-sl {
		text-align: center;
		width: 20%;
		border: 0.5px solid #000000;
		border-left: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-sl-h {
		border-left: 1px solid #000000 !important;
		border-top: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-desc-h {
		border-top: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-desc {
		text-align: center;
		width: 60%;
		border: 0.5px solid #000000;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-amount-h {
		border-top: 1px solid #000000 !important;
		border-right: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-amount {
		text-align: center;
		width: 20%;
		border: 0.5px solid #000000;
		border-right: 1px solid #000000 !important;
	}

	.invoice-table .invoice-table-container .invoice-table-footer {
		border: 1px solid #000000;
		display: flex;
		flex-direction: row;
		border-top: 0.5px solid #000000 !important;
	}

	.invoice-table .invoice-table-container .invoice-table-footer .invoice-total {
		text-align: center;
		width: 80%;
		/* border: 1px solid #000000; */
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-footer
		.invoice-total-amount {
		text-align: center;
		width: 20%;
		/* border: 1px solid #000000; */
	}

	p {
		padding: 0;
		/* margin: 4px; */
	}

	.invoice .invoice-banner {
		margin: 5px;
		width: 100%;
	}

	.invoice .invoice-banner .banner-d {
		width: 200px;
		border: 2px solid #000000;
		border-radius: 5px;
		margin: auto;
		padding: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.invoice .invoice-banner .banner-d p {
		font-weight: bold;
		margin: 0px;
	}

	.invoice .invoice-declaration {
		text-align: center;
	}

	.invoice .invoice-greeting {
		margin-top: 10px;
		margin-bottom: 60px;
		/* width: 200px; */
		/* border: 1px solid red; */
	}

	.invoice .invoice-greeting p {
		margin: 3px;
	}
</style>
