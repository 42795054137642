<template>
  <!--mission & vision-->
  <div class="marketing">
    <div class="container-wrapper">
      <div class="content-header mb-5">
        <h1 class="title text-center">Important Information</h1>
      </div>
      <ul class="nav nav-tabs visible-phone">
        <li>
          <a href="#local" class="active" data-toggle="tab">Local</a>
        </li>
        <li>
          <a href="#inter" data-toggle="tab">International</a>
        </li>
        <li>
          <a href="#policy" data-toggle="tab">Policies, Acts & Laws</a>
        </li>
      </ul>
      <div class="row tab-content" v-if="market">
        <div
          class="col-lg-4 tab-pane active"
          id="local"
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-duration="1000"
        >
          <div class="card border border-dark" v-if="market">
            <div class="card-body">
              <h4>Local Market</h4>
              <img v-lazy="localMarketimage" alt="localMarketimage" />
              <ul>
                <li v-for="(market,index) in market.localMarket" :key="'A'+index">
                  <span v-if="market.title == 'Events'"></span>
                  <span v-else-if="market.title == 'Awards'"></span>
                  <a
                    v-else-if="market.id == 2"
                    href="http://nbr.gov.bd/nbr-enlisted/eng"
                    target="_blank"
                  >{{market.title}}</a>
                  <a
                    v-else-if="market.id == 3"
                    href="https://basisegovhub.info"
                    target="_blank"
                  >{{market.title}}</a>
                  <router-link
                    v-else
                    :to="{ name:'market_detail', params: { id: market.id }}"
                  >{{market.title}}</router-link>
                </li>
                <li>
                  <a
                    :href="$root.baseurl+'/public/files/publication/5e125f5ee61760449abd28a25178d046facfec994d97e.pdf'"
                    target="_blank"
                  >Software and IT Service Catalog</a>
                </li>
                <li v-for="(market,index) in market.localMarket" :key="'B'+index">
                  <router-link
                    v-if="market.title == 'Events'"
                    :to="{name:'allEvents', params:{market:'local',type:'events'}}"
                  >{{market.title}}</router-link>
                  <router-link
                    v-else-if="market.title == 'Awards'"
                    :to="{name:'allEvents', params:{market:'local',type:'awards'}}"
                  >{{market.title}}</router-link>
                </li>
              </ul>
              <router-link
                :to="{ name:'market', params: {type: 'LocalMarket' }}"
                class="btn btn-outline-primary"
              >View All</router-link>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 tab-pane"
          id="inter"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="1500"
        >
          <div class="card border border-dark" v-if="market">
            <div class="card-body">
              <h4>International Market</h4>
              <img v-lazy="international_Marketimage" alt="international_Marketimage" />
              <ul>
                <li v-for="(market,index) in market.international_Market" :key="index">
                  <router-link
                    v-if="market.title == 'Events'"
                    :to="{name:'allEvents', params:{market:'international',type:'events'}}"
                  >{{market.title}}</router-link>
                  <router-link
                    v-else-if="market.title == 'Awards'"
                    :to="{name:'allEvents', params:{market:'international',type:'awards'}}"
                  >{{market.title}}</router-link>
                  <router-link v-else-if="market.title == 'B2B'" to="/b2b">{{market.title}}</router-link>
                  <router-link
                    v-else
                    :to="{ name:'market_detail', params: { id: market.id }}"
                  >{{market.title}}</router-link>
                </li>
              </ul>
              <router-link
                :to="{ name:'market', params: {type: 'InternationalMarket' }}"
                class="btn btn-outline-primary"
              >View All</router-link>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 tab-pane"
          id="policy"
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-duration="2000"
        >
          <div class="card border border-dark">
            <div class="card-body">
              <h4>Policies, Acts & Laws</h4>
              <img v-lazy="policyimage" alt="policyimage" />
              <ul>
                <slot v-for="(policy,index2) in polices">
                  <li :key="'AB'+index2">
                    <a
                      :href="$root.baseurl+'/public'+policy.file"
                      target="_blank"
                    >{{ policy.title }}</a>
                  </li>
                </slot>
              </ul>
              <router-link
                :to="{ name:'policy', params: { type: 'Policies' }}"
                class="btn btn-outline-primary"
              >View All</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
      description: "Marketing"
    }
  },
  data() {
    return {
      localMarketimage: this.$root.baseurl + "/public/images/local-market.svg",
      international_Marketimage:
        this.$root.baseurl + "/public/images/international-market.svg",
      policyimage: this.$root.baseurl + "/public/images/policy.svg",
      polices: {},
      market: {}
    };
  },
  methods: {
    get_policy_home() {
      axios.get("/policy-home").then(respons => {
        this.polices = respons.data;
      });
    },
    getMarket() {
      axios.get("/get-market-home").then(respons => {
        this.market = respons.data;
      });
    }
  },
  mounted() {
    setTimeout(() => this.get_policy_home(), 220);
    setTimeout(() => this.getMarket(), 200);
  }
};
</script>
