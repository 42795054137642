<template>
  <div>
    <!-- canceled -->
    <div
      v-if="data.status == 2"
      class="cancel-overlay"
      style="
        width: 100%;
        height: 100%;
        background: white;
        z-index: 9999;
        position: absolute;
        top: 0;
        left: 0;
      "
    >
      <div>
        <img
          class="img-fluid"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 500px;
          "
          :src="$root.baseurl + '/public/images/icon/member-canceled.jpeg'"
          alt=""
        />
      </div>
    </div>

    <!-- cenceled end-->

    <!--company profile-->

    <div class="company-profile" v-if="data.status != 2">
      <div class="company-header">
        <div class="row m-0">
          <div class="col-lg-3 p-lg-0">
            <div class="sidebar">
              <img
                v-if="data.logo === '/images/logo/' || data.logo === null"
                v-lazy="
                  $root.baseurl + require('./../../assets/img/no_logo.gif')
                "
                alt
                class="logo"
              />
              <img
                v-else
                v-lazy="$root.baseurl + '/public' + data.logo"
                class="logo"
                alt
              />

              <div class="companyDetails">
                <big>{{ data.legal_structure }}</big>
                <h1>{{ data.company_name }}</h1>
                <p>{{ data.address }}</p>
              </div>
              <div class="companyHead">
                <h3>{{ data.company_head }}</h3>
                <small>{{ data.designation }}</small>
              </div>
              <div class="experience shadow-lg">
                <div class="experience-text">
                  <big>{{ expYear }}</big>
                  <small>Years of experience</small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9 p-lg-0">
            <div class="profile-banner">
              <!-- <slot v-if="data.status == 0">
                <div class="suspended">
                  <img
                    :src="
                      $root.baseurl + '/public/images/icon/suspended-final.png'
                    "
                    alt=""
                  />
                </div>
                <span class="shape"></span>
                <span class="shape2"></span>
              </slot> -->

              <div class="banner-img" v-if="data.cover_photo">
                <img
                  class="noPhoto"
                  v-lazy="$root.baseurl + '/public/' + `${data.cover_photo}`"
                />
              </div>
              <div class="banner-img">
                <span class="noPhoto"></span>
                <!-- <img v-lazy="$root.baseurl+'/public/images/company-banner.jpg'" alt /> -->
              </div>

              <div class="profile-banner-caption">
                <div class="social">
                  <ul class="nav">
                    <li class="nav-item">
                      <a :href="data.fb_url" target="_blank" class="nav-link">
                        <img
                          v-lazy="
                            $root.baseurl + '/public/images/icon/facebook.png'
                          "
                          alt
                        />
                      </a>
                    </li>
                    <li class="nav-item">
                      <a :href="data.tw_url" target="_blank" class="nav-link">
                        <img
                          v-lazy="
                            $root.baseurl + '/public/images/icon/twitter.png'
                          "
                          alt
                        />
                      </a>
                    </li>
                    <li class="nav-item">
                      <a :href="data.yt_url" target="_blank" class="nav-link">
                        <img
                          v-lazy="
                            $root.baseurl + '/public/images/icon/youtube.png'
                          "
                          alt
                        />
                      </a>
                    </li>
                    <li class="nav-item">
                      <a :href="data.le_url" target="_blank" class="nav-link">
                        <img
                          v-lazy="
                            $root.baseurl + '/public/images/icon/linkedin.png'
                          "
                          alt
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="member-details">
                  <router-link to="/">
                    <img
                      class="basis-logo"
                      v-lazy="$root.baseurl + '/public/images/logo.svg'"
                      alt="basis-logo"
                    />
                  </router-link>
                  <h1 style="margin-bottom: 0px">
                    <big>{{ data.membership_id }}</big>
                  </h1>
                  <small>({{ data.membership_type }} Member)</small>
                  <p v-if="data.date">
                    <b>Member Since : {{ data.date | formatDate }}</b>
                  </p>
                  <p
                    class="mb-2"
                    :class="validTillClass"
                    v-if="validTillClass == 'text-danger'"
                  >
                    <b
                      >Valid Till : {{ validTill | formatDate }}
                      <i
                        class="fa fa-circle blink"
                        style="color: red"
                        aria-hidden="true"
                      ></i>
                    </b>
                  </p>
                  <p
                    class="mb-2"
                    :class="validTillClass"
                    v-if="validTillClass == 'text-success'"
                  >
                    <b
                      >Valid Till : {{ validTill | formatDate }}
                      <i
                        class="fa fa-circle blink"
                        style="color: green"
                        aria-hidden="true"
                      ></i>
                    </b>
                  </p>

                  <a
                    v-if="this.data.profile"
                    :href="this.$root.baseurl + '/public' + data.profile"
                    target="_blank"
                    class="btn btn-sm btn-outline-explore"
                  >
                    <i class="fas fa-file-pdf"></i>View Profile
                  </a>
                  <a
                    v-else
                    onclick="alert('Sorry!! profile has not uploaded yet')"
                    class="btn btn-sm btn-outline-explore"
                  >
                    <i class="fas fa-file-pdf"></i>View Profile
                  </a>

                  <!-- <button
                    type="button"
                    @click="contactForm()"
                    class="btn btn-sm btn-explore"
                  >
                    Contact Now
                  </button> -->
                </div>
              </div>
            </div>
            <div class="banner-bottom p-5">
              <div class="row">
                <div class="col-lg-8 p-lg-0">
                  <div class="bref">
                    <h4 class="profile-title">Brief of company</h4>
                    <p v-if="data.short_profile">
                      {{ data.short_profile.substring(0, 500) }}
                    </p>
                    <button
                      v-if="data.short_profile"
                      @click="readMore()"
                      class="font-weight-bold btn-custom bg-transparent border-0"
                    >
                      [Read more]
                    </button>
                    <p class="text-danger mb-0" v-else>
                      Company brief hasn't uploaded yet
                    </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card card-box border-0 shadow-sm">
                    <div class="card-header">
                      <img
                        v-if="
                          data.representatives_primary &&
                          data.representatives_primary.image !== null &&
                          data.representatives_primary.image !== ''
                        "
                        class="rep-img"
                        v-lazy="
                          $root.baseurl +
                          '/public' +
                          data.representatives_primary.image
                        "
                        alt
                      />
                      <img
                        v-else
                        class="rep-img"
                        v-lazy="
                          $root.baseurl +
                          require('./../../assets/img/male_avatar.png')
                        "
                        alt
                      />
                    </div>
                    <div class="card-body pt-0">
                      <h4>Representative</h4>
                      <h1 v-if="data.representatives_primary">
                        {{ data.representatives_primary.name }}
                      </h1>
                      <p>
                        <strong>Designation:</strong>
                        <span v-if="data.representatives_primary">{{
                          data.representatives_primary.designation
                        }}</span>
                      </p>
                      <!-- <p
                        v-if="
                          data.representatives_primary &&
                          data.representatives_primary.hide_mobile != 1
                        "
                      >
                        <strong>Contact:</strong>
                        <span>{{ data.representatives_primary.mobile }}</span>
                      </p>
                      <p>
                        <strong>Email:</strong>
                        <span v-if="data.representatives_primary">{{
                          data.representatives_primary.email
                        }}</span>
                      </p> -->
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 my-4 member-details-sm">
                  <div class="member-details">
                    <router-link to="/">
                      <img
                        class="basis-logo"
                        v-lazy="$root.baseurl + '/public/images/logo.svg'"
                        alt="basis-logo"
                      />
                    </router-link>
                    <h1>
                      <big>{{ data.membership_id }}</big>
                    </h1>
                    <small>({{ data.membership_type }} Member)</small>
                    <p v-if="data.date">
                      <b>Member Since : {{ data.date | formatDate }}</b>
                    </p>
                    <p class="mb-2" :class="validTillClass">
                      <b>Valid Till : {{ validTill | formatDate }}</b>
                    </p>

                    <a
                      v-if="this.data.profile"
                      :href="this.$root.baseurl + '/public' + data.profile"
                      target="_blank"
                      class="btn btn-sm btn-outline-explore"
                    >
                      <i class="fas fa-file-pdf"></i>View Profile
                    </a>
                    <a
                      v-else
                      onclick="alert('Sorry!! profile has not uploaded yet')"
                      class="btn btn-sm btn-outline-explore"
                    >
                      <i class="fas fa-file-pdf"></i>View Profile
                    </a>

                    <!-- <button
											type="button"
											@click="contactForm()"
											class="btn btn-sm btn-explore"
										>
											Contact Now
										</button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company-body py-4">
        <div class="container-fluid">
          <div class="row mb-4">
            <div
              class="col-lg-3"
              v-if="data.services && Object.keys(data.services).length > 0"
            >
              <div class="services">
                <h4 class="profile-title">Our Services</h4>
                <ul class="nav flex-column">
                  <li
                    v-for="(serv, index) in data.services"
                    :key="index"
                    @click="getService(serv.id)"
                    class="nav-item"
                    style="cursor: pointer"
                  >
                    <a class="nav-link">{{ serv.service }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              :class="
                Object.keys(data.services).length > 0 ? 'col-lg-9' : 'col-lg-12'
              "
              v-if="data.products && Object.keys(data.products).length > 0"
            >
              <div class="products">
                <div class="title-header">
                  <div class="d-flex offset-md-1">
                    <h4 class="profile-title text-left">Our Products</h4>
                  </div>
                </div>
                <div class="row">
                  <div
                    v-for="(product, index) in data.products"
                    :key="index"
                    @click="getProduct(product.id)"
                    class="col-lg-4"
                  >
                    <div class="product-item">
                      <h5>{{ product.title }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="expertise">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-3 p-0">
                <div class="expertise-title card border-0">
                  <h4>Technical Tools</h4>
                </div>
              </div>
              <div class="col-lg-9 align-self-center">
                <div class="row p-3" v-if="data.member_language">
                  <div
                    class="col"
                    v-for="(lang, index) in data.member_language"
                    :key="index"
                  >
                    <h5>{{ lang.name }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="platform py-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-4">
                <h4 class="profile-title">Web Platform</h4>
                <ul
                  class="nav flex-column"
                  v-if="
                    data.platforms && Object.keys(data.platforms).length > 0
                  "
                >
                  <li
                    v-for="(platform, index) in data.platforms"
                    :key="index"
                    class="nav-item"
                  >
                    <a class="nav-link">{{ platform.name }}</a>
                  </li>
                </ul>
                <p class="text-danger mb-0" v-else>
                  You have no web platform yet
                </p>
              </div>
              <div class="col-lg-4">
                <h4 class="profile-title">Mobile Platform</h4>
                <ul
                  class="nav flex-column"
                  v-if="
                    data.mobileplatform &&
                    Object.keys(data.mobileplatform).length > 0
                  "
                >
                  <li
                    v-for="(mplatform, index) in data.mobileplatform"
                    :key="index"
                    class="nav-item"
                  >
                    <a class="nav-link">{{ mplatform.name }}</a>
                  </li>
                </ul>
                <p class="text-danger mb-0" v-else>
                  You have no mobile platform yet
                </p>
              </div>
              <div class="col-lg-4">
                <div class="title-header">
                  <h4 class="profile-title">IT ENABLED SERVICES</h4>
                </div>
                <div class="video-gallery">
                  <ul class="nav flex-column" v-if="data.categories">
                    <li
                      v-for="(cate, index) in data.categories"
                      :key="index"
                      class="nav-item"
                    >
                      <a class="nav-link">{{
                        extraData.categories[cate.category]
                          ? extraData.categories[cate.category]
                          : ""
                      }}</a>
                    </li>
                  </ul>
                  <p v-else class="text-danger text-center mb-0">
                    You have no it enabled services yet
                  </p>
                </div>
              </div>
              <!-- <div class="col-lg-4">
              <h4 class="profile-title">Partners</h4>

              <ul
                class="nav flex-column"
                v-if="data.partners && Object.keys(data.partners).length>0"
              >
                <li v-for="(partner, index) in data.partners" :key="index" class="nav-item">
                  <a class="nav-link">{{ partner.partner_name }}</a>
                </li>
              </ul>
              <p class="text-danger mb-0" v-else>You have no partners yet</p>
            </div>-->
            </div>
          </div>
        </div>
        <div
          class="industry-expertise py-5"
          v-if="
            data.industry_expertise &&
            Object.keys(data.industry_expertise).length > 0
          "
        >
          <div class="container-fluid">
            <h4 class="profile-title">Industry Expertise</h4>
            <hr />
            <div class="row">
              <div
                class="col-lg-3"
                v-if="
                  data.industry_expertise.clients1 &&
                  data.industry_expertise.industry1
                "
              >
                <div class="industry-expertise-item shadow-sm">
                  <span
                    class="bg-primary text-white"
                    v-if="data.industry_expertise.clients1"
                    >{{ data.industry_expertise.clients1 }}</span
                  >
                  <h5 v-if="data.industry_expertise.industry1">
                    {{
                      extraData.industryLists[data.industry_expertise.industry1]
                        ? extraData.industryLists[
                            data.industry_expertise.industry1
                          ]
                        : ""
                    }}
                  </h5>
                </div>
              </div>
              <div
                class="col-lg-3"
                v-if="
                  data.industry_expertise.clients2 &&
                  data.industry_expertise.industry2
                "
              >
                <div class="industry-expertise-item shadow-sm">
                  <span
                    class="bg-info text-white"
                    v-if="data.industry_expertise.clients2"
                    >{{ data.industry_expertise.clients2 }}</span
                  >
                  <h5 v-if="data.industry_expertise.industry2">
                    {{
                      extraData.industryLists[data.industry_expertise.industry2]
                        ? extraData.industryLists[
                            data.industry_expertise.industry2
                          ]
                        : ""
                    }}
                  </h5>
                </div>
              </div>
              <div
                class="col-lg-3"
                v-if="
                  data.industry_expertise.clients3 &&
                  data.industry_expertise.industry3
                "
              >
                <div class="industry-expertise-item shadow-sm">
                  <span
                    class="bg-success text-white"
                    v-if="data.industry_expertise.clients3"
                    >{{ data.industry_expertise.clients3 }}</span
                  >
                  <h5 v-if="data.industry_expertise.industry3">
                    {{
                      extraData.industryLists[data.industry_expertise.industry3]
                        ? extraData.industryLists[
                            data.industry_expertise.industry3
                          ]
                        : ""
                    }}
                  </h5>
                </div>
              </div>
              <div
                class="col-lg-3"
                v-if="
                  data.industry_expertise.clients4 &&
                  data.industry_expertise.industry4
                "
              >
                <div class="industry-expertise-item shadow-sm">
                  <span
                    class="bg-danger text-white"
                    v-if="data.industry_expertise.clients4"
                    >{{ data.industry_expertise.clients4 }}</span
                  >
                  <h5 v-if="data.industry_expertise.industry4">
                    {{
                      extraData.industryLists[data.industry_expertise.industry4]
                        ? extraData.industryLists[
                            data.industry_expertise.industry4
                          ]
                        : ""
                    }}
                  </h5>
                </div>
              </div>
              <div
                class="col-lg-3"
                v-if="
                  data.industry_expertise.clients5 &&
                  data.industry_expertise.industry5
                "
              >
                <div class="industry-expertise-item shadow-sm">
                  <span
                    class="bg-success text-white"
                    v-if="data.industry_expertise.clients5"
                    >{{ data.industry_expertise.clients5 }}</span
                  >
                  <h5 v-if="data.industry_expertise.industry5">
                    {{
                      extraData.industryLists[data.industry_expertise.industry5]
                        ? extraData.industryLists[
                            data.industry_expertise.industry5
                          ]
                        : ""
                    }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="gallery py-5">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="title-header">
                  <div class="d-flex justify-content-center">
                    <h4 class="profile-title">Photo Gallery</h4>
                  </div>
                </div>
                <div class="photo-gallery">
                  <div class="row m-0">
                    <div
                      class="col-lg-2 p-1"
                      v-for="(image, index) in images"
                      :key="index"
                    >
                      <div class="galleryPhoto">
                        <img
                          v-lazy="images[index].src"
                          @click="openGallery(index)"
                          alt="GALLERY PHOTO 1"
                        />
                      </div>
                    </div>
                    <LightBox
                      :images="images"
                      ref="lightbox"
                      :show-caption="true"
                      :show-light-box="false"
                      :showThumbs="false"
                    ></LightBox>
                    <!-- <div class="col-lg-4 p-1">
                    <div class="galleryPhoto">
                      <img v-lazy="images[1].src" @click="openGallery(1)" alt="GALLERY PHOTO 2" />
                    </div>
                  </div>
                  <div class="col-lg-4 p-1">
                    <div class="galleryPhoto">
                      <img v-lazy="images[2].src" @click="openGallery(2)" alt="GALLERY PHOTO 3" />
                    </div>
                  </div>
                  <div class="col-lg-6 p-1">
                    <div class="galleryPhoto">
                      <img v-lazy="images[3].src" @click="openGallery(3)" alt="GALLERY PHOTO 4" />
                    </div>
                  </div>
                  <div class="col-lg-6 p-1">
                    <div class="galleryPhoto">
                      <img v-lazy="images[4].src" @click="openGallery(4)" alt="GALLERY PHOTO 5" />
                    </div>
                  </div>-->
                  </div>
                  <LightBox
                    :images="images"
                    ref="lightbox"
                    :show-caption="true"
                    :show-light-box="false"
                    :showThumbs="false"
                  ></LightBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company-footer py-5">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-2 align-self-center text-center">
              <img
                v-if="data.logo === '/images/logo/' || data.logo === null"
                v-lazy="
                  $root.baseurl + require('./../../assets/img/no_logo.gif')
                "
                alt
                class="logo"
              />
              <img
                v-else
                v-lazy="$root.baseurl + '/public' + data.logo"
                class="logo"
                alt
              />
            </div>
            <div class="col-lg-4">
              <div class="footer-address">
                <h4 class="profile-title">Address</h4>
                <address>{{ data.address }}</address>
                <p class="mb-0">
                  <strong>Email:</strong>
                  {{ data.email }}
                </p>
                <p class="mb-0">
                  <strong>Phone:</strong>
                  {{ data.phone }}
                </p>
                <p class="mb-0">
                  <strong>Web:</strong>
                  <a
                    href="javascript:void(0)"
                    @click="redirectLink(data.website)"
                    class="text-info"
                    >{{ data.website }}</a
                  >
                </p>
              </div>
            </div>
            <div class="col-lg-4 profile-map">
              <h4 class="profile-title">Google Map</h4>
              <!-- <iframe
              v-if="data && data.map_url"
              :src="data.map_url"
              width="100%"
              height="140"
              frameborder="0"
              style="border:0;"
              allowfullscreen
            ></iframe>-->
              <div v-if="data && data.map_url" v-html="data.map_url"></div>
              <p class="text-danger text-danger mb-0" v-else>
                <b>Map has not been updated yet</b>
              </p>
            </div>
            <div class="col-lg-2 align-self-center text-center">
              <!-- <button
                type="button"
                @click="contactForm()"
                class="btn btn-outline-explore"
              >
                Contact Now
              </button> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Product -->
      <div class="custom-modal" v-bind:class="{ show: isActive }">
        <div class="container-fluid">
          <div class="custom-modal-body">
            <button
              type="button"
              class="btn btn-sm shadow-none"
              v-on:click="customModal"
            >
              <i class="mdi mdi-24px mdi-close"></i>
            </button>
            <!-- Modal product -->
            <modal-product
              :product="product"
              :categories="extraData.categories"
              v-if="productModal"
            ></modal-product>
            <!-- Modal service -->
            <modal-service
              :service="service"
              :industryLists="extraData.industryLists"
              v-if="serviceModal"
            ></modal-service>
            <!-- Modal contact-->
            <modal-contact :data="data" v-if="contactModal"></modal-contact>
            <!-- Modal read more-->
            <modal-read-more
              :data="data"
              v-if="readMoreModal"
            ></modal-read-more>
          </div>
        </div>
      </div>
      <!-- <pre>{{ extraData.industryLists }}</pre>
    <pre>{{ data.industry_expertise }}</pre>-->
    </div>
  </div>
  <!--//company profile-->
</template>
<script>
import LightBox from "vue-image-lightbox";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

const breadcumb = [
  {
    title: "Company Profile",
  },
];
import no_image from "../../assets/img/no_image.jpg";
import ModalReadMore from "../../components/elements/ModalReadMore";
import ModalContact from "../../components/elements/ModalContactForm";
import ModalProduct from "../../components/elements/ModalProduct";
import ModalService from "../../components/elements/ModalService";

export default {
  name: "CompanyProfile",
  components: {
    ModalService,
    ModalContact,
    ModalProduct,
    ModalReadMore,
    LightBox,
  },
  data() {
    return {
      isActive: false,
      productModal: false,
      serviceModal: false,
      contactModal: false,
      readMoreModal: false,
      breadcumb: breadcumb,
      breadcrumbTitle: "Company Profile",
      images: [
        { src: this.$root.baseurl + no_image },
        { src: this.$root.baseurl + no_image },
        { src: this.$root.baseurl + no_image },
        { src: this.$root.baseurl + no_image },
        { src: this.$root.baseurl + no_image },
        { src: this.$root.baseurl + no_image },
      ],
      data: [],
      expYear: "",
      validTill: "",
      validTillClass: "",
      service: {},
      product: {},
      extraData: {
        categories: [],
        industryLists: [],
      },
    };
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    customModal: function () {
      this.isActive = !this.isActive;
    },
    get_data() {
      axios
        .get("/get-company-profile/" + this.$route.params.slug)
        .then((response) => {
          this.data = response.data.member;
          this.expYear = response.data.expYear;
          this.validTill = response.data.valid_till;
          this.validTillClass = response.data.valid_till_class;
          this.getPhotosFiles(response.data.member.member_files);
        })
        .catch((error) => {
          console.log(error);
        })
        .then((res) => {
          setTimeout(
            () => (this.$root.loading = false),
            this.$root.minloadtimefd
          );
        });
    },
    getService: function (id) {
      axios.get("/getMemberServiceModal/" + id).then((response) => {
        if (Object.keys(response.data).length !== 0) {
          this.service = response.data;
          this.isActive = !this.isActive;
          this.readMoreModal = false;
          this.productModal = false;
          this.contactModal = false;
          this.serviceModal = true;
        }
      });
    },
    getProduct: function (id) {
      axios.get("/getMemberProductModal/" + id).then((response) => {
        if (Object.keys(response.data).length !== 0) {
          this.product = response.data;
          this.isActive = !this.isActive;
          this.readMoreModal = false;
          this.serviceModal = false;
          this.contactModal = false;
          this.productModal = true;
        }
      });
    },
    contactForm: function (id) {
      this.isActive = !this.isActive;
      this.readMoreModal = false;
      this.productModal = false;
      this.serviceModal = false;
      this.contactModal = true;
    },
    readMore: function () {
      this.isActive = !this.isActive;
      this.productModal = false;
      this.serviceModal = false;
      this.contactModal = false;
      this.readMoreModal = true;
    },
    getPhotosFiles: function (data) {
      if (data.company_photo1) {
        this.images[0].src =
          this.$root.baseurl + "/public" + data.company_photo1;
      }
      if (data.company_photo2) {
        this.images[1].src =
          this.$root.baseurl + "/public" + data.company_photo2;
      }
      if (data.company_photo3) {
        this.images[2].src =
          this.$root.baseurl + "/public" + data.company_photo3;
      }
      if (data.company_photo4) {
        this.images[3].src =
          this.$root.baseurl + "/public" + data.company_photo4;
      }
      if (data.company_photo5) {
        this.images[4].src =
          this.$root.baseurl + "/public" + data.company_photo5;
      }
      if (data.company_photo6) {
        this.images[5].src =
          this.$root.baseurl + "/public" + data.company_photo6;
      }
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    redirectLink(link) {
      var sp = link.split("://");
      if (sp[1]) {
        var url = link;
      } else {
        var url = "http://" + link;
      }
      window.open(url, "_blank");
    },
  },
  mounted() {
    this.get_data();
    // all Industry List
    axios.get("admin/getIndustryListPluck").then((response) => {
      this.extraData.industryLists = response.data;
    });
    // all Category
    axios.get("admin/getCategoryListPluck").then((response) => {
      this.extraData.categories = response.data;
    });
  },
};
</script>

<style>
.profile-map iframe {
  height: 180px !important;
  width: 402px !important;
}
.custom-modal-body button {
  float: right;
}
.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    color: greenyellow;
  }
}
</style>
