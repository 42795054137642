<template>
  <div class="member-services" v-if="!$root.spinner">
    <div
      v-if="invoice && Object.keys(invoice).length > 0"
      class="card shadow-sm mb-3"
    >
      <div class="card-header px-0 mt-1">
        <div class="justyfy text-center">
          <h5 class="px-3 mb-0">Payment Method</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="form-row col-12">
            <div class="col-md-6 mb-3">
              <div class="form-group mt-5 pt-4">
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="online_payment"
                    name="payment_method"
                    value="ssl"
                    v-model="invoice.payment_method"
                  />
                  <label class="custom-control-label" for="online_payment"
                    >SSL Commerz (Online payment)</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="CashOrPayorder"
                    name="payment_method"
                    value="pbl"
                    v-model="invoice.payment_method"
                  />
                  <label class="custom-control-label" for="CashOrPayorder"
                    >PRIME Bank</label
                  >
                </div>
              </div>
              <div class="mt-5 font-weight-bold">
                Your Payabale Amount :
                <slot>{{ total_amount | currency }}</slot>
              </div>
            </div>

            <div class="col-md-6 mb-3" style="border: 1px solid #eee">
              <div v-if="invoice.payment_method == 'pbl'">
                <h4 class="text-center pt-3">Payment with PRIME Bank</h4>
                <hr />
                <ul class="nav flex-column service-menu">
                  Please download your invoice and submit the invoice print copy
                  to any Prime Bank branch.
                  <br />
                  Following transactions method are available through Prime
                  Bank:
                  <li class="nav-link pb-0">
                    Cash deposit to any Prime Bank branch.
                  </li>
                  <li class="nav-link pb-0">
                    Pay order to
                    <b>"BASIS"</b> account
                  </li>
                  <li class="nav-link pb-0">
                    Submit check in favor of BASIS account <br />
                    (Account Number: <b>2113317014064</b>, Account Name -
                    <b>BASIS</b>)
                  </li>
                  <li class="nav-link pb-0">
                    Amount can be transferred from any Prime Bank account to
                    BASIS account. After transferring the money, please contact
                    with the BASIS office for confirmation.
                  </li>
                </ul>
              </div>
              <div v-else>
                <h4 class="text-center pt-3">Payment with SSL</h4>
                <hr />
                <ul class="nav flex-column service-menu">
                  <li class="nav-item">
                    Following transactions method is available through SSL
                    online payment gateway:
                  </li>
                  <li class="nav-link pb-0">
                    Visa Card (Local/International) : 2.00% of the Transaction
                    Amount.
                  </li>
                  <li class="nav-link pb-0">
                    Master Card (Local/International) : 2.00% of the Transaction
                    Amount.
                  </li>
                  <li class="nav-link pb-0">
                    American Express Card (Local/International) : 3.00% of the
                    Transaction Amount.
                  </li>
                  <li class="nav-link pb-0">
                    Internet Banking : 2.00% of the Transaction Amount.
                  </li>
                  <li class="nav-link pb-0">
                    City Touch : 2.50% of the Transaction Amount.
                  </li>
                  <li class="nav-link pb-0">
                    Mobile Banking : 2.00% of the Transaction Amount.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="invoice.payment_method === 'ssl'" class="form-row col-12">
            <button v-on:click="getPandingDueYear()" class="btn btn-success"> Pay Now </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col-12 text-center my-5">Sorry!! Invoice not found</div>

    <div
      v-if="invoice && invoice.payment_method == 'ssl'"
      class="form-row mt-3"
    >
      <img :src="method" />
    </div>
    <!-- <pre>{{data}}</pre> -->
  </div>
</template>
<script>
export default {
  name: "PaymentMethod",
  props: ["memberInfo"],
  data() {
    return {
      method: this.$root.baseurl + "/public/images/method.jpg",
      errors: {},
      invoice: {},
      total_amount: 0,
      member: {
        info: null,
      },
    };
  },
  methods: {
    getPandingDueYear: function () {
      this.scrollTop();
      this.$root.spinner = true;
      if (this.invoice.account_head_id != 2) {
        this.sslPayment();
        return false;
      }
      axios
        .post("/member/getPandingDueYear", {
          invoice_date: this.invoice.invoice_date,
          invoice_id: this.invoice.id,
        })
        .then((res) => {
          if (res.data.status) {
            this.$toastr("warning", res.data.message, "Sorry!!");
            this.$root.spinner = false;
          } else {
            this.sslPayment();
          }
        });
    },
    sslPayment: function () {
      axios
        .post("/ssl-payment", {
          type: "member",
          id: this.memberInfo.id,
          invoice_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.GatewayPageURL) {
            window.location.href = res.data.GatewayPageURL;
          }
        });
    },
    getInvoice: function () {
      this.$root.spinner = true;
      axios
        .get("/member/get-invoice/" + this.$route.params.id)
        .then((res) => {
          if (Object.keys(res.data).length > 0) {
            this.invoice = res.data.invoice;
            this.total_amount = res.data.invoice.amount;
          }
        })
        .catch((error) => console.log(error))
        .then((alw) => (this.$root.spinner = false));
    },
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  created() {
    this.loggedIn();
    this.getInvoice();
  },
};
</script>

