<template>
  <div class="member-services" v-if="!$parent.loading">
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">Change Password</h5>
        </div>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="submit" id="form">
          <div class="row">
            <div class="col-lg-6 mb-3">
              <div class="form-group">
                <label class="col-form-label-sm font-weight-bold mb-0" for="applicant_id">
                  Old Password
                  <!-- (
                  <small
                    class="text-danger"
                  >Please type slowly for checking old password</small> )-->
                </label>
                <input
                  autocomplete="off"
                  v-model="data.old_password"
                  type="password"
                  class="form-control form-control-sm"
                  :class="{ 'is-invalid': validation.hasError('data.old_password'),'is-valid': data.old_password }"
                  placeholder="Write your old password"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'Write your old password'"
                />
                <div class="invalid-feedback">{{ validation.firstError('data.old_password') }}</div>
              </div>
              <slot>
                <div class="form-group">
                  <label
                    class="col-form-label-sm font-weight-bold mb-0"
                    for="applicant_id"
                  >New Password</label>
                  <input
                    autocomplete="off"
                    v-model="data.new_password"
                    type="password"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': validation.hasError('data.new_password'),'is-valid': data.new_password }"
                    placeholder="e.g. ie: A-Z, a-z,digit(0-9),special character"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. ie: A-Z, a-z,digit(0-9),special character'"
                  />
                  <div class="invalid-feedback">{{ validation.firstError('data.new_password') }}</div>
                </div>
                <div class="form-group">
                  <label
                    class="col-form-label-sm font-weight-bold mb-0"
                    for="applicant_id"
                  >Confirm Password</label>
                  <input
                    autocomplete="off"
                    v-model="data.confirm_password"
                    type="password"
                    v-on:keyup.enter="submit"
                    class="form-control form-control-sm"
                    :class="{ 'is-invalid': validation.hasError('data.confirm_password'),'is-valid':data.confirm_password }"
                    placeholder="e.g. ie: A-Z, a-z,digit(0-9),special character"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. ie: A-Z, a-z,digit(0-9),special character'"
                  />
                  <div class="invalid-feedback">{{ validation.firstError('data.confirm_password') }}</div>
                </div>
              </slot>
            </div>
          </div>

          <br />
          <button class="btn btn-success" type="submit">Change Password</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
// ===============Promise===============
import Promise from "bluebird";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
const breadcumb = [
  {
    title: "Change Password"
  }
];
export default {
  name: "ChangePassword",
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Change Password",
      pass_verify: false,
      data: { new_password: null, confirm_password: null },
      member: {
        info: null
      },
      errors: {}
    };
  },
  methods: {
    submit: function() {
      this.$validate().then(res => {
        if (res) {
          this.$parent.loading = true;
          this.data.alt_login = this.$root.variable.alt_login;
          axios
            .post("member/password-change", this.data)
            .then(response => {
              this.data = {};
              this.$toastr(
                "success",
                "Change Password Successfully",
                "Success!!"
              );
              this.logoutMember();
              this.$parent.loading = false;
            })
            .catch(error => {
              console.log(error);
            })
            .then(error => {
              this.$parent.loading = false;
            });
        }
      });
    }
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  mounted() {
    this.loggedIn();
    setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefd);
  },
  validators: {
    "data.old_password": function(value = null) {
      var app = this;
      return Validator.value(value)
        .required("Old password is required")
        .minLength(6)
        .custom(function() {
          if (!Validator.isEmpty(value)) {
            app.data.alt_login = app.$root.variable.alt_login;
            axios.post("/member/check-old-password", app.data).then(res => {
              if (res.data) {
                app.pass_verify = true;
              } else {
                app.pass_verify = false;
                return "Old password do not match our records!!";
              }
            });
            return Promise.delay(2000).then(function() {
              if (!app.pass_verify) {
                return "Old password do not match our records!!";
              }
            });
          }
        });
    },
    "data.new_password": function(value = null) {
      return Validator.value(value)
        .required("New Password is required")
        .regex("(?=.*?[A-Z])", "at least one uppercase letter required")
        .regex("(?=.*?[a-z])", "at least one lowercase letter required")
        .regex("(?=.*?[0-9])", "at least one digit required")
        .regex("(?=.*?[#?!@$%^&*-])", "at least one special charater required")
        .minLength(6);
    },
    "data.confirm_password, data.new_password": function(
      confirm_password,
      new_password
    ) {
      return Validator.value(confirm_password)
        .required("Password confirmation is required")
        .match(new_password);
    }
  }
};
</script>

