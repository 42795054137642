var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"example-content"}},[_c('div',{staticClass:"servicePage"},[_c('h5',{staticClass:"page-title font-weight-bold text-uppercase"},[_vm._v("\n      "+_vm._s(_vm.breadcrumbTitle)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"service-tab"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"service-item tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"paid","role":"tabpanel","aria-labelledby":"paid-tab"}},[_c('div',{staticClass:"service-item py-4",class:_vm.$route.params.type == 'member' ? 'member' : 'nonmember'},[(_vm.data.paidService && !_vm.$root.spinner)?_c('div',{staticClass:"row"},[_vm._l((_vm.data.paidService),function(service,index){return _vm._t("default",function(){return [_c('div',{key:index,staticClass:"col-lg-4"},[(service.external_page == 0)?_c('router-link',{attrs:{"to":{
                    name: 'service_detail',
                    params: { id: service.id, type: _vm.$route.params.type },
                  }}},[_c('div',{staticClass:"card card-body shadow-sm"},[_c('div',{staticClass:"iconbox"},[_c('span',{domProps:{"innerHTML":_vm._s(service.icon)}})]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(service.title))])])]):_c('router-link',{attrs:{"to":'/' +
                    service.form_link +
                    '/' +
                    service.id +
                    '/' +
                    _vm.$route.params.type}},[_c('div',{staticClass:"card card-body shadow-sm"},[_c('div',{staticClass:"iconbox"},[_c('span',{domProps:{"innerHTML":_vm._s(service.icon)}})]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(service.title))])])])],1),_vm._v(" "),(index % 3 == 2)?_c('div',{key:'a' + index,staticClass:"w-100 mb-4"}):_vm._e()]})})],2):_vm._e(),_vm._v(" "),(_vm.$root.spinner)?_c('spinner'):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"free","role":"tabpanel","aria-labelledby":"free-tab"}},[_c('div',{staticClass:"service-item py-4",class:_vm.$route.params.type == 'member' ? 'member' : 'nonmember'},[(_vm.data.freeService && !_vm.$root.spinner)?_c('div',{staticClass:"row"},[_vm._l((_vm.data.freeService),function(service,index){return _vm._t("default",function(){return [_c('div',{key:index,staticClass:"col-lg-4"},[(service.external_page == 0)?_c('router-link',{attrs:{"to":{
                    name: 'service_detail',
                    params: { id: service.id, type: _vm.$route.params.type },
                  }}},[_c('div',{staticClass:"card card-body shadow-sm"},[_c('div',{staticClass:"iconbox"},[_c('span',{domProps:{"innerHTML":_vm._s(service.icon)}})]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(service.title))])])]):_c('router-link',{attrs:{"to":'/' +
                    service.form_link +
                    '/' +
                    service.id +
                    '/' +
                    _vm.$route.params.type}},[_c('div',{staticClass:"card card-body shadow-sm"},[_c('div',{staticClass:"iconbox"},[_c('span',{domProps:{"innerHTML":_vm._s(service.icon)}})]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(service.title))])])])],1),_vm._v(" "),(index % 3 == 2)?_c('div',{key:'a' + index,staticClass:"w-100 mb-4"}):_vm._e()]})})],2):_vm._e(),_vm._v(" "),(_vm.$root.spinner)?_c('spinner'):_vm._e()],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-header mb-5"},[_c('ul',{staticClass:"nav nav-tabs justify-content-center",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"paid-tab","data-toggle":"tab","href":"#paid","role":"tab","aria-controls":"paid","aria-selected":"true"}},[_vm._v("Paid Service")])]),_vm._v(" "),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"free-tab","data-toggle":"tab","href":"#free","role":"tab","aria-controls":"free","aria-selected":"false"}},[_vm._v("Free Service")])])])])
}]

export { render, staticRenderFns }