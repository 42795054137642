<template>
	<div id="example-content">
		<slot v-if="!$root.spinner">
			<div class="row">
				<slot v-if="event.image">
					<div class="col-xs-12 col-lg-5">
						<img
							v-lazy="event.FullUrl + event.image"
							class="img-fluid border mb-3"
							:alt="event.title"
						/>
					</div>
					<div
						v-if="event.location"
						class="col-xs-12 col-lg-7"
						data-aos="fade-right"
					>
						<!--Heading-->
						<h5 class="font-weight-bold text-uppercase">
							{{ event.title }}
						</h5>
						<p>
							<span class="location">
								<i class="mdi mdi-map-marker-outline pr-1"></i>
								{{ event.location }}
							</span>
						</p>
						<p class="col-lg-12 pl-0">
							<span class="date text-danger">
								<i class="mdi mdi-calendar-month pr-1"></i>
								{{ event.start_date | formatDate("Do MMM") }} to
								{{ event.end_date | formatDate("Do MMM YYYY") }}
							</span>
						</p>
					</div>
					<div class="col-lg-12">
						<!--Text-->
						<p class="text-justify" v-html="event.description"></p>
					</div>
				</slot>
				<slot v-else>
					<div
						class="col-xs-12 col-lg-12 row"
						data-aos="fade-right"
						v-if="event.title"
					>
						<h5 class="font-weight-bold text-uppercase">
							{{ event.title }}
						</h5>
						<p class="col-lg-12 pl-0">
							<span class="location">
								<i class="mdi mdi-map-marker-outline pr-1"></i>
								{{ event.location }}
							</span>
						</p>
						<p class="col-lg-12 pl-0">
							<span class="date text-danger">
								<i class="mdi mdi-calendar-month pr-1"></i>
								{{ event.start_date | formatDate("Do MMM") }} to
								{{ event.end_date | formatDate("Do MMM YYYY") }}
							</span>
						</p>
						<div class="col-lg-12">
							<p
								class="text-justify"
								v-html="event.description"
							></p>
						</div>
					</div>
				</slot>
			</div>

			<!-- button -->
			<div
				class="row py-3"
				v-if="
					event.is_registred == 'yes' &&
					event.events_payment == 'paid'
				"
			>
				<div class="col-lg-12">
					<button
						class="btn btn-success"
						@click="clickToCheckLogin(event.url, event.slug)"
					>
						Submit Form
					</button>
				</div>
			</div>
			<!-- default from -->
			<div
				class="row py-3"
				v-if="event.default_form && event.events_payment == 'paid'"
			>
				<div class="col-lg-12">
					<button
						class="btn btn-success"
						@click="
							clickToCheckLogin('default-event-form', event.slug)
						"
					>
						Get It Now!
					</button>
				</div>
			</div>
			<!-- /button -->
		</slot>
		<spinner v-if="$root.spinner" />
	</div>
</template>

<script>
	export default {
		name: "ViewEvent",
		data() {
			return {
				event: {},
				breadcrumbTitle: "",
				breadcumb: []
			};
		},
		methods: {
			asyncData() {
				this.get_data();
				this.$root.loading = false;
			},
			get_data() {
				this.$root.spinner = true;
				axios
					.get("/get-event-details/" + this.$route.params.slug)
					.then(respons => {
						this.event = respons.data.details;
						this.breadcrumbTitle = respons.data.details.title;
						this.breadcumb = [
							{
								title: respons.data.details.event_awards
							},
							{ title: this.breadcrumbTitle }
						];
						this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
						this.$root.spinner = false;
					})
					.catch(error => {
						console.log(error);
					});
			},
			getEvent(market, type) {
				window.location.href =
					this.$root.baseurl + "/ea/" + market + "/events";
			}
		},
		mounted() {},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		watch: {
			$route: {
				handler: "asyncData",
				immediate: true
			}
		}
	};
</script>