<template>
	<div
		class="member-services"
		v-if="!$parent.loading && member.info && member.info.status == 1"
	>
		<div class="card shadow-sm mb-3">
			<div class="justyfy text-center">
				<img
					v-lazy="ebl"
					alt="mtb-bank-visa-credit-card"
					style="height: 180px"
				/>
			</div>
			<div class="card-header px-0 mt-1">
				<div class="justyfy text-center">
					<h5 class="px-3 mb-0">
						BASIS-MTB Bank Visa Credit Card Recommendation Form
					</h5>
				</div>
			</div>
			<div class="card-body">
				<div class="alert alert-danger" role="alert" v-if="!showServiceForm">
					<h4 class="alert-heading">Warning!</h4>
					<p>This service is not availabe</p>
					<hr />
				</div>
				<form v-on:submit.prevent="submit" id="form" v-if="showServiceForm">
					<div class="row">
						<div class="col-lg-6">
							<div class="form-row mb-3">
								<label for="comments">
									Company Name
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									v-if="memberInfo"
									v-model="memberInfo.company_name"
									class="form-control"
									readonly
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("memberInfo.company_name") }}
								</div>
							</div>
							<div class="form-row mb-3">
								<label for="comments">
									Card Holder Name
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									v-model="data.applicant_name"
									class="form-control"
									id="formGroup"
									placeholder="Eg: John Doe"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'Eg: John Doe'"
									:class="{
										'is-invalid': validation.hasError('data.applicant_name'),
										'is-valid': data.applicant_name,
									}"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.applicant_name") }}
								</div>
							</div>
							<div class="form-row mb-3">
								<div class="col-md-12 px-0">
									<label for="comments">
										Email
										<span class="text-danger requierd-star"></span>
									</label>
									<input
										type="text"
										v-model="data.email"
										class="form-control"
										id="inputemail"
										placeholder="example@domain.com"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'Eg: example@domain.com'"
										:class="{
											'is-invalid': validation.hasError('data.email'),
											'is-valid': data.email,
										}"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.email") }}
									</div>
								</div>
							</div>
							<div class="form-row mb-3">
								<label for="comments">
									Business Nature
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									v-model="data.business_sector"
									class="form-control"
									id="inputBusiness"
									placeholder="Nature Of Business"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'Nature Of Business'"
									:class="{
										'is-invalid': validation.hasError('data.business_sector'),
										'is-valid': data.business_sector,
									}"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.business_sector") }}
								</div>
							</div>
						</div>
						<!-- right side -->
						<div class="col-lg-6">
							<div class="form-row mb-3">
								<label for="comments">
									Membership ID
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									v-if="memberInfo"
									v-model="memberInfo.membership_no"
									class="form-control"
									id="inputmembershipid"
									readonly
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("memberInfo.member_id") }}
								</div>
							</div>
							<div class="form-row mb-3">
								<label for="comments">Designation</label>
								<span class="text-danger requierd-star"></span>
								<input
									type="text"
									v-model="data.designation_representative"
									class="form-control"
									id="inputdesignation"
									placeholder="Designation"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'Designation'"
									:class="{
										'is-invalid': validation.hasError(
											'data.designation_representative'
										),
										'is-valid': data.designation_representative,
									}"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.designation_representative") }}
								</div>
							</div>
							<div class="form-row mb-3">
								<div class="col-md-6 px-0">
									<label for="comments">
										Mobile No
										<span class="text-danger requierd-star"></span>
									</label>
									<input
										type="text"
										v-model="data.mobile"
										class="form-control"
										id="inputmobile"
										placeholder="Mobile No"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'Mobile No'"
										:class="{
											'is-invalid': validation.hasError('data.mobile'),
											'is-valid': data.mobile,
										}"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.mobile") }}
									</div>
								</div>
								<div class="col-md-6 px-0 pl-1">
									<label for="comments">
										Phone No
										<!-- <span class="text-danger requierd-star"></span> -->
									</label>
									<input
										type="text"
										v-model="data.phone"
										class="form-control"
										id="inputphone"
										placeholder="Phone No"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'Phone No'"
										:class="{
											'is-invalid': validation.hasError('data.phone'),
											'is-valid': data.phone,
										}"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.phone") }}
									</div>
								</div>
							</div>
							<div class="form-row mb-3">
								<label for="comments">Address</label>
								<span class="text-danger requierd-star"></span>
								<input
									type="text"
									v-model="data.permanent_address"
									class="form-control"
									id="inputadress"
									placeholder="Eg: Street, City(town), State(area), Zip Code"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'Eg: Street, City(town), State(area), Zip Code'"
									:class="{
										'is-invalid': validation.hasError('data.permanent_address'),
										'is-valid': data.permanent_address,
									}"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.permanent_address") }}
								</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col-md-12">
							<div class>
								<label for="check">Specific busniess purpose of payment</label>
								<span class="text-danger requierd-star"></span>
							</div>
						</div>
						<div class="col-md-6">
							<b-form-checkbox
								class="col-form-label-sm font-weight-bold mb-0"
								name="checkbox-1"
								:value="'International Alliance / Software Regestration Fee'"
								:unchecked-value="true"
								v-model="data.business_purpose_payment1"
							>
								International Alliance / Software Regestration Fee
							</b-form-checkbox>
						</div>
						<div class="col-md-6">
							<b-form-checkbox
								class="col-form-label-sm font-weight-bold mb-0"
								name="checkbox-1"
								:value="'Domain Registration / Hosting Fee'"
								:unchecked-value="true"
								v-model="data.business_purpose_payment2"
								>Domain Registration / Hosting Fee</b-form-checkbox
							>
						</div>
						<div class="col-md-6">
							<b-form-checkbox
								class="col-form-label-sm font-weight-bold mb-0"
								name="checkbox-1"
								:value="'Service Maintenance Fee'"
								:unchecked-value="true"
								v-model="data.business_purpose_payment3"
								>Service Maintenance Fee</b-form-checkbox
							>
						</div>
						<div class="col-md-6">
							<b-form-checkbox
								class="col-form-label-sm font-weight-bold mb-0"
								name="checkbox-1"
								:value="'Account verification / Remettance Fee'"
								:unchecked-value="true"
								v-model="data.business_purpose_payment4"
								>Account verification / Remettance Fee</b-form-checkbox
							>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<b-form-checkbox
									class="col-form-label-sm font-weight-bold mb-0"
									v-model="data.is_other"
									name="is_other"
									:value="1"
									:unchecked-value="0"
									>Other</b-form-checkbox
								>
							</div>
						</div>
						<div
							class="form-group col-5"
							v-if="data.is_other || data.extra_reason"
						>
							<div class="form-group">
								<input
									type="text"
									name="extra_reason"
									v-model="data.extra_reason"
									placeholder="Other business purpose of payment"
									class="form-control form-control-sm"
								/>
							</div>
						</div>
					</div>
					<div class="row mt-5">
						<div class="col-md-12 mb-3">
							<label for="declaration">Declaration</label>
							<span class="text-danger requierd-star"></span>
							<div class="form-group">
								<b-form-checkbox
									class="col-form-label-sm font-weight-bold mb-0"
									name="checkbox-1"
									:value="true"
									:unchecked-value="false"
									v-model="declaration"
								>
									I Confirm that information given avobe is complete and i agree
									to comply with the terms and conditions BASIS-MTB Bank Visa
									Credit Card with the existing charges
								</b-form-checkbox>
								<div class="invalid-feedback">
									{{ validation.firstError("declaration") }}
								</div>
							</div>
						</div>
					</div>
					<br />
					<button class="btn btn-success text-center" type="submit">
						Next
					</button>
				</form>
			</div>
		</div>
		<!-- <pre>{{data}}</pre> -->
	</div>
	<div v-else-if="member.info && member.info.status == 0" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">
						Sorry!! your account is suspend
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Vue from "vue";
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	import eblImg from "../../../../../assets/img/mtb_visa_card.jpeg";
	export default {
		name: "MtbVisaCreditCardForm",
		props: ["memberInfo"],
		data() {
			return {
				ebl: this.$root.baseurl + eblImg,
				declaration: false,
				showServiceForm: false,
				services: {},
				data: {},
				errors: {},
				member: {
					info: null,
				},
			};
			wsa;
		},
		methods: {
			submit: function () {
				this.data.form_type = this.$route.name;
				this.$validate().then((res) => {
					if (this.validation.countErrors() > 0) {
						this.$toastr(
							"warning",
							'You need to fill <span class="font-weight-bold">' +
								this.validation.countErrors() +
								" more empty</span> mandatory fields",
							"Empty Mandatory Fields!!"
						);
					} else {
						if (res) {
							this.$parent.loading = true;
							axios
								.post("/member/store-company-info", this.data)
								.then((response) => {
									this.$toastr(
										"success",
										"Your information has been submitted, please payment",
										"Success!!"
									);
									if (response.data) {
										localStorage.setItem("recp_id", response.data.recp_id);
										this.$router.push({
											name: "paymentMethod",
											params: {
												id: this.data.service_id,
												amount: response.data.amount,
											},
										});
									}
								})
								.catch((error) => {
									console.log(error);
								})
								.then((alw) => {
									this.$parent.loading = false;
								});
						}
					}
				});
			},
			getServiceInfo() {
				axios
					.get("/member/get-serviceinfo/" + this.$route.params.id)
					.then((res) => {
						this.showServiceForm = res.data;
					});
			},
		},
		created() {
			this.loggedIn();
			setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);
			this.data.service_id = this.$route.params.id;
			this.getServiceInfo();
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
		validators: {
			"memberInfo.company_name": function (value = null) {
				return Validator.value(value).required("Company name is required");
			},
			"data.applicant_name": function (value = null) {
				return Validator.value(value).required("Card Holder name is required");
			},

			"data.email": function (value = null) {
				return Validator.value(value)
					.required("E-mail is required")
					.email("Input Must be an email");
			},
			"data.business_sector": function (value = null) {
				return Validator.value(value).required("Business Nature is required");
			},
			"data.permanent_address": function (value = null) {
				return Validator.value(value).required("Address is required");
			},

			"memberInfo.membership_no": function (value = null) {
				return Validator.value(value).required("Member ID is required");
			},
			"data.designation_representative": function (value = null) {
				return Validator.value(value).required("Designation is required");
			},
			"data.mobile": function (value = null) {
				return Validator.value(value)
					.required("Mobile Number is required")
					.digit()
					.regex("01+[0-9+-]*$", "Must start with 01.")
					.minLength(11)
					.maxLength(15);
			},
			"data.phone": function (value = null) {
				// return Validator.value(value)
				//   .required("Phone Number is requird")
				//   .digit()
				//   .maxLength(15);
			},

			declaration: function (value = null) {
				return Validator.value(value)
					.required("Confirmation is required")
					.custom(function () {
						if (value == false) {
							return "Confirmation is required";
						}
					});
			},
		},
	};
</script>

