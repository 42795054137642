<template>
	<!--main-->
	<main class="main">
		<!--company dashboard-->
		<div class="company-dashboard py-4">
			<div class="container-fluid">
				<div class="row">
					<!--===== left side start =====-->
					<div class="col-lg-3 col-xs-12">
						<left-side :memberInfo="member.info"></left-side>
					</div>
					<!--===== left side end =====-->

					<!--===== other page start =====-->
					<div class="col-lg-9 col-xs-12 position-relative">
						<Loading v-if="loading" />
						<spinner v-if="$root.spinner" />
						<router-view :memberInfo="member.info"></router-view>
					</div>
					<!--===== other page end =====-->
				</div>
			</div>
		</div>
		<Feedback v-if="display" />
		<!--//company dashboard-->
	</main>
	<!--//main-->
</template>
<script>
	import Feedback from "../../../components/elements/Feedback";
	import leftSide from "../../../components/Frontend/partials/member/LeftSide";
	import Loading from "./../../../components/LoaderMember";
	export default {
		name: "Layout",
		components: {
			leftSide,
			Loading,
			Feedback
		},
		data() {
			return {
				data: {},
				loading: true,
				feedback: {},
				display: false,
				member: {
					info: null
				},
				errors: {}
			};
		},
		methods: {},
		mounted() {
			// this.memberLoggedIn("get");
			this.loggedIn("get");

			setTimeout(() => {
				axios.get("/member/check-feedback").then(res => {
					this.feedback = res.data;
					if (Object.keys(res.data).length > 0) {
						if (!this.$root.variable.aLogId) {
							this.display = true;
						}
					}
				});
			}, 2000);
			setTimeout(
				() => (this.$root.loading = false),
				this.$root.minloadtimefd
			);
		}
	};
</script>
