<template>
  <div class="member-invoice" v-if="!$root.spinner">
    <slot v-if="account_info.account">
      <div class="col-12 form-row m-4 justify-content-center">
        <div class="col-6 col-lg-2 text-center">
          <button @click="downloadInvoice()" class="btn btn-info">
            <i class="fa fa-download"></i> Download
          </button>
        </div>
        <div
          v-if="account_info.account.status == 'success'"
          class="col-6 col-lg-3 text-center"
        >
          <button
            @click="moneyReceipt(account_info.account.id)"
            class="btn btn-success ml-4"
          >
            <i class="fa fa-eye"></i> Money Receipt
          </button>
        </div>
        <div
          class="col-6 col-lg-2 text-center"
          v-if="
            account_info.account.payment_method == 'ssl' &&
            account_info.account.status != 'success'
          "
        >
          <!-- <button
            class="btn btn-success"
            type="button"
            @click="sslPayment(account_info.account.id)"
          >
            Pay Now
          </button> -->

          <router-link
            class="btn btn-success"
            :to="{
              name: 'reg-member.paymentOption',
              params: { id: account_info.account.id },
            }"
            >Check Out</router-link
          >
        </div>
      </div>
      <!-- Invoice -->
      <div class="invoice-frontend border border-dark">
        <img
          v-lazy="$root.baseurl + '/public/images/BASIS_Letterhead.jpg'"
          width="100%"
          height="100%"
          alt="BASIS_Letterhead"
        />
        <div
          class="invoice-content"
          style="
            position: absolute;
            top: 0;
            left: 0;
            padding: 80px 25px;
            width: 100%;
          "
        >
          <table
            v-if="account_info"
            class="invoice-frontend-table"
            width="100%"
            border="0"
            cellspacing="0"
            cellpadding="0"
          >
            <tr>
              <td>
                Ref:
                <span>{{ data.business_activity.applying_for }} Member</span>
              </td>
              <td class="text-right">
                Date:
                <span>
                  {{
                    account_info.account.invoice_date
                      | formatDate("MMMM, DD YYYY")
                  }}
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <p>
                  Invoice No:
                  <span>
                    BASIS-{{
                      account_info.account.invoice_date
                        | formatDate("DDMMYYYY")
                    }}-{{ account_info.account.invoice_number }}
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <p class="mb-2">To</p>
                <p class="mb-2" v-if="data.company_info">
                  {{ data.company_info.company_name }}
                </p>
                <p class="mb-2" v-if="data.organization_head">
                  Attn:
                  <b>
                    <span>
                      {{ data.representative.name }},
                      {{ data.representative.designation }}
                    </span>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <table width="100%">
                  <tr>
                    <td>
                      <h3
                        style="
                          border: 1px solid;
                          width: 30%;
                          text-align: center;
                          border-radius: 4px;
                          padding: 4px 0px;
                          margin: 10px auto;
                        "
                      >
                        Invoice
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table
                        class="table"
                        width="100%"
                        border="1"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <thead>
                          <tr>
                            <th class="text-center">SL No.</th>
                            <th class="text-center">Description</th>
                            <th class="text-center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">01</td>
                            <td class="text-center">
                              {{ account_info.accountHead.name }}
                            </td>
                            <td class="text-center">
                              {{ account_info.account.amount | currency }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right" colspan="2">
                              <b>Total=</b>
                            </td>
                            <td class="text-center">
                              <b>{{ total_amount | currency }}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-if="account_info.account.amount"
                      class="text-left"
                      colspan="3"
                    >
                      Inword:
                      <b>
                        <span style="text-transform: capitalize"
                          >{{ Math.round(total_amount) | inWords }} Only</span
                        >
                      </b>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <p class="text-justify mb-3">
                  Please make payment in favor of <b>"BASIS"</b> by Cash Deposit
                  or Pay Order or Cheque directly to Prime Bank Limited, Kawran
                  Bazar branch <b>(Account No- "2113317014064")</b> please take
                  2 (two) copies of invoice to the bank and after making
                  successful payment, you are requested to send the scanned copy
                  of the same with bank seal to <b>payment@basis.org.bd</b> for
                  updating at BASIS end
                  <br />
                  <br />
                  OR
                  <br />
                  <br />
                  You may also pay the fee using the online payment gateway of
                  BASIS.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <br />
                <br />
                <br />
                <p class="mt-2">Thanks & Regards</p>
                <!-- <img v-lazy="sign" height="50px" width="100px" alt="Hashim Ahmed" />
                <p class="mb-0">Hashim Ahmed</p>
                <p class="mb-0">Secretary</p>
                <p class="mb-0">BASIS</p>-->
              </td>
            </tr>
          </table>
          <br />
          <br />
          <br />
          <p style="font-style: italic">
            "This is an electronically generated letter or invoice, no signature
            is required"
          </p>
        </div>
      </div>
      <!-- Invoice -->
      <div class="col-12 form-row m-4 justify-content-center">
        <div class="col-6 col-lg-2 text-center">
          <button @click="downloadInvoice()" class="btn btn-info">
            <i class="fa fa-download"></i> Download
          </button>
        </div>
        <div
          v-if="account_info.account.status == 'success'"
          class="col-6 col-lg-3 text-center"
        >
          <button
            @click="moneyReceipt(account_info.account.id)"
            class="btn btn-success ml-4"
          >
            <i class="fa fa-eye"></i> Money Receipt
          </button>
        </div>
        <div
          class="col-6 col-lg-2 text-center"
          v-if="
            account_info.account.payment_method == 'ssl' &&
            account_info.account.status != 'success'
          "
        >
          <router-link
            class="btn btn-success"
            :to="{
              name: 'reg-member.paymentOption',
              params: { id: account_info.account.id },
            }"
            >Check Out</router-link
          >
        </div>
      </div>
    </slot>
    <slot v-else>
      <h6 class="text-center">
        Please complete
        <b>STEP 5</b>
      </h6>
      <div class="form-group col-12 mt-5">
        <div class="text-center">
          <button @click="payment" class="btn btn-info">
            ৳ Proceed to Payment
          </button>
        </div>
      </div>
    </slot>
    <!-- <pre>{{account_info}}</pre> -->
  </div>
</template>
<script>
import sign from "../../../../assets/img/sign/HashimAhmed.png";
import Promise from "bluebird";
export default {
  name: "Invoice",
  data() {
    return {
      title: "Invoice",
      sign: this.$root.baseurl + sign,
      invoiceNo: null,
      payment_type: {},
      account_info: {
        account: {},
        accountHead: {},
        member: {},
      },
      data: {
        company_info: { district: null },
        organization_head: {},
        representative: {},
        business_activity: { activities: {} },
        primary_activity: {
          categories: {},
        },
        files_others: {},
      },
      member: {
        info: [],
      },
      ssl_charge: null,
      total_amount: null,
    };
  },
  methods: {
    sslPayment: function (invoice_id) {
      this.$root.spinner = true;
      axios
        .post("/ssl-payment", {
          type: "reg-member",
          id: this.account_info.member.id,
          invoice_id: invoice_id,
        })
        .then((response) => {
          this.$root.spinner = false;
          if (response.data.GatewayPageURL) {
            window.location.href = response.data.GatewayPageURL;
          }
        });
    },
    getProfile: function () {
      this.$root.spinner = true;
      axios
        .get("/reg-member/get-profile-info")
        .then((response) => {
          if (Object.keys(response.data).length > 0) {
            this.data = response.data;
            this.$root.spinner = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getAccount: function () {
      axios.get("/reg-member/get-account-info").then((response) => {
        if (Object.keys(response.data).length > 0) {
          this.account_info = response.data;
          this.ssl_charge = 0;
          // if (this.account_info.member.payment_method == "ssl") {
          //   this.total_amount =
          //     +this.ssl_charge + +this.account_info.account.amount;
          // } else {
          this.total_amount = this.account_info.account.amount;
          // }
        }
      });
    },
    downloadInvoice() {
      window.location.href = this.$root.baseurl + "/reg-member/invoice-pdf";
    },
    moneyReceipt(id) {
      window.open(this.$root.baseurl + "/reg-member/money-receipt/" + id);
    },
    payment() {
      this.$router.push({
        name: "reg-member.dashboardForm",
        params: { paymentOption: true },
      });
    },
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  created() {
    this.LoggedInRegMember();
    this.getProfile();
    this.getAccount();
    let vm = this;
    // Promise.delay(100).then(function() {
    //   vm.ssl_extra_charge();
    // });
  },
  mounted() {
    this.payment_type = this.$root.variable.payment_method;
  },
};
</script>
<style type="text/css">
body {
  font-family: sans-serif;
  -webkit-print-color-adjust: exact;
}

.page {
  page-break-after: always;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-right {
  text-align: right;
}
.invoice-frontend-table {
  margin-top: 10px;
}

.invoice-frontend-table .table tr th,
.invoice-frontend-table .table tr td {
  padding: 5px;
  border: 1px solid #222;
}
.invoice-frontend-table tr th,
.invoice-frontend-table tr td {
  border: 0;
  padding: 8px 0;
  font-weight: 600;
}

.page:last-child {
  page-break-after: auto;
}

.invoice-frontend {
  position: relative;
  max-width: 660px;
  height: 1180px;
  margin: 0 auto;
}
.invoice-frontend > img {
  height: 1140px;
}
</style>
