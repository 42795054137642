<template>
  <div class="memberLogin py-5" style="min-height:450px;">
    <div class="container-wrapper">
      <div class="row justify-content-md-center">
        <div class="col-lg-4 p-0">
          <div class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Unauthorised!!</h4>
            <p>Please Login First..</p>
            <p></p>
          </div>

          <router-link
            to="/member-login"
            class="btn btn-outline-primary font-weight-bold rounded-50 ml-2"
          >Login</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
export default {
  name: "Authenticate",
  data() {
    return {
      una: false
    };
  },
  methods: {
    checkAuth() {
      axios.get("/member/loggedOrNot/members").then(response => {
        if (response.data) {
          window.location.href = this.$root.baseurl + "/member/dashboard";
        }
      });
    }
  },
  mounted() {
    this.checkAuth();
    setTimeout(() => (this.$root.loading = false), this.$root.minloadtimefd);
  }
};
</script>
