<template>
  <div
    class="member-services"
    v-if="!$parent.loading && member.info && member.info.status == 1"
  >
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0 mt-1">
        <div class="justyfy text-center">
          <h5 class="px-3 mb-0">{{ serviceTitle }}</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="alert alert-danger" role="alert" v-if="!showServiceForm">
          <h4 class="alert-heading">Warning!</h4>
          <p>This service is not availabe</p>
          <hr />
        </div>
        <form v-on:submit.prevent="submit" id="form" v-if="showServiceForm">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Company Name
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  v-if="memberInfo"
                  v-model="memberInfo.company_name"
                  class="form-control"
                  readonly
                />
              </div>
              <div class="form-row mb-3">
                <label for="comments">
                  Representative Name
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  v-model="data.applicant_name"
                  class="form-control"
                  id="formGroup"
                  placeholder="Eg: John Doe"
                  readonly
                />
              </div>
            </div>
            <!-- right side -->
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Membership ID
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  v-if="memberInfo"
                  v-model="memberInfo.membership_no"
                  class="form-control"
                  id="inputmembershipid"
                  readonly
                />
              </div>
              <div class="form-row mb-3">
                <label for="comments">Designation</label>
                <span class="text-danger requierd-star"></span>
                <input
                  type="text"
                  v-model="data.designation_representative"
                  class="form-control"
                  id="inputdesignation"
                  placeholder="Designation"
                  readonly
                />
              </div>
            </div>
          </div>
          <br />
          <button class="btn btn-success text-center" type="submit">
            Next
          </button>
        </form>
      </div>
    </div>
    <!-- <pre>{{data}}</pre> -->
  </div>
  <div v-else-if="member.info && member.info.status == 0" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! your account is suspend
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EblCreditCardForm",
  props: ["memberInfo"],
  data() {
    return {
      declaration: false,
      showServiceForm: false,
      serviceTitle: "",
      data: {},
      errors: {},
      member: {
        info: null,
      },
    };
  },
  methods: {
    submit: function () {
      this.data.form_type = this.$route.name;
      this.$parent.loading = true;
      axios
        .post("/member/store-company-info", this.data)
        .then((response) => {
          this.$toastr(
            "success",
            "Your information has been submitted, please payment",
            "Success!!"
          );
          if (response.data) {
            localStorage.setItem("recp_id", response.data.recp_id);
            this.$router.push({
              name: "paymentMethod",
              params: {
                id: this.data.service_id,
                amount: response.data.amount,
              },
            });
          }
        })
        .catch((error) => console.log(error))
        .then((alw) => (this.$parent.loading = false));
    },
    getServiceInfo() {
      axios
        .get("/member/get-serviceinfo/" + this.$route.params.id)
        .then((res) => {
          this.showServiceForm = res.data;
        });
    },
  },
  created() {
    this.loggedIn();
    setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);
    this.data.service_id = this.$route.params.id;
    axios.get("member/get-represent-info/primary").then((response) => {
      this.data.applicant_name = response.data.name;
      this.data.designation_representative = response.data.designation;
    });

    axios.get("/admin/Service/" + this.$route.params.id).then((res) => {
      this.serviceTitle = res.data.title;
    });
    this.getServiceInfo();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
};
</script>

