<template>
  <div
    class="member-services"
    v-if="
      (!$parent.loading &&
        $root.access &&
        Object.keys($root.access).length > 0 &&
        $root.access['photos_file']) ||
      $root.variable.rep_login ||
      $root.variable.aLogId
    "
  >
    <form v-on:submit.prevent="submit" id="form">
      <div class="card shadow-sm mb-3">
        <div class="card-header px-0">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="text-uppercase font-weight-bold px-3 mb-0">
              Upload 6 photos of your company:
            </h5>
          </div>
        </div>
        <div class="card-body">
          <div class="row align-items-center justify-content-end mb-4">
            <div
              v-if="Object.keys(errors).length > 0"
              class="col-lg-12 py-2"
              style="background: #fddede"
            >
              <div class="error">
                <span
                  v-for="(err, errIndex) in errors"
                  :key="errIndex"
                  class="text-danger"
                >
                  {{ err[0] }}
                  <br />
                </span>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-6 mb-3">
              <div class="form-row">
                <div class="form-group col-2 text-center">
                  <img
                    v-lazy="
                      company_photo1 || photosFiles.company_photo1
                        ? company_photo1_src
                        : noPdf
                    "
                    class="img-responsive"
                    height="65"
                    width="70"
                    alt="company_photo1"
                  />
                </div>
                <div class="form-group col-10">
                  <label for="comments">
                    Company Photo 1
                    <span class="requierd-star"></span>
                  </label>
                  <b-form-file
                    v-model="company_photo1"
                    size="sm"
                    placeholder="Choose jpg,jpeg,png file"
                    accept=".jpg, .png"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <small>Resize your photo (width:600px X height:400px)</small>
                  <div class="invalid-feedback">
                    {{ validation.firstError("company_photo1") }}
                  </div>
                </div>
                <input
                  type="hidden"
                  :value="photosFiles.company_photo1"
                  name="old_company_photo1"
                />
              </div>
              <div class="form-row">
                <div class="form-group col-2 text-center">
                  <img
                    v-lazy="
                      company_photo2 || photosFiles.company_photo2
                        ? company_photo2_src
                        : noPdf
                    "
                    class="img-responsive"
                    height="65"
                    width="70"
                    alt="company_photo2"
                  />
                </div>
                <div class="form-group col-10">
                  <label for="comments">
                    Company Photo 2
                    <span class="requierd-star"></span>
                  </label>
                  <b-form-file
                    v-model="company_photo2"
                    size="sm"
                    placeholder="Choose jpg,jpeg,png file"
                    accept=".jpg, .png"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <small>Resize your photo (width:600px X height:400px)</small>
                  <div class="invalid-feedback">
                    {{ validation.firstError("company_photo2") }}
                  </div>
                </div>
                <input
                  type="hidden"
                  :value="photosFiles.company_photo2"
                  name="old_company_photo2"
                />
              </div>
              <div class="form-row">
                <div class="form-group col-2 text-center">
                  <img
                    v-lazy="
                      company_photo3 || photosFiles.company_photo3
                        ? company_photo3_src
                        : noPdf
                    "
                    class="img-responsive"
                    height="65"
                    width="70"
                    alt="company_photo3"
                  />
                </div>
                <div class="form-group col-10">
                  <label for="comments">
                    Company Photo 3
                    <span class="requierd-star"></span>
                  </label>
                  <b-form-file
                    v-model="company_photo3"
                    size="sm"
                    placeholder="Choose jpg,jpeg,png file"
                    accept=".jpg, .png"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <small>Resize your photo (width:600px X height:400px)</small>
                  <div class="invalid-feedback">
                    {{ validation.firstError("company_photo3") }}
                  </div>
                </div>
                <input
                  type="hidden"
                  :value="photosFiles.company_photo3"
                  name="old_company_photo3"
                />
              </div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="form-row">
                <div class="form-group col-2 text-center">
                  <img
                    v-lazy="
                      company_photo4 || photosFiles.company_photo4
                        ? company_photo4_src
                        : noPdf
                    "
                    class="img-responsive"
                    height="65"
                    width="70"
                    alt="company_photo4"
                  />
                </div>
                <div class="form-group col-10">
                  <label for="comments">Company Photo 4</label>
                  <b-form-file
                    v-model="company_photo4"
                    size="sm"
                    placeholder="Choose jpg,jpeg,png file"
                    accept=".jpg, .png"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <small>Resize your photo (width:600px X height:400px)</small>
                </div>
                <div class="invalid-feedback">
                  {{ validation.firstError("company_photo4") }}
                </div>
                <input
                  type="hidden"
                  :value="photosFiles.company_photo4"
                  name="old_company_photo4"
                />
              </div>
              <div class="form-row">
                <div class="form-group col-2 text-center">
                  <img
                    v-lazy="
                      company_photo5 || photosFiles.company_photo5
                        ? company_photo5_src
                        : noPdf
                    "
                    class="img-responsive"
                    height="65"
                    width="70"
                    alt="company_photo5"
                  />
                </div>
                <div class="form-group col-10">
                  <label for="comments">Company Photo 5</label>
                  <b-form-file
                    v-model="company_photo5"
                    size="sm"
                    placeholder="Choose jpg,jpeg,png file"
                    accept=".jpg, .png"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <small>Resize your photo (width:600px X height:400px)</small>
                </div>
                <div class="invalid-feedback">
                  {{ validation.firstError("company_photo5") }}
                </div>
                <input
                  type="hidden"
                  :value="photosFiles.company_photo5"
                  name="old_company_photo5"
                />
              </div>
              <div class="form-row">
                <div class="form-group col-2 text-center">
                  <img
                    v-lazy="
                      company_photo6 || photosFiles.company_photo6
                        ? company_photo6_src
                        : noPdf
                    "
                    class="img-responsive"
                    height="65"
                    width="70"
                    alt="company_photo6"
                  />
                </div>
                <div class="form-group col-10">
                  <label for="comments">Company Photo 6</label>
                  <b-form-file
                    v-model="company_photo6"
                    size="sm"
                    placeholder="Choose jpg,jpeg,png file"
                    accept=".jpg, .png"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <small>Resize your photo (width:600px X height:400px)</small>
                </div>
                <div class="invalid-feedback">
                  {{ validation.firstError("company_photo6") }}
                </div>
                <input
                  type="hidden"
                  :value="photosFiles.company_photo6"
                  name="old_company_photo6"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <fieldset class="col-lg-12 border border-primary p-3">
              <legend class="w-25">
                <small class="font-weight-bold text-uppercase"
                  >Upload Cover Photo</small
                >
              </legend>
              <div class="col-lg-6">
                <div class="form-row">
                  <div
                    class="form-group col-2 text-center"
                    v-if="member.info && !cover_photo"
                  >
                    <img
                      v-if="member.info.cover_photo"
                      v-lazy="
                        member.info.cover_photo
                          ? $root.baseurl + '/public' + member.info.cover_photo
                          : noPdf
                      "
                      class="img-responsive"
                      height="65"
                      width="70"
                      alt="cover_photo"
                    />
                  </div>
                  <div class="form-group col-2 text-center" v-else>
                    <img
                      v-lazy="cover_photo ? cover_photo_src : noPdf"
                      class="img-responsive"
                      height="65"
                      width="70"
                      alt="cover_photo"
                    />
                  </div>
                  <div class="form-group col-10">
                    <label for="comments">Cover Photo</label>
                    <span class="requierd-star"></span>
                    <b-form-file
                      v-model="cover_photo"
                      size="sm"
                      placeholder="Choose jpg,jpeg,png file"
                      accept=".jpg, .png"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <small
                      >Resize your cover photo (width:800px X
                      height:240px)</small
                    >
                  </div>
                  <div class="invalid-feedback">
                    {{ validation.firstError("cover_photo") }}
                  </div>
                  <input
                    type="hidden"
                    v-if="member.info"
                    :value="member.info.cover_photo"
                    name="old_cover_photo"
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="card shadow-sm mb-3">
        <div class="card-header px-0">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="px-3 mb-0">Files and Others:</h5>
          </div>
        </div>
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-lg-6 mb-3">
              <!-- <div class="form-row mb-3">
                <label for="Signature_person_name">
                  Signature person name
                  <span class="requierd-star"></span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="inputEmail3"
                  name="sign_person_name"
                  v-model="photosFiles.sign_person_name"
                  placeholder="Signature person name"
                />
              </div>-->
              <div class="form-row">
                <div class="form-group col-2 text-center">
                  <img
                    v-lazy="
                      representative_sign || representative.sign
                        ? sign_src
                        : noPdf
                    "
                    class="img-responsive"
                    height="65"
                    width="100%"
                    alt="representative_sign"
                  />
                </div>
                <div class="col-10 mb-3">
                  <label for="representative_sign"
                    >Representative Signature</label
                  >
                  <div class="loginBox">
                    <b-form-file
                      v-model="representative_sign"
                      :class="{
                        'is-invalid': validation.hasError(
                          'representative_sign'
                        ),
                        'is-valid': representative_sign,
                      }"
                      accept=".jpg, .png"
                      placeholder="Choose jpg or png image"
                      drop-placeholder="Drop file here..."
                      name="representative_sign"
                    ></b-form-file>
                    <span class="mdi mdi-file-pdf text-danger"></span>
                  </div>
                  <small>Size ( W200 X H80)</small>
                  <a
                    v-if="representative && representative.sign"
                    :href="$root.baseurl + '/public' + representative.sign"
                    class="float-right"
                    target="_blank"
                    >View file</a
                  >
                  <div class="invalid-feedback">
                    {{ validation.firstError("representative_sign") }}
                  </div>
                </div>

                <input
                  v-if="representative"
                  type="hidden"
                  v-model="representative.sign"
                  name="old_representative_sign"
                />
              </div>

              <div class="form-row mb-3">
                <div class="form-group col-2 text-center">
                  <img
                    v-if="photosFiles"
                    v-lazy="
                      printed_brochure || photosFiles.printed_brochure
                        ? pdfAttach
                        : noPdf
                    "
                    class="img-responsive"
                    height="65"
                    width="70"
                    alt="printed_brochure"
                  />
                </div>
                <div class="col-10 mb-3">
                  <label for="printed_brochure"
                    >Printed brochure (if available)</label
                  >
                  <b-form-file
                    v-model="printed_brochure"
                    :class="{
                      'is-invalid': validation.hasError('printed_brochure'),
                      'is-valid': printed_brochure,
                    }"
                    placeholder="Choose a pdf"
                    accept=".pdf"
                    drop-placeholder="Drop file here..."
                    name="printed_brochure"
                  ></b-form-file>
                  <input
                    type="hidden"
                    v-model="photosFiles.printed_brochure"
                    name="old_printed_brochure"
                  />
                  <small>File size must be smaller than 2mb</small>
                  <a
                    v-if="photosFiles.printed_brochure"
                    :href="
                      $root.baseurl + '/public' + photosFiles.printed_brochure
                    "
                    class="float-right"
                    target="_blank"
                    >View file</a
                  >
                  <div class="invalid-feedback">
                    {{ validation.firstError("printed_brochure") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="form-row mb-3">
                <div class="form-group col-2 text-center">
                  <img
                    v-if="member.info"
                    v-lazy="
                      company_profile || member.info.profile ? pdfAttach : noPdf
                    "
                    class="img-responsive"
                    height="65"
                    width="70"
                    alt="company_profile"
                  />
                </div>
                <div class="col-10 mb-3">
                  <label for="Company_profile">
                    Company profile (
                    <small>File size must be smaller than 2mb</small>)
                  </label>
                  <div class="loginBox">
                    <b-form-file
                      v-model="company_profile"
                      :class="{
                        'is-invalid': validation.hasError('company_profile'),
                        'is-valid': company_profile,
                      }"
                      accept=".pdf"
                      :placeholder="
                        member.info && member.info.profile
                          ? 'File attached '
                          : 'Choose a pdf file'
                      "
                      drop-placeholder="Drop file here..."
                      name="company_profile"
                    ></b-form-file>
                    <span class="mdi mdi-file-pdf text-danger"></span>
                  </div>
                  <small
                    >Company profile describing infrastructure, human resource,
                    business activity, capability and experience of the
                    Organizing/ Company</small
                  >
                  <a
                    v-if="member.info && member.info.profile"
                    :href="$root.baseurl + '/public' + member.info.profile"
                    class="float-right"
                    target="_blank"
                    >View file</a
                  >

                  <div class="invalid-feedback">
                    {{ validation.firstError("company_profile") }}
                  </div>
                </div>
                <input
                  v-if="member.info"
                  type="hidden"
                  v-model="member.info.company_profile"
                  name="old_company_profile"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <fieldset class="col-lg-12 border border-primary p-3">
              <legend class="w-25">
                <small class="font-weight-bold text-uppercase"
                  >Work Order</small
                >
              </legend>
              <div class="form-row">
                <div class="form-row col-lg-4 mb-3">
                  <div
                    class="form-group col-3 text-center pt-3"
                    v-if="photosFiles"
                  >
                    <img
                      id="displayProfilPhoto"
                      v-lazy="
                        photosFiles.work_order_first || work_order_first
                          ? attachPdf
                          : noPdf
                      "
                      height="45px"
                      alt="displayProfilPhoto"
                    />
                  </div>
                  <div class="form-group col-9">
                    <label for="comments">Work Order (First)</label>
                    <b-form-file
                      v-model="work_order_first"
                      accept=".pdf"
                      placeholder="Choose a pdf file"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <input
                      type="hidden"
                      :value="photosFiles.work_order_first"
                      name="old_work_order_first"
                    />

                    <small>File size must be smaller than 2mb</small>
                    <div class="invalid-feedback">
                      {{ validation.firstError("work_order_first") }}
                    </div>
                    <a
                      v-if="photosFiles.work_order_first"
                      :href="
                        $root.baseurl + '/public' + photosFiles.work_order_first
                      "
                      class="float-right"
                      target="_blank"
                      >View file</a
                    >
                  </div>
                </div>
                <div class="form-row col-lg-4 mb-3">
                  <div
                    class="form-group col-3 text-center pt-3"
                    v-if="photosFiles"
                  >
                    <img
                      id="displayProfilPhoto"
                      v-lazy="
                        photosFiles.work_order_second || work_order_second
                          ? attachPdf
                          : noPdf
                      "
                      height="45px"
                      alt="displayProfilPhoto"
                    />
                  </div>
                  <div class="form-group col-9">
                    <label for="comments">Work Order (Second)</label>
                    <b-form-file
                      v-model="work_order_second"
                      accept=".pdf"
                      placeholder="Choose a pdf file"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <input
                      type="hidden"
                      :value="photosFiles.work_order_second"
                      name="old_work_order_second"
                    />

                    <small>File size must be smaller than 2mb</small>
                    <div class="invalid-feedback">
                      {{ validation.firstError("work_order_second") }}
                    </div>
                    <a
                      v-if="photosFiles.work_order_second"
                      :href="
                        $root.baseurl +
                        '/public' +
                        photosFiles.work_order_second
                      "
                      class="float-right"
                      target="_blank"
                      >View file</a
                    >
                  </div>
                </div>
                <div class="form-row col-lg-4 mb-3">
                  <div
                    class="form-group col-3 text-center pt-3"
                    v-if="photosFiles"
                  >
                    <img
                      id="displayProfilPhoto"
                      v-lazy="
                        photosFiles.work_order_third || work_order_third
                          ? attachPdf
                          : noPdf
                      "
                      height="45px"
                      alt="displayProfilPhoto"
                    />
                  </div>
                  <div class="form-group col-9">
                    <label for="comments">Work Order (Third)</label>
                    <b-form-file
                      v-model="work_order_third"
                      accept=".pdf"
                      placeholder="Choose a pdf file"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>

                    <small>File size must be smaller than 2mb</small>
                    <input
                      type="hidden"
                      :value="photosFiles.work_order_third"
                      name="old_work_order_third"
                    />
                    <div class="invalid-feedback">
                      {{ validation.firstError("work_order_third") }}
                    </div>
                    <a
                      v-if="photosFiles.work_order_third"
                      :href="
                        $root.baseurl + '/public' + photosFiles.work_order_third
                      "
                      class="float-right"
                      target="_blank"
                      >View file</a
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="row justify-content-center mt-3">
            <button class="btn btn-success" type="submit">
              Upload Photos / Files
            </button>
          </div>
        </div>
      </div>
    </form>
    <!-- <pre>{{validation.allErrors()}}</pre> -->
  </div>
  <div v-else class="row">
    <div class="col-12">
      <div class="card">
        <div v-if="Object.keys($root.access).length > 0" class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! you cannot access this page
          </h4>
        </div>
        <div v-else class="card-body">
          <div
            v-if="!$parent.loading"
            class="
              card-body
              d-flex
              align-items-center
              justify-content-center
              flex-column
            "
          >
            <i
              class="fa fa-spinner fa-spin"
              style="font-size: 24px; color: rgb(0, 127, 62)"
            ></i>
            <span class="processing">processing</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noPdf from "../../../../assets/img/noimg.png";
import pdfAttach from "../../../../assets/img/PdfAttached.png";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
const breadcumb = [
  {
    title: "Upload Photos / Files",
  },
];
export default {
  name: "UploadPhotosFiles",
  props: ["memberInfo"],
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Upload Photos / Files",
      pdf: null,
      noPdf: this.$root.baseurl + noPdf,
      pdfAttach: this.$root.baseurl + pdfAttach,
      attachPdf: this.$root.baseurl + pdfAttach,
      company_profile: null,
      representative_sign: null,
      sign_src: null,
      printed_brochure: null,
      cover_photo: null,
      cover_photo_src: null,
      company_photo1: null,
      company_photo1_src: null,
      company_photo2: null,
      company_photo2_src: null,
      company_photo3: null,
      company_photo3_src: null,
      company_photo4: null,
      company_photo4_src: null,
      company_photo5: null,
      company_photo5_src: null,
      company_photo6: null,
      company_photo6_src: null,
      work_order_first: null,
      work_order_second: null,
      work_order_third: null,
      representative: {},
      photosFiles: {},
      member: {
        info: {},
      },
      errors: {},
    };
  },
  watch: {
    company_profile: function (file, oldFile) {
      this.showPdf(file);
    },
    printed_brochure: function (file, oldFile) {
      this.showPdf(file);
    },
    representative_sign: function (img, oldImg) {
      this.showImage(img, "sign_src");
    },
    cover_photo: function (img, oldImg) {
      this.showImage(img, "cover_photo_src");
    },
    company_photo1: function (img, oldImg) {
      this.showImage(img, "company_photo1_src");
    },
    company_photo2: function (img, oldImg) {
      this.showImage(img, "company_photo2_src");
    },
    company_photo3: function (img, oldImg) {
      this.showImage(img, "company_photo3_src");
    },
    company_photo4: function (img, oldImg) {
      this.showImage(img, "company_photo4_src");
    },
    company_photo5: function (img, oldImg) {
      this.showImage(img, "company_photo5_src");
    },
    company_photo6: function (img, oldImg) {
      this.showImage(img, "company_photo6_src");
    },
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      var form = document.getElementById("form");
      var formData = new FormData(form);

      if (confirm("Are you confirming, is the provided information valid?")) {
        if (this.company_profile) {
          formData.append("company_profile", this.company_profile);
        }
        if (this.cover_photo) {
          formData.append("cover_photo", this.cover_photo);
        }
        if (this.work_order_first) {
          formData.append("work_order_first", this.work_order_first);
        }
        if (this.work_order_second) {
          formData.append("work_order_second", this.work_order_second);
        }
        if (this.work_order_third) {
          formData.append("work_order_third", this.work_order_third);
        }
        if (this.representative_sign) {
          formData.append("representative_sign", this.representative_sign);
        }
        if (this.printed_brochure) {
          formData.append("printed_brochure", this.printed_brochure);
        }
        if (this.company_photo1) {
          formData.append("company_photo1", this.company_photo1);
        }
        if (this.company_photo2) {
          formData.append("company_photo2", this.company_photo2);
        }
        if (this.company_photo3) {
          formData.append("company_photo3", this.company_photo3);
        }
        if (this.company_photo4) {
          formData.append("company_photo4", this.company_photo4);
        }
        if (this.company_photo5) {
          formData.append("company_photo5", this.company_photo5);
        }
        if (this.company_photo6) {
          formData.append("company_photo6", this.company_photo6);
        }
        this.$validate().then((res) => {
          if (this.validation.countErrors() > 0) {
            this.$toastr(
              "warning",
              'You need to fill <span class="font-weight-bold">' +
                this.validation.countErrors() +
                " more empty</span> mandatory fields",
              "Empty Mandatory Fields!!"
            );
          } else {
            if (res) {
              this.$parent.loading = true;
              this.scrollTop();
              axios
                .post("member/PhotosFiles", formData, config)
                .then((response) => {
                  this.$toastr("success", "Update Successfully", "Success!!");
                  this.errors = {};
                  location.reload();
                })
                .catch((error) => {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                  }
                })
                .then((always) => {
                  setTimeout(
                    () => (this.$parent.loading = false),
                    this.$root.minloadtimefd
                  );
                });
            }
          }
        });
      }
    },
    getPhotosFiles: function () {
      axios
        .get("/member/getPhotosFiles")
        .then((response) => {
          this.photosFiles = response.data;

          this.company_photo1_src =
            this.$root.baseurl + "/public" + response.data.company_photo1;
          this.company_photo2_src =
            this.$root.baseurl + "/public" + response.data.company_photo2;
          this.company_photo3_src =
            this.$root.baseurl + "/public" + response.data.company_photo3;
          this.company_photo4_src =
            this.$root.baseurl + "/public" + response.data.company_photo4;
          this.company_photo5_src =
            this.$root.baseurl + "/public" + response.data.company_photo5;
          this.company_photo6_src =
            this.$root.baseurl + "/public" + response.data.company_photo6;
        })
        .catch((error) => console.log(error))
        .then((always) => {
          setTimeout(() => (this.$parent.loading = false), 500);
        });
    },
    getRepresentative: function () {
      axios
        .get("member/get-represent-info/primary")
        .then((response) => {
          this.representative = response.data;
          this.sign_src = this.$root.baseurl + "/public" + response.data.sign;
        })
        .catch((error) => console.log(error))
        .then((res) => {
          setTimeout(() => (this.$parent.loading = false), 500);
        });
    },
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  mounted() {
    this.loggedIn();
    this.getRepresentative();
    this.getPhotosFiles();
  },
  updated() {
    // this.$root.debug_data = this.member;
  },
  validators: {
    company_photo1: function (value = null) {
      if (!this.photosFiles.company_photo3) {
        return Validator.value(value)
          .required("Company photo is required")
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var type = value.type;
              if (
                type == "image/jpeg" ||
                type == "image/jpg" ||
                type == "image/png"
              ) {
              } else {
                return "Image must be of type .jpeg .jpg or .png";
              }
            }
          })
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var size = value.size;
              if (size > 2048000) {
                return "Must be smaller than 2mb";
              }
            }
          });
      } else {
        return Validator.value(value)
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var type = value.type;
              if (
                type == "image/jpeg" ||
                type == "image/jpg" ||
                type == "image/png"
              ) {
              } else {
                return "Image must be of type .jpeg .jpg or .png";
              }
            }
          })
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var size = value.size;
              if (size > 2048000) {
                return "Must be smaller than 2mb";
              }
            }
          });
      }
    },
    company_photo2: function (value = null) {
      if (!this.photosFiles.company_photo3) {
        return Validator.value(value)
          .required("Company photo is required")
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var type = value.type;
              if (
                type == "image/jpeg" ||
                type == "image/jpg" ||
                type == "image/png"
              ) {
              } else {
                return "Image must be of type .jpeg .jpg or .png";
              }
            }
          })
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var size = value.size;
              if (size > 2048000) {
                return "Must be smaller than 2mb";
              }
            }
          });
      } else {
        return Validator.value(value)
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var type = value.type;
              if (
                type == "image/jpeg" ||
                type == "image/jpg" ||
                type == "image/png"
              ) {
              } else {
                return "Image must be of type .jpeg .jpg or .png";
              }
            }
          })
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var size = value.size;
              if (size > 2048000) {
                return "Must be smaller than 2mb";
              }
            }
          });
      }
    },
    company_photo3: function (value = null) {
      if (!this.photosFiles.company_photo3) {
        return Validator.value(value)
          .required("Company photo is required")
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var type = value.type;
              if (
                type == "image/jpeg" ||
                type == "image/jpg" ||
                type == "image/png"
              ) {
              } else {
                return "Image must be of type .jpeg .jpg or .png";
              }
            }
          })
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var size = value.size;
              if (size > 2048000) {
                return "Must be smaller than 2mb";
              }
            }
          });
      } else {
        return Validator.value(value)
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var type = value.type;
              if (
                type == "image/jpeg" ||
                type == "image/jpg" ||
                type == "image/png"
              ) {
              } else {
                return "Image must be of type .jpeg .jpg or .png";
              }
            }
          })
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var size = value.size;
              if (size > 2048000) {
                return "Must be smaller than 2mb";
              }
            }
          });
      }
    },
    company_photo4: function (value = null) {
      return Validator.value(value)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (
              type == "image/jpeg" ||
              type == "image/jpg" ||
              type == "image/png"
            ) {
            } else {
              return "Image must be of type .jpeg .jpg or .png";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "Must be smaller than 2mb";
            }
          }
        });
    },
    company_photo5: function (value = null) {
      return Validator.value(value)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (
              type == "image/jpeg" ||
              type == "image/jpg" ||
              type == "image/png"
            ) {
            } else {
              return "Image must be of type .jpeg .jpg or .png";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "Must be smaller than 2mb";
            }
          }
        });
    },
    company_photo6: function (value = null) {
      return Validator.value(value)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (
              type == "image/jpeg" ||
              type == "image/jpg" ||
              type == "image/png"
            ) {
            } else {
              return "Image must be of type .jpeg .jpg or .png";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "Must be smaller than 2mb";
            }
          }
        });
    },

    representative_sign: function (value = null) {
      return Validator.value(value).custom(function () {
        if (!Validator.isEmpty(value)) {
          var type = value.type;
          if (
            type == "image/jpeg" ||
            type == "image/jpg" ||
            type == "image/png"
          ) {
          } else {
            return "Image must be of type .jpeg .jpg or .png";
          }
        }
      });
    },
    cover_photo: function (value = null) {
      if (this.member.info) {
        if (!this.member.info.cover_photo) {
          return Validator.value(value)
            .required("cover photo is required")
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (
                  type == "image/jpeg" ||
                  type == "image/jpg" ||
                  type == "image/png"
                ) {
                } else {
                  return "Image must be of type .jpeg .jpg or .png";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 2048000) {
                  return "Must be smaller than 2mb";
                }
              }
            });
        }
      } else {
        return Validator.value(value)
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var type = value.type;
              if (
                type == "image/jpeg" ||
                type == "image/jpg" ||
                type == "image/png"
              ) {
              } else {
                return "Image must be of type .jpeg .jpg or .png";
              }
            }
          })
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var size = value.size;
              if (size > 2048000) {
                return "Must be smaller than 2mb";
              }
            }
          });
      }
    },
    company_profile: function (value = null) {
      return Validator.value(value)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "PDF format required";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "Pdf must be smaller than 2mb";
            }
          }
        });
    },
    printed_brochure: function (value = null) {
      return Validator.value(value)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "PDF format required";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "Pdf must be smaller than 2mb";
            }
          }
        });
    },
    work_order_first: function (value = null) {
      return Validator.value(value)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "PDF format required";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "Pdf must be smaller than 2mb";
            }
          }
        });
    },
    work_order_second: function (value = null) {
      return Validator.value(value)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "PDF format required";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "Pdf must be smaller than 2mb";
            }
          }
        });
    },
    work_order_third: function (value = null) {
      return Validator.value(value)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "PDF format required";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 2048000) {
              return "Pdf must be smaller than 2mb";
            }
          }
        });
    },
  },
};
</script>

