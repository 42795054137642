<template>
	<div class="contact">
		<PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
		<div class="row justify-content-center mt-2">
			<div class="col-lg-7 mb-3">
				<div class="member-search">
					<div class="search-body">
						<h1>What's New</h1>
						<div class="row">
							<div class="col-6">
								<DatePicker
									class="datepicker"
									placeholder="From Date: e.g. 01-Jan-2020"
									v-model="search_data.formDate"
									name="formDate"
								/>
							</div>

							<div class="col-6">
								<DatePicker
									class="datepicker"
									placeholder="To Date: e.g. 01-Jan-2020"
									v-model="search_data.toDate"
									name="toDate"
								/>
							</div>
						</div>
						<br />

						<div class="searchBox">
							<input
								type="text"
								placeholder="Search by news title"
								v-on:keyup.enter="get_datas()"
								v-model="search_data.title"
								id="search"
							/>
							<span
								class="mdi mdi-magnify"
								@click="get_datas()"
							></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-wrapper" v-if="!$root.spinner">
			<div class="content-body py-4">
				<div class="row" v-if="Object.keys(table.datas).length > 0">
					<div
						v-for="(item, index) in table.datas"
						:key="index"
						class="col-md-4 mt-4"
					>
						<div class="card card-body whatsnew-card">
							<router-link
								v-if="item.type != 'external_link'"
								:to="{
									name: 'whatsnewDetails',
									params: { id: item.id },
								}"
							>
								<WhatsNewBlock :item="item" />
							</router-link>
							<a v-else :href="item.url" target="_blank">
								<WhatsNewBlock :item="item" />
							</a>
						</div>
					</div>
				</div>
				<!-- pagination -->
				<Pagination
					:url="'get-whats-new'"
					:search_data="search_data"
					v-if="!$root.spinner"
				/>
				<!-- /pagination -->
			</div>
		</div>
		<spinner v-if="$root.spinner" />

		<!-- <pre>
        {{ table }}
    </pre>-->
	</div>
</template>

<script>
	import DatePicker from "vue2-datepicker";
	import "vue2-datepicker/index.css";
	import PageBanner from "../../components/elements/PageBanner";
	import WhatsNewBlock from "./WhatsNewBlock";

	const breadcumb = [
		{
			title: "What's New"
		}
	];
	export default {
		name: "WhatsNew",
		components: { PageBanner, WhatsNewBlock, DatePicker },

		data() {
			return {
				breadcrumbTitle: "What's New",
				breadcumb: [{ title: "What's New" }],
				search_data: { title: "", formDate: null, toDate: null },
				table: {
					datas: []
				},
				meta: [],
				links: []
			};
		},
		methods: {
			asyncData() {
				this.get_datas();
				this.$root.loading = false;
			},
			get_datas: function() {
				this.$root.spinner = true;
				console.log(this.search_data);
				axios
					.get("/get-whats-new", { params: this.search_data })
					.then(res => {
						this.table.datas = res.data;
						this.table.datas = res.data.data;
						this.meta = res.data.meta;
						this.links = res.data.links;
						this.$root.spinner = false;
					})
					.catch(error => console.log(error));
			}
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		watch: {
			$route: {
				handler: "asyncData",
				immediate: true
			}
		}
	};

	// $(".datepicker").datepicker({
	// 	format: "yyyy-mm-dd"
	// });
</script>

<style scoped>
	.whats-new-list .list-group-flush .list-group-item {
		background: transparent;
		padding: 6px 0px;
	}
	img.big-image {
		height: 16em;
		width: 100%;
		object-fit: cover;
	}
	p.description {
		text-align: justify;
	}
	.item-image img {
		width: 9em;
		height: 5em;
		box-shadow: -3px 3px 0px 3px #6ec8bf;
		border-radius: 4px;
		object-fit: cover;
	}
	.item-text strong {
		font-weight: 500;
		color: rgb(0 0 0);
		font-size: 13px;
		font-family: "Raleway";
	}
	.item-text {
		margin-left: 10px;
	}
</style>
