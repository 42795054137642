<template>
    <!--newsroom & members blog-->
    <div class="newsBlog">
        <div class="container-wrapper">
            <div class="content-header text-center">
                <h1 class="title">News Room</h1>
            </div>
            <div class="carousel-slider" v-if="parentData.news">
                <carousel :autoplay="false" :loop="true" :dots="false" :nav="false" :margin="24" :responsive="{
                    0: { items: 1 },
                    600: { items: 2 },
                    768: { items: 3 },
                }">
                    <template slot="prev">
                        <span class="prev">
                            <i class="fas fa-chevron-left"></i>
                        </span>
                    </template>
                    <!-- ============================News========================= -->
                    <div class="items p-lg-1 text-left" v-for="(news, news_index) in parentData.news" :key="news_index">
                        <slot v-if="news.type !== 'external_link'">
                            <router-link :to="{ name: 'whatsnewDetails', params: { id: news.id } }">
                                <section class="news-part">
                                    <div class="row">
                                        <div class="col-lg-11 col-md-11">
                                            <a href="" class="d-block w-100">
                                                <div class="news-item">
                                                    <div class="image position-relative">
                                                        <img v-lazy="'public' + news.image" :alt="news.title"
                                                            class="img-fluid w-100 inner-image" />
                                                    </div>
                                                    <div class="text">
                                                        <div class="date">
                                                            <span class="new-span">
                                                                <i class="mdi mdi-calendar pr-1 new-icon"></i>
                                                                {{ news.start_date | formatDate("Do MMM") }}
                                                            </span>
                                                        </div>
                                                        <div class="title">
                                                            <h4>
                                                                {{ getPostBody(news.title, 40) }}
                                                            </h4>
                                                        </div>
                                                        <p>
                                                            {{ getPostBody(news.description, 150) }}
                                                        </p>
                                                        <div class="button">
                                                            <button>Read More</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </router-link>
                        </slot>
                        <slot v-else>
                            <router-link :to="news.url" target="_blank">
                                <section class="news-part">
                                    <div class="row">
                                        <div class="col-lg-11 col-md-11">
                                            <a href="" class="d-block w-100">
                                                <div class="news-item">
                                                    <div class="image position-relative">
                                                        <img v-lazy="'public' + news.image" :alt="news.title"
                                                            class="img-fluid w-100 inner-image" />
                                                    </div>
                                                    <div class="text">
                                                        <div class="date">
                                                            <span class="new-span">
                                                                <i class="mdi mdi-calendar pr-1 new-icon"></i>
                                                                {{ news.start_date | formatDate("Do MMM") }}
                                                            </span>
                                                        </div>
                                                        <div class="title">
                                                            <h4>
                                                                {{ getPostBody(news.title, 40) }}
                                                            </h4>
                                                        </div>
                                                        <p>
                                                            {{ getPostBody(news.description, 150) }}
                                                        </p>
                                                        <div class="button">
                                                            <button>Read More</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </router-link>
                        </slot>
                    </div>
                    <!-- ============================/News========================= -->
                    <template slot="next">
                        <span class="next">
                            <i class="fas fa-chevron-right"></i>
                        </span>
                    </template>
                </carousel>
            </div>
            <div class="row pt-5">
                <div class="col-lg-12 text-center">
                    <router-link :to="{ name: 'whatsnew', params: { type: 'WhatsNew' } }"
                        class="btn btn-lg btn-primary rounded-50">
                        View All
                        <i class="fas fa-long-arrow-alt-right pl-1"></i>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <!--//newsroom & members blog-->
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
    name: "gallery",
    components: { carousel },
    props: {
        parentData: {
            type: Object,
            default: () => { },
            description: "News",
        },
    },
};
</script>

<style scoped>
/* news part start */

.news-part {
    padding-top: 80px;
    flex-grow: 1;
    flex-shrink: auto;
    flex-basis: auto;
}

.news-item {
    width: 100%;
    background-color: #f2f2f2;
    transition: all linear 0.2s;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom: 3px solid #6dc8be;
    margin-bottom: 40px;
}

.image {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    overflow: hidden;
    height: 180px;
}

.inner-image {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: all linear 0.3s;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.new-span {
    padding-bottom: 10px;
    display: inline-block;
    color: #ed1c24;
    font-weight: 500;
    font-size: 15px;
}

.new-icon {
    padding-right: 3px;
}

.news-part .news-item .text {
    padding: 20px;
    position: relative;
    min-height: 265px;
}

.news-part .news-item .text .title h4 {
    font-size: 21px;
    color: #2d3e50;
    font-weight: 500;
    transition: all linear 0.2s;
    margin-bottom: 10px;
}

.news-part .news-item .text p {
    padding-bottom: 30px;
    color: #2d3e50;
    font-size: 15px;
}

.news-part .news-item .text .button {
    position: absolute;
    top: calc(100% - 20px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
}

.news-part .news-item .text .button button {
    background-color: #6dc8be;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    padding: 6px 30px;
    border-radius: 3px;
    transition: all linear 0.2s;
    border: 0;
}

.news-part .news-item .text .button button:hover {
    background-color: #007f3d;
}

.news-part .news-item:hover .image img {
    transform: scale(1.2) rotate(3deg);
}

.news-part .news-item:hover {
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

/* news part end */
</style>
