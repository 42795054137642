var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customer-profile"},[_c('h3',{staticClass:"my-4"},[_vm._v("Help Desk")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label-sm m-0",attrs:{"for":""}},[_vm._v("Ticket ID")]),_vm._v(" "),_c('div',{staticClass:"loginBox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.ticket_id),expression:"data.ticket_id"}],staticClass:"form-control form-control-sm",attrs:{"readonly":"","type":"text","autocomplete":"off"},domProps:{"value":(_vm.data.ticket_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "ticket_id", $event.target.value)}}})])])]),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label-sm m-0",attrs:{"for":""}},[_vm._v("Subject")]),_vm._v(" "),_c('div',{staticClass:"loginBox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.subject),expression:"data.subject"}],staticClass:"form-control form-control-sm",class:{
							'is-invalid': _vm.validation.hasError('data.subject'),
							'is-valid': _vm.data.subject
						},attrs:{"type":"text","autocomplete":"off"},domProps:{"value":(_vm.data.subject)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "subject", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.subject")))])])])]),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label-sm m-0",attrs:{"for":""}},[_vm._v("Description")]),_vm._v(" "),_c('div',{staticClass:"loginBox"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.description),expression:"data.description"}],staticClass:"form-control form-control-sm",class:{
							'is-invalid': _vm.validation.hasError('data.description'),
							'is-valid': _vm.data.description
						},attrs:{"type":"text","autocomplete":"off","name":"description"},domProps:{"value":(_vm.data.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "description", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.description")))])])])]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary mt-4 ml-3",on:{"click":function($event){return _vm.submit()}}},[_vm._v("Submit")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }