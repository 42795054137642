<template>
  <div
    v-if="!$parent.loading && member.info && member.info.status == 1"
    class="row"
  >
    <div class="col-lg-9">
      <div class="card shadow-sm">
        <div class="card-header bg-transparent border-0">
          <h4 class="text-center">
            Application Form for SCB ERQ Account for Individual
          </h4>
          <p class="font-size-md text-center desc-m">
            Professionals involved in outsourced service exports, will be
            offered from Standard Chartered a bundle offer of a Taka Savings
            Account and an US Dollar ERQ (Exporter Retention Quota) account.
            Individuals, firms, and professionals involved in service exports in
            non-physical from such as outsourcing, BPO, business services,
            professional, research and advisory services etc.
          </p>
        </div>
        <div class="card-body">
          <form @submit.prevent="submit()">
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="applicant_name">
                  Name of the Applicant
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid': validation.hasError('data.applicant_name'),
                    'is-valid': data.applicant_name,
                  }"
                  id="applicant_name"
                  placeholder="e.g. Jane Doe"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. Jane Doe'"
                  v-model="data.applicant_name"
                />
                <small
                  v-if="errors && errors.applicant_name"
                  class="invalid-feedback"
                  >{{ errors.applicant_name[0] }}</small
                >
                <div class="invalid-feedback">
                  {{ validation.firstError("data.applicant_name") }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="mailing_address">
                  Mailing Address
                  <span class="text-danger">*</span>
                </label>
                <textarea
                  rows="5"
                  v-model="data.mailing_address"
                  placeholder="e.g. 221b, Baker Street, London"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. 221b, Baker Street, London'"
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid': validation.hasError('data.mailing_address'),
                    'is-valid': data.mailing_address,
                  }"
                ></textarea>
                <div class="invalid-feedback">
                  {{ validation.firstError("data.mailing_address") }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="permanent_address">
                  Permanent Address
                  <span class="text-danger">*</span>
                </label>
                <textarea
                  rows="5"
                  v-model="data.permanent_address"
                  placeholder="e.g. 2221b, Baker Street, London"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. 221b, Baker Street, London'"
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid': validation.hasError('data.permanent_address'),
                    'is-valid': data.permanent_address,
                  }"
                ></textarea>
                <div class="invalid-feedback">
                  {{ validation.firstError("data.permanent_address") }}
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <label for="gender" class="is-invalid">Gender</label>
                <div class="form-group">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="male"
                      value="Male"
                      :class="{
                        'is-invalid': validation.hasError('data.gender'),
                      }"
                      v-model="data.gender"
                    />
                    <label class="custom-control-label" for="male">Male</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="female"
                      value="Female"
                      :class="{
                        'is-invalid': validation.hasError('data.gender'),
                      }"
                      v-model="data.gender"
                    />
                    <label class="custom-control-label" for="female"
                      >Female</label
                    >
                  </div>
                </div>
                <div class="invalid-feedback">
                  {{ validation.firstError("data.gender") }}
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <label for="dob">Date of Birth</label>
                <input
                  type="date"
                  class="form-control shadow-none"
                  id="dob"
                  :class="{ 'is-invalid': validation.hasError('data.dob') }"
                  v-model="data.dob"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.dob") }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="phone">
                  Cell Phone
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend3"
                      >+88</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    id="phone"
                    pattern="[0-9]*"
                    placeholder="e.g. 01xxxxxxxxx"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
                    :class="{
                      'is-invalid': validation.hasError('data.phone'),
                      'is-valid': data.phone,
                    }"
                    v-model="data.phone"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.phone") }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="email">
                  Email Address
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  id="email"
                  placeholder="e.g. yourname@mai.com"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. yourname@mail.com'"
                  :class="{
                    'is-invalid': validation.hasError('data.email'),
                    'is-valid': data.email,
                  }"
                  v-model="data.email"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.email") }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="work_engagement">Type of work Engagement</label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  id="work_engagement"
                  placeholder="e.g. work"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. work'"
                  :class="{
                    'is-invalid': validation.hasError('data.work_engagement'),
                    'is-valid': data.work_engagement,
                  }"
                  v-model="data.work_engagement"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.work_engagement") }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="academic_degree">
                  Highest Academic Degree achieved
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  id="academic_degree"
                  placeholder="e.g. BSc CSE"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. BSc CSE'"
                  :class="{
                    'is-invalid': validation.hasError('data.academic_degree'),
                    'is-valid': data.academic_degree,
                  }"
                  v-model="data.academic_degree"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.academic_degree") }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="yahoo_id">Yahoo ID (Optional)</label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  id="yahoo_id"
                  :class="{
                    'is-invalid': validation.hasError('data.yahoo_id'),
                    'is-valid': data.yahoo_id,
                  }"
                  placeholder="e.g. yourname@yahoo.com"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. yourname@yahoo.com'"
                  v-model="data.yahoo_id"
                />
              </div>
              <div class="col-md-6 mb-3">
                <label for="skype_id">Skype ID (Optional)</label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  id="skype_id"
                  :class="{
                    'is-invalid': validation.hasError('data.skype_id'),
                    'is-valid': data.skype_id,
                  }"
                  placeholder="e.g. your.id"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. your.id'"
                  v-model="data.skype_id"
                />
              </div>
              <div class="col-md-6 mb-3">
                <label for="google_id">Google ID (Optional)</label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  id="google_id"
                  :class="{
                    'is-invalid': validation.hasError('data.google_id'),
                    'is-valid': data.google_id,
                  }"
                  placeholder="e.g. yourname@gmail.com"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. yourname@gmail.com'"
                  v-model="data.google_id"
                />
                <div class="valid-feedback"></div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="msn_id">MSN ID (Optional)</label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  id="msn_id"
                  :class="{
                    'is-invalid': validation.hasError('data.msn_id'),
                    'is-valid': data.msn_id,
                  }"
                  placeholder="e.g. yourname@outlook.vom"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. yourname@outlook.com'"
                  v-model="data.msn_id"
                />
              </div>
              <div class="col-md-6 mb-3">
                <label for="fb_profile">Facebook profile URL</label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  id="fb_profile"
                  placeholder="e.g. fb.com/yourprofil"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. fb.com/yourprofile'"
                  :class="{
                    'is-invalid': validation.hasError('data.fb_profile'),
                    'is-valid': data.fb_profile,
                  }"
                  v-model="data.fb_profile"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.fb_profile") }}
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="freelancer_since"
                  >Working as freelancer since</label
                >
                <input
                  type="date"
                  class="form-control shadow-none"
                  id="freelancer_since"
                  :class="{
                    'is-invalid': validation.hasError('data.freelancer_since'),
                  }"
                  v-model="data.freelancer_since"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.freelancer_since") }}
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <label for="working_place">
                  Choose Working Place
                  <span class="text-danger">*</span>
                </label>
                <div class="row">
                  <div id="vision" class="col-md-12">
                    <div class="row">
                      <div class="col-md-7">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                            v-model="data.online_marketplace_checkbox"
                            v-on:click="seen1 = !seen1"
                          />
                          <label class="custom-control-label" for="customCheck1"
                            >Working at online Marketplace</label
                          >
                        </div>
                      </div>
                      <div class="col-md-5" v-if="seen1">
                        <input
                          type="text"
                          class="form-control shadow-none"
                          id="online_marketplace"
                          placeholder="e.g. freelance.com"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'e.g. freelance.com'"
                          :class="{
                            'is-invalid': validation.hasError(
                              'data.online_marketplace'
                            ),
                            'is-valid': data.online_marketplace,
                          }"
                          v-model="data.online_marketplace"
                        />
                        <div class="invalid-feedback">
                          {{ validation.firstError("data.online_marketplace") }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="vision" class="col-md-12">
                    <div class="row">
                      <div class="col-md-7">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck2"
                            v-model="data.international_clients_checkbox"
                            v-on:click="seen = !seen"
                          />
                          <label class="custom-control-label" for="customCheck2"
                            >Working directly with International Clients</label
                          >
                        </div>
                      </div>
                      <div class="col-md-5" v-if="seen">
                        <input
                          type="text"
                          class="form-control shadow-none"
                          id="international_clients"
                          placeholder="e.g. Nogor USA"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'e.g. Nogor USA'"
                          :class="{
                            'is-invalid': validation.hasError(
                              'data.international_clients'
                            ),
                            'is-valid': data.international_clients,
                          }"
                          v-model="data.international_clients"
                        />
                        <div class="invalid-feedback">
                          {{
                            validation.firstError("data.international_clients")
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <label for="working_on">Working on (Expertise on)</label>
                <textarea
                  rows="5"
                  v-model="data.working_on"
                  :class="{
                    'is-invalid': validation.hasError('data.working_on'),
                    'is-valid': data.working_on,
                  }"
                  placeholder="e.g. IT"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. IT'"
                  class="form-control shadow-none"
                ></textarea>
                <div class="invalid-feedback">
                  {{ validation.firstError("data.working_on") }}
                </div>
              </div>
              <!-- <div class="col-md-12 mb-3">
                <label for="payment_method">Payment Method</label>
                <select
                  v-model="data.payment_method"
                  class="form-control"
                  :class="{'is-invalid': validation.hasError('data.payment_method'),'is-valid': data.payment_method}"
                >
                  <option selected value="null">Choose...</option>
                  <option>Bank Account deposit</option>
                  <option>BKash</option>
                  <option>SSL Commerce</option>
                </select>
                <div class="invalid-feedback">{{ validation.firstError('data.payment_method') }}</div>
              </div>-->
              <div class="col-md-12 mb-3">
                <label for="is_confirm"></label>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="is_confirm"
                      :class="{
                        'is-invalid': validation.hasError('data.is_confirm'),
                      }"
                      v-model="data.is_confirm"
                      class="custom-control-input"
                      value
                    />
                    <label class="custom-control-label" for="is_confirm">
                      I confirm that the information provided above, and in any
                      attachment, are correct and I understand that any false
                      statement or information could result in my application
                      being terminated.
                    </label>
                  </div>
                </div>
                <div class="invalid-feedback">
                  {{ validation.firstError("data.is_confirm") }}
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary"
              type="submit"
              :disabled="validation.countErrors() > 0"
            >
              Submit
            </button>
          </form>
        </div>

        <!-- <pre>{{data}}</pre> -->
      </div>
    </div>
    <right-side-scb></right-side-scb>
  </div>
  <div v-else-if="member.info && member.info.status == 0" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! your account is suspend
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RightSideScb from "../../../../../components/Frontend/partials/member/RightSideScb";

import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);

export default {
  name: "AccountOfCompany",
  components: {
    RightSideScb,
  },
  data() {
    return {
      title: "Account Of Company",
      submitted: false,
      seen: false,
      seen1: false,
      data: {
        payment_method: null,
        gender: "Male",
      },
      member: {
        info: null,
      },
      text: "",
      errors: {},
    };
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },

  methods: {
    submit() {
      this.data.form_type = this.$route.name;
      this.$validate().then((res) => {
        if (this.validation.countErrors() > 0) {
          this.$toastr(
            "warning",
            'You need to fill <span class="font-weight-bold">' +
              this.validation.countErrors() +
              " more empty</span> mandatory fields",
            "Empty Mandatory Fields!!"
          );
        } else {
          if (res) {
            this.$parent.loading = true;
            axios
              .post("/member/store-company-info", this.data)
              .then((respons) => {
                console.log(respons.data);
              })
              .catch((error) => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                  this.focus_field = Object.keys(this.errors)[0];
                }
              })
              .then((alw) => {
                this.$parent.loading = false;
              });
          }
        }
      });
    },
  },
  created() {
    this.loggedIn();
    setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);
  },
  validators: {
    "data.applicant_name": function (value = null) {
      return Validator.value(value)
        .required("Applicant name is required")
        .minLength(2)
        .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.");
    },
    "data.mailing_address": function (value = null) {
      return Validator.value(value).required("Mailing Address is required");
    },

    "data.permanent_address": function (value = null) {
      return Validator.value(value).required("Permanent Address is required");
    },
    "data.gender": function (value = null) {
      return Validator.value(value).required("Gender is required");
    },
    "data.dob": function (value = null) {
      //   return Validator.value(value).required();
    },
    "data.email": function (value = null) {
      return Validator.value(value).required("Email address required").email();
    },
    "data.phone": function (value = null) {
      return Validator.value(value)
        .required("Cell phone number required")
        .digit()
        .regex("01+[0-9]*$", "Must start with 01.")
        .minLength(11)
        .maxLength(11);
    },
    "data.work_engagement": function (value = null) {
      return Validator.value(value);
    },
    "data.academic_degree": function (value = null) {
      return Validator.value(value)
        .required("Degree required")
        .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.");
    },
    "data.fb_profile": function (value = null) {
      return Validator.value(value).url();
    },
    "data.yahoo_id": function (value = null) {
      return Validator.value(value);
    },
    "data.working_on": function (value = null) {
      return Validator.value(value).regex(
        "^[a-zA-Z_ ]*$",
        "Must only contain alphabetic characters."
      );
    },
    "data.is_confirm": function (value = null) {
      return Validator.value(value).required("Confirmation is required");
    },
    "data.online_marketplace": function (value = null) {
      if ($("#customCheck1").prop("checked") == true) {
        // alert(value);
        return Validator.value(value).required(" is required");
      } else {
        //alert("Skip");
      }
      //return Validator.value(value).required(" is required");
    },
    "data.international_clients": function (value = null) {
      if ($("#customCheck2").prop("checked") == true) {
        // alert(value);
        return Validator.value(value).required(" is required");
      } else {
        //alert("Skip");
      }
      //return Validator.value(value).required(" is required");
    },
  },
};
</script>

<style >
.invalid-feedback {
  display: block !important;
}
</style>
