<template>
	<div class="contact">
		<PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
		<div class="container-wrapper">
			<div class="content-body py-4">
				<div class="row">
					<div class="col-sm-7">
						<div class="card card-body shadow-lg rounded-10">
							<span class="shape"></span>
							<div class="content-header mb-1">
								<h4>Contact Us</h4>
								<p>
									“Got a Question? We'd love to hear from you. Send us a message
									and we'll respond as soon as possible”
								</p>
							</div>
							<form
								v-on:submit.prevent="submit"
								class="form-row"
								v-if="!$root.spinner"
							>
								<div class="form-group col-sm-6">
									<label for class="col-form-label-sm m-0">Name</label>
									<input
										v-model="data.name"
										type="text"
										name="name"
										id="name"
										placeholder="Name"
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError('data.name'),
											'is-valid': data.name,
										}"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.name") }}
									</div>
								</div>
								<div class="form-group col-sm-6">
									<label for class="col-form-label-sm m-0">Email</label>
									<input
										v-model="data.email"
										type="email"
										name="email"
										id="email"
										placeholder="Email"
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError('data.email'),
											'is-valid': data.email,
										}"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.email") }}
									</div>
								</div>
								<div class="form-group col-sm-6">
									<label for="cell_no" class="col-form-label-sm m-0"
										>Cell No:</label
									>
									<input
										v-model="data.phone"
										type="text"
										name="phone"
										id="phone"
										placeholder="Cell No:"
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError('data.phone'),
											'is-valid': data.phone,
										}"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("data.phone") }}
									</div>
								</div>
								<div class="form-group col-sm-6">
									<label for="department" class="col-form-label-sm m-0"
										>Looking for</label
									>
									<select
										v-model="data.department"
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError('data.department'),
											'is-valid': data.department,
										}"
									>
										<option value selected>--Select--</option>
										<option>Secretariat</option>
										<option>Local Market</option>
										<option>International Market</option>
									</select>
									<div class="invalid-feedback">
										{{ validation.firstError("data.department") }}
									</div>
								</div>
								<div class="form-group col-sm-12">
									<label for class="col-form-label-sm m-0">Message</label>
									<textarea
										v-model="data.message"
										rows="4"
										name="message"
										id="message"
										placeholder="Type your message"
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError('data.message'),
											'is-valid': data.message,
										}"
									></textarea>
									<div class="invalid-feedback">
										{{ validation.firstError("data.message") }}
									</div>
								</div>
								<!-- <div class="form-group col-sm-6">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="robo" />
                    <label class="custom-control-label" for="robo">I am not a robot</label>
                  </div>
                </div>-->
								<div class="form-group col-sm-7 text-right">
									<button type="submit" class="btn btn-primary">
										Send
										<i class="mdi mdi-send mdi-rotate-315"></i>
									</button>
								</div>
							</form>
							<spinner v-if="$root.spinner" />
						</div>

						<!-- MAP -->
						<div class="mt-5">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1085.719976776338!2d90.3925894753755!3d23.750512108067536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8a2ac98817b%3A0xf48ca75c1bdfc0f2!2sBangladesh%20Association%20of%20Software%20and%20Information%20Services%20(BASIS)!5e0!3m2!1sen!2sbd!4v1585046088879!5m2!1sen!2sbd"
								width="100%"
								height="300"
								frameborder="0"
								style="border: 0"
								allowfullscreen
							></iframe>
						</div>
					</div>
					<div class="col-sm-5">
						<div class="card-body">
							<h3>Visit our office at</h3>
							<p>
								<i class="mdi mdi-google-maps mdi-18px"></i> BDBL Bhaban (Level
								5 - West), 12 Kawran Bazar, Dhaka -1215
							</p>
							<h3 class="mt-4">Contact us</h3>

							<div class="row">
								<div class="col-12 col-lg-6 mb-4 pr-0">
									<div
										class="rounded p-2"
										style="
											font-size: 13px;
											min-height: 125px;
											border: 2px solid #6abfa9;
										"
									>
										<h5 style="text-decoration: underline">
											BASIS Secretariat
										</h5>
										<p><i class="mdi mdi-phone mdi-16px"></i> 16488</p>
										<p>
											<i class="mdi mdi-email mdi-16px"></i> info@basis.org.bd
										</p>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-4 pr-0">
									<div
										class="rounded p-2"
										style="
											font-size: 13px;
											min-height: 125px;
											border: 2px solid #6abfa9;
										"
									>
										<h5 style="text-decoration: underline">
											Membership / Service
										</h5>
										<p><i class="mdi mdi-phone mdi-16px"></i> 16488</p>
										<p><i class="mdi mdi-phone mdi-16px"></i> 16488</p>
										<p>
											<i class="mdi mdi-email mdi-16px"></i>
											service@basis.org.bd
										</p>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-4 pr-0">
									<div
										class="rounded p-2"
										style="
											font-size: 13px;
											min-height: 125px;
											border: 2px solid #6abfa9;
										"
									>
										<h5 style="text-decoration: underline">Cash Incentive</h5>
										<p>
											<i class="mdi mdi-phone mdi-16px"></i> 16488 (Ex: 127)
										</p>
										<!-- <p>
                      <i class="mdi mdi-phone mdi-16px"></i> 16488
                      (Ex: 129)
                    </p> -->
										<p>
											<i class="mdi mdi-email mdi-16px"></i>
											incentive2@basis.org.bd
										</p>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-4 pr-0">
									<div
										class="rounded p-2"
										style="
											font-size: 13px;
											min-height: 125px;
											border: 2px solid #6abfa9;
										"
									>
										<h5 style="text-decoration: underline">STP1</h5>
										<p>
											<i class="mdi mdi-phone mdi-16px"></i> 16488 (Ex: 125)
										</p>
										<p>
											<i class="mdi mdi-phone mdi-16px"></i> 16488 (Ex: 121)
										</p>
										<p>
											<i class="mdi mdi-email mdi-16px"></i> stp1@basis.org.bd
										</p>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-4 pr-0">
									<div
										class="rounded p-2"
										style="
											font-size: 13px;
											min-height: 125px;
											border: 2px solid #6abfa9;
										"
									>
										<h5 style="text-decoration: underline">BITM Training</h5>
										<p>
											<i class="mdi mdi-phone mdi-16px"></i> +88 096 12342486 
											(Ext:201)
										</p>
										<p>
											<i class="mdi mdi-phone mdi-16px"></i> +88 01810187044
										</p>
										<p>
											<i class="mdi mdi-email mdi-16px"></i> info@bitm.org.bd
										</p>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-4 pr-0">
									<div
										class="rounded p-2"
										style="
											font-size: 13px;
											min-height: 125px;
											border: 2px solid #6abfa9;
										"
									>
										<h5 style="text-decoration: underline">Events</h5>
										<p>
											<i class="mdi mdi-phone mdi-16px"></i> 16488 (Ext:111)
										</p>
										<p>
											<i class="mdi mdi-email mdi-16px"></i> event@basis.org.bd
										</p>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-4 pr-0">
									<div
										class="rounded p-2"
										style="
											font-size: 13px;
											min-height: 125px;
											border: 2px solid #6abfa9;
										"
									>
										<h5 style="text-decoration: underline">Accounts</h5>
										<p>
											<i class="mdi mdi-phone mdi-16px"></i> 16488 (Ext:104)
										</p>
										<p>
											<i class="mdi mdi-email mdi-16px"></i>
											accountant@basis.org.bd
										</p>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-4 pr-0">
									<div
										class="rounded p-2"
										style="
											font-size: 13px;
											min-height: 125px;
											border: 2px solid #6abfa9;
										"
									>
										<h5 style="text-decoration: underline">
											International Market
										</h5>
										<p>
											<i class="mdi mdi-phone mdi-16px"></i> 16488 (Ext:114)
										</p>
										<p>
											<i class="mdi mdi-email mdi-16px"></i>
											international@basis.org.bd
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import PageBanner from "../../components/elements/PageBanner";
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);

	const breadcumb = [
		{
			title: "Contact us",
		},
	];
	export default {
		components: {
			PageBanner,
		},
		data() {
			return {
				success: false,
				data: { department: "" },
				breadcrumbTitle: "Contact us",
				breadcumb: [{ title: "Contact us" }],
			};
		},

		methods: {
			submit: function (e) {
				this.scrollTopPagination();
				this.$validate().then((res) => {
					if (res) {
						this.$root.spinner = true;
						axios
							.post("contact", this.data)
							.then((response) => {
								this.data = { department: "" };
								this.success = true;
								this.$toastr("success", "Message Sent", "Success!!");
								this.$root.spinner = false;
							})
							.catch((error) => {
								if (error.response.status === 422) {
									this.errors = error.response.data.errors || {};
									this.focus_field = Object.keys(this.errors)[0];
									this.$root.spinner = false;
								}
							});
					}
				});
			},
		},
		mounted() {
			this.$root.loading = false;
		},
		validators: {
			"data.name": function (value = null) {
				if (!this.success) {
					return Validator.value(value).required("Name is required");
				}
			},
			"data.email": function (value = null) {
				if (!this.success) {
					return Validator.value(value)
						.required("Email is required")
						.email("Email must be well formatted");
				}
			},
			"data.phone": function (value = null) {
				if (!this.success) {
					return Validator.value(value).required("Cell No is required");
				}
			},
			"data.message": function (value = null) {
				if (!this.success) {
					return Validator.value(value).required("Message is required");
				}
			},
			"data.department": function (value = null) {
				if (!this.success) {
					return Validator.value(value).required("Department is required");
				}
			},
		},
	};
</script>

