<template>
	<div class="popup-modal">
		<b-modal
			id="modal-center"
			centered
			visible
			ref="my-modal"
			hide-footer
			size="lg"
			v-if="popupImage[0].popup_image"
		>
			<div class="d-block text-center">
				<spinner v-if="$root.spinner" />
				<div v-for="(popup, key) in popupImage" :key="key">
					<h2 class="product-title">{{ popup.title }}</h2>
					<div class="image" v-if="!$root.spinner">
						<b-img
							class="product-image"
							:src="imageUrl(popupImage[0].popup_image)"
							:alt="popup.title"
						></b-img>
					</div>

					<b-button
						class="mt-2 modal-button-new"
						variant="outline-success"
						@click="linkClick(popup.url_link)"
						>View details</b-button
					>

					<b-button
						class="mt-2 modal-button"
						variant="outline-danger"
						@click="hideModal"
						>Close Me</b-button
					>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
	export default {
		name: "HomeModal",
		data() {
			return {
				popupImage: [],
				popup: [],
			};
		},

		methods: {
			async get_data() {
				this.$root.spinner = true;
				await axios.get("/api/active-popup").then((res) => {
					var ndata = res.data; //JSON.parse(JSON.stringify(res.data.data));
					this.popup = res.data;
					this.popupImage = ndata;
					this.$root.spinner = false;
					console.log(ndata);
				});
			},
			imageUrl(imagePath) {
				return this.$root.baseurl + "/public/" + imagePath;
			},
			linkClick(url) {
				window.open(url, "_blank");
			},
			hideModal() {
				this.$refs["my-modal"].hide();
			},
		},
		mounted() {
			this.get_data();
			this.$refs["my-modal"].show();
		},
	};
</script>

<style scoped>
	.modal-button-view {
		color: #6dc8be;
	}
	.btn-outline-success {
		border: 2px solid #6dc8be;
	}
	.btn-outline-success:hover {
		background: #6dc8be !important;
		border: 2px solid #6dc8be !important;
	}
	.modal-button {
		color: #ff0000;
	}
	.btn-outline-danger {
		border: 2px solid #ff0000;
	}
	.btn-outline-danger:hover {
		background: #ff0000 !important;
		border: 2px solid #ff0000 !important;
	}
	.modal-button-new {
		color: #6dc8be;
	}

	@media (max-width: 576px) {
		.product-title {
			font-size: 20px !important;
		}
	}
</style>