var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$parent.loading && _vm.member.info && _vm.member.info.status == 1)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"card shadow-sm"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body"},[(!_vm.showServiceForm)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Warning!")]),_vm._v(" "),_c('p',[_vm._v("This service is not availabe")]),_vm._v(" "),_c('hr')]):_vm._e(),_vm._v(" "),(_vm.showServiceForm)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.service_id),expression:"data.service_id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.data.service_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "service_id", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12 mb-3"},[_vm._m(1),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.name_organization),expression:"data.name_organization"}],staticClass:"form-control shadow-none",class:{
                    'is-invalid': _vm.validation.hasError('data.name_organization'),
                    'is-valid': _vm.data.name_organization,
                  },attrs:{"type":"text","id":"name_organization","placeholder":"e.g. Nogor","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. Nogor'"},domProps:{"value":(_vm.data.name_organization)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "name_organization", $event.target.value)}}}),_vm._v(" "),(_vm.errors && _vm.errors.name_organization)?_c('small',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.errors.name_organization[0]))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.name_organization")))])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_vm._m(2),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.mailing_address),expression:"data.mailing_address"}],staticClass:"form-control shadow-none",class:{
                    'is-invalid': _vm.validation.hasError('data.mailing_address'),
                    'is-valid': _vm.data.mailing_address,
                  },attrs:{"rows":"5","placeholder":"e.g. 221b, Baker Street, London","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. 221b, Baker Street, London'"},domProps:{"value":(_vm.data.mailing_address)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "mailing_address", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.mailing_address")))])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{attrs:{"for":"permanent_address"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tPermanent address of the Applicant Company\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.permanent_address),expression:"data.permanent_address"}],staticClass:"form-control shadow-none",class:{
                    'is-invalid': _vm.validation.hasError('data.permanent_address'),
                    'is-valid': _vm.data.permanent_address,
                  },attrs:{"rows":"5","placeholder":"e.g. 221b, Baker Street, London","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. 221b, Baker Street, London'"},domProps:{"value":(_vm.data.permanent_address)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "permanent_address", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.permanent_address")))])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_vm._m(3),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('Datepicker',{staticClass:"form-control shadow-none",class:{
                      'is-invalid': _vm.validation.hasError(
                        'data.year_of_establishment'
                      ),
                      'is-valid': _vm.data.year_of_establishment,
                    },attrs:{"id":"year_of_establishment","placeholder":"e.g. 12-12-2022.","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. 12-12-2022'"},model:{value:(_vm.data.year_of_establishment),callback:function ($$v) {_vm.$set(_vm.data, "year_of_establishment", $$v)},expression:"data.year_of_establishment"}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.year_of_establishment")))])],1)]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.no_of_employees),expression:"data.no_of_employees"}],staticClass:"form-control shadow-none",class:{
                      'is-invalid': _vm.validation.hasError('data.no_of_employees'),
                      'is-valid': _vm.data.no_of_employees,
                    },attrs:{"type":"text","id":"no_of_employees","placeholder":"e.g. 15","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. 15'"},domProps:{"value":(_vm.data.no_of_employees)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "no_of_employees", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.no_of_employees")))])])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_vm._m(5),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.name_representative),expression:"data.name_representative"}],staticClass:"form-control shadow-none",class:{
                      'is-invalid': _vm.validation.hasError(
                        'data.name_representative'
                      ),
                      'is-valid': _vm.data.name_representative,
                    },attrs:{"type":"text","id":"name_representative","placeholder":"e.g. Jane Doe","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. Jane Doe'"},domProps:{"value":(_vm.data.name_representative)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "name_representative", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.name_representative")))])])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_vm._m(6),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.designation_representative),expression:"data.designation_representative"}],staticClass:"form-control shadow-none",class:{
                      'is-invalid': _vm.validation.hasError(
                        'data.designation_representative'
                      ),
                      'is-valid': _vm.data.designation_representative,
                    },attrs:{"type":"text","id":"designation_representative","placeholder":"e.g. CEO","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. CEO'"},domProps:{"value":(_vm.data.designation_representative)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "designation_representative", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.validation.firstError("data.designation_representative"))+"\n\t\t\t\t\t\t\t\t\t")])])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_vm._m(7),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],staticClass:"form-control shadow-none",class:{
                    'is-invalid': _vm.validation.hasError('data.email'),
                    'is-valid': _vm.data.email,
                  },attrs:{"type":"text","id":"email","placeholder":"e.g. yourname@mail.com","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. yourname@mail.com'"},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "email", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.email")))])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tCell Phone\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"input-group"},[_vm._m(8),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.phone),expression:"data.phone"}],staticClass:"form-control shadow-none",class:{
                      'is-invalid': _vm.validation.hasError('data.phone'),
                      'is-valid': _vm.data.phone,
                    },attrs:{"type":"text","id":"phone","placeholder":"e.g. 01xxxxxxxxx","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. 01xxxxxxxxx'"},domProps:{"value":(_vm.data.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "phone", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.phone")))])])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_vm._m(9),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.trade_license),expression:"data.trade_license"}],staticClass:"form-control shadow-none",class:{
                    'is-invalid': _vm.validation.hasError('data.trade_license'),
                    'is-valid': _vm.data.trade_license,
                  },attrs:{"type":"text","id":"trade_license","placeholder":"e.g. 12345","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. 12345'"},domProps:{"value":(_vm.data.trade_license)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "trade_license", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.trade_license")))])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{attrs:{"for":"rjsc_registation"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tRegistration Number (RJSC)\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.registration_number),expression:"data.registration_number"}],staticClass:"form-control shadow-none",class:{
                    'is-invalid': _vm.validation.hasError(
                      'data.registration_number'
                    ),
                    'is-valid': _vm.data.registration_number,
                  },attrs:{"type":"text","id":"registration_number","placeholder":"e.g. 12345","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. 12345'"},domProps:{"value":(_vm.data.registration_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "registration_number", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.registration_number")))])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{attrs:{"for":"business_sector"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tBusiness Sector / Expertise on\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.business_sector),expression:"data.business_sector"}],staticClass:"form-control shadow-none",class:{
                    'is-invalid': _vm.validation.hasError('data.business_sector'),
                    'is-valid': _vm.data.business_sector,
                  },attrs:{"type":"text","id":"business_sector","placeholder":"e.g. IT","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. IT'"},domProps:{"value":(_vm.data.business_sector)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "business_sector", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.business_sector")))])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{attrs:{"for":"offshore_development"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tPerforming Offshore development for\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.offshore_development),expression:"data.offshore_development"}],staticClass:"form-control shadow-none",class:{
                    'is-invalid': _vm.validation.hasError(
                      'data.offshore_development'
                    ),
                    'is-valid': _vm.data.offshore_development,
                  },attrs:{"type":"text","id":"offshore_development","placeholder":"e.g. demo company","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. demo company'"},domProps:{"value":(_vm.data.offshore_development)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "offshore_development", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.offshore_development")))])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{attrs:{"for":"major_countries"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tMajor five exporting countries\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.major_countries1),expression:"data.major_countries1"}],staticClass:"form-control shadow-none",class:{
                        'is-invalid': _vm.validation.hasError(
                          'data.major_countries1'
                        ),
                        'is-valid': _vm.data.major_countries1,
                      },attrs:{"type":"text","id":"major_countries1","placeholder":"1."},domProps:{"value":(_vm.data.major_countries1)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "major_countries1", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"})]),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.major_countries2),expression:"data.major_countries2"}],staticClass:"form-control shadow-none",class:{
                        'is-invalid': _vm.validation.hasError(
                          'data.major_countries2'
                        ),
                        'is-valid': _vm.data.major_countries2,
                      },attrs:{"type":"text","id":"major_countries2","placeholder":"2."},domProps:{"value":(_vm.data.major_countries2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "major_countries2", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"})]),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.major_countries3),expression:"data.major_countries3"}],staticClass:"form-control shadow-none",class:{
                        'is-invalid': _vm.validation.hasError(
                          'data.major_countries3'
                        ),
                        'is-valid': _vm.data.major_countries3,
                      },attrs:{"type":"text","id":"major_countries3","placeholder":"3."},domProps:{"value":(_vm.data.major_countries3)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "major_countries3", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"})]),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.major_countries4),expression:"data.major_countries4"}],staticClass:"form-control shadow-none",class:{
                        'is-invalid': _vm.validation.hasError(
                          'data.major_countries4'
                        ),
                        'is-valid': _vm.data.major_countries4,
                      },attrs:{"type":"text","id":"major_countries4","placeholder":"4."},domProps:{"value":(_vm.data.major_countries4)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "major_countries4", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"})]),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.major_countries5),expression:"data.major_countries5"}],staticClass:"form-control shadow-none",class:{
                        'is-invalid': _vm.validation.hasError(
                          'data.major_countries5'
                        ),
                        'is-valid': _vm.data.major_countries5,
                      },attrs:{"type":"text","id":"major_countries5","placeholder":"5."},domProps:{"value":(_vm.data.major_countries5)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "major_countries5", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"})])])]),_vm._v(" "),_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{attrs:{"for":"domain_outsourcing"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tMajor five domain in outsourcing\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.domain_outsourcing1),expression:"data.domain_outsourcing1"}],staticClass:"form-control shadow-none",class:{
                        'is-invalid': _vm.validation.hasError(
                          'data.domain_outsourcing1'
                        ),
                        'is-valid': _vm.data.domain_outsourcing1,
                      },attrs:{"type":"text","id":"domain_outsourcing1","placeholder":"1."},domProps:{"value":(_vm.data.domain_outsourcing1)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "domain_outsourcing1", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"})]),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.domain_outsourcing2),expression:"data.domain_outsourcing2"}],staticClass:"form-control shadow-none",class:{
                        'is-invalid': _vm.validation.hasError(
                          'data.domain_outsourcing2'
                        ),
                        'is-valid': _vm.data.domain_outsourcing2,
                      },attrs:{"type":"text","id":"domain_outsourcing2","placeholder":"2."},domProps:{"value":(_vm.data.domain_outsourcing2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "domain_outsourcing2", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"})]),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.domain_outsourcing3),expression:"data.domain_outsourcing3"}],staticClass:"form-control shadow-none",class:{
                        'is-invalid': _vm.validation.hasError(
                          'data.domain_outsourcing3'
                        ),
                        'is-valid': _vm.data.domain_outsourcing3,
                      },attrs:{"type":"text","id":"domain_outsourcing3","placeholder":"3."},domProps:{"value":(_vm.data.domain_outsourcing3)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "domain_outsourcing3", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"})]),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.domain_outsourcing4),expression:"data.domain_outsourcing4"}],staticClass:"form-control shadow-none",class:{
                        'is-invalid': _vm.validation.hasError(
                          'data.domain_outsourcing4'
                        ),
                        'is-valid': _vm.data.domain_outsourcing4,
                      },attrs:{"type":"text","id":"domain_outsourcing4","placeholder":"4."},domProps:{"value":(_vm.data.domain_outsourcing4)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "domain_outsourcing4", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"})]),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.domain_outsourcing5),expression:"data.domain_outsourcing5"}],staticClass:"form-control shadow-none",class:{
                        'is-invalid': _vm.validation.hasError(
                          'data.domain_outsourcing5'
                        ),
                        'is-valid': _vm.data.domain_outsourcing5,
                      },attrs:{"type":"text","id":"domain_outsourcing5","placeholder":"5."},domProps:{"value":(_vm.data.domain_outsourcing5)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "domain_outsourcing5", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"})])])]),_vm._v(" "),_c('div',{staticClass:"col-md-12 mb-3"},[_c('label',{attrs:{"for":"is_confirm"}}),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.is_confirm),expression:"data.is_confirm"}],staticClass:"custom-control-input",class:{
                        'is-invalid': _vm.validation.hasError('data.is_confirm'),
                      },attrs:{"type":"checkbox","id":"is_confirm","unchecked-value":false},domProps:{"value":true,"checked":Array.isArray(_vm.data.is_confirm)?_vm._i(_vm.data.is_confirm,true)>-1:(_vm.data.is_confirm)},on:{"change":function($event){var $$a=_vm.data.is_confirm,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=true,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.data, "is_confirm", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.data, "is_confirm", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.data, "is_confirm", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",attrs:{"for":"is_confirm"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\tI confirm that the information provided above, and in any\n\t\t\t\t\t\t\t\t\t\t\tattachment, are correct and I understand that any false\n\t\t\t\t\t\t\t\t\t\t\tstatement or information could result in my application\n\t\t\t\t\t\t\t\t\t\t\tbeing terminated.\n\t\t\t\t\t\t\t\t\t\t")])])]),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.is_confirm")))])])]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.validation.countErrors() > 0}},[_vm._v("Submit")])]):_vm._e()])])]),_vm._v(" "),_c('right-side-scb')],1):(_vm.member.info && _vm.member.info.status == 0)?_c('div',{staticClass:"row"},[_vm._m(10)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-transparent border-0"},[_c('h4',{staticClass:"text-center"},[_vm._v("Application Form for SCB ERQ Account for Company")]),_vm._v(" "),_c('p',{staticClass:"font-size-md text-center desc-m"},[_vm._v("\n\t\t\t\t\t\tProfessionals involved in outsourced service exports, will be\n\t\t\t\t\t\toffered from Standard Chartered a bundle offer of a Taka Savings\n\t\t\t\t\t\tAccount and an US Dollar ERQ (Exporter Retention Quota) account.\n\t\t\t\t\t\tIndividuals, firms, and professionals involved in service exports in\n\t\t\t\t\t\tnon-physical from such as outsourcing, BPO, business services,\n\t\t\t\t\t\tprofessional, research and advisory services etc.\n\t\t\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name_organization"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tName of the Company\n\t\t\t\t\t\t\t\t\t"),_c('span',{staticClass:"text-danger requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"mailing_address"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tMailing Address of the Applicant Company\n\t\t\t\t\t\t\t\t\t"),_c('span',{staticClass:"text-danger requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"y_o_establishment"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tYear of Establishment\n\t\t\t\t\t\t\t\t\t"),_c('span',{staticClass:"text-danger requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"noc_employees"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tNumber of current employees\n\t\t\t\t\t\t\t\t\t"),_c('span',{staticClass:"text-danger requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name_representative"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tName of Representative\n\t\t\t\t\t\t\t\t\t"),_c('span',{staticClass:"text-danger requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"designation_representative"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tDesignation of Representative\n\t\t\t\t\t\t\t\t\t"),_c('span',{staticClass:"text-danger requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"email"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tEmail Address\n\t\t\t\t\t\t\t\t\t"),_c('span',{staticClass:"text-danger requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"inputGroupPrepend3"}},[_vm._v("+88")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"trade_license"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tTrade License Number\n\t\t\t\t\t\t\t\t\t"),_c('span',{staticClass:"text-danger requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"text-center text-danger"},[_vm._v("Sorry!! your account is suspend")])])])])
}]

export { render, staticRenderFns }