<template>
  <div class="col-lg-3">
    <div class="card shadow-sm mb-3">
      <div class="card-header">
        <div class="row bg-ash rounded-sm">
          <div class="col-md-12 px-0">
            <div class="row m-0 header-bg">
              <h6 class="m-auto">Check List</h6>
            </div>
          </div>
          <div class="col-md-12">
            <p
              class="font-weight-bold font-size-md mt-1"
            >For ERQ Account company must submit the below documents:</p>
            <ul class="fontsize-sm pl-3">
              <li>
                <p class="mb-0">Duly filled up form</p>
              </li>
              <li>
                <p class="mb-0">Photocopy or scanned copy of updated Trade License</p>
              </li>
              <li>
                <p class="mb-0">Photocopy or scanned copy of NID and passport of Company owner</p>
              </li>
              <li>
                <p class="mb-0">2 copies of passport size photo of Company owner</p>
              </li>
              <li>
                <p class="mb-0">Proof of outsource work and income</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow-sm">
      <div class="card-header">
        <div class="row mt-4 bg-ash rounded-sm">
          <div class="col-md-12 px-0">
            <div class="row m-0 header-bg">
              <h6 class="m-auto">Documentation and Instructions</h6>
            </div>

            <div class="row pl-3 m-auto">
              <p class="font-weight-bold font-size-md">
                Application Submission Mode:
                <span class="font-weight-light"></span>(you can submit the application through following process)
              </p>

              <ol class="fontsize-sm pl-3">
                <li>
                  <p
                    class="mb-0"
                  >You can submit the hard copy application form along with the necessary documents and payment at BASIS office.</p>
                </li>
                <li>
                  <p class="mb-0">
                    You can email us the form and necessary documents at
                    <strong>erq@basis.org.bd</strong>
                  </p>
                </li>
                <li>
                  <p class="mb-0">
                    You can send us the form and necessary documents by courier.
                    <br />
                    <strong>Mailing Address: BDBL Bhaban, 12 Kawran Bazar (5th floor-west), Dhaka-1215; Contact # 01191016000</strong>
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightSideScb"
};
</script>
