import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

let cart = window.localStorage.getItem('cart');
let cartCount = window.localStorage.getItem('cartCount');
let couponInfo = window.localStorage.getItem('couponInfo');

export default new Vuex.Store({
    state: {
        cart: cart ? JSON.parse(cart) : [],
        cartCount: cartCount ? parseInt(cartCount) : 0,
        countWishList: 0,
        wishLists: [],
        couponInfo: couponInfo ? JSON.parse(couponInfo) : {
            coupon_id: "",
            coupon: "",
            total_price: 0.0
        },
    },
    mutations: {
        addToCart(state, item) {

            let found = state.cart.find(product => (product.id == item.id && product.size_select == item.size_select && product.color_select == item.color_select));


            if (found) {
                if (item.update == "increment") {
                    found.qty += 1;
                    found.totalPrice = parseInt(found.qty) * parseInt(item.price);
                }
                else if (item.update == "decrement") {
                    found.qty -= 1;
                    found.totalPrice = parseInt(found.qty) * parseInt(item.price);
                } else if (item.update == "edit") {
                    if (item.color_select) {
                        found.color_select = item.color_select;
                    }
                    if (item.size_select) {
                        found.size_select = item.size_select;
                    }
                    if (item.total_cart) {
                        found.qty = item.total_cart;
                    }
                    if (item.price) {
                        found.price = item.price;
                        found.totalPrice = parseInt(found.qty) * parseInt(item.price);
                    }
                }
                else {
                    found.qty += 1;
                    found.totalPrice = parseInt(found.qty) * parseInt(item.price);
                    if (item.color_select) {
                        found.color_select = item.color_select;
                    }
                    if (item.size_select) {
                        found.size_select = item.size_select;
                    }
                }

            } else {
                //console.log(item)
                item.qty = item.total_cart;
                state.cart.push(item);
                Vue.set(item, 'qty', parseInt(item.qty));
                Vue.set(item, 'totalPrice', (item.price * parseInt(item.qty)));
            }

            state.cartCount = Object.keys(state.cart).length;
            this.commit('saveCart');
        },
        removeFromCart(state, items) {
            let total = 0;
            state.cart.forEach(item => {
                let index = state.cart.indexOf(item);
                if (item.id == items.id) {
                    state.cart.splice(index, 1);
                } else {
                    total++;
                }

                state.cartCount = total;
                this.commit('saveCart');
            });
        },
        emptyToCart(state) {
            state.cart = [];
            state.cartCount = 0;
            localStorage.removeItem('cart')
            localStorage.removeItem('cartCount')
        },
        saveCart(state) {
            window.localStorage.setItem('cart', JSON.stringify(state.cart));
            window.localStorage.setItem('cartCount', state.cartCount);
        },
        getWishList(state, payload) {
            state.wishLists = payload.data;
            state.countWishList = Object.keys(state.wishLists).length;
        },
        couponInformations(state, payload) {
            state.couponInfo = payload;
            window.localStorage.setItem('couponInfo', JSON.stringify(state.couponInfo));
        },
        removeCoupntInfo(state) {
            window.localStorage.removeItem('couponInfo');
            state.couponInfo = {}
        },

    },
    actions: {
        countWishList(context) {
            axios.get('ecommerce/get-product-wishlist').then(res => {
                context.commit("getWishList", res);
            }).catch(error => { console.log(error) })
        }
    }
});
