<template>
	<div v-if="!$parent.loading && member.info && member.info.status == 1" class="row">
		<div class="col-lg-12">
			<div class="card shadow-sm">
				<div class="card-header bg-transparent">
					<h5 class="text-uppercase font-weight-bold px-3 mb-0">Cash Incentive</h5>
				</div>
				<div class="card-body">
					<div class="alert alert-danger" role="alert" v-if="!showServiceForm">
						<h4 class="alert-heading">Warning!</h4>
						<p>This service is not availabe</p>
						<hr />
					</div>
					<form v-on:submit.prevent="submit" id="form" v-if="showServiceForm">
						<div class="form-row">
							<div class="form-group col-lg-3">
								<label class="control-label">Company Name</label>
								<input
									type="text"
									v-if="this.memberInfo"
									v-model="this.memberInfo.company_name"
									readonly
									class="form-control shadow-none"
								/>
							</div>
							<div class="form-group col-lg-3">
								<label class="control-label">Representative Name</label>
								<input
									type="text"
									v-if="this.memberInfo"
									v-model="this.memberInfo.company_head"
									readonly
									class="form-control shadow-none"
								/>
							</div>
							<div class="form-group col-lg-3">
								<label class="control-label">Contact Email</label>
								<input
									type="email"
									v-if="this.memberInfo"
									v-model="this.memberInfo.email"
									readonly
									class="form-control shadow-none"
								/>
							</div>
							<div class="form-group col-lg-3">
								<label class="control-label">Contact Number</label>
								<input
									type="text"
									v-if="this.memberInfo"
									v-model="this.memberInfo.phone"
									readonly
									class="form-control shadow-none"
								/>
							</div>
						</div>
						<div class="col-lg-12"></div>
						<fieldset class="col-lg-12 border border-primary p-3 mb-3">
							<legend class="w-25 text-uppercase font-weight-bold">Add your invoices</legend>
							<div class="row no-gutters">
								<div class="col-lg-12">
									<div class="row" v-for="(invoice, index) in data.invoices" :key="index">
										<div class="col-lg-4 pr-lg-0">
											<div class="form-group">
												<label class="control-label">Invoice Date</label>
												<span class="text-danger requierd-star"></span>
												<Datepicker
													class="form-control shadow-none"
													placeholder="e.g. 12-12-2022."
													onfocus="this.placeholder = ''"
													onblur="this.placeholder = 'e.g. 12-12-2022'"
													v-model="invoice.date"
													:class="{
                            'is-invalid': validation.hasError('invoice.date'),
                            'is-valid': invoice.date,
                          }"
												/>
												<div class="invalid-feedback">{{ validation.firstError("invoice.date") }}</div>
											</div>
										</div>
										<div class="col-lg-4 pr-lg-0">
											<div class="form-group">
												<label class="control-label">Invoice Number</label>
												<span class="text-danger requierd-star"></span>
												<input
													type="text"
													v-model="invoice.invoice_number"
													placeholder="Invoice Number"
													class="form-control shadow-none"
													:class="{
                            'is-invalid': validation.hasError(
                              'invoice.invoice_number'
                            ),
                            'is-valid': invoice.invoice_number,
                          }"
												/>
												<div class="invalid-feedback">{{ validation.firstError("invoice.invoice_number") }}</div>
											</div>
										</div>
										<div class="col-lg-2 pr-lg-0">
											<div class="form-group">
												<label class="control-label">Invoice Amount</label>
												<span class="text-danger requierd-star"></span>
												<div class="input-group mb-2">
													<div class="input-group-prepend">
														<div class="input-group-text">$</div>
													</div>
													<input
														type="number"
														v-model="invoice.amount"
														value="0.00"
														class="form-control shadow-none text-secondary"
														id="inlineFormInputGroup"
													/>
												</div>
											</div>
										</div>
										<div class="col-lg-1 align-self-center" v-if="index > 0">
											<button
												@click="data.invoices.splice(index, 1)"
												type="button"
												class="btn btn-sm btn-danger"
											>X</button>
										</div>
										<div
											class="col-lg-1 align-self-center"
											v-if="index + 1 == Object.keys(data.invoices).length"
										>
											<button
												v-if="Object.keys(data.invoices).length < 5"
												@click="
                          data.invoices.push({
                            date: '',
                            invoice_number: '',
                            amount: '0.00',
                          })
                        "
												type="button"
												class="btn btn-sm btn-success"
											>Add</button>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-4 pr-lg-0"></div>
										<div class="col-lg-6 pr-lg-0 text-right">
											<h5>
												<span style="border-top: 1px solid #999; padding-top: 5px">
													Total Invoice Amount=
													<span class="font-weight-bold text-success">$ {{ totalAmount }}</span>
												</span>
											</h5>
										</div>
										<div class="col-lg-2"></div>
									</div>
								</div>
							</div>
							<!-- <table class="table table-sm table-borderless">
                <tr v-for="(invoice, index) in data.invoices" :key="index">
                  <td width="30%">
                    <div class="form-group">
                      <label class="control-label">Invoice Date</label>
                      <span class="text-danger requierd-star"></span>
                      <Datepicker
                        class="form-control shadow-none"
                        placeholder="e.g. 12-12-2022."
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. 12-12-2022'"
                        v-model="invoice.date"
                        :class="{'is-invalid': validation.hasError('invoice.date'), 'is-valid': invoice.date}"
                      />
                      <div class="invalid-feedback">{{ validation.firstError('invoice.date') }}</div>
                    </div>
                  </td>
                  <td width="30%">
                    <div class="form-group">
                      <label class="control-label">Invoice Number</label>
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        v-model="invoice.invoice_number"
                        placeholder="Invoice Number"
                        class="form-control shadow-none"
                        :class="{'is-invalid': validation.hasError('invoice.invoice_number'), 'is-valid': invoice.invoice_number}"
                      />
                      <div
                        class="invalid-feedback"
                      >{{ validation.firstError('invoice.invoice_number') }}</div>
                    </div>
                  </td>
                  <td width="15%">
                    <div class="form-group">
                      <label class="control-label">Invoice Amount</label>
                      <span class="text-danger requierd-star"></span>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text">$</div>
                        </div>
                        <input
                          type="number"
                          v-model="invoice.amount"
                          value="0.00"
                          class="form-control shadow-none text-secondary"
                          id="inlineFormInputGroup"
                        />
                      </div>
                    </div>
                  </td>
                  <td v-if="index>0">
                    <button
                      @click="data.invoices.splice(index,1)"
                      type="button"
                      class="btn btn-sm btn-danger"
                    >X</button>
                  </td>
                  <td v-if="(index+1)==Object.keys(data.invoices).length">
                    <button
                      v-if="Object.keys(data.invoices).length<5"
                      @click="data.invoices.push({date: '', invoice_number: '', amount:'0.00'})"
                      type="button"
                      class="btn btn-sm btn-success"
                    >Add more</button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td class="text-right" colspan="2">
                    <h5>
                      <span style="border-top: 1px solid #999;padding-top: 5px;">
                        Total Invoice Amount=
                        <span
                          class="font-weight-bold text-success"
                        >$ {{ totalAmount }}</span>
                      </span>
                    </h5>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
							</table>-->
						</fieldset>
						<div class="col-lg-12">
							<h5>
								BASIS Service Charge =
								<span class="font-weight-bold text-danger">BDT {{ totalCharge }}</span>
							</h5>
						</div>
						<div class="col-lg-12">
							<small>
								Note: After submitting your payment, please submit you necessary
								documents to cash incentive department. For more info
								<a
									target="_blank"
									href="https://basis.org.bd/service-details/14/member"
								>click here</a>
							</small>
						</div>
						<div class="col-lg-12 text-center py-4">
							<button class="btn btn-success text-center" type="submit">Next</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="member.info && member.info.status == 0" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">Sorry!! your account is suspend</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		name: "CashIncentiveForm",
		props: ["memberInfo"],
		data() {
			return {
				errorCount: 0,
				showServiceForm: false,
				data: {
					invoices: [{ date: "", invoice_number: "", amount: "0.00" }],
					amount: 0
				},
				errors: {},
				member: {
					info: null
				}
			};
		},
		methods: {
			submit: function() {
				this.errorCount = 0;
				this.data.invoices.forEach(element => {
					if (!element.date || !element.invoice_number || !element.amount) {
						this.$toastr("warning", "Please fill up empty field", "Warning!!");
						this.errorCount++;
					}
				});
				if (this.errorCount > 0) {
					return false;
				}

				this.data.form_type = this.$route.name;
				// this.$validate().then(res => {
				//   if (res) {
				this.data.amount = this.totalCharge;
				this.$parent.loading = true;
				axios
					.post("/member/store-company-info", this.data)
					.then(response => {
						this.$toastr(
							"success",
							"Your information has been submitted, please payment",
							"Success!!"
						);
						if (response.data) {
							localStorage.setItem("recp_id", response.data.recp_id);
							localStorage.setItem("service_amount", this.totalCharge);
							this.$router.push({
								name: "paymentMethod",
								params: { id: this.data.service_id, amount: this.totalCharge }
							});
						}
					})
					.catch(error => {
						console.log(error);
					})
					.then(alw => {
						this.$parent.loading = false;
					});
				//   }
				// });
			},
			getServiceInfo() {
				axios
					.get("/member/get-serviceinfo/" + this.$route.params.id)
					.then(res => {
						this.showServiceForm = res.data;
					});
			}
		},
		computed: {
			totalAmount() {
				return this.data.invoices.reduce((total, item) => {
					return total + Number(item.amount);
				}, 0);
			},
			totalCharge() {
				if (this.totalAmount <= 3000) {
					return 800;
				} else if (this.totalAmount > 3000 && this.totalAmount <= 10000) {
					return 1500;
				} else if (this.totalAmount > 10000) {
					return 3000;
				}
			}
		},
		created() {
			this.loggedIn();
			setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);
			this.data.service_id = this.$route.params.id;
			this.data.amount = this.totalCharge;
			this.getServiceInfo();
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
		validators: {
			// "data.invoices.date": function(value = null) {
			//   return Validator.value(value)
			//     .required("Invoice  Date is required")
			// },
			// "data.invoices.invoice_number": function(value = null) {
			//   return Validator.value(value)
			//     .required("Invoice  Number is required")
			// },
		}
	};
</script>

