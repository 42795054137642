<template>
  <div class="company-dashboard formValidation py-5">
    <div class="container-wrapper">
      <div class="row">
        <div class="col col-lg-3">
          <left-side></left-side>
        </div>
        <div class="col-lg-9">
          <div class="page-layout position-relative">
            <Loading v-if="loading" />
            <spinner v-if="$root.spinner" />
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftSide from "../../../../components/Frontend/partials/regMember/LeftSide";
import Loading from "./../../../../components/LoaderMember";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);

export default {
  name: "Layout",
  components: {
    leftSide,
    Loading
  },
  data() {
    return {
      loading: true,
      member: {
        info: null
      }
    };
  },
  methods: {},
  created() {
    this.LoggedInRegMember();
  },
  mounted() {
    setTimeout(() => (this.$root.loading = false), this.$root.minloadtimefd);
  }
};
</script>

<style>
</style>
