<template>
	<div class="cart-list container-wrapper">
		<div class="row pt-3">
			<div class="col-md-9">
				<div class="col-md-12">
					<h4 class><i class="mdi mdi-account-box"></i> Shiping Details</h4>

					<hr />
					<div class="row">
						<div
							class="col-md-4 mb-lg-1 mb-4"
							v-for="(address, aindex) in shippingAddressList"
							:key="aindex"
						>
							<div
								class="address-body custom-bg-shadow-redius"
								:class="{ checked: address.selected == 1 }"
							>
								<label :for="'address' + aindex">
									<div
										class="d-flex justify-content-between align-items-center"
									>
										<div class>
											<input
												:id="'address' + aindex"
												type="checkbox"
												@change="selectedAddress(false)"
												:true-value="address.id"
												:false-value="null"
												v-model="defualtAddress"
												name="shippingAddress"
											/>
											<strong class="badge badge-primary"
												>Address {{ aindex + 1 }}</strong
											>
											<strong class="badge badge-danger" v-if="aindex == 0"
												>Last Address</strong
											>
										</div>
										<div class="action-button">
											<a
												@click="editAddress(address.id)"
												href="javascript:void(0)"
											>
												<i class="fa fa-edit"></i>Edit
											</a>
										</div>
									</div>
									<p>
										<b>Name:</b>
										{{ address.name }}
									</p>
									<p>
										<b>Phone:</b>
										{{ address.phone }}
									</p>
									<p>
										<b>Address:</b>
										{{ address.shipping_address }}
									</p>
								</label>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-8">
							<input
								type="checkbox"
								:value="true"
								v-model="data.collect_from_basis"
							/>
							<b>Collect From BASIS Office (No Delivery Charge)</b>
							<br />BDBL Bhaban (Level 5 - West), 12 Kawran Bazar, Dhaka -1215
						</div>
						<div class="col-md-4">
							<a href="javascript:void(0)" @click="addAdress" class="text-dark">
								<i class="fa fa-plus"></i> Add New Address
							</a>
						</div>
					</div>
				</div>
				<hr />
				<form
					autocomplete="off"
					v-if="isAddressEditOrAdd && !data.collect_from_basis"
				>
					<div class="row px-4">
						<div class="col-lg-6 p-0">
							<div class="form-group">
								<label>
									Full Name
									<i class="text-danger">*</i>
								</label>
								<input
									type="text"
									name="name"
									v-model="data.name"
									class="form-control"
									placeholder="Fist Name"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.name") }}
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="form-group">
								<label>
									Email
									<i class="text-danger">*</i>
								</label>
								<input
									type="email"
									name="email"
									v-model="data.email"
									:readonly="data.email != ''"
									class="form-control"
									placeholder="ie:example@gmail.com"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.email") }}
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="form-group">
								<label>
									Phone
									<i class="text-danger">*</i>
								</label>
								<input
									type="number"
									name="phone"
									v-model="data.phone"
									class="form-control"
									placeholder="ie:01**********"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.phone") }}
								</div>
							</div>
						</div>
						<div class="col-lg-3 px-0">
							<div class="form-group">
								<label>
									Division
									<i class="text-danger">*</i>
								</label>
								<select v-model="data.division" class="form-control">
									<option :value="null">Select One</option>
									<option
										v-for="(div, divindex) in locations.division"
										:value="div.id"
										:key="divindex"
									>
										{{ div.name }}
									</option>
								</select>
								<div class="invalid-feedback">
									{{ validation.firstError("data.division") }}
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="form-group">
								<label>
									District
									<i class="text-danger">*</i>
								</label>
								<select v-model="data.district" class="form-control">
									<option :value="null">Select One</option>
									<option
										v-for="(dist, distindex) in locations.district"
										:value="dist.id"
										:key="distindex"
									>
										{{ dist.name }}
									</option>
								</select>
								<div class="invalid-feedback">
									{{ validation.firstError("data.district") }}
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="form-group">
								<label>
									Thana
									<i class="text-danger">*</i>
								</label>
								<select v-model="data.upazila" class="form-control">
									<option :value="null">Select One</option>
									<option
										v-for="(upazila, upaindex) in locations.upazila"
										:value="upazila.id"
										:key="upaindex"
									>
										{{ upazila.name }}
									</option>
								</select>
								<div class="invalid-feedback">
									{{ validation.firstError("data.upazila") }}
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="form-group">
								<label>
									Post Code
									<i class="text-danger">*</i>
								</label>
								<select v-model="data.post_code" class="form-control">
									<option :value="null">Select One</option>
									<option
										v-for="(code, code_index) in postalcodes"
										:value="code.name"
										:key="code_index"
									>
										{{ code.name }}
									</option>
								</select>
								<div class="invalid-feedback">
									{{ validation.firstError("data.post_code") }}
								</div>
							</div>
						</div>
						<div class="col-lg-3 p-0">
							<div class="form-group">
								<label>
									Area
									<i class="text-danger">*</i>
								</label>
								<select v-model="data.area" class="form-control">
									<option :value="null">Select One</option>
									<option
										v-for="(area, area_index) in areas"
										:value="area.name"
										:key="area_index"
									>
										{{ area.name }}
									</option>
								</select>
								<div class="invalid-feedback">
									{{ validation.firstError("data.area") }}
								</div>
							</div>
						</div>

						<div class="col-lg-12 p-0">
							<div class="form-group">
								<label>
									Shipping Address
									<i class="text-danger">*</i>
								</label>
								<textarea
									v-model="data.shipping_address"
									name="shipping_address"
									placeholder="Shipping Address"
									class="form-control"
									rows="3"
								></textarea>
								<div class="invalid-feedback">
									{{ validation.firstError("data.shipping_address") }}
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="col-lg-3 mx-3 mx-lg-0">
				<div class="card custom-bg-shadow-redius">
					<div class="card-header bg-blue-theme">
						<h4>Order Summary</h4>
					</div>
					<div class="card-body px-0 pb-0">
						<table class="order-summary-table">
							<tr>
								<th>Products</th>
								<td></td>
								<th>Total</th>
							</tr>
							<tr
								v-for="(product, indexp) in data.carts"
								:key="indexp"
								class="border-bottom"
							>
								<td style="width: 48%">{{ product.product_name }}</td>
								<td>:</td>
								<td>৳ {{ product.price | currency }}</td>
							</tr>
							<tr class="border-top">
								<th>Subtotal</th>
								<td></td>
								<th>৳ {{ totalPrice | currency }}</th>
							</tr>
							<tr class="border-top" v-if="discountPrice > 0">
								<th>Discount</th>
								<td></td>
								<th>৳ {{ discountPrice | currency }}</th>
							</tr>
							<tr
								class="border-top"
								v-if="$store.state.couponInfo.total_price > 0"
							>
								<th>Coupon</th>
								<td>:</td>
								<td>৳ {{ $store.state.couponInfo.total_price | currency }}</td>
							</tr>
							<tr class="border-top" v-if="data.shipping_amount > 0">
								<th>Delivery Charge</th>
								<td>:</td>
								<td>৳ {{ data.shipping_amount | currency }}</td>
							</tr>
							<tr>
								<th>
									<b>Total</b>
								</th>
								<td></td>
								<th>
									<b>
										৳
										{{
											(totalPrice -
												data.coupon_total_price -
												discountPrice +
												this.data.shipping_amount)
												| currency
										}}
									</b>
								</th>
							</tr>
						</table>

						<br />
						<button
							@click="orderProcess()"
							:disabled="order_process_spinner"
							class="btn btn-success px-3 py-2 w-100"
						>
							<span v-if="order_process_spinner">
								<i class="fa fa-spinner fa-spin"></i>
							</span>
							PLACE ORDER
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Breadcrumb from "../../../../components/Breadcrumb";
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		name: "Checkout",
		components: { Breadcrumb },
		data() {
			return {
				isAddressEditOrAdd: false,

				data: {
					carts: [],
					total_amount: 0.0,
					discount_amount: 0.0,
					shipping_amount: 0.0,
					coupon_total_price: 0.0,
					collect_from_basis: null,
					coupon_code: "",
					coupon_id: "",
					customer_id: "",
					name: "",
					phone: "",
					email: "",
					shipping_address: "",
					division: null,
					district: null,
					upazila: null,
					post_code: "",
					area: "",
					shipping_id: "",
				},
				shippingAddressList: {},
				defualtAddress: null,
				selectedAddressBox: null,
				order_place: false,
				errors: {},
				locations: {
					division: {},
					district: {},
					upazila: {},
				},
				priviousRouteName: "",
				defalult_shipping: false,
				order_process_spinner: false,
				// ecourier
				courier: { ecourier_count: 0 },
				districts: [],
				thanas: [],
				postalcodes: [],
				areas: [],
				payment_method: false,
				ecData: {
					order_no: null,
					recipient_city: null,
					recipient_thana: null,
					recipient_zip: null,
					recipient_area: null,
					recipient_address: null,
					product_price: 50,
					payment_method: "CCRD",
					parcel_type: "DOC",
				},
			};
		},
		methods: {
			addressDataAssign(shippingInfo) {
				this.data.name = shippingInfo.name;
				this.data.phone = shippingInfo.phone;
				this.data.email = shippingInfo.email;
				this.data.shipping_address = shippingInfo.shipping_address;
				this.data.division = shippingInfo.division;
				this.data.district = shippingInfo.district;
				this.data.upazila = shippingInfo.upazila;
				this.data.shipping_id = shippingInfo.id;
				this.isAddressEditOrAdd = true;
				this.order_place = true;
				this.defualtAddress = shippingInfo.id;
				this.data.post_code = value.post_code;
				this.data.area = value.area;
				// eCoureire data assign
				this.ecData.recipient_zip = shippingInfo.post_code;
				this.ecData.recipient_area = shippingInfo.area;
				this.ecData.recipient_address = shippingInfo.shipping_address;

				setTimeout(() => {
					let found_upazilla = this.locations.upazila.find(
						(item) => item.id == shippingInfo.upazila
					).name;

					let found_district = this.locations.district.find(
						(item) => item.id == shippingInfo.district
					).name;

					this.ecData.recipient_city = found_district;
					this.ecData.recipient_thana = found_upazilla;
					if (found_upazilla) {
						this.getPostCode(found_district, found_upazilla);
					}
				}, 3000);
			},
			addAdress() {
				this.selectedAddressBox = null;
				this.isAddressEditOrAdd = true;
				this.data.name = "";
				this.data.phone = "";
				this.data.shipping_address = "";
				this.data.division = "";
				this.data.district = "";
				this.data.upazila = "";
				this.data.post_code = "";
				this.data.area = "";
				this.data.shipping_id = "";
				// assign default shipping amount
				this.defalult_shipping = true;
			},
			editAddress(id) {
				this.isAddressEditOrAdd = true;
				this.defualtAddress = id;
				this.selectedAddress(true);
			},
			selectedAddress(type = false) {
				this.defalult_shipping = false;
				let id = this.defualtAddress;
				if (id != null) {
					this.isAddressEditOrAdd = type;
					this.shippingAddressList.map((value) => {
						if (value.id == id) {
							this.data.name = value.name;
							this.data.phone = value.phone;
							this.data.email = value.email;
							this.data.shipping_address = value.shipping_address;
							this.data.division = value.division;
							this.data.district = value.district;
							this.data.upazila = value.upazila;
							this.data.post_code = value.post_code;
							this.data.area = value.area;
							this.data.shipping_id = id;
							// ecoureire data assign
							this.ecData.recipient_zip = value.post_code;
							this.ecData.recipient_area = value.area;
							this.ecData.recipient_address = value.shipping_address;

							setTimeout(() => {
								let found_upazilla = this.locations.upazila.find(
									(item) => item.id == value.upazila
								).name;

								let found_district = this.locations.district.find(
									(item) => item.id == value.district
								).name;

								this.ecData.recipient_city = found_district;
								this.ecData.recipient_thana = found_upazilla;
								if (found_upazilla) {
									this.getPostCode(found_district, found_upazilla);
								}
							}, 3000);
						}
					});
				} else {
					this.data.name = "";
					this.data.phone = "";
					this.data.email = "";
					this.data.shipping_address = "";
					this.data.division = "";
					this.data.district = "";
					this.data.upazila = "";
					this.data.shipping_id = "";
				}
			},

			async getShippingAddress() {
				this.$eventBus.$emit("ecomloading", true);
				await axios.get("/ecommerce/shipping-address").then((res) => {
					this.shippingAddressList = res.data;
					let found = res.data.find((address) => address.selected == 1);
					if (found) {
						this.defualtAddress = found.id;
						this.selectedAddress();
					}
					setTimeout(() => {
						this.$eventBus.$emit("ecomloading", false);
					}, 1000);
				});
			},

			getCart() {
				this.data.carts = this.$store.state.cart;
			},
			orderProcess() {
				this.$validate().then((res) => {
					if (this.validation.countErrors() > 0) {
						this.$toastr(
							"warning",
							'You need to fill <span class="font-weight-bold">' +
								this.validation.countErrors() +
								" more empty</span> mandatory fields",
							"Shipping Address!!"
						);
						console.log(this.validation.allErrors());
						if (!this.defualtAddress) {
							this.isAddressEditOrAdd = true;
						}
						this.isAddressEditOrAdd = true;
						return false;
					}

					if (res) {
						this.order_process_spinner = true;

						this.total_amount = this.total_amount;
						let data = {
							ecData: this.ecData,
							data: this.data,
						};

						axios
							.post("ecommerce/order-process", data)
							.then((res) => {
								this.$store.commit("emptyToCart");
								this.$store.commit("removeCoupntInfo");
								this.order_process_spinner = false;

								this.$router.push({
									name: "ecommerce.OrderInvoice",
									params: { id: res.data.order_number },
								});
								this.$toastr("success", res.data.message, "Success!!");
							})
							.catch((error) => {
								if (error.response.status === 422) {
									this.errors = error.response.data.errors || {};
								}
							});
					}
				});
			},
			loggedCheck() {
				axios.get("/ecommerce/loggedOrNot").then((res) => {
					if (res.data) {
						this.dataAssign();
						setTimeout(() => this.dataAssign(), 4000);
					} else {
						this.$router.push({ name: "ecommerce.login" });
					}
				});
			},
			dataAssign() {
				this.data.name = this.$root.loggedCustomer.name;
				this.data.email = this.$root.loggedCustomer.email;
				this.data.phone = this.$root.loggedCustomer.mobile;
				this.data.shipping_address = this.$root.loggedCustomer.address;
				if (
					this.$store.state.couponInfo.total_price &&
					this.$store.state.couponInfo
				) {
					this.data.coupon_total_price = this.$store.state.couponInfo.total_price;
					this.data.coupon_code = this.$store.state.couponInfo.coupon;
					this.data.coupon_id = this.$store.state.couponInfo.coupon_id;
				}
			},
			async getDivision() {
				await axios.get("/ecommerce/get-division").then((res) => {
					this.locations.division = res.data;
				});
			},
			shippingAmount() {
				if (this.data.district == 55 && this.data.district) {
					this.data.shipping_amount = 50.0;
					this.ecData.product_price = 50.0;
				} else {
					this.data.shipping_amount = 120.0;
					this.ecData.product_price = 120.0;
				}
			},

			getPostCode(district, upazilla) {
				let params = {
					url: "postcode-list",
					field1: "city",
					field2: "thana",
					value1: district,
					value2: upazilla,
				};

				axios
					.get("/ecommerce/get-ecourier-location", { params: params })
					.then((res) => {
						this.postalcodes = res.data;
					});
			},
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				vm.from = from;
				vm.priviousRouteName = vm.from.name;
			});
		},
		watch: {
			data: {
				handler(val) {
					if (
						(this.data.email != "" &&
							this.data.phone != "" &&
							this.data.name != "") ||
						this.data.collect_from_basis
					) {
						this.order_place = true;
					} else {
						this.order_place = false;
					}
				},
				deep: true,
			},
			"data.division": function (newValue, old) {
				axios
					.get("/ecommerce/get-district", { params: { name: newValue } })
					.then((res) => {
						this.locations.district = res.data;
					});
			},
			"data.district": function (newValue, old) {
				axios
					.get("/ecommerce/get-upazila", { params: { name: newValue } })
					.then((res) => {
						this.locations.upazila = res.data;
					});

				if (this.defualtAddress != null) {
					this.shippingAmount();
				} else {
					this.data.shipping_amount = 0.0;
					this.ecData.product_price = 0.0;
				}
				setTimeout(() => {
					if (this.defalult_shipping) {
						this.shippingAmount();
					}
				}, 1000);
			},
			"data.collect_from_basis": function (newVal, old) {
				if (newVal) {
					this.data.shipping_amount = 0.0;
					this.ecData.product_price = 0.0;
				} else {
					if (this.defualtAddress != null) {
						this.shippingAmount();
					}
				}
			},
			priviousRouteName: function (newValue, old) {
				if (newValue == "ecommerce.OrderInvoice") {
					this.$router.push({ name: "ecommerce.myorders" });
				}
			},

			"data.upazila": function (newValue, old) {
				if (
					!newValue &&
					this.locations.district.length < 1 &&
					this.locations.upazila.length < 1
				) {
					return false;
				}

				let found_district = this.locations.district.find(
					(item) => item.id == this.data.district
				).name;

				let found_upazilla = this.locations.upazila.find(
					(item) => item.id == newValue
				).name;

				this.ecData.recipient_city = found_district;
				this.ecData.recipient_thana = found_upazilla;

				if (found_upazilla) {
					this.getPostCode(found_district, found_upazilla);
				}
			},
			"data.post_code": function (newValue, old) {
				if (!newValue) {
					return false;
				}
				let params = { url: "area-list", field: "postcode", name: newValue };
				this.ecData.recipient_zip = newValue;
				axios
					.get("/ecommerce/get-ecourier-location", { params: params })
					.then((res) => (this.areas = res.data));
			},
			"data.area": function (newValue, old) {
				if (!newValue) {
					return false;
				}
				this.ecData.recipient_area = newValue;
			},
		},
		created() {
			this.$eventBus.$emit("ecomloading", true);
			this.getCart();
			this.loggedCheck();
			this.getDivision();
			this.getShippingAddress();
			//ecourier

			this.$root.loading = false;
			this.$parent.loading = false;

			setTimeout(() => {
				this.$eventBus.$emit("ecomloading", false);
			}, 1000);
		},
		computed: {
			totalPrice() {
				let total = 0.0;
				this.$store.state.cart.forEach((cart) => {
					total += parseFloat(cart.totalPrice);
				});
				this.data.total_amount = total;
				return total.toFixed(2);
			},
			discountPrice() {
				let total = 0.0;
				const current_date = new Date().toISOString().split("T")[0];

				this.$store.state.cart.forEach((cart) => {
					if (cart.discount) {
						if (
							cart.discount_start_date <= current_date &&
							cart.discount_end_date >= current_date
						) {
							total += parseFloat(cart.discount_price) * cart.qty;
						}
					}
				});
				this.data.discount_amount = total;
				return total.toFixed(2);
			},
		},

		validators: {
			"data.name": function (value = null) {
				if (!this.data.collect_from_basis) {
					return Validator.value(value).required("Name is required");
				}
				return Validator.value();
			},
			"data.email": function (value = null) {
				if (!this.data.collect_from_basis) {
					return Validator.value(value).required("Email is required").email();
				}
				return Validator.value();
			},
			"data.phone": function (value = null) {
				if (!this.data.collect_from_basis) {
					return Validator.value(value).required("Phone is required");
				}
				return Validator.value();
			},
			"data.division": function (value = null) {
				if (!this.data.collect_from_basis) {
					return Validator.value(value).required("Division is required");
				}
				return Validator.value();
			},
			"data.district": function (value = null) {
				if (!this.data.collect_from_basis) {
					return Validator.value(value).required("District is required");
				}
				return Validator.value();
			},
			"data.upazila": function (value = null) {
				if (!this.data.collect_from_basis) {
					return Validator.value(value).required("Upazila is required");
				}
				return Validator.value();
			},
			"data.post_code": function (value = null) {
				if (!this.data.collect_from_basis) {
					return Validator.value(value).required("Post Code is required");
				}
				return Validator.value();
			},
			"data.area": function (value = null) {
				if (!this.data.collect_from_basis) {
					return Validator.value(value).required("Area is required");
				}
				return Validator.value();
			},
			"data.shipping_address": function (value = null) {
				if (!this.data.collect_from_basis) {
					return Validator.value(value).required("Shipping address is required");
				}
				return Validator.value();
			},
		},
	};
</script>

<style scoped lang="scss">
	.input-group {
		input {
			border: 1px solid rgb(109 200 190);
			background: rgb(244 244 244);
			text-align: center;
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type="number"] {
			-moz-appearance: textfield;
		}

		.input-group-prepend,
		.input-group-append {
			.input-group-text {
				background: rgb(109 200 190);
				color: rgb(255 255 255);
				border: 0;
				width: 35px;
				cursor: pointer;
			}
		}
	}

	.bg-blue-theme {
		background: #6fccc18c;
	}

	table.order-summary-table {
		border: 0px;
		padding: 0px;
		margin: 0px;

		th,
		td {
			border: 0;
			font-size: 13px;
		}
	}
</style>
