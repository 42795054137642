<template>
	<div class="ecommerce-slider mb-4 px-3">
		<div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
			<div class="carousel-inner" v-if="Object.keys(sliders).length > 0">
				<div
					class="carousel-item"
					:class="index === 1 ? 'active' : ''"
					v-for="(slider, index) in sliders"
					:key="index"
				>
					<router-link
						:to="{
                name: 'ecommerce.products',
                params: { slug: slider.category_slug },
              }"
					>
						<img :src="slider.slider" alt="..." />
					</router-link>
				</div>
			</div>
			<a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
				<span class="carousel-control-prev-icon" aria-hidden="true"></span>
				<span class="sr-only">Previous</span>
			</a>
			<a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
				<span class="carousel-control-next-icon" aria-hidden="true"></span>
				<span class="sr-only">Next</span>
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Slider",
		data() {
			return {
				sliders: {}
			};
		},
		created() {
			axios.get("/ecommerce/get-sliders").then(res => (this.sliders = res.data));
		}
	};
</script>

<style scoped>
</style>
