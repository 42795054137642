<template>
  <div
    class="member-services"
    v-if="
      (!$parent.loading && $root.access && $root.access['vertical_exp']) ||
      $root.variable.rep_login ||
      $root.variable.aLogId
    "
  >
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">
            Vertical Experience
          </h5>
        </div>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="submit" id="form">
          <fieldset class="col-lg-12 border border-primary p-3 mb-3">
            <legend class="w-75 text-center">
              <small class="font-weight-bold">
                If you are a software developers please specify your vertical
                experience
                <span class="text-danger requierd-star"></span>
              </small>
            </legend>
            <div class="row mb-5 justify-content-center">
              <div class="row col-12 mb-3">
                <div class="col-md-4">
                  <label>Expertise</label>
                </div>
                <div class="col-md-3">
                  <label>Number of installations</label>
                </div>
                <div class="col-md-4">
                  <label>Please specify some clients</label>
                </div>
              </div>
              <div
                class="row col-12 mb-3"
                v-for="(expert, index) in data.expertises_lists"
                :key="index"
              >
                <div class="col-md-4">
                  <select v-model="expert.expertise" class="form-control">
                    <option value>Select one</option>
                    <option
                      v-for="(expertise, id, index) in extraData.expertises"
                      :key="index"
                      :value="expertise.id"
                    >
                      {{ expertise.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <input
                    type="number"
                    v-model.number="expert.installations"
                    class="form-control"
                    :class="{
                      'is-invalid': validation.hasError('expert.installations'),
                      'is-valid': expert.installations,
                    }"
                    placeholder="Number of installations"
                  />
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    v-model="expert.clients"
                    class="form-control"
                    :class="{
                      'is-invalid': validation.hasError('expert.clients'),
                      'is-valid': expert.clients,
                    }"
                    placeholder="Please specify some clients"
                  />
                </div>
                <div class="col-lg-1" v-if="index > 0">
                  <button
                    @click="data.expertises_lists.splice(index, 1)"
                    class="btn btn-sm btn-danger w-100"
                    type="button"
                  >
                    <span class="fas fa-times"></span>
                  </button>
                </div>
              </div>
              <div class="col-lg-11 text-right">
                <button
                  @click="data.expertises_lists.push({ expertise: '' })"
                  class="btn btn-sm btn-success"
                  type="button"
                >
                  Add More
                </button>
              </div>
            </div>
          </fieldset>

          <fieldset class="col-lg-12 border border-primary p-3 mb-3">
            <legend class="w-25 text-center">
              <small class="font-weight-bold">
                Language/Tools
                <span class="text-danger requierd-star"></span>
              </small>
            </legend>
            <div class="row">
              <div
                class="col-6 col-lg-4 mb-3"
                v-for="(language, id, index) in $root.variable.languages"
                :key="index"
              >
                <div class="custom-control custom-checkbox">
                  <b-form-checkbox
                    class="col-form-label-sm font-weight-bold mb-0"
                    :value="language"
                    v-model="data.languages[language]"
                    :unchecked-value="false"
                    >{{ language }}</b-form-checkbox
                  >
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="col-lg-12 border border-primary p-3 mb-3">
            <legend class="w-25 text-center">
              <small class="font-weight-bold">
                Platform
                <span class="text-danger requierd-star"></span>
              </small>
            </legend>
            <div class="row">
              <div
                class="col-lg-4 mb-3"
                v-for="(platform, id, index) in $root.variable.platforms"
                :key="index"
              >
                <div class="custom-control custom-checkbox">
                  <b-form-checkbox
                    class="col-form-label-sm font-weight-bold mb-0"
                    :value="platform"
                    v-model="data.platforms[platform]"
                    :unchecked-value="false"
                    >{{ platform }}</b-form-checkbox
                  >
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="col-lg-12 border border-primary p-3 mb-3">
            <legend class="w-25 text-center">
              <small class="font-weight-bold">
                Mobile Platform
                <span class="text-danger requierd-star"></span>
              </small>
            </legend>
            <div class="row">
              <div
                class="col-lg-4 mb-3"
                v-for="(mPlatform, id, index) in $root.variable
                  .mobile_platforms"
                :key="index"
              >
                <div class="custom-control custom-checkbox">
                  <b-form-checkbox
                    class="col-form-label-sm font-weight-bold mb-0"
                    :value="mPlatform"
                    v-model="data.mobile_platforms[mPlatform]"
                    :unchecked-value="false"
                    >{{ mPlatform }}</b-form-checkbox
                  >
                </div>
              </div>
            </div>
          </fieldset>
          <div class="row justify-content-center">
            <button class="btn btn-success" type="submit">
              Update Vertical Experience Information
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- <pre>{{ data }}</pre> -->
  </div>
  <div v-else class="row">
    <div class="col-12">
      <div class="card">
        <div v-if="Object.keys($root.access).length > 0" class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! you cannot access this page
          </h4>
        </div>
        <div v-else class="card-body">
          <div
            v-if="!$parent.loading"
            class="
              card-body
              d-flex
              align-items-center
              justify-content-center
              flex-column
            "
          >
            <i
              class="fa fa-spinner fa-spin"
              style="font-size: 24px; color: rgb(0, 127, 62)"
            ></i>
            <span class="processing">processing</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
const breadcumb = [
  {
    title: "Vertical Experience Information",
  },
];
export default {
  name: "VerticalExperienceForm",
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Vertical Experience Information",
      extraData: {
        expertises: [],
        language_lists: [],
      },
      data: {
        expertises_lists: [{ expertise: "" }],
        languages: {},
        mobile_platforms: {},
        platforms: {},
      },
      member: {
        info: {},
      },
      errors: {},
    };
  },
  validators: {},
  methods: {
    submit: function (e) {
      if (confirm("Are you confirming, is the provided information valid?")) {
        this.scrollTop();
        this.$parent.loading = true;
        axios
          .post("member/VerticalExperienceInfo", this.data)
          .then((response) => {
            this.$toastr("success", "Update Successfully", "Success!!");
            location.reload();
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors || {};
              this.focus_field = Object.keys(this.errors)[0];
            }
          })
          .then((always) => {
            setTimeout(
              () => (this.$parent.loading = false),
              this.$root.minloadtimefd
            );
          });
      }
    },
  },
  mounted() {
    this.loggedIn();
    // Expertise
    this.getList("Expertise", "expertises", true);
    // Member Expertise List
    axios
      .get("/member/getExpertiseList")
      .then((response) => {
        if (Object.keys(response.data).length > 0) {
          this.data.expertises_lists = response.data;
        }
      })
      .catch((error) => console.log(error.response))
      .then((always) => setTimeout(() => (this.$parent.loading = false), 500));
    // Member Language
    axios
      .get("/member/getLanguage")
      .then((response) => {
        if (Object.keys(response.data).length > 0) {
          this.data.languages = response.data;
        }
      })
      .catch((error) => console.log(error.response))
      .then((always) => setTimeout(() => (this.$parent.loading = false), 500));
    // Member Mobile Platform
    axios
      .get("/member/getMobilePlatform")
      .then((response) => {
        if (Object.keys(response.data).length > 0) {
          this.data.mobile_platforms = response.data;
        }
      })
      .catch((error) => console.log(error.response))
      .then((always) => setTimeout(() => (this.$parent.loading = false), 500));
    // Member Platform
    axios
      .get("/member/getPlatform")
      .then((response) => {
        if (Object.keys(response.data).length > 0) {
          this.data.platforms = response.data;
        }
      })
      .catch((error) => console.log(error.response))
      .then((always) => setTimeout(() => (this.$parent.loading = false), 500));
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  updated() {
    this.$root.debug_data = this.data;
  },
};
</script>

