var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"example-content"}},[(!_vm.$root.spinner)?_vm._t("default",function(){return [_c('div',{staticClass:"col-xs-12 col-lg-12",attrs:{"data-aos":"fade-right"}},[_c('h5',{staticClass:"page-title font-weight-bold text-uppercase"},[_vm._v(_vm._s(_vm.title))])]),_vm._v(" "),(!_vm.showInvoice)?_c('div',{staticClass:"col-xs-12 col-lg-12",attrs:{"data-aos":"fade-right"}},[_c('fieldset',{staticClass:"col-12 border border-primary p-3 mb-3"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-row col-6 mb-3 ml-2"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Company Name")]),_vm._v(" "),_c('span',{staticClass:"text-danger requierd-star"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.company_name),expression:"data.company_name"}],staticClass:"form-control shadow-none",class:{
								'is-invalid': _vm.validation.hasError('data.company_name'),
								'is-valid': _vm.data.company_name,
							},attrs:{"type":"text","placeholder":"e.g. Nogor Solutions ltd"},domProps:{"value":(_vm.data.company_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "company_name", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.validation.firstError("data.company_name"))+"\n\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"form-row col-6 mb-3"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Contact Person")]),_vm._v(" "),_c('span',{staticClass:"text-danger requierd-star"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.contact_person),expression:"data.contact_person"}],staticClass:"form-control shadow-none",class:{
								'is-invalid': _vm.validation.hasError('data.contact_person'),
								'is-valid': _vm.data.contact_person,
							},attrs:{"type":"text","placeholder":"Contact Person"},domProps:{"value":(_vm.data.contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "contact_person", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.validation.firstError("data.contact_person"))+"\n\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"form-row col-6 mb-3 ml-2"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Email")]),_vm._v(" "),_c('span',{staticClass:"text-danger requierd-star"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],staticClass:"form-control shadow-none",class:{
								'is-invalid': _vm.validation.hasError('data.email'),
								'is-valid': _vm.data.email,
							},attrs:{"type":"text","placeholder":"Email"},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "email", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.validation.firstError("data.email"))+"\n\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"form-row col-6 mb-3"},[_c('label',{attrs:{"for":"comments"}},[_vm._v("Mobile")]),_vm._v(" "),_c('span',{staticClass:"text-danger requierd-star"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.mobile),expression:"data.mobile"}],staticClass:"form-control shadow-none",class:{
								'is-invalid': _vm.validation.hasError('data.mobile'),
								'is-valid': _vm.data.mobile,
							},attrs:{"type":"text","placeholder":"Mobile"},domProps:{"value":(_vm.data.mobile)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "mobile", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.validation.firstError("data.mobile"))+"\n\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"form-row col-12 ml-2 text-center"},[_c('button',{staticClass:"btn btn-success text-center",attrs:{"type":"button"},on:{"click":_vm.submit}},[_vm._v("\n\t\t\t\t\t\t\tNext\n\t\t\t\t\t\t")])])])])]):_vm._e(),_vm._v(" "),(_vm.showInvoice)?_c('div',{staticClass:"col-xs-12 col-lg-12",attrs:{"data-aos":"fade-right"}},[_c('InvoicePage',{attrs:{"invoice":_vm.invoice}})],1):_vm._e()]}):_vm._e(),_vm._v(" "),(_vm.$root.spinner)?_c('spinner'):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('legend',{staticClass:"w-25 text-center"},[_c('small',{staticClass:"text-uppercase font-weight-bold"},[_vm._v("Company Information")])])
}]

export { render, staticRenderFns }