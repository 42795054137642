<template>
	<div class="home">
		<Slider />
		<slot v-if="!$root.loading">
			<HomeModal />
			<Counter />
			<MissionVision />
			<Benifits />
			<Services />
			<!-- <DiscountServicesHome /> -->
			<!--member & Calender-->
			<div class="memberCalender">
				<div class="container-wrapper">
					<div class="row">
						<div
							class="col-lg-7"
							data-aos="fade-right"
							data-aos-offset="100"
							data-aos-duration="1000"
						>
							<WhatsNew />
						</div>
						<div
							class="col-lg-5"
							data-aos="fade-left"
							data-aos-offset="100"
							data-aos-duration="1500"
						>
							<EventCalander />
						</div>
					</div>
				</div>
			</div>
			<!--//member & Calender-->
			<Marketing />
			<Gallery />
			<NewsRoom :parentData="{ news: data.whats_new }" />
			<!-- <Ecommerce /> -->
			<Partners />
			<FooterTop :parentData="{ content: data.content }" />
		</slot>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Slider from "../../components/elements/Slider";
	import Counter from "../../components/elements/Counter";
	import MissionVision from "../../components/elements/MissionVision";
	import Benifits from "../../components/elements/Benifits";
	import Services from "../../components/elements/Services";
	import WhatsNew from "../../components/elements/WhatsNew";
	import EventCalander from "../../components/elements/EventCalander";
	import Gallery from "../../components/elements/Gallery";
	import NewsRoom from "../../components/elements/NewsRoom";
	import Ecommerce from "../../components/elements/Ecommerce";
	import Partners from "../../components/elements/Partners";
	import Marketing from "../../components/elements/Marketing";
	import FooterTop from "../../components/elements/FooterTop";
	import DiscountServicesHome from "../../components/elements/DiscountServicesHome";
	import HomeModal from "../../components/elements/HomeModal";

	export default {
		name: "home",
		components: {
			HomeModal,
			Ecommerce,
			Slider,
			Counter,
			MissionVision,
			Benifits,
			Services,
			WhatsNew,
			Marketing,
			EventCalander,
			Gallery,
			NewsRoom,
			Partners,
			FooterTop,
			DiscountServicesHome,
		},
		data() {
			return {
				title: "Event Calendar",
				name: "home",
				itemsPerRow: 2,
				data: {},
				events: {},
				eventsCalendar: [],
				month: {},
			};
		},
		computed: {},
		methods: {
			getData() {
				axios.get("/frontend").then((response) => {
					this.data = response.data;
					this.$root.loading = false;
				});
			},
		},
		created() {
			setTimeout(() => this.getData(), 320);
		},
	};
</script>
