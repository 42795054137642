<template>
	<div id="example-content">
		<slot v-if="!$root.spinner">
			<slot v-if="news.image">
				<img
					v-lazy="$root.baseurl + '/public' + news.image"
					class="img-fluid"
					alt="..."
				/>
				<!--Heading-->
				<h5 class="font-weight-bold text-uppercase">{{ news.title }}</h5>
				<!--Text-->
				<p class="text-justify" v-html="news.description"></p>
			</slot>
			<slot v-else>
				<div class="col-lg-12" data-aos="fade-right">
					<h5 class="font-weight-bold text-uppercase">{{ news.title }}</h5>
					<p class="text-justify" v-html="news.description"></p>
				</div>
			</slot>
		</slot>
		<spinner v-if="$root.spinner" />
	</div>
</template>

<script>
	export default {
		name: "ViewNews",
		data() {
			return {
				news: {},
				breadcrumbTitle: "",
				breadcumb: [],
			};
		},
		methods: {
			get_data() {
				this.$root.spinner = true;
				axios
					.get("/get-news-details/" + this.$route.params.slug)
					.then((respons) => {
						this.news = respons.data.result;
						if (this.news.news_type == "IndustryNews") {
							var titleN = "Industry News";
						} else if (this.news.news_type == "PressRelease") {
							var titleN = "Press Release";
						} else {
							var titleN = "Current News";
						}
						this.breadcrumbTitle = respons.data.result.title;
						this.breadcumb = [
							{
								title: titleN,
								route_name: "news",
								params: this.news.news_type,
							},
							{ title: this.breadcrumbTitle },
						];
						this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
						this.$root.spinner = false;
					})
					.catch((error) => {
						console.log(error);
					});
			},
		},
		mounted() {
			this.get_data();
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
	};
</script>
