<template>
	<div id="example-content">
		<slot v-if="!$root.spinner">
			<div class="col-xs-12 col-lg-12" data-aos="fade-right">
				<h5 class="page-title font-weight-bold text-uppercase">{{ title }}</h5>
			</div>
			<div
				v-if="!showInvoice"
				class="col-xs-12 col-lg-12"
				data-aos="fade-right"
			>
				<fieldset class="col-12 border border-primary p-3 mb-3">
					<legend class="w-25 text-center">
						<small class="text-uppercase font-weight-bold"
							>Company Information</small
						>
					</legend>
					<div class="row">
						<div class="form-row col-6 mb-3 ml-2">
							<label for="comments">Company Name</label>
							<span class="text-danger requierd-star"></span>
							<input
								type="text"
								class="form-control shadow-none"
								:class="{
									'is-invalid': validation.hasError('data.company_name'),
									'is-valid': data.company_name,
								}"
								placeholder="e.g. Nogor Solutions ltd"
								v-model="data.company_name"
							/>
							<div class="invalid-feedback">
								{{ validation.firstError("data.company_name") }}
							</div>
						</div>
						<div class="form-row col-6 mb-3">
							<label for="comments">Contact Person</label>
							<span class="text-danger requierd-star"></span>
							<input
								type="text"
								class="form-control shadow-none"
								:class="{
									'is-invalid': validation.hasError('data.contact_person'),
									'is-valid': data.contact_person,
								}"
								placeholder="Contact Person"
								v-model="data.contact_person"
							/>
							<div class="invalid-feedback">
								{{ validation.firstError("data.contact_person") }}
							</div>
						</div>
						<div class="form-row col-6 mb-3 ml-2">
							<label for="comments">Email</label>
							<span class="text-danger requierd-star"></span>
							<input
								type="text"
								class="form-control shadow-none"
								:class="{
									'is-invalid': validation.hasError('data.email'),
									'is-valid': data.email,
								}"
								placeholder="Email"
								v-model="data.email"
							/>
							<div class="invalid-feedback">
								{{ validation.firstError("data.email") }}
							</div>
						</div>
						<div class="form-row col-6 mb-3">
							<label for="comments">Mobile</label>
							<span class="text-danger requierd-star"></span>
							<input
								type="text"
								class="form-control shadow-none"
								:class="{
									'is-invalid': validation.hasError('data.mobile'),
									'is-valid': data.mobile,
								}"
								placeholder="Mobile"
								v-model="data.mobile"
							/>
							<div class="invalid-feedback">
								{{ validation.firstError("data.mobile") }}
							</div>
						</div>
						<div class="form-row col-12 ml-2 text-center">
							<button
								@click="submit"
								class="btn btn-success text-center"
								type="button"
							>
								Next
							</button>
						</div>
					</div>
				</fieldset>
			</div>

			<div v-if="showInvoice" class="col-xs-12 col-lg-12" data-aos="fade-right">
				<InvoicePage :invoice="invoice" />
			</div>
		</slot>
		<spinner v-if="$root.spinner" />
	</div>
</template>

<script>
	import InvoicePage from "./Invoice";

	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		name: "Payment",
		data() {
			return {
				showInvoice: false,
				data: {},
				invoice: {},
				title: "Non Member Payment Process",
				breadcrumbTitle: "Non Member Payment Process",
				breadcumb: [],
			};
		},
		components: {
			InvoicePage,
		},
		methods: {
			submit() {
				this.$validate().then((res) => {
					if (res) {
						this.$root.spinner = true;
						axios
							.post("/non-member-invoice", this.data)
							.then((res) => {
								this.$toastr(
									"success",
									"Invoice Generate Successfully",
									"Success!!"
								);
								this.showInvoice = true;
								this.invoice = res.data;
							})
							.catch((error) => console.log(error))
							.then((alw) => (this.$root.spinner = false));
					}
				});
			},
		},
		mounted() {
			setTimeout(() => {
				this.$root.spinner = false;
			}, 200);
			this.breadcumb = [{ title: this.breadcrumbTitle }];
			this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);

			this.data.service_id = this.$route.params.id;
		},

		validators: {
			"data.company_name": function (value = null) {
				return Validator.value(value).required("Company Name is required");
			},
			"data.contact_person": function (value = null) {
				return Validator.value(value).required("Contact Person is required");
			},
			"data.email": function (value = null) {
				return Validator.value(value).required("Email is required").email();
			},
			"data.mobile": function (value = null) {
				return Validator.value(value)
					.required("Mobile number is required")
					.digit("Mobile number must be digit")
					.regex("01+[0-9]*$", "Must start with 01.")
					.minLength(11)
					.maxLength(11);
			},
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
			this.$root.spinner = true;
		},
	};
</script>
