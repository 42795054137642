<template>
	<div>
		<h4 class="mt-3">My Orders</h4>
		<div class="mt-3 table-responsive">
			<table
				style="border-top: 1px solid #bfb3b344 !important"
				class="table custom-bg-shadow-redius"
				v-if="myOrders && Object.keys(myOrders).length > 0"
			>
				<tr>
					<th>#</th>
					<th style="width: 20%">Order ID</th>
					<th>Order Date</th>
					<th>Amount</th>
					<th>Status</th>
					<th width="20%" style="text-align: center">Action</th>
				</tr>
				<slot v-for="(myOrder, key) in myOrders">
					<tr v-if="myOrder.status != 'cancel'" :key="key">
						<td>{{ key + 1 }}</td>
						<td class="custom-responsive">
							<b>{{ myOrder.order_no }}</b>
							<br />
							<slot
								v-if="
									myOrder.details && Object.keys(myOrder.details).length > 0
								"
							>
								<small
									v-if="myOrder.details[0] && myOrder.details[0].product"
									>{{ myOrder.details[0]["product"].product_name }}</small
								>
							</slot>
						</td>
						<td class="custom-responsive">{{ myOrder.date | formatDate }}</td>
						<td class="custom-responsive">৳ {{ myOrder.total_amount }}</td>
						<td class="custom-responsive">
							<span
								class="text-info badge badge-secondary text-light"
								v-if="myOrder.payment_status == 'pending'"
							>
								<i class="fa fa-info-circle text-warning"></i>
								Your payment is Incomplete
							</span>
							<span
								class="text-info d-block"
								v-if="myOrder.status == 'pending'"
							>
								<i class="fa fa-clock-o"></i> Your Order request is Pending
							</span>
							<span
								class="text-success d-block"
								v-else-if="myOrder.status == 'approved'"
							>
								<i class="fa fa-clock-o"></i>
								Your Order request is Approved
							</span>
							<span
								class="text-danger d-block"
								v-else-if="myOrder.status == 'not_available'"
							>
								<i class="fa fa-clock-o"></i> Sorry! Not available this
								products.
							</span>
							<span
								v-b-tooltip.hover.topleft
								:title="myOrder.delivery_info"
								class="text-primary d-block"
								v-else-if="myOrder.status == 'shipping'"
							>
								<i class="fa fa-check"></i> Your Order has been Shipped
								<br />
								Shipped Date:
								{{ myOrder.shipping_date | formatDate }}
							</span>
							<span
								v-b-tooltip.hover.topleft
								:title="myOrder.delivered_remarks"
								class="text-success d-block"
								v-else-if="myOrder.status == 'delivered'"
							>
								<i class="fa fa-clock-o"></i>
								Your Order has been Delivered
								<br />
								Delivered Date: {{ myOrder.delivery_date | formatDate }}
							</span>
							<span
								class="text-danger d-block"
								v-else-if="myOrder.status == 'cancel'"
							>
								<i class="fa fa-times"></i> Your Order has been Cancelled
							</span>
							<span
								class="text-warning d-block"
								v-else-if="myOrder.status == 'return_pending'"
							>
								<i class="fa fa-clock-o"></i> Your order return request is
								pending
							</span>
							<span
								class="text-warning d-block"
								v-else-if="myOrder.status == 'return_cancel'"
							>
								<i class="fa fa-clock-o"></i> Your order return request is
								cancel
							</span>
							<span
								class="text-warning d-block"
								v-else-if="myOrder.status == 'return'"
							>
								<i class="fa fa-clock-o"></i> Your order return request is
								Approved
							</span>

							<span
								class="text-danger d-block"
								v-else-if="myOrder.status == 'return_cancel'"
							>
								<i class="fa fa-times"></i> Return request Reject !
							</span>
						</td>
						<td>
							<a
								:href="
									$root.baseurl +
									'/ecommerce/generate-ecommerce-invoice/' +
									myOrder.order_no
								"
								target="_blank"
								class="action-button view-invoice"
							>
								<i class="fa fa-print"></i> View invoice
							</a>
							<router-link
								:to="{
									name: 'ecommerce.paymentmethod',
									params: { id: myOrder.order_no },
								}"
								v-if="myOrder.status == 'pending'"
								class="action-button paynow"
							>
								<i class="fa fa-paypal"></i> Pay Now
							</router-link>
							<a
								:href="
									$root.baseurl +
									'/ecommerce/generate-ecommerce-money-receive/' +
									myOrder.order_no
								"
								target="_blank"
								class="action-button view-money-receipt"
								v-if="myOrder.payment_status == 'success'"
							>
								<i class="fa fa-money-check"></i> View Money Receipt
							</a>
							<span
								v-if="
									myOrder.status == 'delivered' ||
									myOrder.status == 'cancel' ||
									myOrder.status == 'return' ||
									myOrder.status == 'return_pending' ||
									myOrder.status == 'return_cancel'
								"
							></span>
							<span v-if="myOrder.payment_status != 'success'">
								<a
									@click="CencelOrder(myOrder.id)"
									class="action-button cancle-order"
								>
									<i class="fa fa-times-circle"></i> Cancel Order
								</a>
							</span>
							<span>
								<span
									v-if="
										myOrder.status == 'return' ||
										myOrder.status == 'return_pending' ||
										myOrder.status == 'return_cancel'
									"
								></span>
								<span
									v-else-if="
										myOrder.status == 'delivered' && myOrder.return_order_req
									"
								>
									<a
										@click="returnOrder(myOrder.id)"
										class="btn action-button return-order"
									>
										<i class="fa fa-reply-all"></i>Return Order
									</a>
								</span>
								<span v-else></span>
							</span>
							<span>
								<span>
									<a
										@click="orderTracking(myOrder)"
										class="btn action-button orderTracking"
									>
										<i class="fa fa-map-signs"></i> Order Tracking
									</a>
								</span>
							</span>
						</td>
					</tr>
				</slot>
			</table>

			<EmptyCart list-name="cart" v-else />
		</div>
		<div>
			<b-modal
				id="modal-center"
				centered
				title="Reasons of returning products"
				:hide-footer="true"
			>
				<div class="form-group col-12">
					<textarea
						v-model="returnData.remarks"
						class="form-control textarea"
						name="remarks"
						required
						placeholder="Please mention why you want to return this product"
					></textarea>
				</div>
				<div class="col-12 mb-3 mt-2">
					<button @click="returnOrderSubmit()" class="btn btn-primary">
						Submit
					</button>
				</div>
			</b-modal>
			<b-modal
				id="order-cancel"
				centered
				title="Reasons of cancel products"
				:hide-footer="true"
			>
				<div class="form-group col-12">
					<textarea
						v-model="order_cancel.remarks"
						class="form-control textarea"
						name="remarks"
						required
						placeholder="Please mention why you want to cancel this product"
					></textarea>
				</div>
				<div class="col-12 mb-3 mt-2">
					<button @click="orderCancelSubmit()" class="btn btn-primary">
						Submit
					</button>
				</div>
			</b-modal>

			<b-modal
				id="order-tracking"
				size="lg"
				centered
				title="Order Tracking"
				:hide-footer="true"
			>
				<div class="row" v-if="order_info">
					<div class="col-6">
						<b>Order ID:</b>
						{{ order_info.order_no }}
					</div>
					<div class="col-6">
						<b>Product Name:</b>
						<slot v-for="(purchaseDetails, index) in order_info.details">
							<span v-if="index == 0" :key="index">
								{{ purchaseDetails.product.product_name }}
							</span>
						</slot>
					</div>
					<div class="col-6">
						<b>Amount:</b>
						{{ order_info.total_amount }}
					</div>
					<div class="col-6" v-if="order_info.order_shipping_info">
						<b>Delivery Address:</b>
						{{ order_info.order_shipping_info.shipping_address }}
					</div>
				</div>
				<br />
				<h5 class="text-center py-1 tracking-status">Tracking Status</h5>
				<div class="form-group col-12"></div>
				<div
					class="row"
					v-if="order_info && Object.keys(order_info.tracking_log).length > 0"
				>
					<div class="timeline-centered">
						<article
							class="timeline-entry"
							v-for="(item, index) in order_info.tracking_log"
							:key="index"
						>
							<div class="timeline-entry-inner">
								<time class="timeline-time" datetime="2014-01-10T03:45">
									{{ item.date | formatDate }}
								</time>
								<div class="timeline-icon">
									<i style="color: #28a745" class="fa fa-check"></i>
								</div>
								<div class="timeline-label">{{ item.status }}</div>
							</div>
						</article>
					</div>
				</div>
				<slot v-else>
					<p class="text-danger text-center">No Tracking</p>
				</slot>
			</b-modal>
		</div>
	</div>
</template>

<script>
	import EmptyCart from "../elements/EmptyCart";
	import swal from "sweetalert";

	export default {
		name: "Myorder",
		components: { EmptyCart },
		data() {
			return {
				myOrders: {
					details: [
						{
							product: {
								product_name: "",
							},
						},
					],
				},
				returnData: { remarks: "", id: "" },
				order_cancel: { remarks: "", id: "" },
				order_info: {
					tracking_log: [],
					order_shipping_info: { shipping_address: "" },
					details: [{ product: { product_name: "" } }],
				},
			};
		},
		methods: {
			CencelOrder(id) {
				this.order_cancel.id = id;
				this.$bvModal.show("order-cancel");
			},
			returnOrder(id) {
				this.returnData.id = id;
				this.$bvModal.show("modal-center");
			},
			returnOrderSubmit() {
				if (this.returnData.remarks == "") {
					this.$toastr(
						"warning",
						"Please write your return reason!",
						"Warning!!"
					);
					return false;
				}
				if (confirm("Are you sure? You want to return your Order?")) {
					axios
						.post("ecommerce/order-return", this.returnData)
						.then((res) => {
							if (res.status == 200) {
								this.$toastr("success", res.data.message, "Success!!");
								this.getMyOrders();
								this.$bvModal.hide("modal-center");
							}
						})
						.catch((error) => console.log(error));
				} else {
					this.$bvModal.hide("modal-center");
					this.getMyOrders();
				}
			},
			orderCancelSubmit() {
				if (this.order_cancel.remarks == "") {
					this.$toastr(
						"warning",
						"Please write your cancel reason!",
						"Warning!!"
					);
					return false;
				}
				if (confirm("Are you sure? You want to cancel Order?")) {
					axios
						.post("ecommerce/order-cancel", this.order_cancel)
						.then((res) => {
							if (res.status === 200) {
								this.$toastr("success", res.data.message, "Success!!");
								this.getMyOrders();
								this.order_cancel.remarks = "";
								this.$bvModal.hide("order-cancel");
							}
						})
						.catch((error) => console.log(error));
				} else {
					this.$bvModal.hide("order-cancel");
					this.getMyOrders();
				}
			},
			getMyOrders() {
				// My Order
				axios
					.get("/ecommerce/get-my-orders")
					.then((res) => (this.myOrders = res.data));
			},
			orderTracking(order) {
				this.order_info = order;
				this.$bvModal.show("order-tracking");
			},
			sslPayment: function (order_no) {
				this.scrollTop();
				this.$root.spinner = true;
				axios
					.post("/ssl-payment", {
						type: "ecommerce_product_buy",
						id: order_no,
						invoice_id: order_no,
						// sandbox: "sandbox"
					})
					.then((res) => {
						if (res.data.GatewayPageURL) {
							window.location.href = res.data.GatewayPageURL;
						}
					});
			},
		},

		created() {
			this.$eventBus.$emit("miniLoader", true);
			this.getMyOrders();
			setTimeout(() => {
				this.$eventBus.$emit("miniLoader", false);
			}, 1000);
		},
		beforeCreate() {
			this.$root.loading = false;
		},
	};
</script>

<style scoped lang="scss">
	th {
		background: rgb(244 244 244);
		border: 0;
		box-shadow: 0px 1px 1px #4444;
	}

	td {
		border: 0;
		background: rgb(255 255 255);
		box-shadow: 0px 0px 1px #4444;
	}

	.textarea {
		height: 100px;
	}

	a.action-button {
		display: block;
		margin: 5px 0px;
		text-align: center;
		padding: 5px 0px;
		font-weight: 600;
		border: 1px solid transparent;
		cursor: pointer;
		white-space: nowrap;
		border-radius: 10px;
		transition: 0.5s;
		box-shadow: 0px 0px 7px 2px #d5d5d569;
	}
	a.action-button:hover {
		box-shadow: 0px 0px 7px 2px #93939369;
		border: 1px solid;
	}

	a.action-button.view-invoice {
		/*background: #d0f0db;*/
		background: #ffffffa3;
		color: #07ac07;
	}

	a.action-button.view-money-receipt {
		/*background: #0097d742;*/
		background: #ffffffa3;
		color: #2b9dff;
	}

	a.action-button.cancle-order {
		/*background: #ff7e8a61;*/
		color: #f02525;
		background: #ffffffa3;
	}

	a.action-button.return-order {
		/*background: #0097d742;*/
		color: #2b9dff;
		background: #ffffffa3;
	}
	a.action-button.paynow {
		/*background: #0097d742;*/
		color: #2b9dff;
		background: #ffffffa3;
	}
	a.action-button.orderTracking {
		/*background: #af27c742;*/
		color: #c73cff;
		background: #ffffffa3;
	}
</style>
