<template>
	<div class="col-md-8 login-page">
		<div class="card-box">
			<div class="row">
				<div class="col-md-6">
					<div class="left-content">
						<img class="basis-logo" :src="$root.baseurl + '/public/images/logo.svg'" alt="basislogo" />
						<h4>Welcome to BASIS DigitalShop</h4>

						<img
							class="login-image"
							:src="$root.baseurl + '/public/images/ecommerce-login.jpg'"
							alt="basislogo"
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div v-if="!$root.spinner" class="right-form">
						<!-- LOGIN SECTION -->
						<!-- LOGIN SECTION -->
						<div v-if="loginSection" class="row justify-content-center align-items-center">
							<div class="col-lg-12">
								<h3 class="text-center">
									<span v-if="!forgotPassword">Please login to your account.</span>
									<span v-else>Reset Password</span>
								</h3>
								<div class="form-group">
									<label for class="col-form-label-sm m-0">Email</label>
									<div class="loginBox">
										<input
											:class="{
                        'is-invalid': validation.hasError('data.email'),
                        'is-valid': data.email,
                      }"
											type="email"
											autocomplete="off"
											onfocus="this.placeholder = ''"
											onblur="this.placeholder = 'ie: customer@mail.com'"
											placeholder="ie: customer@mail.com"
											name="email"
											class="form-control form-control-sm"
											v-model="data.email"
										/>
										<span class="mdi mdi-email"></span>
									</div>
									<div class="invalid-feedback">{{ validation.firstError("data.email") }}</div>
								</div>
								<div class="form-group" v-if="!forgotPassword">
									<label for class="col-form-label-sm m-0">Password</label>
									<div class="loginBox">
										<input
											:class="{
                        'is-invalid': validation.hasError('data.password'),
                        'is-valid': data.password,
                      }"
											type="password"
											autocomplete="off"
											onfocus="this.placeholder = ''"
											onblur="this.placeholder = 'ie: A-Z, a-z,digit(0-9),special character'"
											placeholder="ie: A-Z, a-z,digit(0-9),special character"
											name="password"
											class="form-control form-control-sm"
											v-model="data.password"
											v-on:keyup.enter="submit"
										/>
										<span class="mdi mdi-shield-lock-outline"></span>
									</div>
									<div class="invalid-feedback">{{ validation.firstError("data.password") }}</div>
								</div>
								<div class="row justify-content-between align-items-center mx-2">
									<a
										href="javascript:void(0)"
										class="d-block"
										v-if="!forgotPassword"
										@click="forgotPassword = true"
										style="font-weight: 500 !important; color: #007f3d"
									>Forgot Password?</a>

									<span>
										Create an Account?
										<router-link :to="{ name: 'ecommerce.registration' }">Signup</router-link>
									</span>
								</div>

								<div class="row justify-content-around align-items-center mt-5 mx-2">
									<div>
										<button @click="submit()" class="btn btn-success mt-1">
											<span v-if="spinner">
												<i class="fa fa-spinner fa-spin"></i> processing...
											</span>
											<span v-else>
												<span v-if="!forgotPassword">Login</span>
												<span v-else>Next</span>
											</span>
										</button>
									</div>
									<div v-if="loginSection">
										<router-link class="btn btn-info mt-1" :to="{ name: 'ecommerce.memberLogin' }">
											<i class="fa fa-user"></i> Login with BASIS Member
										</router-link>
									</div>
								</div>
							</div>
						</div>

						<!-- OTP VERIFY -->
						<!-- OTP VERIFY -->
						<div v-if="otpSection" class="row justify-content-center align-items-center">
							<div class="col-lg-12">
								<fieldset class="border rounded p-3">
									<div class="text-success font-weight-bold">Please check your email</div>
									<table class="table table-borderless">
										<tr>
											<th>Name:</th>
											<td v-if="user.name">{{ user.name }}</td>
										</tr>
										<tr>
											<th>Email Address:</th>
											<td v-if="user.email">{{ user.email }}</td>
										</tr>
										<tr>
											<td colspan="2">
												<div class="row align-items-center m-0">
													<div class="col-8 p-0">
														<input
															v-model="otp"
															type="text"
															class="form-control form-control-sm"
															placeholder="OTP"
															name="otp"
															v-on:keyup.enter="verifyOtp"
														/>
													</div>
													<div class="col-4 p-0">
														<button type="submit" class="btn btn-sm btn-primary text-white" @click="verifyOtp()">
															<span v-if="spinner">
																<i class="fa fa-spinner fa-spin"></i>
																processing...
															</span>
															<span v-else>Verify OTP</span>
														</button>
													</div>
													<div class="col-12 p-0">
														<a
															@click="otpMethodSelect()"
															href="javascript:void(0);"
															class="btn-custom text-primary font-weight-bold"
														>
															<span v-if="spinner">
																<i class="fa fa-spinner fa-spin"></i>
																processing...
															</span>
															<span v-else>Resend OTP</span>
														</a>
													</div>
												</div>
											</td>
										</tr>
									</table>
								</fieldset>
							</div>
						</div>

						<!-- CHANGE PASSWORD-->
						<!-- CHANGE PASSWORD-->
						<div v-if="changePassword" class="row justify-content-center align-items-center">
							<div class="col-lg-12">
								<h3 class="text-center">Change Password</h3>

								<div class="form-group">
									<label for class="col-form-label-sm m-0">New Password</label>
									<div class="loginBox">
										<input
											:class="{
                        'is-invalid': validation.hasError('data.new_password'),
                        'is-valid': data.new_password,
                      }"
											type="password"
											placeholder="ie: A-Z, a-z,digit(0-9),special character"
											class="form-control form-control-sm"
											v-model="data.new_password"
										/>
										<span class="mdi mdi-shield-lock-outline"></span>
									</div>
									<div class="invalid-feedback">{{ validation.firstError("data.new_password") }}</div>
								</div>
								<div class="form-group">
									<label for class="col-form-label-sm m-0">Confirm Password</label>
									<div class="loginBox">
										<input
											:class="{
                        'is-invalid': validation.hasError(
                          'data.confirm_password'
                        ),
                        'is-valid': data.confirm_password,
                      }"
											type="password"
											placeholder="ie: A-Z, a-z,digit(0-9),special character"
											class="form-control form-control-sm"
											v-model="data.confirm_password"
										/>
										<span class="mdi mdi-shield-lock-outline"></span>
									</div>
									<div class="invalid-feedback">{{ validation.firstError("data.confirm_password") }}</div>
								</div>
								<button
									@click="changePasswordSubmit()"
									class="btn btn-success mt-4 float-right"
								>Change Password</button>
							</div>
						</div>
					</div>
					<div v-if="$root.spinner" class="right-form">
						<spinner />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		name: "Login",
		data() {
			return {
				data: { new_password: null },
				user: {},
				otp: "",
				spinner: false,
				loginSection: true,
				otpSection: false,
				forgotPassword: false,
				changePassword: false,
				priviousRouteName: ""
			};
		},
		methods: {
			submit: function(e) {
				if (this.forgotPassword) {
					this.checkEmail();
					return false;
				}
				this.$validate().then(res => {
					if (res) {
						this.$root.spinner = true;
						axios
							.post("/ecommerce/login/submit", this.data)
							.then(res => {
								if (res.data.code == 202) {
									this.$toastr("success", res.data.msg, "Success!!");

									if (this.priviousRouteName) {
										if (this.priviousRouteName == "ecommerce.checkout") {
											window.location.href =
												this.$root.baseurl + "/digitalshop/checkout";
										} else if (this.priviousRouteName == "ecommerce.addtocart") {
											window.location.href =
												this.$root.baseurl + "/digitalshop/add-to-cart";
										} else {
											window.location.href =
												this.$root.baseurl + "/digitalshop/dashboard";
										}
									} else {
										window.location.href =
											this.$root.baseurl + "/digitalshop/dashboard";
									}
								} else if (res.data.code == 401) {
									this.$root.spinner = false;
									this.$toastr("error", res.data.msg, "Sorry!!");
								}
							})
							.catch(error => {
								if (error) {
									this.$toastr("error", "Invalid email or password", "Sorry!!");
									this.$root.spinner = false;
								}
							});
					}
				});
			},
			checkEmail() {
				if (!this.data.email) {
					this.$toastr("error", "Email field is required", "Sorry!!");
					return false;
				}
				this.spinner = true;
				axios
					.post("ecommerce/forget-password/check-email", this.data)
					.then(res => {
						if (res.data.code == 200) {
							this.user = res.data.user;
							this.spinner = false;
							this.loginSection = false;
							this.otpSection = true;
							this.sendMail();
						} else if (res.data.code == 401) {
							this.$root.spinner = false;
							this.spinner = false;
							this.$toastr("error", res.data.msg, "Sorry!!");
						}
					});
			},
			otpMethodSelect() {
				this.spinner = true;
				this.sendMail();
			},
			sendMail() {
				axios
					.post("/send-otp-mail", {
						email: this.data.email,
						customer: true
					})
					.then(res => {
						if (res.data.code != 201) {
							this.$toastr(
								"success",
								"A OTP Passcode has been sent to your mail.Please enter the OTP below to verify your Email Address. If you cannot see the email in your inbox, make sure to check your SPAM/Promotions folder",
								"Email Sent!"
							);
						} else {
							this.$toastr("success", res.data.msg, res.data.type);
						}
					})
					.catch(error => console.log(error))
					.then(alw => setTimeout(() => (this.spinner = false), 300));
			},
			verifyOtp() {
				axios.post("otp-check", { otp: this.otp }).then(res => {
					if (res.data) {
						this.otpSection = false;
						this.changePassword = true;
					} else {
						this.$toastr("warning", "OTP doesn't match", "Sorry!!");
					}
				});
			},
			changePasswordSubmit() {
				this.$validate().then(res => {
					if (res) {
						this.$root.spinner = true;
						let data = {
							new_password: this.data.new_password,
							id: this.user.id
						};
						axios
							.post("ecommerce/forget-password/change-password", data)
							.then(res => {
								if (res.data.code == 200) {
									window.location.href =
										this.$root.baseurl + "/digitalshop/dashboard";
									this.$toastr("success", res.data.msg, "Success!!");
								} else if (res.data.code == 201) {
									this.$root.spinner = false;
									this.$toastr("error", res.data.msg, "Sorry!!");
								}
							});
					}
				});
			}
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
			if (this.$root.ecomLogged) {
				this.$router.push({ name: "ecommerce.dashboard" });
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.from = from;
				vm.priviousRouteName = vm.from.name;
			});
		},
		validators: {
			"data.email": function(value = null) {
				if (!this.changePassword) {
					return Validator.value(value)
						.required("Email is required")
						.email();
				}
			},
			"data.password": function(value = null) {
				if (!this.forgotPassword) {
					return Validator.value(value).required("Password is required");
				}
			},
			"data.new_password": function(value = null) {
				if (this.changePassword) {
					return Validator.value(value)
						.required("Password is required")
						.regex("(?=.*?[A-Z])", "at least one uppercase letter required")
						.regex("(?=.*?[a-z])", "at least one lowercase letter required")
						.regex("(?=.*?[0-9])", "at least one digit required")
						.regex(
							"(?=.*?[#?!@$%^&*-])",
							"at least one special charater required"
						)
						.minLength(6);
				}
			},
			"data.confirm_password, data.new_password": function(
				confirm_password,
				new_password
			) {
				if (this.changePassword) {
					return Validator.value(confirm_password)
						.required("Password confirmation is required")
						.match(new_password);
				}
			}
		}
	};
</script>
