<template>
	<div class="servicePage">
		<PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
		<div class="content-body py-4">
			<div class="container-wrapper">
				<div class="row">
					<div class="col-lg-3">
						<affix
							class="sidebar-menu"
							relative-element-selector="#example-content"
						>
							<leftmenu />
						</affix>
					</div>
					<div class="col-lg-9">
						<div id="example-content">
							<img
								class="img-card-top"
								v-if="this.$route.params.type == 'SJIBL'"
								v-lazy="data.SJIBL"
								alt="SJIBL"
							/>
							<img
								class="img-card-top"
								v-if="this.$route.params.type == 'IPDC'"
								v-lazy="data.IPDC"
								alt="IPDC"
							/>

							<span v-if="this.$route.params.type == 'IDLC'">IDLC</span>

							<div v-if="this.$route.params.type == 'BRAC-BANK'">
								<div class="col-xs-12 col-lg-12">
									<img
										v-lazy="data.brac_bank"
										class="img-fluid border mb-3 brack_loan"
									/>
								</div>
								<div class="col-xs-12 col-lg-12" style="text-align: justify">
									<p>
										ব্রাক ব্যাংক কেবলমাত্র বেসিস সদস্যদের জন্য ‘দিগন্ত’ নামে
										একটি বিশেষ ঋণ সুবিধা চালু করেছে । এর আওতায় এককালীন ১ কোটি
										টাকা পর্যন্ত জামানতবিহহীন ঋণ নেওয়ার সুযোগ আছে।
									</p>
									<br />
									‘দিগন্ত’ প্যাকেজের প্রধান বৈশিষ্টগুলো নিম্নে উল্লেখ করা
									হলোঃ<br />
									(১) ঋণের মেয়াদ ১২ থেকে ৩৬ মাস পর্যন্ত। <br />
									(২) এই ঋণের জন্য কোনো জামানতের প্রয়োজন নেই।<br />
									(৩) বর্ণিত ঋণের উপর ৯% সুদের হার প্রযোজ্য হবে।<br />
									(৪) ঋণ গ্রহণের জন্য আবেদনকারী প্রতিষ্ঠানের ব্যবসার বয়স ন্যুনতম
									৩ (তিন) বছর হতে হবে।<br />
									(৫) বাংলাদেশ ব্যাংকের প্রণোদনা প্যাকেজের আওতায় অন্য কোনো ঋণ
									(যেমন- Stimulus Package for CMSME) বা আর্থিক সুবিধা প্রাপ্তিরও
									সুযোগ থাকবে। <br />
									(৬) রিভলভিং লোনের আওতায় ওয়ার্ক অর্ডার ফাইন্যান্সিং পাওয়ারও
									সুযোগ থাকবে। <br />
									(৭) এক কিস্তিতে ৩-১২ মাসের মধ্যে পরিশোধযোগ্য ঋণও নেওয়ার সুযোগ
									থাকবে। <br />
									(৮) উল্লেখ্য, এছাড়াও ‘তারা’ প্যাকেজের আওতায় নারী উদ্যোক্তাগণ
									৭% সুদে ঋণ নিতে পারবেন।<br /><br />

									<p>
										<b
											>ঋণ গ্রহণে আগ্রহী বেসিস সদস্যগণ নিম্নোক্ত লিঙ্কের মাধ্যমে
											সরাসরি ব্রাক ব্যাংকে আবেদন করতে পারবেন। <br />
											লিংকঃ
											<a
												target="_blank"
												href="https://www.bracbank.com/en/applyNow"
												>https://www.bracbank.com/en/applyNow</a
											></b
										>
									</p>
									<br />
									এ বিষয়ে কোনো জিজ্ঞাস্য থাকলে ব্রাক ব্যাংকের কর্মকর্তা
									<b
										>জনাব মুহিত শাহনেওয়াজ (muhit.shahnewaz@bracbank.com, ফোনঃ
										+88 0171 335 0222) এবং জনাব আদনান জামিলী
										(adnaan.jamilee@bracbank.com, ফোনঃ +88 0171 313 7294)
									</b>
									-এর সাথে যোগাযোগ করতে অনুরোধ করা হলো।
								</div>
							</div>

							<!-- for prime bank section -->
							<div v-if="this.$route.params.type == 'PRIME-BANK'">
								<div class="col-xs-12 col-lg-12" style="text-align: justify">
									<p>
										PRIME BANK - BASIS Alliance For ICT-MSME Financing Solution
										For the last 25 years, Prime Bank has helped numerous small
										and medium-sized businesses prosper by leveraging the Bank's
										strong network footprint across the country. Recognized as a
										major player in the MSME community, Prime Bank is proud to
										announce an alliance with the Bangladesh Association of
										Software and Information Services (BASIS) aiming to help
										ICT-MSMES propel their businesses forward, ensuring a
										greater chance of success through the adoption of digital
										tools and capabilities. Under this alliance, BASIS members
										will enjoy a wide range of financial services including
										special loan privileges from Prime Bank.
									</p>
									<br />
								</div>
								<div class="col-xs-12 col-lg-12">
									<img v-lazy="data.PRIME_BANK" class="img-fluid border mb-3" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import SJIBL from "../../assets/img/loan_facility/SJIBL_Details.jpg";
	import IPDC from "../../assets/img/loan_facility/IPDC_Details.jpg";
	import brac_bank_cover from "../../assets/img/loan_facility/brac_bank_banner.jpg";
	import PRIME_BANK from "../../assets/img/loan_facility/prime_bank_details.jpg";
	import PageBanner from "../../components/elements/PageBanner";
	import leftmenu from "../../components/elements/leftmenu";
	import axios from "axios";
	import { Affix } from "vue-affix";
	const routesname = {
		view: "slider_content.show",
	};
	export default {
		name: "LoanFacilityDetails",
		components: {
			PageBanner,
			leftmenu,
			Affix,
		},
		data() {
			return {
				breadcrumbTitle: "Service",
				breadcumb: [{ title: "Service" }],
				data: {
					SJIBL: this.$root.baseurl + SJIBL,
					IPDC: this.$root.baseurl + IPDC,
					PRIME_BANK: this.$root.baseurl + PRIME_BANK,
					brac_bank: this.$root.baseurl + brac_bank_cover,
				},
			};
		},
		mounted() {
			setTimeout(() => (this.$root.loading = false), this.$root.minloadtimefd);
		},
	};
</script>

<style >
	.brack_loan {
		height: 300px;
		width: 100%;
	}
	@media screen and (max-width: 400px) {
		.brack_loan {
			height: 130px;
			width: 100%;
		}
	}
</style>