<template>
	<div class="customer-profile">
		<h3 class="my-4">Change Password</h3>
		<div class="row col-12 col-lg-6">
			<div class="col-md-12">
				<div class="alert alert-danger" role="alert" v-if="old_password">
					<h4 class="alert-heading">Error!</h4>
					<p>
						{{ errorMsg }}
					</p>

					<hr />
				</div>
				<div class="form-group">
					<label for="" class="col-form-label-sm m-0"
						>Old Password <span class="text-danger">*</span></label
					>
					<div class="loginBox">
						<input
							:class="{
								'is-invalid': validation.hasError('data.old_password'),
								'is-valid': data.old_password
							}"
							type="password"
							autocomplete="off"
							placeholder="old password"
							name="old_password"
							v-model="data.old_password"
							class="form-control form-control-sm"
						/>
						<div class="invalid-feedback">
							{{ validation.firstError("data.old_password") }}
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label for="" class="col-form-label-sm m-0"
						>New Password <span class="text-danger">*</span></label
					>
					<div class="loginBox">
						<input
							:class="{
								'is-invalid': validation.hasError('data.new_password'),
								'is-valid': data.new_password
							}"
							type="password"
							autocomplete="off"
							name="new_password"
							v-model="data.new_password"
							placeholder="new password"
							class="form-control form-control-sm"
						/>
						<div class="invalid-feedback">
							{{ validation.firstError("data.new_password") }}
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label for="" class="col-form-label-sm m-0"
						>Confirm Password <span class="text-danger">*</span></label
					>
					<div class="loginBox">
						<input
							:class="{
								'is-invalid': validation.hasError('data.confirm_password'),
								'is-valid': data.confirm_password
							}"
							type="password"
							placeholder="confirm password"
							name="confirm_password"
							autocomplete="off"
							v-model="data.confirm_password"
							class="form-control form-control-sm"
						/>
						<div class="invalid-feedback">
							{{ validation.firstError("data.confirm_password") }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<button v-on:click.prevent="submit()" class="btn btn-primary mt-4 ml-3">
			Update Password
		</button>
	</div>
</template>

<script>
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		name: "PasswordChange",
		data() {
			return {
				data: {
					old_password: "",
					new_password: "",
					confirm_password: ""
				},
				old_password: false,
				errorMsg: ""
			};
		},

		methods: {
			submit: function(e) {
				this.$validate().then(res => {
					if (res) {
						if (this.data.new_password == this.data.confirm_password) {
							this.$root.spinner = true;
							axios
								.post(
									"ecommerce/password-change/" + this.$root.loggedCustomer.id,
									this.data
								)
								.then(respons => {
									if (respons.data.status == 200) {
										this.$toastr("success", respons.data.message, "Success!!");
										this.logoutCustomer();
										(this.old_password = false), (this.errorMsg = "");
									}
									if (respons.data.status == 300) {
										this.old_password = true;
										this.errorMsg = respons.data.message;
										this.$toastr("error", respons.data.message, "Warning!!");
									}
								})
								.catch(error => {})
								.then(alw => (this.$root.spinner = false));
						} else {
							this.old_password = true;
							this.errorMsg = "New password & confirm password not match!";
							this.$toastr(
								"error",
								"New password & confirm password not match!",
								"Warning!!"
							);
						}
					}
				});
			}
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		validators: {
			"data.old_password": function(value = null) {
				return Validator.value(value).required("Old password is required");
			},
			"data.new_password": function(value = null) {
				return Validator.value(value).required("New password is required");
			},
			"data.confirm_password": function(value = null) {
				return Validator.value(value).required("Confirm password is required");
			}
		}
	};
</script>

<style>
</style>
