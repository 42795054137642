<template>
  <div id="example-content">
    <div class="servicePage">
      <!--Heading-->
      <h5 class="page-title font-weight-bold text-uppercase">
        {{ breadcrumbTitle }}
      </h5>

      <div class="service-tab">
        <div class="content-header mb-5">
          <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="paid-tab" data-toggle="tab" href="#paid" role="tab" aria-controls="paid"
                aria-selected="true">Paid Service</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="free-tab" data-toggle="tab" href="#free" role="tab" aria-controls="free"
                aria-selected="false">Free Service</a>
            </li>
          </ul>
        </div>
        <div class="service-item tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="paid" role="tabpanel" aria-labelledby="paid-tab">
            <div class="service-item py-4" :class="$route.params.type == 'member' ? 'member' : 'nonmember'">
              <div class="row" v-if="data.paidService && !$root.spinner">
                <slot v-for="(service, index) in data.paidService">
                  <div :key="index" class="col-lg-4">
                    <router-link v-if="service.external_page == 0" :to="{
                      name: 'service_detail',
                      params: { id: service.id, type: $route.params.type },
                    }">
                      <div class="card card-body shadow-sm">
                        <div class="iconbox">
                          <span v-html="service.icon"></span>
                        </div>
                        <h3>{{ service.title }}</h3>
                      </div>
                    </router-link>
                    <router-link v-else :to="
                      '/' +
                      service.form_link +
                      '/' +
                      service.id +
                      '/' +
                      $route.params.type
                    ">
                      <div class="card card-body shadow-sm">
                        <div class="iconbox">
                          <span v-html="service.icon"></span>
                        </div>
                        <h3>{{ service.title }}</h3>
                      </div>
                    </router-link>
                  </div>
                  <div :key="'a' + index" class="w-100 mb-4" v-if="index % 3 == 2"></div>
                </slot>
              </div>
              <spinner v-if="$root.spinner" />
            </div>
          </div>
          <div class="tab-pane fade" id="free" role="tabpanel" aria-labelledby="free-tab">
            <div class="service-item py-4" :class="$route.params.type == 'member' ? 'member' : 'nonmember'">
              <div class="row" v-if="data.freeService && !$root.spinner">
                <slot v-for="(service, index) in data.freeService">
                  <div :key="index" class="col-lg-4">
                    <router-link v-if="service.external_page == 0" :to="{
                      name: 'service_detail',
                      params: { id: service.id, type: $route.params.type },
                    }">
                      <div class="card card-body shadow-sm">
                        <div class="iconbox">
                          <span v-html="service.icon"></span>
                        </div>
                        <h3>{{ service.title }}</h3>
                      </div>
                    </router-link>
                    <router-link v-else :to="
                      '/' +
                      service.form_link +
                      '/' +
                      service.id +
                      '/' +
                      $route.params.type
                    ">
                      <div class="card card-body shadow-sm">
                        <div class="iconbox">
                          <span v-html="service.icon"></span>
                        </div>
                        <h3>{{ service.title }}</h3>
                      </div>
                    </router-link>
                  </div>
                  <div :key="'a' + index" class="w-100 mb-4" v-if="index % 3 == 2"></div>
                </slot>
              </div>
              <spinner v-if="$root.spinner" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Service",
  data() {
    return {
      breadcrumbTitle: "Service",
      breadcumb: [{ title: "Service" }],
      data: {
        paidService: {},
        freeService: {},
      },
    };
  },
  methods: {
    asyncData() {
      this.get_datas();
      this.$root.loading = false;
    },
    get_datas: function (pageindex) {
      this.$root.spinner = true;
      let url = "/get-services/" + this.$route.params.type;
      axios
        .get(url)
        .then((respons) => {
          if (this.$route.params.type == "member") {
            this.breadcrumbTitle = "Member Service";
            this.breadcumb = [
              {
                title: "Service",
                route_name: "service",
                params: this.$route.params.type,
              },
              { title: this.breadcrumbTitle },
            ];
          } else if (this.$route.params.type == "non_member") {
            this.breadcrumbTitle = "Non Member Service";
            this.breadcumb = [
              {
                title: "Service",
                route_name: "service",
                params: this.$route.params.type,
              },
              { title: this.breadcrumbTitle },
            ];
          }
          this.data.paidService = respons.data.paid;
          this.data.freeService = respons.data.free;
          this.$root.spinner = false;
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.get_datas();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true,
    },
  },
};
</script>

 