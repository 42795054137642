<template>
  <div id="breadcrumb">
    <!--breadcrumb-->
    <div class="row align-items-center justify-content-between breadcrumb p-1 mb-2">
      <div class="col-lg-12">
        <div v-if="$route.params.status" class="text-dark m-0">
          <b-alert
            v-if="$route.params.status==200"
            :show="dismissCountDown"
            dismissible
            variant="success"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
          >
            <p>{{ $route.params.message }}</p>
            <b-progress variant="success" :max="dismissSecs" :value="dismissCountDown" height="4px"></b-progress>
          </b-alert>
          <b-alert
            v-else
            :show="dismissCountDown"
            dismissible
            variant="warning"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
          >
            <p>{{ $route.params.message }}</p>
            <b-progress variant="warning" :max="dismissSecs" :value="dismissCountDown" height="4px"></b-progress>
          </b-alert>
        </div>
      </div>
      <div class="col-lg-6">
        <h4 class="text-dark m-0">{{ msg }}</h4>
      </div>

      <div class="col-lg-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent float-right m-0 p-0">
            <li class="breadcrumb-item">
              <router-link class="text-secondary text-decoration-none" :to="{name:'dashboard'}">
                <span class="fas fa-home"></span>
                {{ dash }}
              </router-link>
            </li>
            <li class="breadcrumb-item" v-for="(route, index) in data" :key="index">
              <span v-if="(index)==Object.keys(data).length-1 ||!route.route_name">{{route.title}}</span>
              <router-link
                :to="{name:route.route_name}"
                class="text-primary text-decoration-none"
                v-else
              >{{ route.title }}</router-link>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <!--end breadcrumb-->
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    msg: String,
    data: Object
  },
  data: function() {
    return {
      dash: "Dashboard",
      dismissSecs: 5,
      dismissCountDown: 5,
      showDismissibleAlert: true
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    }
  }
};
</script>

<style>
</style>
