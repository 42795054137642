<template id="modal_contact">
	<!-- Modal -->
	<div>
		<h4 class="text-center" v-if="data">
			<span class="text-success">Contact with</span>
			<span class="text-danger" v-if="data.company_name">{{
				data.company_name
			}}</span>
		</h4>
		<form v-on:submit.prevent="submit" class="form-row" v-if="!$root.spinner">
			<div class="form-group col-sm-12">
				<label for class="col-form-label-sm m-0">Name</label>
				<input
					type="text"
					v-model="contacts.name"
					name="name"
					id="name"
					placeholder="Name"
					class="form-control shadow-none"
					:class="{
						'is-invalid': validation.hasError('contacts.name'),
						'is-valid': contacts.name
					}"
				/>
				<div class="invalid-feedback">
					{{ validation.firstError("contacts.name") }}
				</div>
			</div>
			<div class="form-group col-sm-6">
				<label for class="col-form-label-sm m-0">Email</label>
				<input
					v-model="contacts.email"
					type="email"
					name="email"
					id="email"
					placeholder="Email"
					:class="{
						'is-invalid': validation.hasError('contacts.email'),
						'is-valid': contacts.email
					}"
					class="form-control shadow-none"
				/>
				<div class="invalid-feedback">
					{{ validation.firstError("contacts.email") }}
				</div>
			</div>
			<div class="form-group col-sm-6">
				<label for="Phone" class="col-form-label-sm m-0">Cell No:</label>
				<input
					type="text"
					v-model="contacts.phone"
					name="Phone"
					id="Phone"
					placeholder="Phone"
					:class="{
						'is-invalid': validation.hasError('contacts.phone'),
						'is-valid': contacts.phone
					}"
					class="form-control shadow-none"
				/>
				<div class="invalid-feedback">
					{{ validation.firstError("contacts.phone") }}
				</div>
			</div>
			<div class="form-group col-sm-12">
				<label for class="col-form-label-sm m-0">Subject</label>
				<input
					type="text"
					v-model="contacts.subject"
					name="subject"
					id="subject"
					placeholder="Subject"
					class="form-control shadow-none"
					:class="{
						'is-invalid': validation.hasError('contacts.subject'),
						'is-valid': contacts.subject
					}"
				/>
				<div class="invalid-feedback">
					{{ validation.firstError("contacts.subject") }}
				</div>
			</div>
			<div class="form-group col-sm-12">
				<label for class="col-form-label-sm m-0">Message</label>
				<textarea
					rows="4"
					v-model="contacts.message"
					name="message"
					id="message"
					placeholder="Type your message"
					:class="{
						'is-invalid': validation.hasError('contacts.message'),
						'is-valid': contacts.message
					}"
					class="form-control shadow-none"
				></textarea>
				<div class="invalid-feedback">
					{{ validation.firstError("contacts.message") }}
				</div>
			</div>
			<div class="form-group col-sm-6 text-right">
				<button type="submit" class="btn btn-success">
					Send
				</button>
			</div>
		</form>
		<spinner v-if="$root.spinner" />
	</div>
</template>
<script>
	import PageBanner from "../../components/elements/PageBanner";
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);

	const breadcumb = [
		{
			title: "Contact us"
		}
	];
	export default {
		name: "ModalContactForm",
		props: ["data"],
		components: {
			PageBanner
		},
		data() {
			return {
				contacts: {}
			};
		},

		methods: {
			submit: function(e) {
				this.contacts.member_email = this.data.representatives_primary.email;
				this.$validate().then(res => {
					if (res) {
						this.$root.spinner = true;
						axios
							.post("ModalContact", this.contacts)
							.then(response => {
								this.$parent.contactModal = false;
								this.$parent.isActive = false;
								this.contact = {};
								if (this.data.company_name) {
									this.$toastr("success", "Message Sent", "Success!!");
								} else {
									this.$toastr(
										"success",
										"Message Sent! A person will contact with you soon from this company",
										"Success!!"
									);
								}
								this.$root.spinner = false;
							})
							.catch(error => {
								if (error.response.status === 422) {
									this.errors = error.response.data.errors || {};
									this.focus_field = Object.keys(this.errors)[0];
								}
							});
					}
				});
			}
		},
		mounted() {
			this.$root.loading = false;
		},
		validators: {
			"contacts.name": function(value = null) {
				return Validator.value(value).required("Name is required");
			},
			"contacts.email": function(value = null) {
				return Validator.value(value)
					.required("Email is required")
					.email("Email must be well formatted");
			},
			"contacts.phone": function(value = null) {
				return Validator.value(value).required("Cell No is required");
			},
			"contacts.subject": function(value = null) {
				return Validator.value(value).required("Subject is required");
			},
			"contacts.message": function(value = null) {
				return Validator.value(value).required("Message is required");
			}
		}
	};
</script>

<style>
</style>