<template>
  <div class="memberLogin py-5" style="min-height:450px;">
    <div class="container-wrapper">
      <div class="row justify-content-md-center">
        <div class="col-lg-4 p-0">
          <div class="alert alert-warning" role="alert">
            <h4 class="alert-heading">Notice!</h4>
            <p>
              We didn't find your company profile or your membership application not approved yet . Please note that,
              your OSS PID email & BASIS Representative email ID must be identical.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
export default {
  name: "Authenticate",
  data() {
    return {};
  },
  methods: {},
  created() {
    axios.get("/member/loggedOrNot/members").then(response => {
      if (response.data) {
        window.location.href = this.$root.baseurl + "/member/dashboard";
      }
    });
  },
  mounted() {
    setTimeout(() => (this.$root.loading = false), this.$root.minloadtimefd);
  }
};
</script>
