var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$parent.loading && _vm.member.info && _vm.member.info.status == 1)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card shadow-sm"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center justify-content-end mb-4"},[(Object.keys(_vm.errors).length > 0)?_c('div',{staticClass:"col-lg-12 py-2",staticStyle:{"background":"#fddede"}},[_c('div',{staticClass:"error"},_vm._l((_vm.errors),function(err,errIndex){return _c('span',{key:errIndex,staticClass:"text-danger"},[_vm._v("\n                "+_vm._s(err[0])+"\n                "),_c('br')])}),0)]):_vm._e()]),_vm._v(" "),_c('form',{staticClass:"form-row",attrs:{"id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group col-lg-6"},[_c('label',{staticClass:"control-label"},[_vm._v("Representative name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.primary.name),expression:"primary.name"}],staticClass:"form-control shadow-none",class:{
                'is-invalid': _vm.validation.hasError('primary.name'),
                'is-valid': _vm.primary.name,
              },attrs:{"type":"text","readonly":"","placeholder":"e.g. Jane Doe","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. Jane Doe'","name":"name_representative"},domProps:{"value":(_vm.primary.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.primary, "name", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n              "+_vm._s(_vm.validation.firstError("primary.name"))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"form-group col-lg-6"},[_c('label',{staticClass:"control-label"},[_vm._v("Representative Designation")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.primary.designation),expression:"primary.designation"}],staticClass:"form-control shadow-none",class:{
                'is-invalid': _vm.validation.hasError('primary.designation'),
                'is-valid': _vm.primary.designation,
              },attrs:{"type":"text","readonly":"","placeholder":"e.g. CEO","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'e.g. CEO'","name":"designation_representative"},domProps:{"value":(_vm.primary.designation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.primary, "designation", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n              "+_vm._s(_vm.validation.firstError("primary.designation"))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"form-group col-lg-4 mb-3"},[_vm._m(1),_vm._v(" "),_c('b-form-file',{class:{
                'is-invalid': _vm.validation.hasError('images.image'),
                'is-valid': _vm.images.image,
              },attrs:{"size":"sm","accept":".png, .jpg, .jpeg","placeholder":"e.g. picture.jpg","onfocus":"e.g. picture.jpg","onblur":"e.g. picture.jpg","drop-placeholder":"Drop file here..."},model:{value:(_vm.images.image),callback:function ($$v) {_vm.$set(_vm.images, "image", $$v)},expression:"images.image"}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n              "+_vm._s(_vm.validation.firstError("images.image"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"form-group col-lg-4 mb-3"},[_vm._m(2),_vm._v(" "),_c('b-form-file',{class:{
                'is-invalid': _vm.validation.hasError('images.sign'),
                'is-valid': _vm.images.sign,
              },attrs:{"size":"sm","accept":".png, .jpg, .jpeg","placeholder":"e.g. picture.jpg","onfocus":"e.g. picture.jpg","onblur":"e.g. picture.jpg","drop-placeholder":"Drop file here..."},model:{value:(_vm.images.sign),callback:function ($$v) {_vm.$set(_vm.images, "sign", $$v)},expression:"images.sign"}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n              "+_vm._s(_vm.validation.firstError("images.sign"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{staticClass:"control-label"},[_vm._v("Company logo (Ai / Psd file)")]),_vm._v(" "),_c('div',{staticClass:"custom-file b-form-file b-custom-control-sm",attrs:{"id":""}},[_c('b-form-file',{class:{
                  'is-invalid': _vm.validation.hasError('images.ai'),
                  'is-valid': _vm.images.ai,
                },attrs:{"size":"sm","accept":".ai, .psd","placeholder":"e.g. ai / psd file","drop-placeholder":"Drop file here..."},model:{value:(_vm.images.ai),callback:function ($$v) {_vm.$set(_vm.images, "ai", $$v)},expression:"images.ai"}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.validation.firstError("images.ai"))+"\n              ")])],1)]),_vm._v(" "),_vm._m(3)])])])])]):(_vm.member.info && _vm.member.info.status == 0)?_c('div',{staticClass:"row"},[_vm._m(4)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-transparent"},[_c('h5',{staticClass:"text-uppercase font-weight-bold px-3 mb-0"},[_vm._v("\n          Privilege Card\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"comments"}},[_vm._v("\n              Representative Photo\n              "),_c('span',{staticClass:"text-danger requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"comments"}},[_vm._v("\n              Representative signature\n              "),_c('span',{staticClass:"text-danger requierd-star"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-lg-12 text-center"},[_c('button',{staticClass:"btn btn-primary",attrs:{"name":"","type":"submit"}},[_vm._v("Submit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"text-center text-danger"},[_vm._v("\n          Sorry!! your account is suspend\n        ")])])])])
}]

export { render, staticRenderFns }