<template>
	<div class="customer-dashboard">
		<h3 class="my-4">Manage My Account</h3>
		<div class="dashboard-content">
			<div class="card card-body custom-bg-shadow-redius">
				<div class="row">
					<div class="col-md-6 border-right">
						<small class="text-muted">Name</small>
						<h5>{{ $root.loggedCustomer.name }}</h5>
						<small class="text-muted">Email</small>
						<h5>{{ $root.loggedCustomer.email }}</h5>
					</div>
					<div class="col-md-6">
						<h5 class="text-muted m-0">Address Book</h5>
						<small class="text-muted">DEFAULT SHIPPING ADDRESS</small>
						<br />
						<h4 v-if="defaultAddress.name">{{ defaultAddress.name }}</h4>
						<p class="text-muted" v-if="defaultAddress.shipping_address">
							<span v-if="defaultAddress.district_info">{{defaultAddress.district_info.name}},</span>
							<span v-if="defaultAddress.upazila_info">{{defaultAddress.upazila_info.name}},</span>
							{{ defaultAddress.shipping_address }}
						</p>
					</div>
				</div>
			</div>
			<h4 class="mt-3">Recent Orders</h4>
			<div class="card card-body mt-3 custom-bg-shadow-redius">
				<div class="table-responsive">
					<table class="table" v-if="recentOrders && Object.keys(recentOrders).length > 0">
						<tr>
							<th>Order #</th>
							<th>Placed On</th>
							<th>Total</th>
							<th>Status</th>
						</tr>
						<tr class="custom-responsive" v-for="(recentOrder, key) in recentOrders" :key="key">
							<td class="custom-responsive">{{ recentOrder.order_no }}</td>
							<td>{{ recentOrder.date }}</td>
							<td>৳ {{ recentOrder.total_amount }}</td>
							<td>
								<span class="text-info d-block" v-if="recentOrder.status == 'pending'">
									<i class="fa fa-clock-o"></i> Your Order request is pending.
								</span>
								<span class="text-success d-block" v-else-if="recentOrder.status == 'shipping'">
									<i class="fa fa-check"></i> Your order has shipped.
									<br />
									Date:{{ recentOrder.shipping_date }}
								</span>
								<span class="text-secondery d-block" v-else-if="recentOrder.status == 'delivered'">
									<i class="fa fa-clock-o"></i> Deliverd.
									<br />
									Date:{{ recentOrder.delivery_date }}
								</span>
								<span class="text-danger d-block" v-else-if="recentOrder.status == 'cancel'">
									<i class="fa fa-times"></i> Your order request is cancel
								</span>
								<span class="text-warning d-block" v-else-if="recentOrder.status == 'return_pending'">
									<i class="fa fa-clock-o"></i> Your order return request is
									pending
								</span>
								<span class="text-success d-block" v-else-if="recentOrder.status == 'return'">
									<i class="fa fa-check"></i> Cancel request appreved.
								</span>
								<span class="text-danger d-block" v-else-if="recentOrder.status == 'return_cancel'">
									<i class="fa fa-times"></i> Return request Reject !
								</span>
								<span class="text-success d-block" v-else-if="recentOrder.status == 'success'">
									<i class="fa fa-check"></i> Delivered
								</span>
								<span class="text-danger d-block" v-else-if="recentOrder.status == 'not_available'">
									<i class="fa fa-check"></i> Sorry ! Not Available
								</span>
							</td>
						</tr>
					</table>

					<EmptyCart v-else list-name="Cart" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import EmptyCart from "../elements/EmptyCart";
	export default {
		name: "Dashboard",
		components: { EmptyCart },
		data() {
			return {
				recentOrders: [],
				defaultAddress: {
					shipping_address: "",
					name: "",
					division_info: { name: "" },
					district_info: { name: "" },
					upazila_info: { name: "" }
				}
			};
		},
		methods: {
			getRecentOrders() {
				// Recent Order
				axios
					.get("/ecommerce/get-recent-orders")
					.then(res => (this.recentOrders = res.data));
			},
			getDefaultAddress() {
				axios
					.get("/ecommerce/get-default-address")
					.then(res => (this.defaultAddress = res.data));
			}
		},

		created() {
			this.$eventBus.$emit("miniLoader", true);
			this.getRecentOrders();
			setTimeout(() => {
				this.$eventBus.$emit("miniLoader", false);
			}, 1000);
			this.getDefaultAddress();
		},

		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		}
	};
</script>

<style scoped>
	th {
		background: rgb(244 244 244);
		border: 0;
		box-shadow: 0px 1px 1px #4444;
	}
	td {
		border: 0;
		background: rgb(255 255 255);
		box-shadow: 0px 0px 1px #4444;
	}
</style>
