<template>
	<div class="Dashboard" v-if="!$root.spinner">
		<div v-if="notification" class="alert alert-success" role="alert">
			<p class="m-0">
				<strong>Nogor Solutions Ltd.</strong>
			</p>
		</div>

		<div class="member-services">
			<div class="row">
				<div class="col-lg-6">
					<div class="card company-info shadow-sm mb-3">
						<div class="card-header px-0">
							<div class="d-flex align-items-center justify-content-between">
								<h5 class="px-3 mb-0">Company information</h5>
							</div>
						</div>
						<div class="card-body" style="min-height: 280px">
							<table class="table table-sm table-borderless">
								<tbody v-if="(info && info.company_info) || info.user">
									<tr>
										<th width="40%">Name</th>
										<td width="2%">:</td>
										<td width="58%">{{ info.user.company_name }}</td>
									</tr>
									<tr>
										<th>Contact No</th>
										<td>:</td>
										<td>{{ info.user.mobile }}</td>
									</tr>
									<tr>
										<th>E-mail</th>
										<td>:</td>
										<td>{{ info.user.email }}</td>
									</tr>
									<tr>
										<th>Year of Establishment</th>
										<td>:</td>
										<td>{{ info.company_info.established_year }}</td>
									</tr>
									<tr>
										<th>Address</th>
										<td>:</td>
										<td>{{ info.company_info.registered_address }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="card notice shadow-sm mb-3">
						<div class="card-header px-0">
							<div class="d-flex align-items-center justify-content-between">
								<h5 class="px-3 mb-0">Applied Status</h5>
							</div>
						</div>
						<div class="card-body">
							<table class="table table-sm table-borderless">
								<tr>
									<td width="40%">Application Status</td>
									<td width="2%">:</td>
									<td width="58%">
										<span class="badge badge-info" v-if="info.user">{{
											info.user.tracking_status
										}}</span>
										<!-- <span
                      v-if="info.account && info.account.status=='pending'"
                      class="badge badge-warning"
                    >{{ info.tracking_status }}</span>
                    <span
                      v-else-if="info.account && info.account.status=='success'"
                      class="badge badge-primary"
                    >Pending</span>
										<span v-else class="badge badge-info">{{ applicationstatus }}</span>-->
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="card payment-info shadow-sm mb-3">
						<div class="card-header px-0">
							<div class="d-flex align-items-center justify-content-between">
								<h5 class="px-3 mb-0">Payment Info</h5>
								<ul class="nav" id="myTab" role="tablist">
									<li class="nav-item">
										<a class="nav-link" href="#"></a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#"></a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#"></a>
									</li>
								</ul>
							</div>
						</div>
						<div class="card-body">
							<div class="tab-content" id="myTabContent">
								<div
									class="tab-pane fade show active"
									id="payment"
									role="tabpanel"
									aria-labelledby="payment-tab"
								>
									<table class="table table-sm table-borderless">
										<tr>
											<td width="40%">Payment Method</td>
											<td width="2%">:</td>
											<td width="58%">
												<span class="text-success" v-if="info && info.account">
													<b v-if="info.account.payment_method == 'ssl'"
														>SSL COMMERZ</b
													>
													<b v-else>MANUAL</b>
												</span>
												<span v-else class="text-danger">Empty</span>
											</td>
										</tr>
										<tr>
											<td width="40%">Payment Status</td>
											<td width="2%">:</td>
											<td width="58%" style="text-transform: capitalize">
												<slot v-if="info && info.account">
													<span
														v-if="info.account.status == 'pending'"
														class="badge badge-warning text-white"
														>{{ info.account.status }}</span
													>
													<span v-else class="badge badge-success text-white">{{
														info.account.status
													}}</span>
												</slot>
												<span v-else class="text-danger">Empty</span>
											</td>
										</tr>
										<tr>
											<td width="40%">Payment Date</td>
											<td width="2%">:</td>
											<td width="58%">
												<span
													v-if="info && info.account"
													class="text-success"
													>{{ info.account.payment_date }}</span
												>
												<span v-else class="text-danger">Empty</span>
											</td>
										</tr>
									</table>
								</div>
								<div
									class="tab-pane fade"
									id="payment-edit"
									role="tabpanel"
									aria-labelledby="payment-edit-tab"
								>
									<form action="#" class="memberEditForm">
										<div class="form-group row mb-1">
											<strong class="col-lg-3">Title Name</strong>
											<span class="col-lg-1">:</span>
											<input
												class="col-lg-8"
												type="text"
												placeholder="Title Name"
											/>
										</div>
										<div class="form-group row mb-1">
											<div class="col-lg-12">
												<button type="button" class="btn btn-sm btn-success">
													<i class="mdi mdi-plus"></i> Add
												</button>
											</div>
										</div>
										<div class="form-group mb-1 text-center">
											<input
												type="submit"
												value="Submit"
												id="payment-tab"
												data-toggle="tab"
												href="#payment"
												role="tab"
												aria-controls="payment"
												aria-selected="true"
											/>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <pre>{{info}}</pre> -->
		<!-- <pre>{{accountInfo.account}}</pre> -->
	</div>
</template>

<script>
	export default {
		name: "Dashboard",
		data() {
			return {
				notification: null,
				applicationstatus: "Processing",
				approvalstatus: "Pending",
				paymentstatus: "Due",
				member: {
					info: null,
				},
				info: {},
			};
		},
		methods: {
			getProfileAndAccountInfo: function () {
				axios
					.get("/reg-member/get-profile-account-info")
					.then((response) => {
						if (Object.keys(response.data).length > 0) {
							this.info = response.data;
							this.$root.spinner = false;
						}
					})
					.catch((error) => {
						console.log(error.response);
					});
			},
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
			this.$root.spinner = true;
		},
		created() {
			this.LoggedInRegMember();
			this.getProfileAndAccountInfo();
		},
	};
</script>

<style>
</style>
