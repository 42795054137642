<template>
	<div class="customer-profile">
		<h3 class="my-4">Help Desk</h3>
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label for class="col-form-label-sm m-0">Ticket ID</label>
					<div class="loginBox">
						<input
							readonly
							type="text"
							autocomplete="off"
							v-model="data.ticket_id"
							class="form-control form-control-sm"
						/>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label for class="col-form-label-sm m-0">Subject</label>
					<div class="loginBox">
						<input
							:class="{
								'is-invalid': validation.hasError('data.subject'),
								'is-valid': data.subject
							}"
							type="text"
							autocomplete="off"
							v-model="data.subject"
							class="form-control form-control-sm"
						/>
						<div class="invalid-feedback">{{ validation.firstError("data.subject") }}</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label for class="col-form-label-sm m-0">Description</label>
					<div class="loginBox">
						<textarea
							:class="{
								'is-invalid': validation.hasError('data.description'),
								'is-valid': data.description
							}"
							type="text"
							autocomplete="off"
							v-model="data.description"
							name="description"
							class="form-control form-control-sm"
						></textarea>
						<div class="invalid-feedback">{{ validation.firstError("data.description") }}</div>
					</div>
				</div>
			</div>
			<button v-on:click="submit()" class="btn btn-primary mt-4 ml-3">Submit</button>
		</div>
	</div>
</template>

<script>
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		data() {
			return {
				data: {
					ticket_id: "",
					subject: "",
					description: "",
					user_id: "",
					reply_status: "open"
				}
			};
		},
		methods: {
			submit: function(e) {
				this.$validate().then(res => {
					if (res) {
						this.$root.spinner = true;
						axios
							.post("ecommerce/help-desk", this.data)
							.then(respons => {
								if (respons.data.code == 200) {
									this.$toastr("success", respons.data.msg, "Success!!");
									this.$router.push({ name: "ecommerce.help-desklist" });
								}
								if (respons.data.code == 202) {
									this.$toastr("error", respons.data.msg, "Error!!");
								}
								this.data = {
									subject: "",
									description: "",
									user_id: this.$root.loggedCustomer.id
								};
							})
							.catch(error => {})
							.then(alw => (this.$root.spinner = false));
					}
				});
			},
			getTicket() {
				axios.get("ecommerce/get-ticket-number").then(res => {
					this.data.ticket_id = res.data;
				});
			}
		},
		created() {
			if (this.$root.ecomLogged) {
				this.data.user_id = this.$root.loggedCustomer.id;
			}
			this.getTicket();
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		validators: {
			"data.subject": function(value = null) {
				return Validator.value(value).required("Subject is required");
			},
			"data.description": function(value = null) {
				return Validator.value(value).required("Description is required");
			}
		}
	};
</script>
