<template>
    <div id="example-content">
        <slot v-if="!$root.spinner">
            <!-- <div class="policy-menu row align-items-center justify-content-center">
				<div class="col-lg-5">
					<ul class="nav nav-fill">
						<li class="nav-item">
							<router-link
								:to="{ name: 'gazette', params: 'slug'}"
								class="nav-link"
								></router-link
							>
						</li>

					</ul>
				</div>
			</div> -->
            <div class="basis-member staff">
                <div class="accordion" id="accordionExample">
                    <div class="card shadow-lg" v-for="(category, index, key) in $root.variable.category" :key="index">
                        <div class="card-header" :id="'heading' + index">
                            <h2 class="mb-0">
                                <button class="btn btn-link text-dark" type="button" data-toggle="collapse"
                                    :data-target="'#collapse' + index" :aria-expanded="index == 0 ? 'true' : 'false'"
                                    :aria-controls="'collapse' + index">
                                    {{ category }}
                                </button>
                            </h2>
                        </div>

                        <div :id="'collapse' + index" :class="key ? 'collapse' : 'collapse'"
                            :aria-labelledby="'heading' + index" data-parent="#accordionExample">
                            <div class="card-body">
                                <table class="table table-sm table-bordered table-hover">
                                    <thead class="bg-success text-light">
                                        <tr>
                                            <th class="text-center">Sl.</th>
                                            <th class="text-center">
                                                <a>{{ $route.params.type }}</a>
                                            </th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <slot v-for="(gazette, index2) in table.datas">
                                            <tr :key="'A' + index2" v-if="index == gazette.category">
                                                <td class="text-center">{{ index2 + 1 }}</td>
                                                <td>
                                                    <a class="text-dark">{{ gazette.title }}</a>
                                                </td>
                                                <td class="text-center">
                                                    <a :href="gazette.file" target="_blank"
                                                        class="btn btn-sm btn-danger rounded-50 lift">
                                                        <i class="mdi mdi-file-pdf"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </slot>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
        <spinner v-if="$root.spinner" />
    </div>
</template>

<script>
export default {
    name: "Gazette",
    data() {
        return {
            breadcrumbTitle: "Gazette",
            breadcumb: [{ title: "Gazette" }],
            table: {
                datas: [],
            },
            meta: [],
            links: [],
            category: this.$route.params.type,
        };
    },
    methods: {
        asyncData() {
            this.$root.loading = false;
            this.get_data(this.$route.params.type);
        },
        get_data(type = null) {
            this.$root.spinner = true;
            if (type == null) {
                type = this.category;
            }
            axios
                .get("/get-gazette/" + type)
                .then((respons) => {
                    this.table.datas = respons.data.data;
                    this.meta = respons.data.meta;
                    this.links = respons.data.links;
                    this.breadcrumbTitle = this.$route.params.type;
                    this.breadcumb = [{ title: this.$route.params.type }];
                    this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
                    this.$root.spinner = false;
                })
                .catch((error) => {
                    console.log(error);
                })
                .then((res) => {
                    this.$root.spinner = false;
                });
        },
    },
    mounted() { },
    beforeCreate: function () {
        this.$root.loading = false;
        this.$parent.loading = false;
    },
    watch: {
        $route: {
            handler: "asyncData",
            immediate: true,
        },
        "$route.path": function (val, oldVal) {
            this.get_data(this.$route.params.type);
            this.$root.loading = false;
        },
    },
};
</script>

<style scoped>
.sidebar-menu.vue-affix {
    top: 0px !important;
    width: 280px;
    animation: FadeUp 1s ease forwards;
}

.sidebar-menu.vue-affix.affix {
    top: 70px !important;
    z-index: 1030;
}

@keyframes FadeUp {
    form {
        top: 0;
    }

    to {
        top: 70px !important;
    }
}
</style>
