<template>
  <div class="row text-black">
    <div class="col-12">
      <h6 class="whatsnew-title">{{ item.title }}</h6>
    </div>
    <div class="col-md-12 mb-4">
      <span class="whatsnew-date">
        <i class="mdi mdi-calendar pr-1"></i>
        {{ item.date | formatDate }}
      </span>
    </div>
    <div class="col-md-6 text-center">
      <slot v-if="item.type != 'file'">
        <slot v-if="item.image">
          <img
            v-lazy="$root.baseurl + '/public' + item.image"
            class="whatsnew-image"
            :alt="item.title"
          />
        </slot>
      </slot>
      <slot v-else>
        <img
          style="height: 125px"
          v-lazy="$root.baseurl + '/public/images/pdf-ty.png'"
          class="img-fluid"
          :alt="item.title"
        />
      </slot>
    </div>
    <div class="description whatsnew col-md-6">
      {{ getPostBody(item.description, 100) }}
      <br />
      <router-link
        class="btn btn-outline-primary"
        v-if="item.type != 'external_link'"
        :to="{ name: 'whatsnewDetails', params: { id: item.id } }"
      >
        Read more
      </router-link>
    </div>
    <span class="whatsnew shape1"></span>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>