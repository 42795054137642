<template>
  <div id="example-content">
    <slot v-if="!$root.spinner">
      <slot v-if="$route.params.type == 'video'">
        <div class="row gallery video" v-if="!$root.spinner">
          <div id="videos" class="col-lg-12" v-if="Object.keys(videos).length>0">
            <div class="videos row">
              <div v-for="(video, index) in videos" :key="index" class="videos__item col-lg-4">
                <div
                  class="card shadow-sm mb-3"
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="500"
                >
                  <div class="demo-image">
                    <LazyYoutubeVideo
                      :url="video.youtube_url"
                      :thumbnail="{ webp: video.thumb,jpg: video.thumb}"
                    />
                  </div>
                  <div class="card-body" style="min-height:130px;">
                    <h4 style="font-size: 15px;">{{video.title}}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center w-100">No videos uploaded yet</div>
        </div>
      </slot>
      <slot v-else-if="$route.params.type == 'album'">
        <div class="row gallery video" v-if="!$root.spinner">
          <div class="col-lg-4 text-center" v-for="(item, index) in videos" :key="index">
            <router-link
              :to="{ name:'video_details', params: {video_gallery_id: item.id,type:'video' }}"
            >
              <div class="card shadow-sm mb-3">
                <div class="card-gallery">
                  <img v-lazy="item.FullUrl+item.image" class="img-fluid" alt="..." />
                </div>
                <div class="card-body">
                  <h4>{{ getPostBody(item.title,100) }}</h4>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else class="text-center w-100">No albums yet</div>
      </slot>
    </slot>
  </div>
</template>
<script>
import LazyYoutubeVideo from "vue-lazy-youtube-video";
export default {
  components: {
    LazyYoutubeVideo
  },
  data() {
    return {
      breadcrumbTitle: "Videos",
      breadcumb: [{ title: "Video Gallery", route_name: "videogallery" }],
      videos: []
    };
  },
  methods: {
    asyncData() {
      this.get_datas();
      this.$root.loading = false;
      this.$parent.loading = false;
    },
    get_datas: function(pageindex) {
      let url =
        "/get-video-details/" +
        this.$route.params.video_gallery_id +
        "/" +
        this.$route.params.type;
      this.$root.spinner = true;
      axios
        .get(url)
        .then(respons => {
          this.videos = respons.data.videos;
          this.breadcrumbTitle = respons.data.title;
          this.breadcumb = [
            {
              title: "Video Gallery",
              route_name: "videogallery"
            },
            { title: this.breadcrumbTitle }
          ];
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
          this.$root.spinner = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {},
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    }
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  }
};
</script>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
