<template>
	<div
		v-if="
			(!$parent.loading &&
				member.info &&
				member.info.status == 1 &&
				$root.access &&
				$root.access['change_request']) ||
			$root.variable.rep_login ||
			$root.variable.aLogId
		"
		class="row"
	>
		<div
			v-if="member.info && Object.keys(member.info).length > 0"
			class="col-lg-12"
		>
			<div class="card shadow-sm">
				<div class="card-header bg-transparent">
					<div class="d-flex justify-content-between">
						<div class>
							<h5 class="text-uppercase font-weight-bold px-3 mb-0">
								Change Request
							</h5>
						</div>
						<div class="d-flex justify-content-between">
							<router-link
								:to="{ name: 'pendingChangeRequest' }"
								class="
									nav-link
									router-link-active
									border border-primary
									btn
									mr-3
								"
							>
								<b>Pending Request</b>
							</router-link>
							<router-link
								:to="{ name: 'changeHistory' }"
								class="nav-link router-link-active border border-primary btn"
							>
								<b>Request History</b>
							</router-link>
						</div>
					</div>
				</div>
				<div class="card-body">
					<form v-on:submit.prevent="submit" id="form" class="form-row">
						<div class="form-group col-lg-12 text-center">
							<h5>
								Date:
								{{ new Date().toLocaleString() | formatDate("Do MMM YYYY") }}
							</h5>
						</div>
						<div class="col-lg-12 mb-3">
							<div class="row align-items-center justify-content-end mb-4">
								<div
									v-if="Object.keys(errors).length > 0"
									class="col-lg-12 py-2"
									style="background: #fddede"
								>
									<div class="error">
										<span
											v-for="(err, errIndex) in errors"
											:key="errIndex"
											class="text-danger"
										>
											{{ err[0] }}
											<br />
										</span>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-4">
									<div class="card card-body shadow-sm mb-3">
										<div
											class="
												custom-control custom-checkbox custom-control-inline
											"
										>
											<input
												class="custom-control-input"
												type="checkbox"
												value="1"
												data-toggle="collapse"
												id="collapseExample1"
												name="representative_change"
												v-model="data.representative_change"
												data-target="#collapsediv1"
											/>
											<label class="custom-control-label" for="collapseExample1"
												>Representative Change</label
											>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="card card-body shadow-sm mb-3">
										<div
											class="
												custom-control custom-checkbox custom-control-inline
											"
										>
											<input
												class="custom-control-input"
												type="checkbox"
												value="1"
												data-toggle="collapse"
												id="collapseExample2"
												name="company_name_change"
												data-target="#collapsediv2"
												v-model="data.company_name_change"
											/>
											<label class="custom-control-label" for="collapseExample2"
												>Company Name Change</label
											>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="card card-body shadow-sm mb-3">
										<div
											class="
												custom-control custom-checkbox custom-control-inline
											"
										>
											<input
												class="custom-control-input"
												type="checkbox"
												value="1"
												data-toggle="collapse"
												id="collapseExample3"
												name="company_type_change"
												data-target="#collapsediv3"
												v-model="data.company_type_change"
											/>
											<label class="custom-control-label" for="collapseExample3"
												>Company Type Change</label
											>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="card card-body shadow-sm mb-3">
										<div
											class="
												custom-control custom-checkbox custom-control-inline
											"
										>
											<input
												class="custom-control-input"
												type="checkbox"
												value="1"
												data-toggle="collapse"
												id="collapseExample4"
												name="membership_category_change"
												data-target="#collapsediv4"
												v-model="data.membership_category_change"
											/>
											<label class="custom-control-label" for="collapseExample4"
												>Membership Category Change</label
											>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12 mb-3">
							<!-- Representative Change -->
							<div
								id="collapsediv1"
								class="collapse div1 mb-3"
								:class="data.representative_change ? 'show' : ''"
							>
								<fieldset class="border border-primary p-3">
									<legend
										class="text-uppercase font-weight-bold text-center w-50"
									>
										Representative Change
									</legend>
									<div action="#" class="form-row">
										<div class="col-12 col-lg-6">
											<div class="form-group col-12">
												<label class="control-label"
													>Representative Name:</label
												>
												<!-- <span class="text-danger requierd-star"></span> -->
												<input
													type="text"
													readonly
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.old_rep_name'
														),
														'is-valid': data.primary_rep.old_rep_name,
													}"
													placeholder="e.g. Jane Doe"
													onfocus="this.placeholder = ''"
													onblur="this.placeholder = 'e.g. Jane Doe'"
													v-model="data.primary_rep.old_rep_name"
													name="primary_rep[old_rep_name]"
												/>
												<div class="invalid-feedback"></div>
											</div>
											<div class="form-group col-12">
												<label class="control-label"
													>Representative Designation:</label
												>
												<!-- <span class="text-danger requierd-star"></span> -->
												<input
													type="text"
													readonly
													v-model="data.primary_rep.old_rep_designation"
													name="primary_rep[old_rep_designation]"
													placeholder="e.g. CEO"
													class="form-control shadow-none"
												/>
												<div class="invalid-feedback"></div>
											</div>
											<div class="form-group col-12">
												<label class="control-label"
													>Representative Mobile:</label
												>
												<!-- <span class="text-danger requierd-star"></span> -->
												<div class="input-group">
													<div class="input-group-prepend">
														<span class="input-group-text" id="direct_phone"
															>+88</span
														>
													</div>
													<input
														type="text"
														readonly
														class="form-control shadow-none"
														:class="{
															'is-invalid': validation.hasError(
																'data.primary_rep.old_rep_mobile'
															),
															'is-valid': data.primary_rep.old_rep_mobile,
														}"
														placeholder="e.g. 01xxxxxxxxx"
														onfocus="this.placeholder = ''"
														onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
														v-model="data.primary_rep.old_rep_mobile"
														id="mobile"
														name="primary_rep[old_rep_mobile]"
													/>
												</div>
												<div class="invalid-feedback"></div>
											</div>
											<div class="form-group col-12">
												<label class="control-label"
													>Representative E-mail Address:</label
												>
												<!-- <span class="text-danger requierd-star"></span> -->
												<input
													type="email"
													readonly
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.old_rep_email'
														),
														'is-valid': data.primary_rep.old_rep_email,
													}"
													placeholder="e.g. demo@mail.com"
													onfocus="this.placeholder = ''"
													onblur="this.placeholder = 'e.g. demo@mail.com'"
													v-model="data.primary_rep.old_rep_email"
													name="primary_rep[old_rep_email]"
												/>
												<div class="invalid-feedback"></div>
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<div class="form-group col-12">
												<label class="control-label"
													>New Representative Name:</label
												>
												<span class="text-danger requierd-star"></span>
												<input
													type="text"
													v-model="data.primary_rep.new_rep_name"
													name="primary_rep[new_rep_name]"
													placeholder="New Representative Name"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.new_rep_name'
														),
														'is-valid': data.primary_rep.new_rep_name,
													}"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"data.primary_rep.new_rep_name"
														)
													}}
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label"
													>New Representative Designation:</label
												>
												<span class="text-danger requierd-star"></span>
												<input
													type="text"
													v-model="data.primary_rep.new_rep_designation"
													name="primary_rep[new_rep_designation]"
													placeholder="e.g. CEO"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.new_rep_designation'
														),
														'is-valid': data.primary_rep.new_rep_designation,
													}"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"data.primary_rep.new_rep_designation"
														)
													}}
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label"
													>New Representative E-mail Address:</label
												>
												<span class="text-danger requierd-star"></span>
												<input
													type="text"
													v-model="data.primary_rep.new_rep_email"
													name="primary_rep[new_rep_email]"
													placeholder="e.g. demo@mail.com"
													onfocus="this.placeholder = ''"
													onblur="this.placeholder = 'e.g. demo@mail.com'"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.new_rep_email'
														),
														'is-valid': data.primary_rep.new_rep_email,
													}"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"data.primary_rep.new_rep_email"
														)
													}}
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label"
													>New Representative Mobile:</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="input-group">
													<div class="input-group-prepend">
														<span class="input-group-text" id="direct_phone"
															>+88</span
														>
													</div>
													<input
														type="text"
														v-model="data.primary_rep.new_rep_mobile"
														name="primary_rep[new_rep_mobile]"
														placeholder="018*********"
														class="form-control shadow-none"
														:class="{
															'is-invalid': validation.hasError(
																'data.primary_rep.new_rep_mobile'
															),
															'is-valid': data.primary_rep.new_rep_mobile,
														}"
													/>
												</div>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"data.primary_rep.new_rep_mobile"
														)
													}}
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label"
													>New Representative Direct Phone:</label
												>
												<div class="input-group">
													<div class="input-group-prepend">
														<span class="input-group-text" id="direct_phone"
															>+88</span
														>
													</div>
													<input
														type="text"
														v-model="data.primary_rep.new_rep_direct_phone"
														name="primary_rep[new_rep_direct_phone]"
														placeholder="018*********"
														class="form-control shadow-none"
														:class="{
															'is-invalid': validation.hasError(
																'data.primary_rep.new_rep_direct_phone'
															),
															'is-valid': data.primary_rep.new_rep_direct_phone,
														}"
													/>
												</div>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"data.primary_rep.new_rep_direct_phone"
														)
													}}
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label">Gender :</label>
												<input
													type="radio"
													checked
													value="Male"
													v-model="data.primary_rep.gender"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.gender'
														),
														'is-valid': data.primary_rep.gender,
													}"
													name="primary_rep[gender]"
												/>
												Male &nbsp;&nbsp;
												<input
													type="radio"
													value="Female"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.gender'
														),
														'is-valid': data.primary_rep.gender,
													}"
													v-model="data.primary_rep.gender"
													name="primary_rep[gender]"
												/>
												Female
												<div class="invalid-feedback">
													{{ validation.firstError("data.primary_rep.gender") }}
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label">National ID :</label>
												<span class="text-danger requierd-star"></span>
												<input
													type="text"
													name="primary_rep[national_id]"
													v-model="data.primary_rep.national_id"
													placeholder="National ID"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.national_id'
														),
														'is-valid': data.primary_rep.national_id,
													}"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"data.primary_rep.national_id"
														)
													}}
												</div>
											</div>
											<div class="form-group col-12 mb-3">
												<label for="comments">Date of Birth :</label>
												<input
													type="date"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.new_date_of_birth'
														),
														'is-valid': data.primary_rep.new_date_of_birth,
													}"
													placeholder="e.g. 2022-02-15"
													onfocus="this.placeholder = ''"
													onblur="this.placeholder = 'e.g. 2022-02-15'"
													v-model="data.primary_rep.new_date_of_birth"
													name="primary_rep[new_date_of_birth]"
												/>
											</div>
											<div class="form-group col-12">
												<label class="control-label">NID Copy :</label>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.nid_copy"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid':
																validation.hasError('images.nid_copy'),
															'is-valid': images.nid_copy,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{ validation.firstError("images.nid_copy") }}
													</div>
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label">Passport No :</label>
												<input
													type="text"
													name="primary_rep[passport]"
													v-model="data.primary_rep.passport"
													placeholder="Passport No"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.passport'
														),
														'is-valid': data.primary_rep.passport,
													}"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError("data.primary_rep.passport")
													}}
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label">LinkedIn :</label>
												<input
													type="text"
													name="primary_rep[linkedin]"
													v-model="data.primary_rep.linkedin"
													placeholder="Linkedin"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.linkedin'
														),
														'is-valid': data.primary_rep.linkedin,
													}"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError("data.primary_rep.linkedin")
													}}
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label"
													>New Representative Picture :</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.image"
														accept=".png, .jpg, .jpeg"
														placeholder="e.g. .png, .jpg, .jpeg"
														onfocus="e.g. .png, .jpg, .jpeg"
														onblur="e.g. .png, .jpg, .jpeg"
														:class="{
															'is-invalid': validation.hasError('images.image'),
															'is-valid': images.image,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<div class="invalid-feedback">
														{{ validation.firstError("images.image") }}
													</div>
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label"
													>New Representative Signature :</label
												>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.signature"
														accept=".png, .jpg, .jpeg"
														placeholder="e.g. .png, .jpg, .jpeg"
														onfocus="e.g. .png, .jpg, .jpeg"
														onblur="e.g. .png, .jpg, .jpeg"
														:class="{
															'is-invalid':
																validation.hasError('images.signature'),
															'is-valid': images.signature,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<div class="invalid-feedback">
														{{ validation.firstError("images.signature") }}
													</div>
													<small>Size ( W200 X H80)</small>
												</div>
											</div>
											<div class="form-group col-12">
												<label class="control-label">CV in PDF :</label>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.cv"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError('images.cv'),
															'is-valid': images.cv,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{ validation.firstError("images.cv") }}
													</div>
												</div>
											</div>
											<div
												class="form-group col-12"
												v-if="
													member.info &&
													member.info.legal_structure != 'Proprietorship'
												"
											>
												<label class="control-label">Board Resolution :</label>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.board_resolution"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.board_resolution'
															),
															'is-valid': images.board_resolution,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>(with all directors’ signature)</small>
													<br />
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError("images.board_resolution")
														}}
													</div>
												</div>
											</div>
											<div
												class="form-group col-12"
												v-if="
													member.info &&
													member.info.legal_structure == 'Proprietorship'
												"
											>
												<label class="control-label"
													>New Representative’s TIN Number :</label
												>
												<span class="text-danger requierd-star"></span>
												<input
													type="text"
													name="primary_rep[tin_number]"
													v-model="data.primary_rep.tin_number"
													placeholder="TIN Number"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.primary_rep.tin_number'
														),
														'is-valid': data.primary_rep.tin_number,
													}"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError("data.primary_rep.tin_number")
													}}
												</div>
											</div>
											<div
												class="form-group col-lg-12"
												v-if="
													member.info &&
													member.info.legal_structure == 'Proprietorship'
												"
											>
												<label class="control-label"
													>New Representative’s TIN Certificate :</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.tin_certificate"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.tin_certificate'
															),
															'is-valid': images.tin_certificate,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError("images.tin_certificate")
														}}
													</div>
												</div>
											</div>
										</div>
									</div>
								</fieldset>
							</div>
							<!-- /Representative Change -->
							<!-- Company Name Change -->
							<div
								id="collapsediv2"
								class="collapse div2 mb-3"
								:class="data.company_name_change ? 'show' : ''"
							>
								<fieldset class="border border-primary p-3">
									<legend
										class="text-uppercase font-weight-bold text-center w-50"
									>
										Company Name Change
									</legend>
									<div action="#" class="form-row">
										<div class="form-group col-lg-6">
											<label class="control-label">Company Name:</label>
											<span class="text-danger requierd-star"></span>
											<input
												type="text"
												name="company_name[old_company_name]"
												v-if="member.info"
												v-model="member.info.company_name"
												class="form-control shadow-none"
												readonly
											/>
										</div>
										<div class="form-group col-lg-6">
											<label class="control-label">New Company Name:</label>
											<span class="text-danger requierd-star"></span>
											<input
												type="text"
												name="company_name[new_company_name]"
												v-model="data.company_name.new_company_name"
												placeholder="New Company Name"
												class="form-control shadow-none"
												:class="{
													'is-invalid': validation.hasError(
														'data.company_name.new_company_name'
													),
													'is-valid': data.company_name.new_company_name,
												}"
											/>
											<div class="invalid-feedback">
												{{
													validation.firstError(
														"data.company_name.new_company_name"
													)
												}}
											</div>
										</div>
										<div class="form-group col-lg-3">
											<label class="control-label"
												>Update Trade License :</label
											>
											<span class="text-danger requierd-star"></span>
											<div class="custom-file b-form-file" id>
												<b-form-file
													v-model="images.trade_license"
													accept=".pdf"
													placeholder="e.g. accept pdf only"
													:class="{
														'is-invalid': validation.hasError(
															'images.trade_license'
														),
														'is-valid': images.trade_license,
													}"
													drop-placeholder="Drop file here..."
												></b-form-file>
												<small>File size must be smaller than 5mb</small>
												<div class="invalid-feedback">
													{{ validation.firstError("images.trade_license") }}
												</div>
											</div>
										</div>
										<div class="form-group col-3">
											<label class="control-label"
												>Update Trade License Number :</label
											>
											<span class="text-danger requierd-star"></span>
											<input
												type="text"
												name="company_name[license_number]"
												v-model="data.company_name.license_number"
												placeholder="Trade License Number"
												class="form-control shadow-none"
												:class="{
													'is-invalid': validation.hasError(
														'data.company_name.license_number'
													),
													'is-valid': data.company_name.license_number,
												}"
											/>
											<div class="invalid-feedback">
												{{
													validation.firstError(
														"data.company_name.license_number"
													)
												}}
											</div>
										</div>
										<slot
											v-if="
												member.info &&
												member.info.legal_structure != 'Proprietorship'
											"
										>
											<div class="form-group col-lg-3">
												<label class="control-label"
													>Company TIN Certificate :</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.tin_certificate"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.tin_certificate'
															),
															'is-valid': images.tin_certificate,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError("images.tin_certificate")
														}}
													</div>
												</div>
											</div>
											<div class="form-group col-lg-3">
												<label class="control-label"
													>Company TIN Number :</label
												>
												<span class="text-danger requierd-star"></span>
												<input
													type="text"
													name="company_name[tin_number]"
													v-model="data.company_name.tin_number"
													placeholder="TIN Number"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.company_name.tin_number'
														),
														'is-valid': data.company_name.tin_number,
													}"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"data.company_name.tin_number"
														)
													}}
												</div>
											</div>
											<div class="form-group col-3">
												<label class="control-label">Board Resolution</label>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.board_resolution"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.board_resolution'
															),
															'is-valid': images.board_resolution,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>(with all directors’ signature)</small>
													<br />
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError("images.board_resolution")
														}}
													</div>
												</div>
											</div>
											<div
												class="form-group col-lg-3"
												v-if="
													member.info &&
													member.info.legal_structure == 'Limited Company'
												"
											>
												<label class="control-label"
													>Certificate of Incorporation</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.certificate_incorporation"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.certificate_incorporation'
															),
															'is-valid': images.certificate_incorporation,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError(
																"images.certificate_incorporation"
															)
														}}
													</div>
												</div>
											</div>
											<div
												class="form-group col-lg-3"
												v-if="
													member.info &&
													member.info.legal_structure == 'Limited Company'
												"
											>
												<label class="control-label"
													>Memorandum of Articles</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.memo_article"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.memo_article'
															),
															'is-valid': images.memo_article,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{ validation.firstError("images.memo_article") }}
													</div>
												</div>
											</div>
											<div
												class="form-group col-lg-3"
												v-if="
													member.info &&
													member.info.legal_structure != 'Proprietorship'
												"
											>
												<label class="control-label">Notary</label>
												<!-- <span class="text-danger requierd-star"></span> -->
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.notary"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid':
																validation.hasError('images.notary'),
															'is-valid': images.notary,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{ validation.firstError("images.notary") }}
													</div>
												</div>
											</div>
										</slot>
										<div class="form-group col-lg-3">
											<label class="control-label">Application Letter :</label>
											<span class="text-danger requierd-star"></span>
											<div class="custom-file b-form-file" id>
												<b-form-file
													v-model="images.application_letter"
													accept=".pdf"
													placeholder="e.g. accept pdf only"
													:class="{
														'is-invalid': validation.hasError(
															'images.application_letter'
														),
														'is-valid': images.application_letter,
													}"
													drop-placeholder="Drop file here..."
												></b-form-file>
												<small>File size must be smaller than 5mb</small>
												<div class="invalid-feedback">
													{{
														validation.firstError("images.application_letter")
													}}
												</div>
											</div>
										</div>
									</div>
								</fieldset>
							</div>
							<!-- /Company Name Change -->
							<!-- Company Type Change -->
							<div
								id="collapsediv3"
								class="collapse div3 mb-3"
								:class="data.company_type_change ? 'show' : ''"
							>
								<fieldset class="border border-primary p-3">
									<legend
										class="text-uppercase font-weight-bold text-center w-50"
									>
										Company Type Change
									</legend>
									<div class="form-row">
										<div class="form-group col-lg-6">
											<label class="control-label">Company Type:</label>
											<span class="text-danger requierd-star"></span>
											<input
												type="text"
												name="company_type[old_company_type]"
												v-if="member.info"
												v-model="member.info.legal_structure"
												class="form-control shadow-none"
												readonly
											/>
										</div>
										<div class="form-group col-lg-6">
											<label class="control-label">New Company Type:</label>
											<span class="text-danger requierd-star"></span>
											<select
												v-model="data.company_type.new_company_type"
												name="company_type[new_company_type]"
												class="form-control shadow-none"
												:class="{
													'is-invalid': validation.hasError(
														'data.company_type.new_company_type'
													),
													'is-valid': data.company_type.new_company_type,
												}"
											>
												<option value selected>--Select--</option>
												<option>Proprietorship</option>
												<option>Partnership</option>
												<option>Limited Company</option>
											</select>
											<div class="invalid-feedback">
												{{
													validation.firstError(
														"data.company_type.new_company_type"
													)
												}}
											</div>
										</div>

										<slot
											v-if="
												(member.info &&
													member.info.legal_structure == 'Proprietorship' &&
													data.company_type.new_company_type ==
														'Limited Company') ||
												(member.info.legal_structure == 'Proprietorship' &&
													data.company_type.new_company_type ==
														'Partnership') ||
												(member.info.legal_structure == 'Partnership' &&
													data.company_type.new_company_type ==
														'Limited Company')
											"
										>
											<div class="form-group col-lg-3">
												<label class="control-label"
													>Update Trade License :</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.trade_license"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.trade_license'
															),
															'is-valid': images.trade_license,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{ validation.firstError("images.trade_license") }}
													</div>
												</div>
											</div>
											<div class="form-group col-3">
												<label class="control-label"
													>Update Trade License Number :</label
												>
												<span class="text-danger requierd-star"></span>
												<input
													type="text"
													name="company_type[license_number]"
													v-model="data.company_type.license_number"
													placeholder="Trade License Number"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.company_type.license_number'
														),
														'is-valid': data.company_type.license_number,
													}"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"data.company_type.license_number"
														)
													}}
												</div>
											</div>
											<div class="form-group col-lg-3">
												<label class="control-label">
													Prev.
													<span
														v-if="
															member.info &&
															member.info.legal_structure == 'Proprietorship' &&
															data.company_type.new_company_type ==
																'Limited Company'
														"
														>2years</span
													>
													Trade License :
												</label>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.prev_trade_license1"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.prev_trade_license1'
															),
															'is-valid': images.prev_trade_license1,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError(
																"images.prev_trade_license1"
															)
														}}
													</div>
												</div>
											</div>
											<!-- <div class="col-6"> -->
											<!-- <div class="form-group">
                        <label class="control-label">Prev. Trade License 2</label>
                        <span class="text-danger requierd-star"></span>
                        <div class="custom-file b-form-file" id>
                          <b-form-file
                            v-model="images.prev_trade_license2"
                            accept=".pdf"
                            placeholder="e.g. accept pdf only"
                            :class="{ 'is-invalid': validation.hasError('images.prev_trade_license2'), 'is-valid': images.prev_trade_license2 }"
                            drop-placeholder="Drop file here..."
                          ></b-form-file>
                          <div
                            class="invalid-feedback"
                          >{{ validation.firstError('images.prev_trade_license2') }}</div>
                        </div>
											</div>-->
											<!-- </div> -->

											<div class="form-group col-lg-3">
												<label class="control-label"
													>Company TIN Certificate :</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.tin_certificate"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.tin_certificate'
															),
															'is-valid': images.tin_certificate,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError("images.tin_certificate")
														}}
													</div>
												</div>
											</div>
											<div class="form-group col-lg-3">
												<label class="control-label"
													>Company TIN Number :</label
												>
												<span class="text-danger requierd-star"></span>
												<input
													type="text"
													name="company_type[tin_number]"
													v-model="data.company_type.tin_number"
													placeholder="TIN Number"
													class="form-control shadow-none"
													:class="{
														'is-invalid': validation.hasError(
															'data.company_type.tin_number'
														),
														'is-valid': data.company_type.tin_number,
													}"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"data.company_type.tin_number"
														)
													}}
												</div>
											</div>
											<div class="form-group col-3">
												<label class="control-label">Board Resolution :</label>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.board_resolution"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.board_resolution'
															),
															'is-valid': images.board_resolution,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>(with all directors’ signature)</small>
													<br />
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError("images.board_resolution")
														}}
													</div>
												</div>
											</div>
											<div class="form-group col-lg-3">
												<label class="control-label"
													>Application Letter :</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.application_letter"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.application_letter'
															),
															'is-valid': images.application_letter,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError("images.application_letter")
														}}
													</div>
												</div>
											</div>
											<slot
												v-if="
													(member.info &&
														member.info.legal_structure == 'Proprietorship' &&
														data.company_type.new_company_type ==
															'Limited Company') ||
													(member.info.legal_structure == 'Partnership' &&
														data.company_type.new_company_type ==
															'Limited Company')
												"
											>
												<div class="form-group col-lg-3">
													<label class="control-label"
														>Certificate of Incorporation</label
													>
													<span class="text-danger requierd-star"></span>
													<div class="custom-file b-form-file" id>
														<b-form-file
															v-model="images.certificate_incorporation"
															accept=".pdf"
															placeholder="e.g. accept pdf only"
															:class="{
																'is-invalid': validation.hasError(
																	'images.certificate_incorporation'
																),
																'is-valid': images.certificate_incorporation,
															}"
															drop-placeholder="Drop file here..."
														></b-form-file>
														<small>File size must be smaller than 5mb</small>
														<div class="invalid-feedback">
															{{
																validation.firstError(
																	"images.certificate_incorporation"
																)
															}}
														</div>
													</div>
												</div>
												<div class="form-group col-lg-3">
													<label class="control-label"
														>Memorandum of Articles</label
													>
													<span class="text-danger requierd-star"></span>
													<div class="custom-file b-form-file" id>
														<b-form-file
															v-model="images.memo_article"
															accept=".pdf"
															placeholder="e.g. accept pdf only"
															:class="{
																'is-invalid': validation.hasError(
																	'images.memo_article'
																),
																'is-valid': images.memo_article,
															}"
															drop-placeholder="Drop file here..."
														></b-form-file>
														<small>File size must be smaller than 5mb</small>
														<div class="invalid-feedback">
															{{ validation.firstError("images.memo_article") }}
														</div>
													</div>
												</div>
											</slot>
											<slot
												v-if="
													member.info &&
													member.info.legal_structure == 'Proprietorship' &&
													data.company_type.new_company_type == 'Partnership'
												"
											>
												<div class="form-group col-lg-3">
													<label class="control-label"
														>Partnership Deed :</label
													>
													<span class="text-danger requierd-star"></span>
													<div class="custom-file b-form-file" id>
														<b-form-file
															v-model="images.partnership_deed"
															accept=".pdf"
															placeholder="e.g. accept pdf only"
															:class="{
																'is-invalid': validation.hasError(
																	'images.partnership_deed'
																),
																'is-valid': images.partnership_deed,
															}"
															drop-placeholder="Drop file here..."
														></b-form-file>
														<small>File size must be smaller than 5mb</small>
														<div class="invalid-feedback">
															{{
																validation.firstError("images.partnership_deed")
															}}
														</div>
													</div>
												</div>
												<div class="col-md-12">
													<div class="form-row">
														<div
															v-for="(partn, index2) in images.partner"
															:key="index2"
															class="form-row col-md-12"
														>
															<div class="form-group col-lg-6 mb-1">
																<label for="cv" class="col-form-label-sm m-0"
																	>Partner ({{ index2 + 1 }}) TIN
																	Certificate</label
																>
																<span class="requierd-star"></span
																>&nbsp;&nbsp;&nbsp;
																<small>(pdf only)</small>
																<div class="loginBox">
																	<b-form-file
																		v-model="partn.tin_pdf"
																		accept=".pdf"
																		:placeholder="
																			partn.tin_certificate
																				? 'File attached '
																				: 'Choose a tin certificate'
																		"
																		drop-placeholder="Drop file here..."
																		ref="pdf1"
																	></b-form-file>
																	<span
																		class="mdi mdi-file-pdf text-danger"
																	></span>
																</div>
																<small
																	>File size must be smaller than 5mb</small
																>
															</div>

															<div class="form-group col-9 col-lg-5 mb-3">
																<label for="cv" class="col-form-label-sm m-0"
																	>Partner ({{ index2 + 1 }}) TIN number</label
																>
																<span class="requierd-star"></span>
																<input
																	:name="'partner[' + index2 + '][tin_number]'"
																	type="text"
																	class="form-control shadow-none"
																	placeholder="e.g. 1223"
																	onfocus="this.placeholder = ''"
																	onblur="this.placeholder = 'e.g. 1223'"
																	v-model="partn.tin_number"
																/>
															</div>
															<div
																class="
																	form-group
																	col-3 col-lg-1
																	text-center
																	align-self-center
																	mb-0
																"
																v-if="index2 > 0"
															>
																<button
																	@click="images.partner.splice(index2, 1)"
																	class="btn btn-sm btn-danger w-100"
																	type="button"
																>
																	<span class="fas fa-times"></span>
																</button>
															</div>
															<div
																class="
																	form-group
																	col-3 col-lg-1
																	text-center
																	align-self-center
																	mb-0
																"
																v-if="index2 == 0 && images"
															>
																<button
																	@click="
																		images.partner.push({
																			tin_certificate: null,
																			tin_number: null,
																		})
																	"
																	class="btn btn-sm btn-success float-right"
																	type="button"
																>
																	Add
																</button>
															</div>
														</div>
													</div>
												</div>
											</slot>
										</slot>

										<div
											class="form-group col-12 text-center"
											style="color: red"
											v-else
										>
											<slot v-if="data.company_type.new_company_type"
												>Invalid Request!!</slot
											>
										</div>
									</div>
								</fieldset>
							</div>
							<!-- /Company Type Change -->
							<!-- Membership Category Change -->
							<div
								id="collapsediv4"
								class="collapse div4 mb-3"
								:class="data.membership_category_change ? 'show' : ''"
							>
								<fieldset class="border border-primary p-3">
									<legend
										class="text-uppercase font-weight-bold text-center w-50"
									>
										Membership Category Change
									</legend>
									<div action="#" class="form-row">
										<div class="form-group col-lg-6">
											<label class="control-label">Membership Category:</label>
											<span class="text-danger requierd-star"></span>
											<input
												type="text"
												name="category[old_category]"
												v-if="member.info"
												v-model="member.info.membership_type"
												class="form-control shadow-none"
												readonly
											/>
										</div>

										<div
											class="form-group col-lg-6"
											v-if="
												member.info &&
												member.info.membership_type != 'Affiliate' &&
												member.info.membership_type != 'International'
											"
										>
											<label class="control-label"
												>New Membership Category:</label
											>
											<span class="text-danger requierd-star"></span>

											<select
												name="category[new_category]"
												v-model="data.category.new_category"
												class="form-control shadow-none"
												:class="{
													'is-invalid': validation.hasError(
														'data.category.new_category'
													),
													'is-valid': data.category.new_category,
												}"
											>
												<option value="">Select One</option>
												<slot
													v-for="(cate, key) in $root.variable.membership_type"
												>
													<slot v-if="member.info.membership_type != cate">
														<option
															v-if="
																member.info.membership_type == 'General' &&
																cate != 'Associate'
															"
															:key="key"
														>
															{{ cate }}
														</option>
														<option
															v-else-if="
																member.info.membership_type == 'Associate'
															"
															:key="key"
														>
															{{ cate }}
														</option>
													</slot>
												</slot>
											</select>
											<div class="invalid-feedback">
												{{
													validation.firstError("data.category.new_category")
												}}
											</div>
										</div>

										<slot
											v-if="
												member.info &&
												member.info.membership_type != 'Affiliate' &&
												member.info.membership_type != 'International'
											"
										>
											<div class="form-group col-lg-3">
												<label class="control-label"
													>Application Letter :</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.application_letter"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.application_letter'
															),
															'is-valid': images.application_letter,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError("images.application_letter")
														}}
													</div>
												</div>
											</div>
											<div class="form-group col-lg-3">
												<label class="control-label"
													>Prev. 2years Trade License :</label
												>
												<span class="text-danger requierd-star"></span>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.prev_trade_license1"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.prev_trade_license1'
															),
															'is-valid': images.prev_trade_license1,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError(
																"images.prev_trade_license1"
															)
														}}
													</div>
												</div>
											</div>
											<div
												class="form-group col-3"
												v-if="
													member.info &&
													member.info.legal_structure != 'Proprietorship'
												"
											>
												<label class="control-label">
													Board Resolution
													<small>(with all directors’ signature)</small> :
													<span class="text-danger requierd-star"></span>
												</label>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.board_resolution"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.board_resolution'
															),
															'is-valid': images.board_resolution,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError("images.board_resolution")
														}}
													</div>
												</div>
											</div>
											<div class="form-group col-lg-3">
												<label class="control-label"
													>Company TIN Certificate :</label
												>
												<div class="custom-file b-form-file" id>
													<b-form-file
														v-model="images.tin_certificate"
														accept=".pdf"
														placeholder="e.g. accept pdf only"
														:class="{
															'is-invalid': validation.hasError(
																'images.tin_certificate'
															),
															'is-valid': images.tin_certificate,
														}"
														drop-placeholder="Drop file here..."
													></b-form-file>
													<small>File size must be smaller than 5mb</small>
													<div class="invalid-feedback">
														{{
															validation.firstError("images.tin_certificate")
														}}
													</div>
												</div>
											</div>
										</slot>
									</div>
								</fieldset>
							</div>
							<!-- /Membership Category Change -->
						</div>
						<div
							class="form-group col-lg-12 text-center"
							v-if="
								$root.variable.role == 1 ||
								data.representative_change ||
								data.company_name_change ||
								data.company_type_change ||
								data.membership_category_change
							"
						>
							<button name type="submit" class="btn btn-primary">
								<span v-if="data.membership_category_change">Next</span>
								<span v-else>Submit</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<!-- <pre>{{ data }}</pre> -->
		<!-- <pre>{{ images }}</pre> -->
		<!-- <pre>{{ validation.allErrors() }}</pre> -->
	</div>
	<div v-else-if="member.info && member.info.status == 0" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">
						Sorry!! your account is suspend
					</h4>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="row">
		<div class="col-12">
			<div class="card">
				<div v-if="Object.keys($root.access).length > 0" class="card-body">
					<h4 class="text-center text-danger">
						Sorry!! you cannot access this page
					</h4>
				</div>
				<div v-else class="card-body">
					<div
						v-if="!$parent.loading"
						class="
							card-body
							d-flex
							align-items-center
							justify-content-center
							flex-column
						"
					>
						<i
							class="fa fa-spinner fa-spin"
							style="font-size: 24px; color: rgb(0, 127, 62)"
						></i>
						<span class="processing">processing</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import noImage from "../../../../assets/img/male_avatar.png";
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);

	export default {
		name: "ChangeRequestForm",
		data() {
			return {
				title: "Account Of Company",
				noImage: this.$root.baseurl + noImage,
				image_src: null,
				sign_src: null,
				data: {
					primary_rep: {
						old_rep_name: null,
						old_rep_designation: null,
						old_rep_mobile: null,
						old_rep_email: null,
						new_rep_name: null,
						new_rep_designation: null,
						new_rep_mobile: null,
						new_rep_email: null,
						national_id: null,
						new_date_of_birth: null,
						gender: "Male",
					},
					company_name: {
						old_company_name: null,
						new_company_name: null,
						trade_license: null,
						tin_certificate: null,
						memo_article: null,
					},
					company_type: {
						new_company_type: "",
						old_company_type: null,
					},
					category: {
						new_category: "",
						old_category: null,
					},
					representative_change: 0,
					company_name_change: 0,
					company_type_change: 0,
					membership_category_change: 0,
				},
				images: {
					image: null,
					cv: null,
					board_resolution: null,
					trade_license: null,
					prev_trade_license1: null,
					prev_trade_license2: null,
					tin_certificate: null,
					memo_article: null,
					certificate_incorporation: null,
					signature: null,
					notary: null,
					partner: [{}],
					partnership_deed: null,
					nid_copy: null,
				},
				policy: {},
				member: {
					info: null,
				},
				company_life: 0,
				errors: {},
			};
		},
		watch: {
			"data.category.new_category": function (val, oldVal) {
				this.membership_category_policy(val);
			},
		},
		methods: {
			submit: function (e) {
				e.preventDefault();
				const config = {
					headers: { "content-type": "multipart/form-data" },
				};
				var form = document.getElementById("form");
				var formData = new FormData(form);
				if (this.images.image) {
					formData.append("representative_image", this.images.image);
				}
				if (this.images.cv) {
					formData.append("representative_cv", this.images.cv);
				}
				if (this.images.board_resolution) {
					formData.append("board_resolution", this.images.board_resolution);
				}
				if (this.images.trade_license) {
					formData.append("trade_license", this.images.trade_license);
				}
				if (this.images.prev_trade_license1) {
					formData.append("prev_trade_license1", this.images.prev_trade_license1);
				}
				if (this.images.prev_trade_license2) {
					formData.append("prev_trade_license2", this.images.prev_trade_license2);
				}
				if (this.images.tin_certificate) {
					formData.append("tin_certificate", this.images.tin_certificate);
				}
				if (this.images.notary) {
					formData.append("notary", this.images.notary);
				}
				if (this.images.application_letter) {
					formData.append("application_letter", this.images.application_letter);
				}
				if (this.images.signature) {
					formData.append("signature", this.images.signature);
				}
				if (this.images.memo_article) {
					formData.append("memo_article", this.images.memo_article);
				}
				if (this.images.certificate_incorporation) {
					formData.append(
						"certificate_incorporation",
						this.images.certificate_incorporation
					);
				}
				if (this.images.partnership_deed) {
					formData.append("partnership_deed", this.images.partnership_deed);
				}
				if (this.images.nid_copy) {
					formData.append("nid_copy", this.images.nid_copy);
				}
				if (this.images.partner) {
					$.each(this.images.partner, function (key, partn) {
						if (partn["tin_pdf"]) {
							formData.append("partner_pdf[" + key + "]", partn["tin_pdf"]);
						}
					});
				}

				if (this.data.representative_change) {
					/*when company type Proprietorship then check company head & representative info same */
					if (this.member.info.legal_structure == "Proprietorship") {
						if (
							this.member.info.company_head !=
								this.data.primary_rep.new_rep_name ||
							this.member.info.designation !=
								this.data.primary_rep.new_rep_designation ||
							this.member.info.company_head_mobile !=
								this.data.primary_rep.new_rep_mobile ||
							this.member.info.company_head_email !=
								this.data.primary_rep.new_rep_email
						) {
							this.$toastr(
								"warning",
								"Organization head and Representative information must be same",
								"Not Match!!"
							);
							return false;
						}
					}
				}

				this.$validate().then((res) => {
					if (this.validation.countErrors() > 0) {
						console.log(this.validation.allErrors());
						this.$toastr(
							"warning",
							'You need to fill <span class="font-weight-bold">' +
								this.validation.countErrors() +
								" more empty</span> mandatory fields",
							"Empty Mandatory Fields!!"
						);
					} else {
						if (res) {
							if (this.data.company_type_change) {
								if (
									this.member.info &&
									Object.keys(this.member.info).length > 0
								) {
									if (!this.images.partner) {
										if (
											this.images.partner[0]["tin_pdf"] == undefined &&
											this.images.partner[0]["tin_number"] == undefined
										) {
											this.$toastr(
												"warning",
												"Please fillup partner tin certificate and tin number",
												"PARTNER'S INFORMATION !!"
											);
											return false;
										}
									}
								}
							}

							if (this.data.membership_category_change) {
								if (
									this.member.info &&
									Object.keys(this.member.info).length > 0
								) {
									var year = this.member.info.establishment_year;
									let today = moment();
									let established_year = moment(year);
									this.company_life = today.diff(established_year, "years");
									// if (this.company_life < 2) {
									//   this.$toastr(
									//     "warning",
									//     "Company age should be at least 2 years",
									//     "Sorry!!"
									//   );
									//   return false;
									// }
								}
								// annual dues error
								// if (this.policy.annual_due == 1) {
								//   this.$toastr(
								//     "warning",
								//     "Please pay your annual dues first",
								//     "Sorry!!"
								//   );
								//   return false;
								// }
							}

							this.$parent.loading = true;
							axios
								.post("/member/changeRequest", formData, config)
								.then((res) => {
									this.$toastr(
										"success",
										"Request Send Successfull",
										"Success!!"
									);
									this.errors = {};
									this.scrollTop();
									if (this.data.membership_category_change) {
										if (
											this.member.info.membership_type == "General" &&
											this.data.category.new_category == "Affiliate"
										) {
											location.reload();
										} else {
											localStorage.setItem("recp_id", 3);
											localStorage.setItem("serv_type", "category");
											var vm = this;
											vm.$router.push({
												name: "paymentMethod",
												params: {
													id: res.data.id,
													amount: vm.policy.amount,
												},
											});
										}
									} else {
										location.reload();
									}
								})
								.catch((error) => {
									if (error.response.status === 422) {
										this.errors = error.response.data.errors || {};
										this.scrollTop();
									}
								})
								.then((alw) => {
									this.$parent.loading = false;
								});
						}
					}
				});
			},
			membership_category_policy(name) {
				axios.get("/member/category-policy/" + name).then((res) => {
					this.policy = res.data;
				});
			},
		},
		created() {
			this.loggedIn();
			// primary
			axios
				.get("member/get-represent-info/primary")
				.then((response) => {
					this.data.primary_rep.old_rep_name = response.data.name;
					this.data.primary_rep.old_rep_designation = response.data.designation;
					this.data.primary_rep.old_rep_mobile = response.data.mobile;
					this.data.primary_rep.old_rep_email = response.data.email;
				})
				.catch((error) => console.log(error))
				.then((alw) => setTimeout(() => (this.$parent.loading = false), 1200));
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
		validators: {
			"data.primary_rep.new_rep_name": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					return Validator.value(value).required(
						"Representative Name is required"
					);
				}
			},
			"data.primary_rep.new_rep_designation": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					return Validator.value(value).required(
						"Representative Designation is required"
					);
				}
			},
			"data.primary_rep.new_rep_mobile": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					return Validator.value(value)
						.required("Representative contact number is required")
						.digit()
						.regex("01+[0-9]*$", "Must start with 01.")
						.minLength(11)
						.maxLength(11);
				}
			},
			"data.primary_rep.new_rep_direct_phone": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					return Validator.value(value).digit();
				}
			},
			"data.primary_rep.linkedin": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					return Validator.value(value).url();
				}
			},
			"data.primary_rep.new_rep_email": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					return Validator.value(value)
						.required("Representative E-mail Address is required")
						.email();
				}
			},
			"data.primary_rep.national_id": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					return Validator.value(value)
						.required("National ID is required")
						.digit();
				}
			},
			"images.image": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					return Validator.value(value).required("Image is required");
				}
			},
			"images.cv": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					return Validator.value(value)
						.required("CV is required")
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var type = value.type;
								if (type == "application/pdf") {
								} else {
									return "PDF format required";
								}
							}
						})
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var size = value.size;
								if (size > 5120000) {
									return "Pdf must be smaller than 5mb";
								}
							}
						});
				}
			},
			"images.nid_copy": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					return Validator.value(value)
						.required("NID Copy is required")
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var type = value.type;
								if (type == "application/pdf") {
								} else {
									return "PDF format required";
								}
							}
						})
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var size = value.size;
								if (size > 5120000) {
									return "PDF must be smaller than 5mb";
								}
							}
						});
				}
			},
			"images.board_resolution": function (value = null) {
				if (
					this.data.representative_change ||
					this.data.company_name_change ||
					(this.data.membership_category_change && !this.$root.variable.aLogId)
				) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (this.member.info.legal_structure != "Proprietorship") {
							return Validator.value(value)
								.required("Board resolution is required")
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 5120000) {
											return "PDF must be smaller than 5mb";
										}
									}
								});
						}
					}
				}
				if (this.data.company_type_change && !this.$root.variable.aLogId) {
					return Validator.value(value)
						.required("Board resolution is required")
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var type = value.type;
								if (type == "application/pdf") {
								} else {
									return "PDF format required";
								}
							}
						})
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var size = value.size;
								if (size > 5120000) {
									return "PDF must be smaller than 5mb";
								}
							}
						});
				}
			},
			"data.primary_rep.tin_number": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (this.member.info.legal_structure == "Proprietorship") {
							return Validator.value(value).required("Tin number is required");
						}
					}
				}
			},
			"images.tin_certificate": function (value = null) {
				if (this.data.representative_change && !this.$root.variable.aLogId) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (this.member.info.legal_structure == "Proprietorship") {
							return Validator.value(value)
								.required("Tin Certificate is required")
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 5120000) {
											return "Pdf must be smaller than 5mb";
										}
									}
								});
						}
					}
				}
				if (this.data.company_name_change && !this.$root.variable.aLogId) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (this.member.info.legal_structure != "Proprietorship") {
							return Validator.value(value)
								.required("Tin Certificate is required")
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 5120000) {
											return "Pdf must be smaller than 5mb";
										}
									}
								});
						}
					}
				}
				if (this.data.company_type_change && !this.$root.variable.aLogId) {
					return Validator.value(value)
						.required("Tin Certificate is required")
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var type = value.type;
								if (type == "application/pdf") {
								} else {
									return "PDF format required";
								}
							}
						})
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var size = value.size;
								if (size > 5120000) {
									return "Pdf must be smaller than 5mb";
								}
							}
						});
				}
			},

			"images.notary": function (value = null) {
				if (this.data.company_name_change && !this.$root.variable.aLogId) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (this.member.info.legal_structure != "Proprietorship") {
							return Validator.value(value)
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 5120000) {
											return "Pdf must be smaller than 5mb";
										}
									}
								});
						}
					}
				}
			},
			"images.application_letter": function (value = null) {
				if (
					this.data.company_name_change ||
					this.data.company_type_change ||
					(this.data.membership_category_change && !this.$root.variable.aLogId)
				) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						return Validator.value(value)
							.required("Application Letter is required")
							.custom(function () {
								if (!Validator.isEmpty(value)) {
									var type = value.type;
									if (type == "application/pdf") {
									} else {
										return "PDF format required";
									}
								}
							})
							.custom(function () {
								if (!Validator.isEmpty(value)) {
									var size = value.size;
									if (size > 5120000) {
										return "Pdf must be smaller than 5mb";
									}
								}
							});
					}
				}
			},
			"data.company_name.new_company_name": function (value = null) {
				if (this.data.company_name_change && !this.$root.variable.aLogId) {
					return Validator.value(value).required("Company Name is required");
				}
			},
			"data.company_name.tin_number": function (value = null) {
				if (this.data.company_name_change && !this.$root.variable.aLogId) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (this.member.info.legal_structure != "Proprietorship") {
							return Validator.value(value).required("Tin number is required");
						}
					}
				}
			},
			"data.company_name.license_number": function (value = null) {
				if (this.data.company_name_change && !this.$root.variable.aLogId) {
					return Validator.value(value).required("Trade License is required");
				}
			},
			"images.trade_license": function (value = null) {
				if (
					this.data.company_name_change ||
					(this.data.company_type_change && !this.$root.variable.aLogId)
				) {
					return Validator.value(value)
						.required("Update Trade License is required")
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var type = value.type;
								if (type == "application/pdf") {
								} else {
									return "PDF format required";
								}
							}
						})
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var size = value.size;
								if (size > 5120000) {
									return "Pdf must be smaller than 5mb";
								}
							}
						});
				}
			},
			"images.memo_article": function (value = null) {
				if (this.data.company_name_change && !this.$root.variable.aLogId) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (this.member.info.legal_structure == "Limited Company") {
							return Validator.value(value)
								.required("Memorandum of Articles is required")
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 5120000) {
											return "Pdf must be smaller than 5mb";
										}
									}
								});
						}
					}
				}

				if (this.data.company_type_change && !this.$root.variable.aLogId) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (
							(this.member.info.legal_structure == "Proprietorship" &&
								this.data.company_type.new_company_type == "Limited Company") ||
							(this.member.info.legal_structure == "Partnership" &&
								this.data.company_type.new_company_type == "Limited Company")
						) {
							return Validator.value(value)
								.required("Memorandum of Articles is required")
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 5120000) {
											return "Pdf must be smaller than 5mb";
										}
									}
								});
						}
					}
				}
			},
			"images.certificate_incorporation": function (value = null) {
				if (this.data.company_name_change && !this.$root.variable.aLogId) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (this.member.info.legal_structure == "Limited Company") {
							return Validator.value(value)
								.required("Certificate Incorporation is required")
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 5120000) {
											return "Pdf must be smaller than 5mb";
										}
									}
								});
						}
					}
				}
				if (this.data.company_type_change && !this.$root.variable.aLogId) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (
							(this.member.info.legal_structure == "Proprietorship" &&
								this.data.company_type.new_company_type == "Limited Company") ||
							(this.member.info.legal_structure == "Partnership" &&
								this.data.company_type.new_company_type == "Limited Company")
						) {
							return Validator.value(value)
								.required("Certificate Incorporation is required")
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 5120000) {
											return "Pdf must be smaller than 5mb";
										}
									}
								});
						}
					}
				}
			},

			"data.company_type.license_number": function (value = null) {
				if (this.data.company_type_change && !this.$root.variable.aLogId) {
					return Validator.value(value).required("Trade License is required");
				}
			},
			"data.company_type.tin_number": function (value = null) {
				if (this.data.company_type_change && !this.$root.variable.aLogId) {
					return Validator.value(value).required("Tin number is required");
				}
			},
			"images.prev_trade_license1": function (value = null) {
				if (
					this.data.company_type_change ||
					(this.data.membership_category_change && !this.$root.variable.aLogId)
				) {
					return Validator.value(value)
						.required("Prev. Trade License is required")
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var type = value.type;
								if (type == "application/pdf") {
								} else {
									return "PDF format required";
								}
							}
						})
						.custom(function () {
							if (!Validator.isEmpty(value)) {
								var size = value.size;
								if (size > 5120000) {
									return "Pdf must be smaller than 5mb";
								}
							}
						});
				}
			},
			"images.partnership_deed": function (value = null) {
				if (this.data.company_type_change && !this.$root.variable.aLogId) {
					if (this.member.info && Object.keys(this.member.info).length > 0) {
						if (
							this.member.info.legal_structure == "Proprietorship" &&
							this.data.company_type.new_company_type == "Partnership"
						) {
							return Validator.value(value)
								.required("Prev. Trade License is required")
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var type = value.type;
										if (type == "application/pdf") {
										} else {
											return "PDF format required";
										}
									}
								})
								.custom(function () {
									if (!Validator.isEmpty(value)) {
										var size = value.size;
										if (size > 5120000) {
											return "Pdf must be smaller than 5mb";
										}
									}
								});
						}
					}
				}
			},
			// "images.prev_trade_license2": function(value = null) {
			//   if (this.data.company_type_change) {
			//     return Validator.value(value).required("Trade License is required");
			//   }
			// },
			"data.company_type.new_company_type": function (value = null) {
				if (this.data.company_type_change && !this.$root.variable.aLogId) {
					return Validator.value(value).required("Company type is required");
				}
			},

			"data.category.new_category": function (value = null) {
				if (this.data.membership_category_change) {
					return Validator.value(value).required("New Category is required");
				}
			},
		},
	};
</script>
