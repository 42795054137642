<template>
  <div v-if="!$root.spinner" class="member-form">
    <form v-on:submit.prevent="submit" class="member-services" id="form">
      <input
        name="tracking_status"
        type="hidden"
        v-model="data.tracking_status"
      />
      <div class="card company-info shadow-sm mb-3">
        <div class="card-header px-0">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="text-uppercase font-weight-bold px-3 mb-0">
              Registration Form
            </h5>
          </div>
        </div>
        <h6 class="text-right px-3 py-3 mb-0">
          You need to fill in
          <b style="color: red">{{ validation.countErrors() }}</b> more empty
          field
        </h6>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="progress-tabmenu">
                <ul class="nav steps" id="progressTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :class="!$route.params.paymentOption ? 'active' : ''"
                      id="formTab1"
                      data-toggle="tab"
                      href="#progressTab1"
                      role="tab"
                      aria-controls="progressTab1"
                      :aria-selected="
                        !$route.params.paymentOption ? 'true' : ''
                      "
                      v-on:click="showStep('showStep1')"
                    >
                      <span>1</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="formTab2"
                      data-toggle="tab"
                      href="#progressTab2"
                      role="tab"
                      aria-controls="progressTab2"
                      aria-selected="false"
                      v-on:click="showStep('showStep2')"
                    >
                      <span>2</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="formTab3"
                      data-toggle="tab"
                      href="#progressTab3"
                      role="tab"
                      aria-controls="progressTab3"
                      aria-selected="false"
                      v-on:click="showStep('showStep3')"
                    >
                      <span>3</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="formTab4"
                      data-toggle="tab"
                      href="#progressTab4"
                      role="tab"
                      aria-controls="progressTab4"
                      aria-selected="false"
                      v-on:click="showStep('showStep4')"
                    >
                      <span>4</span>
                    </a>
                  </li>
                  <template
                    v-if="
                      $root.variable.role == '1' ||
                      $root.variable.role == '3' ||
                      !accounts
                    "
                  >
                    <li
                      class="nav-item"
                      v-if="
                        this.showStep5 ||
                        $route.params.paymentOption ||
                        (data && data.proceed_to_pay)
                      "
                    >
                      <a
                        class="nav-link"
                        :class="
                          showStep5 || $route.params.paymentOption
                            ? 'active'
                            : ''
                        "
                        id="formTab5"
                        data-toggle="tab"
                        href="#progressTab5"
                        role="tab"
                        aria-controls="progressTab5"
                        :aria-selected="
                          showStep5 || $route.params.paymentOption
                            ? 'true'
                            : 'false'
                        "
                        v-on:click="showStep('showStep5')"
                      >
                        <span>5</span>
                      </a>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-end mt-5">
            <div class="col-lg-4 text-right">
              <router-link
                :to="{ name: 'reg-member.pdf' }"
                type="button"
                class="btn btn-success"
                >Preview</router-link
              >
              <button
                type="button"
                class="btn btn-info ml-2"
                @click="draft_info()"
              >
                Save as Draft
              </button>
            </div>
          </div>
          <div class="row align-items-center justify-content-end mt-2">
            <div
              v-if="Object.keys(errors).length > 0"
              class="col-lg-12 py-2"
              style="background: #fddede"
            >
              <div class="error">
                <span
                  v-for="(err, errIndex) in errors"
                  :key="errIndex"
                  class="text-danger"
                >
                  {{ err[0] }}
                  <br />
                </span>
              </div>
            </div>
          </div>
          <div class="progress-tabbody">
            <fieldset :disabled="disabled">
              <div class="tab-content" id="progressTabContent">
                <div
                  class="tab-pane fade"
                  :class="!$route.params.paymentOption ? 'show active' : ''"
                  id="progressTab1"
                  role="tabpanel"
                  aria-labelledby="formTab1"
                >
                  <!-- <h1>Tab1</h1> -->
                  <!-- :disabled="validation.countErrors() > 0" -->
                  <!-- step 1 -->
                  <div>
                    <h4 class="text-success text-weight-bold">Step 1</h4>
                    <div class="form-row">
                      <fieldset
                        class="col-lg-12 border border-primary p-3 mb-3"
                      >
                        <legend class="w-25 text-center">
                          <small class="text-uppercase font-weight-bold"
                            >Company Information</small
                          >
                        </legend>
                        <div class="form-row">
                          <div class="col-md-6 mb-3">
                            <label
                              for="company_name"
                              class="col-form-label-sm m-0"
                              >Company Name</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.company_name'
                                ),
                                'is-valid': data.company_info.company_name,
                              }"
                              id="company_name"
                              :readonly="true"
                              placeholder="e.g. Nogor Solutions Ltd."
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. Nogor Solutions Ltd'"
                              v-model="data.company_info.company_name"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.company_info.company_name"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="district" class="col-form-label-sm m-0"
                              >Company Type</label
                            >
                            <span class="requierd-star"></span>
                            <select
                              name="company_info[company_type]"
                              v-model="data.company_info.company_type"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.company_type'
                                ),
                                'is-valid': data.company_info.company_type,
                              }"
                              class="form-control"
                            >
                              <option :value="null">Choose...</option>
                              <option
                                v-for="(
                                  company_type, id, index
                                ) in company_types"
                                :key="index"
                                :value="id"
                              >
                                {{ company_type }}
                              </option>
                            </select>
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.company_info.company_type"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="established_year"
                              class="col-form-label-sm m-0"
                              >Year of Establishment</label
                            >
                            <span class="requierd-star"></span>
                            <Datepicker
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.established_year'
                                ),
                                'is-valid': data.company_info.established_year,
                              }"
                              id="established_year"
                              placeholder="e.g. 12-12-2022."
                              onfocus="this.placeholder = ''"
                              name="company_info[established_year]"
                              onblur="this.placeholder = 'e.g. 12-12-2022'"
                              v-model="data.company_info.established_year"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.company_info.established_year"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="membership_other_association"
                              class="col-form-label-sm m-0"
                              >Membership with other associations and
                              chambers</label
                            >
                            <input
                              name="company_info[membership_other_association]"
                              type="text"
                              class="form-control shadow-none"
                              id="membership_other_association"
                              placeholder="e.g. ECAB, BACCO, ISPAB, BCS"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. ECAB, BACCO, ISPAB, BCS'"
                              v-model="
                                data.company_info.membership_other_association
                              "
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.company_info.membership_other_association"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="email" class="col-form-label-sm m-0"
                              >Email</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="company_info[email]"
                              type="email"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.email'
                                ),
                                'is-valid': data.company_info.email,
                              }"
                              id="email"
                              placeholder="e.g. you@mail.com"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. you@mail.com'"
                              v-model="data.company_info.email"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError("data.company_info.email")
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="phone" class="col-form-label-sm m-0"
                              >Phone</label
                            >
                            <span class="requierd-star"></span>
                            <div class="input-group">
                              <!-- <div class="input-group-prepend">
                                <span class="input-group-text" id="direct_phone">+88</span>
															</div>-->
                              <input
                                name="company_info[phone]"
                                type="text"
                                class="form-control shadow-none"
                                :class="{
                                  'is-invalid': validation.hasError(
                                    'data.company_info.phone'
                                  ),
                                  'is-valid': data.company_info.phone,
                                }"
                                id="phone"
                                placeholder="e.g. 01xxx, 89xx"
                                onfocus="this.placeholder = ''"
                                onblur="this.placeholder = 'e.g. 01xxx, 89xx'"
                                v-model="data.company_info.phone"
                              />
                            </div>
                            <div class="invalid-feedback">
                              {{
                                validation.firstError("data.company_info.phone")
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="registered_address"
                              class="col-form-label-sm m-0"
                              >Office Address</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="company_info[registered_address]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.registered_address'
                                ),
                                'is-valid':
                                  data.company_info.registered_address,
                              }"
                              id="registered_address"
                              placeholder="e.g. House No. 69 (2nd floor), Road No. 08, Block-D, Niketon"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. House No. 69 (2nd floor), Road No. 08, Block-D, Niketon'"
                              v-model="data.company_info.registered_address"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.company_info.registered_address"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <label for="district" class="col-form-label-sm m-0"
                              >District</label
                            >
                            <span class="requierd-star"></span>
                            <select
                              name="company_info[district]"
                              v-model="data.company_info.district"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.district'
                                ),
                                'is-valid': data.company_info.district,
                              }"
                              class="form-control"
                            >
                              <option selected :value="null">Choose...</option>
                              <option
                                v-for="(district, id, index) in districts"
                                :key="index"
                                :value="id"
                              >
                                {{ district }}
                              </option>
                            </select>
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.company_info.district"
                                )
                              }}
                            </div>
                          </div>

                          <div class="col-md-3 mb-3">
                            <label for="district" class="col-form-label-sm m-0"
                              >Area</label
                            >
                            <span class="requierd-star"></span>
                            <select
                              name="company_info[area]"
                              v-model="data.company_info.area"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.area'
                                ),
                                'is-valid': data.company_info.area,
                              }"
                              class="form-control"
                            >
                              <option selected :value="null">Choose...</option>
                              <option
                                v-for="(area, index) in areas"
                                :key="index"
                                :value="area.name"
                              >
                                {{ area.name }}
                              </option>
                            </select>
                            <div class="invalid-feedback">
                              {{
                                validation.firstError("data.company_info.area")
                              }}
                            </div>
                          </div>

                          <div class="col-md-6 mb-3">
                            <label for="post_code" class="col-form-label-sm m-0"
                              >Postal Code</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="company_info[post_code]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.post_code'
                                ),
                                'is-valid': data.company_info.post_code,
                              }"
                              id="post_code"
                              placeholder="e.g. 1223"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. 1223'"
                              v-model="data.company_info.post_code"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.company_info.post_code"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="website" class="col-form-label-sm m-0"
                              >Website</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="company_info[website]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.website'
                                ),
                                'is-valid': data.company_info.website,
                              }"
                              id="website"
                              placeholder="e.g. http://www.nogorsolutions.com/"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. http://www.nogorsolutions.com/'"
                              v-model="data.company_info.website"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.company_info.website"
                                )
                              }}
                            </div>
                          </div>
                          <div
                            class="col-md-6 mb-3"
                            v-if="
                              data.company_info.company_type != 'Proprietorship'
                            "
                          >
                            <label
                              for="company_tin"
                              class="col-form-label-sm m-0"
                              >Company TIN Number</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="company_info[company_tin]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.company_tin'
                                ),
                                'is-valid': data.company_info.company_tin,
                              }"
                              id="company_tin"
                              placeholder="e.g. 1223"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. 1223'"
                              v-model="data.company_info.company_tin"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.company_info.company_tin"
                                )
                              }}
                            </div>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="
                              data.company_info.company_type != 'Proprietorship'
                            "
                          >
                            <div class="form-row">
                              <div class="form-group col-2 text-center">
                                <img
                                  id="displayProfilPhoto"
                                  v-lazy="
                                    image_pdf10
                                      ? $root.baseurl + imagePdfAttach
                                      : $root.baseurl + imagePdf
                                  "
                                  height="60px"
                                  class="mt-3"
                                  alt="displayProfilPhoto"
                                />
                              </div>
                              <div class="col-10 mb-3">
                                <label
                                  for="tin_certificate_company"
                                  class="col-form-label-sm m-0"
                                  >Company TIN Certificate</label
                                >
                                <span class="requierd-star"></span>
                                <div class="loginBox">
                                  <b-form-file
                                    v-model="images.tin_certificate_company"
                                    accept=".pdf"
                                    :placeholder="
                                      data.images.tin_certificate_company
                                        ? 'File attached '
                                        : 'Choose a pdf file'
                                    "
                                    drop-placeholder="Drop file here..."
                                    ref="pdf7"
                                  ></b-form-file>
                                  <span
                                    class="mdi mdi-file-pdf text-danger"
                                  ></span>
                                </div>
                                <small
                                  >File size must be smaller than 7mb</small
                                >
                                <a
                                  v-if="data.images.tin_certificate_company"
                                  :href="
                                    $root.baseurl +
                                    '/public' +
                                    data.images.tin_certificate_company
                                  "
                                  class="float-right"
                                  target="_blank"
                                  >View file</a
                                >
                                <div class="invalid-feedback">
                                  {{
                                    validation.firstError(
                                      "images.tin_certificate_company"
                                    )
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <slot
                            v-if="
                              data.company_info.company_type ==
                                'Limited Company' ||
                              data.company_info.company_type == 'Others'
                            "
                          >
                            <div class="col-md-6">
                              <div class="form-row">
                                <div class="form-group col-2 text-center">
                                  <img
                                    id="displayProfilPhoto"
                                    v-lazy="
                                      image_pdf20
                                        ? $root.baseurl + imagePdfAttach
                                        : $root.baseurl + imagePdf
                                    "
                                    height="60px"
                                    class="mt-3"
                                    alt="displayProfilPhoto"
                                  />
                                </div>
                                <div class="col-10 mb-3">
                                  <label
                                    for="certificate_at_incorporation"
                                    class="col-form-label-sm m-0"
                                    >Certificate of Incorporation</label
                                  >
                                  <span class="requierd-star"></span>
                                  <div class="loginBox">
                                    <b-form-file
                                      v-model="
                                        images.certificate_at_incorporation
                                      "
                                      accept=".pdf"
                                      :placeholder="
                                        data.images.certificate_at_incorporation
                                          ? 'File attached '
                                          : 'Choose a pdf file'
                                      "
                                      drop-placeholder="Drop file here..."
                                      ref="pdf20"
                                    ></b-form-file>
                                    <span
                                      class="mdi mdi-file-pdf text-danger"
                                    ></span>
                                  </div>
                                  <small
                                    >File size must be smaller than 7mb</small
                                  >
                                  <a
                                    v-if="
                                      data.images.certificate_at_incorporation
                                    "
                                    :href="
                                      $root.baseurl +
                                      '/public' +
                                      data.images.certificate_at_incorporation
                                    "
                                    class="float-right"
                                    target="_blank"
                                    >View file</a
                                  >
                                  <div class="invalid-feedback">
                                    {{
                                      validation.firstError(
                                        "images.certificate_at_incorporation"
                                      )
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-row">
                                <div class="form-group col-2 text-center">
                                  <img
                                    id="displayProfilPhoto"
                                    v-lazy="
                                      image_pdf27
                                        ? $root.baseurl + imagePdfAttach
                                        : $root.baseurl + imagePdf
                                    "
                                    height="60px"
                                    class="mt-3"
                                    alt="displayProfilPhoto"
                                  />
                                </div>
                                <div class="col-10 mb-3">
                                  <label
                                    for="memo_article"
                                    class="col-form-label-sm m-0"
                                    >Memorandum of Articles</label
                                  >
                                  <span class="requierd-star"></span>
                                  <div class="loginBox">
                                    <b-form-file
                                      v-model="images.memo_article"
                                      accept=".pdf"
                                      :placeholder="
                                        data.images.memo_article
                                          ? 'File attached '
                                          : 'Choose a pdf file'
                                      "
                                      drop-placeholder="Drop file here..."
                                      ref="pdf20"
                                    ></b-form-file>
                                    <span
                                      class="mdi mdi-file-pdf text-danger"
                                    ></span>
                                  </div>
                                  <small
                                    >File size must be smaller than 2mb</small
                                  >
                                  <a
                                    v-if="data.images.memo_article"
                                    :href="
                                      $root.baseurl +
                                      '/public' +
                                      data.images.memo_article
                                    "
                                    class="float-right"
                                    target="_blank"
                                    >View file</a
                                  >
                                  <div class="invalid-feedback">
                                    {{
                                      validation.firstError(
                                        "images.memo_article"
                                      )
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </slot>

                          <div class="col-md-6">
                            <div class="form-row">
                              <div class="form-group col-2 text-center">
                                <img
                                  id="displayProfilPhoto"
                                  v-lazy="
                                    image_pdf32
                                      ? $root.baseurl + imagePdfAttach
                                      : $root.baseurl + imagePdf
                                  "
                                  height="60px"
                                  class="mt-3"
                                  alt="displayProfilPhoto"
                                />
                              </div>
                              <div class="col-10 mb-3">
                                <label
                                  for="tax_certificate"
                                  class="col-form-label-sm m-0"
                                  >Proof of Submission of Income Tax Return
                                  (Latest Year)</label
                                >
                                <!-- <span class="requierd-star"></span> -->
                                <div class="loginBox">
                                  <b-form-file
                                    v-model="images.tax_certificate"
                                    accept=".pdf"
                                    :placeholder="
                                      data.images.tax_certificate
                                        ? 'File attached '
                                        : 'Choose a pdf file'
                                    "
                                    drop-placeholder="Drop file here..."
                                    ref="pdf20"
                                  ></b-form-file>
                                  <span
                                    class="mdi mdi-file-pdf text-danger"
                                  ></span>
                                </div>
                                <small
                                  >File size must be smaller than 2mb</small
                                >
                                <a
                                  v-if="data.images.tax_certificate"
                                  :href="
                                    $root.baseurl +
                                    '/public' +
                                    data.images.tax_certificate
                                  "
                                  class="float-right"
                                  target="_blank"
                                  >View file</a
                                >
                                <div class="invalid-feedback">
                                  {{
                                    validation.firstError(
                                      "images.tax_certificate"
                                    )
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-6"
                            v-if="
                              data.company_info.company_type == 'Partnership'
                            "
                          >
                            <div class="form-row">
                              <div class="form-group col-2 text-center">
                                <img
                                  id="displayProfilPhoto"
                                  v-lazy="
                                    image_pdf30
                                      ? $root.baseurl + imagePdfAttach
                                      : $root.baseurl + imagePdf
                                  "
                                  height="60px"
                                  class="mt-3"
                                  alt="displayProfilPhoto"
                                />
                              </div>
                              <div class="col-10 mb-3">
                                <label
                                  for="partnership_deed"
                                  class="col-form-label-sm m-0"
                                  >Partnership Deed</label
                                >
                                <span class="requierd-star"></span>
                                <div class="loginBox">
                                  <b-form-file
                                    v-model="images.partnership_deed"
                                    accept=".pdf"
                                    :placeholder="
                                      data.images.partnership_deed
                                        ? 'File attached '
                                        : 'Choose a pdf file'
                                    "
                                    drop-placeholder="Drop file here..."
                                    ref="pdf20"
                                  ></b-form-file>
                                  <span
                                    class="mdi mdi-file-pdf text-danger"
                                  ></span>
                                </div>
                                <small
                                  >File size must be smaller than 7mb</small
                                >
                                <a
                                  v-if="data.images.partnership_deed"
                                  :href="
                                    $root.baseurl +
                                    '/public' +
                                    data.images.partnership_deed
                                  "
                                  class="float-right"
                                  target="_blank"
                                  >View file</a
                                >
                                <div class="invalid-feedback">
                                  {{
                                    validation.firstError(
                                      "images.partnership_deed"
                                    )
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6 mb-3">
                            <label
                              for="bin_number"
                              class="col-form-label-sm m-0"
                              >BIN Number</label
                            >
                            <input
                              name="company_info[bin_number]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.company_info.bin_number'
                                ),
                                'is-valid': data.company_info.bin_number,
                              }"
                              id="bin_number"
                              placeholder="e.g. 1223"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. 1223'"
                              v-model="data.company_info.bin_number"
                            />
                          </div>

                          <div class="w-100"></div>

                          <fieldset
                            v-if="
                              data.company_info.company_type == 'Partnership' ||
                              data.company_info.company_type ==
                                'Limited Company'
                            "
                            class="col-12 border border-primary p-3 mb-3 mt-3"
                          >
                            <legend class="w-50 text-center">
                              <small class="text-uppercase font-weight-bold"
                                >Shareholder Informations</small
                              >
                            </legend>

                            <!-- WOMEN SHAREHOLDER -->
                            <!-- WOMEN SHAREHOLDER -->

                            <div class="col-md-12">
                              <div class="form-row p-0">
                                <div
                                  v-for="(share, index) in data.shareholder"
                                  :key="index"
                                  class="form-row col-md-12 mb-2 pb-2"
                                  :style="
                                    Object.keys(data.shareholder).length !=
                                    index + 1
                                      ? 'border-bottom: 2px solid #4fa277'
                                      : ''
                                  "
                                >
                                  <div class="form-group col-lg-4 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Shareholder ({{ index + 1 }}) Name</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="'shareholder[' + index + '][name]'"
                                      type="text"
                                      class="form-control shadow-none"
                                      placeholder="Name"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'Name'"
                                      v-model="share.name"
                                    />
                                  </div>
                                  <div class="form-group col-lg-4 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Shareholder ({{ index + 1 }})
                                      Designation</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="
                                        'shareholder[' +
                                        index +
                                        '][designation]'
                                      "
                                      type="text"
                                      class="form-control shadow-none"
                                      placeholder="Designation"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'Designation'"
                                      v-model="share.designation"
                                    />
                                  </div>
                                  <div class="form-group col-lg-2 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Percentage of Share</label
                                    >
                                    <span class="requierd-star"></span>
                                    <div class="input-group">
                                      <input
                                        :name="
                                          'shareholder[' + index + '][percent]'
                                        "
                                        type="text"
                                        max="100"
                                        maxlength="100"
                                        class="form-control shadow-none"
                                        placeholder="e.g. 10"
                                        onfocus="this.placeholder = ''"
                                        onblur="this.placeholder = 'e.g. 10'"
                                        v-model="share.percent"
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text"
                                          id="direct_phone"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="form-group col-lg-1 text-center align-self-center mb-0 mt-3"
                                    v-if="index > 0"
                                  >
                                    <button
                                      @click="data.shareholder.splice(index, 1)"
                                      class="btn btn-sm btn-danger w-100"
                                      type="button"
                                    >
                                      <span class="fas fa-times"></span>
                                    </button>
                                  </div>
                                  <div
                                    class="form-group col-lg-1 text-center align-self-center mb-0 mt-3"
                                    v-if="
                                      index + 1 ==
                                      Object.keys(data.shareholder).length
                                    "
                                  >
                                    <button
                                      @click="
                                        data.shareholder.push({ percent: 0 })
                                      "
                                      class="btn btn-sm btn-success float-right"
                                      type="button"
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12 row mb-3">
                              <div class="col-4">
                                <b>Any Women Shareholder?</b>
                                <span class="requierd-star"></span>
                                <div class="invalid-feedback">
                                  {{
                                    validation.firstError(
                                      "data.company_info.women_shareholder"
                                    )
                                  }}
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="women_shareholder"
                                    name="company_info[women_shareholder]"
                                    class="custom-control-input"
                                    value="Yes"
                                    v-model="
                                      data.company_info.women_shareholder
                                    "
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="women_shareholder"
                                    >Yes</label
                                  >
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="women_shareholder1"
                                    name="company_info[women_shareholder]"
                                    class="custom-control-input"
                                    value="No"
                                    v-model="
                                      data.company_info.women_shareholder
                                    "
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="women_shareholder1"
                                    >No</label
                                  >
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          <fieldset
                            class="col-12 border border-primary p-3 mb-3 mt-3"
                          >
                            <legend class="w-25 text-center">
                              <small class="text-uppercase font-weight-bold"
                                >Trade License</small
                              >
                            </legend>
                            <div class="col-12 row mb-3">
                              <div class="col-7">
                                <b>
                                  Is the trade license contains software or ITES
                                  as business type?
                                </b>
                                <span class="requierd-star"></span>
                                <div class="invalid-feedback">
                                  {{
                                    validation.firstError(
                                      "data.company_info.business_type"
                                    )
                                  }}
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="business_type"
                                    name="company_info[business_type]"
                                    class="custom-control-input"
                                    value="Yes"
                                    v-model="data.company_info.business_type"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="business_type"
                                    >Yes</label
                                  >
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="business_type1"
                                    name="company_info[business_type]"
                                    class="custom-control-input"
                                    value="No"
                                    v-model="data.company_info.business_type"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="business_type1"
                                    >No</label
                                  >
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-row p-0">
                                <div
                                  v-for="(trade, index) in data.images
                                    .trade_license"
                                  :key="index"
                                  class="form-row col-md-12 mb-3 pb-3"
                                  :style="
                                    Object.keys(data.images.trade_license)
                                      .length !=
                                    index + 1
                                      ? 'border-bottom: 2px solid #4fa277'
                                      : ''
                                  "
                                >
                                  <div class="form-group col-lg-4 mb-1">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Updated Trade License</label
                                    >
                                    <span class="requierd-star"></span
                                    >&nbsp;&nbsp;&nbsp;
                                    <small>(pdf only)</small>
                                    <div class="loginBox">
                                      <b-form-file
                                        v-on:change="
                                          attach($event, 'trade_pdf', index)
                                        "
                                        v-model="trade.trade_pdf"
                                        :id="'trade_pdf' + index"
                                        accept=".pdf"
                                        :placeholder="
                                          trade.trade_license
                                            ? 'File attached '
                                            : 'Choose a updated trade license'
                                        "
                                        drop-placeholder="Drop file here..."
                                        ref="pdf1"
                                      ></b-form-file>
                                      <span
                                        class="mdi mdi-file-pdf text-danger"
                                      ></span>
                                    </div>
                                    <small>
                                      File size must be smaller than 7mb
                                    </small>
                                    <a
                                      v-if="trade.trade_license"
                                      :href="
                                        $root.baseurl +
                                        '/public' +
                                        trade.trade_license
                                      "
                                      class="float-right"
                                      target="_blank"
                                      >View file</a
                                    >
                                    <input
                                      type="hidden"
                                      v-model="trade.trade_license"
                                      :name="
                                        'trade_license[' + index + '][old]'
                                      "
                                    />
                                  </div>

                                  <div class="form-group col-9 col-lg-3 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Updated Trade License No.</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="
                                        'trade_license[' +
                                        index +
                                        '][license_number]'
                                      "
                                      type="text"
                                      class="form-control shadow-none"
                                      placeholder="e.g. 1223"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'e.g. 1223'"
                                      v-model="trade.license_number"
                                    />
                                  </div>
                                  <div class="form-group col-9 col-lg-4 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Validity Date</label
                                    >
                                    <span class="requierd-star"></span>
                                    <Datepicker
                                      class="form-control shadow-none"
                                      placeholder="e.g. 12-12-2022."
                                      :name="
                                        'trade_license[' +
                                        index +
                                        '][validity_date]'
                                      "
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'e.g. 12-12-2022'"
                                      v-model="trade.validity_date"
                                    />
                                  </div>
                                  <div
                                    class="form-group col-3 col-lg-1 text-center align-self-center mb-0"
                                    v-if="index > 0"
                                  >
                                    <button
                                      @click="
                                        data.images.trade_license.splice(
                                          index,
                                          1
                                        )
                                      "
                                      class="btn btn-sm btn-danger w-100"
                                      type="button"
                                    >
                                      <span class="fas fa-times"></span>
                                    </button>
                                  </div>
                                  <div
                                    class="form-group col-3 col-lg-1 text-center align-self-center mb-0"
                                    v-if="index == 0"
                                  >
                                    <button
                                      v-if="
                                        Object.keys(data.images.trade_license)
                                          .length < 5
                                      "
                                      @click="
                                        data.images.trade_license.push({
                                          trade_license: null,
                                          license_number: null,
                                        })
                                      "
                                      class="btn btn-sm btn-success float-right"
                                      type="button"
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          <fieldset
                            v-if="
                              data.company_info.company_type == 'Partnership'
                            "
                            class="col-12 border border-primary p-3 mb-3"
                          >
                            <legend class="w-50 text-center">
                              <small class="text-uppercase font-weight-bold"
                                >Partner's Information</small
                              >
                            </legend>
                            <div class="col-md-12">
                              <div class="form-row p-0">
                                <div
                                  v-for="(part, index) in data.images.partner"
                                  :key="index"
                                  class="form-row col-md-12 mb-2 pb-2"
                                  :style="
                                    Object.keys(data.images.partner).length !=
                                    index + 1
                                      ? 'border-bottom: 2px solid #4fa277'
                                      : ''
                                  "
                                >
                                  <div class="form-group col-lg-6 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Partner ({{ index + 1 }}) Name</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="'partner[' + index + '][name]'"
                                      type="text"
                                      class="form-control shadow-none"
                                      placeholder="Name"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'Name'"
                                      v-model="part.name"
                                    />
                                  </div>
                                  <div class="form-group col-lg-6 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Partner ({{ index + 1 }})
                                      Designation</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="
                                        'partner[' + index + '][designation]'
                                      "
                                      type="text"
                                      class="form-control shadow-none"
                                      placeholder="Designation"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'Designation'"
                                      v-model="part.designation"
                                    />
                                  </div>
                                  <div class="form-group col-lg-6 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Partner ({{ index + 1 }}) Email</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="'partner[' + index + '][email]'"
                                      type="email"
                                      class="form-control shadow-none"
                                      placeholder="Email"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'Email'"
                                      v-model="part.email"
                                    />
                                  </div>
                                  <div class="form-group col-lg-6 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Partner ({{ index + 1 }}) Mobile</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="'partner[' + index + '][mobile]'"
                                      type="number"
                                      class="form-control shadow-none"
                                      placeholder="Mobile"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'Mobile'"
                                      v-model="part.mobile"
                                    />
                                  </div>

                                  <div class="form-group col-lg-6 mb-1">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Partner ({{ index + 1 }}) TIN
                                      Certificate</label
                                    >
                                    <span class="requierd-star"></span
                                    >&nbsp;&nbsp;&nbsp;
                                    <small>(pdf only)</small>
                                    <div class="loginBox">
                                      <b-form-file
                                        v-on:change="
                                          attach($event, 'tin_pdf', index)
                                        "
                                        :id="'tin_pdf' + index"
                                        v-model="part.tin_pdf"
                                        accept=".pdf"
                                        :placeholder="
                                          part.tin_certificate
                                            ? 'File attached '
                                            : 'Choose a TIN Certificate'
                                        "
                                        drop-placeholder="Drop file here..."
                                        ref="pdf1"
                                      ></b-form-file>
                                      <span
                                        class="mdi mdi-file-pdf text-danger"
                                      ></span>
                                    </div>
                                    <small
                                      >File size must be smaller than 7mb</small
                                    >
                                    <a
                                      v-if="part.tin_certificate"
                                      :href="
                                        $root.baseurl +
                                        '/public' +
                                        part.tin_certificate
                                      "
                                      class="float-right"
                                      target="_blank"
                                      >View file</a
                                    >
                                    <input
                                      type="hidden"
                                      v-model="part.tin_certificate"
                                      :name="'partner[' + index + '][old]'"
                                    />
                                  </div>
                                  <div class="form-group col-lg-4 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Partner ({{ index + 1 }}) TIN
                                      Number</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="
                                        'partner[' + index + '][tin_number]'
                                      "
                                      type="text"
                                      class="form-control shadow-none"
                                      placeholder="e.g. 1223"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'e.g. 1223'"
                                      v-model="part.tin_number"
                                    />
                                  </div>

                                  <div
                                    class="form-group col-lg-1 text-center align-self-center mb-0"
                                    v-if="index > 0"
                                  >
                                    <button
                                      @click="
                                        data.images.partner.splice(index, 1)
                                      "
                                      class="btn btn-sm btn-danger w-100"
                                      type="button"
                                    >
                                      <span class="fas fa-times"></span>
                                    </button>
                                  </div>
                                  <div
                                    class="form-group col-lg-1 text-center align-self-center mb-0"
                                    v-if="
                                      index + 1 ==
                                      Object.keys(data.images.partner).length
                                    "
                                  >
                                    <button
                                      v-if="
                                        Object.keys(data.images.partner)
                                          .length < 5
                                      "
                                      @click="
                                        data.images.partner.push({
                                          tin_certificate: null,
                                          tin_number: null,
                                        })
                                      "
                                      class="btn btn-sm btn-success float-right"
                                      type="button"
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          <fieldset
                            v-if="
                              data.company_info.company_type ==
                              'Limited Company'
                            "
                            class="col-12 border border-primary p-3 mb-3"
                          >
                            <legend class="w-50 text-center">
                              <small class="text-uppercase font-weight-bold"
                                >Board of Director's</small
                              >
                            </legend>
                            <div class="col-md-12">
                              <div class="form-row p-0">
                                <div
                                  v-for="(dir, index) in data.board_of_director"
                                  :key="index"
                                  class="form-row col-md-12 mb-2 pb-2"
                                  :style="
                                    Object.keys(data.board_of_director)
                                      .length !=
                                    index + 1
                                      ? 'border-bottom: 2px solid #4fa277'
                                      : ''
                                  "
                                >
                                  <div class="form-group col-lg-6 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Director ({{ index + 1 }}) Name</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="
                                        'board_of_director[' + index + '][name]'
                                      "
                                      type="text"
                                      class="form-control shadow-none"
                                      placeholder="Name"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'Name'"
                                      v-model="dir.name"
                                    />
                                  </div>
                                  <div class="form-group col-lg-4 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Director ({{ index + 1 }})
                                      Designation</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="
                                        'board_of_director[' +
                                        index +
                                        '][designation]'
                                      "
                                      type="text"
                                      class="form-control shadow-none"
                                      placeholder="Designation"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'Designation'"
                                      v-model="dir.designation"
                                    />
                                  </div>
                                  <div class="form-group col-lg-6 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Director ({{ index + 1 }}) Email</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="
                                        'board_of_director[' +
                                        index +
                                        '][email]'
                                      "
                                      type="email"
                                      class="form-control shadow-none"
                                      placeholder="Email"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'Email'"
                                      v-model="dir.email"
                                    />
                                  </div>
                                  <div class="form-group col-lg-4 mb-3">
                                    <label
                                      for="cv"
                                      class="col-form-label-sm m-0"
                                      >Director ({{ index + 1 }}) Mobile</label
                                    >
                                    <span class="requierd-star"></span>
                                    <input
                                      :name="
                                        'board_of_director[' +
                                        index +
                                        '][mobile]'
                                      "
                                      type="number"
                                      class="form-control shadow-none"
                                      placeholder="Mobile"
                                      onfocus="this.placeholder = ''"
                                      onblur="this.placeholder = 'Mobile'"
                                      v-model="dir.mobile"
                                    />
                                  </div>
                                  <div
                                    class="form-group col-lg-1 text-center align-self-center mb-0"
                                    v-if="index > 0"
                                  >
                                    <button
                                      @click="
                                        data.board_of_director.splice(index, 1)
                                      "
                                      class="btn btn-sm btn-danger w-100"
                                      type="button"
                                    >
                                      <span class="fas fa-times"></span>
                                    </button>
                                  </div>
                                  <div
                                    class="form-group col-lg-1 text-center align-self-center mb-0"
                                    v-if="
                                      index + 1 ==
                                      Object.keys(data.board_of_director).length
                                    "
                                  >
                                    <button
                                      v-if="
                                        Object.keys(data.board_of_director)
                                          .length < 5
                                      "
                                      @click="data.board_of_director.push({})"
                                      class="btn btn-sm btn-success float-right"
                                      type="button"
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </fieldset>

                      <!-- Organization Head -->
                      <fieldset
                        class="col-lg-12 border border-primary p-3 mb-3"
                      >
                        <legend class="w-25 text-center">
                          <small class="text-uppercase font-weight-bold"
                            >Company Head</small
                          >
                        </legend>
                        <div class="form-row">
                          <div class="col-md-6 mb-3">
                            <label
                              for="organization_head"
                              class="col-form-label-sm m-0"
                              >Name</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="organization_head[name]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.organization_head.name'
                                ),
                                'is-valid': data.organization_head.name,
                              }"
                              id="organization_head"
                              placeholder="e.g. Jane Doe"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. Jane Doe'"
                              v-model="data.organization_head.name"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.organization_head.name"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="designation"
                              class="col-form-label-sm m-0"
                              >Designation</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="organization_head[designation]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.organization_head.designation'
                                ),
                                'is-valid': data.organization_head.designation,
                              }"
                              id="designation"
                              placeholder="e.g. Managing Director"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. Managing Director'"
                              v-model="data.organization_head.designation"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.organization_head.designation"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="mobile" class="col-form-label-sm m-0"
                              >Mobile</label
                            >
                            <span class="requierd-star"></span>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="direct_phone"
                                  >+88</span
                                >
                              </div>
                              <input
                                name="organization_head[mobile]"
                                type="text"
                                class="form-control shadow-none"
                                :class="{
                                  'is-invalid': validation.hasError(
                                    'data.organization_head.mobile'
                                  ),
                                  'is-valid': data.organization_head.mobile,
                                }"
                                id="mobile"
                                placeholder="e.g. 01xxxxxxxxx"
                                onfocus="this.placeholder = ''"
                                onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
                                v-model="data.organization_head.mobile"
                              />
                            </div>
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.organization_head.mobile"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="direct_phone"
                              class="col-form-label-sm m-0"
                              >Direct Phone</label
                            >
                            <!-- <span class="requierd-star"></span> -->
                            <input
                              name="organization_head[direct_phone]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.organization_head.direct_phone'
                                ),
                                'is-valid': data.organization_head.direct_phone,
                              }"
                              id="direct_phone"
                              placeholder="e.g. 01xxxxx"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. 892xxx'"
                              v-model="data.organization_head.direct_phone"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.organization_head.direct_phone"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="email" class="col-form-label-sm m-0"
                              >Email</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="organization_head[email]"
                              type="email"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.organization_head.email'
                                ),
                                'is-valid': data.organization_head.email,
                              }"
                              id="email"
                              placeholder="e.g. you@mail.com"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. you@mail.com'"
                              v-model="data.organization_head.email"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.organization_head.email"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="linkedin" class="col-form-label-sm m-0"
                              >LinkedIn</label
                            >
                            <input
                              name="organization_head[linkedin]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.organization_head.linkedin'
                                ),
                                'is-valid': data.organization_head.linkedin,
                              }"
                              id="linkedin"
                              placeholder="e.g. http://linkedin.com/yourprofile"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. http://linkedin.com/yourprofile'"
                              v-model="data.organization_head.linkedin"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.organization_head.linkedin"
                                )
                              }}
                            </div>
                          </div>
                          <div class="form-group col-2 text-center">
                            <img
                              id="displayProfilPhoto2"
                              v-lazy="image_src2"
                              height="75px"
                              width="80px"
                              alt="displayProfilPhoto2"
                            />
                          </div>
                          <div class="col-10 mb-3">
                            <label for="pp_size" class="col-form-label-sm m-0"
                              >PP size photo less than : (300 x 350,max
                              250kb)</label
                            >
                            <span class="requierd-star"></span>
                            <b-form-file
                              v-model="images.org_image"
                              id="org_image1"
                              :class="{
                                'is-invalid':
                                  validation.hasError('images.org_image'),
                                'is-valid': images.org_image,
                              }"
                              accept=".jpg, .png"
                              placeholder="Choose jpg,jpeg,png file"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                            <!-- :state="Boolean(images.org_file)" -->
                            <div class="invalid-feedback">
                              {{ validation.firstError("images.org_image") }}
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <!-- Basis Representative -->
                      <fieldset
                        class="col-lg-12 border border-primary p-3 mb-3"
                      >
                        <legend class="w-75 text-center">
                          <small class="text-uppercase font-weight-bold"
                            >YOUR AUTHORISED REPRESENTATIVE FOR BASIS</small
                          >
                        </legend>
                        <div class="form-row">
                          <div class="col-md-12">
                            <b-form-checkbox
                              class="col-form-label-sm font-weight-bold mb-0"
                              name="checkbox-1"
                              :value="true"
                              :unchecked-value="false"
                              v-model="same_as_head"
                              @change="same_as_head_method($event)"
                              >Same as organization head</b-form-checkbox
                            >
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="representative_name"
                              class="col-form-label-sm m-0"
                              >Name</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="representative[name]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.representative.name'
                                ),
                                'is-valid': data.representative.name,
                              }"
                              id="representative_name"
                              placeholder="e.g. Jane Doe"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. Jane Doe'"
                              v-model="data.representative.name"
                              :readonly="same_as_head"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.representative.Name"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="designation1"
                              class="col-form-label-sm m-0"
                              >Designation</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="representative[designation]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.representative.designation'
                                ),
                                'is-valid': data.representative.designation,
                              }"
                              id="designation1"
                              placeholder="e.g. CEO"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. CEO'"
                              v-model="data.representative.designation"
                              :readonly="same_as_head"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.representative.designation"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="mobile1" class="col-form-label-sm m-0"
                              >Mobile</label
                            >
                            <span class="requierd-star"></span>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="direct_phone"
                                  >+88</span
                                >
                              </div>
                              <input
                                name="representative[mobile]"
                                type="text"
                                :readonly="same_as_head"
                                class="form-control shadow-none"
                                :class="{
                                  'is-invalid': validation.hasError(
                                    'data.representative.mobile'
                                  ),
                                  'is-valid': data.representative.mobile,
                                }"
                                id="mobile1"
                                placeholder="e.g. 892xxx"
                                onfocus="this.placeholder = ''"
                                onblur="this.placeholder = 'e.g. 892xxx'"
                                v-model="data.representative.mobile"
                              />
                            </div>
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.representative.mobile"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-4 mb-3">
                            <label
                              for="direct_phone1"
                              class="col-form-label-sm m-0"
                              >Direct Phone</label
                            >
                            <!-- <span class="requierd-star"></span> -->
                            <input
                              name="representative[direct_phone]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.representative.direct_phone'
                                ),
                                'is-valid': data.representative.direct_phone,
                              }"
                              id="direct_phone1"
                              placeholder="e.g. 892xxx"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. 892xxx'"
                              v-model="data.representative.direct_phone"
                              :readonly="same_as_head"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.representative.direct_phone"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-2 mb-3">
                            <label for="comments">Gender</label>
                            <span class="text-danger requierd-star"></span>
                            <br />
                            <input
                              type="radio"
                              checked
                              value="Male"
                              v-model="data.representative.gender"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.representative.gender'
                                ),
                                'is-valid': data.representative.gender,
                              }"
                              name="representative[gender]"
                            />
                            Male &nbsp;&nbsp;
                            <input
                              type="radio"
                              value="Female"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.representative.gender'
                                ),
                                'is-valid': data.representative.gender,
                              }"
                              v-model="data.representative.gender"
                              name="representative[gender]"
                            />
                            Female
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.representative.gender"
                                )
                              }}
                            </div>
                          </div>

                          <div class="col-md-6 mb-3">
                            <label for="email1" class="col-form-label-sm m-0"
                              >Email</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              :readonly="same_as_head"
                              name="representative[email]"
                              type="email"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.representative.email'
                                ),
                                'is-valid': data.representative.email,
                              }"
                              id="email1"
                              placeholder="e.g. you@mail.com"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. you@mail.com'"
                              v-model="data.representative.email"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.representative.email"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="linkedin1" class="col-form-label-sm m-0"
                              >Linkedin</label
                            >
                            <input
                              name="representative[linkedin]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.representative.linkedin'
                                ),
                                'is-valid': data.representative.linkedin,
                              }"
                              id="linkedin1"
                              placeholder="e.g. http://linkedin.com/yourprofile"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. http://linkedin.com/yourprofile'"
                              v-model="data.representative.linkedin"
                              :readonly="same_as_head"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.representative.linkedin"
                                )
                              }}
                            </div>
                          </div>
                          <div class="form-group col-2 text-center">
                            <img
                              id="displayProfilPhoto"
                              v-lazy="image_src"
                              height="75px"
                              width="80px"
                              alt="displayProfilPhoto"
                            />
                          </div>
                          <div class="col-10 mb-3">
                            <label for="pp_size" class="col-form-label-sm m-0"
                              >PP size photo less than : (300 x 350,max
                              250kb)</label
                            >
                            <span class="requierd-star"></span>
                            <b-form-file
                              v-model="images.rep_image"
                              id="rep_image1"
                              accept=".jpg, .png"
                              placeholder="Choose jpg,jpeg,png file"
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                            <!-- :state="Boolean(images.rep_file)" -->
                            <div class="invalid-feedback">
                              {{ validation.firstError("images.rep_image") }}
                            </div>
                          </div>

                          <div class="form-row col-lg-6">
                            <div class="form-group col-2 text-center">
                              <img
                                id="displayProfilPhoto"
                                v-lazy="
                                  image_pdf1
                                    ? $root.baseurl + imagePdfAttach
                                    : $root.baseurl + imagePdf
                                "
                                height="60px"
                                alt="displayProfilPhoto"
                              />
                            </div>
                            <div class="col-10 mb-3">
                              <label for="cv" class="col-form-label-sm m-0"
                                >CV in PDF</label
                              >
                              <span class="requierd-star"></span>
                              <div class="loginBox">
                                <b-form-file
                                  v-model="images.rep_pdf"
                                  accept=".pdf"
                                  :placeholder="
                                    data.images.rep_pdf
                                      ? 'File attached '
                                      : 'Choose a pdf file'
                                  "
                                  drop-placeholder="Drop file here..."
                                  ref="pdf1"
                                ></b-form-file>
                                <span
                                  class="mdi mdi-file-pdf text-danger"
                                ></span>
                              </div>
                              <small>File size must be smaller than 7mb</small>
                              <a
                                v-if="data.images.rep_pdf"
                                :href="
                                  $root.baseurl +
                                  '/public' +
                                  data.images.rep_pdf
                                "
                                class="float-right"
                                target="_blank"
                                >View file</a
                              >
                              <div class="invalid-feedback">
                                {{ validation.firstError("images.rep_pdf") }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="representative_passport"
                              class="col-form-label-sm m-0"
                              >Passport No</label
                            >
                            <input
                              name="representative[passport]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.representative.passport'
                                ),
                                'is-valid': data.representative.passport,
                              }"
                              id="representative_passport"
                              placeholder="e.g. your Passport No"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. your Passport No'"
                              v-model="data.representative.passport"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.representative.passport"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="representative_nid"
                              class="col-form-label-sm m-0"
                              >National ID No.</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="representative[nid]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.representative.nid'
                                ),
                                'is-valid': data.representative.nid,
                              }"
                              id="representative_nid"
                              placeholder="e.g. 7558xxxxx"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. 7558xxxxx'"
                              v-model="data.representative.nid"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError("data.representative.nid")
                              }}
                            </div>
                          </div>
                          <div class="form-row col-md-6">
                            <div class="form-group col-2 text-center">
                              <img
                                id="displayProfilPhoto"
                                v-lazy="
                                  image_pdf_nid
                                    ? $root.baseurl + imagePdfAttach
                                    : $root.baseurl + imagePdf
                                "
                                height="60px"
                                class="mt-3"
                                alt="displayProfilPhoto"
                              />
                            </div>
                            <div class="col-md-10 mb-3">
                              <label
                                for="nid_copy"
                                class="col-form-label-sm m-0"
                                >NID Copy</label
                              >
                              <span class="requierd-star"></span>
                              <div class="loginBox">
                                <b-form-file
                                  v-model="images.nid_copy"
                                  accept=".pdf"
                                  :placeholder="
                                    data.images.nid_copy
                                      ? 'File attached '
                                      : 'Choose a pdf file'
                                  "
                                  drop-placeholder="Drop file here..."
                                  ref="pdf7"
                                ></b-form-file>
                                <span
                                  class="mdi mdi-file-pdf text-danger"
                                ></span>
                              </div>
                              <small>File size must be smaller than 7mb</small>
                              <a
                                v-if="data.images.nid_copy"
                                :href="
                                  $root.baseurl +
                                  '/public' +
                                  data.images.nid_copy
                                "
                                class="float-right"
                                target="_blank"
                                >View file</a
                              >
                              <div class="invalid-feedback">
                                {{ validation.firstError("images.nid_copy") }}
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-6 mb-3"
                            v-if="
                              data.company_info.company_type == 'Proprietorship'
                            "
                          >
                            <label
                              for="representative_tin"
                              class="col-form-label-sm m-0"
                              >Representative TIN Number</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="representative[tin]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.representative.tin'
                                ),
                                'is-valid': data.representative.tin,
                              }"
                              id="representative_tin"
                              placeholder="e.g. Your TIN"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. Your TIN'"
                              v-model="data.representative.tin"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError("data.representative.tin")
                              }}
                            </div>
                          </div>
                          <div
                            class="form-row col-md-6"
                            v-if="
                              data.company_info.company_type == 'Proprietorship'
                            "
                          >
                            <div class="form-group col-2 text-center">
                              <img
                                id="displayProfilPhoto"
                                v-lazy="
                                  image_pdf11
                                    ? $root.baseurl + imagePdfAttach
                                    : $root.baseurl + imagePdf
                                "
                                height="60px"
                                class="mt-3"
                                alt="displayProfilPhoto"
                              />
                            </div>
                            <div class="col-md-10 mb-3">
                              <label
                                for="tin_certificate_rep"
                                class="col-form-label-sm m-0"
                                >Representative TIN Certificate</label
                              >
                              <span class="requierd-star"></span>
                              <div class="loginBox">
                                <b-form-file
                                  v-model="images.tin_certificate_rep"
                                  accept=".pdf"
                                  :placeholder="
                                    data.images.tin_certificate_rep
                                      ? 'File attached '
                                      : 'Choose a pdf file'
                                  "
                                  drop-placeholder="Drop file here..."
                                  ref="pdf7"
                                ></b-form-file>
                                <span
                                  class="mdi mdi-file-pdf text-danger"
                                ></span>
                              </div>
                              <small>File size must be smaller than 7mb</small>
                              <a
                                v-if="data.images.tin_certificate_rep"
                                :href="
                                  $root.baseurl +
                                  '/public' +
                                  data.images.tin_certificate_rep
                                "
                                class="float-right"
                                target="_blank"
                                >View file</a
                              >
                              <div class="invalid-feedback">
                                {{
                                  validation.firstError(
                                    "images.tin_certificate_rep"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-row col-lg-6"
                            v-if="
                              data.company_info.company_type !=
                                'Proprietorship' &&
                              data.company_info.company_type
                            "
                          >
                            <div class="form-group col-2 text-center">
                              <img
                                id="displayProfilPhoto"
                                v-lazy="
                                  image_pdf3
                                    ? $root.baseurl + imagePdfAttach
                                    : $root.baseurl + imagePdf
                                "
                                height="60px"
                                class="mt-3"
                                alt="displayProfilPhoto"
                              />
                            </div>
                            <div class="col-10 mb-3">
                              <label for="pp_size" class="col-form-label-sm m-0"
                                >Board Resolution</label
                              >
                              <span class="requierd-star"></span>
                              <div class="loginBox">
                                <b-form-file
                                  v-model="images.board_resolution"
                                  id="board_resolution"
                                  :class="{
                                    'is-invalid': validation.hasError(
                                      'images.board_resolution'
                                    ),
                                    'is-valid': images.board_resolution,
                                  }"
                                  accept=".pdf"
                                  :placeholder="
                                    data.images.board_resolution
                                      ? 'File attached '
                                      : 'Choose a pdf file'
                                  "
                                  drop-placeholder="Drop file here..."
                                ></b-form-file>
                                <span
                                  class="mdi mdi-file-pdf text-danger"
                                ></span>
                              </div>
                              <small>File size must be smaller than 7mb</small>
                              <a
                                v-if="data.images.board_resolution"
                                :href="
                                  $root.baseurl +
                                  '/public' +
                                  data.images.board_resolution
                                "
                                class="float-right"
                                target="_blank"
                                >View file</a
                              >
                              <div class="invalid-feedback">
                                {{
                                  validation.firstError(
                                    "images.board_resolution"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset
                        class="col-lg-12 border border-primary p-3 mb-3"
                      >
                        <legend class="w-75 text-center">
                          <small class="font-weight-bold"
                            >ALTERNATIVE CONTACT FOR GENERAL
                            COMMUNICATIONS</small
                          >
                        </legend>
                        <div class="form-row">
                          <div class="col-md-6 mb-3">
                            <label
                              for="alternative_name"
                              class="col-form-label-sm m-0"
                              >Name</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="alternative[name]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.alternative.name'
                                ),
                                'is-valid': data.alternative.name,
                              }"
                              id="alternative_name"
                              placeholder="e.g. Jane Doe"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. Jane Doe'"
                              v-model="data.alternative.name"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError("data.alternative.name")
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="alternative_designation"
                              class="col-form-label-sm m-0"
                              >Designation</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="alternative[designation]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.alternative.designation'
                                ),
                                'is-valid': data.alternative.designation,
                              }"
                              id="alternative_designation"
                              placeholder="e.g. Manager"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. Manager'"
                              v-model="data.alternative.designation"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.alternative.designation"
                                )
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="alternative_phone"
                              class="col-form-label-sm m-0"
                              >Mobile</label
                            >
                            <span class="requierd-star"></span>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="direct_phone"
                                  >+88</span
                                >
                              </div>
                              <input
                                name="alternative[phone]"
                                type="text"
                                class="form-control shadow-none"
                                :class="{
                                  'is-invalid': validation.hasError(
                                    'data.alternative.phone'
                                  ),
                                  'is-valid': data.alternative.phone,
                                }"
                                id="alternative_phone"
                                placeholder="e.g. 01xxxxxxxxx"
                                onfocus="this.placeholder = ''"
                                onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
                                v-model="data.alternative.phone"
                              />
                            </div>
                            <div class="invalid-feedback">
                              {{
                                validation.firstError("data.alternative.phone")
                              }}
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label
                              for="alternative_email"
                              class="col-form-label-sm m-0"
                              >Email</label
                            >
                            <span class="requierd-star"></span>
                            <input
                              name="alternative[email]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.alternative.email'
                                ),
                                'is-valid': data.alternative.email,
                              }"
                              id="alternative_email"
                              placeholder="e.g. yourname@mail.com"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. yourname@mail.com'"
                              v-model="data.alternative.email"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError("data.alternative.email")
                              }}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button
                          type="button"
                          class="btn btn-info ml-2"
                          @click="draft_info()"
                        >
                          Save as Draft
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          class="btn btn-success float-right"
                          type="submit"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="progressTab2"
                  role="tabpanel"
                  aria-labelledby="formTab2"
                >
                  <!-- <h1>Tab2</h1> -->
                  <!-- step 2 -->
                  <div>
                    <h4 class="text-success text-weight-bold">Step 2</h4>
                    <div class="form-row">
                      <fieldset
                        class="col-lg-12 border border-primary p-3 mb-3"
                      >
                        <legend class="w-50 text-center">
                          <small class="font-weight-bold"
                            >I am Applying For:</small
                          >
                        </legend>
                        <div class="form-row">
                          <div
                            class="col-2"
                            v-if="data.business_activity && accounts"
                          >
                            <b>{{ data.business_activity.applying_for }}</b>
                          </div>

                          <template v-show="invoicePaidStatus()">
                            <div
                              :class="
                                !accounts ||
                                $root.variable.role == '1' ||
                                $root.variable.role == '3'
                                  ? ''
                                  : 'd-none'
                              "
                              class="col-md-6 mb-3"
                            >
                              <div class="custom-control custom-radio">
                                <input
                                  v-if="
                                    !data.company_info.established_year ||
                                    company_life < 2
                                      ? ''
                                      : 'readonly'
                                  "
                                  type="radio"
                                  id="general"
                                  name="business_activity[applying_for]"
                                  class="custom-control-input"
                                  value="General"
                                  v-model="data.business_activity.applying_for"
                                />
                                <label
                                  :ref="generalRef"
                                  @click="checkEstablishYear()"
                                  class="custom-control-label"
                                  for="general"
                                >
                                  General Member
                                  <span
                                    style="font-size: 11px"
                                    :class="
                                      !data.company_info.established_year ||
                                      company_life < 2
                                        ? 'text-danger'
                                        : ''
                                    "
                                  >
                                    ({{
                                      validation.firstError(
                                        "data.business_activity.applying_for"
                                      )
                                        ? validation.firstError(
                                            "data.business_activity.applying_for"
                                          )
                                        : "More than 2 years of company age"
                                    }})
                                  </span>
                                </label>
                                <b-tooltip
                                  :target="() => $refs[generalRef]"
                                  title="General Member"
                                />
                              </div>

                              <div class="custom-control custom-radio">
                                <input
                                  v-if="
                                    !data.company_info.established_year ||
                                    company_life > 2
                                      ? ''
                                      : 'readonly'
                                  "
                                  type="radio"
                                  id="associate"
                                  name="business_activity[applying_for]"
                                  class="custom-control-input"
                                  value="Associate"
                                  v-model="data.business_activity.applying_for"
                                />
                                <label
                                  :ref="associateRef"
                                  @click="checkEstablishYearLessThanTwoYears()"
                                  class="custom-control-label"
                                  for="associate"
                                  >Associate Member

                                  <span
                                    style="font-size: 11px"
                                    :class="
                                      !data.company_info.established_year ||
                                      company_life > 2
                                        ? 'text-danger'
                                        : ''
                                    "
                                  >
                                    ({{
                                      validation.firstError(
                                        "data.business_activity.applying_for"
                                      )
                                        ? validation.firstError(
                                            "data.business_activity.applying_for"
                                          )
                                        : "Less than 2 years of company age"
                                    }})
                                  </span>
                                </label>
                                <b-tooltip
                                  :target="() => $refs[associateRef]"
                                  title="Associate Member"
                                />
                              </div>
                              <div class="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="Affiliate"
                                  name="business_activity[applying_for]"
                                  class="custom-control-input"
                                  value="Affiliate"
                                  v-model="data.business_activity.applying_for"
                                />
                                <label
                                  :ref="affiliateRef"
                                  class="custom-control-label"
                                  for="Affiliate"
                                  >Affiliate Member</label
                                >
                                <b-tooltip
                                  :target="() => $refs[affiliateRef]"
                                  title="Affiliate Member"
                                />
                              </div>
                              <div class="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="International"
                                  name="business_activity[applying_for]"
                                  class="custom-control-input"
                                  value="International"
                                  v-model="data.business_activity.applying_for"
                                />
                                <label
                                  :ref="internationalRef"
                                  class="custom-control-label"
                                  for="International"
                                  >International Member</label
                                >
                                <b-tooltip
                                  :target="() => $refs[internationalRef]"
                                  title="International Member"
                                />
                              </div>
                            </div>
                          </template>
                        </div>
                      </fieldset>

                      <!-- Shareholder Informations only International Member-->

                      <fieldset
                        v-if="
                          data.business_activity.applying_for == 'International'
                        "
                        class="col-12 border border-primary p-3 mb-3 mt-3"
                      >
                        <legend class="w-50 text-center">
                          <small class="text-uppercase font-weight-bold"
                            >Shareholder Informations</small
                          >
                        </legend>

                        <div class="col-12 row mb-3">
                          <div class="form-group col-lg-6">
                            <label for="cv" class="col-form-label-sm m-0"
                              >Shareholder / Company Name</label
                            >
                            <span class="requierd-star"></span>

                            <input
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.shareholder_primary.name'
                                ),
                                'is-valid': data.shareholder_primary.name,
                              }"
                              :name="'shareholder_primary[name]'"
                              type="text"
                              class="form-control shadow-none"
                              placeholder="Name"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'Name'"
                              v-model="data.shareholder_primary.name"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.shareholder_primary.name"
                                )
                              }}
                            </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label for="cv" class="col-form-label-sm m-0"
                              >Percentage (%)</label
                            >
                            <span class="requierd-star"></span>

                            <input
                              :class="{
                                'is-invalid': validation.hasError(
                                  'data.shareholder_primary.percentage'
                                ),
                                'is-valid': data.shareholder_primary.percentage,
                              }"
                              :name="'shareholder_primary[percentage]'"
                              type="number"
                              class="form-control shadow-none"
                              placeholder="10 / 20 / 30"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = '10 / 20 / 30'"
                              v-model="data.shareholder_primary.percentage"
                            />
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "data.shareholder_primary.percentage"
                                )
                              }}
                            </div>
                          </div>
                          <div class="form-group col-lg-6">
                            <label for="cv" class="col-form-label-sm m-0"
                              >Is shareholder company listed in stock
                              exchange?</label
                            >
                            <span class="requierd-star"></span>

                            <div class="row">
                              <div class="col-4">
                                <div class="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="stock_exz1"
                                    name="shareholder_primary[stock_exchange]"
                                    class="custom-control-input"
                                    value="Yes"
                                    v-model="
                                      data.shareholder_primary.stock_exchange
                                    "
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="stock_exz1"
                                    >Yes</label
                                  >
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="stock_exz2"
                                    name="shareholder_primary[stock_exchange]"
                                    class="custom-control-input"
                                    value="No"
                                    v-model="
                                      data.shareholder_primary.stock_exchange
                                    "
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="stock_exz2"
                                    >No</label
                                  >
                                </div>
                              </div>
                              <div class="invalid-feedback">
                                {{
                                  validation.firstError(
                                    "data.shareholder_primary.stock_exchange"
                                  )
                                }}
                              </div>
                            </div>
                          </div>

                          <div class="form-group col-lg-6">
                            <label for="cv" class="col-form-label-sm m-0">
                              Country name of the stock exchange
                            </label>
                            <input
                              :name="'shareholder_primary[country]'"
                              type="text"
                              class="form-control shadow-none"
                              placeholder="USA, UK, London"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'USA, UK, London'"
                              v-model="data.shareholder_primary.country"
                            />
                          </div>
                        </div>
                      </fieldset>

                      <!-- AREA OF BUSINESS ACTIVITY -->
                      <!-- AREA OF BUSINESS ACTIVITY -->
                      <fieldset
                        class="col-lg-12 border border-primary p-3 mb-3"
                      >
                        <legend class="w-50 text-center">
                          <small class="font-weight-bold"
                            >AREA OF BUSINESS ACTIVITY</small
                          >
                        </legend>
                        <div class="form-row">
                          <div
                            class="col-md-4"
                            v-for="(bActivity, key) in activity_list"
                            :key="key"
                          >
                            <div class="custom-control custom-checkbox">
                              <b-form-checkbox
                                :name="
                                  'business_activity[activities][' +
                                  bActivity.id +
                                  ']'
                                "
                                class="col-form-label-sm font-weight-bold mb-0"
                                :value="true"
                                v-model="
                                  data.business_activity.activities[
                                    bActivity.id
                                  ]
                                "
                                :unchecked-value="false"
                                >{{ bActivity.title }}</b-form-checkbox
                              >
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12 col-lg-7">
                          <div
                            v-for="(activityName, index) in data.business_activity.other_activities"
                            :key="index"
                            class="form-row col-12 mb-2"
                          >
                            <div class="col-6 col-md-8">
                              <input
                                :name="'business_activity[other_activities]['+index+'][activity]'"
                                type="text"
                                class="form-control shadow-none"
                                placeholder="New Activity"
                                v-model="activityName.activity"
                              />
                            </div>
                            <div class="col-6 col-md-4" v-if="index>0">
                              <button
                                @click="data.business_activity.other_activities.splice(index,1)"
                                class="btn btn-sm btn-danger"
                                type="button"
                              >
                                <span class="fas fa-times"></span>
                              </button>
                            </div>
                            <div class="col-4 col-md-4" v-if="index==0">
                              <button
                                v-if="Object.keys(data.business_activity.other_activities).length<3"
                                @click="data.business_activity.other_activities.push({})"
                                class="btn btn-sm btn-success"
                                type="button"
                              >Add More</button>
                            </div>
                          </div>
												</div>-->
                      </fieldset>

                      <!-- Affiliate Category-->
                      <!-- Affiliate Category-->
                      <fieldset
                        v-if="
                          data.business_activity.applying_for == 'Affiliate'
                        "
                        class="col-lg-12 border border-primary p-3 mb-3"
                      >
                        <legend class="w-75 text-center">
                          <small class="font-weight-bold"
                            >Which of the following category or platform is your
                            main activity?</small
                          >
                        </legend>
                        <div class="form-row">
                          <div
                            class="col-md-6"
                            v-for="(aCategory, key) in affiliate_category_list"
                            :key="key"
                          >
                            <div class="custom-control custom-radio mb-1">
                              <input
                                type="radio"
                                :id="'affiliate_category' + key"
                                name="company_info[affiliate_category]"
                                class="custom-control-input"
                                :value="aCategory.name"
                                v-model="data.company_info.affiliate_category"
                              />
                              <label
                                style="font-size: 14px"
                                class="custom-control-label"
                                :for="'affiliate_category' + key"
                                >{{ aCategory.name }}</label
                              >
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="custom-control custom-radio mb-1">
                              <input
                                type="radio"
                                id="affiliate_category00"
                                name="company_info[affiliate_category]"
                                class="custom-control-input"
                                value="Other"
                                v-model="data.company_info.affiliate_category"
                              />
                              <label
                                style="font-size: 14px"
                                class="custom-control-label"
                                for="affiliate_category00"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                          <div
                            v-if="
                              data.company_info.affiliate_category == 'Other'
                            "
                            class="col-md-6"
                          >
                            <input
                              type="text"
                              name="company_info[affiliate_category_other]"
                              class="form-control form-control-sm"
                              placeholder="Category Name"
                              v-model="
                                data.company_info.affiliate_category_other
                              "
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button
                          type="button"
                          class="btn btn-info ml-2"
                          @click="draft_info()"
                        >
                          Save as Draft
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          class="btn btn-success float-right"
                          type="submit"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="progressTab3"
                  role="tabpanel"
                  aria-labelledby="formTab3"
                >
                  <!-- <h1>Tab3</h1> -->
                  <!-- step 3 -->
                  <div>
                    <h4 class="text-success text-weight-bold">Step 3</h4>
                    <div class="form-row">
                      <fieldset
                        class="col-lg-12 border border-primary p-3 mb-3"
                      >
                        <legend class="w-50 text-center">
                          <small class="font-weight-bold"
                            >BUSINESS PRIMARY ACTIVITY</small
                          >
                        </legend>
                        <div class="form-row">
                          <div class="col-md-3 mb-3">
                            <b>Business Primary Activity:</b>
                            <br />
                            <small>Maximum 3 Allowed</small>
                          </div>
                          <div class="col-md-6 mb-3">
                            <div class="row">
                              <div class="col-md-3 mb-3">
                                <label class="font-weight-normal"
                                  >Priority 1</label
                                >
                                <span class="requierd-star"></span>
                              </div>

                              <div class="col-md-9 mb-3">
                                <select
                                  name="primary_activity[priority1]"
                                  v-model="data.primary_activity.priority1"
                                  :class="{
                                    'is-invalid': validation.hasError(
                                      'data.primary_activity.priority1'
                                    ),
                                    'is-valid': data.primary_activity.priority1,
                                  }"
                                  class="form-control"
                                >
                                  <option selected :value="null">
                                    Choose...
                                  </option>
                                  <option
                                    v-for="(activity, key) in activity_list"
                                    :key="key"
                                    :value="activity.id"
                                  >
                                    {{ activity.title }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-3 mb-3">
                                <label class="font-weight-normal"
                                  >Priority 2</label
                                >
                                <span class="requierd-star"></span>
                              </div>

                              <div class="col-md-9 mb-3">
                                <select
                                  name="primary_activity[priority2]"
                                  v-model="data.primary_activity.priority2"
                                  :class="{
                                    'is-invalid': validation.hasError(
                                      'data.primary_activity.priority2'
                                    ),
                                    'is-valid': data.primary_activity.priority2,
                                  }"
                                  class="form-control"
                                >
                                  <option selected :value="null">
                                    Choose...
                                  </option>
                                  <option
                                    v-for="(activity, key) in activity_list"
                                    :key="key"
                                    :value="activity.id"
                                  >
                                    {{ activity.title }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-3 mb-3">
                                <label class="font-weight-normal"
                                  >Priority 3</label
                                >
                                <span class="requierd-star"></span>
                              </div>

                              <div class="col-md-9 mb-3">
                                <select
                                  name="primary_activity[priority3]"
                                  v-model="data.primary_activity.priority3"
                                  :class="{
                                    'is-invalid': validation.hasError(
                                      'data.primary_activity.priority3'
                                    ),
                                    'is-valid': data.primary_activity.priority3,
                                  }"
                                  class="form-control"
                                >
                                  <option selected :value="null">
                                    Choose...
                                  </option>
                                  <option
                                    v-for="(activity, key) in activity_list"
                                    :key="key"
                                    :value="activity.id"
                                  >
                                    {{ activity.title }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="form-row">
                          <div class="col-md-12 text-center">
                            <center>
                              <p>
                                If you are an IT Enabled Service (ITES) provider
                                please fill in the following additional
                                categories
                                <span class="text-danger">*</span>
                              </p>
                            </center>
                          </div>
                          <div
                            class="col-md-6"
                            v-for="(category, key) in category_list"
                            :key="key"
                          >
                            <div class="custom-control custom-checkbox">
                              <b-form-checkbox
                                :name="
                                  'primary_activity[categories][' +
                                  category.id +
                                  ']'
                                "
                                class="col-form-label-sm font-weight-bold mb-0"
                                :value="true"
                                v-model="
                                  data.primary_activity.categories[category.id]
                                "
                                :unchecked-value="false"
                                >{{ category.title }}</b-form-checkbox
                              >
                            </div>
                          </div>
                        </div>
                        <!-- <div class="form-row col-7">
                          <div
                            v-for="(othCategory, index) in data.primary_activity.other_categories"
                            :key="index"
                            class="form-row col-12 mb-2"
                          >
                            <div class="col-8 col-md-8">
                              <input
                                :name="'primary_activity[other_categories]['+index+'][category]'"
                                type="text"
                                class="form-control shadow-none"
                                placeholder="New Category"
                                v-model="othCategory.category"
                              />
                            </div>
                            <div class="col-4 col-md-4" v-if="index>0">
                              <button
                                @click="data.primary_activity.other_categories.splice(index,1)"
                                class="btn btn-sm btn-danger"
                                type="button"
                              >
                                <span class="fas fa-times"></span>
                              </button>
                            </div>
                            <div class="col-4 col-md-4" v-if="index==0">
                              <button
                                @click="data.primary_activity.other_categories.push({})"
                                class="btn btn-sm btn-success"
                                type="button"
                                v-if="Object.keys(data.primary_activity.other_categories).length<3"
                              >Add More</button>
                            </div>
                          </div>
												</div>-->
                        <hr />
                        <div class="form-row">
                          <div class="col-md-12 text-center">
                            <b>PLEASE SPECIFY of YOUR INDUSTRY EXPERTISE</b>
                            <br />
                            <b>
                              (Based on your successfull
                              installations/services):
                            </b>
                            <br />
                          </div>
                          <div
                            class="row mb-3"
                            v-for="(primary, index) in data.primary_activity
                              .expertise_lists"
                            :key="index"
                          >
                            <div class="col-md-2 mb-3">
                              <b>
                                {{ index + 1
                                }}{{ getSuffixes(index + 1) }} Expertise:
                              </b>
                              <span class="requierd-star"></span>
                            </div>
                            <div class="col-md-4 mb-3">
                              <select
                                :name="
                                  'primary_activity[expertise_lists][' +
                                  index +
                                  '][expertiseName]'
                                "
                                v-model="primary.expertiseName"
                                :class="{
                                  'is-invalid': validation.hasError(
                                    'data.primary_activity.expertiseName'
                                  ),
                                  'is-valid': primary.expertiseName,
                                }"
                                class="form-control"
                              >
                                <option selected :value="null">
                                  Choose...
                                </option>
                                <option
                                  v-for="(
                                    expertises_name, id, key
                                  ) in expertises_lists"
                                  :key="key"
                                  :value="id"
                                >
                                  {{ expertises_name }}
                                </option>
                              </select>
                              <!-- :disabled="is_selected({value:id,data:data.primary_activity.expertise_lists,field_name:'expertiseName'})" -->
                            </div>
                            <div class="col-md-2 mb-3">
                              <input
                                :name="
                                  'primary_activity[expertise_lists][' +
                                  index +
                                  '][no_of_clients]'
                                "
                                type="number"
                                class="form-control shadow-none"
                                :class="{
                                  'is-invalid': validation.hasError(
                                    'data.primary_activity.no_of_clients'
                                  ),
                                  'is-valid': primary.no_of_clients,
                                }"
                                placeholder="Number Of Clients"
                                v-model="primary.no_of_clients"
                              />
                            </div>
                            <div class="col-md-3 mb-3">
                              <input
                                :name="
                                  'primary_activity[expertise_lists][' +
                                  index +
                                  '][remarks]'
                                "
                                type="text"
                                class="form-control shadow-none"
                                :class="{
                                  'is-invalid':
                                    validation.hasError('primary.remarks'),
                                  'is-valid': primary.remarks,
                                }"
                                placeholder="Remarks"
                                v-model="primary.remarks"
                              />
                            </div>
                            <div class="col-md-1" v-if="index > 0">
                              <button
                                @click="
                                  data.primary_activity.expertise_lists.splice(
                                    index,
                                    1
                                  )
                                "
                                class="btn btn-sm btn-danger w-100"
                                type="button"
                              >
                                <span class="fas fa-times"></span>
                              </button>
                            </div>
                          </div>
                          <div
                            class="col-lg-11 text-right"
                            v-if="data.primary_activity"
                          >
                            <button
                              v-if="
                                Object.keys(
                                  data.primary_activity.expertise_lists
                                ).length < 5
                              "
                              @click="
                                data.primary_activity.expertise_lists.push({
                                  expertiseName: null,
                                })
                              "
                              class="btn btn-sm btn-success"
                              type="button"
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button
                          type="button"
                          class="btn btn-info ml-2"
                          @click="draft_info()"
                        >
                          Save as Draft
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          class="btn btn-success float-right"
                          type="submit"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="progressTab4"
                  role="tabpanel"
                  aria-labelledby="formTab4"
                >
                  <!-- <h1>Tab4</h1> -->
                  <!-- step 4 -->
                  <div>
                    <h4 class="text-success text-weight-bold">Step 4</h4>
                    <!-- Files & Others -->
                    <div class="form-row">
                      <fieldset
                        class="col-lg-12 border border-primary p-3 mb-5"
                      >
                        <legend class="w-25 text-center">
                          <small class="font-weight-bold">FILES & OTHERS</small>
                        </legend>
                        <div class="form-row">
                          <div class="form-group col-2 col-lg-1 text-center">
                            <img
                              id="displayProfilPhoto3"
                              v-lazy="
                                image_pdf2
                                  ? $root.baseurl + imagePdfAttach
                                  : $root.baseurl + imagePdf
                              "
                              height="60px"
                              class="mt-3"
                              alt="displayProfilPhoto3"
                            />
                          </div>
                          <div class="col-10 col-lg-5 mb-3">
                            <label for="company_profile">Company Profile</label>
                            <span class="requierd-star"></span>
                            <br />
                            <!-- <small>
                                    Describing infrastructure, Human Resource, Business Activity,
                                    Capability and Experience of the Organizing/Company
														</small>-->
                            <div class="loginBox">
                              <b-form-file
                                v-model="images.company_profile_pdf"
                                ref="pdf2"
                                accept=".pdf"
                                :placeholder="
                                  data.images.company_profile_pdf
                                    ? 'File attached '
                                    : 'Choose a pdf file'
                                "
                                drop-placeholder="Drop file here..."
                              ></b-form-file>
                              <span class="mdi mdi-file-pdf text-danger"></span>
                            </div>
                            <small>File size must be smaller than 7mb</small>
                            <a
                              v-if="data.images.company_profile_pdf"
                              :href="
                                $root.baseurl +
                                '/public' +
                                data.images.company_profile_pdf
                              "
                              class="float-right"
                              target="_blank"
                              >View file</a
                            >
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "images.company_profile_pdf"
                                )
                              }}
                            </div>
                          </div>

                          <!-- =======================workorder===================== -->
                          <div class="form-group col-2 col-lg-1 text-center">
                            <img
                              id="displayProfilPhoto3"
                              v-lazy="
                                image_pdf4
                                  ? $root.baseurl + imagePdfAttach
                                  : $root.baseurl + imagePdf
                              "
                              height="60px"
                              class="mt-3"
                              alt="displayProfilPhoto3"
                            />
                          </div>
                          <div class="col-10 col-lg-5 mb-3">
                            <label for="work_order_first"
                              >Work Order (first)</label
                            >
                            <span
                              v-if="
                                data.business_activity.applying_for == 'General'
                              "
                              class="requierd-star"
                            ></span>
                            <div class="loginBox">
                              <b-form-file
                                v-model="images.work_order_first"
                                ref="pdf3."
                                accept=".pdf"
                                :placeholder="
                                  data.images.work_order_first
                                    ? 'File attached '
                                    : 'Choose a pdf file'
                                "
                                drop-placeholder="Drop file here..."
                              ></b-form-file>
                              <span class="mdi mdi-file-pdf text-danger"></span>
                            </div>
                            <small>File size must be smaller than 2mb</small>
                            <a
                              v-if="data.images.work_order_first"
                              :href="
                                $root.baseurl +
                                '/public' +
                                data.images.work_order_first
                              "
                              class="float-right"
                              target="_blank"
                              >View file</a
                            >
                            <div
                              v-if="
                                data.business_activity.applying_for == 'General'
                              "
                              class="invalid-feedback"
                            >
                              {{
                                validation.firstError("images.work_order_first")
                              }}
                            </div>
                          </div>
                          <div class="form-group col-2 col-lg-1 text-center">
                            <img
                              id="displayProfilPhoto3"
                              v-lazy="
                                image_pdf5
                                  ? $root.baseurl + imagePdfAttach
                                  : $root.baseurl + imagePdf
                              "
                              height="60px"
                              class="mt-3"
                              alt="displayProfilPhoto3"
                            />
                          </div>
                          <div class="col-10 col-lg-5 mb-3">
                            <label for="work_order_second"
                              >Work Order (Second)</label
                            >
                            <span
                              v-if="
                                data.business_activity.applying_for == 'General'
                              "
                              class="requierd-star"
                            ></span>
                            <div class="loginBox">
                              <b-form-file
                                v-model="images.work_order_second"
                                ref="pdf4"
                                accept=".pdf"
                                :placeholder="
                                  data.images.work_order_second
                                    ? 'File attached '
                                    : 'Choose a pdf file'
                                "
                                drop-placeholder="Drop file here..."
                              ></b-form-file>
                              <span class="mdi mdi-file-pdf text-danger"></span>
                            </div>
                            <small>File size must be smaller than 2mb</small>
                            <a
                              v-if="data.images.work_order_second"
                              :href="
                                $root.baseurl +
                                '/public' +
                                data.images.work_order_second
                              "
                              class="float-right"
                              target="_blank"
                              >View file</a
                            >
                            <div
                              v-if="
                                data.business_activity.applying_for == 'General'
                              "
                              class="invalid-feedback"
                            >
                              {{
                                validation.firstError(
                                  "images.work_order_second"
                                )
                              }}
                            </div>
                          </div>
                          <div class="form-group col-2 col-lg-1 text-center">
                            <img
                              id="displayProfilPhoto3"
                              v-lazy="
                                image_pdf6
                                  ? $root.baseurl + imagePdfAttach
                                  : $root.baseurl + imagePdf
                              "
                              height="60px"
                              class="mt-3"
                              alt="displayProfilPhoto3"
                            />
                          </div>
                          <div class="col-10 col-lg-5 mb-3">
                            <label for="work_order_third"
                              >Work Order (Third)</label
                            >
                            <span
                              v-if="
                                data.business_activity.applying_for == 'General'
                              "
                              class="requierd-star"
                            ></span>
                            <div class="loginBox">
                              <b-form-file
                                v-model="images.work_order_third"
                                ref="pdf5"
                                accept=".pdf"
                                :placeholder="
                                  data.images.work_order_third
                                    ? 'File attached '
                                    : 'Choose a pdf file'
                                "
                                drop-placeholder="Drop file here..."
                              ></b-form-file>
                              <span class="mdi mdi-file-pdf text-danger"></span>
                            </div>
                            <small>File size must be smaller than 2mb</small>
                            <a
                              v-if="data.images.work_order_third"
                              :href="
                                $root.baseurl +
                                '/public' +
                                data.images.work_order_third
                              "
                              class="float-right"
                              target="_blank"
                              >View file</a
                            >
                            <div
                              v-if="
                                data.business_activity.applying_for == 'General'
                              "
                              class="invalid-feedback"
                            >
                              {{
                                validation.firstError("images.work_order_third")
                              }}
                            </div>
                          </div>
                          <!-- =======================/workorder===================== -->
                          <!-- <div class="col-md-12 mb-3">
                                  <label for="work_order">Work Order (If Available)</label>
                                  <input
                                    name="files_others[work_order]"
                                    type="text"
                                    class="form-control shadow-none"
                                    id="files_others"
                                    placeholder="Work Order (If Available)"
                                    v-model="data.files_others.work_order"
                                  />
                                  <div
                                    class="invalid-feedback"
                                  >{{ validation.firstError('data.files_others.work_order') }}</div>
													</div>-->
                          <!-- <div class="col-md-6 mb-3">
                            <label for="signature_person_name">Signature Person Name</label>
                            <span class="requierd-star"></span>
                            <input
                              name="files_others[signature_person_name]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{ 'is-invalid': validation.hasError('data.files_others.signature_person_name'), 'is-valid': data.files_others.signature_person_name }"
                              id="signature_person_name"
                              placeholder="Signature Person Name"
                              v-model="data.files_others.signature_person_name"
                            />
                            <div
                              class="invalid-feedback"
                            >{{ validation.firstError('data.files_others.signature_person_name') }}</div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="signature_person_designation">Signature Person Designation</label>
                            <span class="requierd-star"></span>
                            <input
                              name="files_others[signature_person_designation]"
                              type="text"
                              class="form-control shadow-none"
                              :class="{ 'is-invalid': validation.hasError('data.files_others.signature_person_designation'), 'is-valid': data.files_others.signature_person_designation }"
                              id="signature_person_designation"
                              placeholder="Signature Person Designation"
                              v-model="data.files_others.signature_person_designation"
                            />
                            <div
                              class="invalid-feedback"
                            >{{ validation.firstError('data.files_others.signature_person_designation') }}</div>
													</div>-->
                          <div class="form-group col-2 text-center">
                            <img
                              id="displayProfilPhoto3"
                              v-lazy="image_src3"
                              height="60px"
                              alt="displayProfilPhoto3"
                            />
                          </div>
                          <div class="col-10 mb-3">
                            <label for="work_order_third"
                              >Representative Signature</label
                            >
                            <span class="requierd-star"></span>
                            <div class="loginBox">
                              <b-form-file
                                v-model="images.representative_sign"
                                ref="pdf6"
                                accept=".jpg, .png"
                                placeholder="Choose a jpg or png file"
                                drop-placeholder="Drop file here..."
                              ></b-form-file>
                            </div>
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "images.representative_sign"
                                )
                              }}
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <fieldset
                        class="col-lg-12 border border-primary p-3 mb-3"
                      >
                        <legend class="w-25 text-center">
                          <small class="font-weight-bold">REFERENCES</small>
                        </legend>
                        <div class="form-row">
                          <!--=================1st Reference=================-->
                          <!--=================1st Reference=================-->
                          <div class="col-12">
                            <h5>
                              1st Reference from any BASIS member:
                              <button
                                :disabled="fdisabled ? 'disabled' : false"
                                v-if="this.$root.variable.raLogId"
                                type="button"
                                @click="resetReferenceRequest('first')"
                                class="btn btn-danger btn-sm ml-2"
                              >
                                <slot v-if="!fdisabled">Reset Request</slot>
                                <slot v-else>
                                  <i class="fa fa-spinner fa-spin"></i>
                                  processing...
                                </slot>
                              </button>
                            </h5>
                          </div>
                          <div class="col-6 member-list">
                            <label for="district" class="col-form-label-sm m-0"
                              >Select Member</label
                            >
                            <span class="requierd-star"></span>
                            <model-select
                              :options="members"
                              name="first_reff"
                              v-model="references.first_reference_member"
                              placeholder="select item"
                            ></model-select>
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "references.first_reference_status"
                                )
                              }}
                            </div>
                            <div>
                              <span
                                v-if="
                                  first_reference_info &&
                                  first_reference_info.representatives_primary
                                "
                                >Rep. Name:
                                {{
                                  first_reference_info.representatives_primary
                                    .name
                                }}</span
                              >
                              <br />
                              <span
                                v-if="
                                  first_reference_info &&
                                  first_reference_info.representatives_primary
                                "
                                >Email:
                                {{
                                  first_reference_info.representatives_primary
                                    .email
                                }}</span
                              >
                              ,
                              <span
                                v-if="
                                  first_reference_info &&
                                  first_reference_info.representatives_primary
                                "
                                >Mobile:
                                {{
                                  first_reference_info.representatives_primary
                                    .mobile
                                }}</span
                              >
                              <br />
                            </div>
                          </div>
                          <div class="col-2 mt-4 pt-2">
                            <button
                              :disabled="fdisabled ? 'disabled' : false"
                              v-if="
                                references.first_reference_status !=
                                  'Success' && parseInt(first_timer) == 0
                              "
                              type="button"
                              @click="sendReferenceRequest('first')"
                              class="btn btn-info ml-2"
                            >
                              <slot v-if="!fdisabled">Send Request</slot>
                              <slot v-else>
                                <i class="fa fa-spinner fa-spin"></i>
                                processing...
                              </slot>
                            </button>
                            <div v-else class="ml-2 mt-2">
                              <span
                                v-if="
                                  references.first_reference_status != 'Success'
                                "
                                >Request Sent</span
                              >
                            </div>
                          </div>
                          <div class="col-2">
                            <label for="district" class="col-form-label-sm m-0"
                              >Status</label
                            >
                            <div class>
                              <b
                                v-if="
                                  references.first_reference_status == 'Pending'
                                "
                                class="text-warning"
                                >{{ references.first_reference_status }}</b
                              >
                              <b v-else class="text-success">
                                {{ references.first_reference_status }}
                              </b>
                            </div>
                          </div>
                          <div
                            class="col-2"
                            v-if="
                              references.first_reference_status == 'Pending'
                            "
                          >
                            <label class="col-12 pb-2">Expire at</label>
                            <b class="text-danger">
                              {{ firstReffTime | prettify_hours }}
                            </b>
                          </div>

                          <div
                            class="w-100 mb-0 mt-3"
                            style="border-bottom: 1px solid rgb(212 212 212)"
                          ></div>

                          <!--=================2nd Reference=================-->
                          <!--=================2nd Reference=================-->
                          <div class="col-12 mt-4">
                            <h5>
                              2nd Reference from any BASIS member:
                              <button
                                :disabled="sdisabled ? 'disabled' : false"
                                v-if="this.$root.variable.raLogId"
                                type="button"
                                @click="resetReferenceRequest('second')"
                                class="btn btn-danger btn-sm ml-2"
                              >
                                <slot v-if="!fdisabled">Reset Request</slot>
                                <slot v-else>
                                  <i class="fa fa-spinner fa-spin"></i>
                                  processing...
                                </slot>
                              </button>
                            </h5>
                          </div>
                          <div class="col-6 member-list">
                            <label class="col-form-label-sm m-0"
                              >Select Member</label
                            >
                            <span class="requierd-star"></span>
                            <model-select
                              :options="members"
                              name="first_reff"
                              v-model="references.second_reference_member"
                              placeholder="select item"
                            ></model-select>
                            <div class="invalid-feedback">
                              {{
                                validation.firstError(
                                  "references.second_reference_status"
                                )
                              }}
                            </div>
                            <div>
                              <span
                                v-if="
                                  second_reference_info &&
                                  second_reference_info.representatives_primary
                                "
                                >Rep. Name:
                                {{
                                  second_reference_info.representatives_primary
                                    .name
                                }}</span
                              >
                              <br />
                              <span
                                v-if="
                                  second_reference_info &&
                                  second_reference_info.representatives_primary
                                "
                                >Email:
                                {{
                                  second_reference_info.representatives_primary
                                    .email
                                }}</span
                              >
                              ,
                              <span
                                v-if="
                                  second_reference_info &&
                                  second_reference_info.representatives_primary
                                "
                                >Mobile:
                                {{
                                  second_reference_info.representatives_primary
                                    .mobile
                                }}</span
                              >
                              <br />
                            </div>
                          </div>

                          <div class="col-2 mt-4 pt-2">
                            <button
                              :disabled="sdisabled ? 'disabled' : false"
                              v-if="
                                references.second_reference_status !=
                                  'Success' && parseInt(second_timer) <= 0
                              "
                              type="button"
                              @click="sendReferenceRequest('second')"
                              class="btn btn-info ml-2"
                            >
                              <slot v-if="!sdisabled">Send Request</slot>
                              <slot v-else>
                                <i class="fa fa-spinner fa-spin"></i>
                                processing...
                              </slot>
                            </button>
                            <div v-else class="ml-2 mt-2">
                              <span
                                v-if="
                                  references.second_reference_status !=
                                  'Success'
                                "
                                >Request Sent</span
                              >
                            </div>
                          </div>
                          <div class="col-2">
                            <label for="district" class="col-form-label-sm m-0"
                              >Status</label
                            >
                            <div class>
                              <b
                                v-if="
                                  references.second_reference_status ==
                                  'Pending'
                                "
                                class="text-warning"
                                >{{ references.second_reference_status }}</b
                              >
                              <b v-else class="text-success">
                                {{ references.second_reference_status }}
                              </b>
                            </div>
                          </div>
                          <div
                            class="col-2"
                            v-if="
                              references.second_reference_status == 'Pending'
                            "
                          >
                            <label class="col-12 pb-2">Expire at</label>
                            <b class="text-danger">
                              {{ secondReffTime | prettify_hours }}
                            </b>
                          </div>

                          <!-- confirmation -->
                          <div class="col-sm-12 form-group mt-4">
                            <div class="col-12 pl-0">
                              <b>Note:</b> Please check the
                              <a
                                @click="openReferenceTermsModal()"
                                href="javascript:void()"
                              >
                                <b>Terms and conditions</b>
                              </a>
                              section before filled-up the referral process.
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <!-- confirmation -->
                      <div class="col-sm-12 form-group mt-4">
                        <p>I hereby declare that:</p>
                        <div class="row col-12 pl-0">
                          <div class="custom-control custom-checkbox">
                            <b-form-checkbox
                              class="col-form-label-sm font-weight-bold mb-0"
                              name="agree"
                              :value="true"
                              :class="{
                                'is-invalid': validation.hasError('data.agree'),
                              }"
                              v-model="data.agree"
                              :unchecked-value="false"
                              >All the information and documents submitted is
                              true/right to the best of my knowledge and
                              belief.</b-form-checkbox
                            >
                            <div class="invalid-feedback">
                              {{ validation.firstError("data.agree") }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- confirmation -->
                      <div class="col-sm-12 form-group mt-1">
                        <div class="row col-12 pl-0">
                          <div class="custom-control custom-checkbox">
                            <b-form-checkbox
                              class="col-form-label-sm font-weight-bold mb-0"
                              name="agree2"
                              :value="true"
                              :class="{
                                'is-invalid':
                                  validation.hasError('data.agree2'),
                              }"
                              v-model="data.agree2"
                              :unchecked-value="false"
                              >I will accept the decision of BASIS EC regarding
                              our Membership as BASIS EC has the right to accept
                              or reject the membership
                              application</b-form-checkbox
                            >
                            <div class="invalid-feedback">
                              {{ validation.firstError("data.agree2") }}
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button
                          type="button"
                          class="btn btn-info ml-2"
                          @click="draft_info()"
                        >
                          Save as Draft
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          v-if="!accounts"
                          class="btn btn-success float-right"
                          type="submit"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="progressTab5"
                  role="tabpanel"
                  :class="
                    showStep5 || $route.params.paymentOption
                      ? 'show active'
                      : ''
                  "
                  aria-labelledby="formTab5"
                >
                  <h4 class="text-success text-weight-bold">Step 5</h4>
                  <div class="form-row">
                    <fieldset class="col-lg-12 border border-primary p-3 mb-3">
                      <legend class="w-50 text-center">
                        <small class="font-weight-bold"
                          >Payment Information</small
                        >
                      </legend>
                      <!-- membership fee -->
                      <div class="col-sm-12 form-group mt-1">
                        <div class="text-center">
                          <h3 class="font-weight-bold text-danger">
                            Your membership registration fee is:
                            <span
                              v-if="
                                data.business_activity.applying_for == 'General'
                              "
                              >20,000</span
                            >
                            <span
                              v-if="
                                data.business_activity.applying_for ==
                                'Associate'
                              "
                              >10,000</span
                            >
                            <span
                              v-if="
                                data.business_activity.applying_for ==
                                'Affiliate'
                              "
                              >15,000</span
                            >
                            <span
                              v-if="
                                data.business_activity.applying_for ==
                                'International'
                              "
                              >1,70,000</span
                            >
                            Tk.
                          </h3>
                        </div>
                      </div>

                      <div class="text-center my-5">
                        <span
                          v-if="accounts && accounts.status == 'success'"
                        ></span>
                        <button
                          type="button"
                          v-else
                          @click="generateInvoice()"
                          class="btn btn-success"
                          :disabled="incomplete == true"
                        >
                          <span v-if="accounts && accounts.id != ''"
                            >Update</span
                          ><span v-else>Generate</span> Invoice
                        </button>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.org_image"
        name="old[org_image]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.rep_image"
        name="old[rep_image]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.rep_pdf"
        name="old[rep_pdf]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.certificate_at_incorporation"
        name="old[certificate_at_incorporation]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.memo_article"
        name="old[memo_article]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.tax_certificate"
        name="old[tax_certificate]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.partnership_deed"
        name="old[partnership_deed]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.board_resolution"
        name="old[board_resolution]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.tin_certificate_rep"
        name="old[tin_certificate_rep]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.tin_certificate_company"
        name="old[tin_certificate_company]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.representative_sign"
        name="old[representative_sign]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.company_profile_pdf"
        name="old[company_profile_pdf]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.work_order_first"
        name="old[work_order_first]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.work_order_second"
        name="old[work_order_second]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.work_order_third"
        name="old[work_order_third]"
      />
      <input
        type="hidden"
        v-if="data.images"
        v-model="data.images.nid_copy"
        name="old[nid_copy]"
      />
    </form>

    <!-- Modal Terms -->
    <div class="custom-modal" v-bind:class="{ show: isActive }">
      <div class="container-fluid">
        <div class="custom-modal-body">
          <button
            type="button"
            class="btn btn-sm shadow-none"
            v-on:click="openReferenceTermsModal"
          >
            <i class="mdi mdi-24px mdi-close"></i>
          </button>

          <div class="w-100">
            <h4>
              Terms & Conditions of Referral process of the BASIS Membership
            </h4>
            <p class="mt-3">
              <b style="text-decoration: underline">Approval Process:</b>
            </p>
            <ul class="ml-5">
              <li>
                The referee company will get email with a unique approval link,
                which will be valid for 48 hours. If the referee company is not
                approving Applicant Company, they can be changing the referee
                company’s name after 48 hours. After approval of the reference,
                the applicant company will receive a confirmation email.
              </li>
              <li>
                If the applicant company wants to change the referee company’s
                name, they must email to service@basis.org.bd for changing the
                referee company name during session time (48 hours).
              </li>
            </ul>
            <p class="my-3">
              <b>Who can refer a new member:</b> Those who do not know any
              company cannot give reference for them.
            </p>
            <p class="mt-3">
              <b style="text-decoration: underline">Important Note:</b>
            </p>
            <ul class="ml-5">
              <li>
                One person can refer only once in a single membership form,
                whether he/she representing 1 or more companies)
              </li>
              <li>
                If the new member company’s owner is the BASIS representative
                from different company, he/she cannot refer his/her own company.
              </li>
              <li>
                The Members of the BASIS Executive Council cannot give reference
                to any new Company.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>{{ data.images.partner }}</pre> -->
    <!-- <pre>{{ accounts }}</pre> -->
    <!-- <pre>{{ memberInfo }}</pre> -->
    <!-- <pre>{{ data }}</pre> -->
    <!-- <pre>{{ category_list }}</pre> -->
    <!-- <pre>{{ data.primary_activity.expertise_lists }}</pre> -->
    <!-- <pre>{{ validation.allErrors() }}</pre> -->
  </div>
</template>

<script>
import moment from "moment";
import Promise from "bluebird";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
import { ModelSelect } from "vue-search-select";
import Datepicker from "vuejs-datepicker";
import Dropdown from "vue-simple-search-dropdown";
import image from "../../../../assets/img/male_avatar.png";
import imagePdf from "../../../../assets/img/noimg.png";
import imagePdfAttach from "../../../../assets/img/PdfAttached.png";
const today = new Date();
today.setHours(0, 0, 0, 0);
export default {
  name: "Form",
  components: {
    Datepicker,
    ModelSelect,
  },
  data() {
    return {
      isActive: false,
      emailVarify: false,
      first_timer: 0,
      second_timer: 0,
      sdisabled: false,
      fdisabled: false,
      generalRef: "General",
      associateRef: "Associate",
      affiliateRef: "Affiliate",
      internationalRef: "International",
      errorCount: 0,
      method: this.$root.baseurl + "/public/images/method.jpg",
      imagePdf: imagePdf,
      imagePdfAttach: imagePdfAttach,
      image_src: this.$root.baseurl + image,
      image_src2: this.$root.baseurl + image,
      image_src3: this.$root.baseurl + image,
      image_pdf1: null,
      image_pdf2: null,
      image_pdf3: null,
      image_pdf4: null,
      image_pdf5: null,
      image_pdf6: null,
      image_pdf7: null,
      image_pdf10: null,
      image_pdf20: null,
      image_pdf27: null,
      image_pdf30: null,
      image_pdf11: null,
      image_pdf_nid: null,
      company_life: 0,
      company_types: {
        Proprietorship: "Proprietorship",
        Partnership: "Partnership",
        "Limited Company": "Limited Company",
        Others: "Others",
      },
      format: "D MMM YYYY",
      readonly: false,
      disabled: false,
      showStep1: true,
      showStep2: false,
      showStep3: false,
      showStep4: false,
      showStep5: false,
      incomplete: false,
      responseMsg: false,
      same_as_head: false,
      references: {},
      members: [],
      expertises_lists: { expertise: "" },
      data: {
        payment_method: "ssl",
        status: "pending",
        company_info: {
          // token_no:null,
          company_type: null,
          website: "http://www.",
          district: null,
          area: null,
          established_year: null,
          women_shareholder: "No",
        },
        board_of_director: [{}],
        organization_head: { name: null },
        representative: { gender: "Male" },
        alternative: {},
        business_activity: {
          activities: {},
          applying_for: "Associate",
          other_activities: [{ activity: null }],
        },
        primary_activity: {
          priority1: null,
          priority2: null,
          priority3: null,
          expertise_lists: [{ expertiseName: null }, { expertiseName: null }],
          categories: {},
          other_categories: [{ category: null }],
        },
        images: {
          trade_license: [{}],
          partner: [{}],
        },
        files_others: {},
        agree: false,
        agree2: false,
      },
      member: {
        info: null,
      },
      images: {
        org_image: null,
        org_file: null,
        rep_image: null,
        rep_file: null,
        rep_pdf: null,
        repp_file: null,
        nid_copy: null,
        company_profile_pdf: null,
        board_resolution: null,
        work_order_first: null,
        work_order_second: null,
        work_order_third: null,
        representative_sign: null,
        memo_article: null,
        tax_certificate: null,
        partnership_deed: null,
        tin_certificate_comapny: null,
        tin_certificate_rep: null,
      },
      districts: [],
      areas: [],
      activity_list: [],
      affiliate_category_list: [],
      category_list: [],
      memberInfo: {},
      accounts: {},
      errors: {},
      //reference info
      first_reference_info: {},
      second_reference_info: {},
    };
  },
  watch: {
    "data.company_info.established_year": function (val, oldval) {
      let today = moment();
      let established_year = moment(val);
      this.company_life = today.diff(established_year, "years");
      if (this.company_life < 2) {
        this.data.business_activity.applying_for = "Associate";
      }
    },
    "images.rep_image": function (image, oldImage) {
      this.showImage(image);
    },
    "images.org_image": function (image1, oldImage1) {
      this.showImage(image1, "image_src2");
    },
    "images.representative_sign": function (image2, oldImage2) {
      this.showImage(image2, "image_src3");
    },
    "images.rep_pdf": function (image, oldImage) {
      this.showImage(image, "image_pdf1");
    },
    "images.tin_certificate_company": function (image, oldImage) {
      this.showImage(image, "image_pdf10");
    },
    "images.certificate_at_incorporation": function (image, oldImage) {
      this.showImage(image, "image_pdf20");
    },
    "images.memo_article": function (image, oldImage) {
      this.showImage(image, "image_pdf27");
    },
    "images.tax_certificate": function (image, oldImage) {
      this.showImage(image, "image_pdf32");
    },
    "images.partnership_deed": function (image, oldImage) {
      this.showImage(image, "image_pdf30");
    },
    "images.tin_certificate_rep": function (image, oldImage) {
      this.showImage(image, "image_pdf11");
    },
    "images.company_profile_pdf": function (image, oldImage) {
      this.showImage(image, "image_pdf2");
    },
    "images.board_resolution": function (image, oldImage) {
      this.showImage(image, "image_pdf3");
    },
    "images.work_order_first": function (image, oldImage) {
      this.showImage(image, "image_pdf4");
    },
    "images.work_order_second": function (image, oldImage) {
      this.showImage(image, "image_pdf5");
    },
    "images.work_order_third": function (image, oldImage) {
      this.showImage(image, "image_pdf6");
    },
    "images.nid_copy": function (image, oldImage) {
      this.showImage(image, "image_pdf_nid");
    },
    "data.company_info.district": function (newVal, oldVal) {
      axios.get("/reg-member/get-area-list/" + newVal).then((res) => {
        this.areas = res.data;
      });
    },
    "references.first_reference_member": function (newVal, oldVal) {
      if (newVal) {
        axios.get("/reg-member/member-info/" + newVal).then((res) => {
          this.first_reference_info = res.data;
        });
      }
    },
    "references.second_reference_member": function (newVal, oldVal) {
      if (newVal) {
        axios.get("/reg-member/member-info/" + newVal).then((res) => {
          this.second_reference_info = res.data;
        });
      }
    },
  },
  methods: {
    getSuffixes: function (index) {
      var suff = "";
      if (index == 1) {
        suff = "st";
      } else if (index == 2) {
        suff = "nd";
      } else if (index == 3) {
        suff = "rd";
      } else {
        suff = "th";
      }
      return suff;
    },
    checkEstablishYear: function (e) {
      var yDate = this.data.company_info.established_year;
      if (this.company_life < 2 || !yDate) {
        this.data.business_activity.applying_for = "Associate";
        if (!yDate) {
          this.$toastr(
            "warning",
            "Please select <span class='font-weight-bold'>Year of Establishment</span> date",
            "Step 1"
          );
        } else {
          this.$toastr(
            "warning",
            "Your company age must have more than 2 years for this category",
            "Sorry!!"
          );
        }
      }
    },
    checkEstablishYearLessThanTwoYears: function (e) {
      var yDate = this.data.company_info.established_year;
      if (this.company_life > 2 || !yDate) {
        this.data.business_activity.applying_for = "Associate";
        if (!yDate) {
          this.$toastr(
            "warning",
            "Please select <span class='font-weight-bold'>Year of Establishment</span> date",
            "Step 1"
          );
        } else {
          this.$toastr(
            "warning",
            "Your company age more than 2 years, you are not eligible for this category",
            "Sorry!!"
          );
        }
      }
    },
    subString: function (path) {
      var split = path.split("__");
      return split[1] ? split[1] : "No name of trade license";
    },
    same_as_head_method: function (e) {
      if (this.same_as_head) {
        if (
          this.data.organization_head &&
          this.data.organization_head.name != ""
        ) {
          let head = this.data.organization_head;
          let rep = this.data.representative;
          this.$set(rep, "name", head.name);
          this.$set(rep, "designation", head.designation);
          this.$set(rep, "direct_phone", head.direct_phone);
          this.$set(rep, "mobile", head.mobile);
          this.$set(rep, "linkedin", head.linkedin);
          this.$set(rep, "email", head.email);
          this.readonly = true;
        } else {
          this.$toastr(
            "warning",
            "Please fillup organization head",
            "Empty Organization Head!!"
          );
        }
      }
    },
    draft_info() {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      var form = document.getElementById("form");
      var formData = new FormData(form);
      //for draft
      formData.append("draft_info", "yes");

      //check this member lock or unlock
      if (this.memberInfo.is_lock == 1) {
        this.$toastr(
          "error",
          "Your application has already been submitted, You can’t update your application.",
          "Warning"
        );
        return false;
      }

      if (!this.$root.variable.raLogId) {
        if (
          (this.memberInfo &&
            this.memberInfo.tracking_status == "Documents Received by BASIS") ||
          this.memberInfo.tracking_status == "Visit Pending" ||
          this.memberInfo.tracking_status == "Waiting for Approval"
        ) {
          this.$toastr(
            "error",
            "Sorry!! You cann't update your profile",
            "Warning"
          );
          return false;
        }
      }
      if (this.data.images.trade_license) {
        $.each(this.data.images.trade_license, function (key, value) {
          if (value.trade_pdf) {
            formData.append("trade_pdf[" + key + "]", value.trade_pdf);
          }
        });
      }
      if (this.data.images.partner) {
        $.each(this.data.images.partner, function (key, value) {
          if (value.tin_pdf) {
            formData.append("partner_pdf[" + key + "]", value.tin_pdf);
          }
        });
      }
      if (this.images.tin_certificate_company) {
        formData.append(
          "tin_certificate_company",
          this.images.tin_certificate_company
        );
      } else {
        formData.append("tin_certificate_company", "");
      }
      if (this.images.tin_certificate_rep) {
        formData.append("tin_certificate_rep", this.images.tin_certificate_rep);
      } else {
        formData.append("tin_certificate_rep", "");
      }
      if (this.images.certificate_at_incorporation) {
        formData.append(
          "certificate_at_incorporation",
          this.images.certificate_at_incorporation
        );
      } else {
        formData.append("certificate_at_incorporation", "");
      }
      if (this.images.memo_article) {
        formData.append("memo_article", this.images.memo_article);
      } else {
        formData.append("memo_article", "");
      }
      if (this.images.tax_certificate) {
        formData.append("tax_certificate", this.images.tax_certificate);
      } else {
        formData.append("tax_certificate", "");
      }
      if (this.images.partnership_deed) {
        formData.append("partnership_deed", this.images.partnership_deed);
      } else {
        formData.append("partnership_deed", "");
      }
      if (this.images.org_image) {
        formData.append("org_image", this.images.org_image);
      } else {
        formData.append("org_image", "");
      }
      if (this.images.rep_image) {
        formData.append("rep_image", this.images.rep_image);
      } else {
        formData.append("rep_image", "");
      }
      if (this.images.rep_pdf) {
        formData.append("rep_pdf", this.images.rep_pdf);
      } else {
        formData.append("rep_pdf", "");
      }
      if (this.images.company_profile_pdf) {
        formData.append("company_profile_pdf", this.images.company_profile_pdf);
      } else {
        formData.append("company_profile_pdf", "");
      }
      if (this.images.board_resolution) {
        formData.append("board_resolution", this.images.board_resolution);
      } else {
        formData.append("board_resolution", "");
      }
      if (this.images.work_order_first) {
        formData.append("work_order_first", this.images.work_order_first);
      } else {
        formData.append("work_order_first", "");
      }
      if (this.images.work_order_second) {
        formData.append("work_order_second", this.images.work_order_second);
      } else {
        formData.append("work_order_second", "");
      }
      if (this.images.work_order_third) {
        formData.append("work_order_third", this.images.work_order_third);
      } else {
        formData.append("work_order_third", "");
      }
      if (this.images.representative_sign) {
        formData.append("representative_sign", this.images.representative_sign);
      } else {
        formData.append("representative_sign", "");
      }
      if (this.images.nid_copy) {
        formData.append("nid_copy", this.images.nid_copy);
      } else {
        formData.append("nid_copy", "");
      }
      this.$root.spinner = true;

      axios
        .post("/reg-member/store-profile-info", formData, config)
        .then((respons) => {
          if (respons.data) {
            this.errors = {};
            this.$toastr(
              "success",
              "Your data has been successfuly drafted",
              "Drafted!!"
            );
            location.reload();

            this.scrollTop();
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors || {};
          }
        })
        .then((always) => {
          setTimeout(
            () => (this.$root.spinner = false),
            this.$root.minloadtimefd
          );
        });
    },
    generateInvoice: function () {
      this.$validate().then((res) => {
        if (res) {
          this.scrollTop();
          this.$root.spinner = true;
          var payment = this.data.payment_method;
          var type = this.data.business_activity.applying_for;
          axios
            .post("/reg-member/formClose", {
              payment_method: payment,
              type: type,
            })
            .then((respons) => {
              if (respons.data) {
                this.$router.push({
                  name: "reg-member.invoice",
                  params: { paymentOption: true },
                });
              }
            })
            .catch((error) => {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors || {};
                this.focus_field = Object.keys(this.errors)[0];
              }
            });
        }
      });
    },
    submit: function (e) {
      e.preventDefault();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      //check this member lock or unlock
      if (this.memberInfo.is_lock == 1) {
        this.$toastr(
          "error",
          "Your application has already been submitted, You can’t update your application.",
          "Warning"
        );
        return false;
      }
      if (!this.$root.variable.raLogId) {
        if (
          (this.memberInfo &&
            this.memberInfo.tracking_status == "Documents Received by BASIS") ||
          this.memberInfo.tracking_status == "Visit Pending" ||
          this.memberInfo.tracking_status == "Waiting for Approval"
        ) {
          this.$toastr(
            "error",
            "Sorry!! You cann't update your profile",
            "Warning"
          );
          return false;
        }
      }

      var form = document.getElementById("form");
      var formData = new FormData(form);

      /*when company type Proprietorship then check company head & representative info same */
      if (this.data.company_info.company_type == "Proprietorship") {
        if (
          this.data.organization_head.name != this.data.representative.name ||
          this.data.organization_head.designation !=
            this.data.representative.designation ||
          this.data.organization_head.mobile !=
            this.data.representative.mobile ||
          this.data.organization_head.email != this.data.representative.email
        ) {
          this.$toastr(
            "warning",
            "Organization head and Representative information must be same",
            "Not Match!!"
          );
          return false;
        }
      }

      if (confirm("Are you confirming, is the provided information valid?")) {
        if (this.data.images.trade_license) {
          $.each(this.data.images.trade_license, function (key, value) {
            if (value.trade_pdf) {
              formData.append("trade_pdf[" + key + "]", value.trade_pdf);
            }
          });
        }
        if (this.data.images.partner) {
          $.each(this.data.images.partner, function (key, value) {
            if (value.tin_pdf) {
              formData.append("partner_pdf[" + key + "]", value.tin_pdf);
            }
          });
        }
        if (this.images.tin_certificate_company) {
          formData.append(
            "tin_certificate_company",
            this.images.tin_certificate_company
          );
        } else {
          formData.append("tin_certificate_company", "");
        }
        if (this.images.tin_certificate_rep) {
          formData.append(
            "tin_certificate_rep",
            this.images.tin_certificate_rep
          );
        } else {
          formData.append("tin_certificate_rep", "");
        }
        if (this.images.certificate_at_incorporation) {
          formData.append(
            "certificate_at_incorporation",
            this.images.certificate_at_incorporation
          );
        } else {
          formData.append("certificate_at_incorporation", "");
        }
        if (this.images.memo_article) {
          formData.append("memo_article", this.images.memo_article);
        } else {
          formData.append("memo_article", "");
        }
        if (this.images.tax_certificate) {
          formData.append("tax_certificate", this.images.tax_certificate);
        } else {
          formData.append("tax_certificate", "");
        }
        if (this.images.partnership_deed) {
          formData.append("partnership_deed", this.images.partnership_deed);
        } else {
          formData.append("partnership_deed", "");
        }
        if (this.images.org_image) {
          formData.append("org_image", this.images.org_image);
        } else {
          formData.append("org_image", "");
        }
        if (this.images.rep_image) {
          formData.append("rep_image", this.images.rep_image);
        } else {
          formData.append("rep_image", "");
        }
        if (this.images.rep_pdf) {
          formData.append("rep_pdf", this.images.rep_pdf);
        } else {
          formData.append("rep_pdf", "");
        }
        if (this.images.company_profile_pdf) {
          formData.append(
            "company_profile_pdf",
            this.images.company_profile_pdf
          );
        } else {
          formData.append("company_profile_pdf", "");
        }
        if (this.images.board_resolution) {
          formData.append("board_resolution", this.images.board_resolution);
        } else {
          formData.append("board_resolution", "");
        }
        if (this.images.work_order_first) {
          formData.append("work_order_first", this.images.work_order_first);
        } else {
          formData.append("work_order_first", "");
        }
        if (this.images.work_order_second) {
          formData.append("work_order_second", this.images.work_order_second);
        } else {
          formData.append("work_order_second", "");
        }
        if (this.images.work_order_third) {
          formData.append("work_order_third", this.images.work_order_third);
        } else {
          formData.append("work_order_third", "");
        }
        if (this.images.representative_sign) {
          formData.append(
            "representative_sign",
            this.images.representative_sign
          );
        } else {
          formData.append("representative_sign", "");
        }
        if (this.images.nid_copy) {
          formData.append("nid_copy", this.images.nid_copy);
        } else {
          formData.append("nid_copy", "");
        }
        if (this.showStep4) {
          formData.set("tracking_status", "Waiting for Payment");
        }

        this.$validate().then((res) => {
          if (this.validation.countErrors() > 0) {
            console.log(this.validation.allErrors());
            this.$toastr(
              "warning",
              'You need to fill <span class="font-weight-bold">' +
                this.validation.countErrors() +
                " more empty</span> mandatory fields",
              "Empty Mandatory Fields!!"
            );
          } else {
            if (res) {
              if (this.showStep1 || this.showStep4 || this.showStep5) {
                let trade = this.data.images.trade_license;
                let check = false;
                if (trade && Object.keys(trade).length > 0) {
                  trade.forEach((el) => {
                    check = el["license_number"] ? false : true;
                    check = el["validity_date"] ? false : true;
                    check = el["trade_license"]
                      ? false
                      : el["trade_pdf"]
                      ? false
                      : true;
                  });
                }
                if (check) {
                  this.$toastr(
                    "warning",
                    'Please fillup <span class="font-weight-bold"> trade license</span> number / file / date',
                    "Trade License!!"
                  );
                  return false;
                }
              }
              if (this.showStep1 || this.showStep4 || this.showStep5) {
                if (this.data.company_info.company_type == "Partnership") {
                  let part = this.data.images.partner;
                  let check1 = false;
                  console.log(part);
                  if (part && Object.keys(part).length > 0) {
                    part.forEach((pel) => {
                      check1 = pel["tin_pdf"] ? false : true;
                      check1 = pel["tin_number"] ? false : true;
                    });
                  }
                  if (check1) {
                    this.$toastr(
                      "warning",
                      'Please upload <span class="font-weight-bold">tin certificate / tin_number</span>',
                      "Partner's Information!!"
                    );
                    return false;
                  }
                }
              }
              if (this.data.business_activity.applying_for != "Affiliate") {
                if (this.showStep2 || this.showStep4 || this.showStep5) {
                  if (
                    this.data.business_activity &&
                    Object.keys(this.data.business_activity.activities)
                      .length == 0
                  ) {
                    this.$toastr(
                      "warning",
                      'Please select <span class="font-weight-bold">minimum 1 business activity</span>',
                      "Business Activity !!"
                    );
                    return false;
                  }
                }
              } else if (!this.data.company_info.affiliate_category) {
                this.$toastr(
                  "warning",
                  'Please select <span class="font-weight-bold">category</span>',
                  "Affiliate Category !!"
                );
                return false;
              }
              if (this.showStep3 || this.showStep4 || this.showStep5) {
                if (
                  this.data.primary_activity &&
                  Object.keys(this.data.primary_activity.categories).length == 0
                ) {
                  this.$toastr(
                    "warning",
                    'Please select <span class="font-weight-bold">minimum 1 ITES</span>',
                    "IT Enabled Service (ITES)!!"
                  );
                  return false;
                }
              }
              if (this.showStep3 || this.showStep4 || this.showStep5) {
                if (this.data.primary_activity) {
                  // if (
                  //   !this.data.primary_activity.expertise_lists[0]
                  //     .expertiseName ||
                  //   !this.data.primary_activity.expertise_lists[1].expertiseName
                  // ) {
                  //   this.$toastr(
                  //     "warning",
                  //     'Please select <span class="font-weight-bold">minimum 2 industry expertise</span>',
                  //     "Industry Expertise !!"
                  //   );
                  //   return false;
                  // }

                  this.errorCount = 0;
                  this.data.primary_activity.expertise_lists.forEach(
                    (element) => {
                      if (!element.expertiseName || !element.no_of_clients) {
                        this.errorCount++;
                      }
                    }
                  );
                }
              }
              if (this.errorCount > 0) {
                this.$toastr(
                  "warning",
                  "Industry Expertise is required",
                  "Industry Expertise!!"
                );
                return false;
              }
              if (this.showStep4) {
                //when two refference status success then execute next step
                if (
                  (this.references &&
                    this.references.first_reference_status != "Success") ||
                  this.references.second_reference_status != "Success"
                ) {
                  this.$toastr(
                    "warning",
                    'Your reference from BASIS member company is pending, please wait for the "success" status.',
                    "Reference!!"
                  );
                  return false;
                }

                formData.append("proceed_to_pay", 1);
              }

              this.scrollTop();
              this.$parent.loading = true;
              axios
                .post("/reg-member/store-profile-info", formData, config)
                .then((respons) => {
                  if (respons.data) {
                    if (this.showStep4) {
                      $("#progressTab")
                        .children()
                        .children("a")
                        .removeClass("active");

                      this.showStep4 = false;
                      this.showStep1 = false;
                      this.showStep2 = false;
                      this.showStep3 = false;
                      this.showStep5 = true;

                      $("#progressTabContent")
                        .children()
                        .removeClass("show active");

                      // this.$router.push({
                      //   name: "reg-member.pdf",
                      //   params: { paymentOption: true }
                      // });
                      //window.location = this.$root.baseurl + "/reg-member/pdf";
                    } else if (
                      this.showStep1 ||
                      this.showStep2 ||
                      this.showStep3
                    ) {
                      $(".nav > .nav-item > .active")
                        .parent()
                        .next("li")
                        .find("a")
                        .trigger("click");
                      var text = $(".steps")
                        .children(".nav-item")
                        .find(".nav-link.active")
                        .children("span")
                        .text();
                      if (parseInt(text) == 2) {
                        this.showStep2 = true;
                        this.showStep1 = false;
                        this.showStep3 = false;
                        this.showStep4 = false;
                        this.showStep5 = false;
                      } else if (parseInt(text) == 3) {
                        this.showStep3 = true;
                        this.showStep1 = false;
                        this.showStep2 = false;
                        this.showStep4 = false;
                        this.showStep5 = false;
                      } else if (parseInt(text) == 4) {
                        this.showStep4 = true;
                      }
                    }

                    if (this.showStep4) {
                      var msg = "Successfully completed your information";
                      this.$toastr("success", msg, "Success!!");
                    } else if (!this.showStep5) {
                      var msg = "Successfully completed this step";
                      this.$toastr("success", msg, "Success!!");
                    }
                    this.errors = {};
                    this.scrollTop();
                  }
                })
                .catch((error) => {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                  }
                })
                .then((always) => {
                  setTimeout(
                    () => (this.$parent.loading = false),
                    this.$root.minloadtimefd
                  );
                });
            }
          }
        });
      }
    },
    showStep: function (step) {
      if ("showStep1" == step) {
        this.showStep1 = true;
        this.showStep2 = false;
        this.showStep3 = false;
        this.showStep4 = false;
        this.showStep5 = false;
      } else if ("showStep2" == step) {
        this.showStep1 = false;
        this.showStep2 = true;
        this.showStep3 = false;
        this.showStep4 = false;
        this.showStep5 = false;
      } else if ("showStep3" == step) {
        this.showStep1 = false;
        this.showStep2 = false;
        this.showStep3 = true;
        this.showStep4 = false;
        this.showStep5 = false;
      } else if ("showStep4" == step) {
        this.showStep1 = false;
        this.showStep2 = false;
        this.showStep3 = false;
        this.showStep4 = true;
        this.showStep5 = false;
      } else if ("showStep5" == step) {
        $("#progressTab").children().children("a").removeClass("active");

        this.showStep1 = false;
        this.showStep2 = false;
        this.showStep3 = false;
        this.showStep4 = false;
        this.showStep5 = true;

        $("#progressTabContent").children().removeClass("show active");
      }
    },
    getProfile: function () {
      // this.$root.spinner = true;
      axios
        .get("/reg-member/get-profile-info")
        .then((response) => {
          if (Object.keys(response.data).length > 0) {
            this.data = response.data;
            if (response.data.images) {
              if (response.data.images.org_image) {
                this.image_src2 =
                  this.$root.baseurl +
                  "/public" +
                  response.data.images.org_image;
              }
              if (response.data.images.rep_image) {
                this.image_src =
                  this.$root.baseurl +
                  "/public" +
                  response.data.images.rep_image;
              }
              if (response.data.images.representative_sign) {
                this.image_src3 =
                  this.$root.baseurl +
                  "/public" +
                  response.data.images.representative_sign;
              }
              if (response.data.images.rep_pdf) {
                this.image_pdf1 = true;
              }
              if (response.data.images.company_profile_pdf) {
                this.image_pdf2 = true;
              }
              if (response.data.images.board_resolution) {
                this.image_pdf3 = true;
              }
              if (response.data.images.work_order_first) {
                this.image_pdf4 = true;
              }
              if (response.data.images.work_order_second) {
                this.image_pdf5 = true;
              }
              if (response.data.images.work_order_third) {
                this.image_pdf6 = true;
              }
              if (response.data.images.tin_certificate_company) {
                this.image_pdf10 = true;
              }
              if (response.data.images.certificate_at_incorporation) {
                this.image_pdf20 = true;
              }
              if (response.data.images.memo_article) {
                this.image_pdf27 = true;
              }
              if (response.data.images.tax_certificate) {
                this.image_pdf32 = true;
              }
              if (response.data.images.partnership_deed) {
                this.image_pdf30 = true;
              }
              if (response.data.images.tin_certificate_rep) {
                this.image_pdf11 = true;
              }
              if (response.data.images.nid_copy) {
                this.image_pdf_nid = true;
              }
            }
          }
        })
        .catch((error) => {})
        .then((always) => {
          setTimeout(
            () => (this.$root.spinner = false),
            this.$root.minloadtimefd
          );
        });
    },
    remove_checkmark_disabled() {
      Promise.delay(500).then(function () {
        $("input").each(function () {
          var readonly = $(this).attr("readonly");
          if (readonly && readonly.toLowerCase() !== "false") {
            // this is readonly
            $(this).removeClass("is-valid");
          }
        });
      });
    },
    getMember() {
      axios.get("/reg-member/members-for-reference").then((response) => {
        this.members = response.data;
      });
    },
    attach(e, key, index) {
      const file = e.target.files[0];
      if (!file) {
        e.preventDefault();
        alert("No file chosen");
        return;
      }
      const isPDF = file["type"].split("/")[1] == "pdf" ? true : false;
      if (!isPDF) {
        if (index == "trade_pdf") {
          this.data.images.trade_license[index][key] = "";
        } else {
          this.data.images.partner[index][key] = "";
        }
        this.$toastr("warning", "PDF format required", "Warning!!");
        document.getElementById(key + index).value = "";
        return;
      }
      let mb = 1024 * 1024;
      if (file.size > 7168000) {
        e.preventDefault();
        setTimeout(() => {
          if (index == "trade_pdf") {
            this.data.images.trade_license[index][key] = "";
          } else {
            this.data.images.partner[index][key] = "";
          }
          document.getElementById(key + index).value = "";
        }, 200);
        this.$toastr(
          "warning",
          "File size must be smaller than 7mb",
          "Warning!!"
        );
        return;
      }
    },
    openReferenceTermsModal() {
      this.isActive = !this.isActive;
    },
    getReference(dateTime = true) {
      axios.get("/reg-member/get-reference").then((res) => {
        this.references = res.data;
        if (dateTime) {
          this.dateTimeConvert(res.data);
        }
      });
    },
    sendReferenceRequest(type) {
      this.references.type = type;
      let first = this.references.first_reference_member;
      let second = this.references.second_reference_member;

      if (type == "first" && !first) {
        this.$toastr("warning", "Please select member", "Warning!!");
        return false;
      }
      if (type == "second" && !second) {
        this.$toastr("warning", "Please select member", "Warning!!");
        return false;
      }
      if (first == second) {
        this.$toastr(
          "warning",
          "You have already selected this member",
          "Warning!!"
        );
        type == "first" ? (this.references.first_reference_member = "") : "";
        type == "second" ? (this.references.second_reference_member = "") : "";
        return false;
      }

      type == "first" ? (this.fdisabled = true) : "";
      type == "second" ? (this.sdisabled = true) : "";

      if (confirm("Are you sure want to send request?")) {
        axios
          .post("/reg-member/reference-request", this.references)
          .then((res) => {
            if (res.data.status == 404) {
              this.$toastr("warning", res.data.message, "Warning!!");
            } else {
              this.getReference();
              this.$toastr(
                "success",
                "Request sent successfully. Please contact with Representative of the selected company for approval.",
                "Success!!"
              );
            }
            setTimeout(() => {
              this.fdisabled = false;
              this.sdisabled = false;
            }, 400);
          })
          .catch((error) => console.log(error.response))
          .then((alw) => {
            setTimeout(() => {
              this.fdisabled = false;
              this.sdisabled = false;
            }, 400);
          });
      }
    },
    resetReferenceRequest(type) {
      this.references.type = type;
      let first = this.references.first_reference_member;
      let second = this.references.second_reference_member;

      if (confirm("Are you sure want to reset?")) {
        axios
          .post("/reg-member/reset-reference-request", this.references)
          .then((res) => {
            this.getReference(false);
            this.$toastr("success", "Resquest reset successfully", "Success!!");
            setTimeout(() => {
              this.fdisabled = false;
              this.sdisabled = false;

              type == "first" ? (this.first_timer = 0) : "";
              type == "second" ? (this.second_timer = 0) : "";
            }, 400);
            type == "first" ? (this.fdisabled = true) : "";
            type == "second" ? (this.sdisabled = true) : "";
          });
      }
    },
    dateTimeConvert(data) {
      if (data.first_reference_date) {
        let todate = moment().format("YYYY-MM-DD HH:mm:ss");
        let fsecs = moment(data.first_reference_date).diff(
          moment(todate),
          "seconds"
        );
        if (parseInt(fsecs) > 0) {
          this.first_timer = fsecs;
        }
      }
      if (data.second_reference_date) {
        let stodate = moment().format("YYYY-MM-DD HH:mm:ss");
        let secs = moment(data.second_reference_date).diff(
          moment(stodate),
          "seconds"
        );
        if (parseInt(secs) > 0) {
          this.second_timer = secs;
        }
      }
      setTimeout(() => {
        if (data.first_reference_status == "Pending") {
          this.firstReffCountDown();
        }
        if (data.second_reference_status == "Pending") {
          this.secondReffCountDown();
        }
      }, 200);
    },
    firstReffCountDown() {
      if (parseInt(this.first_timer) > 0) {
        setTimeout(() => {
          this.first_timer -= 1;
          this.firstReffCountDown();
        }, 1000);
      } else {
      }
    },
    secondReffCountDown() {
      if (parseInt(this.second_timer) > 0) {
        setTimeout(() => {
          this.second_timer -= 1;
          this.secondReffCountDown();
        }, 1000);
      } else {
      }
    },
    getProfileAndAccountInfo: function () {
      axios.get("/reg-member/get-profile-account-info").then((response) => {
        if (Object.keys(response.data).length > 0) {
          this.accounts = response.data.account;
        }
      });
    },
    invoicePaidStatus() {
      if (this.accounts && this.accounts.status == "success") {
        return false;
      }
      return true;
    },
  },
  created() {
    let vm = this;
    this.getReference();
    this.LoggedInRegMember();
    this.getProfileAndAccountInfo();
    this.getList("districts", "districts");
    // business activity list
    axios.get("/admin/getAllBusinessActivityData").then((response) => {
      this.activity_list = response.data;
    });
    axios.get("/admin/getAllAffiliateCategoryData").then((response) => {
      this.affiliate_category_list = response.data;
    });
    axios.get("/admin/getAllCategoryData").then((response) => {
      this.category_list = response.data;
    });
    // Member Expertise List
    axios.get("/reg-member/getExpertiseList2").then((response) => {
      this.expertises_lists = response.data;
    });

    axios.get("/reg-member/user").then((response) => {
      this.memberInfo = response.data;
    });

    this.getMember();
    this.getProfile();
  },
  computed: {
    firstReffTime() {
      var hrs = ~~(this.first_timer / 3600);
      var mins = ~~((this.first_timer % 3600) / 60);
      var secs = ~~this.first_timer % 60;
      return hrs + ":" + mins + ":" + secs;
    },
    secondReffTime() {
      var hrs = ~~(this.second_timer / 3600);
      var mins = ~~((this.second_timer % 3600) / 60);
      var secs = ~~this.second_timer % 60;
      return hrs + ":" + mins + ":" + secs;
    },
  },
  mounted() {},

  validators: {
    // step 1=============
    // step 1=============
    "data.company_info.company_name": function (value = null) {
      var app = this;
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Company Name is required");
      }
    },
    "data.company_info.company_type": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Company Type is required");
      }
    },
    "data.company_info.website": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Website is required");
      }
    },
    "data.company_info.business_type": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required(
          "Is the trade license contains software or ITES as buiness type is required"
        );
      }
    },

    "images.board_resolution": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (this.data.company_info.company_type != "Proprietorship") {
          if (!this.data.images.board_resolution) {
            return Validator.value(value)
              .required("Board Resolution is required")
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 7168000) {
                    return "Pdf must be smaller than 7mb";
                  }
                }
              });
          }
        } else {
          return Validator.value(value);
        }
      }
    },
    "images.tin_certificate_company": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (this.data.company_info.company_type != "Proprietorship") {
          if (!this.data.images.tin_certificate_company) {
            return Validator.value(value)
              .required("Tin Certificate is required")
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 7168000) {
                    return "Pdf must be smaller than 7mb";
                  }
                }
              });
          }
        } else {
          return Validator.value(value);
        }
      }
    },
    "data.company_info.company_tin": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (this.data.company_info.company_type != "Proprietorship") {
          return Validator.value(value).required("Tin Number is required");
        } else {
          return Validator.value(value);
        }
      }
    },
    "images.tin_certificate_rep": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (this.data.company_info.company_type == "Proprietorship") {
          if (!this.data.images.tin_certificate_rep) {
            return Validator.value(value)
              .required("Tin Certificate is required")
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 7168000) {
                    return "Pdf must be smaller than 7mb";
                  }
                }
              });
          }
        } else {
          return Validator.value(value);
        }
      }
    },
    "data.representative.gender": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Gender is required");
      }
    },
    "data.representative.tin": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (this.data.company_info.company_type == "Proprietorship") {
          return Validator.value(value).required("Tin Number is required");
        } else {
          return Validator.value(value);
        }
      }
    },
    "images.certificate_at_incorporation": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (
          this.data.company_info.company_type == "Limited Company" ||
          this.data.company_info.company_type == "Others"
        ) {
          if (!this.data.images.certificate_at_incorporation) {
            return Validator.value(value)
              .required("Certificate of incorporation is required")
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 7168000) {
                    return "Pdf must be smaller than 7mb";
                  }
                }
              });
          }
        }
      }
    },
    "images.memo_article": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (
          this.data.company_info.company_type == "Limited Company" ||
          this.data.company_info.company_type == "Others"
        ) {
          if (!this.data.images.memo_article) {
            return Validator.value(value)
              .required("Memorandum of Articles is required")
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 2048000) {
                    return "Pdf must be smaller than 2mb";
                  }
                }
              });
          } else {
            return Validator.value(value)
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 2048000) {
                    return "Pdf must be smaller than 2mb";
                  }
                }
              });
          }
        }
      }
    },
    // "images.tax_certificate": function (value = null) {
    //   if (this.showStep1 || this.showStep4 || this.showStep5) {
    //     if (
    //       this.data.company_info.company_type == "Limited Company" ||
    //       this.data.company_info.company_type == "Others"
    //     ) {
    //       if (!this.data.images.tax_certificate) {
    //         return Validator.value(value)
    //           .required("Tax certificate is required")
    //           .custom(function () {
    //             if (!Validator.isEmpty(value)) {
    //               var type = value.type;
    //               if (type == "application/pdf") {
    //               } else {
    //                 return "PDF format required";
    //               }
    //             }
    //           })
    //           .custom(function () {
    //             if (!Validator.isEmpty(value)) {
    //               var size = value.size;
    //               if (size > 2048000) {
    //                 return "Pdf must be smaller than 2mb";
    //               }
    //             }
    //           });
    //       } else {
    //         return Validator.value(value)
    //           .custom(function () {
    //             if (!Validator.isEmpty(value)) {
    //               var type = value.type;
    //               if (type == "application/pdf") {
    //               } else {
    //                 return "PDF format required";
    //               }
    //             }
    //           })
    //           .custom(function () {
    //             if (!Validator.isEmpty(value)) {
    //               var size = value.size;
    //               if (size > 2048000) {
    //                 return "Pdf must be smaller than 2mb";
    //               }
    //             }
    //           });
    //       }
    //     }
    //   }
    // },
    "images.partnership_deed": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (this.data.company_info.company_type == "Partnership") {
          if (!this.data.images.partnership_deed) {
            return Validator.value(value)
              .required("Partnership Deed is required")
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 7168000) {
                    return "Pdf must be smaller than 7mb";
                  }
                }
              });
          } else {
            return Validator.value(value)
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 7168000) {
                    return "Pdf must be smaller than 7mb";
                  }
                }
              });
          }
        }
      }
    },
    "data.representative.nid": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("National ID no is required")
          .digit("Digit only");
      }
    },
    "images.nid_copy": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (!this.data.images.nid_copy) {
          return Validator.value(value)
            .required("National ID Copy no is required")
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "application/pdf") {
                } else {
                  return "PDF format required";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 7168000) {
                  return "Pdf must be smaller than 7mb";
                }
              }
            });
        } else {
          return Validator.value(value)
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "application/pdf") {
                } else {
                  return "PDF format required";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 7168000) {
                  return "Pdf must be smaller than 7mb";
                }
              }
            });
        }
      }
    },
    "data.company_info.established_year": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Established Year is required");
      }
    },
    "data.company_info.registered_address": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required(
          "Registered Address is required"
        );
      }
    },
    "data.company_info.email": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Email is required").email();
      }
    },
    "data.company_info.phone": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Mobile is required");
        // .digit()
        // .regex("01+[0-9+-]*$", "Must start with 01.")
        // .minLength(11)
        // .maxLength(15);
      }
    },
    "data.company_info.district": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("District is required");
      }
    },
    "data.company_info.post_code": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .digit()
          .required("Postal Code is required");
      }
    },
    "data.organization_head.name": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Name is required")
          .minLength(2)
          .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.");
      }
    },
    "data.organization_head.designation": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Designation is required")
          .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.");
      }
    },
    "data.organization_head.direct_phone": function (value = null) {
      // if (this.showStep1 || this.showStep4 || this.showStep5) {
      return Validator.value(value).digit();
      //     .required("Direct Phone is required")
      //     .regex("^[0-9+-]*$", "Must be a digit.");
      // }
    },
    "data.organization_head.mobile": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Mobile is required")
          .digit()
          .regex("01+[0-9+-]*$", "Must start with 01.")
          .minLength(11)
          .maxLength(11);
      }
    },
    "data.organization_head.email": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Email is required").email();
      }
    },
    "data.organization_head.linkedin": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).url(
          "Enter a valid url. Ex: https://www.linkedin.com/in/example"
        );
      }
    },
    "images.org_image": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (!this.data.images.org_image) {
          return Validator.value(value)
            .required("Image is required")
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "image/jpeg" || type == "image/png") {
                } else {
                  return "Image must be of type .jpg or .png";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 260096) {
                  return "Image must be smaller than 250kb";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var wid, high;
                var _URL = window.URL || window.webkitURL;
                $("#org_image1").change(function (e) {
                  //  console.log(this.width + ", " + this.height);
                  var file, img;
                  if ((file = this.files[0])) {
                    img = new Image();
                    img.onload = function () {
                      wid = this.width;
                      high = this.height;
                      //  console.log(this.width + ", " + this.height);
                    };
                    img.src = _URL.createObjectURL(file);
                  }
                });

                return Promise.delay(500).then(function () {
                  //  console.log("asd="+wid+",  ada="+high);
                  if (wid != undefined && high != undefined) {
                    if (wid != 300 || high != 350) {
                      return "image must be width:300px x height:350px";
                    }
                  }
                });
              }
            });
        } else {
          return Validator.value(value)
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "image/jpeg" || type == "image/png") {
                } else {
                  return "Image must be of type .jpg or .png";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 260096) {
                  return "Image must be smaller than 250kb";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var wid, high;
                var _URL = window.URL || window.webkitURL;
                $("#org_image1").change(function (e) {
                  //  console.log(this.width + ", " + this.height);
                  var file, img;
                  if ((file = this.files[0])) {
                    img = new Image();
                    img.onload = function () {
                      wid = this.width;
                      high = this.height;
                      //  console.log(this.width + ", " + this.height);
                    };
                    img.src = _URL.createObjectURL(file);
                  }
                });

                return Promise.delay(500).then(function () {
                  //  console.log("asd="+wid+",  ada="+high);
                  if (wid != undefined && high != undefined) {
                    if (wid != 300 || high != 350) {
                      return "image must be width:300px x height:350px";
                    }
                  }
                });
              }
            });
        }
      }
    },
    "data.representative.name": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Name is required")
          .regex("^[a-zA-Z._ ]*$", "Must only contain alphabetic characters.");
      }
    },
    "data.representative.designation": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Designation is required")
          .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.");
      }
    },
    "data.representative.direct_phone": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).digit();
        // .required("Direct Phone is required")
        // .regex("^[0-9+-]*$", "Must be a digit.");
      }
    },
    "data.representative.mobile": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Mobile is required")
          .digit()
          .regex("01+[0-9+-]*$", "Must start with 01.")
          .minLength(11)
          .maxLength(15);
      }
    },
    "data.representative.email": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Email is required")
          .email()
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              axios
                .post("/representative-email-validation", { email: value })
                .then((respons) => {
                  if (respons.data.status) {
                    app.emailVarify = true;
                  } else {
                    app.emailVarify = false;
                  }
                });
              return Promise.delay(1000).then(function () {
                if (app.emailVarify) {
                  return "Mail already exists";
                } else {
                }
              });
            }
          });
      }
    },
    "data.representative.linkedin": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).url(
          "Enter a valid url. Ex: https://www.linkedin.com/in/example"
        );
      }
    },
    "images.rep_image": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (!this.data.images.rep_image) {
          return Validator.value(value)
            .required("Image is required")
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "image/jpeg" || type == "image/png") {
                  // console.log("value jpg");
                } else {
                  return "Image must be of type .jpg or .png";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 250000) {
                  return "Image must be smaller than 250kb";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var wid, high;
                var _URL = window.URL || window.webkitURL;
                $("#rep_image1").change(function (e) {
                  var file, img;
                  if ((file = this.files[0])) {
                    img = new Image();
                    img.onload = function () {
                      wid = this.width;
                      high = this.height;
                      // console.log(this.width + " " + this.height);
                    };
                    img.src = _URL.createObjectURL(file);
                  }
                });

                return Promise.delay(1000).then(function () {
                  //  console.log("asd"+wid+"ada"+high);
                  if (wid != undefined && high != undefined) {
                    if (wid != 300 || high != 350) {
                      return "image must be width:300px x height:350px";
                    }
                  }
                });
              }
            });
        }
      }
    },
    "images.rep_pdf": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        if (!this.data.images.rep_pdf) {
          return Validator.value(value)
            .required("CV is required")
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "application/pdf") {
                } else {
                  return "PDF format required";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 7168000) {
                  return "Pdf must be smaller than 7mb";
                }
              }
            });
        }
      }
    },
    "data.alternative.name": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Name is required");
      }
    },
    "data.alternative.designation": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Designation is required");
      }
    },
    "data.alternative.phone": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Mobile is required")
          .regex("01+[0-9+-]*$", "Must start with 01.")
          .minLength(11)
          .maxLength(11);
      }
    },
    "data.alternative.email": function (value = null) {
      if (this.showStep1 || this.showStep4 || this.showStep5) {
        return Validator.value(value).email().required("Email is required");
      }
    },
    // step 2=============
    // step 2=============
    "data.business_activity.applying_for": function (value = null) {
      let vm = this;
      if (this.showStep2 || this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Please Select One")
          .custom(function () {
            if (vm.company_life < 2 && value == "General") {
              return "Atleast 2 years of company age";
            }
          });
      }
    },

    // step 3=============
    // step 3=============
    "data.primary_activity.priority1": function (value = null) {
      if (this.showStep3 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Priority 1 is required");
      }
    },
    "data.primary_activity.priority2": function (value = null) {
      if (this.showStep3 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Priority 2 is required");
      }
    },
    "data.primary_activity.priority3": function (value = null) {
      if (this.showStep3 || this.showStep4 || this.showStep5) {
        return Validator.value(value).required("Priority 3 is required");
      }
    },

    // step 4===============
    // step 4===============
    "images.company_profile_pdf": function (value = null) {
      if (this.showStep4 || this.showStep5) {
        if (!this.data.images.company_profile_pdf) {
          return Validator.value(value)
            .required("Company Profile is required")
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "application/pdf") {
                } else {
                  return "PDF format required";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 7168000) {
                  return "Pdf must be smaller than 7mb";
                }
              }
            });
        } else {
          return Validator.value(value)
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "application/pdf") {
                } else {
                  return "PDF format required";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 7168000) {
                  return "Pdf must be smaller than 7mb";
                }
              }
            });
        }
      }
    },
    "images.work_order_first": function (value = null) {
      if (this.showStep4 || this.showStep5) {
        if (this.data.business_activity.applying_for == "General") {
          if (!this.data.images.work_order_first) {
            return Validator.value(value)
              .required("First work order is required")
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 2048000) {
                    return "Pdf must be smaller than 2mb";
                  }
                }
              });
          }
        } else {
          return Validator.value(value)
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "application/pdf") {
                } else {
                  return "PDF format required";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 2048000) {
                  return "Pdf must be smaller than 2mb";
                }
              }
            });
        }
      }
    },
    "images.work_order_second": function (value = null) {
      if (this.showStep4 || this.showStep5) {
        if (this.data.business_activity.applying_for == "General") {
          if (!this.data.images.work_order_second) {
            return Validator.value(value)
              .required("Second work order is required")
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 2048000) {
                    return "Pdf must be smaller than 2mb";
                  }
                }
              });
          } else {
            return Validator.value(value)
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 2048000) {
                    return "Pdf must be smaller than 2mb";
                  }
                }
              });
          }
        } else {
          return Validator.value(value)
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "application/pdf") {
                } else {
                  return "PDF format required";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 2048000) {
                  return "Pdf must be smaller than 2mb";
                }
              }
            });
        }
      }
    },
    "images.work_order_third": function (value = null) {
      if (this.showStep4 || this.showStep5) {
        if (this.data.business_activity.applying_for == "General") {
          if (!this.data.images.work_order_third) {
            return Validator.value(value)
              .required("Third work order is required")
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 2048000) {
                    return "Pdf must be smaller than 2mb";
                  }
                }
              });
          } else {
            return Validator.value(value)
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var type = value.type;
                  if (type == "application/pdf") {
                  } else {
                    return "PDF format required";
                  }
                }
              })
              .custom(function () {
                if (!Validator.isEmpty(value)) {
                  var size = value.size;
                  if (size > 2048000) {
                    return "Pdf must be smaller than 2mb";
                  }
                }
              });
          }
        } else {
          return Validator.value(value)
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "application/pdf") {
                } else {
                  return "PDF format required";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 2048000) {
                  return "Pdf must be smaller than 2mb";
                }
              }
            });
        }
      }
    },
    "images.representative_sign": function (value = null) {
      if (this.showStep4 || this.showStep5) {
        if (!this.data.images.representative_sign) {
          return Validator.value(value)
            .required("Representative signature is required")
            .required("Image is required")
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var type = value.type;
                if (type == "image/jpeg" || type == "image/png") {
                  // console.log("value jpg");
                } else {
                  return "Image must be of type .jpg or .png";
                }
              }
            })
            .custom(function () {
              if (!Validator.isEmpty(value)) {
                var size = value.size;
                if (size > 250000) {
                  return "Image must be smaller than 250kb";
                }
              }
            });
        }
      }
    },
    // "data.files_others.signature_person_name": function(value = null) {
    //   if (this.showStep4 || this.showStep5) {
    //     return Validator.value(value)
    //       .required("Signature Person Name is required")
    //       .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.");
    //   }
    // },
    // "data.files_others.signature_person_designation": function(value = null) {
    //   if (this.showStep4 || this.showStep5) {
    //     return Validator.value(value)
    //       .required("Signature Person Designation is required")
    //       .regex("^[a-zA-Z_ ]*$", "Must only contain alphabetic characters.");
    //   }
    // },
    "references.first_reference_status": function (value = null) {
      if (this.showStep4 || this.showStep5) {
        return Validator.value(value).required("1st Reference is required");
      }
    },
    "references.second_reference_status": function (value = null) {
      if (this.showStep4 || this.showStep5) {
        return Validator.value(value).required("2nd Reference is required");
      }
    },
    "data.payment_method": function (value = null) {
      if (this.showStep5) {
        return Validator.value(value).required("Payment Method is required");
      }
    },
    "data.agree": function (value = null) {
      if (this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Please check this box if you want to proceed.")
          .custom(function () {
            if (value == false) {
              return "Please check this box if you want to proceed.";
            }
          });
      }
    },
    "data.agree2": function (value = null) {
      if (this.showStep4 || this.showStep5) {
        return Validator.value(value)
          .required("Please check this box if you want to proceed.")
          .custom(function () {
            if (value == false) {
              return "Please check this box if you want to proceed.";
            }
          });
      }
    },
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
};
</script>
<style>
.mx-datepicker {
  width: 403px;
}
.member-list .dropdown:hover::before {
  text-shadow: 0px -1px #fff !important;
}
</style>
