<template>
  <div class="member-services" v-if="!$root.spinner">
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="px-3 mb-0">My Services</h5>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead class="bg-success text-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Application Date</th>
              <th scope="col">Service Name</th>
              <th scope="col" class="text-center">Payment Status</th>
              <th scope="col" class="text-center">Service Status</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <slot v-if="table.datas && Object.keys(table.datas).length > 0">
              <tr v-for="(serv, index) in table.datas" :key="index">
                <th>{{ index + 1 }}</th>
                <td>{{ serv.created_at | formatDate("MMMM, DD YYYY") }}</td>
                <td>
                  <div v-if="serv.service">
                    <slot v-if="serv.type == 'training'">
                      <span v-if="serv.training">{{
                        serv.training.title
                      }}</span>
                    </slot>
                    <slot v-else-if="serv.type == 'event'">
                      <span v-if="serv.event">{{ serv.event.title }}</span>
                    </slot>
                    <slot v-else>
                      <span v-if="serv.service">
                        {{ serv.service.title }}
                        <br />
                        <small v-if="serv.service.id == 31"
                          >( {{ serv.card.title }} )</small
                        >
                      </span>
                    </slot>
                  </div>
                </td>
                <td class="text-center">
                  <slot v-if="serv.invoice">
                    <span
                      v-if="serv.invoice.status == 'pending'"
                      class="badge badge-warning"
                      >DUE</span
                    >
                    <span v-else class="badge badge-success">PAID</span>
                  </slot>
                </td>
                <td class="text-center">
                  <span v-if="serv.status == 'a'" class="badge badge-success"
                    >APPROVED</span
                  >
                  <slot v-else>
                    <span v-if="serv.comments">
                      {{ serv.comments }}
                    </span>
                    <span
                      class="badge badge-warning"
                      v-else-if="serv.status == 'p'"
                    >
                      PENDING
                    </span>
                    <span
                      class="badge badge-danger"
                      v-else-if="serv.status == 'd'"
                    >
                      DEACTIVE
                    </span>
                  </slot>
                </td>
                <td>{{ serv.amount | currency }}</td>
              </tr>
            </slot>
            <slot v-else>
              <tr v-if="!$root.spinner">
                <td colspan="6" class="text-center">No services yet</td>
              </tr>
            </slot>
          </tbody>
        </table>
        <!-- pagination -->
        <Pagination :url="'/member/allExistingService'" />
        <!-- /pagination -->
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "../../../../components/Pagination";
const breadcumb = [
  {
    title: "My Services",
  },
];
export default {
  name: "MyService",
  components: { Pagination },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "My Services",
      member: {
        info: {},
      },
      table: { datas: [] },
      meta: [],
      links: [],
      services: {},
      errors: {},
    };
  },
  validators: {},
  methods: {
    getService: function (pageindex) {
      this.$root.spinner = true;
      let url = "/member/allExistingService";
      if (pageindex > 0) {
        url = "/member/allExistingService?page=" + pageindex;
      }
      axios
        .get(url)
        .then((response) => {
          this.table.datas = response.data.data;
          this.meta = response.data.meta;
          this.links = response.data.links;
        })
        .catch((error) => {})
        .then((always) => {
          this.$root.spinner = false;
        });
    },
  },
  mounted() {
    this.loggedIn();
    this.getService();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
};
</script>

