var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"example-content"}},[(!_vm.$root.spinner)?_vm._t("default",function(){return [(_vm.table.datas && Object.keys(_vm.table.datas).length > 0)?_vm._t("default",function(){return [(_vm.table.datas)?_c('div',{staticClass:"row"},_vm._l((_vm.table.datas),function(data,index){return _c('div',{key:index,staticClass:"col-lg-4 mb-3"},[_c('div',{staticClass:"blog-card card shadow-sm"},[_c('div',{staticClass:"card-header bg-transparent border-0"},[_c('span',{staticClass:"shape"}),_vm._v(" "),_c('div',{staticClass:"event-logo"},[_c('img',{attrs:{"src":_vm.basislogo,"alt":"basis logo"}})]),_vm._v(" "),_c('div',{staticClass:"event-title"},[_c('h3',[_vm._v(_vm._s(data.training_name))])])]),_vm._v(" "),_c('div',{staticClass:"card-body py-4"},[_c('p',{staticClass:"m-0"},[_vm._m(0,true),_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("formatDate")(data.training_last_date,"Do MMM YYYY"))+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),_c('p',{staticClass:"m-0"},[_vm._m(1,true),_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(data.training_time)+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),_c('p',{staticClass:"m-0"},[_vm._m(2,true),_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(data.training_period)+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),_c('p',{staticClass:"m-0"},[_vm._m(3,true),_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(data.training_venue)+"\n\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"card-footer bg-transparent border-0 py-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('big',{staticClass:"text-danger font-weight-bold"},[_vm._v("Tk "+_vm._s(_vm._f("currency")(data.training_fee_amount)))])],1),_vm._v(" "),_c('div',{staticClass:"col-6 text-right"},[_c('router-link',{attrs:{"to":{
											name: 'TrainingSeminarDetails',
											params: { id: data.id, type: 'training' },
										}}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\tDetails\n\t\t\t\t\t\t\t\t\t\t"),_c('i',{staticClass:"mdi mdi-arrow-right"})])],1)])])])])}),0):_vm._e(),_vm._v(" "),(!_vm.$root.spinner)?_c('Pagination',{attrs:{"url":'/get-training-seminar/training/' + _vm.$route.params.type}}):_vm._e()]}):_c('div',{staticClass:"text-center"},[_vm._m(4)])]}):_vm._e(),_vm._v(" "),(_vm.$root.spinner)?_c('spinner'):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('i',{staticClass:"mdi mdi-calendar-check"}),_vm._v(" Apply last date:\n\t\t\t\t\t\t\t\t")])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('i',{staticClass:"mdi mdi-clock-outline"}),_vm._v(" Total Hours:\n\t\t\t\t\t\t\t\t")])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('i',{staticClass:"mdi mdi-clock-outline"}),_vm._v(" Training Period:\n\t\t\t\t\t\t\t\t")])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('i',{staticClass:"mdi mdi-map-marker"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('b',[_vm._v("No training at this time")])])
}]

export { render, staticRenderFns }