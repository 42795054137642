<template>
  <div
    v-if="!$parent.loading && member.info && member.info.status == 1"
    class="row"
  >
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            This page is temporarily closed
          </h4>
        </div>
      </div>
    </div>
    <div v-if="!$root.variable.alt_login" class="col-lg-12 d-none">
      <div class="card shadow-sm">
        <div class="card-header bg-transparent">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">
            Join ON Committee / Forum
          </h5>
        </div>
        <div class="card-body">
          <form v-on:submit.prevent="submit" id="form" class="form-row">
            <div class="form-group col-lg-12 text-center">
              <h5>
                Date:
                {{ new Date().toLocaleString() | formatDate("Do MMM YYYY") }}
              </h5>
            </div>
            <!-- collapse -->
            <div class="col-lg-12 mb-3">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card card-body shadow-sm mb-3">
                    <div
                      class="custom-control custom-checkbox custom-control-inline"
                    >
                      <input
                        class="custom-control-input"
                        type="checkbox"
                        value="1"
                        data-toggle="collapse"
                        id="collapseExample1"
                        name="standing_committee_change"
                        v-model="data.standing_committee_change"
                        data-target="#collapsediv1"
                      />
                      <label class="custom-control-label" for="collapseExample1"
                        >Standing Committee</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card card-body shadow-sm mb-3">
                    <div
                      class="custom-control custom-checkbox custom-control-inline"
                    >
                      <input
                        class="custom-control-input"
                        type="checkbox"
                        value="1"
                        data-toggle="collapse"
                        id="collapseExample2"
                        name="forum_change"
                        v-model="data.forum_change"
                        data-target="#collapsediv2"
                      />
                      <label class="custom-control-label" for="collapseExample2"
                        >Forum</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card card-body shadow-sm mb-3">
                    <div
                      class="custom-control custom-checkbox custom-control-inline"
                    >
                      <input
                        class="custom-control-input"
                        type="checkbox"
                        value="1"
                        data-toggle="collapse"
                        id="collapseExample3"
                        name="focus_group_change"
                        v-model="data.focus_group_change"
                        data-target="#collapsediv3"
                      />
                      <label class="custom-control-label" for="collapseExample3"
                        >Focus Group</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /collapse -->
            <div class="col-lg-12 mb-3">
              <!-- Standing Committee -->
              <div id="collapsediv1" class="collapse div1 mb-3">
                <fieldset class="border border-primary p-3">
                  <legend
                    class="text-uppercase font-weight-bold text-center w-50"
                  >
                    Standing Committee
                  </legend>
                  <div action="#" class="form-row">
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Company Name:</label>
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        readonly
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.standing_committee.company_name'
                          ),
                          'is-valid': data.standing_committee.company_name,
                        }"
                        placeholder="e.g. Jane Doe"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. Jane Doe'"
                        v-model="data.standing_committee.company_name"
                        name="standing_committee[company_name]"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Membership No:</label>
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        readonly
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.standing_committee.membership_no'
                          ),
                          'is-valid': data.standing_committee.membership_no,
                        }"
                        placeholder="e.g. A000"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. A000'"
                        v-model="data.standing_committee.membership_no"
                        name="standing_committee[membership_no]"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0"
                        >Representative name:</label
                      >
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        readonly
                        v-model="data.standing_committee.representative_name"
                        name="standing_committee[representative_name]"
                        placeholder="e.g. Jane Doe"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. Jane Doe'"
                        class="form-control shadow-none"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0"
                        >Representative Designation:</label
                      >
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        readonly
                        v-model="
                          data.standing_committee.representative_designation
                        "
                        name="standing_committee[representative_designation]"
                        placeholder="e.g. CEO"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. CEO'"
                        class="form-control shadow-none"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0"
                        >Representative Mobile:</label
                      >
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        readonly
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.standing_committee.representative_mobile'
                          ),
                          'is-valid':
                            data.standing_committee.representative_mobile,
                        }"
                        placeholder="e.g. 01xxxxxxxxx"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
                        v-model.number="
                          data.standing_committee.representative_mobile
                        "
                        id="mobile"
                        name="standing_committee[representative_mobile]"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0"
                        >Representative E-mail:</label
                      >
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="email"
                        readonly
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.standing_committee.representative_email'
                          ),
                          'is-valid':
                            data.standing_committee.representative_email,
                        }"
                        placeholder="e.g. demo@mail.com"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. demo@mail.com'"
                        v-model="data.standing_committee.representative_email"
                        name="standing_committee[representative_email]"
                      />
                    </div>
                    <div class="form-group col-lg-12">
                      <label class="control-label p-0">Address:</label>
                      <span class="text-danger requierd-star"></span>
                      <textarea
                        class="form-control shadow-none"
                        readonly
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.standing_committee.representative_address'
                          ),
                          'is-valid':
                            data.standing_committee.representative_address,
                        }"
                        placeholder="e.g. demo@mail.com"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. demo@mail.com'"
                        v-model="data.standing_committee.representative_address"
                        name="standing_committee[representative_address]"
                      ></textarea>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">First Priority:</label>
                      <span class="text-danger requierd-star"></span>
                      <select
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.standing_committee.first_priority'
                          ),
                          'is-valid': data.standing_committee.first_priority,
                        }"
                        v-model="data.standing_committee.first_priority"
                        name="standing_committee[first_priority]"
                        class="form-control"
                      >
                        <option value>Select one</option>
                        <option
                          v-for="(comt, index) in category.committees"
                          :key="index"
                          :value="comt.id"
                        >
                          {{ comt.name }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Second Priority:</label>
                      <span class="text-danger requierd-star"></span>
                      <select
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.standing_committee.second_priority'
                          ),
                          'is-valid': data.standing_committee.second_priority,
                        }"
                        v-model="data.standing_committee.second_priority"
                        name="standing_committee[second_priority]"
                        class="form-control"
                      >
                        <option value>Select one</option>
                        <option
                          v-for="(comt, index) in category.committees"
                          :key="index"
                          :value="comt.id"
                        >
                          {{ comt.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </fieldset>
              </div>
              <!-- /Standing Committee -->
              <!-- Forum -->
              <div id="collapsediv2" class="collapse div2 mb-3">
                <fieldset class="border border-primary p-3">
                  <legend
                    class="text-uppercase font-weight-bold text-center w-25"
                  >
                    Forum
                  </legend>
                  <div action="#" class="form-row">
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Company Name:</label>
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        readonly
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.forum.company_name'
                          ),
                          'is-valid': data.forum.company_name,
                        }"
                        placeholder="e.g. Jane Doe"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. Jane Doe'"
                        v-model="data.forum.company_name"
                        name="forum[company_name]"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Membership No:</label>
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        readonly
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.forum.membership_no'
                          ),
                          'is-valid': data.forum.membership_no,
                        }"
                        placeholder="e.g. A000"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. A000'"
                        v-model="data.forum.membership_no"
                        name="forum[membership_no]"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Participant name:</label>
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        v-model="data.forum.participant_name"
                        name="forum[participant_name]"
                        placeholder="e.g. Jane Doe"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. Jane Doe'"
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.forum.participant_name'
                          ),
                          'is-valid': data.forum.participant_name,
                        }"
                      />
                      <div class="invalid-feedback">
                        {{
                          validation.firstError("data.forum.participant_name")
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0"
                        >Participant Designation:</label
                      >
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        v-model="data.forum.participant_designation"
                        name="forum[participant_designation]"
                        placeholder="e.g. CEO"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. CEO'"
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.forum.participant_designation'
                          ),
                          'is-valid': data.forum.participant_designation,
                        }"
                      />
                      <div class="invalid-feedback">
                        {{
                          validation.firstError(
                            "data.forum.participant_designation"
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0"
                        >Participant Mobile:</label
                      >
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.forum.participant_mobile'
                          ),
                          'is-valid': data.forum.participant_mobile,
                        }"
                        placeholder="e.g. 01xxxxxxxxx"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
                        v-model="data.forum.participant_mobile"
                        id="mobile"
                        name="forum[participant_mobile]"
                      />
                      <div class="invalid-feedback">
                        {{
                          validation.firstError("data.forum.participant_mobile")
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0"
                        >Participant E-mail:</label
                      >
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="email"
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.forum.participant_email'
                          ),
                          'is-valid': data.forum.participant_email,
                        }"
                        placeholder="e.g. demo@mail.com"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. demo@mail.com'"
                        v-model="data.forum.participant_email"
                        name="forum[participant_email]"
                      />
                      <div class="invalid-feedback">
                        {{
                          validation.firstError("data.forum.participant_email")
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-12">
                      <label class="control-label p-0">Address:</label>
                      <span class="text-danger requierd-star"></span>
                      <textarea
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.forum.participant_address'
                          ),
                          'is-valid': data.forum.participant_address,
                        }"
                        placeholder="e.g. demo@mail.com"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. demo@mail.com'"
                        v-model="data.forum.participant_address"
                        name="forum[participant_address]"
                      ></textarea>
                      <div class="invalid-feedback">
                        {{
                          validation.firstError(
                            "data.forum.participant_address"
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">First Priority:</label>
                      <span class="text-danger requierd-star"></span>
                      <select
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.forum.first_priority'
                          ),
                          'is-valid': data.forum.first_priority,
                        }"
                        v-model="data.forum.first_priority"
                        name="forum[first_priority]"
                        class="form-control"
                      >
                        <option value>Select one</option>
                        <option
                          v-for="(forum, index) in category.forums"
                          :key="index"
                          :value="forum.id"
                        >
                          {{ forum.name }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        {{ validation.firstError("data.forum.first_priority") }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Second Priority:</label>
                      <span class="text-danger requierd-star"></span>
                      <select
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.forum.second_priority'
                          ),
                          'is-valid': data.forum.second_priority,
                        }"
                        v-model="data.forum.second_priority"
                        name="forum[second_priority]"
                        class="form-control"
                      >
                        <option value>Select one</option>
                        <option
                          v-for="(forum, index) in category.forums"
                          :key="index"
                          :value="forum.id"
                        >
                          {{ forum.name }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        {{
                          validation.firstError("data.forum.second_priority")
                        }}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <!-- /Forum -->
              <!-- Focus Group -->
              <div id="collapsediv3" class="collapse div3 mb-3">
                <fieldset class="border border-primary p-3">
                  <legend
                    class="text-uppercase font-weight-bold text-center w-25"
                  >
                    Focus Group
                  </legend>
                  <div action="#" class="form-row">
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Company Name:</label>
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        readonly
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.focus_group.company_name'
                          ),
                          'is-valid': data.focus_group.company_name,
                        }"
                        placeholder="e.g. Jane Doe"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. Jane Doe'"
                        v-model="data.focus_group.company_name"
                        name="focus_group[company_name]"
                      />
                      <div class="invalid-feedback">
                        {{
                          validation.firstError("data.focus_group.company_name")
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Membership No:</label>
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        readonly
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.focus_group.membership_no'
                          ),
                          'is-valid': data.focus_group.membership_no,
                        }"
                        placeholder="e.g. A000"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. A000'"
                        v-model="data.focus_group.membership_no"
                        name="focus_group[membership_no]"
                      />
                      <div class="invalid-feedback">
                        {{
                          validation.firstError(
                            "data.focus_group.membership_no"
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Participant name:</label>
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        v-model="data.focus_group.participant_name"
                        name="focus_group[participant_name]"
                        placeholder="e.g. Jane Doe"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. Jane Doe'"
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.focus_group.participant_name'
                          ),
                          'is-valid': data.focus_group.participant_name,
                        }"
                      />
                      <div class="invalid-feedback">
                        {{
                          validation.firstError(
                            "data.focus_group.participant_name"
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0"
                        >Participant Designation:</label
                      >
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        v-model="data.focus_group.participant_designation"
                        name="focus_group[participant_designation]"
                        placeholder="e.g. CEO"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. CEO'"
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.focus_group.participant_designation'
                          ),
                          'is-valid': data.focus_group.participant_designation,
                        }"
                      />
                      <div class="invalid-feedback">
                        {{
                          validation.firstError(
                            "data.focus_group.participant_designation"
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0"
                        >Participant Mobile:</label
                      >
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="text"
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.focus_group.participant_mobile'
                          ),
                          'is-valid': data.focus_group.participant_mobile,
                        }"
                        placeholder="e.g. 01xxxxxxxxx"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
                        v-model="data.focus_group.participant_mobile"
                        id="mobile"
                        name="focus_group[participant_mobile]"
                      />
                      <div class="invalid-feedback">
                        {{
                          validation.firstError(
                            "data.focus_group.participant_mobile"
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0"
                        >Participant E-mail:</label
                      >
                      <span class="text-danger requierd-star"></span>
                      <input
                        type="email"
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.focus_group.participant_email'
                          ),
                          'is-valid': data.focus_group.participant_email,
                        }"
                        placeholder="e.g. demo@mail.com"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. demo@mail.com'"
                        v-model="data.focus_group.participant_email"
                        name="focus_group[participant_email]"
                      />
                      <div class="invalid-feedback">
                        {{
                          validation.firstError(
                            "data.focus_group.participant_email"
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-12">
                      <label class="control-label p-0">Address:</label>
                      <span class="text-danger requierd-star"></span>
                      <textarea
                        class="form-control shadow-none"
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.focus_group.participant_address'
                          ),
                          'is-valid': data.focus_group.participant_address,
                        }"
                        placeholder="e.g. demo@mail.com"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'e.g. demo@mail.com'"
                        v-model="data.focus_group.participant_address"
                        name="focus_group[participant_address]"
                      ></textarea>
                      <div class="invalid-feedback">
                        {{
                          validation.firstError(
                            "data.focus_group.participant_address"
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">First Priority:</label>
                      <span class="text-danger requierd-star"></span>
                      <select
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.focus_group.first_priority'
                          ),
                          'is-valid': data.focus_group.first_priority,
                        }"
                        v-model="data.focus_group.first_priority"
                        name="focus_group[first_priority]"
                        class="form-control"
                      >
                        <option value>Select one</option>
                        <option
                          v-for="(focus, index) in category.focus_groups"
                          :key="index"
                          :value="focus.id"
                        >
                          {{ focus.name }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        {{
                          validation.firstError(
                            "data.focus_group.first_priority"
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label class="control-label p-0">Second Priority:</label>
                      <span class="text-danger requierd-star"></span>
                      <select
                        :class="{
                          'is-invalid': validation.hasError(
                            'data.focus_group.second_priority'
                          ),
                          'is-valid': data.focus_group.second_priority,
                        }"
                        v-model="data.focus_group.second_priority"
                        name="focus_group[second_priority]"
                        class="form-control"
                      >
                        <option value>Select one</option>
                        <option
                          v-for="(focus, index) in category.focus_groups"
                          :key="index"
                          :value="focus.id"
                        >
                          {{ focus.name }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        {{
                          validation.firstError(
                            "data.focus_group.second_priority"
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <!-- /Focus Group -->
            </div>
            <div
              class="form-group col-lg-12 text-center"
              v-if="
                ($root.variable.role == 1 && data.standing_committee_change) ||
                data.forum_change ||
                data.focus_group_change
              "
            >
              <button name type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="member.info && member.info.status == 0" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! your account is suspend
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noImage from "../../../../assets/img/male_avatar.png";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);

export default {
  name: "JoinCommitteeForum",
  data() {
    return {
      title: "Join On Committee / Forum",
      noImage: this.$root.baseurl + noImage,
      image_src: null,
      sign_src: null,
      data: {
        standing_committee: {
          company_name: null,
          company_name: null,
          membership_no: null,
          representative_name: null,
          representative_designation: null,
          representative_email: null,
          representative_mobile: null,
          representative_address: null,
          first_priority: "",
          second_priority: "",
        },
        forum: {
          company_name: null,
          membership_no: null,
          participant_name: null,
          participant_designation: null,
          participant_email: null,
          participant_mobile: null,
          participant_address: null,
          first_priority: "",
          second_priority: "",
        },
        focus_group: {
          company_name: null,
          membership_no: null,
          participant_name: null,
          participant_designation: null,
          participant_email: null,
          participant_mobile: null,
          participant_address: null,
          first_priority: "",
          second_priority: "",
        },
      },
      member: {
        info: null,
      },
      category: {
        committees: {},
        forums: {},
        focus_groups: {},
      },
      errors: {},
    };
  },
  methods: {
    submit: function () {
      this.$validate().then((res) => {
        if (res) {
          this.$parent.loading = true;
          axios
            .post("/member/JoinCommitteeForum", this.data)
            .then((respons) => {
              this.$toastr("success", "Submit Successfull", "Success!!");
              location.reload();
            })
            .catch((error) => {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors || {};
                this.focus_field = Object.keys(this.errors)[0];
              }
            });
        }
      });
    },
  },
  created() {
    this.loggedIn();
    // primary
    axios
      .get("member/get-represent-info/primary")
      .then((response) => {
        this.data.standing_committee.representative_name = response.data.name;
        this.data.standing_committee.representative_designation =
          response.data.designation;
        this.data.standing_committee.representative_mobile =
          response.data.mobile;
        this.data.standing_committee.representative_email = response.data.email;
      })
      .catch((error) => console.log(error))
      .then((alw) => setTimeout(() => (this.$parent.loading = false), 1000));

    // focus
    axios.get("admin/get-commFormlist").then((response) => {
      this.category.committees = response.data.committees;
      this.category.forums = response.data.forums;
      this.category.focus_groups = response.data.focuses;
    });
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  updated() {
    if (this.member.info) {
      this.data.standing_committee.company_name = this.member.info.company_name;
      this.data.standing_committee.membership_no = this.member.info.membership_no;
      this.data.standing_committee.representative_address = this.member.info.address;

      this.data.forum.company_name = this.member.info.company_name;
      this.data.forum.membership_no = this.member.info.membership_no;
      this.data.forum.participant_address = this.member.info.address;

      this.data.focus_group.company_name = this.member.info.company_name;
      this.data.focus_group.membership_no = this.member.info.membership_no;
      this.data.focus_group.participant_address = this.member.info.address;
    }
  },
  validators: {
    "data.standing_committee.first_priority": function (value = null) {
      if (this.data.standing_committee_change) {
        return Validator.value(value).required("First Priority is required");
      }
    },
    "data.standing_committee.second_priority": function (value = null) {
      if (this.data.standing_committee_change) {
        return Validator.value(value).required("Second Priority is required");
      }
    },

    // Forum
    "data.forum.participant_name": function (value = null) {
      if (this.data.forum_change) {
        return Validator.value(value).required("Participant Name is required");
      }
    },
    "data.forum.participant_designation": function (value = null) {
      if (this.data.forum_change) {
        return Validator.value(value).required(
          "Participant Designation is required"
        );
      }
    },
    "data.forum.participant_email": function (value = null) {
      if (this.data.forum_change) {
        return Validator.value(value)
          .required("Participant Email is required")
          .email();
      }
    },
    "data.forum.participant_mobile": function (value = null) {
      if (this.data.forum_change) {
        return Validator.value(value)
          .required("Participant Mobile is required")
          .digit()
          .regex("01+[0-9+-]*$", "Must start with 01.")
          .minLength(11)
          .maxLength(11);
      }
    },
    "data.forum.participant_address": function (value = null) {
      if (this.data.forum_change) {
        return Validator.value(value).required(
          "Participant Address is required"
        );
      }
    },
    "data.forum.first_priority": function (value = null) {
      if (this.data.forum_change) {
        return Validator.value(value).required("First Priority is required");
      }
    },
    "data.forum.second_priority": function (value = null) {
      if (this.data.forum_change) {
        return Validator.value(value).required("Second Priority is required");
      }
    },

    // Focus
    "data.focus_group.participant_name": function (value = null) {
      if (this.data.focus_group_change) {
        return Validator.value(value).required("Participant Name is required");
      }
    },
    "data.focus_group.participant_designation": function (value = null) {
      if (this.data.focus_group_change) {
        return Validator.value(value).required(
          "Participant Designation is required"
        );
      }
    },
    "data.focus_group.participant_email": function (value = null) {
      if (this.data.focus_group_change) {
        return Validator.value(value)
          .required("Participant Email is required")
          .email();
      }
    },
    "data.focus_group.participant_mobile": function (value = null) {
      if (this.data.focus_group_change) {
        return Validator.value(value)
          .required("Participant Mobile is required")
          .digit()
          .regex("01+[0-9]*$", "Must start with 01.")
          .minLength(11)
          .maxLength(11);
      }
    },
    "data.focus_group.participant_address": function (value = null) {
      if (this.data.focus_group_change) {
        return Validator.value(value).required(
          "Participant Address is required"
        );
      }
    },
    "data.focus_group.first_priority": function (value = null) {
      if (this.data.focus_group_change) {
        return Validator.value(value).required("First prioriry is required");
      }
    },
    "data.focus_group.second_priority": function (value = null) {
      if (this.data.focus_group_change) {
        return Validator.value(value).required("Second Priority is required");
      }
    },
  },
};
</script>
