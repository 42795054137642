<template>
  <div class="servicePage">
    <PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
    <div class="content-body py-4">
      <div class="container-wrapper">
        <div class="row">
          <div class="col-lg-3">
            <affix class="sidebar-menu" relative-element-selector="#example-content">
              <leftmenu />
            </affix>
          </div>
          <div class="col-lg-9">
            <div id="example-content">
              <!-- <div class="rental-body card card-body shadow-sm">
                <h1>Rental Facilities at BASIS Premises</h1>
                <p>
                  BASIS Provides the facilities to use (on rent) its Board room, Auditorium and Lounge.
                  Any BASIS member or any other organization can avail this facility.
                </p>
                <div class="row py-4">
                  <div class="col-lg-4">
                    <img class="card-img-top" height="150" v-lazy="data.img1" :alt="data.img1" />
                  </div>
                  <div class="col-lg-8">
                    <strong>BASIS Conference Hall Facilities</strong>
                    <ul class="pl-4">
                      <li>Air-conditioned room</li>
                      <li>Board table with Chairs</li>
                      <li>Sound system</li>
                      <li>Wiﬁ connection</li>
                      <li>Projector</li>
                    </ul>
                  </div>
                </div>
                <div class="row py-4">
                  <div class="col-lg-4">
                    <img class="card-img-top" height="150" v-lazy="data.img2" :alt="data.img2" />
                  </div>
                  <div class="col-lg-8">
                    <strong>BASIS Auditorium Room Facilities</strong>
                    <ul class="pl-4">
                      <li>Air-conditioned Hall</li>
                      <li>Sound system</li>
                      <li>Wiﬁ connection</li>
                      <li>Projector & screen</li>
                      <li>Sitting arrangement</li>
                    </ul>
                  </div>
                </div>
                <div class="row py-4">
                  <div class="col-lg-4">
                    <img class="card-img-top" height="150" v-lazy="data.img3" :alt="data.img3" />
                  </div>
                  <div class="col-lg-8">
                    <strong>BASIS Lounge</strong>
                    <ul class="pl-4">
                      <li>Air-conditioned Hall</li>
                      <li>Sound system</li>
                      <li>Wiﬁ connection</li>
                      <li>Sitting arrangement</li>
                    </ul>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <strong>Price Table</strong>
                    <table class="table table-des table-sm table-bordered mb-3">
                      <thead class="thead-dark">
                        <tr>
                          <th>Room/Hall</th>
                          <th>Time Slot</th>
                          <th>BASIS Member</th>
                          <th>Non BASIS member</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Board Room</td>
                          <td>Full day</td>
                          <td>Tk. 15,000</td>
                          <td>Tk. 20,000</td>
                        </tr>
                        <tr>
                          <td>Board Room</td>
                          <td>Half day</td>
                          <td>Tk. 11,250</td>
                          <td>Tk. 15,000</td>
                        </tr>
                        <tr>
                          <td>BASIS Auditorium</td>
                          <td>Full day</td>
                          <td>Tk. 22,500</td>
                          <td>Tk. 30,000</td>
                        </tr>
                        <tr>
                          <td>BASIS Auditorium</td>
                          <td>Half day</td>
                          <td>Tk. 15,000</td>
                          <td>Tk. 20,000</td>
                        </tr>
                        <tr>
                          <td>BASIS Lounge</td>
                          <td>Full / Half Day</td>
                          <td>Tk. 7,500</td>
                          <td>Tk. 10,000</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-lg-12">
                    <strong>Room Size Table</strong>
                    <table class="table table-sm table-bordered mb-3">
                      <thead class="thead-dark">
                        <tr>
                          <th>Room/Hall</th>
                          <th>Area (Sq feet)</th>
                          <th>Celing height (feet)</th>
                          <th>Class room</th>
                          <th>Banquet</th>
                          <th>Roundtable</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Full Auditorium</td>
                          <td>738 (41’X18’)</td>
                          <td>8.5</td>
                          <td>100</td>
                          <td>50</td>
                          <td>60</td>
                        </tr>
                        <tr>
                          <td>BASIS Lounge</td>
                          <td>432 (27’X16’)</td>
                          <td>8.5</td>
                          <td>14</td>
                          <td>14</td>
                          <td>14</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-lg-12">
                    <p>For more details, please contact with :</p>
                    <small>
                      <big class="font-weight-bold text-success">Ms.Fatematuze Johora Asha,</big>
                      <br />Admin Officer,
                      <br />Cell: 01798323118
                      <br />email: operation@basis.org.bd
                    </small>
                  </div>
                  <div class="col-lg-12 py-5 text-center" v-if="$route.params.type == 'member'">
                    <button
                      class="btn btn-success"
                      @click="clickToCheckLogin('rental-facilities',$route.params.id)"
                    >Get It Now!</button>
                  </div>
                </div>
              </div>-->
              <p class="text-justify" v-html="service.description"></p>
              <slot v-if="service.is_form==1 && $route.params.type=='member'">
                <button
                  class="btn btn-success"
                  @click="clickToCheckLogin('rental-facilities',service.id)"
                >Get It Now!</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import img1 from "../../../assets/img/rental/conference-room.png";
import img2 from "../../../assets/img/rental/Auditorium.png";
import img3 from "../../../assets/img/rental/lounge.png";
import PageBanner from "../../../components/elements/PageBanner";
import leftmenu from "../../../components/elements/leftmenu";
import axios from "axios";
import { Affix } from "vue-affix";

export default {
  name: "RentService",
  components: {
    PageBanner,
    leftmenu,
    Affix
  },
  data() {
    return {
      breadcrumbTitle: "Rent Service",
      breadcumb: [{ title: "Rent Service" }],
      data: {
        img1: this.$root.baseurl + img1,
        img2: this.$root.baseurl + img2,
        img3: this.$root.baseurl + img3
      },
      service: {}
    };
  },
  mounted() {
    this.$root.loading = false;
    var id = this.$route.params.id;
    var type = this.$route.params.type;
    axios.get("/get-service-details/" + id + "/" + type).then(respons => {
      this.service = respons.data.result;
      this.$root.loading = false;
    });
  }
};
</script>
<style>
.rental-body h1 {
  font-size: 30px;
  font-weight: bold;
  color: var(--success);
}
.rental-body strong {
  color: var(--success);
}
.rental-body ul li {
  font-size: 14px;
  line-height: 20px;
}
.rental-body img {
  object-fit: cover;
}
.table-des td {
  text-align: center;
}
.table-des .thead-dark th {
  text-align: center;
}
</style>