<template>
	<div class="member-services" v-if="!$parent.loading">
		<div class="card shadow-sm mb-3">
			<div class="card-header px-0">
				<div class="d-flex align-items-center justify-content-between">
					<h5 class="text-uppercase font-weight-bold px-3 mb-0">
						Certificate Delivery Confirmation
					</h5>
				</div>
			</div>
			<div class="card-body">
				<p class="text-center">
					You will get the hardcopy certificate within 3~5 Days.
				</p>
				<p class="text-center">
					<b> For delivery charge inside Dhaka 40 Tk & outside Dhaka 70 Tk. </b>
				</p>
				<h6 class="text-center my-4 mb-5">
					<u v-if="payment_method"><b>Payment Method</b></u>
					<u v-else><b>Delivery Address</b></u>
				</h6>

				<div v-if="!payment_method" class="row col-12">
					<div class="form-group col-3">
						<label for="comments">
							District
							<span class="text-danger requierd-star"></span>
						</label>
						<select
							class="form-control"
							:class="{
								'is-invalid': validation.hasError('ecData.recipient_city'),
								'is-valid': ecData.recipient_city,
							}"
							v-model="ecData.recipient_city"
							required
						>
							<option selected :value="null">Choose City...</option>
							<option
								v-for="(city, index) in districts"
								:key="index"
								:value="city"
							>
								{{ city }}
							</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="comments">
							Thana
							<span class="text-danger requierd-star"></span>
						</label>
						<select
							class="form-control"
							:class="{
								'is-invalid': validation.hasError('ecData.recipient_thana'),
								'is-valid': ecData.recipient_thana,
							}"
							v-model="ecData.recipient_thana"
							required
						>
							<option selected :value="null">Choose Thana...</option>
							<option
								v-for="(thana, index) in thanas"
								:key="index"
								:value="thana.name"
							>
								{{ thana.name }}
							</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="comments">
							Postal Code
							<span class="text-danger requierd-star"></span>
						</label>

						<select
							class="form-control"
							:class="{
								'is-invalid': validation.hasError('ecData.recipient_zip'),
								'is-valid': ecData.recipient_zip,
							}"
							v-model="ecData.recipient_zip"
							required
						>
							<option selected :value="null">Choose Area...</option>
							<option
								v-for="(code, index) in postalcodes"
								:key="index"
								:value="code.name"
							>
								{{ code.name }}
							</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="comments">
							Area
							<span class="text-danger requierd-star"></span>
						</label>

						<select
							class="form-control"
							:class="{
								'is-invalid': validation.hasError('ecData.recipient_area'),
								'is-valid': ecData.recipient_area,
							}"
							v-model="ecData.recipient_area"
							required
						>
							<option selected :value="null">Choose Area...</option>
							<option
								v-for="(area, index) in areas"
								:key="index"
								:value="area.name"
							>
								{{ area.name }}
							</option>
						</select>
					</div>

					<div class="form-group col-6">
						<label for="comments">
							Address
							<span class="text-danger requierd-star"></span>
						</label>
						<textarea
							v-model="ecData.recipient_address"
							placeholder="Address"
							class="form-control"
						></textarea>
					</div>
					<div class="form-group col-3">
						<label for="comments">
							Receiver Name
							<span class="text-danger requierd-star"></span>
						</label>
						<input
							class="form-control"
							:class="{
								'is-invalid': validation.hasError('ecData.recipient_name'),
								'is-valid': ecData.recipient_name,
							}"
							v-model="ecData.recipient_name"
							placeholder="Receiver Name"
							required
						/>
					</div>
					<div class="form-group col-3">
						<label for="comments">
							Receiver Mobile
							<span class="text-danger requierd-star"></span>
						</label>
						<input
							class="form-control"
							:class="{
								'is-invalid': validation.hasError('ecData.recipient_mobile'),
								'is-valid': ecData.recipient_mobile,
							}"
							v-model="ecData.recipient_mobile"
							placeholder="Receiver Mobile"
							required
						/>
					</div>

					<div class="form-group col-12 mt-3 d-flex justify-content-center">
						<button
							v-if="courier.ecourier_count === 0"
							type="button"
							@click="paymentMethod()"
							class="btn btn-primary"
						>
							Next
						</button>
						<span v-else class="my-4 text-danger">Already Submitted</span>
					</div>
				</div>

				<!-- Payment Info -->
				<!-- Payment Info -->
				<div
					v-if="payment_method"
					class="row col-12 my-5 d-flex justify-content-center"
				>
					<h5 class="w-100 text-center mb-4" style="text-decoration: underline">
						Your payable amount: <b>{{ ecData.product_price }} tk</b>
					</h5>
					<!-- <div class="form-group col-4">
            <input type="radio" v-model="ecData.payment_method" value="CCRD" />
            Online Payment
          </div> -->
					<div class="w-100"></div>
					<div class="form-group col-4">
						<input type="radio" v-model="ecData.payment_method" value="COD" />
						Cash On Delivery
					</div>

					<div class="form-group col-12 mt-3 d-flex justify-content-center">
						<button @click="store()" type="button" class="btn btn-primary ml-4">
							<span v-if="ecData.payment_method == 'CCRD'">Pay Now</span>
							<span v-else>Confirm</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);

	export default {
		name: "CertificateEcourier",
		data() {
			return {
				member: {
					info: {},
				},
				courier: { ecourier_count: 0 },
				districts: [],
				thanas: [],
				postalcodes: [],
				areas: [],
				payment_method: false,
				ecData: {
					certificate_id: null,
					recipient_city: null,
					recipient_thana: null,
					recipient_zip: null,
					recipient_area: null,
					recipient_address: null,
					recipient_name: null,
					recipient_mobile: null,
					product_price: 40,
					payment_method: "COD",
					parcel_type: "DOC",
				},
			};
		},
		watch: {
			"ecData.recipient_city": function (name) {
				if (name) {
					if (name) {
						this.ecData.product_price = name == "Dhaka" ? 40 : 70;
					}
					let params = { url: "thana-list", field: "city", name: name };
					axios
						.get("/member/get-ecourier-location", { params: params })
						.then((res) => (this.thanas = res.data));
				}
			},
			"ecData.recipient_thana": function (name) {
				if (name) {
					let params = {
						url: "postcode-list",
						field1: "city",
						field2: "thana ",
						value1: this.ecData.recipient_city,
						value2: name,
					};
					axios
						.get("/member/get-ecourier-location", { params: params })
						.then((res) => {
							this.postalcodes = res.data;
						});
				}
			},
			"ecData.recipient_zip": function (name) {
				if (name) {
					let params = { url: "area-list", field: "postcode", name: name };
					axios
						.get("/member/get-ecourier-location", { params: params })
						.then((res) => (this.areas = res.data));
				}
			},
		},
		methods: {
			store() {
				this.$parent.loading = true;
				this.ecData.certificate_id = this.$route.params.id;
				axios
					.post("/member/ecourier-store", this.ecData)
					.then((res) => {
						this.$toastr("success", "Confirm Successfully", "Success!!");
						if (res.data.url) {
							window.location.href = res.data.url;
						} else {
							this.$router.push({ name: "certificateList" });
						}
					})
					.catch((error) => {})
					.finally((res) =>
						setTimeout(() => (this.$parent.loading = false), 300)
					);
			},
			paymentMethod() {
				this.$validate().then((res) => {
					if (res) {
						this.payment_method = true;
					} else {
						this.payment_method = false;
					}
				});
			},

			getMemberInfo() {
				this.$parent.loading = true;
				axios
					.get("/member/info")
					.then((res) => {
						let dist = this.districts[Number(res.data.district_id)];
						this.ecData.recipient_city = dist ? dist : null;
						this.ecData.recipient_thana = null;
						this.ecData.recipient_zip = res.data.postcode;
						this.ecData.recipient_area = null;
						this.ecData.recipient_address = res.data.address;
					})
					.finally((res) =>
						setTimeout(() => (this.$parent.loading = false), 300)
					);
			},
		},
		async created() {
			axios
				.get("member/get-certificate/" + this.$route.params.id)
				.then((res) => (this.courier = res.data));

			await this.getList("districts", "districts");
			this.getMemberInfo();
		},
		mounted() {
			this.loggedIn();
			setTimeout(() => (this.$parent.loading = false), 300);
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
		validators: {
			"ecData.recipient_city": function (value = null) {
				return Validator.value(value).required("District is required");
			},
			"ecData.recipient_thana": function (value = null) {
				return Validator.value(value).required("Thana is required");
			},
			"ecData.recipient_zip": function (value = null) {
				return Validator.value(value).required("Postal Code is required");
			},
			"ecData.recipient_area": function (value = null) {
				return Validator.value(value).required("Area is required");
			},
			"ecData.recipient_address": function (value = null) {
				return Validator.value(value).required("Address is required");
			},
			"ecData.recipient_name": function (value = null) {
				return Validator.value(value).required("Contact Name is required");
			},
			"ecData.recipient_mobile": function (value = null) {
				return Validator.value(value).required("Mobile is required");
			},
		},
	};
</script>

