<template>  
    <div  id="allpage" >
        <keep-alive>
            <router-view />
        </keep-alive>
    </div>
</template>

<script>
import Vue from "vue";
// import Topnav from "./components/Topnav";
// import Sidebar from "./components/Sidebar";
// import Footer from "./components/Footer";

import Debug from "./components/Debug";
import Loader from "./components/Loader";
export default {
  data: function() {
    return {
      txt: "Dashboard",
      breadcrumbTitle: "Dashboard",
      isActive: "active",
      debug_data: {}
    };
  },
  components: {
    Debug,
    Loader
  },

  methods: {
    toggleClass: function(event) {
      if (this.isActive) {
        this.isActive = "";
      } else {
        this.isActive = "active";
      }
    },
    username() {
      return this.$route.params.username;
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    }
  },
  mounted() {},
  beforeCreate() {
     this.$root.fullLoader = true;
   },
  created() {
    setTimeout(() => (this.$root.fullLoader = false), 1500);
  }
};
</script>