<template>
  <!--page-banner-->
  <div class="page-banner py-3">
    <div class="container-wrapper">
      <span class="shape"></span>
      <span class="shape2"></span>
      <span class="shape3"></span>
      <div class="row align-items-center">
        <div class="col-lg-6" v-if="msg">
          <h3 class="mb-0 text-capitalize">{{ msg }}</h3>
          <!-- <h3 class="mb-0 text-capitalize">{{ getPostBody(msg,35) }}</h3> -->
        </div>
        <div class="col-lg-6">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb justify-content-end bg-transparent mb-0">
              <li class="breadcrumb-item">
                <router-link class="breadcrumb-item" to="/">Home</router-link>
              </li>
              <li
                class="breadcrumb-item text-capitalize"
                v-for="(route, index) in data"
                :key="index"
              >
                <span
                  v-if="(index)==Object.keys(data).length-1 ||!route.route_name"
                >{{ getPostBody(route.title,15) }}</span>
                <router-link
                  :to="{ name:route.route_name, params: { type: route.params }}"
                  class="breadcrumb-item"
                  v-else-if="Object.keys(data).length-1 || !route.news_type"
                >{{ getPostBody(route.title,15) }}</router-link>
                <router-link
                  :to="{name:route.route_name}"
                  class="breadcrumb-item"
                  v-else
                >{{ getPostBody(route.title,15) }}</router-link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!--page-banner-->
</template>
<script>
export default {
  name: "Banner",
  props: {
    msg: String,
    data: {}
  }
};
</script>
