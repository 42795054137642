<template>
	<div class="servicePage">
		<PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
		<div class="content-body py-4">
			<div class="container-wrapper">
				<div class="row">
					<div class="col-lg-3">
						<affix
							class="sidebar-menu"
							relative-element-selector="#example-content"
						>
							<leftmenu />
						</affix>
					</div>
					<div class="col-lg-9" id="example-content">
						<div class="loan-facility">
							<div class="row">
								<div class="col-lg-4">
									<router-link
										:to="{
											name: 'loan-facility-details',
											params: { type: 'BRAC-BANK' },
										}"
									>
										<div
											class="
												card
												shadow-sm
												align-items-center
												justify-content-center
												mb-3
											"
										>
											<img
												class="img-card-top"
												v-lazy="data.img4"
												alt="BASIS-BRAC Bank Digonto Financing Scheme"
											/>
											<div class="card-body">
												<h3 class="mb-0">
													BASIS-BRAC Bank Digonto Financing Scheme
												</h3>
											</div>
										</div>
									</router-link>
								</div>
								<div class="col-lg-4">
									<router-link
										:to="{
											name: 'service_detail',
											params: { id: this.$route.params.id, type: 'member' },
										}"
									>
										<div
											class="
												card
												shadow-sm
												align-items-center
												justify-content-center
												mb-3
											"
										>
											<img
												class="img-card-top"
												v-lazy="data.img3"
												alt="Loan Facility from IDLC"
											/>
											<div class="card-body">
												<h3 class="mb-0">Loan Facility from IDLC</h3>
											</div>
										</div>
									</router-link>
								</div>
								<div class="col-lg-4">
									<router-link
										:to="{
											name: 'loan-facility-details',
											params: { type: 'IPDC' },
										}"
									>
										<div
											class="
												card
												shadow-sm
												align-items-center
												justify-content-center
												mb-3
											"
										>
											<img
												class="img-card-top"
												:src="data.img2"
												alt="Loan Facility from IPDC"
											/>
											<div class="card-body">
												<h3 class="mb-0">Loan Facility from IPDC</h3>
											</div>
										</div>
									</router-link>
								</div>
								<div class="col-lg-4">
									<router-link
										:to="{
											name: 'loan-facility-details',
											params: { type: 'SJIBL' },
										}"
									>
										<div
											class="
												card
												shadow-sm
												align-items-center
												justify-content-center
												mb-3
											"
										>
											<img
												class="img-card-top"
												v-lazy="data.img1"
												alt="Loan Facility from Shahjalal Bank Limited"
											/>
											<div class="card-body">
												<h3 class="mb-0">
													Loan Facility from Shahjalal Bank Limited
												</h3>
											</div>
										</div>
									</router-link>
								</div>
								<div class="col-lg-4">
									<router-link
										:to="{
											name: 'loan-facility-details',
											params: { type: 'PRIME-BANK' },
										}"
									>
										<div
											class="
												card
												shadow-sm
												align-items-center
												justify-content-center
												mb-3
											"
										>
											<img
												class="img-card-top"
												v-lazy="data.img5"
												alt="PRIME BANK - BASIS Allianee For ICT-MSME Financing Solution"
											/>
											<div class="card-body">
												<h3 class="mb-0">
													PRIME BANK - BASIS Allianee For ICT-MSME Financing
													Solution
												</h3>
											</div>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import img1 from "../../assets/img/loan_facility/Shahjalal.png";
	import img2 from "../../assets/img/loan_facility/IPDC.png";
	import img3 from "../../assets/img/loan_facility/IDLC.png";
	import img4 from "../../assets/img/loan_facility/brackbank_logo.png";
	import img5 from "../../assets/img/loan_facility/primebank_logo.png";
	import PageBanner from "../../components/elements/PageBanner";
	import leftmenu from "../../components/elements/leftmenu";
	import axios from "axios";
	import { Affix } from "vue-affix";
	const routesname = {
		view: "slider_content.show",
	};
	export default {
		name: "LoanFacility",
		components: {
			PageBanner,
			leftmenu,
			Affix,
		},
		data() {
			return {
				breadcrumbTitle: "Service",
				breadcumb: [{ title: "Service" }],
				fields_name: { 0: "Select One", description: "Description" },
				search_data: {
					field_name: 0,
					value: "",
				},
				data: {
					img1: this.$root.baseurl + img1,
					img2: this.$root.baseurl + img2,
					img3: this.$root.baseurl + img3,
					img4: this.$root.baseurl + img4,
					img5: this.$root.baseurl + img5,
				},
			};
		},
		methods: {
			asyncData() {
				this.get_datas();
			},
			get_datas: function (pageindex) {
				this.$root.loading = true;
				let url = "/service/" + this.$route.params.type;
				axios
					.get(url)
					.then((respons) => {})
					.catch((error) => {
						console.log(error);
					})
					.then((res) => {
						setTimeout(
							() => (this.$root.loading = false),
							this.$root.minloadtimefd
						);
					});
			},
		},
		mounted() {},
		watch: {
			$route: {
				handler: "asyncData",
				immediate: true,
			},
		},
	};
</script>
