<template>
  <div class="feedback">
    <div class="col-md-5 m-auto">
      <div class="feedback-card">
        <form @submit.prevent="feedbackSubmit">
          <h3 class="title">Send us your feedback</h3>
          <div class="incentive-icon text-center">
            <img
              v-if="$parent.feedback.type == 'service'"
              :src="$root.baseurl + '/public/images/icon/incentive.png'"
              alt=""
            />
            <img
              v-else
              :src="$root.baseurl + '/public/images/icon/help-desk.png'"
              alt=""
            />
          </div>
          <h5 class="text">
            <slot v-if="$parent.feedback.type == 'service'">
              How satisfied were you with your last
              <span v-if="$parent.feedback.service">
                "{{ $parent.feedback.service.title }}"
              </span>
              services?
            </slot>
            <slot v-else>
              How happy are you with our online help-desk service? Please rate
              based on your last ticketing service
            </slot>
          </h5>
          <div class="rating">
            <ul>
              <li>
                <input
                  v-model="feedbacks.feedback"
                  value="poor"
                  type="radio"
                  id="poor"
                  class="d-none"
                />
                <label for="poor">
                  <i
                    class="fa fa-star"
                    :style="feedbacks.feedback === 'poor' ? active : ''"
                  ></i>
                  <br />
                  <span>Poor</span>
                </label>
              </li>
              <li>
                <input
                  v-model="feedbacks.feedback"
                  value="below average"
                  type="radio"
                  id="Below_average"
                  class="d-none"
                />
                <label for="Below_average">
                  <i
                    class="fa fa-star"
                    :style="
                      feedbacks.feedback === 'below average' ? active : ''
                    "
                  ></i
                  ><br />
                  <span>Below average</span>
                </label>
              </li>
              <li>
                <input
                  v-model="feedbacks.feedback"
                  value="average"
                  type="radio"
                  id="average"
                  class="d-none"
                />
                <label for="average">
                  <i
                    class="fa fa-star"
                    :style="feedbacks.feedback === 'average' ? active : ''"
                  ></i
                  ><br />
                  <span>Average</span>
                </label>
              </li>
              <li>
                <input
                  v-model="feedbacks.feedback"
                  value="good"
                  type="radio"
                  id="good"
                  class="d-none"
                />
                <label for="good">
                  <i
                    class="fa fa-star"
                    :style="feedbacks.feedback === 'good' ? active : ''"
                  ></i
                  ><br />
                  <span>Good</span>
                </label>
              </li>
              <li>
                <input
                  v-model="feedbacks.feedback"
                  value="excellent"
                  type="radio"
                  id="excellent"
                  class="d-none"
                />
                <label for="excellent">
                  <i
                    class="fa fa-star"
                    :style="feedbacks.feedback === 'excellent' ? active : ''"
                  ></i
                  ><br />
                  <span>Excellent</span>
                </label>
              </li>
            </ul>
          </div>
          <div class="comment">
            <h5 class="text">
              <slot v-if="$parent.feedback.type == 'service'">
                Please leave your comments on how we improve our services
              </slot>
              <slot v-else>
                Please leave your comments on how we improve our online customer
                services
              </slot>
            </h5>
            <textarea
              v-model="feedbacks.comments"
              class="form-control"
              rows="4"
            ></textarea>
          </div>

          <div class="footer-button d-flex justify-content-between">
            <button
              type="submit"
              :disabled="spinner ? true : false"
              class="btn btn-success"
            >
              <span v-if="spinner">
                <i class="fa fa-spinner fa-spin"></i> processing...
              </span>
              <span v-else>Submit</span>
            </button>

            <a href="#" @click="feedbackSkip()">
              <span v-if="spinner1">
                <i class="fa fa-spinner fa-spin"></i> processing...
              </span>
              <span v-else>Not Now</span>
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feedback",
  data() {
    return {
      spinner: false,
      spinner1: false,
      feedbacks: {
        feedback: "",
        id: "",
      },
      active: "font-weight: bold",
    };
  },
  methods: {
    feedbackSubmit: function () {
      if (!this.feedbacks.feedback) {
        this.$toastr("warning", "Please select any rating star", "Warning!!");
        return false;
      }
      this.spinner = true;
      this.feedbacks.id = this.$parent.feedback ? this.$parent.feedback.id : "";
      axios.post("/member/store-feedback", this.feedbacks).then((res) => {
        this.$parent.display = false;
        this.$toastr("success", "Send Successfully", "Success!!");
        this.spinner = false;
      });
    },
    feedbackSkip: function () {
      this.spinner1 = false;
      let id = this.$parent.feedback ? this.$parent.feedback.id : "";
      let data = { id: id };
      axios.get("/member/skip-feedback", { params: data }).then((res) => {
        this.$parent.display = false;
        this.$toastr("success", "Skip Successfully", "Success!!");
        this.spinner1 = false;
      });
    },
  },
};
</script>

<style scoped>
</style>
