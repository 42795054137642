<template>
  <div class="memberLogin py-5">
    <div class="container-wrapper">
      <div class="row justify-content-md-center">
        <div class="col-lg-8 p-0">
          <div class="card login-body shadow-lg rounded-10">
            <div class="card-body p-0">
              <span class="shape"></span>
              <div class="row">
                <div class="col-lg-4">
                  <div class="loginSidetext h-100">
                    <div class="body">
                      <img :src="basislogo" class="basisLogo" alt />
                    </div>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="loginForm py-5">
                    <div
                      class="titleText text-center position-relative"
                      v-if="!reset_password_request"
                    >
                      <i class="mdi mdi-account-circle-outline mdi-48px"></i>
                      <h3>Login</h3>
                      <h4 class="mb-4">
                        <big>Become a Member</big>
                      </h4>
                    </div>
                    <div
                      class="titleText text-center position-relative"
                      v-if="reset_password_request"
                    >
                      <i class="mdi mdi-account-circle-outline mdi-48px"></i>
                      <h3>Reset</h3>
                      <h4 class="mb-4">
                        <big>Password</big>
                      </h4>
                    </div>

                    <!--=============== Login Form ===============-->
                    <div :class="overlay">
                      <div
                        v-if="otpmethodsection"
                        class="row w-100 justify-content-center align-items-center"
                      >
                        <div class="col-lg-10">
                          <fieldset class="border border-dark rounded p-3">
                            <table class="table table-borderless">
                              <tr>
                                <td
                                  class="text-center"
                                >Please select your prefered method for verification code.</td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="form-group col-12">
                                    <div class="d-flex align-items-center justify-content-center">
                                      <span class="pr-2">OTP Type</span>
                                      <div
                                        class="custom-control custom-radio custom-control-inline"
                                      >
                                        <input
                                          type="radio"
                                          id="sms"
                                          name="sms"
                                          value="sms"
                                          class="custom-control-input"
                                          v-model="otp_method"
                                        />
                                        <label class="custom-control-label" for="sms">SMS</label>
                                      </div>
                                      <div
                                        class="custom-control custom-radio custom-control-inline"
                                      >
                                        <input
                                          type="radio"
                                          id="email"
                                          name="email"
                                          value="email"
                                          class="custom-control-input"
                                          v-model="otp_method"
                                        />
                                        <label class="custom-control-label" for="email">Email</label>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  <button
                                    class="btn btn-sm btn-success mt-3"
                                    type="submit"
                                    @click="otp_method_select()"
                                  >
                                    <span v-if="spinner">
                                      <i class="fa fa-spinner fa-spin"></i> processing...
                                    </span>
                                    <span v-else>Submit</span>
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </fieldset>
                        </div>
                      </div>
                      <div
                        v-if="otpsection"
                        class="row w-100 justify-content-center align-items-center"
                      >
                        <div class="col-lg-10">
                          <fieldset class="border rounded p-3">
                            <table class="table table-borderless">
                              <tr>
                                <td colspan="3">
                                  <img v-lazy="basislogo" class="basis-logo" alt="basis logo" />
                                </td>
                              </tr>
                              <tr>
                                <th width="30%">Company Name</th>
                                <td width="2%">:</td>
                                <td>{{member.company_name}}</td>
                              </tr>
                              <tr>
                                <th width="30%">Email Address</th>
                                <td width="2%">:</td>
                                <td>{{member.email}}</td>
                              </tr>
                              <tr>
                                <th width="30%">Mobile No</th>
                                <td width="2%">:</td>
                                <td>{{member.mobile}}</td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <small>
                                    Please check your
                                    <slot v-if="otp_method=='sms'">mobile</slot>
                                    <slot v-else>email</slot>
                                  </small>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <div class="row align-items-center m-0">
                                    <div class="col p-0">
                                      <input
                                        v-model="member.otp"
                                        type="text"
                                        class="form-control form-control-sm"
                                        placeholder="OTP"
                                        name="otp"
                                        v-on:keyup.enter="verify_otp"
                                      />
                                    </div>
                                    <div class="col p-0">
                                      <button
                                        type="submit"
                                        id="verify-otp"
                                        class="btn btn-sm btn-success text-white"
                                        @click="verify_otp"
                                      >Verify OTP</button>
                                    </div>
                                    <div class="col p-0">
                                      <button
                                        class="btn btn-sm btn-primary text-white"
                                        @click="otp_method_select()"
                                      >
                                        <span v-if="spinner">
                                          <i class="fa fa-spinner fa-spin"></i> processing...
                                        </span>
                                        <span v-else>Resend</span>
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </fieldset>
                        </div>
                      </div>
                      <div
                        v-if="reset_password"
                        class="row w-100 justify-content-center align-items-center"
                      >
                        <div class="col-lg-10" v-if="!$root.spinner">
                          <fieldset class="border border-dark rounded p-3">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label
                                    class="col-form-label-sm font-weight-bold mb-0"
                                    for="applicant_id"
                                  >New Password</label>
                                  <input
                                    v-model="member.new_password"
                                    type="password"
                                    class="form-control form-control-sm"
                                    :class="{ 'is-invalid': validation.hasError('member.new_password'),'is-valid': data.new_password }"
                                    placeholder="e.g. ie: A-Z, a-z,digit(0-9),special character"
                                    onfocus="this.placeholder = ''"
                                    onblur="this.placeholder = 'e.g. ie: A-Z, a-z,digit(0-9),special character'"
                                    name="otp3"
                                    required
                                  />
                                  <div
                                    class="invalid-feedback"
                                  >{{ validation.firstError('member.new_password') }}</div>
                                </div>
                                <div class="w-100"></div>
                                <div class="form-group">
                                  <label
                                    class="col-form-label-sm font-weight-bold mb-0"
                                  >Confirm Password</label>
                                  <input
                                    v-model="member.confirm_password"
                                    type="password"
                                    class="form-control form-control-sm"
                                    :class="{ 'is-invalid': validation.hasError('member.confirm_password'),'is-valid':data.confirm_password }"
                                    placeholder="e.g. ie: A-Z, a-z,digit(0-9),special character"
                                    onfocus="this.placeholder = ''"
                                    onblur="this.placeholder = 'e.g. ie: A-Z, a-z,digit(0-9),special character'"
                                    name="otp2"
                                    required
                                  />
                                  <div
                                    class="invalid-feedback"
                                  >{{ validation.firstError('member.confirm_password') }}</div>
                                </div>
                                <div class="w-100"></div>
                                <div class="col-md-12 form-group">
                                  <button
                                    class="btn btn-sm btn-success mt-3 float-right"
                                    type="submit"
                                    @click="resetPassword"
                                  >Submit</button>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <spinner v-if="$root.spinner" />
                      </div>
                    </div>

                    <!--=============== Login Form ===============-->
                    <form autocomplete="off" v-on:submit.prevent="submit" class="form-row px-3">
                      <div class="col-12" v-if="!$root.spinner">
                        <div class="form-group col-12">
                          <label
                            class="col-form-label-sm font-weight-bold mb-0"
                            for="applicant_id"
                          >Email</label>
                          <div class="loginBox">
                            <input
                              type="text"
                              v-model="data.email"
                              required
                              autocomplete="off"
                              placeholder="e.g. yourname@mail.com"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. yourname@mail.com'"
                              class="form-control shadow-none email_input"
                              :class="{ 'is-invalid': validation.hasError('data.email'), 'is-valid': data.email }"
                            />
                            <span class="mdi mdi-email"></span>
                          </div>
                          <div class="invalid-feedback">{{ validation.firstError('data.email') }}</div>
                          <small
                            v-if="errors && errors.email"
                            class="text-danger"
                          >{{ errors.email[0] }}</small>
                        </div>
                        <div class="form-group col-12" v-if="!reset_password_request">
                          <label
                            class="col-form-label-sm font-weight-bold mb-0"
                            for="Password"
                          >Password</label>
                          <div class="loginBox">
                            <input
                              type="password"
                              v-model="data.password"
                              required
                              autocomplete="off"
                              v-on:keyup.enter="submit"
                              placeholder="Password"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. Password'"
                              class="form-control shadow-none"
                              :class="{ 'is-invalid': validation.hasError('data.password'), 'is-valid': data.password }"
                            />
                            <span class="mdi mdi-shield-lock-outline"></span>
                          </div>
                          <small
                            v-if="errors && errors.password"
                            class="text-danger"
                          >{{ errors.password[0] }}</small>
                          <div class="invalid-feedback">{{ validation.firstError('data.password') }}</div>
                        </div>
                        <div class="form-group col-12" v-if="!reset_password_request">
                          <a
                            href="javascript:void(0)"
                            @click="change_reset_password"
                            class="text-success"
                          >Forgot Password?</a>
                        </div>
                        <div class="form-group col-12">
                          <button
                            class="btn btn-primary mt-3 float-right"
                            type="submit"
                            :disabled="validation.countErrors() > 0"
                          >
                            <span v-if="reset_password_request">Next</span>
                            <span v-else>Login</span>
                          </button>
                        </div>
                      </div>
                      <spinner v-if="$root.spinner" />
                    </form>
                    <!--=============== Login Form ===============-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>{{member }}</pre> -->
  </div>
</template>
<script>
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  name: "MemberRegisterLogin",
  data() {
    return {
      basislogo: this.$root.baseurl + "/public/images/logo.svg",
      spinner: false,
      otpsection: false,
      otpmethodsection: false,
      overlay: "",
      otp_method: "sms",
      reset_password_request: false,
      reset_password: false,
      otp: 0,
      data: {
        email: null,
        password: null
      },
      member: {},
      errors: {}
    };
  },
  methods: {
    submit: function(e) {
      if (this.reset_password_request) {
        this.verify_email();
        return false;
      }
      this.$validate().then(res => {
        if (res) {
          this.$root.spinner = true;
          axios
            .post("/reg-member/login/submit", this.data)
            .then(respons => {
              if (respons.data.status == 202) {
                this.$toastr("success", respons.data.msg, "Success!!");
                window.location.href =
                  this.$root.baseurl + "/reg-member/dashboard";
              } else if (respons.data.status == 401) {
                this.$toastr("error", respons.data.msg, "Sorry!!");
                this.$root.spinner = false;
              } else if (respons.data.status == 402) {
                this.$toastr("warning", respons.data.msg, "Sorry!!");
                this.$root.spinner = false;
              }
            })
            .catch(error => {
              if (error) {
                this.$toastr(
                  "error",
                  "Your Login Information is Wrong",
                  "Sorry!!"
                );
                this.$root.spinner = false;
              }
            });
        }
      });
    },
    change_reset_password: function() {
      this.reset_password_request = true;
    },
    verify_email() {
      var email = $(".email_input").val();
      if (email == "") {
        $(".email_input").css("border", "1px solid #ED1C24");
        $(".email_input").css("background-color", "rgba(237, 28, 36, 0.1)");
        this.$toastr("warning", "Email is required", "Warning!!");
        return false;
      }
      axios
        .get("/reg-member-email-verify", {
          params: {
            email: this.data.email
          }
        })
        .then(response => {
          if (response.data) {
            this.otpmethodsection = true;
            this.overlay = "ottp-body";
            this.member = response.data;
          } else {
            this.$toastr("error", "Email not found", "Sorry!!");
          }
        });
    },
    verify_otp() {
      axios.post("otp-check", { otp: this.member.otp }).then(res => {
        if (res.data) {
          this.otpsection = null;
          this.reset_password = true;
        } else {
          this.$toastr("warning", "OTP doesn't match", "Sorry!!");
        }
      });
    },
    resetPassword() {
      this.$validate().then(res => {
        if (res) {
          if (this.member.new_password == this.member.confirm_password) {
            this.$root.spinner = true;
            axios
              .post("/reset-reg-member-password", this.member)
              .then(respons => {
                if (respons.data.status == 202) {
                  this.$toastr("success", respons.data.msg, "Success!!");
                  window.location.href =
                    this.$root.baseurl + "/reg-member/dashboard";
                } else if (respons.data.status == 401) {
                  this.$toastr("error", respons.data.msg, "Sorry!!");
                  this.$root.spinner = false;
                } else if (respons.data.status == 402) {
                  this.$toastr("warning", respons.data.msg, "Sorry!!");
                  this.$root.spinner = false;
                }
              })
              .catch(error => {
                if (error) {
                  this.$toastr(
                    "error",
                    "Your Login Information is Wrong",
                    "Sorry!!"
                  );
                  this.$root.spinner = false;
                }
              });
          }
        }
      });
    },
    otp_method_select() {
      this.spinner = true;
      if (this.otp_method == "sms") {
        this.send_sms();
      } else {
        this.send_mail();
      }
    },
    send_sms() {
      axios
        .post("send-otp-sms", {
          email: this.member.email,
          type: "become"
        })
        .then(res => {
          if (res.data.code == 200) {
            this.otpmethodsection = false;
            this.otpsection = true;
            this.$toastr("success", res.data.msg, res.data.type);
          } else {
            this.$toastr("error", res.data.msg, res.data.type);
          }
        })
        .catch(error => {})
        .then(alw => setTimeout(() => (this.spinner = false), 300));
    },
    send_mail() {
      axios
        .post("/send-otp-mail", {
          email: this.member.email,
          type: "become"
        })
        .then(res => {
          if (res.data.code == 200 || res.data) {
            this.otpmethodsection = false;
            this.otpsection = true;
            this.$toastr(
              "success",
              "A OTP Passcode has been sent to your mail.Please enter the OTP below to verify your Email Address. If you cannot see the email in your inbox, make sure to check your SPAM/Promotions folder",
              "Email Sent!"
            );
          } else {
            this.$toastr("error", res.data.msg, res.data.type);
          }
        })
        .catch(error => console.log(error))
        .then(alw => setTimeout(() => (this.spinner = false), 300));
    }
  },
  created() {
    axios.get("/member/loggedOrNot/members-registration").then(response => {
      if (response.data) {
        window.location.href = this.$root.baseurl + "/reg-member/dashboard";
      }
    });
  },
  mounted() {
    setTimeout(() => (this.$root.loading = false), this.$root.minloadtimefd);
    if (this.$route.params.loadBrowser) {
      location.reload();
    }
  },
  validators: {
    "data.email": function(value = null) {
      if (!this.reset_password) {
        return Validator.value(value)
          .email()
          .required("Email is required");
      }
    },
    "data.password": function(value = null) {
      if (!this.reset_password) {
        return Validator.value(value).required("Password is required");
      }
    },
    "member.new_password": function(value = null) {
      if (this.reset_password) {
        return Validator.value(value)
          .required("New Password required")
          .regex("(?=.*?[A-Z])", "at least one uppercase letter required")
          .regex("(?=.*?[a-z])", "at least one lowercase letter required")
          .regex("(?=.*?[0-9])", "at least one digit required")
          .regex(
            "(?=.*?[#?!@$%^&*-])",
            "at least one special charater required"
          )
          .minLength(6);
      }
    },
    "member.confirm_password, member.new_password": function(
      confirm_password = null,
      new_password = null
    ) {
      if (this.reset_password) {
        return Validator.value(confirm_password)
          .required("Password confirmation required")
          .match(new_password);
      }
    }
  }
};
</script>

<style>
.zindex {
  z-index: 1 !important;
  opacity: 0.9;
}
</style>