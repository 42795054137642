var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_vm._l((_vm.categories),function(category,index){return _vm._t("default",function(){return [_c('div',{key:index},[_c('div',{staticClass:"product-section-title d-flex justify-content-between align-items-center"},[(
              category.child_home_page &&
              Object.keys(category.child_home_page).length > 0
            )?_c('h3',{staticClass:"px-2 mb-0"},[_vm._v(_vm._s(category.name))]):_vm._e(),_vm._v(" "),(
              category.child_home_page &&
              Object.keys(category.child_home_page).length > 0
            )?_c('router-link',{staticClass:"view-more",attrs:{"to":{
              name: 'ecommerce.all-subcategory',
              params: { slug: category.slug },
              query: {
                slug: category.slug,
              },
            }}},[_vm._v("View All")]):_vm._e()],1),_vm._v(" "),(
            category.child_home_page &&
            Object.keys(category.child_home_page).length > 0
          )?_vm._t("default",function(){return [_c('SubCategory',{attrs:{"subCategory":category.child_home_page,"category_slug":category.slug}}),_vm._v(" "),_c('span')]}):_vm._e(),_vm._v(" "),(index == 1)?_c('div',{staticClass:"row"},[_c('Slider')],1):_vm._e()],2)]})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }