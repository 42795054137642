var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"basis-ecommerce-section"},[_c('div',{staticClass:"container-wrapper"},[_vm._m(0),_vm._v(" "),(_vm.productCategory.length)?_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.productCategory),function(subcat,key){return _c('div',{key:key,staticClass:"col-lg-12 my-4"},[_c('div',{staticClass:"sub-category-product"},[_c('router-link',{attrs:{"to":{
                                name: 'ecommerce.products',
                                params: { slug: subcat.slug },

                                query: {
                                slug:subcat.parent_category.slug,
                                type: 'sub-category',
                                },
                            }}},[_c('div',{staticClass:"image"},[_c('img',{staticClass:"product-image",attrs:{"src":subcat.image,"alt":""}})]),_vm._v(" "),_c('h5',{staticClass:"product-title"},[_vm._v(_vm._s(subcat.name))])]),_vm._v(" "),_c('router-link',{staticClass:"add-to-cart-button",attrs:{"to":{
                                name: 'ecommerce.products',
                                params: { slug: subcat.slug },

                                query: {
                                slug:subcat.parent_category.slug,
                                type: 'sub-category',
                                },
                            }}},[_vm._v("\n\t\t\t\t\t\t\tView All\n\t\t\t\t\t\t\t"),_c('i',{staticClass:"fa fa-long-arrow-right"})])],1)])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row pt-5"},[_c('div',{staticClass:"col-lg-12 text-center"},[_c('router-link',{staticClass:"btn btn-lg btn-primary rounded-50",attrs:{"to":{ name:'ecommerce.home'}}},[_vm._v("\n\t\t\t\t\t\tShow More\n\t\t\t\t\t\t"),_c('i',{staticClass:"fas fa-long-arrow-alt-right pl-1"})])],1)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-header text-center mb-5"},[_c('h1',{staticClass:"title"},[_vm._v("DigitalShop Product")])])
}]

export { render, staticRenderFns }