var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services"},[_c('span',{staticClass:"shape1"}),_vm._v(" "),_c('span',{staticClass:"shape2"}),_vm._v(" "),_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"service-tab"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"service-item tab-content",attrs:{"id":"myTabContent"}},[(_vm.data)?_c('div',{staticClass:"members tab-pane fade show active",attrs:{"id":"members","role":"tabpanel","aria-labelledby":"members-tab"}},[(_vm.data.member_service)?_c('div',{staticClass:"row"},[_vm._l((_vm.data.member_service),function(service,index){return _vm._t("default",function(){return [_c('div',{key:index,staticClass:"col-6 col-lg-4",attrs:{"data-aos":"fade-up","data-aos-offset":"100","data-aos-duration":500 + index * 200}},[(service.external_page == 0)?_c('router-link',{attrs:{"to":{
                    name: 'service_detail',
                    params: { id: service.id, type: 'member' },
                  }}},[_c('div',{staticClass:"card card-body shadow-sm mb-3"},[_c('div',{staticClass:"iconbox"},[_c('span',{domProps:{"innerHTML":_vm._s(service.icon)}})]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(service.title))])])]):_c('router-link',{attrs:{"to":'/' + service.form_link + '/' + service.id + '/member'}},[_c('div',{staticClass:"card card-body shadow-sm mb-3"},[_c('div',{staticClass:"iconbox"},[_c('span',{domProps:{"innerHTML":_vm._s(service.icon)}})]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(service.title))])])])],1)]})})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row pt-5"},[_c('div',{staticClass:"col-lg-12 text-center"},[_c('router-link',{staticClass:"btn btn-lg btn-primary rounded-50",attrs:{"to":{ name: 'service', params: { type: 'member' } }}},[_vm._v("\n                View All\n                "),_c('i',{staticClass:"fas fa-long-arrow-alt-right pl-1"})])],1)])]):_vm._e(),_vm._v(" "),(_vm.data)?_c('div',{staticClass:"nonmembers tab-pane fade",attrs:{"id":"nonmembers","role":"tabpanel","aria-labelledby":"nonmembers-tab"}},[(_vm.data.non_member_service)?_c('div',{staticClass:"row"},[_vm._l((_vm.data.non_member_service),function(service,index){return _vm._t("default",function(){return [_c('div',{key:index,staticClass:"col-6 col-lg-4",attrs:{"data-aos":"fade-up","data-aos-offset":"100","data-aos-duration":500 + index * 200}},[(service.external_page == 0)?_c('router-link',{attrs:{"to":{
                    name: 'service_detail',
                    params: { id: service.id, type: 'non_member' },
                  }}},[_c('div',{staticClass:"card card-body shadow-sm mb-3"},[_c('div',{staticClass:"iconbox"},[_c('span',{domProps:{"innerHTML":_vm._s(service.icon)}})]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(service.title))])])]):_c('router-link',{attrs:{"to":'/' + service.form_link + '/' + service.id + '/non_member'}},[_c('div',{staticClass:"card card-body shadow-sm mb-3"},[_c('div',{staticClass:"iconbox"},[_c('span',{domProps:{"innerHTML":_vm._s(service.icon)}})]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(service.title))])])])],1)]})})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row pt-5"},[_c('div',{staticClass:"col-lg-12 text-center"},[_c('router-link',{staticClass:"btn btn-lg btn-primary rounded-50",attrs:{"to":{ name: 'service', params: { type: 'non_member' } }}},[_vm._v("\n                View All\n                "),_c('i',{staticClass:"fas fa-long-arrow-alt-right pl-1"})])],1)])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-header mb-5"},[_c('h1',{staticClass:"title text-center"},[_vm._v("Our Service")]),_vm._v(" "),_c('ul',{staticClass:"nav nav-tabs justify-content-center",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"members-tab","data-toggle":"tab","href":"#members","role":"tab","aria-controls":"members","aria-selected":"true"}},[_vm._v("Members")])]),_vm._v(" "),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"nonmembers-tab","data-toggle":"tab","href":"#nonmembers","role":"tab","aria-controls":"nonmembers","aria-selected":"false"}},[_vm._v("Non Members")])])])])
}]

export { render, staticRenderFns }