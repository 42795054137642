<template>
	<div class="contact">
		<PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
		<div class="container-wrapper main__body">
			<div class="card card-body my-5 bg-transparent">
				<div class="col-lg-12">
					<div class="row">
						<div class="col-lg-4">
							<div class="search-body">
								<h3>Organization Name</h3>
								<div class="searchBox">
									<input
										type="text"
										placeholder="Search by Organization Name"
										v-on:keyup.enter="get_datas()"
										v-model="search_data.organization_name"
										id="search"
									/>
									<span class="mdi mdi-magnify" @click="get_datas()"></span>
								</div>
							</div>
						</div>
						<div class="col-lg-4"></div>
						<div class="col-lg-4">
							<div class="search-body">
								<h3>Discout category</h3>
								<select
									name="discount_category[name]"
									v-on:click="get_datas()"
									v-model="search_data.category_id"
									class="form-control form-control-lg rounded-15"
									style="height: 40px"
								>
									<option :value="null">Search by discout category</option>
									<option
										v-for="(val, index) in discount_category"
										:key="index"
										:value="val.id"
									>{{ val.name }}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div class="row mb-5">
					<div class="col-md-3 mb-5 align-center" v-for="(data, index) in table.datas" :key="index">
						<span class="discountShape test" :class="' bg-discount' + (index + 2)"></span>
						<div class="card_body">
							<router-link
								:to="{
                  name: 'discountServiceDetails',
                  params: { id: data.id },
                }"
							>
								<img :src="$root.baseurl + '/public' + data.company_logo" alt />
								<h4 class="category_name">
									<slot
										v-if="data.discout_category && data.discout_category.name"
									>{{ data.discout_category.name }}</slot>
								</h4>
								<div class="company__name" v-if="data.organization_name">
									<h2>{{ data.organization_name }}</h2>
								</div>
							</router-link>
							<div class="discount">
								<h5 :class="'bg-percentage' + (index + 1)" v-if="data.discount_percentage">
									<span class="upto">upto</span>&nbsp;
									<span class="number">
										{{
										data.discount_percentage
										}}
									</span>
									<span class="percent">%</span>
								</h5>
							</div>
						</div>
						<span class="rolShape1"></span>
						<span class="rolShape2"></span>
						<span class="rolShape3"></span>
					</div>
				</div>
				<!-- pagination -->
				<Pagination :url="'get-discount-services'" :search_data="search_data" v-if="!$root.spinner" />
				<!-- /pagination -->
			</div>
		</div>
	</div>
</template>
<script>
	import PageBanner from "../../components/elements/PageBanner";
	import axios from "axios";

	export default {
		components: {
			PageBanner
		},
		data() {
			return {
				fields_name: {
					0: "Select One",
					organization_name: "Name",
					service_sector: "Service Sector"
				},
				search_data: {
					field_name: 0,
					organization_name: "",
					category_id: null,
					value: "",
					status: ""
				},
				success: false,
				data: { department: "" },
				breadcrumbTitle: "Privilege Services",
				breadcumb: [{ title: "Privilege Services" }],
				table: {
					datas: []
				},
				meta: [],
				links: [],
				discount_category: {}
			};
		},

		methods: {
			asyncData() {
				this.get_datas();
				this.get_discountCategory();
			},
			get_datas() {
				axios
					.get("/get-discount-services", { params: this.search_data })
					.then(res => {
						this.table.datas = res.data.data;
						this.meta = res.data.meta;
						this.links = res.data.links;
					})
					.catch(error => console.log(error.respons))
					.then(alw => setTimeout(() => (this.$root.loading = false), 200));
			},
			get_discountCategory: function() {
				axios
					.get("get-discount-category")
					.then(respons => {
						this.discount_category = respons.data.data;
						this.$parent.debug_data = respons;
						// console.log(respons);
					})
					.catch(error => {
						this.$parent.debug_data = error;
						console.log(error.respons);
					});
			},
			search() {
				this.get_datas();
			}
		},
		// created() {
		//   this.$root.loading = false;
		//   this.get_datas();
		//   this.get_discountCategory();
		// },
		watch: {
			$route: {
				handler: "asyncData",
				immediate: true
			}
		}
	};
</script>

