<template>
  <!--Previous EC-->
  <div id="example-content">
    <slot v-if="!$root.spinner">
      <div class="basis-member staff">
        <div class="basis-member previousEc">
          <h5 class="page-title font-weight-bold text-uppercase">{{ breadcrumbTitle }}</h5>
          <div class="accordion" id="accordionExample">
            <div
              class="card shadow-sm"
              v-for="(session_name,key,index) in $root.variable.sessions"
              :key="index"
            >
              <div class="card-header bg-transparent" id="headingOne">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapseOne'+index"
                    :aria-expanded="index==0?'true':'false'"
                    :aria-controls="'collapseOne'+index"
                  >BASIS {{ session_name }}</button>
                </h2>
              </div>
              <div
                :id="'collapseOne'+index"
                class="collapse"
                :class="index==0?'show':''"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="card-body">
                      <div class="row" v-if="previous_ecs">
                        <div
                          class="col-lg-6 mb-3"
                          v-for="(ec, index) in previous_ecs"
                          :key="index"
                          v-if="ec.sessions == session_name"
                        >
                          <div class="card shadow-sm">
                            <div class="card-body p-2">
                              <div class="row no-gutters align-items-center">
                                <div class="col-lg-9">
                                  <div class="profile-content p-2">
                                    <span>{{ ec.designation }}</span>
                                    <h3>{{ ec.name }}</h3>
                                    <!-- <small>Chief Executive Officer</small> -->
                                    <small>{{ ec.company }}</small>
                                  </div>
                                </div>
                                <div class="col-lg-3">
                                  <div class="profileImg shadow-sm">
                                    <img v-lazy="ec.FullUrl+ec.image" :alt="ec.name" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slot>
    <spinner v-if="$root.spinner" />
  </div>
  <!--//Previous EC-->
</template>

<script>
export default {
  name: "PreviousEcs",
  data() {
    return {
      breadcrumbTitle: "Previous- EC's",
      breadcumb: [{ title: "Previous- EC's" }],
      previous_ecs: []
    };
  },
  methods: {
    previousEcs() {
      this.$root.spinner = true;
      axios
        .get("/get-previous-ecs")
        .then(respons => {
          this.previous_ecs = respons.data;
          this.$root.spinner = false;
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.previousEcs();
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  }
};
</script>
