<template>
  <div id="example-content">
    <slot v-if="!$root.spinner">
      <slot v-if="content.image">
        <div class="row">
          <div class="col-xs-12 col-lg-7" data-aos="fade-right">
            <!--Heading-->
            <h5 class="page-title font-weight-bold text-uppercase">{{content.title}}</h5>
            <!--Text-->
            <p class="text-justify" v-html="content.description"></p>
          </div>
          <div class="col-xs-12 col-lg-5">
            <img v-lazy="content.FullUrl+content.image" class="img-fluid" alt="..." />
          </div>
        </div>
      </slot>
      <slot v-else>
        <div class="col-xs-12 col-lg-12" data-aos="fade-right">
          <h5 class="page-title font-weight-bold text-uppercase">{{content.title}}</h5>
          <p class="text-justify" v-html="content.description"></p>
        </div>
      </slot>
      <div class="col-xs-12 col-lg-12" data-aos="fade-right" v-if="content.content_file.length > 0">
        <table class="table table-sm table-hover table-fixed table-bordered">
          <thead>
            <tr class="bg-success text-light">
              <th>SL</th>
              <th>Name of Title</th>
              <th class="text-center">Attachment</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(content_file, index) in content.content_file" :key="index">
              <td class="text-center">{{index+1}}</td>
              <td>{{ content_file.title }}</td>
              <td class="text-center">
                <a
                  :href="content_file.FullUrl+ content_file.file"
                  target="_blank"
                  class="btn btn-sm btn-success rounded-50 lift"
                >
                  <span class="fab fa fa-download"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </slot>
    <spinner v-if="$root.spinner" />
  </div>
</template>

<script>
const breadcumb = [
  {
    title: "Members List"
  }
];
export default {
  name: "Content",
  data() {
    return {
      content: {
        content_file: [],
        breadcrumbTitle: "",
        breadcumb: []
      }
    };
  },
  methods: {
    asyncData() {
      this.get_data();
      this.$root.loading = false;
    },
    get_data() {
      this.$root.spinner = true;
      axios
        .get("/get-content/" + this.$route.params.slug)
        .then(respons => {
          this.content = respons.data.result;
          localStorage.setItem("breadcrumbTitle", respons.data.result.title);
          localStorage.setItem(
            "breadcumb",
            JSON.stringify(respons.data.breadcumb)
          );
          this.$root.spinner = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {},
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    }
  }
};
</script>
