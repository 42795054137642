<template>
	<div class="member-services" v-if="
		(!$parent.loading && ifCon) ||
		$root.variable.rep_login ||
		$root.variable.aLogId
	">
		<div class="card shadow-sm mb-3">
			<div class="card-header px-0">
				<div class="d-flex align-items-center justify-content-between">
					<h5 class="text-uppercase font-weight-bold px-3 mb-0">Products & Services Information</h5>
				</div>
			</div>
			<div class="card-body">
				<div class="row align-items-center justify-content-end mb-4">
					<div v-if="Object.keys(errors).length > 0" class="col-lg-12 py-2" style="background: #fddede">
						<div class="error">
							<span v-for="(err, errIndex) in errors" :key="errIndex" class="text-danger">
								{{ err[0] }}
								<br />
							</span>
						</div>
					</div>
				</div>

				<form v-on:submit.prevent="submit" id="form">
					<!-- products info -->
					<fieldset class="border border-primary p-3 mb-3">
						<legend class="w-25 text-center">
							<small class="font-weight-bold">Product Information</small>
						</legend>
						<div class="row mb-5 justify-content-center">
							<div class="row col-12 mb-3" v-for="(product, index) in data.product_lists" :key="index">
								<div class="col-md-4 mb-3">
									<label for="namea">Category</label>
									<span class="text-danger requierd-star"></span>
									<div class="form-group">
										<select v-model="product.category" :name="'product_lists[' + index + '][category]'"
											class="form-control" :class="{
												'is-invalid': validation.hasError('product.category'),
												'is-valid': product.category,
											}">
											<option value>Select one</option>
											<option v-for="(expertise, id, index) in extraData.expertises" :key="index"
												:value="expertise.id">{{ expertise.name }}</option>
										</select>
										<div class="invalid-feedback">
											{{
												validation.firstError(
													"data.compliances.product.category"
												)
											}}
										</div>
									</div>
								</div>
								<div class="col-md-8 mb-3">
									<label for="namea">Name</label>
									<span class="text-danger requierd-star"></span>
									<div class="form-group">
										<input type="text" :name="'product_lists[' + index + '][title]'"
											v-model="product.title" class="form-control" :class="{
												'is-invalid': validation.hasError('product.title'),
												'is-valid': product.title,
											}" placeholder="Name" />
									</div>
								</div>
								<div class="col-md-6 form-row mb-3">
									<div class="col-md-12 mb-3">
										<label for="pdf">File</label>
										<!-- <span class="requierd-star"></span> -->
										<div class="loginBox">
											<b-form-file v-model="product.pdf_file" accept=".pdf" :placeholder="
												product.file ? 'File attached ' : 'Choose a pdf file'
											" drop-placeholder="Drop file here..."></b-form-file>
											<span class="mdi mdi-file-pdf text-danger"></span>
										</div>
										<small>File size must be smaller than 5mb</small>
										<a v-if="product.file" :href="$root.baseurl + '/public' + product.file"
											class="float-right" target="_blank">View file</a>
										<input type="hidden" v-model="product.file"
											:name="'product_lists[' + index + '][old_file]'" />
									</div>
								</div>
								<div class="col-md-6 form-row mb-3">
									<div class="col-md-12 mb-3">
										<label for="pdf">Image</label>
										<!-- <span class="requierd-star"></span> -->
										<div class="loginBox">
											<b-form-file v-model="product.image_path" accept=".jpg, .png" :placeholder="
												product.image ? 'File attached ' : 'Choose a image'
											" drop-placeholder="Drop file here..."></b-form-file>
											<a v-if="product.image" :href="$root.baseurl + '/public' + product.image"
												class="float-right" target="_blank">View file</a>
											<input type="hidden" v-model="product.image"
												:name="'product_lists[' + index + '][old_image]'" />
											<span class="mdi mdi-file-pdf text-danger"></span>
										</div>
									</div>
								</div>
								<div class="col-md-12 mb-3">
									<label for="namea">Details</label>
									<div class="form-group">
										<textarea rows="5" class="form-control"
											:name="'product_lists[' + index + '][detail]'" v-model="product.detail" :class="{
												'is-invalid': validation.hasError(
													'product.installations'
												),
												'is-valid': product.installations,
											}" placeholder="Name"></textarea>
									</div>
								</div>
								<div class="col-lg-1" v-if="index > 0">
									<button @click="data.product_lists.splice(index, 1)" class="btn btn-sm btn-danger w-100"
										type="button">
										<span class="fas fa-times"></span>
									</button>
								</div>
								<hr class="w-100" />
							</div>
							<div class="col-lg-11 text-right">
								<button @click="
									data.product_lists.push({
										category: '',
										pdf_file: null,
										image_path: null,
									})
								" class="btn btn-sm btn-success" type="button">Add More</button>
							</div>
						</div>
					</fieldset>

					<!-- services info -->
					<fieldset class="border border-primary p-3 mb-3">
						<legend class="w-25 text-center">
							<small class="font-weight-bold">Service Information</small>
						</legend>
						<div class="row mb-5 justify-content-center">
							<div class="row col-12 mb-3" v-for="(serv, index) in data.industry_lists" :key="index">
								<div class="col-md-4 mb-3">
									<div class="form-row mb-3">
										<label for="namea" class="col-md-4">
											<small>
												<b>Service</b>
											</small>
											<span class="text-danger requierd-star"></span>
										</label>
										<div class="col-md-8">
											<input type="text" v-model="serv.service"
												:name="'service_lists[' + index + '][service]'" class="form-control" :class="{
													'is-invalid': validation.hasError('serv.service'),
													'is-valid': serv.service,
												}" placeholder="Service" />
										</div>
									</div>
									<div class="form-row">
										<label for="namea" class="col-md-4">
											<small>
												<b>Scale of the proposed service</b>
											</small>
										</label>
										<div class="col-md-8">
											<input type="text" v-model="serv.scale"
												:name="'service_lists[' + index + '][scale]'" class="form-control" :class="{
													'is-invalid': validation.hasError('serv.scale'),
													'is-valid': serv.intended_domain,
												}" placeholder="Scale of theproposed service" />
										</div>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-row mb-3">
										<label for="namea" class="col-md-4">
											<small>
												<b>Target Industry</b>
											</small>
											<span class="text-danger requierd-star"></span>
										</label>
										<div class="col-md-8">
											<select v-model="serv.industry" :name="'service_lists[' + index + '][industry]'"
												class="form-control">
												<option value>Select one</option>
												<option v-for="(expertise, index) in extraData.industryLists" :key="index"
													:value="expertise.id">{{ expertise.name }}</option>
											</select>
										</div>
									</div>
									<div class="form-row">
										<label for="namea" class="col-md-4">
											<small>
												<b>Type</b>
											</small>
										</label>
										<div class="col-md-8">
											<select v-model="serv.type" :name="'service_lists[' + index + '][type]'"
												class="form-control">
												<option value>Select one</option>
												<option>Export</option>
												<option>Local</option>
												<option>Both</option>
											</select>
										</div>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-row mb-3">
										<label for="namea" class="col-md-4">
											<small>
												<b>Platform</b>
											</small>
										</label>
										<div class="col-md-8">
											<input type="text" v-model="serv.member_platforms"
												:name="'service_lists[' + index + '][member_platforms]'"
												class="form-control" :class="{
													'is-invalid': validation.hasError(
														'serv.member_platforms'
													),
													'is-valid': serv.member_platforms,
												}" placeholder="Platform" />
										</div>
									</div>
									<div class="form-row">
										<label for="namea" class="col-md-4">
											<small>
												<b>Marketing Material Design</b>
											</small>
										</label>
										<div class="col-md-8">
											<input type="text" v-model="serv.attachment"
												:name="'service_lists[' + index + '][attachment]'" class="form-control"
												:class="{
													'is-invalid': validation.hasError('serv.attachment'),
													'is-valid': serv.attachment,
												}" placeholder="Marketing Material Design" />
										</div>
									</div>
								</div>
								<div class="col-lg-1" v-if="index > 0">
									<button @click="data.industry_lists.splice(index, 1)"
										class="btn btn-sm btn-danger w-100" type="button">
										<span class="fas fa-times"></span>
									</button>
								</div>
								<hr class="w-100" />
							</div>
							<div class="col-lg-11 text-right">
								<button @click="
									data.industry_lists.push({
										category: '',
										type: '',
										industry: '',
									})
								" class="btn btn-sm btn-success" type="button">Add More</button>
							</div>
						</div>
					</fieldset>

					<fieldset class="border border-primary p-3 mb-3">
						<legend class="w-25 text-center">
							<small class="font-weight-bold">Export Information</small>
						</legend>
						<div class="row mb-5 justify-content-center">
							<div class="row col-10 mb-3">
								<label class="col-md-6">Does your company export ?</label>
								<div class="col-md-4">
									<select v-model="data.export_infos.does_export" name="export_infos[does_export]"
										class="form-control">
										<option value>Select one</option>
										<option>Yes</option>
										<option>No</option>
									</select>
								</div>
							</div>
							<div class="row col-10 mb-3">
								<label class="col-md-6">
									If yes please specify the percentage of your last years’
									revenue from export
								</label>
								<div class="col-md-4">
									<input type="number" v-model.number="data.export_infos.percentage_export"
										name="export_infos[percentage_export]" class="form-control" placeholder="e.g. 50"
										onfocus="this.placeholder = ''" onblur="this.placeholder = 'e.g. 50'" />
								</div>
								<div class="col-md-1 col-xs-1">%</div>
							</div>
							<div class="row col-10 mb-3">
								<label class="col-md-6">Country/s of export (last 3 years)</label>
								<div class="col-md-4">
									<div class="row mb-3" v-for="(countryList, index) in data.countryLists" :key="index">
										<div class="col-md-10">
											<select v-model="countryList.country"
												:name="'countryLists[' + index + '][country]'" class="form-control">
												<option value>Select one</option>
												<option v-for="(country, index) in extraData.country_lists" :key="index"
													:value="country.id">{{ country.name }}</option>
											</select>
										</div>
										<div class="col-md-2">
											<button v-if="index > 0" @click="data.countryLists.splice(index, 1)"
												class="btn btn-sm btn-danger" type="button">
												<span class="fas fa-times"></span>
											</button>
										</div>
									</div>
									<button @click="data.countryLists.push({ country: '' })" class="btn btn-sm btn-success"
										type="button">Add More</button>
								</div>
							</div>
							<div class="row col-10 mb-3">
								<label class="col-md-6">Is this a foreign JV (Joint Venture)</label>
								<div class="col-md-4">
									<select v-model="data.export_infos.foreign_jv" name="export_infos[foreign_jv]"
										class="form-control">
										<option value>Select one</option>
										<option>Yes</option>
										<option>No</option>
									</select>
								</div>
							</div>
							<div class="row col-10 mb-3">
								<label class="col-md-6">Are you a captive center of a foreign organization</label>
								<div class="col-md-4">
									<select v-model="data.export_infos.captive" name="export_infos[captive]"
										class="form-control">
										<option value>Select one</option>
										<option>Yes</option>
										<option>No</option>
									</select>
								</div>
							</div>
							<div class="row col-10 mb-3">
								<label class="col-md-6">If yes Name of the organization</label>
								<div class="col-md-4">
									<input type="text" v-model="data.export_infos.captive_org_name"
										name="export_infos[captive_org_name]" class="form-control" :class="{
											'is-invalid': validation.hasError(
												'data.export_infos.captive_org_name'
											),
											'is-valid': data.export_infos.captive_org_name,
										}" placeholder="e.g. Nogor Solutions ltd" onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Nogor Solutions ltd'" />
								</div>
							</div>
							<div class="row col-10 mb-3">
								<label class="col-md-6">Address of the organization</label>
								<div class="col-md-4">
									<input type="text" v-model="data.export_infos.captive_org_address"
										name="export_infos[captive_org_address]" class="form-control" :class="{
											'is-invalid': validation.hasError(
												'data.export_infos.captive_org_address'
											),
											'is-valid': data.export_infos.captive_org_address,
										}" placeholder="e.g. 221b baker street london" onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 221b baker street london'" />
								</div>
							</div>
							<div class="row col-10 mb-3">
								<label class="col-md-6">Website</label>
								<div class="col-md-4">
									<input type="text" v-model="data.export_infos.captive_org_web"
										name="export_infos[captive_org_web]" class="form-control" :class="{
											'is-invalid': validation.hasError(
												'data.export_infos.captive_org_web'
											),
											'is-valid': data.export_infos.captive_org_web,
										}" placeholder="e.g. http://website.com" onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. http://website.com'" />
								</div>
							</div>
							<div class="row col-10 mb-3">
								<label class="col-md-6">Future market expansion (country/s)</label>
								<div class="col-md-4">
									<input type="text" v-model="data.export_infos.future_market"
										name="export_infos[future_market]" class="form-control" :class="{
											'is-invalid': validation.hasError(
												'data.export_infos.future_market'
											),
											'is-valid': data.export_infos.future_market,
										}" placeholder="e.g. Future" onfocus="this.placeholder = ''" onblur="this.placeholder = 'e.g. Future'" />
								</div>
							</div>
						</div>
					</fieldset>

					<div class="row justify-content-center">
						<button class="btn btn-success" type="submit">Update Products & Service Information</button>
					</div>
				</form>
			</div>
		</div>
		<!-- <pre>{{data}}</pre> -->
		<!-- <pre>{{validations.allErrors()}}</pre> -->
	</div>
	<div v-else class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">Sorry!! you cannot access this page</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
const breadcumb = [
	{
		title: "Products / Services Information"
	}
];
export default {
	name: "ProductsServicesForm",
	data() {
		return {
			ifCon: false,
			breadcumb: breadcumb,
			breadcrumbTitle: "Products / Services Information",
			extraData: {
				expertises: [],
				industryLists: [],
				country_lists: []
			},
			data: {
				product_lists: [{ category: "", pdf_file: null, image_path: null }],
				industry_lists: [{ category: "", type: "", industry: "" }],
				export_infos: { captive: "", does_export: "", foreign_jv: "" },
				countryLists: [{ country: "" }]
			},
			member: {
				info: {}
			},
			errors: {}
		};
	},
	methods: {
		submit: function (e) {
			e.preventDefault();
			const config = {
				headers: { "content-type": "multipart/form-data" }
			};
			var form = document.getElementById("form");
			var formData = new FormData(form);

			if (confirm("Are you confirming, is the provided information valid?")) {
				if (this.data.product_lists) {
					$.each(this.data.product_lists, function (key, value) {
						if (value.image_path) {
							formData.append("product_image[" + key + "]", value.image_path);
						}
						if (value.pdf_file) {
							formData.append("product_pdf_file[" + key + "]", value.pdf_file);
						}
					});
				}
				this.$validate().then(res => {
					if (this.validation.countErrors() > 0) {
						this.$toastr(
							"warning",
							'You need to fill <span class="font-weight-bold">' +
							this.validation.countErrors() +
							" more empty</span> mandatory fields",
							"Empty Mandatory Fields!!"
						);
					} else {
						if (res) {
							this.scrollTop();
							this.$parent.loading = true;
							axios
								.post("member/productsServices", formData, config)
								.then(response => {
									this.$toastr("success", "Update Successfully", "Success!!");
									this.errors = {};
									location.reload();
								})
								.catch(error => {
									if (error.response.status === 422) {
										this.errors = error.response.data.errors || {};
										this.$parent.loading = false;
									}
								})
								.then(always => {
									setTimeout(
										() => (this.$parent.loading = false),
										this.$root.minloadtimefd
									);
								});
						}
					}
				});
			}
		},
		getData: function (e) {
			// Member Export Countries List
			axios.get("/member/getExportCountriesList").then(response => {
				this.data.countryLists = response.data;
			});
			axios.get("/member/getExportInfos").then(response => {
				this.data.export_infos = response.data;
			});
			// Member Services List
			axios.get("/member/getServices").then(response => {
				if (Object.keys(response.data).length > 0) {
					this.data.industry_lists = response.data;
				}
			});
			// Member Products List
			axios.get("/member/getProducts").then(response => {
				if (Object.keys(response.data).length > 0) {
					console.log(response.data);
					this.data.product_lists = response.data;
				}
			});

			setTimeout(() => {
				this.$parent.loading = false;
			}, 1000);
		}
	},
	mounted() {
		this.loggedIn();
		// Expertise
		this.getList("Expertise", "expertises", true);
		// Country List
		this.getList("Country", "country_lists", true);
		// all Industry List
		this.getList("IndustryList", "industryLists", true);
		// member data
		this.getData();
	},
	beforeCreate: function () {
		this.$root.loading = false;
		this.$parent.loading = true;
		if (this.$root.access != null || this.$root.access['product_service']) {
			this.ifCon = true;
		}
		//  && Object.keys($root.access).length > 0 && $root.access['product_service']
	},
	updated() {
		this.$root.debug_data = this.data;
	},
	validators: {
		// "product.category": function(value = null) {
		//   return Validator.value(value).required("Category is required");
		// },
		// "product.title": function(value = null) {
		//   return Validator.value(value).required("Name is required");
		// }
	}
};
</script>

