<template>
  <div class="benifits">
    <div class="container-wrapper">
      <div class="card card-body p-0 rounded-10 shadow-lg">
        <span class="shape"></span>
        <span class="bubble1"></span>
        <span class="bubble2"></span>
        <span class="bubble3"></span>
        <span class="bubble4"></span>
        <span class="bubble5"></span>
        <div class="row">
          <div class="col-lg-5 pr-lg-0">
            <div class="rounded-0 h-100 border-0">
              <div class="card-body" v-if="data">
                <h3 v-if="data.benifits">{{data.benifits.title}}</h3>
                <p v-if="data.benifits" v-html="data.benifits.description.substring(0, 750)"></p>
                <router-link
                  class="btn btn-sm btn-success mt-4"
                  :to="{name:'content_detail', params:{slug:'benifits-of-members'}}"
                >
                  Read more..
                  <i></i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-7 pl-lg-0">
            <div class="becomeMemberApply">
              <h1>
                Become a
                <br />
                <span>Member</span>
              </h1>
              <div class="btn-apply">
                <!-- <a href="#">
                  APPLY
                  <i class="fa fa-play play-i white-color" aria-hidden="true"></i>
                </a>-->
                <router-link to="/memberRegistration">
                  APPLY NOW
                  <i class="fa fa-play play-i white-color" aria-hidden="true"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>{{ data }}</pre> -->
  </div>
</template>

<script>
export default {
  name: "Benifits",
  data() {
    return {
      data: {}
    };
  },
  methods: {
    getBenifits() {
      axios.get("/get-benifits-of-members").then(response => {
        this.data = response.data;
      });
    }
  },
  mounted() {
    setTimeout(() => this.getBenifits(), 100);
  }
};
</script>

<style>
</style>