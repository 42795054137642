<template>
	<div class="member-invoice" v-if="!$root.spinner">
		<div class="col-12 form-row m-4 justify-content-center">
			<div class="col-3 text-center">
				<button @click="printInvoice()" class="btn btn-info">
					<i class="fa fa-download"></i> Download
				</button>
			</div>
			<div
				class="col-3 text-center"
				v-if="
					(invoice.status == 'pending' &&
						member.info &&
						member.info.status == 1) ||
					(member.info &&
						member.info.status == 2 &&
						invoice.account_head &&
						invoice.account_head.id == 2) ||
					(member.info &&
						member.info.status == 0 &&
						invoice.account_head &&
						invoice.account_head.id == 2)
				"
			>
				<!-- <button
          class="btn btn-success"
          type="button"
          @click="sslPayment(invoice.id)"
        >
          Pay Now
        </button> -->

				<router-link
					class="btn btn-success"
					:to="{ name: 'paymentOption', params: { id: $route.params.id } }"
					>Check Out</router-link
				>
			</div>
		</div>
		<!-- Invoice -->
		<div class="invoice-frontend border border-dark" v-if="invoice">
			<img
				v-lazy="$root.baseurl + '/public/images/BASIS_Letterhead.jpg'"
				width="100%"
				height="100%"
				alt="BASIS_Letterhead"
			/>
			<div
				class="invoice-content"
				style="
					position: absolute;
					top: 0;
					left: 0;
					padding: 80px 25px;
					width: 100%;
				"
			>
				<table
					v-if="memberInfo"
					class="invoice-frontend-table"
					width="100%"
					border="0"
					cellspacing="0"
					cellpadding="0"
				>
					<tr>
						<td style="vertical-align: top">
							Ref:
							<span v-if="memberInfo"
								>{{ memberInfo.membership_type }}/Invoice/{{
									memberInfo.membership_no
								}}/{{ invoice.invoice_number }}</span
							>
						</td>
						<td class="text-right">
							Invoice No:
							<span v-if="invoice"
								>BASIS-{{ invoice.invoice_date | formatDate("DDMMYYYY") }}-{{
									invoice.invoice_number
								}}</span
							>
							<br />
							<img :src="barcode" alt style="width: 237px; height: 30px" />
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<p>
								<span v-if="invoice">{{
									invoice.invoice_date | formatDate("MMMM, DD YYYY")
								}}</span>
							</p>
						</td>
					</tr>
					<tr v-if="memberInfo">
						<td colspan="2">
							<p class="mb-1">Invoice to:</p>
							<p class="mb-1">{{ memberInfo.company_name }}</p>
							<p class="mb-1">{{ memberInfo.address }}</p>
							<p class="mb-0 mt-3">
								Attn:
								<b>
									<span>
										{{
											invoice.representative ? invoice.representative.name : ""
										}},
										{{
											invoice.representative
												? invoice.representative.designation
												: ""
										}}
									</span>
								</b>
							</p>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<table width="100%">
								<tr>
									<td>
										<h3
											style="
												border: 1px solid;
												width: 20%;
												text-align: center;
												border-radius: 4px;
												padding: 4px 0px;
												margin: 10px auto;
											"
										>
											Invoice
										</h3>
									</td>
								</tr>
								<tr>
									<td>
										<table
											class="table"
											width="100%"
											border="1"
											cellpadding="0"
											cellspacing="0"
										>
											<thead>
												<tr>
													<th class="text-center">SL No.</th>
													<th class="text-center">Description</th>
													<th class="text-center">Amount</th>
												</tr>
											</thead>
											<tbody v-if="invoice">
												<tr>
													<td class="text-center">01</td>
													<td class="text-center">
														<span v-if="invoice.account_head">
															<slot v-if="invoice.account_head.id == 2"
																>{{ invoice.account_head.name }}-
																{{
																	invoice.invoice_date | formatDate("YYYY")
																}}</slot
															>
															<slot v-else>{{
																invoice.account_head.name
															}}</slot>
														</span>
														<slot v-if="invoice.type == 'training'">
															<span v-if="invoice.training">{{
																invoice.training.name
															}}</span>
														</slot>
														<slot v-else-if="invoice.type == 'event'">
															<span v-if="invoice.event">{{
																invoice.event.title
															}}</span>
														</slot>
														<slot v-else>
															<span v-if="invoice.service">{{
																invoice.service.title
															}}</span>
														</slot>
													</td>
													<td class="text-center">
														{{ invoice.amount | currency }}
													</td>
												</tr>
												<tr>
													<td class="text-right" colspan="2">
														<b>Total=</b>
													</td>
													<td class="text-center">
														<b>{{ total_amount | currency }}</b>
													</td>
												</tr>
											</tbody>
										</table>
										Inword:
										<b>
											<span style="text-transform: capitalize"
												>{{ Math.round(total_amount) | inWords }} Only</span
											>
										</b>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<p class="text-justify mb-3">
								Please make payment in favor of <b>"BASIS"</b> by Cash Deposit
								or Pay Order or Cheque directly to Prime Bank Limited, Kawran
								Bazar branch <b>(Account No- "2113317014064")</b> please take 2
								(two) copies of invoice to the bank and after making successful
								payment, you are requested to send the scanned copy of the same
								with bank seal to <b>payment@basis.org.bd</b> for updating at
								BASIS end
								<br />
								<br />
								OR
								<br />
								<br />
								You may also pay the fee using the online payment gateway of
								BASIS.
							</p>
							<p
								v-if="invoice.account_head && invoice.account_head.id == 2"
								class="text-justify mb-2"
								style="color: red"
							>
								Please note that, as per the section no. 6.1 of our Memorandum
								of Association, the last date for payment is February 28,
								{{ invoice.invoice_date | formatDate("YYYY") }}.
							</p>
						</td>
					</tr>
				</table>
				<br />
				<slot v-if="invoice.account_head && invoice.account_head.id == 2">
					<p class="p-content" style="padding-left: 5px">Note:</p>
					<div class="note">
						<div style="margin-bottom: 0rem">
							1. For Membership Certificate, you need to pay the annual
							subscription and complete your company profile (with Compliance
							Information) from BASIS website.
						</div>
						<div>
							2. You may request for Membership Certificate by clicking on the
							“Certificate Req” button and download the Certificate from
							“Certificate List” from the membership dashboard of BASIS website.
						</div>
						<div>
							3. Original Copy of the Certificate may be collected from BASIS
							Secretariat in person or you may request for home delivery to your
							location.
						</div>
					</div>
				</slot>
				<p style="font-style: italic; text-align: center">
					"This is an electronically generated letter or invoice, no signature
					is required"
				</p>
			</div>
		</div>
		<!-- Invoice -->
		<div class="col-12 form-row m-4 justify-content-center">
			<div class="col-3 text-center">
				<button @click="printInvoice()" class="btn btn-info">
					<i class="fa fa-download"></i> Download
				</button>
			</div>
			<div v-if="invoice" class="col-3 text-center">
				<!-- <button
          class="btn btn-success"
          type="button"
          @click="sslPayment(invoice.id)"
        >
          Pay Now
        </button> -->

				<router-link
					v-if="
						invoice.status == 'pending' &&
						member.info &&
						member.info.status == 1
					"
					class="btn btn-success btn-sm"
					:to="{ name: 'paymentOption', params: { id: $route.params.id } }"
					>Check Out</router-link
				>
				<router-link
					v-else-if="
						member.info &&
						invoice.status == 'pending' &&
						invoice.account_head_id == 2 &&
						member.info.status == 2
					"
					class="btn btn-success btn-sm"
					:to="{ name: 'paymentOption', params: { id: $route.params.id } }"
					>Check Out</router-link
				>
				<router-link
					v-else-if="
						member.info &&
						invoice.status == 'pending' &&
						invoice.account_head_id == 2 &&
						member.info.status == 0
					"
					class="btn btn-success btn-sm"
					:to="{ name: 'paymentOption', params: { id: $route.params.id } }"
					>Check Out</router-link
				>
			</div>
		</div>
	</div>
</template>
<script>
	import sign from "../../../../assets/img/sign/HashimAhmed.png";
	export default {
		name: "Invoice",
		props: ["memberInfo"],
		data() {
			return {
				sign: this.$root.baseurl + sign,
				invoiceNo: null,
				barcode: null,
				invoice: {},
				member: {
					info: null,
				},
				total_amount: null,
			};
		},
		methods: {
			sslPayment: function (invoice_id) {
				return false;
				this.$root.spinner = true;
				axios
					.post("/ssl-payment", {
						type: "member",
						id: this.memberInfo.id,
						invoice_id: invoice_id,
					})
					.then((response) => {
						if (response.data.GatewayPageURL) {
							window.location.href = response.data.GatewayPageURL;
						}
					});
			},
			printInvoice() {
				this.$root.spinner = true;
				window.location.href =
					this.$root.baseurl +
					"/member/generate-invoice-pdf/" +
					this.$route.params.id;
				setTimeout(() => {
					this.$root.spinner = false;
				}, 200);
			},
			getInvoice: function () {
				this.$root.spinner = true;
				axios
					.get("/member/get-invoice/" + this.$route.params.id)
					.then((response) => {
						if (Object.keys(response.data).length > 0) {
							this.invoice = response.data.invoice;
							this.barcode = response.data.barcode;
							this.total_amount = response.data.invoice.amount;
						}
					})
					.catch((error) => {})
					.then((always) => {
						this.$root.spinner = false;
					});
			},
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		created() {
			this.loggedIn();
			// this.getInvoice();
		},
		mounted() {
			this.getInvoice();
		},
	};
</script>
<style>
	.invoice {
		/* height: 200px; */
		width: 680px;
		border: 1px solid #000000;
		margin: auto;
		padding: 20px;
	}

	.invoice .invoice-logo {
		width: 100%;
		/* border: 1px solid red; */
	}

	.invoice .invoice-logo img {
		/* height: 100px; */
		width: 100px;
	}

	.invoice .invoice-sec-1 {
		width: 100%;
		/* border: 1px solid red; */
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 60px;
		margin-bottom: 20px;
	}

	.invoice .invoice-sec-1 .invoice-sec-1-ref {
		/* border: 1px solid red; */
		width: 70%;
	}

	.invoice .invoice-sec-1 .invoice-sec-1-date {
		/* border: 1px solid red; */
		width: 30%;
		/* height: 100%;
	        display: flex;
	        flex-direction: column;
	        justify-content: start;
	        align-items: flex-start; */
	}

	.invoice .invoice-sec-1 .invoice-sec-1-date p {
		position: relative;
		top: -107px;
		text-align: right;
	}

	.invoice .invoice-sec-1 .to-invoice {
		margin-top: 45px;
		/* padding-left: 42px; */
	}

	.invoice-table {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 40px;
		/* border: 1px solid red; */
	}

	.invoice-table .invoice-table-container {
		width: 100%;
		/* border: 1px solid #000000; */
		margin: auto;
	}

	.invoice-table .invoice-table-container .invoice-table-data {
		display: flex;
		flex-direction: row;
		/* border: 1px solid #000000; */
	}

	.invoice-table .invoice-table-container .invoice-table-data .invoice-table-sl {
		text-align: center;
		width: 20%;
		border: 0.5px solid #000000;
		border-left: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-sl-h {
		border-left: 1px solid #000000 !important;
		border-top: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-desc-h {
		border-top: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-desc {
		text-align: center;
		width: 60%;
		border: 0.5px solid #000000;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-amount-h {
		border-top: 1px solid #000000 !important;
		border-right: 1px solid #000000 !important;
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-data
		.invoice-table-amount {
		text-align: center;
		width: 20%;
		border: 0.5px solid #000000;
		border-right: 1px solid #000000 !important;
	}

	.invoice-table .invoice-table-container .invoice-table-footer {
		border: 1px solid #000000;
		display: flex;
		flex-direction: row;
		border-top: 0.5px solid #000000 !important;
	}

	.invoice-table .invoice-table-container .invoice-table-footer .invoice-total {
		text-align: center;
		width: 80%;
		/* border: 1px solid #000000; */
	}

	.invoice-table
		.invoice-table-container
		.invoice-table-footer
		.invoice-total-amount {
		text-align: center;
		width: 20%;
		/* border: 1px solid #000000; */
	}

	p {
		padding: 0;
		/* margin: 4px; */
	}

	.invoice .invoice-banner {
		margin: 5px;
		width: 100%;
	}

	.invoice .invoice-banner .banner-d {
		width: 200px;
		border: 2px solid #000000;
		border-radius: 5px;
		margin: auto;
		padding: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.invoice .invoice-banner .banner-d p {
		font-weight: bold;
		margin: 0px;
	}

	.invoice .invoice-declaration {
		text-align: center;
	}

	.invoice .invoice-greeting {
		margin-top: 10px;
		margin-bottom: 60px;
		/* width: 200px; */
		/* border: 1px solid red; */
	}

	.invoice .invoice-greeting p {
		margin: 3px;
	}
	.note {
		padding-left: 20px;
		font-size: 14px;
		text-align: justify;
		margin-top: 8px;
	}

	.note div {
		font-size: 13px;
		margin-bottom: 7px !important;
	}
</style>
