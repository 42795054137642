var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.subCategory && Object.keys(_vm.subCategory).length > 0)?_c('div',{staticClass:"row"},_vm._l((_vm.subCategory),function(subcat,key){return _c('div',{key:key,staticClass:"col-lg-3 my-4"},[_c('div',{staticClass:"sub-category-product"},[_c('router-link',{attrs:{"to":{
          name: 'ecommerce.products',
          params: { slug: subcat.slug },

          query: {
            slug: _vm.category_slug,
            type: 'sub-category',
          },
        }}},[_c('div',{staticClass:"image"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(subcat.image),expression:"subcat.image"}],staticClass:"product-image",attrs:{"alt":""}})]),_vm._v(" "),_c('h5',{staticClass:"product-title"},[_vm._v(_vm._s(subcat.name))])]),_vm._v(" "),_c('router-link',{staticClass:"add-to-cart-button",attrs:{"to":{
          name: 'ecommerce.products',
          params: { slug: subcat.slug },
          query: {
            slug: _vm.category_slug,
            type: 'sub-category',
          },
        }}},[_vm._v("\n        View All\n        "),_c('i',{staticClass:"fa fa-long-arrow-right"})])],1)])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }