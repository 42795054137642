<template>
	<div>
		<div class="card sidebar shadow-sm mb-3">
			<div class="card-header">
				<div class="text-center">
					<p class="font-weight-bold" v-if="$root.variable.aLogId">
						<span class="text-danger">Login as:</span>
						<span class="text-success">{{ $root.variable.aLogName }}</span>
					</p>
				</div>
				<div class="user">
					<sup
						v-if="progress"
						:class="
							progress.totalProgress >= 50 ? 'badge-success' : 'badge-danger'
						"
						class="badge rounded-50 mt-2"
						>{{ progress.totalProgress }}% Completed</sup
					>
					<img
						class="memLogo"
						v-if="memberInfo && memberInfo.logo"
						:src="$root.baseurl + '/public' + memberInfo.logo"
						alt="logo"
					/>
					<img
						class="memLogo"
						v-else
						:src="
							$root.baseurl + require('./../../../../assets/img/no_logo.gif')
						"
					/>
					<div class="userName py-3">
						<h2 class="text-uppercase" v-if="memberInfo">
							{{ memberInfo.company_name }}
						</h2>
					</div>
				</div>
			</div>
		</div>
		<div class="card sidemenu shadow-sm mb-3">
			<div class="card-body p-0">
				<nav class="navbar navbar-expand-lg p-0">
					<button
						class="navbar-toggler m-2"
						type="button"
						data-toggle="collapse"
						data-target="#dashMenu"
						aria-controls="dashMenu"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span class="fas fa-bars pr-1"></span>
						Profile Menu
					</button>
					<div class="collapse navbar-collapse" id="dashMenu">
						<ul class="nav flex-column w-100">
							<li class="nav-item">
								<router-link :to="{ name: 'member-dashboard' }" class="nav-link"
									>Dashboard</router-link
								>
							</li>
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login && access && access['company'])
								"
								class="nav-item"
							>
								<router-link :to="{ name: 'EditCompanyInfo' }" class="nav-link">
									Company Information
									<sup
										v-if="progress.single"
										:class="
											progress.single.companyInfo >= 50
												? 'badge-success'
												: 'badge-danger'
										"
										class="badge rounded-50"
										>{{ progress.single.companyInfo }}%</sup
									>
								</router-link>
							</li>
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login &&
										access &&
										access['representative'])
								"
								class="nav-item"
							>
								<router-link
									:to="{ name: 'RepresentativeInformation' }"
									class="nav-link"
								>
									Representative Information
									<sup
										v-if="progress.single"
										:class="
											progress.single.representative >= 50
												? 'badge-success'
												: 'badge-danger'
										"
										class="badge rounded-50"
										>{{ progress.single.representative }}%</sup
									>
								</router-link>
							</li>
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login && access && access['business'])
								"
								class="nav-item"
							>
								<router-link
									:to="{ name: 'BusinessActivityInformation' }"
									class="nav-link"
								>
									Business Activity Information
									<sup
										v-if="progress.single"
										:class="
											progress.single.businessActivity >= 50
												? 'badge-success'
												: 'badge-danger'
										"
										class="badge rounded-50"
										>{{ progress.single.businessActivity }}%</sup
									>
								</router-link>
							</li>
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login && access && access['vertical_exp'])
								"
								class="nav-item"
							>
								<router-link
									:to="{ name: 'VerticalExperienceInformation' }"
									class="nav-link"
								>
									Vertical Experience Information
									<sup
										v-if="progress.single"
										:class="
											progress.single.verticalExpertise >= 50
												? 'badge-success'
												: 'badge-danger'
										"
										class="badge rounded-50"
										>{{ progress.single.verticalExpertise }}%</sup
									>
								</router-link>
							</li>
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login &&
										access &&
										access['product_service'])
								"
								class="nav-item"
							>
								<router-link
									:to="{ name: 'ProductsServices' }"
									class="nav-link"
								>
									Products & Services Information
									<sup
										v-if="progress.single"
										:class="
											progress.single.products >= 50
												? 'badge-success'
												: 'badge-danger'
										"
										class="badge rounded-50"
										>{{ progress.single.products }}%</sup
									>
								</router-link>
							</li>
							<!-- <li class="nav-item">
                <router-link :to="{ name:'HumanResource'}" class="nav-link">Human Resource Information</router-link>
							</li>-->
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login && access && access['compliance'])
								"
								class="nav-item"
							>
								<router-link :to="{ name: 'ComplianceInfo' }" class="nav-link">
									Compliance Information
									<sup
										v-if="progress.single"
										:class="
											progress.single.compliance >= 50
												? 'badge-success'
												: 'badge-danger'
										"
										class="badge rounded-50"
										>{{ progress.single.compliance }}%</sup
									>
								</router-link>
							</li>
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login && access && access['photos_file'])
								"
								class="nav-item"
							>
								<router-link
									:to="{ name: 'uploadPhotosFiles' }"
									class="nav-link"
								>
									Upload Photos & Files
									<sup
										v-if="progress.single"
										:class="
											progress.single.photoFiles >= 50
												? 'badge-success'
												: 'badge-danger'
										"
										class="badge rounded-50"
										>{{ progress.single.photoFiles }}%</sup
									>
								</router-link>
							</li>
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login &&
										access &&
										access['change_request'])
								"
								class="nav-item"
							>
								<router-link :to="{ name: 'changeRequest' }" class="nav-link"
									>Change Request</router-link
								>
							</li>
							<!-- <li class="nav-item">
                  <router-link
                    :to="{ name:'JoinCommitteeForum'}"
                    class="nav-link"
                  >Join on Committee/Forum</router-link>
							</li>-->
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login &&
										access &&
										access['payment_history'])
								"
								class="nav-item"
							>
								<router-link :to="{ name: 'PaymentHistory' }" class="nav-link"
									>Payment History</router-link
								>
							</li>
							<!-- <li class="nav-item">
								<router-link :to="{ name:'myServeice'}" class="nav-link">My Services</router-link>
							</li>-->
							<li class="nav-item" v-if="$root.variable.aLogId">
								<router-link :to="{ name: 'voterInfo' }" class="nav-link"
									>Voter Information</router-link
								>
							</li>
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login &&
										access &&
										access['online_helpdesk'])
								"
								class="nav-item"
							>
								<router-link :to="{ name: 'onlineHelpdesk' }" class="nav-link">
									Online Helpdesk
									<sup class="badge badge-danger rounded-50">
										{{ countHelpDesk }}
									</sup>
								</router-link>
							</li>
							<li
								v-if="
									$root.variable.rep_login ||
									$root.variable.aLogId ||
									($root.variable.alt_login && access && access['social_links'])
								"
								class="nav-item"
							>
								<router-link :to="{ name: 'memberSettings' }" class="nav-link"
									>Social Links</router-link
								>
							</li>
							<li class="nav-item">
								<router-link :to="{ name: 'changePassword' }" class="nav-link"
									>Change Password</router-link
								>
							</li>

							<li class="nav-item">
								<a
									style="cursor: pointer"
									class="nav-link"
									v-on:click="logout()"
									>Logout</a
								>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "LeftSide",
		props: ["memberInfo"],
		data() {
			return {
				countHelpDesk: 0,
				progress: {},
				access: {},
				loader: true,
			};
		},
		methods: {
			logout: function () {
				this.logoutMember();
			},
		},
		mounted() {
			axios.get("/member/getProgress").then((res) => (this.progress = res.data));
			axios
				.get("/member/getCountHelpDesk")
				.then((res) => (this.countHelpDesk = res.data));
			axios
				.get("member/get-represent-info/secondary")
				.then((res) => (this.access = res.data.access));

			this.loader = true;
		},
		updated() {
			if (this.$parent.member.info) {
				this.loader = false;
			}
		},
	};
</script>
