<template>
	<div
		class="member-services"
		v-if="
			(!$parent.loading && $root.access && $root.access['business']) ||
			$root.variable.rep_login ||
			$root.variable.aLogId
		"
	>
		<div class="card shadow-sm mb-3">
			<div class="card-header px-0">
				<div class="d-flex align-items-center justify-content-between">
					<h5 class="text-uppercase font-weight-bold px-3 mb-0">
						Business Activity Information
					</h5>
				</div>
			</div>
			<div class="card-body">
				<form v-on:submit.prevent="submit" id="form">
					<div class="form-row">
						<fieldset class="col-lg-12 border border-primary p-3 mb-3">
							<legend class="w-25 text-center">
								<small class="font-weight-bold"> Membership Type </small>
							</legend>
							<div class="form-row">
								<div class="col-md-12 mb-3">
									<h2 class="text-center text-success">
										{{ data.business_activity.applying_for }}
										Member
									</h2>

									<div
										class="custom-control custom-radio"
										v-if="$root.variable.aLogId"
									>
										<input
											type="radio"
											id="general"
											name="business_activity[applying_for]"
											class="custom-control-input"
											value="General"
											v-model="data.business_activity.applying_for"
										/>
										<label class="custom-control-label" for="general"
											>General Member</label
										>
									</div>
									<div
										class="custom-control custom-radio"
										v-if="$root.variable.aLogId"
									>
										<input
											type="radio"
											id="associate"
											name="business_activity[applying_for]"
											class="custom-control-input"
											value="Associate"
											v-model="data.business_activity.applying_for"
										/>
										<label class="custom-control-label" for="associate"
											>Associate Member</label
										>
									</div>
									<div
										class="custom-control custom-radio"
										v-if="$root.variable.aLogId"
									>
										<input
											type="radio"
											id="affiliate"
											name="business_activity[applying_for]"
											class="custom-control-input"
											value="Affiliate"
											v-model="data.business_activity.applying_for"
										/>
										<label class="custom-control-label" for="affiliate"
											>Affiliate Member</label
										>
									</div>
									<div
										class="custom-control custom-radio"
										v-if="$root.variable.aLogId"
									>
										<input
											type="radio"
											id="international"
											name="business_activity[applying_for]"
											class="custom-control-input"
											value="International"
											v-model="data.business_activity.applying_for"
										/>
										<label class="custom-control-label" for="international"
											>International Member</label
										>
									</div>
									<div class="invalid-feedback">
										{{
											validation.firstError(
												"data.business_activity.applying_for"
											)
										}}
									</div>
								</div>
							</div>
						</fieldset>

						<!-- Shareholder Informations only international member-->
						<slot v-if="member.info">
							<fieldset
								v-if="data.business_activity.applying_for == 'International'"
								class="col-12 border border-primary p-3 mb-3 mt-4"
							>
								<legend class="w-50 text-center">
									<small class="text-uppercase font-weight-bold"
										>Shareholder Informations</small
									>
								</legend>
								<div class="col-md-12">
									<div class="form-row">
										<div class="col-12 row mb-3">
											<div class="form-group col-lg-6">
												<label for="cv" class="col-form-label-sm m-0"
													>Shareholder / Company Name</label
												><span class="requierd-star"></span>
												<input
													:class="{
														'is-invalid': validation.hasError(
															'member.info.shareholder_primary.name'
														),
														'is-valid': member.info.shareholder_primary.name,
													}"
													name="shareholder_primary[name]"
													type="text"
													class="form-control shadow-none"
													placeholder="Name"
													onfocus="this.placeholder = ''"
													onblur="this.placeholder = 'Name'"
													v-model="member.info.shareholder_primary.name"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"member.info.shareholder_primary.name"
														)
													}}
												</div>
											</div>
											<div class="form-group col-lg-6">
												<label for="cv" class="col-form-label-sm m-0"
													>Percentage (%)</label
												><span class="requierd-star"></span>
												<input
													:class="{
														'is-invalid': validation.hasError(
															'member.info.shareholder_primary.percentage'
														),
														'is-valid':
															member.info.shareholder_primary.percentage,
													}"
													name="shareholder_primary[percentage]"
													type="number"
													class="form-control shadow-none"
													placeholder="10 / 20 / 30"
													onfocus="this.placeholder = ''"
													onblur="this.placeholder = '10 / 20 / 30'"
													v-model="member.info.shareholder_primary.percentage"
												/>
												<div class="invalid-feedback">
													{{
														validation.firstError(
															"member.info.shareholder_primary.percentage"
														)
													}}
												</div>
											</div>
											<div class="form-group col-lg-6">
												<label for="cv" class="col-form-label-sm m-0"
													>Is shareholder company listed in stock
													exchange?</label
												><span class="requierd-star"></span>
												<div class="row">
													<div class="col-4">
														<div class="custom-control custom-radio">
															<input
																type="radio"
																id="stock_exz1"
																name="shareholder_primary[stock_exchange]"
																class="custom-control-input"
																value="Yes"
																v-model="
																	member.info.shareholder_primary.stock_exchange
																"
															/>
															<label
																class="custom-control-label"
																for="stock_exz1"
																>Yes</label
															>
														</div>
													</div>
													<div class="col-2">
														<div class="custom-control custom-radio">
															<input
																type="radio"
																id="stock_exz2"
																name="shareholder_primary[stock_exchange]"
																class="custom-control-input"
																value="No"
																v-model="
																	member.info.shareholder_primary.stock_exchange
																"
															/>
															<label
																class="custom-control-label"
																for="stock_exz2"
																>No</label
															>
														</div>
													</div>

													<div class="invalid-feedback">
														{{
															validation.firstError(
																"member.info.shareholder_primary.stock_exchange"
															)
														}}
													</div>
												</div>
											</div>

											<div class="form-group col-lg-6">
												<label for="cv" class="col-form-label-sm m-0">
													Country name of the stock exchange
												</label>
												<input
													:name="'shareholder_primary[country]'"
													type="text"
													class="form-control shadow-none"
													placeholder="USA, UK, London"
													onfocus="this.placeholder = ''"
													onblur="this.placeholder = 'USA, UK, London'"
													v-model="member.info.shareholder_primary.country"
												/>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</slot>

						<!-- Area of Business Activity -->
						<fieldset class="col-lg-12 border border-primary p-3 mb-3">
							<legend class="w-25 text-center">
								<small class="font-weight-bold">
									Area of Business Activity
									<span class="requierd-star"></span>
								</small>
							</legend>
							<div class="form-row">
								<div
									class="col-md-6"
									v-for="(bActivity, key) in extraData.businessActivities"
									:key="key"
								>
									<div class="custom-control custom-checkbox">
										<b-form-checkbox
											:name="
												'business_activity[activities][' + bActivity.id + ']'
											"
											class="col-form-label-sm font-weight-bold mb-0"
											:value="bActivity.id"
											v-model="data.business_activity.activities[bActivity.id]"
											:unchecked-value="false"
											>{{ bActivity.title }}</b-form-checkbox
										>
									</div>
								</div>
							</div>
							<!-- <div class="form-row">
                <div
                  v-for="(activityName, index) in data.business_activity.new_activities"
                  :key="index"
                  class="form-row col-12 mb-2"
                >
                  <div class="col-7 col-lg-4">
                    <input
                      :name="'business_activity[new_activities]['+index+'][activity]'"
                      type="text"
                      class="form-control shadow-none"
                      placeholder="New Activity"
                      v-model="activityName.activity"
                    />
                  </div>
                  <div class="col-5 col-lg-4" v-if="index>0">
                    <button
                      @click="data.business_activity.new_activities.splice(index,1)"
                      class="btn btn-sm btn-danger"
                      type="button"
                    >
                      <span class="fas fa-times"></span>
                    </button>
                  </div>
                  <div class="col-5 col-lg-4" v-if="index==0">
                    <button
                      v-if="Object.keys(data.business_activity.new_activities).length<5"
                      @click="data.business_activity.new_activities.push({})"
                      class="btn btn-sm btn-success"
                      type="button"
                    >Add More</button>
                  </div>
                </div>
              </div>-->
						</fieldset>

						<!-- Affiliate Category-->
						<!-- Affiliate Category-->
						<fieldset
							v-if="data.business_activity.applying_for == 'Affiliate'"
							class="col-lg-12 border border-primary p-3 mb-3"
						>
							<legend class="w-75 text-center">
								<small class="font-weight-bold">
									Which of the following category or platform is your main
									activity?
									<span class="requierd-star"></span>
								</small>
							</legend>
							<div class="form-row">
								<div
									class="col-md-6"
									v-for="(aCategory, key) in affiliate_category_list"
									:key="key"
								>
									<div class="custom-control custom-radio mb-1">
										<input
											type="radio"
											:id="'affiliate_category' + key"
											name="'business_activity[affiliate_category]'"
											class="custom-control-input"
											:value="aCategory.name"
											v-model="data.affiliate_category"
										/>
										<label
											style="font-size: 14px"
											class="custom-control-label"
											:for="'affiliate_category' + key"
											>{{ aCategory.name }}</label
										>
									</div>
								</div>

								<div class="col-md-6">
									<div class="custom-control custom-radio mb-1">
										<input
											type="radio"
											id="affiliate_category00"
											name="affiliate_category"
											class="custom-control-input"
											value="Other"
											v-model="data.affiliate_category"
										/>
										<label
											style="font-size: 14px"
											class="custom-control-label"
											for="affiliate_category00"
										>
											Other
										</label>
									</div>
								</div>
								<div v-if="data.affiliate_category == 'Other'" class="col-md-6">
									<input
										type="text"
										name="affiliate_category_other"
										class="form-control form-control-sm"
										placeholder="Category Name"
										v-model="data.affiliate_category_other"
									/>
								</div>
								<div class="invalid-feedback">
									{{ validation.firstError("data.affiliate_category") }}
								</div>
							</div>
						</fieldset>
					</div>
					<fieldset class="col-lg-12 border border-primary p-3 mb-3">
						<legend class="w-50 text-center">
							<small class="font-weight-bold"
								>Primary Business Activity (Maximum 3 allowed)</small
							>
						</legend>
						<div class="row justify-content-center">
							<div class="col-lg-5 mb-3">
								<div class="form-row mb-3">
									<label class="col-md-3">
										Priority 1
										<span class="requierd-star"></span>
									</label>
									<div class="col-md-9">
										<select
											:class="{
												'is-invalid': validation.hasError(
													'data.primary_activity.priority1'
												),
												'is-valid': data.primary_activity.priority1,
											}"
											v-model="data.primary_activity.priority1"
											class="form-control"
										>
											<option value>Select one</option>
											<option
												v-for="(
													activity, index
												) in extraData.businessActivities"
												:key="index"
												:value="activity.id"
											>
												{{ activity.title }}
											</option>
										</select>
									</div>
								</div>
								<div class="form-row mb-3">
									<label class="col-md-3">
										Priority 2
										<span class="requierd-star"></span>
									</label>
									<div class="col-md-9">
										<select
											:class="{
												'is-invalid': validation.hasError(
													'data.primary_activity.priority2'
												),
												'is-valid': data.primary_activity.priority2,
											}"
											v-model="data.primary_activity.priority2"
											class="form-control"
										>
											<option value>Select one</option>
											<option
												v-for="(
													activity, index
												) in extraData.businessActivities"
												:key="index"
												:value="activity.id"
											>
												{{ activity.title }}
											</option>
										</select>
									</div>
								</div>
								<div class="form-row mb-3">
									<label class="col-md-3">
										Priority 3
										<span class="requierd-star"></span>
									</label>
									<div class="col-md-9">
										<select
											:class="{
												'is-invalid': validation.hasError(
													'data.primary_activity.priority3'
												),
												'is-valid': data.primary_activity.priority3,
											}"
											v-model="data.primary_activity.priority3"
											class="form-control"
										>
											<option value>Select one</option>
											<option
												v-for="(
													activity, index
												) in extraData.businessActivities"
												:key="index"
												:value="activity.id"
											>
												{{ activity.title }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</fieldset>

					<fieldset class="col-lg-12 border border-primary p-3 mb-3">
						<legend class="text-center">
							<small class="font-weight-bold">
								If you are an IT Enabled Service (ITES) provider please fill in
								the following additional categories
								<span class="requierd-star"></span>
							</small>
						</legend>
						<div class="row">
							<div
								class="col-md-6"
								v-for="(category, key) in extraData.categories"
								:key="key"
							>
								<div class="custom-control custom-checkbox">
									<b-form-checkbox
										:name="'category_list[categories][' + category.id + ']'"
										class="col-form-label-sm font-weight-bold mb-0"
										v-model="data.category_list[category.id]"
										:value="category.id"
										:unchecked-value="false"
										>{{ category.title }}</b-form-checkbox
									>
								</div>
							</div>
							<div class="w-100"></div>

							<!-- <div
                v-for="(othCategory, index1) in data.new_category_list"
                :key="'A'+index1"
                class="form-row col-12 mb-2"
              >
                <div class="col-7 col-lg-4">
                  <input
                    :name="'new_category[category]['+index1+'][category]'"
                    type="text"
                    class="form-control shadow-none"
                    placeholder="New Category"
                    v-model="othCategory.category"
                  />
                </div>
                <div class="col-5 col-lg-4" v-if="index1>0">
                  <button
                    @click="data.new_category_list.splice(index,1)"
                    class="btn btn-sm btn-danger"
                    type="button"
                  >
                    <span class="fas fa-times"></span>
                  </button>
                </div>
                <div class="col-5 col-lg-4" v-if="index1==0">
                  <button
                    @click="data.new_category_list.push({})"
                    class="btn btn-sm btn-success"
                    type="button"
                    v-if="Object.keys(data.new_category_list).length<5"
                  >Add More</button>
                </div>
              </div>-->
						</div>
					</fieldset>

					<!-- <fieldset class="col-lg-12 border border-primary p-3 mb-3">
              <legend class="w-25 text-center">
                <small class="font-weight-bold">Infrastructure Information</small>
              </legend>
              <div class="row justify-content-center">
                <div class="col-lg-6 mb-3">
                  <div class="form-row mb-3">
                    <label for="Modes_of_internet_connectivity">Modes of internet connectivity</label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputEmail3"
                      placeholder="Modes of internet connectivity"
                    />
                  </div>
                  <div class="form-row mb-3">
                    <label for="Bandwith">Bandwith</label>
                    <input type="text" class="form-control" id="inputEmail3" placeholder="Bandwith" />
                  </div>
                  <div class="row mb-3">
                    <label
                      for="interested_to_move_in_to_any_STP"
                    >Are you interested to move any STP like the BDBL or Janata Tower</label>
                    <div class="custom-control custom-radio col-lg-6 text-center">
                      <input
                        type="radio"
                        id="general11"
                        class="custom-control-input"
                        value="General11"
                      />
                      <label class="custom-control-label" for="general11">Yes</label>
                    </div>
                    <div class="custom-control custom-radio col-lg-6">
                      <input
                        type="radio"
                        id="general114"
                        class="custom-control-input"
                        value="General114"
                      />
                      <label class="custom-control-label" for="general114">No</label>
                    </div>
                  </div>
                  <div class="form-row mb-3">
                    <label for="preferable_for_you">Which area is preferable for you</label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputEmail3"
                      placeholder="Which area is preferable for you"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="form-row mb-3">
                    <label
                      for="regardless_the_area_of_your_choices"
                    >Will you move in regardless the area of your choices</label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputEmail3"
                      placeholder="Modes of internet connectivity"
                    />
                  </div>
                  <div class="form-row mb-3">
                    <label for="Current_office_size">Current office size (SQFT)</label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputEmail3"
                      placeholder="Current office size (SQFT)"
                    />
                  </div>
                  <div class="form-row mb-3">
                    <label for="looking_for_in_STP">How much space you are looking for in STP (SQFT)</label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputEmail3"
                      placeholder="STP (SQFT)"
                    />
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset class="col-lg-12 border border-primary p-3 mb-3">
              <legend class="w-50 text-center">
                <small class="font-weight-bold">Participation in promotional activities:</small>
              </legend>
              <div class="row justify-content-center">
                <div class="col-lg-8 mb-3">
                  <div class="form-row mb-3">
                    <label
                      for="product_services_in_Basis_catalogues"
                      class="col-lg-12"
                    >Will you be interested in industry specific fairs</label>
                    <div class="custom-control custom-radio col-lg-6 text-center">
                      <input
                        type="radio"
                        id="general11"
                        class="custom-control-input"
                        value="General11"
                      />
                      <label class="custom-control-label" for="general11">Yes</label>
                    </div>
                    <div class="custom-control custom-radio col-lg-6">
                      <input
                        type="radio"
                        id="general114"
                        class="custom-control-input"
                        value="General114"
                      />
                      <label class="custom-control-label" for="general114">No</label>
                    </div>
                  </div>

                  <div class="form-row mb-3">
                    <label for="if_yes col-lg-5">(If yes) in which one :</label>
                    <div class="col-lg-7">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1" />
                        <label class="custom-control-label" for="customCheck1">BGMEA & BKMEA</label>
                      </div>
                      <label for="blank-1"></label>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1" />
                        <label class="custom-control-label" for="customCheck1">REHABB</label>
                      </div>
                      <label for="blank-2"></label>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1" />
                        <label
                          class="custom-control-label"
                          for="customCheck1"
                        >Banking, Insurance and Financials</label>
                      </div>
                      <label for="blank-3"></label>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1" />
                        <label class="custom-control-label" for="customCheck1">Pharmaceuticals</label>
                      </div>
                    </div>
                  </div>

                  <div class="form-row mb-3">
                    <label
                      for="product_services_in_Basis_catalogues"
                      class="col-lg-12"
                    >Will you be interested to list you product/ services in Basis catalogues</label>
                    <div class="custom-control custom-radio col-lg-6 text-center">
                      <input
                        type="radio"
                        id="general11"
                        class="custom-control-input"
                        value="General11"
                      />
                      <label class="custom-control-label" for="general11">Yes</label>
                    </div>
                    <div class="custom-control custom-radio col-lg-6">
                      <input
                        type="radio"
                        id="general114"
                        class="custom-control-input"
                        value="General114"
                      />
                      <label class="custom-control-label" for="general114">No</label>
                    </div>
                  </div>
                </div>
              </div>
          </fieldset>-->

					<fieldset class="col-lg-12 border border-primary p-3 mb-3">
						<legend class="text-center">
							<small class="font-weight-bold"
								>Please specify 5 of your industry expertise (Based on your
								successful installations/services)</small
							>
						</legend>
						<div class="row mb-2 justify-content-center">
							<div class="row col-12 mb-3">
								<div class="col-md-2">Order of Expertise</div>
								<div class="col-md-4">Industry</div>
								<div class="col-md-3">Number of Clients</div>
								<div class="col-md-3">Remarks</div>
							</div>
							<div class="row col-12 mb-3">
								<label class="col-md-2">
									1st Expertise
									<span class="requierd-star"></span>
								</label>
								<div class="col-md-4">
									<select
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.industry1'
											),
											'is-valid': data.industry_expertise.industry1,
										}"
										v-model="data.industry_expertise.industry1"
										class="form-control"
									>
										<option value>Select one</option>
										<option
											v-for="(industry, index) in extraData.industryLists"
											:key="index"
											:value="industry.id"
										>
											{{ industry.name }}
										</option>
									</select>
								</div>
								<div class="col-md-3">
									<input
										type="number"
										v-model.number="data.industry_expertise.clients1"
										class="form-control"
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.clients1'
											),
											'is-valid': data.industry_expertise.clients1,
										}"
										placeholder="e.g. 05"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 05'"
									/>
								</div>
								<div class="col-md-3">
									<input
										type="text"
										v-model="data.industry_expertise.remark1"
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.remark1'
											),
											'is-valid': data.industry_expertise.remark1,
										}"
										class="form-control"
										placeholder="e.g. Remark"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Remark'"
									/>
								</div>
							</div>
							<div class="row col-12 mb-3">
								<label class="col-md-2">2nd Expertise</label>
								<div class="col-md-4">
									<select
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.industry2'
											),
											'is-valid': data.industry_expertise.industry2,
										}"
										v-model="data.industry_expertise.industry2"
										class="form-control"
									>
										<option value>Select one</option>
										<option
											v-for="(industry, id, index) in extraData.industryLists"
											:key="index"
											:value="industry.id"
										>
											{{ industry.name }}
										</option>
									</select>
								</div>
								<div class="col-md-3">
									<input
										type="number"
										v-model.number="data.industry_expertise.clients2"
										class="form-control"
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.clients2'
											),
											'is-valid': data.industry_expertise.clients2,
										}"
										placeholder="e.g. 05"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 05'"
									/>
								</div>
								<div class="col-md-3">
									<input
										type="text"
										v-model="data.industry_expertise.remark2"
										class="form-control"
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.remark2'
											),
											'is-valid': data.industry_expertise.remark2,
										}"
										placeholder="e.g. Remark"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Remark'"
									/>
								</div>
							</div>
							<div class="row col-12 mb-3">
								<label class="col-md-2">3rd Expertise</label>
								<div class="col-md-4">
									<select
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.industry3'
											),
											'is-valid': data.industry_expertise.industry3,
										}"
										v-model="data.industry_expertise.industry3"
										class="form-control"
									>
										<option value>Select one</option>
										<option
											v-for="(industry, id, index) in extraData.industryLists"
											:key="index"
											:value="industry.id"
										>
											{{ industry.name }}
										</option>
									</select>
								</div>
								<div class="col-md-3">
									<input
										type="number"
										v-model.number="data.industry_expertise.clients3"
										class="form-control"
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.clients3'
											),
											'is-valid': data.industry_expertise.clients3,
										}"
										placeholder="e.g. 05"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 05'"
									/>
								</div>
								<div class="col-md-3">
									<input
										type="text"
										v-model="data.industry_expertise.remark3"
										class="form-control"
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.remark3'
											),
											'is-valid': data.industry_expertise.remark3,
										}"
										placeholder="e.g. Remark"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Remark'"
									/>
								</div>
							</div>
							<div class="row col-12 mb-3">
								<label class="col-md-2">4th Expertise</label>
								<div class="col-md-4">
									<select
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.industry4'
											),
											'is-valid': data.industry_expertise.industry4,
										}"
										v-model="data.industry_expertise.industry4"
										class="form-control"
									>
										<option value>Select one</option>
										<option
											v-for="(industry, id, index) in extraData.industryLists"
											:key="index"
											:value="industry.id"
										>
											{{ industry.name }}
										</option>
									</select>
								</div>
								<div class="col-md-3">
									<input
										type="number"
										v-model.number="data.industry_expertise.clients4"
										class="form-control"
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.clients4'
											),
											'is-valid': data.industry_expertise.clients4,
										}"
										placeholder="e.g. 05"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 05'"
									/>
								</div>
								<div class="col-md-3">
									<input
										type="text"
										v-model="data.industry_expertise.remark4"
										class="form-control"
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.remark4'
											),
											'is-valid': data.industry_expertise.remark4,
										}"
										placeholder="e.g. Remark"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Remark'"
									/>
								</div>
							</div>
							<div class="row col-12 mb-3">
								<label class="col-md-2">5th Expertise</label>
								<div class="col-md-4">
									<select
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.industry5'
											),
											'is-valid': data.industry_expertise.industry5,
										}"
										v-model="data.industry_expertise.industry5"
										class="form-control"
									>
										<option value>Select one</option>
										<option
											v-for="(industry, id, index) in extraData.industryLists"
											:key="index"
											:value="industry.id"
										>
											{{ industry.name }}
										</option>
									</select>
								</div>
								<div class="col-md-3">
									<input
										type="number"
										v-model.number="data.industry_expertise.clients5"
										class="form-control"
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.clients5'
											),
											'is-valid': data.industry_expertise.clients5,
										}"
										placeholder="e.g. 5"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 5'"
									/>
								</div>
								<div class="col-md-3">
									<input
										type="text"
										v-model="data.industry_expertise.remark5"
										:class="{
											'is-invalid': validation.hasError(
												'data.industry_expertise.remark5'
											),
											'is-valid': data.industry_expertise.remark5,
										}"
										class="form-control"
										placeholder="e.g. Remark"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Remark'"
									/>
								</div>
							</div>
						</div>
					</fieldset>

					<fieldset class="col-lg-12 border border-primary p-3 mb-3">
						<legend class="text-center">
							<small class="font-weight-bold"
								>International presence (Only for those companies which have
								international offices)</small
							>
						</legend>
						<div class="row mb-2 justify-content-center">
							<div class="col-lg-12">
								<div class="form-row mb-3">
									<label for="comments">County (List all the country/s)</label>
									<input
										type="text"
										class="form-control shadow-none"
										placeholder="e.g. USA, UK, Canada"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. USA, UK, Canada'"
										:class="{
											'is-invalid': validation.hasError(
												'member.info.international_presence_countries'
											),
											'is-valid': member.info.international_presence_countries,
										}"
										v-model="member.info.international_presence_countries"
										name="country_list"
									/>
								</div>
							</div>
						</div>

						<div
							class="row mb-5"
							v-for="(countryList, index) in data.countryLists"
							:key="index"
						>
							<div class="col-lg-6 mb-3">
								<div class="form-row mb-3">
									<label for="comments">Country</label>
									<input
										type="text"
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError('countryList.country'),
											'is-valid': countryList.country,
										}"
										placeholder="e.g. Bangladesh"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Bangladesh'"
										:name="'international_presence[' + index + '][country]'"
										v-model="countryList.country"
									/>
								</div>
								<div class="form-row mb-3">
									<label for="comments"
										>Name of the company in that country</label
									>
									<input
										type="text"
										class="form-control shadow-none"
										placeholder="e.g. Nogor Solutions ltd"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Nogor Solution ltd'"
										:class="{
											'is-invalid': validation.hasError(
												'countryList.company_name'
											),
											'is-valid': countryList.company_name,
										}"
										v-model="countryList.company_name"
										:name="
											'international_presence[' + index + '][company_name]'
										"
									/>
								</div>
								<div class="form-row mb-3">
									<label for="comments">Address</label>
									<input
										type="text"
										class="form-control shadow-none"
										placeholder="e.g. 24/7 Down street, Newyork"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 24/7 Down Street, Newyork'"
										:class="{
											'is-invalid': validation.hasError('countryList.address'),
											'is-valid': countryList.address,
										}"
										v-model="countryList.address"
										:name="'international_presence[' + index + '][address]'"
									/>
								</div>
							</div>
							<div class="col-lg-6 mb-3">
								<div class="form-row mb-3">
									<label for="comments">Phone</label>
									<input
										type="text"
										class="form-control shadow-none"
										placeholder="e.g. 01xxxxxxxx"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 01xxxxxxxx'"
										:class="{
											'is-invalid': validation.hasError('countryList.phone'),
											'is-valid': countryList.phone,
										}"
										v-model="countryList.phone"
										:name="'international_presence[' + index + '][phone]'"
									/>
								</div>
								<div class="form-row mb-3">
									<label for="comments">FAX</label>
									<input
										type="text"
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError('countryList.fax'),
											'is-valid': countryList.fax,
										}"
										placeholder="e.g. 01xxxxxxxx"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 01xxxxxxxx'"
										v-model="countryList.fax"
										:name="'international_presence[' + index + '][fax]'"
									/>
								</div>
								<div class="form-row mb-3">
									<label for="comments">Email</label>
									<input
										type="email"
										class="form-control shadow-none"
										:class="{
											'is-invalid': validation.hasError('countryList.email'),
											'is-valid': countryList.email,
										}"
										placeholder="e.g. yourname@mail.com"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. yourname@mail.com'"
										v-model="countryList.email"
										:name="'international_presence[' + index + '][email]'"
									/>
									<div class="invalid-feedback">
										{{ validation.firstError("countryList.email") }}
									</div>
								</div>
							</div>
							<div class="col-lg-1" v-if="index > 0">
								<button
									@click="data.countryLists.splice(index, 1)"
									class="btn btn-sm btn-danger w-100"
									type="button"
								>
									<span class="fas fa-times"></span>
								</button>
							</div>
						</div>

						<div class="row mb-2 float-right">
							<div class="col-12">
								<button
									@click="data.countryLists.push({})"
									class="btn btn-sm btn-success"
									type="button"
								>
									Add More
								</button>
							</div>
						</div>
					</fieldset>

					<div class="row justify-content-center">
						<button class="btn btn-success" type="submit">
							Update Business Activity Information
						</button>
					</div>
				</form>
				<!-- <pre>{{data}}</pre> -->
				<!-- <pre>{{ extraData.categories }}</pre> -->

				<!-- <pre>{{ validation.allErrors() }}</pre> -->
				<!-- <pre>{{member.info.international_presence_countries}}</pre> -->
			</div>
		</div>
	</div>
	<div v-else class="row">
		<div class="col-12">
			<div class="card">
				<div v-if="Object.keys($root.access).length > 0" class="card-body">
					<h4 class="text-center text-danger">
						Sorry!! you cannot access this page
					</h4>
				</div>
				<div v-else class="card-body">
					<div
						v-if="!$parent.loading"
						class="
							card-body
							d-flex
							align-items-center
							justify-content-center
							flex-column
						"
					>
						<i
							class="fa fa-spinner fa-spin"
							style="font-size: 24px; color: rgb(0, 127, 62)"
						></i>
						<span class="processing">processing</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	const breadcumb = [
		{
			title: "Business Activity Information",
		},
	];
	export default {
		name: "businessActivityForm",
		props: ["memberInfo"],
		data() {
			return {
				breadcumb: breadcumb,
				breadcrumbTitle: "Business Activity Information",
				affiliate_category_list: [],
				extraData: {
					businessActivities: [],
					categories: [],
					industryLists: [],
				},
				data: {
					affiliate_category: "",
					category_list: {},
					new_category_list: {},
					shareholder_primary: {},
					primary_activity: {
						priority1: "",
						priority2: "",
						priority3: "",
					},
					industry_expertise: {
						industry1: "",
						industry2: "",
						industry3: "",
						industry4: "",
						industry5: "",
					},
					business_activity: {
						activities: {},
						new_activities: [{}],
						applying_for: "Associate",
					},
					international_presence: null,
					countryLists: [{}],
				},
				member: {
					info: {},
				},
				errors: {},
			};
		},
		methods: {
			submit: function (e) {
				if (confirm("Are you confirming, is the provided information valid?")) {
					this.$validate().then((res) => {
						if (this.validation.countErrors() > 0) {
							this.$toastr(
								"warning",
								'You need to fill <span class="font-weight-bold">' +
									this.validation.countErrors() +
									" more empty</span> mandatory fields",
								"Empty Mandatory Fields!!"
							);
						} else {
							if (res) {
								if (!this.$root.variable.aLogId) {
									if (
										this.data.business_activity &&
										Object.keys(this.data.business_activity.activities).length ==
											0
									) {
										this.$toastr(
											"warning",
											'Please select <span class="font-weight-bold">minimum 1 business activity</span>',
											"Business Activity !!"
										);
										return false;
									}

									if (Object.keys(this.data.category_list).length == 0) {
										this.$toastr(
											"warning",
											'Please select <span class="font-weight-bold">minimum 1 ITES</span>',
											"IT Enabled Service (ITES)!!"
										);
										return false;
									}
								}
								this.scrollTop();
								this.$parent.loading = true;
								this.data.international_presence =
									this.member.info.international_presence_countries;
								this.data.shareholder_primary =
									this.member.info.shareholder_primary;
								this.data.adminId = this.$root.variable.aLogId;
								axios
									.post("member/BusinessActivityInfo", this.data)
									.then((response) => {
										this.$toastr("success", "Update Successfully", "Success!!");
										// location.reload();
									})
									.catch((error) => {
										if (error.response.status === 422) {
											this.errors = error.response.data.errors || {};
											this.focus_field = Object.keys(this.errors)[0];
										}
									})
									.then((always) => {
										setTimeout(
											() => (this.$parent.loading = false),
											this.$root.minloadtimefd
										);
									});
							}
						}
					});
				}
			},
			getDatas() {
				// country list
				axios.get("member/get-country-list").then((response) => {
					if (Object.keys(response.data).length > 0) {
						this.data.countryLists = response.data;
					}
				});
				// all Primary Activity List
				axios
					.get("/member/getActivityList")
					.then((response) => {
						this.data.primary_activity = response.data;
					})
					.catch((error) => {})
					.then((always) => {
						setTimeout(
							() => (this.$parent.loading = false),
							this.$root.minloadtimefd
						);
					});
				// all Business Activity List
				axios
					.get("/member/getBusinessActivityList")
					.then((response) => {
						this.data.business_activity = response.data;
					})
					.catch((error) => {})
					.then((always) => {
						setTimeout(
							() => (this.$parent.loading = false),
							this.$root.minloadtimefd
						);
					});
				// all Industry Expertise List
				axios
					.get("/member/getIndustryExpertise")
					.then((response) => {
						this.data.industry_expertise = response.data;
					})
					.catch((error) => {})
					.then((always) => {
						setTimeout(
							() => (this.$parent.loading = false),
							this.$root.minloadtimefd
						);
					});
				// all category List
				axios
					.get("/member/getCategoryList")
					.then((response) => {
						this.data.category_list = response.data;
					})
					.catch((error) => {})
					.then((always) => {
						setTimeout(
							() => (this.$parent.loading = false),
							this.$root.minloadtimefd
						);
					});
				// new category List
				axios
					.get("/member/getNewCategoryList")
					.then((response) => {
						this.data.new_category_list = response.data;
					})
					.catch((error) => {})
					.then((always) => {
						setTimeout(
							() => (this.$parent.loading = false),
							this.$root.minloadtimefd
						);
					});

				// all Industry Expertise List
				axios.get("/member/info").then((res) => {
					this.data.affiliate_category = res.data.affiliate_category;
					this.data.affiliate_category_other = res.data.affiliate_category_other;
				});
				// business Activities
				this.getList("BusinessActivity", "businessActivities", true);
				// all Category
				this.getList("Category", "categories", true);
				// all Industry List
				this.getList("IndustryList", "industryLists", true);

				axios.get("/admin/getAllAffiliateCategoryData").then((response) => {
					this.affiliate_category_list = response.data;
				});
			},
		},
		mounted() {
			this.loggedIn();
			this.getDatas();
		},
		validators: {
			"data.affiliate_category": function (value = null) {
				if (
					!this.$root.variable.aLogId &&
					this.data.business_activity.applying_for == "Affiliate"
				) {
					return Validator.value(value).required();
				}
			},
			"data.industry_expertise.industry1": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value).required();
				}
			},
			"data.industry_expertise.clients1": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value).required();
				}
			},
			"data.industry_expertise.clients2": function (value = null) {
				if (!this.$root.variable.aLogId) {
					if (
						this.data.industry_expertise.industry2 &&
						!this.data.industry_expertise.clients2
					) {
						return Validator.value(value).required();
					} else {
						return Validator.value(value);
					}
				}
			},
			"data.industry_expertise.clients3": function (value = null) {
				if (!this.$root.variable.aLogId) {
					if (
						this.data.industry_expertise.industry3 &&
						!this.data.industry_expertise.clients3
					) {
						return Validator.value(value).required();
					} else {
						return Validator.value(value);
					}
				}
			},
			"data.industry_expertise.clients4": function (value = null) {
				if (!this.$root.variable.aLogId) {
					if (
						this.data.industry_expertise.industry4 &&
						!this.data.industry_expertise.clients4
					) {
						return Validator.value(value).required();
					} else {
						return Validator.value(value);
					}
				}
			},
			"data.industry_expertise.clients5": function (value = null) {
				if (!this.$root.variable.aLogId) {
					if (
						this.data.industry_expertise.industry5 &&
						!this.data.industry_expertise.clients5
					) {
						return Validator.value(value).required();
					} else {
						return Validator.value(value);
					}
				}
			},

			"data.primary_activity.priority1": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value).required();
				}
			},
			"data.primary_activity.priority2": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value).required();
				}
			},
			"data.primary_activity.priority3": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value).required();
				}
			},
			"countryList.email": function (value = null) {
				if (!this.$root.variable.aLogId) {
					return Validator.value(value).email();
				}
			},
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
	};
</script>

