<template>
  <div
    class="member-services"
    v-if="
      (!$parent.loading && $root.access && $root.access['company']) ||
      $root.variable.rep_login ||
      $root.variable.aLogId
    "
  >
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">
            Company Information
          </h5>
        </div>
      </div>
      <div class="card-body">
        <div class="row align-items-center justify-content-end mb-4"></div>
        <form v-on:submit.prevent="submit" id="form">
          <input
            type="hidden"
            name="logged_id"
            v-model="$root.variable.aLogId"
          />
          <div class="row">
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Company Name
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  v-if="member.info"
                  required
                  type="text"
                  disabled
                  class="form-control shadow-none disable"
                  :class="{
                    'is-invalid': validation.hasError(
                      'member.info.company_name'
                    ),
                    'is-valid': member.info.company_name,
                  }"
                  placeholder="e.g. Nogor Solutions ltd"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. Nogor Soltions ltd'"
                  v-model="member.info.company_name"
                  name="company_name"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.company_name") }}
                </div>
              </div>
              <div class="form-row mb-3">
                <label for="comments"
                  >Membership with other associations and chambers</label
                >
                <input
                  v-if="member.info"
                  type="text"
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid': validation.hasError(
                      'member.info.other_org_membership'
                    ),
                    'is-valid': member.info.other_org_membership,
                  }"
                  placeholder="e.g. ECAB, BACCO, ISPAB, BCS"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. ECAB, BACCO, ISPAB, BCS'"
                  v-model="member.info.other_org_membership"
                  name="other_org_membership"
                />
                <div class="invalid-feedback">
                  {{
                    validation.firstError("member.info.other_org_membership")
                  }}
                </div>
              </div>
              <div class="form-row form-row-2 mb-3">
                <label for="comments">
                  Short Profile
                  <span class="text-danger requierd-star"></span>
                </label>
                <textarea
                  v-if="member.info"
                  class="form-control"
                  placeholder="e.g. IT Solutions"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. IT solution'"
                  :class="{
                    'is-invalid': validation.hasError(
                      'member.info.short_profile'
                    ),
                    'is-valid': member.info.short_profile,
                  }"
                  v-model="member.info.short_profile"
                  name="short_profile"
                ></textarea>
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.short_profile") }}
                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col-md-6">
                  <label for="comments">
                    Establishment Month
                    <span class="text-danger requierd-star"></span>
                  </label>
                  <select
                    v-if="member.info"
                    class="form-control"
                    :class="{
                      'is-invalid': validation.hasError(
                        'member.info.establishment_month'
                      ),
                      'is-valid': member.info.establishment_month,
                    }"
                    v-model="member.info.establishment_month"
                    name="establishment_month"
                    required
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March" selected>March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                  <div class="invalid-feedback">
                    {{
                      validation.firstError("member.info.establishment_month")
                    }}
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="comments">
                    Establishment Year
                    <span class="text-danger requierd-star"></span>
                  </label>
                  <select
                    v-if="member.info"
                    class="form-control"
                    :class="{
                      'is-invalid': validation.hasError(
                        'member.info.establishment_year'
                      ),
                      'is-valid': member.info.establishment_year,
                    }"
                    v-model.number="member.info.establishment_year"
                    name="establishment_year"
                    required
                  >
                    <option
                      v-if="member.info.establishment_year"
                      :value="member.info.establishment_year"
                      selected
                    >
                      {{ member.info.establishment_year }}
                    </option>
                    <option v-else value>Select one</option>
                    <option
                      v-for="(value, name, index) in years"
                      :key="index"
                      v-bind:value="value"
                    >
                      {{ value }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{
                      validation.firstError("member.info.establishment_year")
                    }}
                  </div>
                </div>
              </div>
              <div class="form-row mb-3" v-if="$root.variable.aLogId">
                <label for="comments">
                  Membership Approval Date
                  <span class="text-danger requierd-star"></span>
                </label>
                <Datepicker
                  v-if="member.info"
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid': validation.hasError('member.info.date'),
                    'is-valid': member.info.date,
                  }"
                  name="date"
                  placeholder="e.g. 12-12-2022."
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. 12-12-2022'"
                  v-model="member.info.date"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.date") }}
                </div>
              </div>
              <div class="form-row mb-3">
                <label for="comments">
                  Office Address
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  v-if="member.info"
                  required
                  :class="{
                    'is-invalid': validation.hasError('member.info.address'),
                    'is-valid': member.info.address,
                  }"
                  class="form-control shadow-none"
                  placeholder="e.g. 221b baker street london"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. 221b baker street london'"
                  v-model="member.info.address"
                  name="address"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.address") }}
                </div>
              </div>
              <div class="form-row mb-3">
                <label for="comments">Fax</label>
                <input
                  v-if="member.info"
                  type="text"
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid': validation.hasError('member.info.fax'),
                    'is-valid': member.info.fax,
                  }"
                  placeholder="e.g. 01xxxxxxxxx"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
                  v-model="member.info.fax"
                  name="fax"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.fax") }}
                </div>
              </div>
              <div class="form-row mb-3">
                <label for="comments">
                  Website
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  v-if="member.info"
                  type="text"
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid': validation.hasError('member.info.website'),
                    'is-valid': member.info.website,
                  }"
                  placeholder="e.g. http://website.com"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. http://website.com'"
                  v-model="member.info.website"
                  name="website"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.website") }}
                </div>
              </div>

              <div class="form-row mb-4">
                <label for="comments">BIN Number</label>
                <input
                  v-if="member.info"
                  type="text"
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid': validation.hasError('member.info.bin_number'),
                    'is-valid': member.info.bin_number,
                  }"
                  placeholder="e.g. 12345"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. 12345'"
                  v-model="member.info.bin_number"
                  name="bin_number"
                />
              </div>
              <slot v-if="member.info">
                <div
                  class="form-row mb-4"
                  v-if="member.info.legal_structure != 'Proprietorship'"
                >
                  <label for="comments">Company TIN Number</label>
                  <span class="requierd-star"></span>
                  <input
                    v-if="member.info"
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'member.info.tin_number'
                      ),
                      'is-valid': member.info.tin_number,
                    }"
                    placeholder="e.g. 01xxxxxxxxx"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
                    v-model="member.info.tin_number"
                    name="tin_number"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("member.info.tin_number") }}
                  </div>
                </div>
              </slot>
              <slot v-if="member.info">
                <div
                  class="form-row mb-3"
                  v-if="member.info.legal_structure == 'Limited Company'"
                >
                  <!-- certificate incorporation -->
                  <div class="form-group col-2 text-center" v-if="member.info">
                    <img
                      id="displayProfilPhoto"
                      v-lazy="
                        member.info.certificate_incorporation ||
                        images.certificate_incorporation
                          ? attachPdf
                          : noImage
                      "
                      height="60px"
                      alt="displayProfilPhoto"
                    />
                  </div>
                  <div class="form-group col-10">
                    <label for="comments">Certificate of Incorporation</label>
                    <span class="text-danger requierd-star"></span>
                    <b-form-file
                      v-if="member.info"
                      v-model="images.certificate_incorporation"
                      accept=".pdf"
                      :placeholder="
                        member.info.certificate_incorporation
                          ? 'File attached '
                          : 'Choose a pdf file'
                      "
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <input
                      type="hidden"
                      v-if="member.info"
                      :value="member.info.certificate_incorporation"
                      name="old_certificate_incorporation"
                    />
                    <small>File size must be smaller than 7mb</small>
                    <a
                      v-if="
                        member.info && member.info.certificate_incorporation
                      "
                      :href="
                        $root.baseurl +
                        '/public' +
                        member.info.certificate_incorporation
                      "
                      class="float-right"
                      target="_blank"
                      >View file</a
                    >
                    <div class="invalid-feedback">
                      {{
                        validation.firstError(
                          "images.certificate_incorporation"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </slot>
              <slot v-if="member.info">
                <div
                  class="form-row mb-3"
                  v-if="member.info.legal_structure == 'Partnership'"
                >
                  <!-- certificate incorporation -->
                  <div class="form-group col-2 text-center" v-if="member.info">
                    <img
                      id="displayProfilPhoto"
                      v-lazy="
                        member.info.partnership_deed || images.partnership_deed
                          ? attachPdf
                          : noImage
                      "
                      height="60px"
                      alt="displayProfilPhoto"
                    />
                  </div>
                  <div class="form-group col-10">
                    <label for="comments">Partnership Deed</label>
                    <span class="requierd-star"></span>
                    <b-form-file
                      v-model="images.partnership_deed"
                      accept=".pdf"
                      :placeholder="
                        member.info.partnership_deed
                          ? 'File attached '
                          : 'Choose a pdf file'
                      "
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <small>File size must be smaller than 7mb</small>
                    <a
                      v-if="member.info && member.info.partnership_deed"
                      :href="
                        $root.baseurl + '/public' + member.info.partnership_deed
                      "
                      class="float-right text-right"
                      target="_blank"
                      >View file</a
                    >
                    <div class="invalid-feedback">
                      {{ validation.firstError("images.partnership_deed") }}
                    </div>

                    <input
                      type="hidden"
                      v-if="member.info"
                      :value="member.info.partnership_deed"
                      name="old_partnership_deed"
                    />
                  </div>
                </div>
              </slot>
            </div>
            <!-- right side -->
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  BASIS Membership Number
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  v-if="member.info"
                  type="text"
                  disabled
                  class="form-control shadow-none disable"
                  :class="{
                    'is-invalid': validation.hasError(
                      'member.info.membership_no'
                    ),
                    'is-valid': member.info.membership_no,
                  }"
                  placeholder="e.g. A-12345"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. A-12345'"
                  v-model="member.info.membership_no"
                  name="membership_no"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.membership_no") }}
                </div>
              </div>
              <div class="form-row mb-3">
                <label for="comments">
                  Company Type
                  <span class="text-danger requierd-star"></span>
                </label>
                <select
                  disabled
                  v-if="member.info"
                  name="legal_structure"
                  :class="{
                    'is-invalid': validation.hasError(
                      'member.info.legal_structure'
                    ),
                    'is-valid': member.info.legal_structure,
                  }"
                  v-model="member.info.legal_structure"
                  class="form-control"
                >
                  <option :value="null" selected="selected">Choose...</option>
                  <option
                    v-for="(company_type, id, index) in company_types"
                    :key="index"
                    :value="id"
                  >
                    {{ company_type }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.legal_structure") }}
                </div>
              </div>
              <div class="form-row form-row-2 mb-3">
                <label for="comments">Success Story</label>
                <textarea
                  v-if="member.info"
                  class="form-control"
                  placeholder="e.g. Success"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. Success'"
                  :class="{
                    'is-invalid': validation.hasError(
                      'member.info.success_story'
                    ),
                    'is-valid': member.info.success_story,
                  }"
                  v-model="member.info.success_story"
                  name="success_story"
                ></textarea>
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.success_story") }}
                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col-md-6">
                  <label for="comments">
                    District
                    <span class="text-danger requierd-star"></span>
                  </label>
                  <select
                    v-if="member.info"
                    class="form-control"
                    name="district_id"
                    :class="{
                      'is-invalid': validation.hasError(
                        'member.info.district_id'
                      ),
                      'is-valid': member.info.district_id,
                    }"
                    v-model="member.info.district_id"
                  >
                    <option selected :value="null">Choose...</option>
                    <option
                      v-for="(district, id, index) in districts"
                      :key="index"
                      :value="id"
                    >
                      {{ district }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{ validation.firstError("member.info.district_id") }}
                  </div>
                </div>

                <div class="col-md-6">
                  <label for="comments"> Area </label>
                  <select
                    v-if="member.info"
                    class="form-control"
                    name="area"
                    :class="{
                      'is-invalid': validation.hasError('member.info.area'),
                      'is-valid': member.info.area,
                    }"
                    v-model="member.info.area"
                  >
                    <option selected :value="null">Choose...</option>
                    <option
                      v-for="(area, index) in areas"
                      :key="index"
                      :value="area.name"
                    >
                      {{ area.name }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{ validation.firstError("member.info.area") }}
                  </div>
                </div>
              </div>

              <div class="form-row mb-3">
                <div class="col-md-6">
                  <label for="comments">
                    Postcode
                    <span class="text-danger requierd-star"></span>
                  </label>
                  <input
                    v-if="member.info"
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError('member.info.postcode'),
                      'is-valid': member.info.postcode,
                    }"
                    placeholder="e.g. 12345"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 12345'"
                    v-model="member.info.postcode"
                    name="postcode"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("member.info.postcode") }}
                  </div>
                </div>

                <div class="col-md-6">
                  <label for="comments">
                    Phone
                    <span class="text-danger requierd-star"></span>
                  </label>
                  <input
                    type="text"
                    v-if="member.info"
                    class="form-control"
                    placeholder="e.g. 01xxxxxxxx, 01xxxxxxxx"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 01xxxxxxxx, 01xxxxxxxx'"
                    :class="{
                      'is-invalid': validation.hasError('member.info.phone'),
                      'is-valid': member.info.phone,
                    }"
                    v-model="member.info.phone"
                    name="phone"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("member.info.phone") }}
                  </div>
                </div>
              </div>
              <div class="form-row mb-3">
                <label for="comments">
                  Email
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  v-if="member.info"
                  type="text"
                  class="form-control shadow-none"
                  placeholder="e.g. yourname@mail.com"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. yourname@mail.com'"
                  :class="{
                    'is-invalid': validation.hasError('member.info.email'),
                    'is-valid': member.info.email,
                  }"
                  v-model="member.info.email"
                  name="email"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.email") }}
                </div>
              </div>
              <div class="form-row mb-3">
                <label for="comments"
                  >Other Websites that belongs to the company</label
                >
                <input
                  v-if="member.info"
                  type="text"
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid': validation.hasError(
                      'member.info.other_website'
                    ),
                    'is-valid': member.info.other_website,
                  }"
                  placeholder="e.g. http://website.com"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'e.g. http://website.com'"
                  v-model="member.info.other_website"
                  name="other_website"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("member.info.other_website") }}
                </div>
              </div>
              <div class="form-row">
                <!-- logo -->
                <div
                  class="form-group col-3 text-center"
                  v-if="member.info && member.info.logo && !images.logo"
                >
                  <img
                    v-lazy="
                      member.info.logo
                        ? $root.baseurl + '/public' + member.info.logo
                        : noImage
                    "
                    class="img-responsive"
                    style="max-height: 40px"
                    width="100%"
                    alt="noImage"
                  />
                </div>
                <div class="form-group col-3 text-center" v-else>
                  <img
                    v-lazy="images.logo ? logo_src : noImage"
                    class="img-responsive"
                    height="85"
                    width="100%"
                    alt="noImage"
                  />
                </div>
                <div class="form-group col-9">
                  <label for="comments">
                    Company Logo
                    <span class="requierd-star"></span>
                  </label>
                  <b-form-file
                    v-model="images.logo"
                    size="sm"
                    placeholder="Choose jpg,jpeg,png file"
                    accept=".jpg, .png"
                    :class="{
                      'is-invalid': validation.hasError('images.logo'),
                      'is-valid': images.logo,
                    }"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <small>Supported formats are gif, jpg, png, jpeg.</small>
                  <input
                    type="hidden"
                    v-if="member.info"
                    :value="member.info.logo"
                    name="old_logo"
                  />
                  <a
                    v-if="member.info && member.info.logo"
                    :href="$root.baseurl + '/public' + member.info.logo"
                    class="float-right"
                    target="_blank"
                    >View Image</a
                  >
                  <div class="invalid-feedback">
                    {{ validation.firstError("images.logo") }}
                  </div>
                </div>
              </div>
              <div class="form-row" v-if="member.info">
                <!-- certificate incorporation -->
                <slot v-if="member.info.legal_structure != 'Proprietorship'">
                  <div class="form-group col-2 text-center">
                    <img
                      id="displayProfilPhoto"
                      v-lazy="
                        member.info.tin_certificate || images.tin_certificate
                          ? attachPdf
                          : noImage
                      "
                      height="60px"
                      alt="displayProfilPhoto"
                    />
                  </div>
                  <div class="form-group col-10">
                    <label for="comments">Company TIN Certificate</label>
                    <span class="text-danger requierd-star"></span>
                    <b-form-file
                      v-if="member.info"
                      v-model="images.tin_certificate"
                      accept=".pdf"
                      :placeholder="
                        member.info.tin_certificate
                          ? 'File attached '
                          : 'Choose a pdf file'
                      "
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <input
                      type="hidden"
                      v-if="member.info"
                      :value="member.info.tin_certificate"
                      name="old_tin_certificate"
                    />
                    <small>File size must be smaller than 7mb</small>
                    <a
                      v-if="member.info && member.info.tin_certificate"
                      :href="
                        $root.baseurl + '/public' + member.info.tin_certificate
                      "
                      class="float-right"
                      target="_blank"
                      >View file</a
                    >
                    <div class="invalid-feedback">
                      {{ validation.firstError("images.tin_certificate") }}
                    </div>
                  </div>
                </slot>
              </div>

              <div class="form-row mb-3" v-if="member.info">
                <slot
                  v-if="
                    member.info.legal_structure == 'Others' ||
                    member.info.legal_structure == 'Limited Company'
                  "
                >
                  <div class="form-group col-2 text-center" v-if="member.info">
                    <img
                      id="displayProfilPhoto"
                      v-lazy="
                        member.info.memo_article || images.memo_article
                          ? attachPdf
                          : noImage
                      "
                      height="60px"
                      alt="displayProfilPhoto"
                    />
                  </div>
                  <div class="form-group col-10">
                    <label for="comments">Memorandum of Articles</label>
                    <span class="requierd-star"></span>
                    <b-form-file
                      v-model="images.memo_article"
                      accept=".pdf"
                      :placeholder="
                        member.info.memo_article
                          ? 'File attached '
                          : 'Choose a pdf file'
                      "
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <input
                      type="hidden"
                      v-if="member.info"
                      :value="member.info.memo_article"
                      name="old_memo_article"
                    />
                    <small>File size must be smaller than 7mb</small>
                    <a
                      v-if="member.info && member.info.memo_article"
                      :href="
                        $root.baseurl + '/public' + member.info.memo_article
                      "
                      class="float-right"
                      target="_blank"
                      >View file</a
                    >
                    <div class="invalid-feedback">
                      {{ validation.firstError("images.memo_article") }}
                    </div>
                  </div>
                </slot>
              </div>
            </div>

            <!-- Shareholder Informations-->
            <slot v-if="member.info">
              <fieldset
                v-if="
                  member.info.legal_structure == 'Partnership' ||
                  member.info.legal_structure == 'Limited Company'
                "
                class="col-12 border border-primary p-3 mb-3 mt-4"
              >
                <legend class="w-50 text-center">
                  <small class="text-uppercase font-weight-bold"
                    >Shareholder Informations</small
                  >
                </legend>
                <div class="col-md-12">
                  <div class="form-row">
                    <slot>
                      <div
                        v-for="(share, index2) in member.info.shareholders"
                        :key="index2"
                        class="form-row col-md-12 mb-3 pb-2"
                        :style="
                          Object.keys(member.info.shareholders).length !=
                          index2 + 1
                            ? 'border-bottom: 2px solid #4fa277'
                            : ''
                        "
                      >
                        <div class="form-group col-lg-4 mb-3">
                          <label for="cv" class="col-form-label-sm m-0"
                            >Shareholder ({{ index2 + 1 }}) Name</label
                          >
                          <span class="requierd-star"></span>
                          <input
                            :name="'shareholders[' + index2 + '][name]'"
                            type="text"
                            class="form-control shadow-none"
                            placeholder="Name"
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'Name'"
                            v-model="share.name"
                          />
                          <!-- <span
														class="invalid-feedback"
														v-if="
															checkError(index2, 'shareholders', 'share_name')
														"
														>{{
															checkError(index2, "shareholders", "share_name")
														}}</span
													> -->
                        </div>
                        <div class="form-group col-lg-4 mb-3">
                          <label for="cv" class="col-form-label-sm m-0"
                            >Shareholder ({{ index2 + 1 }}) Designation</label
                          >
                          <span class="requierd-star"></span>
                          <input
                            :name="'shareholders[' + index2 + '][designation]'"
                            type="text"
                            class="form-control shadow-none"
                            placeholder="Designation"
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'Designation'"
                            v-model="share.designation"
                          />
                          <!-- <span
														class="invalid-feedback"
														v-if="
															checkError(
																index2,
																'shareholders',
																'share_designation'
															)
														"
														>{{
															checkError(
																index2,
																"shareholders",
																"share_designation"
															)
														}}</span
													> -->
                        </div>
                        <div class="form-group col-lg-2 mb-3">
                          <label for="cv" class="col-form-label-sm m-0"
                            >Percentage of Share</label
                          >
                          <span class="requierd-star"></span>
                          <div class="input-group">
                            <input
                              :name="'shareholders[' + index2 + '][percent]'"
                              type="number"
                              class="form-control shadow-none"
                              placeholder="e.g. 10"
                              onfocus="this.placeholder = ''"
                              onblur="this.placeholder = 'e.g. 10'"
                              v-model="share.percent"
                            />
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="direct_phone"
                                >%</span
                              >
                            </div>
                            <!-- <span
															class="invalid-feedback"
															v-if="
																checkError(
																	index2,
																	'shareholders',
																	'share_percent'
																)
															"
															>{{
																checkError(
																	index2,
																	"shareholders",
																	"share_percent"
																)
															}}</span
														> -->
                          </div>
                        </div>
                        <div
                          class="form-group col-3 col-lg-1 text-center align-self-center mb-0"
                          v-if="index2 > 0"
                        >
                          <button
                            @click="removeBoardOfShareholders(index2)"
                            class="btn btn-sm btn-danger w-100"
                            type="button"
                          >
                            <span class="fas fa-times"></span>
                          </button>
                        </div>
                        <div
                          class="form-group col-3 col-lg-1 text-center align-self-center mb-0"
                        >
                          <button
                            v-if="
                              index2 + 1 ==
                              Object.keys(member.info.shareholders).length
                            "
                            @click="addBoardOfShareholders()"
                            class="btn btn-sm btn-success float-right"
                            type="button"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </slot>

                    <div class="col-12 row mb-3">
                      <div class="col-6">
                        <b>Have Any Women Shareholder In This Company?</b>
                        <span class="requierd-star"></span>
                        <div class="invalid-feedback">
                          {{
                            validation.firstError(
                              "member.info.women_shareholder"
                            )
                          }}
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="women_shareholder"
                            name="women_shareholder"
                            class="custom-control-input"
                            value="Yes"
                            v-model="member.info.women_shareholder"
                          />
                          <label
                            class="custom-control-label"
                            for="women_shareholder"
                            >Yes</label
                          >
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="women_shareholder1"
                            name="women_shareholder"
                            class="custom-control-input"
                            value="No"
                            v-model="member.info.women_shareholder"
                          />
                          <label
                            class="custom-control-label"
                            for="women_shareholder1"
                            >No</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="w-100"></div>
                  </div>
                </div>
              </fieldset>
            </slot>

            <!-- trade license -->
            <fieldset class="col-12 border border-primary p-3 mb-3 mt-4">
              <legend class="w-25 text-center">
                <small class="text-uppercase font-weight-bold"
                  >Trade License</small
                >
              </legend>
              <div class="col-12 row mb-3">
                <div class="col-8" v-if="member.info">
                  <b>
                    Is the trade license contains software or ITES as business
                    type ?
                  </b>
                  <span class="requierd-star"></span>
                  <div class="invalid-feedback">
                    {{ validation.firstError("member.info.business_type") }}
                  </div>
                </div>
                <div class="col-2">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="business_type"
                      name="business_type"
                      class="custom-control-input"
                      value="Yes"
                      v-model="member.info.business_type"
                    />
                    <label class="custom-control-label" for="business_type"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="col-2">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="business_type1"
                      name="business_type"
                      class="custom-control-input"
                      value="No"
                      v-model="member.info.business_type"
                    />
                    <label class="custom-control-label" for="business_type1"
                      >No</label
                    >
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-row">
                  <slot v-if="member.info">
                    <div
                      v-for="(trade, index) in member.info.trade_license
                        ? member.info.trade_license
                        : images.trade_license"
                      :key="index"
                      class="form-row col-md-12 mb-3 pb-3"
                      :style="
                        Object.keys(
                          member.info.trade_license
                            ? member.info.trade_license
                            : images.trade_license
                        ).length !=
                        index + 1
                          ? 'border-bottom: 2px solid #4fa277'
                          : ''
                      "
                    >
                      <div class="form-group col-lg-4 mb-1">
                        <label for="cv" class="col-form-label-sm m-0"
                          >Updated Trade License</label
                        >
                        <span class="requierd-star"></span>&nbsp;&nbsp;&nbsp;
                        <small>(pdf only)</small>
                        <div class="loginBox">
                          <b-form-file
                            :id="'trade_pdf' + index"
                            v-on:change="attach($event, 'trade_pdf', index)"
                            v-model="trade.trade_pdf"
                            accept=".pdf"
                            :placeholder="
                              trade.trade_license
                                ? 'File attached'
                                : 'Choose a updated trade license'
                            "
                            drop-placeholder="Drop file here..."
                            ref="pdf1"
                            :class="{
                              'is-invalid':
                                validation.hasError('trade.trade_pdf'),
                              'is-valid': trade.trade_pdf,
                            }"
                          ></b-form-file>
                          <span class="mdi mdi-file-pdf text-danger"></span>
                        </div>
                        <small>File size must be smaller than 7mb</small>
                        <a
                          v-if="trade.trade_license"
                          :href="
                            $root.baseurl + '/public' + trade.trade_license
                          "
                          class="float-right"
                          target="_blank"
                          >View file</a
                        >
                        <input
                          type="hidden"
                          v-model="trade.trade_license"
                          :name="'trade_license[' + index + '][old]'"
                        />
                      </div>

                      <div class="form-group col-9 col-lg-3 mb-3">
                        <label for="cv" class="col-form-label-sm m-0"
                          >Updated Trade License Number</label
                        >
                        <span class="requierd-star"></span>
                        <input
                          :name="'trade_license[' + index + '][license_number]'"
                          type="text"
                          class="form-control shadow-none"
                          placeholder="e.g. 1223"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'e.g. 1223'"
                          v-model="trade.license_number"
                          :class="{
                            'is-invalid': validation.hasError(
                              'trade.license_number'
                            ),
                            'is-valid': trade.license_number,
                          }"
                        />
                        <div class="invalid-feedback">
                          {{ validation.firstError("trade.license_number") }}
                        </div>
                      </div>
                      <div class="form-group col-9 col-lg-4 mb-3">
                        <label for="cv" class="col-form-label-sm m-0"
                          >Validity Date</label
                        >
                        <span class="requierd-star"></span>
                        <Datepicker
                          class="form-control shadow-none"
                          placeholder="e.g. 12-12-2022."
                          :name="'trade_license[' + index + '][validity_date]'"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'e.g. 12-12-2022'"
                          v-model="trade.validity_date"
                        />
                      </div>
                      <div
                        class="form-group col-3 col-lg-1 text-center align-self-center mb-0"
                        v-if="index > 0 && member.info"
                      >
                        <button
                          v-if="member.info.trade_license"
                          @click="member.info.trade_license.splice(index, 1)"
                          class="btn btn-sm btn-danger w-100"
                          type="button"
                        >
                          <span class="fas fa-times"></span>
                        </button>
                        <button
                          v-else
                          @click="images.trade_license.splice(index, 1)"
                          class="btn btn-sm btn-danger w-100"
                          type="button"
                        >
                          <span class="fas fa-times"></span>
                        </button>
                      </div>
                      <div
                        class="form-group col-3 col-lg-1 text-center align-self-center mb-0"
                        v-if="
                          index == 0 &&
                          member.info &&
                          Object.keys(
                            member.info.trade_license
                              ? member.info.trade_license
                              : images.trade_license
                          ).length < 5
                        "
                      >
                        <button
                          v-if="member.info.trade_license"
                          @click="
                            member.info.trade_license.push({
                              trade_license: null,
                              license_number: null,
                            })
                          "
                          class="btn btn-sm btn-success float-right"
                          type="button"
                        >
                          Add
                        </button>
                        <button
                          v-else
                          @click="
                            images.trade_license.push({
                              trade_license: null,
                              license_number: null,
                            })
                          "
                          class="btn btn-sm btn-success float-right"
                          type="button"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </slot>
                </div>
              </div>
            </fieldset>

            <!-- partner tin / tin certificate-->
            <slot v-if="member.info">
              <fieldset
                v-if="member.info.legal_structure == 'Partnership'"
                class="col-12 border border-primary p-3 mb-3 mt-4"
              >
                <legend class="w-50 text-center">
                  <small class="text-uppercase font-weight-bold"
                    >Partner's Information</small
                  >
                </legend>
                <div class="col-md-12">
                  <div class="form-row">
                    <slot v-if="member.info">
                      <div
                        v-for="(partn, index2) in member.info.partner
                          ? member.info.partner
                          : images.partner"
                        :key="index2"
                        class="form-row col-md-12 mb-3 pb-2"
                        :style="
                          (member.info.partner
                            ? Object.keys(member.info.partner).length
                            : Object.keys(images.partner).length) !=
                          index2 + 1
                            ? 'border-bottom: 2px solid #4fa277'
                            : ''
                        "
                      >
                        <div class="form-group col-lg-6 mb-3">
                          <label for="cv" class="col-form-label-sm m-0"
                            >Partner ({{ index2 + 1 }}) Name</label
                          >
                          <span class="requierd-star"></span>
                          <input
                            :name="'partner[' + index2 + '][partner_name]'"
                            type="text"
                            class="form-control shadow-none"
                            placeholder="Name"
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'Name'"
                            v-model="partn.partner_name"
                          />
                        </div>
                        <div class="form-group col-lg-6 mb-3">
                          <label for="cv" class="col-form-label-sm m-0"
                            >Partner ({{ index2 + 1 }}) Designation</label
                          >
                          <span class="requierd-star"></span>
                          <input
                            :name="
                              'partner[' + index2 + '][partner_designation]'
                            "
                            type="text"
                            class="form-control shadow-none"
                            placeholder="Designation"
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'Designation'"
                            v-model="partn.partner_designation"
                          />
                        </div>
                        <div class="form-group col-lg-6 mb-3">
                          <label for="cv" class="col-form-label-sm m-0"
                            >Partner ({{ index2 + 1 }}) Email</label
                          >
                          <span class="requierd-star"></span>
                          <input
                            :name="'partner[' + index2 + '][partner_email]'"
                            type="email"
                            class="form-control shadow-none"
                            placeholder="Email"
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'Email'"
                            v-model="partn.partner_email"
                          />
                        </div>
                        <div class="form-group col-lg-6 mb-3">
                          <label for="cv" class="col-form-label-sm m-0"
                            >Partner ({{ index2 + 1 }}) Mobile</label
                          >
                          <span class="requierd-star"></span>
                          <input
                            :name="'partner[' + index2 + '][partner_mobile]'"
                            type="number"
                            class="form-control shadow-none"
                            placeholder="Mobile"
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'Mobile'"
                            v-model="partn.partner_mobile"
                          />
                        </div>

                        <div class="form-group col-lg-6 mb-1">
                          <label for="cv" class="col-form-label-sm m-0"
                            >Partner ({{ index2 + 1 }}) TIN Certificate</label
                          >
                          <span class="requierd-star"></span>&nbsp;&nbsp;&nbsp;
                          <small>(pdf only)</small>
                          <div class="loginBox">
                            <b-form-file
                              :id="'tin_pdf' + index2"
                              v-on:change="attach($event, 'tin_pdf', index2)"
                              v-model="partn.tin_pdf"
                              accept=".pdf"
                              :placeholder="
                                partn.tin_certificate
                                  ? 'File attached '
                                  : 'Choose a tin certificate'
                              "
                              drop-placeholder="Drop file here..."
                              ref="pdf1"
                            ></b-form-file>
                            <span class="mdi mdi-file-pdf text-danger"></span>
                          </div>
                          <small>File size must be smaller than 7mb</small>
                          <a
                            v-if="partn.tin_certificate"
                            :href="
                              $root.baseurl + '/public' + partn.tin_certificate
                            "
                            class="float-right"
                            target="_blank"
                            >View file</a
                          >
                          <input
                            type="hidden"
                            v-model="partn.tin_certificate"
                            :name="'partner[' + index2 + '][old]'"
                          />
                        </div>
                        <div class="form-group col-lg-4 mb-3">
                          <label for="cv" class="col-form-label-sm m-0"
                            >Partner ({{ index2 + 1 }}) TIN number</label
                          >
                          <span class="requierd-star"></span>
                          <input
                            :name="'partner[' + index2 + '][tin_number]'"
                            type="text"
                            class="form-control shadow-none"
                            placeholder="e.g. 1223"
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'e.g. 1223'"
                            v-model="partn.tin_number"
                          />
                        </div>

                        <div
                          class="form-group col-3 col-lg-1 text-center align-self-center mb-0"
                          v-if="index2 > 0"
                        >
                          <button
                            v-if="member.info.partner"
                            @click="member.info.partner.splice(index2, 1)"
                            class="btn btn-sm btn-danger w-100"
                            type="button"
                          >
                            <span class="fas fa-times"></span>
                          </button>
                          <button
                            v-else
                            @click="images.partner.splice(index2, 1)"
                            class="btn btn-sm btn-danger w-100"
                            type="button"
                          >
                            <span class="fas fa-times"></span>
                          </button>
                        </div>
                        <div
                          class="form-group col-3 col-lg-1 text-center align-self-center mb-0"
                        >
                          <button
                            v-if="
                              member.info.partner &&
                              Object.keys(member.info.partner).length < 5 &&
                              index2 + 1 ==
                                Object.keys(member.info.partner).length
                            "
                            @click="
                              member.info.partner.push({
                                tin_certificate: null,
                              })
                            "
                            class="btn btn-sm btn-success float-right"
                            type="button"
                          >
                            Add
                          </button>
                          <slot v-else-if="!member.info.partner">
                            <button
                              v-if="
                                index2 + 1 == Object.keys(images.partner).length
                              "
                              @click="
                                images.partner.push({ tin_certificate: null })
                              "
                              class="btn btn-sm btn-success float-right"
                              type="button"
                            >
                              Add
                            </button>
                          </slot>
                        </div>
                      </div>
                    </slot>
                  </div>
                </div>
              </fieldset>
            </slot>

            <!-- BOARD OF DIRECTOR'S-->
            <slot v-if="member.info">
              <fieldset
                v-if="member.info.legal_structure == 'Limited Company'"
                class="col-12 border border-primary p-3 mb-3 mt-4"
              >
                <legend class="w-50 text-center">
                  <small class="text-uppercase font-weight-bold"
                    >Board of Director's</small
                  >
                </legend>
                <div class="col-md-12">
                  <div class="form-row">
                    <div
                      v-for="(dire, index2) in member.info.directors"
                      :key="index2"
                      class="form-row col-md-12 mb-3 pb-2"
                      :style="[
                        Object.keys(member.info.directors).length != index2 + 1
                          ? 'border-bottom: 2px solid #4fa277'
                          : '',
                      ]"
                    >
                      <div class="form-group col-lg-6 mb-3">
                        <label for="cv" class="col-form-label-sm m-0"
                          >Director ({{ index2 + 1 }}) Name</label
                        >
                        <span class="requierd-star"></span>
                        <input
                          :name="'directors[' + index2 + '][name]'"
                          type="text"
                          class="form-control shadow-none"
                          placeholder="Name"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'Name'"
                          v-model="dire.name"
                        />
                        <!-- <span
													class="invalid-feedback"
													v-if="
														checkError(index2, 'directors', 'director_name')
													"
													>{{
														checkError(index2, "directors", "director_name")
													}}</span
												> -->
                      </div>

                      <div class="form-group col-lg-6 mb-3">
                        <label for="cv" class="col-form-label-sm m-0"
                          >Director ({{ index2 + 1 }}) Designation</label
                        >
                        <span class="requierd-star"></span>
                        <input
                          :name="'directors[' + index2 + '][designation]'"
                          type="text"
                          class="form-control shadow-none"
                          placeholder="Designation"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'Designation'"
                          v-model="dire.designation"
                        />
                        <!-- <span
													class="invalid-feedback"
													v-if="
														checkError(
															index2,
															'directors',
															'director_designation'
														)
													"
													>{{
														checkError(
															index2,
															"directors",
															"director_designation"
														)
													}}</span
												> -->
                      </div>
                      <div class="form-group col-lg-6 mb-3">
                        <label for="cv" class="col-form-label-sm m-0"
                          >Director ({{ index2 + 1 }}) Email</label
                        >
                        <span class="requierd-star"></span>
                        <input
                          :name="'directors[' + index2 + '][email]'"
                          type="email"
                          class="form-control shadow-none"
                          placeholder="Email"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'Email'"
                          v-model="dire.email"
                        />
                        <!-- <span
													class="invalid-feedback"
													v-if="
														checkError(index2, 'directors', 'director_email')
													"
													>{{
														checkError(index2, "directors", "director_email")
													}}</span
												> -->
                      </div>
                      <div class="form-group col-lg-4 mb-3">
                        <label for="cv" class="col-form-label-sm m-0"
                          >Director ({{ index2 + 1 }}) Mobile</label
                        >
                        <span class="requierd-star"></span>
                        <input
                          :name="'directors[' + index2 + '][mobile]'"
                          type="number"
                          class="form-control shadow-none"
                          placeholder="Mobile"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'Mobile'"
                          v-model="dire.mobile"
                        />
                        <!-- <span
													class="invalid-feedback"
													v-if="
														checkError(index2, 'directors', 'director_mobile')
													"
													>{{
														checkError(index2, "directors", "director_mobile")
													}}</span
												> -->
                      </div>

                      <div
                        class="form-group col-3 col-lg-1 text-center align-self-center mb-0"
                        v-if="index2 > 0"
                      >
                        <button
                          @click="removeBoardOfDirectors(index2)"
                          class="btn btn-sm btn-danger w-100"
                          type="button"
                        >
                          <span class="fas fa-times"></span>
                        </button>
                      </div>
                      <div
                        class="form-group col-3 col-lg-1 text-center align-self-center mb-0"
                      >
                        <button
                          v-if="
                            index2 + 1 ==
                            Object.keys(member.info.directors).length
                          "
                          @click="addBoardOfDirectors()"
                          class="btn btn-sm btn-success float-right"
                          type="button"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </slot>

            <!-- Company Head -->
            <fieldset class="col-lg-12 border border-primary p-3 mb-3 mt-4">
              <legend class="w-25 text-center">
                <small class="text-uppercase font-weight-bold"
                  >Company Head</small
                >
              </legend>
              <div class="form-row">
                <div class="form-group col-6 mb-3">
                  <label for="comments">Company head</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    v-if="member.info"
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'member.info.company_head'
                      ),
                      'is-valid': member.info.company_head,
                    }"
                    placeholder="e.g. Jane Doe"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. Jane Doe'"
                    v-model="member.info.company_head"
                    name="company_head"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("member.info.company_head") }}
                  </div>
                </div>
                <div class="form-group col-6 mb-3">
                  <label for="comments">Designation</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    v-if="member.info"
                    type="text"
                    class="form-control shadow-none"
                    placeholder="e.g. CEO"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. CEO'"
                    :class="{
                      'is-invalid': validation.hasError(
                        'member.info.designation'
                      ),
                      'is-valid': member.info.designation,
                    }"
                    v-model="member.info.designation"
                    name="designation"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("member.info.designation") }}
                  </div>
                </div>
                <div class="form-group col-6 mb-3">
                  <label for="comments">Mobile</label>
                  <span class="text-danger requierd-star"></span>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="direct_phone"
                        >+88</span
                      >
                    </div>
                    <input
                      v-if="member.info"
                      type="text"
                      class="form-control shadow-none"
                      :class="{
                        'is-invalid': validation.hasError(
                          'member.info.company_head_mobile'
                        ),
                        'is-valid': member.info.company_head_mobile,
                      }"
                      placeholder="e.g. 01xxxxxxxxxx"
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
                      v-model="member.info.company_head_mobile"
                      name="company_head_mobile"
                    />
                  </div>
                  <div class="invalid-feedback">
                    {{
                      validation.firstError("member.info.company_head_mobile")
                    }}
                  </div>
                </div>
                <div class="form-group col-6 mb-3">
                  <label for="comments">Direct Phone</label>
                  <input
                    v-if="member.info"
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'member.info.company_head_phone'
                      ),
                      'is-valid': member.info.company_head_phone,
                    }"
                    placeholder="e.g. 01xxxxxxxxxx"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
                    v-model="member.info.company_head_phone"
                    name="company_head_phone"
                  />
                  <div class="invalid-feedback">
                    {{
                      validation.firstError("member.info.company_head_phone")
                    }}
                  </div>
                </div>
                <div class="form-group col-6 mb-3">
                  <label for="comments">E-mail of Company Head</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    v-if="member.info"
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'member.info.company_head_email'
                      ),
                      'is-valid': member.info.company_head_email,
                    }"
                    placeholder="e.g. yourname@mail.com"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. yourname@mail.com'"
                    v-model="member.info.company_head_email"
                    name="company_head_email"
                  />
                  <div class="invalid-feedback">
                    {{
                      validation.firstError("member.info.company_head_email")
                    }}
                  </div>
                </div>
                <div class="form-group col-6 mb-3">
                  <label for="comments">LinkedIn</label>
                  <input
                    v-if="member.info"
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'member.info.company_head_linkedin'
                      ),
                      'is-valid': member.info.company_head_linkedin,
                    }"
                    placeholder="e.g. yourname@mail.com"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. yourname@mail.com'"
                    v-model="member.info.company_head_linkedin"
                    name="company_head_linkedin"
                  />
                </div>
                <div class="form-row col-12 col-lg-6 mb-3">
                  <!-- image -->
                  <div
                    class="form-group col-2 text-center"
                    v-if="
                      member.info &&
                      member.info.company_head_image &&
                      !images.head_image
                    "
                  >
                    <img
                      v-lazy="
                        member.info.company_head_image
                          ? $root.baseurl +
                            '/public' +
                            member.info.company_head_image
                          : noImage
                      "
                      class="img-responsive"
                      style="max-height: 60px"
                      width="100%"
                      alt="noImage"
                    />
                  </div>
                  <div class="form-group col-2 text-center" v-else>
                    <img
                      v-lazy="images.head_image ? head_image_src : noImage"
                      class="img-responsive"
                      height="max-height:60px;"
                      width="100%"
                      alt="noImage"
                    />
                  </div>
                  <div class="form-group col-10">
                    <label for="comments"
                      >PP size photo less than : (300 x 350)</label
                    >
                    <span class="requierd-star"></span>
                    <b-form-file
                      v-model="images.head_image"
                      size="sm"
                      placeholder="Choose jpg,jpeg,png file"
                      accept=".jpg, .png"
                      :class="{
                        'is-invalid': validation.hasError('images.head_image'),
                        'is-valid': images.head_image,
                      }"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <input
                      type="hidden"
                      v-if="member.info"
                      :value="member.info.head_image"
                      name="old_head_image"
                    />
                    <a
                      v-if="member.info && member.info.company_head_image"
                      :href="
                        $root.baseurl +
                        '/public' +
                        member.info.company_head_image
                      "
                      class="float-right"
                      target="_blank"
                      >View Image</a
                    >
                    <div class="invalid-feedback">
                      {{ validation.firstError("images.head_image") }}
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <br />

          <div class="row justify-content-center">
            <button class="btn btn-success" type="submit">
              Update Company Information
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- <pre>{{ member.info }}</pre> -->
    <!-- <pre>{{ validation.allErrors()  }}</pre> -->
  </div>
  <div v-else class="row">
    <div class="col-12">
      <div class="card">
        <div v-if="Object.keys($root.access).length > 0" class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! you cannot access this page
          </h4>
        </div>
        <div v-else class="card-body">
          <div
            v-if="!$parent.loading"
            class="card-body d-flex align-items-center justify-content-center flex-column"
          >
            <i
              class="fa fa-spinner fa-spin"
              style="font-size: 24px; color: rgb(0, 127, 62)"
            ></i>
            <span class="processing">processing</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noImage from "../../../../assets/img/no_logo.gif";
import imagePdfAttach from "../../../../assets/img/PdfAttached.png";
import Promise from "bluebird";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
const breadcumb = [
  {
    title: "Company Information",
  },
];
export default {
  name: "CompanyInfo",
  props: ["memberInfo"],
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Update Company Information",
      noImage: this.$root.baseurl + noImage,
      attachPdf: this.$root.baseurl + imagePdfAttach,
      count: 0,
      member: {
        info: {
          establishment_month: "January",
          establishment_year: "",
          district_id: "Dhaka",
          website: "http://www",
        },
      },
      company_types: {
        Proprietorship: "Proprietorship",
        Partnership: "Partnership",
        "Limited Company": "Limited Company",
        Others: "Others",
      },
      districts: [],
      areas: [],
      logo_src: null,
      head_image_src: null,
      images: {
        logo: null,
        head_image: null,
        certificate_incorporation: null,
        tin_certificate: null,
        memo_article: null,
        partnership_deed: null,
        trade_license: [{}],
        partner: [{}],
      },
      errors: {},
    };
  },
  watch: {
    "images.logo": function (img, oldImg) {
      this.showImage(img, "logo_src");
      $(".memLogo").attr("src", this.logo_src);
    },
    "images.head_image": function (img, oldImg) {
      this.showImage(img, "head_image_src");
    },
    "member.info.district_id": function (newVal, oldVal) {
      axios.get("member/get-area-list/" + newVal).then((res) => {
        this.areas = res.data;
      });
    },
    // "member.info.directors": {
    // 	deep: true,
    // 	handler() {
    // 		this.boardOfDirectors();
    // 	},
    // },
    // "member.info.partner": {
    // 	deep: true,
    // 	handler() {
    // 		this.boardOfPartners();
    // 	},
    // },
    // "member.info.shareholders": {
    // 	deep: true,
    // 	handler() {
    // 		this.boardOfShareholders();
    // 	},
    // },

    member: {
      deep: true,
      handler(newVal) {
        if (newVal.info.legal_structure == "Partnership") {
          this.boardOfPartners();
        }
        if (newVal.info.legal_structure == "Limited Company") {
          this.boardOfDirectors();
        }
      },
    },
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      let errors = this.errors;
      if (Object.keys(errors).length > 0) {
        this.$toastr(
          "warning",
          'You need to fill <span class="font-weight-bold">' +
            Object.keys(errors).length +
            " more empty</span> mandatory fields",
          "Empty Mandatory Fields!!"
        );
      } else {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        var form = document.getElementById("form");
        var formData = new FormData(form);
        if (confirm("Are you confirming, is the provided information valid?")) {
          if (this.images.logo) {
            formData.append("logo", this.images.logo);
          } else {
            this.images.logo = "";
          }
          if (this.images.head_image) {
            formData.append("company_head_image", this.images.head_image);
          } else {
            this.images.head_image = "";
          }
          if (this.images.certificate_incorporation) {
            formData.append(
              "certificate_incorporation",
              this.images.certificate_incorporation
            );
          } else {
            this.images.certificate_incorporation = "";
          }
          if (this.images.tin_certificate) {
            formData.append("tin_certificate", this.images.tin_certificate);
          } else {
            this.images.tin_certificate = "";
          }
          if (this.images.memo_article) {
            formData.append("memo_article", this.images.memo_article);
          } else {
            this.images.memo_article = "";
          }
          if (this.images.partnership_deed) {
            formData.append("partnership_deed", this.images.partnership_deed);
          } else {
            this.images.partnership_deed = "";
          }
          if (this.images.trade_license && !this.member.info.trade_license) {
            $.each(this.images.trade_license, function (key, trLicense) {
              if (trLicense["trade_pdf"]) {
                formData.append(
                  "trade_pdf[" + key + "]",
                  trLicense["trade_pdf"]
                );
              }
            });
          } else if (this.member.info.trade_license) {
            $.each(this.member.info.trade_license, function (key1, value1) {
              if (value1.trade_pdf) {
                formData.append("trade_pdf[" + key1 + "]", value1.trade_pdf);
              }
            });
          }
          if (this.images.partner && !this.member.info.partner) {
            $.each(this.images.partner, function (key, partn) {
              if (partn["tin_pdf"]) {
                formData.append("partner_pdf[" + key + "]", partn["tin_pdf"]);
              }
            });
          } else if (this.member.info.partner) {
            $.each(this.member.info.partner, function (key1, value1) {
              if (value1["tin_pdf"]) {
                formData.append("partner_pdf[" + key1 + "]", value1.tin_pdf);
              }
            });
          }

          this.$validate().then((res) => {
            if (this.validation.countErrors() > 0) {
              this.$toastr(
                "warning",
                'You need to fill <span class="font-weight-bold">' +
                  this.validation.countErrors() +
                  " more empty</span> mandatory fields",
                "Empty Mandatory Fields!!"
              );
              console.log(this.validation.allErrors());
            } else {
              if (res) {
                if (!this.$root.variable.aLogId) {
                  if (!this.member.info.district_id) {
                    this.$toastr(
                      "warning",
                      "District is required",
                      "Warning!!"
                    );
                  }

                  if (!this.member.info.trade_license) {
                    if (
                      this.images.trade_license[0]["trade_pdf"] == undefined &&
                      this.images.trade_license[0]["license_number"] ==
                        undefined
                    ) {
                      this.$toastr(
                        "warning",
                        "Please fillup Updated Trade License and License number",
                        "TRADE LICENSE !!"
                      );
                      return false;
                    }
                  }
                  if (this.member.info.legal_structure == "Partnership") {
                    if (!this.member.info.partner) {
                      if (
                        this.images.partner[0]["tin_certificate"] ==
                          undefined &&
                        this.images.partner[0]["tin_number"] == undefined
                      ) {
                        this.$toastr(
                          "warning",
                          "Please fillup partner tin certificate and tin number",
                          "PARTNER'S INFORMATION !!"
                        );
                        return false;
                      }
                    }
                  }
                }

                this.scrollTop();
                this.$parent.loading = true;
                console.log(formData);
                axios
                  .post("member/updateCompanyInfo", formData, config)
                  .then((response) => {
                    this.$toastr("success", "Update Successfully", "Success!!");
                    location.reload();
                    this.$parent.loading = true;
                  })
                  .catch((error) => {
                    if (error.response.status === 422) {
                      this.errors = error.response.data.errors || {};
                      this.$parent.loading = true;
                    }
                  })
                  .then((alw) => (this.$parent.loading = true));
              }
            }
          });
        }
      }
    },
    //add new directors
    addBoardOfDirectors() {
      this.member.info.directors.push({
        designation: "",
        email: "",
        mobile: "",
        name: "",
      });
      // this.errorHandle(this.member.info.directors, "directors");
    },

    //remove directors
    removeBoardOfDirectors(index) {
      // Object.keys(this.member.info.directors[index]).forEach((key) => {
      // 	this.errors = this.objectSplice(
      // 		this.errors,
      // 		`directors[${index}].${key}`
      // 	);
      // });
      this.member.info.directors.splice(index, 1);
    },

    //add new shareholders
    addBoardOfShareholders() {
      this.member.info.shareholders.push({
        name: "",
        designation: "",
        percent: "",
      });
      // this.errorHandle(this.member.info.shareholders, "shareholders");
    },

    //remove shareholders
    removeBoardOfShareholders(index) {
      // Object.keys(this.member.info.shareholders[index]).forEach((key) => {
      // 	this.errors = this.objectSplice(
      // 		this.errors,
      // 		`shareholders[${index}].${key}`
      // 	);
      // });
      this.member.info.shareholders.splice(index, 1);
    },

    //add new partner
    // addBoardOfPartners() {
    // 	this.member.info.partner.push({
    // 		partner_designation: "",
    // 		partner_email: "",
    // 		partner_mobile: "",
    // 		partner_name: "",
    // 		partner_certificate: "",
    // 		partner_number: "",
    // 	});
    // 	this.errorHandle(this.member.info.partner, "partner");
    // },

    //remove partner
    // removeBoardOfPartners(index) {
    // 	Object.keys(this.member.info.partner[index]).forEach((key) => {
    // 		this.errors = this.objectSplice(
    // 			this.errors,
    // 			`partner[${index}].${key}`
    // 		);
    // 	});
    // 	this.member.info.partner.splice(index, 1);
    // },

    // setErrors(index, prop, message) {
    // 	this.errors = {
    // 		...this.errors,
    // 		[`${index}.${prop}`]: message,
    // 	};
    // },

    // checkError(index, name, prop) {
    // 	return this.errors[`${name}[${index}].${prop}`];
    // },

    // objectSplice(object, index) {
    // 	var newObj = {};

    // 	Object.entries(object).forEach(function ([key, item]) {
    // 		if (index != key && item != false) {
    // 			newObj[key] = item;
    // 		}
    // 	});
    // 	return newObj;
    // },

    // nameToUpperCase(val) {
    // 	let eName = val.split("_");
    // 	return eName[1].charAt(0).toUpperCase() + eName[1].slice(1);
    // },
    // errorHandle(object, name) {
    // 	if (object) {
    // 		object.forEach((obj, index) => {
    // 			Object.entries(obj).forEach((val) => {
    // 				let errorName = this.nameToUpperCase(val[0]);
    // 				if (!val[1]) {
    // 					this.setErrors(
    // 						`${name}[${index}]`,
    // 						`${val[0]}`,
    // 						`${errorName} is required`
    // 					);
    // 				} else {
    // 					this.setErrors(`${name}[${index}]`, `${val[0]}`, false);
    // 					this.errors = this.objectSplice(
    // 						this.errors,
    // 						`${name}[${index}].${val[0]}`
    // 					);
    // 				}
    // 			});
    // 		});
    // 	}
    // },

    boardOfDirectors() {
      // this.errorHandle(this.member.info.directors, "directors");
    },
    boardOfShareholders() {
      // this.errorHandle(this.member.info.shareholders, "shareholders");
    },
    boardOfPartners() {
      // this.errorHandle(this.member.info.partner, "partner");
    },
    remove_readonly() {
      Promise.delay(500).then(function () {
        $("input").each(function () {
          $(this).removeAttr("readonly");
          $(this).removeAttr("disabled");
        });
        $("select").each(function () {
          $(this).removeAttr("disabled");
        });
        $("textarea").each(function () {
          $(this).removeAttr("readonly");
          $(this).removeAttr("disabled");
        });
      });
    },

    attach(e, key, index) {
      if (!this.$root.variable.aLogId) {
        const file = e.target.files[0];
        if (!file) {
          e.preventDefault();
          alert("No file chosen");
          return;
        }
        const isPDF = file["type"].split("/")[1] == "pdf" ? true : false;
        if (!isPDF) {
          if (key == "trade_pdf") {
            if (this.member.info && this.member.info.trade_license) {
              this.member.info.trade_license[index][key] = "";
            } else {
              this.images.trade_license[index][key] = "";
            }
          } else {
            if (this.member.info && this.member.info.partner) {
              this.member.info.partner[index][key] = "";
            } else {
              this.images.partner[index][key] = "";
            }
          }
          this.$toastr("warning", "PDF format required", "Warning!!");
          document.getElementById(key + index).value = "";
          return;
        }
        let mb = 1024 * 1024;
        if (file.size > 7 * mb) {
          e.preventDefault();
          setTimeout(() => {
            console.log(key);
            if (key == "trade_pdf") {
              if (this.member.info && this.member.info.trade_license) {
                this.member.info.trade_license[index][key] = "";
              } else {
                this.images.trade_license[index][key] = "";
              }
            } else {
              if (this.member.info && this.member.info.partner) {
                this.member.info.partner[index][key] = "";
              } else {
                this.images.partner[index][key] = "";
              }
            }
            document.getElementById(key + index).value = "";
          }, 200);
          this.$toastr(
            "warning",
            "File size must be smaller than 7mb",
            "Warning!!"
          );
          return;
        }
      }
    },
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  mounted() {
    this.loggedIn();
    this.getList("districts", "districts");
    if (this.$root.variable.aLogId) {
      this.remove_readonly();
    }
    setTimeout(() => (this.$parent.loading = false), 1500);
  },
  updated() {
    if (this.$root.variable.aLogId) {
      this.remove_readonly();
    }
  },

  validators: {
    "member.info.company_head_phone": function (value = null) {
      return Validator.value(value).digit();
      // .required("Phone /Mobile number is required")
      // .digit("Phone/ mobile number must be digit")
      // .regex("01+[0-9]*$", "Must start with 01.")
      // .minLength(11)
      // .maxLength(11);
    },
    "member.info.company_head": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Company Head Name is required");
      }
    },
    "member.info.email": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value)
          .required("Email is required")
          .email("Email must be an email formatted");
      }
    },
    "member.info.company_head_email": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value)
          .required("Company Head Email is required")
          .email("Email must be well formatted");
      }
    },
    "member.info.company_head_mobile": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value)
          .required("Company Head Mobile is required")
          .digit()
          .regex("01+[0-9+-]*$", "Must start with 01.")
          .minLength(11)
          .maxLength(11);
      }
    },
    "member.info.phone": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Phone is required");
        // .digit()
        // .regex("^+d{8,11}(,+d{8,11})*$", "Numeric only");
      }
    },
    "member.info.website": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Website is required");
        // .url("Website URL Must be http:// or https://");
      }
    },
    "member.info.company_head_linkedin": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).url("URL Must be formatted");
      }
    },
    "member.info.short_profile": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Short Profile is Required");
      }
    },
    "member.info.company_name": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Company Name is Required");
      }
    },
    "member.info.membership_no": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Membership Number is Required");
      }
    },
    "member.info.establishment_month": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required(
          "Establishment Month is Required"
        );
      }
    },
    "member.info.establishment_year": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required(
          "Establishment Year is Required"
        );
      }
    },
    "member.info.establishment_month": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Company Name is Required");
      }
    },
    "member.info.district_id": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("District is Required");
      }
    },
    "member.info.postcode": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value)
          .required("Postcode is Required")
          .digit("Postcode must be digit");
      }
    },
    "member.info.date": function (value = null) {
      if (!this.$root.variable.aLogId) {
        // return Validator.value(value).required(
        //   "Membership Approval Date is Required"
        // );
      }
    },
    "member.info.address": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Office Address is Required");
      }
    },
    "member.info.fax": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).digit("Fax Number must be digit");
      }
    },
    "member.info.other_website": function (value = null) {
      if (!this.$root.variable.aLogId) {
        // return Validator.value(value).url("Website URL must be well formatted");
      }
    },
    "member.info.business_type": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Business Type is required");
      }
    },
    "member.info.legal_structure": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Company Type is required");
      }
    },
    "member.info.designation": function (value = null) {
      if (!this.$root.variable.aLogId) {
        return Validator.value(value).required("Designation is required");
      }
    },

    "images.logo": function (value = null) {
      if (!this.$root.variable.aLogId) {
        if (this.member.info) {
          if (!this.member.info.logo) {
            return Validator.value(value).required("Logo is required");
          }
        }
      }
    },
    "images.head_image": function (value = null) {
      if (!this.$root.variable.aLogId) {
        if (this.member.info) {
          if (!this.member.info.company_head_image) {
            return Validator.value(value).required(
              "Company Head Image is required"
            );
          }
        }
      }
    },
    "images.memo_article": function (value = null) {
      if (!this.$root.variable.aLogId) {
        if (this.member.info) {
          if (
            this.member.info.legal_structure == "Others" ||
            this.member.info.legal_structure == "Limited Company"
          ) {
            if (!this.member.info.memo_article) {
              return Validator.value(value)
                .required("Memorandum of Articles is required")
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var type = value.type;
                    if (type == "application/pdf") {
                    } else {
                      return "PDF format required";
                    }
                  }
                })
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var size = value.size;
                    if (size > 7168000) {
                      return "Pdf must be smaller than 7mb";
                    }
                  }
                });
            } else {
              return Validator.value(value)
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var type = value.type;
                    if (type == "application/pdf") {
                    } else {
                      return "PDF format required";
                    }
                  }
                })
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var size = value.size;
                    if (size > 7168000) {
                      return "Pdf must be smaller than 7mb";
                    }
                  }
                });
            }
          }
        }
      }
    },
    "images.partnership_deed": function (value = null) {
      if (!this.$root.variable.aLogId) {
        if (this.member.info) {
          if (this.member.info.legal_structure == "Partnership") {
            if (!this.member.info.partnership_deed) {
              return Validator.value(value)
                .required("Partnership Deed is required")
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var type = value.type;
                    if (type == "application/pdf") {
                    } else {
                      return "PDF format required";
                    }
                  }
                })
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    console.log(size);
                    var size = value.size;
                    if (size > 7168000) {
                      return "Pdf must be smaller than 7mb";
                    }
                  }
                });
            } else {
              return Validator.value(value)
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var type = value.type;
                    if (type == "application/pdf") {
                    } else {
                      return "PDF format required";
                    }
                  }
                })
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var size = value.size;
                    if (size > 7168000) {
                      return "Pdf must be smaller than 7mb";
                    }
                  }
                });
            }
          }
        }
      }
    },
    "images.certificate_incorporation": function (value = null) {
      if (!this.$root.variable.aLogId) {
        if (this.member.info) {
          if (this.member.info.legal_structure == "Limited Company") {
            if (!this.member.info.certificate_incorporation) {
              return Validator.value(value)
                .required("Certificate of Incorporation is required")
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var type = value.type;
                    if (type == "application/pdf") {
                    } else {
                      return "PDF format required";
                    }
                  }
                })
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var size = value.size;
                    if (size > 7168000) {
                      return "Pdf must be smaller than 7mb";
                    }
                  }
                });
            } else {
              return Validator.value(value)
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var type = value.type;
                    if (type == "application/pdf") {
                    } else {
                      return "PDF format required";
                    }
                  }
                })
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var size = value.size;
                    if (size > 7168000) {
                      return "Pdf must be smaller than 7mb";
                    }
                  }
                });
            }
          }
        }
      }
    },
    "member.info.tin_number": function (value = null) {
      if (!this.$root.variable.aLogId) {
        if (this.member.info) {
          if (this.member.info.legal_structure != "Proprietorship") {
            return Validator.value(value).required("TIN number is required");
          } else {
            return Validator.value(value);
          }
        }
      }
    },
    // BOARD OF DIRECTOR'S
    // "member.info.directors.director_name": function (value = null) {
    // 	if (!this.$root.variable.aLogId) {
    // 		if (this.member.info.directors) {
    // 			if (this.member.info.legal_structure != "Limited Company") {
    // 				return Validator.value(value).required(
    // 					"name for directors is required"
    // 				);
    // 			} else {
    // 				return Validator.value(value);
    // 			}
    // 		}
    // 	}
    // },
    "images.tin_certificate": function (value = null) {
      if (!this.$root.variable.aLogId) {
        if (this.member.info) {
          if (this.member.info.legal_structure != "Proprietorship") {
            if (!this.member.info.tin_certificate) {
              return Validator.value(value)
                .required("Tin Certificate is required")
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var type = value.type;
                    if (type == "application/pdf") {
                    } else {
                      return "PDF format required";
                    }
                  }
                })
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var size = value.size;
                    if (size > 7168000) {
                      return "Pdf must be smaller than 7mb";
                    }
                  }
                });
            } else {
              return Validator.value(value)
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var type = value.type;
                    if (type == "application/pdf") {
                    } else {
                      return "PDF format required";
                    }
                  }
                })
                .custom(function () {
                  if (!Validator.isEmpty(value)) {
                    var size = value.size;
                    if (size > 7168000) {
                      return "Pdf must be smaller than 7mb";
                    }
                  }
                });
            }
          }
        }
      }
    },
  },
};
</script>

