var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-menu"},[_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"dropdown"},[_vm._m(0),_vm._v(" "),(Object.keys(_vm.categories).length > 0)?_c('div',{staticClass:"dropdown-menu p-0 category-dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuLink"}},_vm._l((_vm.categories),function(category,key){return _c('router-link',{key:key,staticClass:"dropdown-item",attrs:{"to":{
              name: 'ecommerce.products',
              params: { slug: category.slug },
              query: { slug: category.slug },
            }}},[_vm._v("\n            "+_vm._s(category.name)+"\n            "),_c('i',{staticClass:"fa fa-angle-right text-muted"}),_vm._v(" "),(
                category.childs && Object.keys(category.childs).length > 0
              )?_c('ul',{staticClass:"child-category"},_vm._l((category.childs),function(child,chIndex){return _c('li',{key:chIndex},[_c('router-link',{attrs:{"to":{
                    name: 'ecommerce.products',
                    params: { slug: child.slug },
                    query: {
                      slug: category.slug,
                      type: 'sub-category',
                    },
                  }}},[_c('span',[_vm._v(_vm._s(child.name))]),_vm._v(" "),_c('img',{staticStyle:{"width":"1em"},attrs:{"src":child.image,"alt":""}})])],1)}),0):_vm._e()])}),1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"topcategory d-none d-lg-block"},[_c('ul',_vm._l((_vm.popularCategories),function(popular,key){return _c('li',{key:key},[_c('router-link',{attrs:{"to":{
                name: 'ecommerce.products',
                params: { slug: popular.slug },
                query: { slug: popular.slug },
              }}},[_vm._v(_vm._s(popular.name))])],1)}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"category-dropdown dropdown-toggle",attrs:{"href":"#","role":"button","id":"dropdownMenuLink","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-list-ul"}),_vm._v(" Categories\n        ")])
}]

export { render, staticRenderFns }