import { render, staticRenderFns } from "./EventCalender.vue?vue&type=template&id=751a8587&scoped=true"
import script from "./EventCalender.vue?vue&type=script&lang=js"
export * from "./EventCalender.vue?vue&type=script&lang=js"
import style0 from "./EventCalender.vue?vue&type=style&index=0&id=751a8587&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "751a8587",
  null
  
)

export default component.exports