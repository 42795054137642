<template>
  <div class="gallery-tab">
    <div class="container-wrapper">
      <div class="title-container">
        <div>
          <h3 class="title">Our Gallery</h3>
        </div>
        <div class="filters filter-menu">
          <span
            class="filter"
            v-bind:class="{ active: currentFilter === 'ALL' }"
            v-on:click="setFilter('ALL')"
            >All</span
          >
          <span
            class="filter"
            v-bind:class="{ active: currentFilter === 'PHOTO' }"
            v-on:click="setFilter('PHOTO')"
            >Photo Gallery</span
          >
          <span
            class="filter"
            v-bind:class="{ active: currentFilter === 'VIDEO' }"
            v-on:click="setFilter('VIDEO')"
            >Video Gallery</span
          >
        </div>
      </div>

      <transition-group class="projects row" name="projects">
        <div
          class="project col-6 col-lg-4"
          v-for="(photo, index) in photosVideos"
          v-bind:key="index + 1"
          v-if="currentFilter === photo.category || currentFilter === 'ALL'"
        >
          <div class="project-image-wrapper">
            <div class="image">
              <LazyYoutubeVideo
                v-if="photo.category == 'VIDEO'"
                :url="photo.y_url"
                :thumbnail="{ webp: photo.url, jpg: photo.url }"
              />
              <img v-lazy="photo.url" alt="photo" v-else />
              <div class="overlay" v-if="photo.category != 'VIDEO'">
                <div class="icons">
                  <!-- <a href="#" class="icon-img">
                    <i class="fa fa-search-plus"></i>
									</a>-->
                  <!-- <a href="#" target="_blank">
                    <i title="Details" class="fa fa-link"></i>
									</a>-->
                  <router-link
                    :to="{
                      name: 'photo_details',
                      params: { photo_gallery_id: photo.id, type: 'album' },
                    }"
                  >
                    <i title="Details" class="fa fa-link"></i>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="info">
              <h4>{{ photo.title }}</h4>
            </div>
          </div>
        </div>
      </transition-group>
      <div class="row mt-3 mb-5">
        <div class="col-lg-12 text-center">
          <router-link
            to="/photo-gallery"
            class="btn btn-lg btn-primary rounded-50"
            >View All Photos</router-link
          >
          <router-link
            to="/video-gallery"
            class="btn btn-lg btn-primary rounded-50"
            >View All Videos</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyYoutubeVideo from "vue-lazy-youtube-video";
import { gsap } from "gsap";
import LightBox from "vue-image-lightbox";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");
export default {
  components: {
    LightBox,
    LazyYoutubeVideo,
  },
  data() {
    return {
      currentFilter: "ALL",
      photosVideos: [],
    };
  },
  methods: {
    setFilter: function (filter) {
      this.currentFilter = filter;
    },
    getGallery() {
      axios.get("/photoVideoGallery").then((respons) => {
        this.photosVideos = respons.data;
      });
    },
  },
  mounted() {
    setTimeout(() => this.getGallery(), 270);
  },
};
</script>
