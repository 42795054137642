<template>
	<div id="example-content">
		<div class="service-tab">
			<section class="event-list">
				<div class="container">
					<div class="row">
						<div class="col-lg-12" v-for="(item, index) in events" :key="index">
							<div class="single-item">
								<div class="date"></div>
								<div class="event-item">
									<a href="javascript:void(0);" class="d-block w-100">
										<div class="row">
											<div class="col-lg-3">
												<div
													class="
														time
														d-flex
														justify-content-center
														align-items-center
													"
												>
													<span>
														{{ index | formatDate("Do MMM") }}
													</span>
												</div>
											</div>
											<div class="col-lg-9">
												<div class="info">
													<ul>
														<li
															v-for="(post, index1) in item"
															:key="index1"
															class="mb-4 d-block"
														>
															<h3 class="col-lg-9">
																{{ getPostBody(post.event_name, 70) }}
															</h3>
															<div class="row">
																<div class="col-lg-6">
																	<span v-if="post.location">
																		<i
																			class="fa fa-map-marker"
																			aria-hidden="true"
																		></i>

																		<span>{{
																			getPostBody(post.location, 30)
																		}}</span>
																	</span>
																</div>
																<div class="col-lg-6">
																	<span v-if="post.from_time && post.to_time">
																		<i
																			class="fa fa-clock-o"
																			aria-hidden="true"
																		></i>
																		<span
																			>{{ post.from_time }} -
																			{{ post.to_time }}</span
																		>
																	</span>
																</div>
															</div>
															<hr />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="calander-button mt-4">
			<router-link
				:to="{ name: 'allPreviousEvent' }"
				class="btn btn-sm btn-outline-primary"
				>Previous Events</router-link
			>
		</div>
	</div>
</template>
<script>
	const breadcumb = [
		{
			title: "Event",
		},
	];
	export default {
		name: "Event",
		data() {
			return {
				breadcumb: breadcumb,
				breadcrumbTitle: "Event",
				international: false,
				local: false,
				events: null,
				awards: null,
				activeEvets: false,
			};
		},
		methods: {
			asyncData() {
				this.get_datas(this.$route.params.market, this.$route.params.type);
				this.$root.loading = false;
			},
			get_datas: function (market = null, type = null, date = null) {
				if (market == "international") {
					this.local = false;
					this.international = true;
				} else {
					this.local = true;
					this.international = false;
				}
				let url = "/event-calender-data";
				this.$root.spinner = true;
				axios
					.get(url)
					.then((respons) => {
						console.log(respons.data);
						this.events = respons.data;
						// this.awards = respons.data.awards;
						// this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
						this.$root.spinner = false;
					})
					.catch((error) => {});
			},
			eTitle(eTitle) {
				return eTitle ? String(eTitle).substring(0, 35) : "";
			},
			getEvent(market, type) {
				var date = this.$route.params.date ? this.$route.params.date : "";
				this.get_datas(market, type, date);
			},
		},
		mounted() {
			var date = this.$route.params.date ? this.$route.params.date : "";
			this.get_datas(this.$route.params.market, this.$route.params.type, date);
			if (date) {
				this.activeEvets = true;
			}
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		watch: {
			$route: {
				handler: "asyncData",
				immediate: true,
			},
		},
	};
</script>
<style scoped>
	.sidebar-menu.vue-affix {
		top: 0px !important;
		width: 280px;
		animation: FadeUp 1s ease forwards;
	}
	.sidebar-menu.vue-affix.affix {
		top: 70px !important;
		z-index: 1030;
	}

	@keyframes FadeUp {
		form {
			top: 0;
		}
		to {
			top: 70px !important;
		}
	}
	/* event list part start */

	.new-date {
		text-align: center !important;
		justify-content: center !important;
		font-size: 25px;
		margin-left: auto;
		margin-right: auto;
	}

	.event-list .single-item {
		margin-bottom: 30px;
	}

	.event-list .single-item:last-child {
		margin-bottom: 0px;
	}

	.event-list .event-item {
		border-radius: 5px;
		box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
		margin-bottom: 20px;
	}

	.event-list .event-item:last-child {
		margin-bottom: 0;
	}

	.event-list .date {
		padding-bottom: 14px;
		margin-bottom: 20px;
	}

	.event-list .date h4 {
		font-size: 24px;
		font-weight: 700;
		color: #070919;
	}

	.event-list .time {
		background-color: #6dc8be;
		color: #ffffff;
		width: 100%;
		height: 100%;
		text-align: center;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.event-list .time span {
		font-size: 18px;
		font-weight: 600;
		color: #ffffff;
	}

	.event-list .info {
		padding: 20px 0;
	}

	.event-list .info h3 {
		font-size: 20px;
		font-weight: 700;
		color: #6dc8be;
		margin-bottom: 20px;
		margin-left: -10px;
	}

	.event-list .info ul li {
		font-size: 15px;
		font-weight: 400;
		color: #070919;
		display: inline-block;
		margin-right: 40px;
	}

	.event-list .info ul li i {
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		border-radius: 50%;
		background-color: #ed1c24;
		color: #fff;
		font-size: 14px;
		margin-right: 8px;
	}

	/* .event-list .info ul li:nth-child(2) i {
					background-color: #007f3d;
				} */

	/* event list part end */
	@media (min-width: 360px) and (max-width: 767.98px) {
		.col-lg-9,
		.col-lg-3 {
			padding: 24px !important;
		}
		.event-item .info ul li span {
			margin-bottom: 10px;
			display: inline-block;
		}
	}
	@media (min-width: 768px) and (max-width: 991.98px) {
		.col-lg-9,
		.col-lg-3 {
			padding: 24px !important;
		}
		.event-item .info ul li span {
			margin-bottom: 10px;
			display: inline-block;
		}
	}
</style>
