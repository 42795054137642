<template>
  <div class="member-services" v-if="!$root.spinner">
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="px-3 mb-0">Pending Change Request</h5>
          <router-link
            :to="{ name: 'changeRequest' }"
            class="nav-link router-link-active border border-primary btn mr-3"
          >
            <b>Back</b></router-link
          >
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead class="bg-success text-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Application Date</th>
              <th scope="col">Company Name</th>
              <th scope="col">Changes Type</th>
              <th scope="col" class="text-center">Tracking Status</th>
            </tr>
          </thead>
          <tbody>
            <slot v-if="table.datas && Object.keys(table.datas).length > 0">
              <tr v-for="(history, index) in table.datas" :key="index">
                <th>{{ index + 1 }}</th>
                <td>{{ history.created_at | formatDate("MMMM, DD YYYY") }}</td>
                <td>
                  <slot v-if="history.member">
                    {{ history.member.company_name }}
                  </slot>
                </td>
                <td>
                  <slot v-if="history.representative_status == 'p'">
                    Representative Change<br />
                  </slot>
                  <slot v-if="history.company_type_status == 'p'">
                    Company Type Change<br />
                  </slot>
                  <slot v-if="history.company_name_status == 'p'">
                    Company Name Change <br
                  /></slot>
                  <slot v-if="history.membership_category_status == 'p'">
                    Membership Category_change
                  </slot>
                </td>
                <td class="text-center">
                  <span v-if="history.comments">
                    {{ history.comments }}
                  </span>
                  <span class="text-warning" v-else>
                    <b>PENDING</b>
                  </span>
                </td>
              </tr>
            </slot>
            <slot v-else>
              <tr v-if="!$root.spinner">
                <td colspan="6" class="text-center">No data found</td>
              </tr>
            </slot>
          </tbody>
        </table>
        <!-- pagination -->
        <Pagination :url="'/member/allExistingService'" />
        <!-- /pagination -->
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "../../../../components/Pagination";
const breadcumb = [
  {
    title: "Pending Change Request",
  },
];
export default {
  name: "MyService",
  components: { Pagination },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Pending Change Request",
      member: {
        info: {},
      },
      table: { datas: [] },
      meta: [],
      links: [],
    };
  },
  validators: {},
  methods: {
    getHistory: function (pageindex) {
      this.$root.spinner = true;
      let url = "/member/get-pending-change-request";
      if (pageindex > 0) {
        url = "/member/get-change-history?page=" + pageindex;
      }
      axios
        .get(url)
        .then((response) => {
          this.table.datas = response.data.data;
          this.meta = response.data.meta;
          this.links = response.data.links;
        })
        .catch((error) => {})
        .then((always) => {
          this.$root.spinner = false;
        });
    },
  },
  mounted() {
    this.loggedIn();
    this.getHistory();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
};
</script>

