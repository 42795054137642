<template>
	<div class="basis-ecommerce-section">
		<div class="container-wrapper">
			<div class="content-header text-center mb-5">
				<h1 class="title">DigitalShop Product</h1>
			</div>

			<VueSlickCarousel v-bind="settings" v-if="productCategory.length">
				<div v-for="(subcat, key) in productCategory" :key="key" class="col-lg-12 my-4">
					<div class="sub-category-product">
						<router-link
							:to="{
                                name: 'ecommerce.products',
                                params: { slug: subcat.slug },

                                query: {
                                slug:subcat.parent_category.slug,
                                type: 'sub-category',
                                },
                            }"
						>
							<div class="image">
								<img class="product-image" :src="subcat.image" alt />
							</div>
							<h5 class="product-title">{{ subcat.name }}</h5>
						</router-link>
						<router-link
							:to="{
                                name: 'ecommerce.products',
                                params: { slug: subcat.slug },

                                query: {
                                slug:subcat.parent_category.slug,
                                type: 'sub-category',
                                },
                            }"
							class="add-to-cart-button"
						>
							View All
							<i class="fa fa-long-arrow-right"></i>
						</router-link>
					</div>
				</div>
			</VueSlickCarousel>

			<div class="row pt-5">
				<div class="col-lg-12 text-center">
					<router-link :to="{ name:'ecommerce.home'}" class="btn btn-lg btn-primary rounded-50">
						Show More
						<i class="fas fa-long-arrow-alt-right pl-1"></i>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VueSlickCarousel from "vue-slick-carousel";
	import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

	export default {
		components: { VueSlickCarousel },
		name: "Ecommerce",
		data: () => ({
			productCategory: {},
			settings: {
				autoplay: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
				arrows: true,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 4,
							slidesToScroll: 1,
							infinite: true,
							dots: true
						}
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							initialSlide: 2
						}
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			}
		}),

		created() {
			axios.get("/ecommerce/get-home-category").then(res => {
				this.productCategory = res.data;
			});
		}
	};
</script>

<style scoped lang="scss">
	.basis-ecommerce-section {
		padding: 4rem 0;

		.sub-category-product {
			position: relative;
			overflow: hidden;
			border-radius: 6px;
			padding: 10px;
			transition: transform 0.6s ease;
			box-shadow: 0px 0px 2px #4444;

			&:hover {
				img.product-image {
					transform: scale(1.3);
				}
			}

			.image {
				height: 16em;
				position: relative;
				overflow: hidden;
			}

			img.product-image {
				transition: transform 0.6s ease;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			a.add-to-cart-button {
				background: #6dc8be;
				padding: 7px 19px;
				color: white;
				font-size: 16px;
				border-radius: 50px;
				margin: 4px 33px;
				display: block;
				text-align: center;

				i {
					opacity: 0;
					position: relative;
					animation: mymove 0.9s infinite;
				}

				@keyframes mymove {
					from {
						left: 0px;
					}
					to {
						right: 4px;
						opacity: 0;
					}
				}

				&:hover {
					i {
						opacity: 1;
					}
				}
			}

			h5.product-title {
				color: black;
				font-size: 21px;
				margin: 15px 0px;
			}
		}
	}
</style>
