<template>
  <div class="member-services" v-if="!$parent.loading">
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0 mt-1">
        <div class="justyfy text-center">
          <h5 class="px-3 mb-0">Generate Invoice</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="form-row col-12 my-5">
            <div class="col-12 text-center">Please wait a moment...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  name: "PaymentMethod",
  props: ["memberInfo"],
  data() {
    return {
      loading: false,
      data: { payment_method: "ssl" },
      errors: {},
      member: {
        info: {},
      },
    };
  },
  methods: {
    generateInvoice: function () {
      this.loading = true;
      if (this.getLocalStgString("service_amount")) {
        this.data.amount = this.getLocalStgString("service_amount");
      }
      axios
        .post("/member/generate-invoice", this.data)
        .then((response) => {
          if (response.data) {
            if (response.data.status == 201) {
              localStorage.removeItem("recp_id");
              localStorage.removeItem("serv_type");
              localStorage.removeItem("service_amount");
              this.$router.push({
                name: "memberInvoice",
                params: { id: response.data.id },
              });
            }
            if (response.data.status == 424) {
              this.$toastr("error", response.data.msg, "Error!!");
            }
          }
        })
        .catch((error) => console.log(error))
        .then((alw) => (this.loading = false));
    },
  },
  created() {
    this.loggedIn();
    setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefd);
    this.data.type = this.getLocalStgString("serv_type");

    if (this.data.type == "category") {
      this.data.account_head_id = this.getLocalStgString("recp_id");
    } else {
      this.data.service_receipent_id = this.getLocalStgString("recp_id");
    }
    this.data.service_id = this.$route.params.id;
    this.data.amount = this.$route.params.amount;

    setTimeout(() => this.generateInvoice(), 200);
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
};
</script>

