import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'aos/dist/aos.css'
import Pagination from './components/Pagination'
import Spinner from './components/Spinner'
// import './assets/css/frontend/flaticon.css'
// import './assets/css/frontend/materialdesignicons.min.css'
// import './assets/css/frontend/all.min.css'
// import './assets/css/frontend/bootstrap.min.css'
// import './assets/css/frontend/style.css'
// import './assets/css/frontend/style2.css'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
    // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
    listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
})
import moment from 'moment'
import VueToastr from '@deveodk/vue-toastr'
import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css'
import 'vue-search-select/dist/VueSearchSelect.css'

// import 'vue2-datepicker/index.css';
import Datepicker from "vuejs-datepicker";
import Promise from "bluebird";

Vue.component('Spinner', Spinner)
Vue.component('Pagination', Pagination)
Vue.use(BootstrapVue)
Vue.use(VueToastr)
Vue.use(Datepicker)
Vue.component('Datepicker', Datepicker)


import ProductZoomer from 'vue-product-zoomer'

Vue.use(ProductZoomer)


try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap')
} catch (e) {
}
Vue.filter('formatDate', function (value, format = 'll') {
    if (value) {
        return moment(String(value)).format(format)
    }
})
Vue.filter('currency', function (value) {
    if (value) {
        return new Number(value).toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
})
Vue.filter('highlight', function (words, query) {
    return words.replace(query, '<a class="highlight">' + query + '</a>')
});
Vue.filter('prettify', function (value) {
    let data = value.split(":");
    let minutes = data[0];
    let secondes = data[1];
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (secondes < 10) {
        secondes = "0" + secondes;
    }
    return minutes + "m : " + secondes + 's';
});
Vue.filter('prettify_hours', function (value) {
    let data = value.split(":");
    let hours = data[0];
    let minutes = data[1];
    let secondes = data[2];
    if (parseInt(hours) < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (secondes < 10) {
        secondes = "0" + secondes;
    }
    return hours + "h : " + minutes + "m : " + secondes + 's';
});
Vue.filter('inWords', function (n) {
    var string = n.toString(),
        units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words;

    /* Is number zero? */
    if (parseInt(string) === 0) {
        return 'zero';
    }
    /* Array of units as words */
    units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    /* Array of tens as words */
    tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    /* Array of scales as words */
    scales = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'];
    /* Split user arguemnt into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while (start > 0) {
        end = start;
        chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }
    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
        return '';
    }
    /* Stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {
        chunk = parseInt(chunks[i]);
        if (chunk) {
            /* Split chunk into array of individual integers */
            ints = chunks[i].split('').reverse().map(parseFloat);
            /* If tens integer is 1, i.e. 10, then add 10 to units integer */
            if (ints[1] === 1) {
                ints[0] += 10;
            }
            /* Add scale word if chunk is not zero and array item exists */
            if ((word = scales[i])) {
                words.push(word);
            }
            /* Add unit word if array item exists */
            if ((word = units[ints[0]])) {
                words.push(word);
            }
            /* Add tens word if array item exists */
            if ((word = tens[ints[1]])) {
                words.push(word);
            }
            /* Add hundreds word if array item exists */
            if ((word = units[ints[2]])) {
                words.push(word + ' hundred');
            }
        }
    }
    return words.reverse().join(' ');
})
Vue.use(VueToastr, {
    defaultPosition: 'toast-bottom-right',
    defaultType: 'info',
    defaultTimeout: 800
});
