<template>
  <div class="container">
    <div
      class="product-section-title d-flex justify-content-between align-items-center ">
      <h3 v-if="category">{{ category.name }}</h3>
    </div>
    <slot v-if="category.childs && Object.keys(category.childs).length > 0">
      <SubCategory :subCategory="category.childs" />
    </slot>
  </div>
</template>

<script>
import SubCategory from "./SubCategory";

export default {
  name: "Category_product",
  components: { SubCategory },
  data() {
    return {
      category: {},
    };
  },
  methods: {
    getSubcategory() {
      axios
        .get("ecommerce/get-all-subcategory/" + this.$route.params.slug)
        .then((res) => {
          this.category = res.data;
        });
    },
  },
  created() {
    this.getSubcategory();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
};
</script>

<style scoped>
</style>
