<template>
	<div>
		<h4 class="mt-3">Help Desk Lists</h4>
		<div class="card card-body mt-3">
			<table class="table" v-if="helpDeskList.length > 0">
				<tr>
					<th>Ticket ID</th>
					<th>Subject</th>
					<th>Status</th>
					<th>Created At</th>
					<th>Action</th>
				</tr>
				<tr v-for="(item, key) in helpDeskList" :key="key">
					<td>{{ item.ticket_id }}</td>
					<td>{{ item.subject }}</td>
					<td>
						<span v-if="item.reply_status =='open'" class="badge badge-success">Open</span>
						<span v-else class="badge badge-danger">Closed</span>
					</td>
					<td>{{ item.created_at }}</td>
					<td>
						<router-link :to="{name:'ecommerce.helpDeskView',params:{id:item.id}}">
							<i class="fa fa-eye"></i> view
						</router-link>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
	export default {
		name: "HelpDeskList",
		data() {
			return {
				helpDeskList: []
			};
		},
		methods: {
			getHelpDeskList() {
				// My Order
				axios
					.get("/ecommerce/get-help-desk-list")
					.then(res => (this.helpDeskList = res.data));
			}
		},

		created() {
			this.getHelpDeskList();
		},

		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		}
	};
</script>

<style scoped>
	th {
		background: rgb(244 244 244);
		border: 0;
		box-shadow: 0px 1px 1px #4444;
	}
	td {
		border: 0;
		background: rgb(255 255 255);
		box-shadow: 0px 0px 1px #4444;
	}
</style>
