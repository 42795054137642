<template>
  <div class="slider">
    <div
      id="carouselExampleCaptions"
      class="carousel vert slide"
      data-pause="false"
      data-ride="carousel"
      data-interval="5000"
    >
      <ol class="carousel-indicators">
        <slot v-for="(slider,index) in extraData.sliderData.slider_content">
          <li
            v-if="!index"
            :key="index"
            data-target="#carouselExampleCaptions"
            data-slide-to="0"
            class="active"
          ></li>
          <li v-else :key="index" data-target="#carouselExampleCaptions" :data-slide-to="index"></li>
        </slot>
        <!-- <li data-target="#carouselExampleCaptions" data-slide-to="2"></li> -->
      </ol>
      <div class="carousel-inner">
        <slot v-for="(slider,index) in extraData.sliderData.slider_content">
          <div v-if="!index" :key="index" class="carousel-item active">
            <div class="row">
              <div class="col-lg-8 p-0">
                <img v-lazy="'public'+slider.image" class="d-block w-100" alt="public slider" />
              </div>
              <div class="col-lg-4 p-0">
                <div class="slider-caption">
                  <span class="shape"></span>
                  <div class="caption">
                    <h4 v-if="slider.url_link"></h4>
                    <h1>{{slider.title}}</h1>
                    <p>{{slider.description}}</p>
                    <a v-if="slider.url_link" target="_blank" :href="slider.url_link">
                      Get started
                      <span class="mdi mdi-arrow-right"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else :key="index" class="carousel-item">
            <div class="row">
              <div class="col-lg-8 p-0">
                <img v-lazy="'public'+slider.image" class="d-block w-100" alt="public slider" />
              </div>
              <div class="col-lg-4 p-0">
                <div class="slider-caption">
                  <span class="shape"></span>
                  <div class="caption">
                    <h1>{{slider.title}}</h1>
                    <p>{{slider.description}}</p>
                    <a v-if="slider.url_link" :href="slider.url_link">
                      Get started
                      <span class="mdi mdi-arrow-right"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </slot>
      </div>
    </div>
    <div class="banner-anime-button">
      <a href="#" v-scroll-to="{ el: '#counter', offset: -50 }">
        <span>
          <i class="fas fa-arrow-circle-down"></i>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
const Vue = require("vue");
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
export default {
  data() {
    return {
      extraData: {
        sliderData: []
      }
    };
  },
  created() {
    this.getData("Slider", 1, "sliderData", "admin");
  },
  mounted() {
    $(".carousel").carousel({
      interval: 5000
    });
  },
  updated() {
    if (this.extraData.sliderData) {
      setTimeout(() => (this.$root.loading = false), 100);
    }
  }
};
</script>
