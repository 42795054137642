<template >
  <div v-if="data">
    <h4 class="text-center">
      <span class="text-success">BRIEF OF COMPANY</span>
    </h4>
    <p v-html="data.short_profile"></p>
  </div>
</template>


<script>
export default {
  name: "ModalReadMore",
  props: ["data"]
};
</script>

<style>
</style>