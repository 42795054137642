<template>
	<div class="col-md-3 d-none d-lg-block" :class="$parent.showSidemenu?'d-block':''">
		<div class="leftmenu-bar">
			<button
				@click="$parent.showSidemenu = false"
				type="button"
				title="click to view menu"
				class="d-block d-lg-none btn btn-secondary menu-close"
			>
				<i class="fa fa-times"></i>
			</button>
			<div class="card card-body custom-bg-shadow-redius mb-2">
				<h5>
					<router-link class="text-dark" :to="{ name: 'ecommerce.dashboard' }">
						<i class="mdi mdi-monitor-dashboard text-muted"></i> Dashboard
					</router-link>
				</h5>
			</div>
			<div class="card card-body custom-bg-shadow-redius mb-2">
				<h2>
					<i class="fas fa-user-circle"></i>
					<span :style="name_length > 8 ? 'font-size:16px':''">{{ $root.loggedCustomer.name }}</span>
				</h2>
				<p style="margin-left: 31px;">{{ $root.loggedCustomer.email }}</p>
				<p style="margin-left: 31px;">{{ $root.loggedCustomer.mobile }}</p>
			</div>
			<div class="card card-body custom-bg-shadow-redius mb-2">
				<h4 class="mt-2">My account</h4>
				<ul class="navbar-nav ml-4">
					<li class="nav-item">
						<router-link :to="{ name: 'ecommerce.profile' }">
							<i class="mdi mdi-account text-primary"></i>Account information
						</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'ecommerce.password-change' }">
							<i class="fa fa-key text-warning"></i> Change Password
						</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'ecommerce.deliveryaddress' }">
							<i class="fa fa-address-book"></i> Delivery address
						</router-link>
					</li>
				</ul>
			</div>
			<div class="card card-body custom-bg-shadow-redius mb-2">
				<h4>Order information</h4>
				<ul class="navbar-nav ml-4">
					<li class="nav-item">
						<router-link :to="{ name: 'ecommerce.myorders' }">
							<i class="fa fa-box-open text-info"></i> My Orders
						</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'ecommerce.cancelorder' }">
							<a href>
								<i class="fa fa-minus-circle"></i> Cancel Order
							</a>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'ecommerce.wishlist' }">
							<i class="fa fa-heart-o text-danger"></i> My Wishlist
						</router-link>
					</li>
				</ul>
			</div>
			<div class="card card-body custom-bg-shadow-redius mb-2">
				<h4>Help Desk</h4>
				<ul class="navbar-nav ml-4">
					<li class="nav-item">
						<router-link :to="{ name: 'ecommerce.help-desk' }">
							<i class="fas fa-headset text-info"></i> Create new ticket
						</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'ecommerce.help-desklist' }">
							<i class="fas fa-stream text-danger"></i> Ticket list
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "LeftmenuBar",
		data() {
			return {
				isShowMenu: false,
				name_length: ""
			};
		},
		methods: {
			nameFontSizeChnage() {
				let name = this.$root.loggedCustomer.name;
				this.name_length = name.length;
			}
		},
		created() {
			this.nameFontSizeChnage();
		}
	};
</script>

<style scoped>
	.leftmenu-bar ul li a {
		font-size: 15px;
		color: #666666;
		padding: 4px 1px;
		display: block;
	}
	button.btn.btn-secondary.menu-close {
		position: fixed;
		top: 0;
		right: -4px;
		z-index: 12;
		margin: 10px;
		background: #ed1c24;
		border: 0;
	}
</style>
