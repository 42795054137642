<template>
  <div class="container-wrapper">
    <div class="products my-4">
      <div class="row">
        <div class="col-md-12 d-block text-right d-lg-none">
          <a
            @click="isShowFilterCategory = !isShowFilterCategory"
            class="mobile-filter"
          >
            <i class="fas fa-filter"></i> Filters
          </a>
        </div>
          <div class="blur d-block d-lg-none" @click="isShowFilterCategory = false" v-if="isShowFilterCategory"></div>
        <div
          class="col-md-3 d-lg-block"
          :class="isShowFilterCategory ? 'd-block' : 'd-none'"
        >
          <div class="left-menu">
            <div class="category-menu-filter">
              <h3>Categories</h3>
              <hr style="border-color: #6ec8bf" />
              <nav>
                <ul
                  v-if="Object.keys(categories).length > 0"
                  class="navbar-nav main-menu"
                >
                  <li
                    class="accordion"
                    role="tablist"
                    v-for="(category, key) in categories"
                    :key="key"
                  >
                    <div class="d-flex justify-content-between" role="tab">
                      <router-link
                        class="nav-link d-flex justify-content-between"
                        :to="{
                          name: 'ecommerce.products',
                          params: { slug: category.slug },
                          query: {
                            type: 'category',
                          },
                        }"
                        >{{ category.name }}</router-link
                      >
                      <b-button
                        class="category-plus-button"
                        v-if="Object.keys(category.childs).length > 0"
                        v-b-toggle="'accordion-' + category.slug"
                      >
                        <span></span>
                      </b-button>
                    </div>
                    <b-collapse
                      :id="'accordion-' + category.slug"
                      :visible="
                        category.slug == $route.query.slug ? true : false
                      "
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <ul
                        class="navbar-nav ml-3 childe-menu"
                        v-if="
                          category.childs &&
                          Object.keys(category.childs).length > 0
                        "
                      >
                        <li
                          class="nav-item"
                          v-for="(subCategory, key) in category.childs"
                          :key="key"
                        >
                          <b-form-checkbox
                            :checked="subCategory.slug == $route.params.slug"
                            @change="getSubcategoryProduct(subCategory)"
                          >
                            <span>{{ subCategory.name }}</span>
                          </b-form-checkbox>
                        </li>
                      </ul>
                    </b-collapse>
                  </li>
                </ul>
              </nav>
            </div>
<!--            <div class="price-range my-5">-->
<!--              <h3>Price Range</h3>-->
<!--              <hr />-->
<!--              <VueSimpleRangeSlider-->
<!--                style="width: 100%;z-index: 123456;"-->
<!--                :min="0"-->
<!--                :max="1000000"-->
<!--                :logarithmic="true"-->
<!--                v-model="range"-->
<!--              />-->
<!--              <div class="price-value px-4 d-flex justify-content-between">-->
<!--                <input type="text" v-model="range[0]" />-->
<!--                <input type="text" v-model="range[1]" />-->
<!--              </div>-->
<!--            </div>-->
            <div
              class="price-range my-5"
              v-if="colors && Object.keys(colors).length > 0"
            >
              <h3>Color</h3>
              <hr />
              <ul class="navbar-nav ml-3 childe-menu">
                <li class="nav-item" v-for="color in colors" :key="color">
                  <b-form-checkbox v-model="search_data.colors" :value="color">
                    <span>{{ color }}</span>
                  </b-form-checkbox>
                </li>
              </ul>
            </div>
            <div
              class="price-range my-5"
              v-if="sizes && Object.keys(sizes).length > 0"
            >
              <h3>Size</h3>
              <hr />
              <ul class="navbar-nav ml-3 childe-menu">
                <li class="nav-item" v-for="size in sizes" :key="size">
                  <b-form-checkbox v-model="search_data.sizes" :value="size">
                    <span>{{ size }}</span>
                  </b-form-checkbox>
                </li>
              </ul>
            </div>
            <div class="price-range my-5">
              <h3>Discount</h3>
              <hr />
              <ul class="navbar-nav ml-2 ml-lg-3">
                <li class="nav-item my-2">
                  <b-form-checkbox v-model="search_data.discount" value="10">10% discount</b-form-checkbox>
                </li>
                <li class="nav-item my-2">
                  <b-form-checkbox v-model="search_data.discount" value="15">15% discount</b-form-checkbox>
                </li>
                <li class="nav-item my-2">
                  <b-form-checkbox v-model="search_data.discount" value="20"
                    >20% discount</b-form-checkbox
                  >
                </li>
                <li class="nav-item my-2">
                  <b-form-checkbox v-model="search_data.discount" value="30"
                    >30% discount</b-form-checkbox
                  >
                </li>
                <li class="nav-item my-2">
                  <b-form-checkbox v-model="search_data.discount" value="50"
                    >50% discount</b-form-checkbox
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="filter-ibar">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <h4>{{ current_category.name }}</h4>
              </div>
              <div class="col-lg-6 text-right pr-0">
                <form
                  action
                  class="d-flex align-items-center justify-content-around"
                >
                  <div class="showitem">
                    <span>Show:</span>
                    <select v-model="search_data.pagination">
                      <option value="9">9</option>
                      <option value="12">12</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                  <div class="sortby">
                    <span>Short by:</span>
                    <select v-model="search_data.sorting">
                      <option value>Default</option>
                      <option value="price_high_to_low">
                        Price High to Low
                      </option>
                      <option value="price_low_to_high">
                        Price Low to High
                      </option>
                      <option value="best_sale">Best sale</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!--Product Lists  -->
          <SingleProduct
            v-if="table.datas && Object.keys(table.datas).length > 0"
            :products="table.datas"
          />
          <!--Product List /-->
          <div class="text-center mt-5" v-else>
            <img
              :src="$root.baseurl + '/public/images/no-product-found.jpg'"
              alt
            />
          </div>
          <!-- pagination -->
          <Pagination
            :url="paginationUrl"
            :search_data="search_data"
            v-if="!$root.spinner"
          />
          <!-- /pagination -->
        </div>
      </div>

      <!-- <div class="container-wrapper">
        <div class="footer-banner">
          <img
            class="img-fluid w-100 first-banner"
            src="https://image.freepik.com/free-vector/banners-e-commerce-promotions-with-mid-season-flash-sale-illustration-concept_106954-604.jpg"
            alt
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import SingleProduct from "./../elements/SingleProduct";
import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import RelatedProduct from "../elements/RelatedProduct";
//import Pagination from "../elements/Pagination";

var paginationUrl = "/ecommerce/get-products";

export default {
  name: "Products",
  components: {
    SingleProduct,
    RelatedProduct,
    VueSimpleRangeSlider,
  },
  data() {
    return {
      isShowFilterCategory: false,
      paginationUrl: paginationUrl,
      innerLoader: false,
      field: "category_id",
      range: [0, 1000000],
      categories: [],
      table: {
        datas: [],
      },
      meta: [],
      links: [],
      filters: [],
      colors: [],
      sizes: [],
      current_category: { name: "" },

      search_data: {
        slug: this.$route.params.slug,
        field: "",
        sizes: [],
        discount: [],
        pagination: "9",
        colors: [],
        min_price: "",
        max_price: "",
        sorting: "",
      },
    };
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true,
    },
    range: {
      handler(current, old) {
        this.search_data.min_price = current[0];
        this.search_data.max_price = current[1];

        this.$eventBus.$emit("ecomloadingecomloading", true);

        setTimeout(() => {
          axios
            .get("/ecommerce/get-products", { params: this.search_data })
            .then((res) => {
              this.table.datas = [];
              this.meta = [];
              this.links = [];

              this.table.datas = res.data.data;
              this.meta = res.data.meta;
              this.links = res.data.links;
              setTimeout(
                () => this.$eventBus.$emit("ecomloading", false),
                1000
              );
            });
        }, 1500);
      },
      deep: true,
    },
    "search_data.colors": {
      handler(val) {
        this.search_data.slug = this.$route.params.slug;
        this.search_data.field = this.field;
        if (this.search_data.colors.length > 0) {
          this.$eventBus.$emit("ecomloading", true);
          axios
            .get("/ecommerce/get-products", { params: this.search_data })
            .then((res) => {
              this.table.datas = [];
              this.meta = [];
              this.links = [];

              this.table.datas = res.data.data;
              this.meta = res.data.meta;
              this.links = res.data.links;
              setTimeout(
                () => this.$eventBus.$emit("ecomloading", false),
                1000
              );
            });
        } else {
          this.getProducts();
        }
      },
      deep: true,
    },
    "search_data.sizes": {
      handler(val) {
        this.search_data.slug = this.$route.params.slug;
        this.search_data.field = this.field;
        if (this.search_data.sizes.length > 0) {
          this.$eventBus.$emit("ecomloading", true);
          axios
            .get("/ecommerce/get-products", { params: this.search_data })
            .then((res) => {
              this.table.datas = [];
              this.meta = [];
              this.links = [];

              this.table.datas = res.data.data;
              this.meta = res.data.meta;
              this.links = res.data.links;
              setTimeout(
                () => this.$eventBus.$emit("ecomloading", false),
                1000
              );
            });
        } else {
          this.getProducts();
        }
      },
      deep: true,
    },
    "search_data.discount": {
      handler(val) {
        this.search_data.slug = this.$route.params.slug;
        this.search_data.field = this.field;
        if (this.search_data.discount.length > 0) {
          this.$eventBus.$emit("ecomloading", true);
          axios
            .get("/ecommerce/get-products", { params: this.search_data })
            .then((res) => {
              this.table.datas = [];
              this.meta = [];
              this.links = [];

              res.data.data.forEach((product) => {
                if (product.discount) {
                  this.table.datas.push(product);
                  this.meta = res.data.meta;
                  this.links = res.data.links;
                }
              });
              setTimeout(
                () => this.$eventBus.$emit("ecomloading", false),
                1000
              );
            });
        } else {
          this.getProducts();
        }
      },
      deep: true,
    },
    "search_data.pagination": {
      handler(val) {
        this.search_data.slug = this.$route.params.slug;
        this.search_data.field = this.field;
        if (this.search_data.pagination >= 9) {
          this.$eventBus.$emit("ecomloading", true);
          axios
            .get("/ecommerce/get-products", { params: this.search_data })
            .then((res) => {
              this.table.datas = [];
              this.meta = [];
              this.links = [];

              this.table.datas = res.data.data;
              this.meta = res.data.meta;
              this.links = res.data.links;
              setTimeout(
                () => this.$eventBus.$emit("ecomloading", false),
                1000
              );
            });
        } else {
          this.getProducts();
        }
      },
      deep: true,
    },
    "search_data.sorting": {
      handler(val) {
        this.search_data.slug = this.$route.params.slug;
        this.search_data.field = this.field;
        if (this.search_data.sorting != "") {
          this.$eventBus.$emit("ecomloading", true);
          axios
            .get("/ecommerce/get-products", { params: this.search_data })
            .then((res) => {
              this.table.datas = [];
              this.meta = [];
              this.links = [];

              this.table.datas = res.data.data;
              this.meta = res.data.meta;
              this.links = res.data.links;
              setTimeout(
                () => this.$eventBus.$emit("ecomloading", false),
                1000
              );
            });
        } else {
          this.getProducts();
        }
      },
      deep: true,
    },
  },
  methods: {
    asyncData() {
        this.isShowFilterCategory = false;
      this.$root.loading = false;
      this.$parent.loading = false;
      this.search_data.slug = this.$route.params.slug;
      if (this.$route.query.type == "sub-category") {
        this.field = "sub_category_id";
        this.search_data.field = "sub_category_id";
      } else {
        this.field = "category_id";
        this.search_data.field = "category_id";
        this.getCurrentCategory();
      }
      this.getProducts();
      this.colorSize();
    },
    getCategories() {
      axios.get("/ecommerce/get-categories").then((res) => {
        this.categories = res.data;
      });
    },
    getProducts() {
      this.$eventBus.$emit("ecomloading", true);
      axios
        .get("/ecommerce/get-products", { params: this.search_data })
        .then((res) => {
          this.table.datas = res.data.data;
          this.meta = res.data.meta;
          this.links = res.data.links;
          setTimeout(() => {
            this.$eventBus.$emit("ecomloading", false);
          }, 1000);
        });
    },
    getSubcategoryProduct(subCategory) {
      this.$eventBus.$emit("ecomloading", true);
      this.$router.push({
        name: "ecommerce.products",
        params: { slug: subCategory.slug },
        query: {
          type: "sub-category",
        },
      });
      this.getCurrentCategory();
    },
    colorSize() {
      let data = { slug: this.$route.params.slug };
      axios.get("ecommerce/get-color-size", { params: data }).then((res) => {
        this.colors = res.data.colors;
        this.sizes = res.data.sizes;
      });
    },
    getCurrentCategory() {
      axios
        .get("/ecommerce/get-current-category/" + this.$route.params.slug)
        .then((res) => {
          this.current_category = res.data;
          setTimeout(() => {
            this.$eventBus.$emit("ecomloading", false);
          }, 1000);
        });
    },
  },

  created() {
    this.getCategories();
  },

  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
};
</script>

<style scoped>
.innderLoader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f0f1f1e3;
}

.smallLoader {
  text-align: center;
  padding-top: 22%;
  font-size: 19px;
  color: #007f3d;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background: #6dc8be00 !important;
  border: 2px solid #6dc8be00 !important;
  color: #f4f4f4 !important;
  box-shadow: none;
}
.price-value input {
  width: 5em;
  border: 1px solid #4444;
  padding: 3px 5px;
  border-radius: 4px;
}
</style>
