<template>
	<div class="row">
		<div class="col-lg-12 ml-auto">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb justify-content-end bg-transparent mb-0">
					<li class="breadcrumb-item">
						<a href="/" class="breadcrumb-item router-link-active">
							<i class="fa fa-home"></i>
						</a>
					</li>
					<li class="breadcrumb-item text-capitalize">
						<span>
							<router-link
								style="text-transform: initial"
								:to="{ name: 'ecommerce.home' }"
							>BASIS DigitalShop</router-link>
						</span>
					</li>

					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.dashboard'">
						<span>
							<router-link :to="{ name: 'ecommerce.dashboard' }">Dashboard</router-link>
						</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.myorders'">
						<span>
							<router-link :to="{ name: 'ecommerce.myorders' }">My Orders</router-link>
						</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.wishlist'">
						<span>
							<router-link :to="{ name: 'ecommerce.wishlist' }">Wishlist</router-link>
						</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.profile'">
						<span>
							<router-link :to="{ name: 'ecommerce.profile' }">Profile</router-link>
						</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.password-change'">
						<span>
							<router-link :to="{ name: 'ecommerce.password-change' }">Change Password</router-link>
						</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.deliveryaddress'">
						<span>
							<router-link :to="{ name: 'ecommerce.deliveryaddress' }">Delivery Address</router-link>
						</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.cancelorder'">
						<span>
							<router-link :to="{ name: 'ecommerce.cancelorder' }">Cancel order</router-link>
						</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.feed-back'">
						<span>
							<router-link :to="{ name: 'ecommerce.feed-back' }">Review</router-link>
						</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.help-desk'">
						<span>
							<router-link :to="{ name: 'ecommerce.help-desk' }">Help Desk</router-link>
						</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.help-desklist'">
						<span>
							<router-link :to="{ name: 'ecommerce.help-desklist' }">Help Desk List</router-link>
						</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.products.details'">
						<span>Product Details</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.products'">
						<span>Product</span>
					</li>
					<li class="breadcrumb-item text-capitalize" v-if="$route.name == 'ecommerce.all-subcategory'">
						<span>Sub Category</span>
					</li>
				</ol>
			</nav>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Breadcumb"
	};
</script>

<style scoped>
</style>
