<template>
  <!--memberlist-->
  <div id="example-content">
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <div class="member-search">
          <div class="search-body">
            <h1>Search Member</h1>
            <div class="searchBox">
              <input
                type="text"
                placeholder="Company Name"
                v-on:keyup.enter="searchCompany('')"
                v-model="search_data.company_name"
                id="search"
              />
              <span class="mdi mdi-magnify" @click="searchCompany('')"></span>
            </div>
            <div class="search-menu">
              <ul class="nav flex-row justify-content-between">
                <li v-for="(alp, index) in alphabet" :key="index">
                  <a class="char" @click="searchCompany(alp)">
                    <span
                      v-bind:class="
                        search_data.character == alp ? 'active-char' : ''
                      "
                      >{{ alp }}</span
                    >
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/member-search-->
    <div class="row">
      <div class="col-lg-12">
        <fieldset class="data-search-body border">
          <legend style="width: 155px">
            <button
              type="button"
              class="btn btn-explore data-search shadow-sm"
              data-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Advance Search
            </button>
          </legend>
          <div class="collapse py-2 px-5" id="collapseExample">
            <form action="#" class="form-row">
              <div class="form-group">
                <label for="all_member">
                  <input
                    type="radio"
                    @click="advanceSearch('All')"
                    id="all_member"
                    name="radio"
                    checked
                  />
                  All
                </label>
                <label for="genarel_member" class="ml-3">
                  <input
                    type="radio"
                    @click="advanceSearch('General')"
                    id="genarel_member"
                    name="radio"
                  />
                  General Member
                </label>
                <label for="associate_member" class="ml-3">
                  <input
                    type="radio"
                    @click="advanceSearch('Associate')"
                    id="associate_member"
                    name="radio"
                  />
                  Associate Member
                </label>
                <label for="affiliate_member" class="ml-3">
                  <input
                    type="radio"
                    @click="advanceSearch('Affiliate')"
                    id="affiliate_member"
                    name="radio"
                  />
                  Affiliate Member
                </label>
                <label for="international_member" class="ml-3">
                  <input
                    type="radio"
                    @click="advanceSearch('International')"
                    id="international_member"
                    name="radio"
                  />
                  International Member
                </label>
              </div>
              <div class="w-100"></div>
              <div class="form-group col-lg-2">
                <label for class="col-form-label-sm mb-0">Service</label>
                <input
                  type="text"
                  class="form-control form-control-sm shadow-none"
                  placeholder="Service"
                  v-model="search_data.service"
                />
              </div>
              <div class="form-group col-lg-2">
                <label for class="col-form-label-sm mb-0">Product</label>
                <input
                  type="text"
                  class="form-control form-control-sm shadow-none"
                  placeholder="Product"
                  v-model="search_data.product"
                />
              </div>
              <!-- <div class="form-group col-lg-2">
                            <label for class="col-form-label-sm mb-0">Team Structure</label>
                            <select
                              v-model="search_data.team"
                              class="form-control form-control-sm shadow-none"
                            >
                              <option value>--Select--</option>
                              <option>1-10</option>
                              <option>10-15</option>
                              <option>15-20</option>
                              <option>20-25</option>
                              <option>25-30</option>
                            </select>
              </div>-->
              <div class="form-group col-lg-2">
                <label for class="col-form-label-sm mb-0">Location</label>
                <input
                  type="text"
                  class="form-control form-control-sm shadow-none"
                  placeholder="Location"
                  v-model="search_data.location"
                />
              </div>
              <div class="form-group col-lg-2">
                <label for class="col-form-label-sm mb-0">Membership ID</label>
                <input
                  type="text"
                  class="form-control form-control-sm shadow-none"
                  placeholder="Membership ID"
                  v-model="search_data.membership_no"
                />
              </div>
              <div class="form-group col-lg-2">
                <label for class="col-form-label-sm mb-0">Rep. Name</label>
                <input
                  type="text"
                  class="form-control form-control-sm shadow-none"
                  placeholder="Rep. Name"
                  v-model="search_data.representative_name"
                />
              </div>
              <div class="form-group col-lg-2 align-self-end">
                <button
                  type="button"
                  @click="advanceSearch('advance')"
                  class="btn btn-sm btn-success"
                >
                  <i class="mdi mdi-magnify"></i>Search
                </button>
              </div>
            </form>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="row py-3">
      <div class="col-lg-12">
        <div
          class="card card-body shadow-sm"
          v-if="!$root.spinner && Object.keys(table.datas).length > 0"
        >
          <div
            v-for="(member, index) in table.datas"
            :key="index"
            class="search-list-item row mb-3"
          >
            <div class="col-lg-2 align-self-center text-center">
              <img
                v-if="member.logo === '/images/logo/' || member.logo === null"
                v-lazy="
                  $root.baseurl + require('./../../assets/img/no_logo.gif')
                "
                alt
              />
              <img
                v-else
                v-lazy="$root.baseurl + '/public' + member.logo"
                alt
              />
            </div>
            <div class="col-lg-2 text-center border-right pl-0 pr-0">
              <h1>[{{ member.membership_id }}]</h1>
              <h5 v-if="member.membership_type">
                {{ member.membership_type }} Member
              </h5>
              <a
                v-if="member.profile"
                :href="$root.baseurl + '/public' + member.profile"
                target="_blank"
                class="btn-custom"
                >[PDF Profile]</a
              >
              <a
                href="javascript:void(0);"
                v-else
                onclick="alert('Sorry!! profile has not uploaded yet')"
                class="btn-custom"
                >[PDF Profile]</a
              >
            </div>
            <div class="col-lg-6">
              <h4>
                <router-link
                  target="_blank"
                  :to="{
                    name: 'company-profile',
                    params: { slug: member.membership_no },
                  }"
                  >{{ member.company_name }}</router-link
                >
              </h4>
              <small
                >Establishment: {{ member.establishment_month }}
                {{ member.establishment_year }}</small
              >
              <p v-if="member.short_profile">
                {{ member.short_profile.substring(0, 200) }}
              </p>
            </div>
            <div class="col-lg-2 align-self-center text-center">
              <router-link
                target="_blank"
                :to="{
                  name: 'company-profile',
                  params: { slug: member.membership_no },
                }"
                class="btn btn-sm btn-outline-explore"
                >More Details</router-link
              >
            </div>
          </div>
        </div>
        <div v-else>
          <h6 v-if="!$root.spinner">
            <center>No record found!!</center>
          </h6>
        </div>
        <spinner v-if="$root.spinner" />
      </div>
    </div>
    <!-- pagination -->
    <Pagination
      :url="'get-member-list'"
      :search_data="search_data"
      v-if="!$root.spinner"
    />
    <!-- /pagination -->
  </div>
  <!--//memberlist-->
</template>
<script>
export default {
  name: "MemberList",
  data() {
    return {
      breadcrumbTitle: "Member List",
      breadcumb: [{ title: "Member List" }],
      search_data: {
        team: "",
      },
      table: {
        datas: [],
        services: [],
        products: [],
      },
      meta: [],
      links: [],
      alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),
    };
  },
  methods: {
    get_datas: function () {
      this.$root.spinner = true;
      let url = "/get-member-list";
      axios
        .get(url, { params: this.search_data })
        .then((respons) => {
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
        })
        .catch((error) => {})
        .then((res) => {
          this.$root.spinner = false;
        });
    },
    searchCompany: function (char = null) {
      if (char != "" && char != null) {
        this.search_data.character = char;
        this.search_data.company_name = "";
        this.search_data.product = "";
        this.search_data.service = "";
        this.search_data.team = "";
        this.search_data.location = "";
        this.search_data.membership_type = this.search_data.membership_type;
      } else {
        this.search_data.character = "";
        this.search_data.service = "";
        this.search_data.product = "";
        this.search_data.team = "";
        this.search_data.location = "";
        this.search_data.membership_type = this.search_data.membership_type;
      }
      this.get_datas();
    },
    advanceSearch: function (value = null) {
      if (value == "advance") {
        this.search_data.character = "";
        this.search_data.company_name = "";
        this.search_data.membership_type = this.search_data.membership_type;
      } else {
        this.search_data.character = "";
        this.search_data.company_name = "";
        this.search_data.product = "";
        this.search_data.service = "";
        this.search_data.team = "";
        this.search_data.location = "";
        this.search_data.membership_type = value;
      }
      this.get_datas();
    },
  },
  mounted() {
    this.get_datas();
    this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
  },
  created() {
    this.search_data.membership_type = this.$route.query.type;
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
};
</script>

<style scoped>
.active-char {
  color: red;
  font-weight: bold;
  font-size: 14px;
}
.char {
  cursor: pointer;
}
</style>