<template>
  <!-- custom Modal -->
  <div class v-if="product">
    <h4 class="text-center">
      <span class="text-success">Product Information</span>
    </h4>
    <table class="table">
      <tr>
        <td>Product Name</td>
        <td>:</td>
        <td>{{ product.product.title }}</td>
      </tr>
      <tr>
        <td>Category</td>
        <td>:</td>
        <!-- <td v-if="product.category">{{ categories[product.category] }}</td> -->
        <td v-if="product.product.category">{{ product.expertise.name }}</td>
      </tr>
      <tr>
        <td>Image</td>
        <td>:</td>
        <td>
          <a
            v-if="product.product.image"
            :href="$root.baseurl + '/public' + product.product.image"
            target="_blank"
            class="btn btn-sm btn-primary"
            >View</a
          >
          <a
            v-if="product.product.image"
            :href="$root.baseurl + '/public' + product.product.image"
            class="btn btn-sm btn-success"
            download
            >Download</a
          >
          <p v-else>No Image Added</p>
        </td>
      </tr>
      <tr>
        <td>File</td>
        <td>:</td>
        <td>
          <a
            v-if="product.product.file"
            :href="$root.baseurl + '/public' + product.product.file"
            target="_blank"
            class="btn btn-sm btn-primary"
            >View</a
          >
          <a
            v-if="product.product.file"
            :href="$root.baseurl + '/public' + product.product.file"
            class="btn btn-sm btn-success"
            download
            >Download</a
          >
          <p v-else>No File Added</p>
        </td>
      </tr>
      <tr>
        <td>Details</td>
        <td>:</td>
        <td style="white-space: pre-wrap" v-html="product.product.detail"></td>
      </tr>
    </table>
  </div>
  <!-- custom Modal -->
</template>


<script>
export default {
  name: "ModalProduct",
  props: ["product", "categories"],
};
</script>

<style>
</style>
