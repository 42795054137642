<template>
  <div class="customer-profile">
    <h3
      v-if="cencelOrders && Object.keys(cencelOrders).length > 0"
      class="my-4"
    >
      Cancel Order
    </h3>
    <div class="row pt-3">
      <div class="col-md-12">
        <table
          class="table"
          v-if="cencelOrders && Object.keys(cencelOrders).length > 0"
        >
          <tr class="bg-blue-theme">
            <th>Order No.</th>
            <th>Price</th>
            <th width="5%"></th>
          </tr>
          <tr v-for="(data, index) in cencelOrders" :key="index">
            <td>{{ data.order_no }}</td>
            <td>৳ {{ data.total_amount }}</td>
            <td>
              <a @click="removeCencelOrder(data.id)">
                <span class="btn btn-danger btn-sm">
                  <i class="fa fa-trash-alt"></i>
                </span>
              </a>
            </td>
          </tr>
        </table>
        <div v-else class="text-center text-info p-4">No Data Found !</div>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyCart from "../elements/EmptyCart";
import swal from "sweetalert";
export default {
  name: "CancelOrder",
  components: { EmptyCart },
  data() {
    return {
      data: {},
      cencelOrders: [],
    };
  },
  methods: {
      removeCencelOrder(id) {
          swal({
              title: "Are you sure?",
              text: "You want to delete this ",
              icon: "warning",
              buttons: true,
              dangerMode: true
          }).then(willDelete => {
              if (willDelete) {
                  swal("Poof! Your order has been deleted!", {
                      icon: "success"
                  });
                  axios
                      .get("ecommerce/remove-cencel-orders/" + id)
                      .then((res) => {
                          if (res.status === 200) {
                              this.$toastr("success", res.data.message, "Success!!");
                              this.getCancelOrders();
                          }
                      })
                      .catch((error) => console.log(error));
              } else {
                  swal("Your order is safe!");
              }
          });
      },

    getCancelOrders() {
      // My Cancel Order
      axios
        .get("/ecommerce/get-cencel-orders")
        .then((res) => (this.cencelOrders = res.data));
    },
  },
  created() {
    this.getCancelOrders();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
};
</script>

<style>
</style>
