var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 login-page"},[_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"left-content"},[_c('img',{staticClass:"basis-logo",attrs:{"src":_vm.$root.baseurl + '/public/images/logo.svg',"alt":"basislogo"}}),_vm._v(" "),_c('h4',[_vm._v("Welcome to BASIS DigitalShop")]),_vm._v(" "),_c('img',{staticClass:"login-image",attrs:{"src":_vm.$root.baseurl + '/public/images/ecommerce-login.jpg',"alt":"basislogo"}})])]),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[(!_vm.$root.spinner)?_c('div',{staticClass:"right-form"},[_c('div',{staticClass:"row justify-content-center align-items-center"},[_c('div',{staticClass:"col-lg-12"},[_c('h3',{staticClass:"text-center"},[_vm._v("Login With BASIS Membership Account.")]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label-sm m-0",attrs:{"for":""}},[_vm._v("Representative Email")]),_vm._v(" "),_c('div',{staticClass:"loginBox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],staticClass:"form-control form-control-sm",class:{
                        'is-invalid': _vm.validation.hasError('data.email'),
                        'is-valid': _vm.data.email,
                      },attrs:{"type":"email","autocomplete":"off","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'ie: member@email.com'","placeholder":"ie: member@email.com","name":"email"},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "email", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"mdi mdi-email"})]),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.email")))])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label-sm m-0",attrs:{"for":""}},[_vm._v("Password")]),_vm._v(" "),_c('div',{staticClass:"loginBox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.password),expression:"data.password"}],staticClass:"form-control form-control-sm",class:{
                        'is-invalid': _vm.validation.hasError('data.password'),
                        'is-valid': _vm.data.password,
                      },attrs:{"type":"password","autocomplete":"off","onfocus":"this.placeholder = ''","onblur":"this.placeholder = 'ie: A-Z, a-z,digit(0-9),special character'","placeholder":"ie: A-Z, a-z,digit(0-9),special character","name":"password"},domProps:{"value":(_vm.data.password)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "password", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"mdi mdi-shield-lock-outline"})]),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation.firstError("data.password")))])]),_vm._v(" "),_c('div',{staticClass:"row justify-content-between align-items-center mt-5 mx-2"},[_c('button',{staticClass:"btn btn-success float-right",on:{"click":function($event){return _vm.submit()}}},[(_vm.spinner)?_c('span',[_c('i',{staticClass:"fa fa-spinner fa-spin"}),_vm._v(" processing...\n\t\t\t\t\t\t\t\t\t\t")]):_c('span',[_vm._v("Login")])]),_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t\t\t\t\t\t\tCreate an Account?\n\t\t\t\t\t\t\t\t\t\t"),_c('router-link',{attrs:{"to":{ name: 'ecommerce.registration' }}},[_vm._v("Signup")])],1)])])])]):_vm._e(),_vm._v(" "),(_vm.$root.spinner)?_c('div',{staticClass:"right-form"},[_c('spinner')],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }