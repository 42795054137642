<template>
  <!--services-->
  <div class="services">
    <span class="shape1"></span>
    <span class="shape2"></span>
    <div class="container-wrapper">
      <div class="service-tab">
        <div class="content-header mb-5">
          <h1 class="title text-center">Our Service</h1>
          <ul
            class="nav nav-tabs justify-content-center"
            id="myTab"
            role="tablist"
          >
            <li class="nav-item">
              <a
                class="nav-link active"
                id="members-tab"
                data-toggle="tab"
                href="#members"
                role="tab"
                aria-controls="members"
                aria-selected="true"
                >Members</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="nonmembers-tab"
                data-toggle="tab"
                href="#nonmembers"
                role="tab"
                aria-controls="nonmembers"
                aria-selected="false"
                >Non Members</a
              >
            </li>
          </ul>
        </div>
        <div class="service-item tab-content" id="myTabContent">
          <div
            class="members tab-pane fade show active"
            id="members"
            role="tabpanel"
            aria-labelledby="members-tab"
            v-if="data"
          >
            <div class="row" v-if="data.member_service">
              <slot v-for="(service, index) in data.member_service">
                <div
                  :key="index"
                  class="col-6 col-lg-4"
                  data-aos="fade-up"
                  data-aos-offset="100"
                  :data-aos-duration="500 + index * 200"
                >
                  <router-link
                    v-if="service.external_page == 0"
                    :to="{
                      name: 'service_detail',
                      params: { id: service.id, type: 'member' },
                    }"
                  >
                    <div class="card card-body shadow-sm mb-3">
                      <div class="iconbox">
                        <span v-html="service.icon"></span>
                      </div>
                      <h3>{{ service.title }}</h3>
                    </div>
                  </router-link>
                  <router-link
                    v-else
                    :to="'/' + service.form_link + '/' + service.id + '/member'"
                  >
                    <div class="card card-body shadow-sm mb-3">
                      <div class="iconbox">
                        <span v-html="service.icon"></span>
                      </div>
                      <h3>{{ service.title }}</h3>
                    </div>
                  </router-link>
                </div>
                <!-- <div :key="'a'+index" class="w-100 mb-4" v-if="index%3==2"></div> -->
              </slot>
            </div>
            <div class="row pt-5">
              <div class="col-lg-12 text-center">
                <router-link
                  :to="{ name: 'service', params: { type: 'member' } }"
                  class="btn btn-lg btn-primary rounded-50"
                >
                  View All
                  <i class="fas fa-long-arrow-alt-right pl-1"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div
            class="nonmembers tab-pane fade"
            id="nonmembers"
            role="tabpanel"
            aria-labelledby="nonmembers-tab"
            v-if="data"
          >
            <div class="row" v-if="data.non_member_service">
              <slot v-for="(service, index) in data.non_member_service">
                <div
                  :key="index"
                  class="col-6 col-lg-4"
                  data-aos="fade-up"
                  data-aos-offset="100"
                  :data-aos-duration="500 + index * 200"
                >
                  <!-- <router-link
                    :to="{ name:'service_detail', params: { id: service.id, type: 'non_member' }}"
                  >
                    <div class="card card-body shadow-sm mb-3">
                      <div class="iconbox">
                        <span v-html="service.icon"></span>
                      </div>
                      <h3>{{service.title}}</h3>
                    </div>
                  </router-link>-->
                  <router-link
                    v-if="service.external_page == 0"
                    :to="{
                      name: 'service_detail',
                      params: { id: service.id, type: 'non_member' },
                    }"
                  >
                    <div class="card card-body shadow-sm mb-3">
                      <div class="iconbox">
                        <span v-html="service.icon"></span>
                      </div>
                      <h3>{{ service.title }}</h3>
                    </div>
                  </router-link>
                  <router-link
                    v-else
                    :to="
                      '/' + service.form_link + '/' + service.id + '/non_member'
                    "
                  >
                    <div class="card card-body shadow-sm mb-3">
                      <div class="iconbox">
                        <span v-html="service.icon"></span>
                      </div>
                      <h3>{{ service.title }}</h3>
                    </div>
                  </router-link>
                </div>
                <!-- <div :key="'a'+index" class="w-100 mb-4" v-if="index%3==2"></div> -->
              </slot>
            </div>
            <div class="row pt-5">
              <div class="col-lg-12 text-center">
                <router-link
                  :to="{ name: 'service', params: { type: 'non_member' } }"
                  class="btn btn-lg btn-primary rounded-50"
                >
                  View All
                  <i class="fas fa-long-arrow-alt-right pl-1"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--services-->
</template>
<script>
export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    getServices() {
      axios.get("/get-services-home").then((response) => {
        this.data = response.data;
      });
    },
  },
  mounted() {
    setTimeout(() => this.getServices(), 150);
  },
};
</script>