<template>
  <div id="example-content">
    <!--training-details-->
    <div class="training-details" v-if="!$root.spinner">
      <h3>{{ data.training_name }}</h3>
      <div class="row">
        <div class="col-lg-8 text-justify">
          <span v-html="data.training_description"></span>
        </div>
        <div class="col-lg-4">
          <div class="card shadow-sm mb-3 rounded-10 py-4">
            <span class="shape"></span>
            <div class="card-header bg-transparent border-0">
              <h3
                class="font-weight-bold mb-0 text-capitalize"
              >{{ this.$route.params.type }} Information</h3>
            </div>
            <div class="card-body pt-0">
              <p v-if="data.training_date">
                <strong>Date:</strong>
                {{ data.training_date }}
              </p>
              <p v-if="data.training_time">
                <strong>Time:</strong>
                {{ data.training_time }}
              </p>
              <p v-if="data.training_period">
                <strong>Training Period:</strong>
                {{ data.training_period }}
              </p>
              <p v-if="data.training_venue">
                <strong>Venue:</strong>
                {{ data.training_venue }}
              </p>
              <strong v-if="data.trainer">Trainer:</strong>
              <ul class="nav flex-column pl-3 rounded-10" v-if="data.trainer">
                <li v-for="(trainee, index) in  data.trainer" :key="index">
                  <a :href="data.trainer_social_link" target="_blank">{{ trainee.trainer_name }}</a>
                </li>
              </ul>
              <p v-if="data.traning_module">
                <strong class="text-capitalize">{{ this.$route.params.type }} Module:</strong>
                <a :href="$root.baseurl+'/public'+data.traning_module" target="_blank">View</a>
              </p>
            </div>
          </div>
          <div class="card shadow-sm mb-3 rounded-10 py-4" v-if="data.transaction_type=='Paid'">
            <span class="shape"></span>
            <div class="card-header bg-transparent border-0">
              <h3 class="font-weight-bold mb-0 text-capitalize">{{ this.$route.params.type }} Fee</h3>
            </div>
            <div class="card-body pt-0">
              <p>
                <strong>Amount:</strong>
                {{ data.training_fee_amount|currency }} (Per Company)
              </p>
              <p>
                <strong>Last Date:</strong>
                {{ data.training_last_date }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-3" v-if="data.transaction_type=='Paid'">
        <div class="col-lg-12">
          <button
            class="btn btn-success"
            @click="clickToCheckLogin('training-form',$route.params.id)"
          >Get It Now!</button>
        </div>
      </div>
    </div>
    <spinner v-if="$root.spinner" />
    <!--/training-details-->
  </div>
</template>
<script>
export default {
  name: "TrainingSeminarDetails",
  data() {
    return {
      breadcrumbTitle: this.$route.params.type + " Details",
      breadcumb: [
        { title: this.$route.params.type },
        { title: this.$route.params.type + " Details" }
      ],
      basislogo: this.$root.baseurl + "/public/images/logo.svg",
      data: {}
    };
  },
  methods: {
    get_datas: function() {
      this.$root.spinner = true;
      axios
        .get("/get-details/" + this.$route.params.id)
        .then(response => {
          this.data = response.data;
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
          this.$root.spinner = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_datas();
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  }
};
</script>
