<template>
  <!--out patner-->
  <div class="our-patner">
    <div class="container-wrapper" v-if="data.content">
      <div class="row">
        <div class="col-lg-4" data-aos="fade-right" data-aos-offset="100" data-aos-duration="1000">
          <div class="content-header">
            <h1 class="title">{{data.content.title}}</h1>
            <span v-html="data.content.description"></span>
          </div>
        </div>
        <div class="col-lg-8" data-aos="fade-left" data-aos-offset="100" data-aos-duration="1500">
          <ul class="nav">
            <slot v-for="(partner,index) in data.partners">
              <li :key="index" class="nav-item">
                <a target="_blank" :href="partner.url" class="nav-link">
                  <img v-lazy="'public'+partner.logo" :alt="data.content.title" />
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--//out patner-->
</template>

<script>
export default {
  name: "Partner",
  data() {
    return {
      data: {
        content: {},
        partners: {}
      }
    };
  },
  methods: {
    getPartner() {
      axios.get("/get-partner-home").then(respons => {
        this.data.partners = respons.data.partners;
        this.data.content = respons.data.content;
      });
    }
  },
  mounted() {
    setTimeout(() => this.getPartner(), 350);
  }
};
</script>