<template>
	<div
		class="memberBankInfoView"
		v-if="
			(!$parent.loading && $root.access && $root.access['payment_history']) ||
			$root.variable.rep_login ||
			$root.variable.aLogId
		"
	>
		<b-button
			v-b-modal.modal-prevent-closing
			class="btn btn-success btn-sm mr-4"
			>View bank details</b-button
		>

		<b-modal
			id="modal-prevent-closing"
			ref="my-modal"
			title="View Your Bank Info"
			hide-footer
		>
			<div class="col-lg-12">
				<MemberInfoEdit v-on:updatedBank="updateBankInfo"></MemberInfoEdit>
			</div>
			<div class="card-body">
				<div class="tableList py-2">
					<div class="table-responsive">
						<table
							class="table table-striped table-bordered table-hover"
							border="1"
						>
							<tbody>
								<tr>
									<td>Bank Name</td>
									<td>{{ $parent.bankData.bank_name }}</td>
								</tr>
								<tr>
									<td>Account Name</td>
									<td>{{ $parent.bankData.account_name }}</td>
								</tr>
								<tr>
									<td>Account Number</td>
									<td>{{ $parent.bankData.account_no }}</td>
								</tr>
								<tr>
									<td>Routing Number</td>
									<td>{{ $parent.bankData.routing_no }}</td>
								</tr>
								<tr>
									<td>Branch</td>
									<td>{{ $parent.bankData.branch }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="col-lg-12">
				<b-button
					class="mt-2 modal-button d-block w-100"
					variant="outline-danger"
					@click="hideModal"
					>Close Me</b-button
				>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import axios from "axios";
	import MemberInfoEdit from "./MemberInfoEdit";
	const base_route = "payment-history";
	export default {
		props: ["memberInfo"],
		components: { MemberInfoEdit },
		data() {
			return {
				txt: "Form",
				base_route: base_route,
				data: {
					text: "",
				},
				errors: {},
				focus_field: "",
			};
		},
		methods: {
			handleSubmit() {
				this.$nextTick(() => {
					this.$bvModal.hide("modal-prevent-closing");
				});
			},
			hideModal() {
				this.$refs["my-modal"].hide();
			},
			updateBankInfo() {
				this.$emit("memberBankInfo");
			},
		},
	};
</script>

<style scoped>
	.btn-warning {
		background: #ffc107;
		color: #007f3d;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 1.1px;
		text-align: center;
		border: 2px solid #ffc107;
		padding: 5px 20px;
		box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
	.btn-success {
		background: #007f3d;
		color: white;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 1.1px;
		text-align: center;
		border: 2px solid #007f3d;
		padding: 5px 20px;
		box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
</style>