 <template>
  <div
    class="member-services"
    v-if="
      (!$parent.loading &&
        $root.access &&
        Object.keys($root.access).length > 0 &&
        $root.access['compliance']) ||
      $root.variable.rep_login ||
      $root.variable.aLogId
    "
  >
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">
            Compliance Information
          </h5>
        </div>
      </div>
      <div class="card-body">
        <div class="row align-items-center justify-content-end mb-4">
          <div
            v-if="Object.keys(errors).length > 0"
            class="col-lg-12 py-2"
            style="background: #fddede"
          >
            <div class="error">
              <span
                v-for="(err, errIndex) in errors"
                :key="errIndex"
                class="text-danger"
              >
                {{ err[0] }}
                <br />
              </span>
            </div>
          </div>
        </div>

        <div class="form-row mb-3">
          <p>
            <!-- To assess the compliance of section 10.5 (f) of the Articles of
            Association. "A member shall ipso facto cease to be a member of the
            association: In the event of that at any time such member shall not
            be actively engaged in the business of software & services within
            the scope of the association". Each member of the association has to
            fill the form and submit to BASIS Secretariat by January 31 of each
            year. This form will also help BASIS to assess the industry position
            and prepare it's documentation for policy advocacy. -->
            All the information requested in the compliance form must be
            submitted to receive the updated BASIS membership certificate. As
            per the Articles of Association of the BASIS, each member of the
            association has to fill out the form. Being a national trade
            association, we require the most recent data for your last fiscal
            year (01 July 2023 - 30 June 2024), including the Income Tax
            Certificate, TIN Certificate, updated trade license, Revenue (from
            local & international market) HR, and business operation
            information. This form will help BASIS assess the industry position
            and prepare its documentation for policy advocacy.
          </p>
        </div>
        <form v-on:submit.prevent="submit" id="form">
          <fieldset class="border border-primary p-3 mb-3">
            <div class="row">
              <div class="col-lg-6">
                <div class="row mb-3">
                  <label for="new_company" class="col-lg-5"
                    >Name of Company:</label
                  >
                  <label for="new_company" class="col-lg-7" v-if="memberInfo">
                    {{ memberInfo.company_name }}
                  </label>
                </div>
                <div class="row">
                  <label for="new_company" class="col-lg-5"
                    >Membership Number:</label
                  >
                  <label for="new_company" class="col-lg-7" v-if="memberInfo">
                    {{ memberInfo.membership_id }}
                  </label>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="border border-primary p-3 mb-3">
            <legend class="w-50 text-center">
              <small class="text-uppercase font-weight-bold"
                >REVENUE OF LAST FINANCIAL YEAR</small
              >
            </legend>
            <div class="row">
              <div class="col-lg-6">
                <h6 class="text-uppercase font-weight-bold">From Software</h6>
                <hr />
                <div class="form-row mb-3">
                  <label for="comments">From Local Market</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.compliances.software_local_market'
                      ),
                      'is-valid': data.compliances.software_local_market,
                    }"
                    placeholder="e.g. 10,000 / N/A"
                    v-model="data.compliances.software_local_market"
                    name="software_local_market"
                  />
                  <div class="invalid-feedback">
                    {{
                      validation.firstError(
                        "data.compliances.software_local_market"
                      )
                    }}
                  </div>
                </div>
                <div class="form-row mb-3">
                  <label for="comments">From Overseas</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.compliances.software_overseas'
                      ),
                      'is-valid': data.compliances.software_overseas,
                    }"
                    placeholder="e.g. 10,000 / N/A"
                    v-model="data.compliances.software_overseas"
                    name="software_overseas"
                  />
                  <div class="invalid-feedback">
                    {{
                      validation.firstError(
                        "data.compliances.software_overseas"
                      )
                    }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <h6 class="text-uppercase font-weight-bold">From ITES</h6>
                <hr />
                <div class="form-row mb-3">
                  <label for="comments">From Local Market</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.compliances.ites_local_market'
                      ),
                      'is-valid': data.compliances.ites_local_market,
                    }"
                    placeholder="e.g. 10,000 / N/A"
                    v-model="data.compliances.ites_local_market"
                    name="ites_local_market"
                  />
                  <div class="invalid-feedback">
                    {{
                      validation.firstError(
                        "data.compliances.ites_local_market"
                      )
                    }}
                  </div>
                </div>
                <div class="form-row mb-3">
                  <label for="comments">From Overseas</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    type="text"
                    name="ites_overseas"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.compliances.ites_overseas'
                      ),
                      'is-valid': data.compliances.ites_overseas,
                    }"
                    placeholder="e.g. 10,000 / N/A"
                    v-model="data.compliances.ites_overseas"
                  />
                  <div class="invalid-feedback">
                    {{
                      validation.firstError("data.compliances.ites_overseas")
                    }}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="border border-primary p-3 mb-3">
            <legend class="w-50 text-center">
              <small class="font-weight-bold text-uppercase">
                Human Resources Information
                <span class="text-danger requierd-star"></span>
              </small>
            </legend>
            <div class="row justify-content-center">
              <div class="form-group col-6 col-lg-3 mb-3">
                <label>Software</label>
                <div class="form-group">
                  <input
                    type="text"
                    name="hr_software"
                    v-on:keyup="totalHr()"
                    v-model="data.compliances.hr_software"
                    class="form-control"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.compliances.hr_software'
                      ),
                      'is-valid': data.compliances.hr_software,
                    }"
                    placeholder="e.g. 05"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 05'"
                  />
                </div>
              </div>
              <div class="form-group col-6 col-lg-3 mb-3">
                <label>ITES</label>
                <div class="form-group">
                  <input
                    type="text"
                    name="hr_ites"
                    v-model="data.compliances.hr_ites"
                    v-on:keyup="totalHr()"
                    class="form-control"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.compliances.hr_ites'
                      ),
                      'is-valid': data.compliances.hr_ites,
                    }"
                    placeholder="e.g. 20"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 20'"
                  />
                </div>
              </div>
              <div class="form-group col-6 col-lg-3 mb-3">
                <label>Business/Non-technical</label>
                <div class="form-group">
                  <input
                    type="text"
                    name="hr_business_non_technical"
                    v-model="data.compliances.hr_business_non_technical"
                    v-on:keyup="totalHr()"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.compliances.hr_business_non_technical'
                      ),
                      'is-valid': data.compliances.hr_business_non_technical,
                    }"
                    class="form-control"
                    placeholder="e.g. 20"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 20'"
                  />
                </div>
              </div>
              <div class="form-group col-6 col-lg-3 mb-3 align-self-end">
                <label>Total</label>
                <div class="form-group">
                  <input
                    readonly
                    type="text"
                    v-model="total_employee"
                    name="total_employee"
                    class="form-control"
                    placeholder="e.g. 20"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 20'"
                  />
                </div>
              </div>
              <div class="row col-12 col-lg-5">
                <label class="col-md-5">Female Employee</label>
                <div class="col-md-6">
                  <input
                    type="text"
                    name="hr_female"
                    v-model="data.compliances.hr_female"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.compliances.hr_female'
                      ),
                      'is-valid': data.compliances.hr_female,
                    }"
                    class="form-control"
                    v-on:keyup="totalHr()"
                    placeholder="e.g. 05"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'e.g. 05'"
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="border border-primary p-3 mb-3">
            <div class="row">
              <div class="col-lg-3">
                <div class="form-row">
                  <div class="form-group col-2 col-lg-3 text-center pt-3">
                    <img
                      v-lazy="
                        pdf || data.compliances.income_tax ? pdfAttach : noPdf
                      "
                      class="img-responsive"
                      height="50"
                      width="70"
                      alt="no image"
                    />
                  </div>
                  <div class="col-10 col-lg-9 mb-3">
                    <label for="income_tax"
                      >Proof of Submission of Income Tax Return (Latest
                      Year)</label
                    >
                    <span class="requierd-star"></span>
                    <div class="loginBox">
                      <b-form-file
                        v-model="income_tax"
                        accept=".pdf"
                        :placeholder="
                          data.compliances.income_tax
                            ? 'File attached '
                            : 'Choose a pdf file'
                        "
                        drop-placeholder="Drop file here..."
                        :class="{
                          'is-invalid': validation.hasError('income_tax'),
                          'is-valid': income_tax,
                        }"
                      ></b-form-file>
                      <span class="mdi mdi-file-pdf text-danger"></span>
                    </div>
                    <small>Smaller than 7 mb</small>
                    <a
                      v-if="data.compliances.income_tax"
                      :href="
                        $root.baseurl + '/public' + data.compliances.income_tax
                      "
                      class="float-right"
                      target="_blank"
                      >View file</a
                    >
                    <div class="invalid-feedback">
                      {{ validation.firstError("income_tax") }}
                    </div>
                  </div>
                  <input
                    type="hidden"
                    v-model="data.compliances.income_tax"
                    name="old_income_tax"
                  />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-row">
                  <div class="form-group col-2 col-lg-3 text-center pt-3">
                    <img
                      v-lazy="
                        pdf1 || data.compliances.trade_license
                          ? pdfAttach
                          : noPdf
                      "
                      class="img-responsive"
                      height="50"
                      width="70"
                      alt="no image"
                    />
                  </div>
                  <div class="col-10 col-lg-9 mb-3">
                    <label for="trade_license">Latest Trade License</label>
                    <span class="requierd-star"></span>
                    <div class="loginBox">
                      <b-form-file
                        v-if="data.compliances"
                        v-model="trade_license"
                        accept=".pdf"
                        :placeholder="
                          data.compliances.trade_license
                            ? 'File attached '
                            : 'Choose a pdf file'
                        "
                        drop-placeholder="Drop file here..."
                        :class="{
                          'is-invalid': validation.hasError('trade_license'),
                          'is-valid': trade_license,
                        }"
                      ></b-form-file>
                      <span class="mdi mdi-file-pdf text-danger"></span>
                    </div>
                    <small>Smaller than 7 mb</small>
                    <a
                      v-if="data.compliances.trade_license"
                      :href="
                        $root.baseurl +
                        '/public' +
                        data.compliances.trade_license
                      "
                      class="float-right"
                      target="_blank"
                      >View file</a
                    >
                    <div class="invalid-feedback">
                      {{ validation.firstError("trade_license") }}
                    </div>
                  </div>

                  <input
                    type="hidden"
                    v-model="data.compliances.trade_license"
                    name="old_trade_license"
                  />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-row">
                  <div class="form-group col-2 col-lg-3 text-center pt-3">
                    <img
                      v-lazy="
                        pdf2 || data.compliances.tin_certificate
                          ? pdfAttach
                          : noPdf
                      "
                      class="img-responsive"
                      height="50"
                      width="70"
                      alt="no image"
                    />
                  </div>
                  <div class="col-10 col-lg-9 mb-3">
                    <label for="tin_certificate">Tin Certificate</label>
                    <div class="loginBox">
                      <b-form-file
                        v-model="tin_certificate"
                        accept=".pdf"
                        :placeholder="
                          data.compliances.tin_certificate
                            ? 'File attached '
                            : 'Choose a pdf file'
                        "
                        drop-placeholder="Drop file here..."
                        :class="{
                          'is-invalid': validation.hasError('tin_certificate'),
                          'is-valid': tin_certificate,
                        }"
                      ></b-form-file>
                      <span class="mdi mdi-file-pdf text-danger"></span>
                    </div>
                    <small>Smaller than 7 mb</small>
                    <a
                      v-if="data.compliances.tin_certificate"
                      :href="
                        $root.baseurl +
                        '/public' +
                        data.compliances.tin_certificate
                      "
                      class="float-right"
                      target="_blank"
                      >View file</a
                    >
                    <div class="invalid-feedback">
                      {{ validation.firstError("tin_certificate") }}
                    </div>
                  </div>
                  <input
                    type="hidden"
                    v-model="data.compliances.tin_certificate"
                    name="old_tin_certificate"
                  />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-row">
                  <div class="form-group col-2 col-lg-3 text-center pt-3">
                    <img
                      v-lazy="
                        pdf3 || data.compliances.form_x ? pdfAttach : noPdf
                      "
                      class="img-responsive"
                      height="50"
                      width="70"
                      alt="no image"
                    />
                  </div>
                  <div class="col-10 col-lg-9 mb-3">
                    <label for="form_x">Form X</label>
                    <div class="loginBox">
                      <b-form-file
                        v-model="form_x"
                        accept=".pdf"
                        :placeholder="
                          data.compliances.form_x
                            ? 'File attached '
                            : 'Choose a pdf file'
                        "
                        drop-placeholder="Drop file here..."
                        :class="{
                          'is-invalid': validation.hasError('form_x'),
                          'is-valid': form_x,
                        }"
                      ></b-form-file>
                      <span class="mdi mdi-file-pdf text-danger"></span>
                    </div>
                    <small>Smaller than 7 mb</small>
                    <a
                      v-if="data.compliances.form_x"
                      :href="
                        $root.baseurl + '/public' + data.compliances.form_x
                      "
                      class="float-right"
                      target="_blank"
                      >View file</a
                    >
                    <div class="invalid-feedback">
                      {{ validation.firstError("form_x") }}
                    </div>
                  </div>
                  <input
                    type="hidden"
                    v-model="data.compliances.form_x"
                    name="old_form_x"
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="border border-primary p-3 mb-3">
            <label for class="text-center col-12 mb-3">
              Area of operation for this period: (Please tick multiple area if
              you work in those area)
              <span class="requierd-star"></span>
            </label>
            <div class="row" v-if="activity_list">
              <div
                class="col-md-4"
                v-for="(bActivity, key) in activity_list"
                :key="key"
              >
                <div class="custom-control custom-checkbox">
                  <b-form-checkbox
                    :name="'activities[' + bActivity.id + ']  '"
                    class="col-form-label-sm font-weight-bold mb-0"
                    v-model="data.activities[bActivity.id]"
                    :value="true"
                    :unchecked-value="false"
                    >{{ bActivity.title }}</b-form-checkbox
                  >
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="border border-primary p-3 mb-3">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkAgree"
                      :value="true"
                      name="agree"
                      :class="{
                        'is-invalid': validation.hasError(
                          'data.compliances.agree'
                        ),
                      }"
                      v-model="data.compliances.agree"
                      class="custom-control-input"
                      :unchecked-value="false"
                    />
                    <label class="custom-control-label" for="checkAgree">
                      <b>Confirmation:</b>
                      <br />Knowing the fact that giving inaccurate data may
                      lead to cancellation of our membership, we confirm that we
                      have given the above information correctly, If BASIS
                      requires, we will provide supporting documents for
                      verification.
                    </label>
                  </div>
                </div>
                <div class="invalid-feedback">
                  {{ validation.firstError("data.compliances.agree") }}
                </div>

                <br />
                <br />
                <strong> <u>Signature of Authorized Signatory</u> : </strong>
                <br />
                <br />
              </div>
              <div class="col-lg-6">
                <div class="row mb-3">
                  <label for="new_company" class="col-lg-5"
                    >Representative Name :</label
                  >
                  <label
                    for="new_company"
                    class="col-lg-7"
                    v-if="representative"
                    >{{ representative.name }}</label
                  >
                </div>
                <div class="row">
                  <label for="new_company" class="col-lg-5">
                    Date :
                    <span class="requierd-star"></span>
                  </label>
                  <div class="col-lg-7">
                    <Datepicker
                      class="form-control shadow-none"
                      :class="{
                        'is-invalid': validation.hasError(
                          'data.compliances.date'
                        ),
                        'is-valid': data.compliances.date,
                      }"
                      id="established_year"
                      placeholder="e.g. 01-01-2020."
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'e.g. 01-01-2020'"
                      name="date"
                      v-model="data.compliances.date"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-3">
                  <label for="new_company" class="col-lg-4"
                    >Designation :</label
                  >
                  <label
                    for="new_company"
                    class="col-lg-8"
                    v-if="representative"
                    >{{ representative.designation }}</label
                  >
                </div>
              </div>
            </div>
          </fieldset>

          <div class="row justify-content-center">
            <button class="btn btn-success" type="submit">
              Update Compliance Information
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- <pre>{{ data }}</pre> -->
  </div>
  <div v-else class="row">
    <div class="col-12">
      <div class="card">
        <div v-if="Object.keys($root.access).length > 0" class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! you cannot access this page
          </h4>
        </div>
        <div v-else class="card-body">
          <div
            v-if="!$parent.loading"
            class="card-body d-flex align-items-center justify-content-center flex-column"
          >
            <i
              class="fa fa-spinner fa-spin"
              style="font-size: 24px; color: rgb(0, 127, 62)"
            ></i>
            <span class="processing">processing</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noPdf from "../../../../assets/img/noimg.png";
import pdfAttach from "../../../../assets/img/PdfAttached.png";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
const breadcumb = [
  {
    title: "Compliance Information",
  },
];
export default {
  name: "ComplianceForm",
  props: ["memberInfo"],
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Compliance Information",
      pdf: null,
      noPdf: this.$root.baseurl + noPdf,
      pdfAttach: this.$root.baseurl + pdfAttach,
      total_employee: 0,
      data: {
        compliances: {
          hr_software: 0,
          hr_ites: 0,
          hr_business_non_technical: 0,
          hr_female: 0,
        },
        activities: {},
      },
      income_tax: null,
      trade_license: null,
      tin_certificate: null,
      form_x: null,
      activity_list: [],
      representative: null,
      member: {
        inof: null,
      },
      pdf1: null,
      pdf2: null,
      pdf3: null,
      errors: {},
    };
  },
  watch: {
    income_tax: function (file, oldFile) {
      this.showPdf(file);
    },
    trade_license: function (file, oldFile) {
      this.showPdf(file, "pdf1");
    },
    tin_certificate: function (file, oldFile) {
      this.showPdf(file, "pdf2");
    },
    form_x: function (file, oldFile) {
      this.showPdf(file, "pdf3");
    },
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      var form = document.getElementById("form");
      var formData = new FormData(form);

      if (confirm("Are you confirming, is the provided information valid?")) {
        if (this.income_tax) {
          formData.append("income_tax", this.income_tax);
        }
        if (this.trade_license) {
          formData.append("trade_license", this.trade_license);
        }
        if (this.tin_certificate) {
          formData.append("tin_certificate", this.tin_certificate);
        }
        if (this.form_x) {
          formData.append("form_x", this.form_x);
        }
        this.$validate().then((res) => {
          if (this.validation.countErrors() > 0) {
            this.$toastr(
              "warning",
              'You need to fill <span class="font-weight-bold">' +
                this.validation.countErrors() +
                " more empty</span> mandatory fields",
              "Empty Mandatory Fields!!"
            );
            console.log(this.validation.allErrors());
          } else {
            if (res) {
              if (
                this.data.activities &&
                Object.keys(this.data.activities).length == 0
              ) {
                this.$toastr(
                  "warning",
                  'Please select <span class="font-weight-bold">minimum 1 </span>',
                  "Area of operation for this period !!"
                );
                return false;
              }
              this.scrollTop();
              this.$parent.loading = true;
              axios
                .post("member/ComplianceInfo", formData, config)
                .then((response) => {
                  this.$toastr("success", "Update Successfully", "Success!!");
                  location.reload();
                })
                .catch((error) => {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                    this.$parent.loading = false;
                  }
                })
                .then((always) => {
                  setTimeout(
                    () => (this.$parent.loading = false),
                    this.$root.minloadtimefd
                  );
                });
            }
          }
        });
      }
    },

    totalHr() {
      // setTimeout(() => {
      this.total_employee =
        parseInt(this.data.compliances.hr_software) +
        parseInt(this.data.compliances.hr_ites) +
        parseInt(this.data.compliances.hr_business_non_technical);
      // }, 1500);
    },
    getCompliance() {
      // Member Compliance Infos
      axios
        .get("/member/getComplianceInfos")
        .then((response) => {
          if (Object.keys(response.data).length > 0) {
            this.data.compliances = response.data;
          }
        })
        .catch((error) => {})
        .then((always) => {
          setTimeout(() => (this.$parent.loading = false), 500);
        });
      // Member Compliance ACtivity
      axios
        .get("/member/getComplianceActivities")
        .then((response) => {
          if (Object.keys(response.data).length > 0) {
            this.data.activities = response.data;
          }
        })
        .catch((error) => {})
        .then((always) => {
          setTimeout(() => (this.$parent.loading = false), 500);
        });
    },
  },

  mounted() {
    this.loggedIn();
    // business activity list
    axios
      .get("/admin/getAllBusinessActivityData")
      .then((response) => {
        this.activity_list = response.data;
      })
      .catch((error) => {})
      .then((always) => {
        setTimeout(
          () => (this.$parent.loading = false),
          this.$root.minloadtimefd
        );
      });
    // representative
    axios.get("/member/getRepresentative").then((response) => {
      this.representative = response.data;
    });
    this.getCompliance();
  },
  updated() {
    this.$root.debug_data = this.data;
    this.totalHr();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  validators: {
    "data.compliances.software_local_market": function (value = null) {
      return Validator.value(value).required("From Local Market is required");
    },
    "data.compliances.ites_local_market": function (value = null) {
      return Validator.value(value).required("From Local Market is required");
    },
    "data.compliances.software_overseas": function (value = null) {
      return Validator.value(value).required("From Overseas is required");
    },
    "data.compliances.ites_overseas": function (value = null) {
      return Validator.value(value).required("From Overseas is required");
    },
    "data.compliances.hr_software": function (value = null) {
      return Validator.value(value)
        .required("From Overseas is required")
        .digit();
    },
    "data.compliances.hr_ites": function (value = null) {
      return Validator.value(value)
        .required("From Overseas is required")
        .digit();
    },
    "data.compliances.hr_business_non_technical": function (value = null) {
      return Validator.value(value)
        .required("From Overseas is required")
        .digit();
    },
    "data.compliances.hr_female": function (value = null) {
      return Validator.value(value)
        .required("From Overseas is required")
        .digit();
    },
    "data.compliances.date": function (value = null) {
      return Validator.value(value).required("Data is required");
    },
    income_tax: function (value = null) {
      return Validator.value(value)
        .required("Income tax is required")
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "PDF format required";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 7168000) {
              return "PDF must be smaller than 7 MB";
            }
          }
        });
    },
    tin_certificate: function (value = null) {
      return Validator.value(value)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "PDF format required";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 7168000) {
              return "PDF must be smaller than 7 MB";
            }
          }
        });
      // .required("Income tax is required")
    },
    trade_license: function (value = null) {
      if (!this.data.compliances.trade_license) {
        return Validator.value(value)
          .required("Trade license is required")
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var type = value.type;
              if (type == "application/pdf") {
              } else {
                return "PDF format required";
              }
            }
          })
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var size = value.size;
              if (size > 7168000) {
                return "PDF must be smaller than 7 MB";
              }
            }
          });
      }
    },
    form_x: function (value = null) {
      return Validator.value(value)
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (type == "application/pdf") {
            } else {
              return "PDF format required";
            }
          }
        })
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var size = value.size;
            if (size > 7168000) {
              return "PDF must be smaller than 7 MB";
            }
          }
        });
    },
    "data.compliances.agree": function (value = null) {
      return Validator.value(value)
        .required("Confirmation is required")
        .custom(function () {
          if (value == false) {
            return "Confirmation is required";
          }
        });
    },
  },
};
</script>

