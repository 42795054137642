<template>
	<div id="example-content">
		<div class="service-tab row">
			<section class="event-list col-lg-9">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="single-item">
								<div class="date col-lg-12 text-center">
									<div class="date-block">
										<h1 class="mt-3 text-bold year">{{ y }}</h1>
										<div
											class="event-item"
											v-for="(year, index) in dates.slice(0, limit)"
											:key="index"
										>
											<a href="javascript:void(0);" class="d-block w-100">
												<div class="row">
													<div class="col-lg-3">
														<div
															class="
																time
																d-flex
																justify-content-center
																align-items-center
															"
														>
															<span>
																{{ year.from_date | formatDate("Do MMM") }}
															</span>
														</div>
													</div>
													<div class="col-lg-9">
														<div class="info">
															<ul>
																<li class="mb-4 d-block">
																	<h3 class="col-lg-9">
																		{{ getPostBody(year.event_name, 70) }}
																	</h3>
																	<div class="row">
																		<div class="col-lg-6">
																			<span v-if="year.location">
																				<i
																					class="fa fa-map-marker"
																					aria-hidden="true"
																				></i>

																				<span>{{
																					getPostBody(year.location, 30)
																				}}</span>
																			</span>
																		</div>
																		<div class="col-lg-6">
																			<span>
																				<i
																					class="fa fa-clock-o"
																					aria-hidden="true"
																				></i>
																				<span
																					>{{ year.from_time }} -
																					{{ year.to_time }}</span
																				>
																			</span>
																		</div>
																	</div>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</a>
										</div>
										<div class="calander-button mt-4">
											<button
												class="btn btn-sm btn-outline-primary"
												@click="loadMore(dates.length)"
											>
												Load More
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="col-lg-3">
				<affix
					class="sidebar-menu"
					relative-element-selector="#example-content"
				>
					<slot v-if="!$root.spinner || !spinner">
						<div class="leftside-menu">
							<div class="card shadow-sm py-4 mb-3 rounded-10">
								<span class="shape"></span>
								<span class="shape2"></span>
								<div
									class="card-header bg-transparent"
									v-for="(eventdatas, year) in events"
									:key="year"
								>
									<h3 class="mb-0 year-hover" @click="updateyear(eventdatas)">
										{{ eventdatas }}
									</h3>
								</div>
							</div>
						</div>
					</slot>
				</affix>
			</section>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	const breadcumb = [
		{
			title: "PreviousEvent",
		},
	];
	export default {
		name: "PreviousEvent",
		data() {
			return {
				breadcumb: breadcumb,
				breadcrumbTitle: "Previous Event",
				events: "",
				limit: 5,
				dates: "",
				y: new Date().getFullYear(),
			};
		},
		methods: {
			asyncData() {
				this.$root.loading = false;
			},
			getPreviousEvents() {
				axios.get("/get-prev-events-data").then((res) => {
					const newData = res.data;
					const sortData = Object.keys(newData);
					const sortedData = sortData.sort((a, b) => b - a);

					this.events = sortedData;
					this.$root.spinner = false;
				});
			},

			getPreviousYears() {
				axios.get("/get-prev-years/" + this.y).then((res) => {
					this.dates = res.data;
					this.$root.spinner = false;
				});
			},
			updateyear(updateY) {
				this.y = updateY;
				axios.get("/get-prev-years/" + updateY).then((res) => {
					this.dates = res.data;
					this.$root.spinner = false;
				});
			},
			loadMore(eventLength) {
				if (eventLength > 5) {
					return (this.limit = eventLength);
				}
			},
		},
		created() {
			this.loadMore();
			this.getPreviousYears();
			this.getPreviousEvents();
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		watch: {
			$route: {
				handler: "asyncData",
				immediate: true,
			},
		},
	};
</script>

<style scoped>
	/* event list part start */

	.new-date {
		text-align: center !important;
		justify-content: center !important;
		font-size: 25px;
		margin-left: auto;
		margin-right: auto;
		text-align: left;
	}

	.event-list .single-item:last-child {
		margin-bottom: 0px;
	}

	.event-list .event-item {
		border-radius: 5px;
		box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
		margin-bottom: 20px;
	}

	.event-list .event-item:last-child {
		margin-bottom: 20px;
	}

	.event-list .date {
		padding-bottom: 14px;
		margin-bottom: 50px;
		box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
			rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
			rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
	}

	.event-list .date h4 {
		font-size: 24px;
		font-weight: 700;
		color: #070919;
	}

	.event-list .time {
		background-color: #6dc8be;
		color: #ffffff;
		width: 100%;
		height: 100%;
		text-align: center;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.event-list .time span {
		font-size: 18px;
		font-weight: 600;
		color: #ffffff;
	}

	.event-list .info {
		padding: 20px 0;
	}

	.event-list .info h3 {
		font-size: 25px;
		font-weight: 700;
		color: #6dc8be;
		margin-bottom: 20px;
		text-align: left;
		margin-left: -10px;
	}

	.event-list .info ul li {
		font-size: 18px;
		font-weight: 400;
		color: #070919;
		display: inline-block;
		margin-right: 40px;
		text-align: left;
	}

	.event-list .info ul li i {
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		border-radius: 50%;
		background-color: #ed1c24;
		color: #fff;
		font-size: 14px;
		margin-right: 8px;
	}

	.event-list .info ul li:nth-child(2) i {
		background-color: #007f3d;
	}

	.year-hover:hover {
		cursor: pointer;
		color: crimson;
	}

	.year {
		color: #6dc8be;
	}

	/* event list part end */
	@media (min-width: 360px) and (max-width: 767.98px) {
		.col-lg-9,
		.col-lg-3 {
			padding: 24px !important;
		}
		.event-item .info ul li span {
			margin-bottom: 10px;
			display: inline-block;
		}
	}
	@media (min-width: 768px) and (max-width: 991.98px) {
		.col-lg-9,
		.col-lg-3 {
			padding: 24px !important;
		}
		.event-item .info ul li span {
			margin-bottom: 10px;
			display: inline-block;
		}
	}
	@media (max-width: 768px) {
		.sidebar-menu {
			display: block;
		}
	}
</style>