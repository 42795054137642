<template>
  <div
    class="member-services"
    v-if="
      (!$parent.loading &&
        member.info &&
        member.info.status == 1 &&
        $root.access &&
        $root.access['online_helpdesk']) ||
      $root.variable.rep_login ||
      $root.variable.aLogId
    "
  >
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">
            Existing Ticket
          </h5>
          <div class="px-3 mb-0">
            <router-link
              :to="{ name: 'createTicket' }"
              class="btn btn-warning btn-sm"
            >
              <b>Create A New Ticket</b>
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">TICKET ID</th>
              <th scope="col">SUBJECT</th>
              <th scope="col">TOPICS</th>
              <th scope="col">STATUS</th>
              <th scope="col">CREATED ON</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <slot v-if="Object.keys(table.datas).length > 0">
              <tr v-for="(tck, index) in table.datas" :key="index">
                <th>
                  {{ tck.ticket }}
                  <sup
                    v-if="tck.ticket_reply_count != 0"
                    class="badge badge-danger rounded-50"
                    >{{ tck.ticket_reply_count }}</sup
                  >
                </th>
                <td>{{ tck.subject }}</td>
                <td>{{ tck.topics }}</td>
                <td>
                  <span v-if="tck.status == 'd'" class="badge badge-danger"
                    >Closed</span
                  >
                  <span v-else class="badge badge-success">Open</span>
                </td>
                <td>{{ tck.created_at | formatDate("Do MMM, YYYY") }}</td>
                <td>
                  <router-link
                    :to="{ name: 'viewTicket', params: { tID: tck.id } }"
                    class="btn btn-success btn-sm"
                    >view</router-link
                  >
                </td>
              </tr>
            </slot>
            <tr v-else>
              <td colspan="6" style="text-align: center">No record found</td>
            </tr>
          </tbody>
        </table>
        <!-- pagination -->
        <Pagination
          :url="'member/ticketSystem'"
          :scrollNone="true"
          v-if="Object.keys(table.datas).length > 0"
        />
        <!-- /pagination -->
      </div>
    </div>
  </div>
  <div v-else-if="member.info && member.info.status == 0" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! your account is suspend
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="member.info && member.info.status == 2" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! your account is cancel
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="row">
    <div class="col-12">
      <div class="card">
        <div v-if="Object.keys($root.access).length > 0" class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! you cannot access this page
          </h4>
        </div>
        <div v-else class="card-body">
          <div
            v-if="!$parent.loading"
            class="
              card-body
              d-flex
              align-items-center
              justify-content-center
              flex-column
            "
          >
            <i
              class="fa fa-spinner fa-spin"
              style="font-size: 24px; color: rgb(0, 127, 62)"
            ></i>
            <span class="processing">processing</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
const breadcumb = [
  {
    title: "Online Healpdesk",
  },
];
export default {
  name: "onlineHealpdesk",
  data() {
    return {
      breadcumb: breadcumb,
      dismissSecs: 10,
      dismissCountDown: 0,
      breadcrumbTitle: "Online Healpdesk",
      data: { ticket: null },
      member: {
        info: {},
      },
      table: { datas: [] },
      meta: [],
      links: [],
      errors: {},
    };
  },
  validators: {},
  methods: {
    getTicketList: function (pageindex) {
      this.$root.spinner = true;
      axios
        .get("/member/ticketSystem")
        .then((response) => {
          this.table.datas = response.data.data;
          this.meta = response.data.meta;
          this.links = response.data.links;
          this.$parent.debug_data = response;
        })
        .catch((error) => {})
        .then((res) => {
          this.$root.spinner = false;
        });
    },
  },
  mounted() {
    this.loggedIn();
    this.getTicketList();
    if (this.$route.params.notify) {
      this.$toastr(
        "success",
        "Please wait for feedback",
        "Successfully Create!!"
      );
    }
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
    this.$root.spinner = false;
  },
};
</script>

