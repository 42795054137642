<template>
	<div id="example-content">
		<slot v-if="!$root.spinner">
			<section class="career mt-5">
				<div class="container">
					<div class="row">
						<div
							class="col-lg-4 col-md-6"
							v-for="(content_file, index) in careers"
							:key="index"
						>
							<router-link
								:to="generateCareerUrl(content_file.id)"
								class="d-block w-100"
							>
								<div class="career-item">
									<div class="row">
										<div class="col-lg-3 col-md-3 col-sm-3">
											<div class="icon">
												<b-img
													v-if="content_file.logo_image"
													class="img-fluid w-100"
													:src="imageUrl(content_file.logo_image)"
													:alt="content_file.job_title"
												></b-img>
												<b-img
													v-else
													class="img-fluid w-100"
													:src="defaultImageUrl()"
													:alt="content_file.job_title"
												></b-img>
											</div>
										</div>
										<div class="col-lg-9 col-md-9 col-sm-9">
											<div class="info">
												<h4>{{ content_file.job_title }}</h4>
												<p>{{ content_file.industry_name }}</p>
												<ul>
													<li>
														<i
															class="fa fa-map-marker mr-3"
															aria-hidden="true"
														></i>
														<b>Location:</b> {{ content_file.location }}
													</li>
													<li>
														<i
															class="fa fa-briefcase mr-3"
															aria-hidden="true"
														></i>
														<b>Experience:</b> {{ content_file.experience }}
													</li>
													<li>
														<i
															class="fa fa-calendar mr-3"
															aria-hidden="true"
														></i>
														<b>Deadline:</b> {{ content_file.deadline }}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</section>
		</slot>
		<spinner v-if="$root.spinner" />
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: "Career",
		data() {
			return {
				content: {
					content_file: [],
					breadcrumbTitle: "",
					breadcumb: [],
				},

				careers: [],
			};
		},
		methods: {
			generateCareerUrl(id) {
				return `career/${id}`;
			},
			asyncData() {
				this.get_data();
			},
			get_data() {
				axios
					.get("/get-career")
					.then((respons) => {
						this.careers = respons.data.data;
						console.log();
					})
					.catch((error) => {
						console.log(error);
					})
					.then((res) => {
						setTimeout(
							() => (this.$root.loading = false),
							this.$root.minloadtimefd
						);
					});
			},
			imageUrl(imagePath) {
				return this.$root.baseurl + "/public/" + imagePath;
			},
			defaultImageUrl() {
				return this.$root.baseurl + "/public/images/career-logo.png";
			},
		},
		mounted() {},
		watch: {
			$route: {
				handler: "asyncData",
				immediate: true,
			},
		},
	};
</script>

<style scoped>
	.career .career-item {
		padding: 20px;
		background-color: #fff;
		border-radius: 5px;
		margin-bottom: 24px;
		box-shadow: 0 0 14px rgb(191 191 191 / 54%);
		border-left: 3px solid #6dc8be;
	}

	.career .career-item p {
		font-size: 16px;
		font-weight: 700;
		color: #2c194b;
		padding-top: 5px;
	}

	.career .career-item ul li {
		font-size: 15px;
		color: #2c194b;
		margin: 5px 0;
		list-style-type: none;
	}

	.career .career-item ul li i {
		color: #6dc8be;
	}

	.career .career-item h4 {
		font-size: 18px;
		font-weight: 700;
		color: #6dc8be;
	}

	.career .career-item .button {
		position: absolute;
		top: calc(100% - 44px);
		left: 50%;
		transform: translateX(-50%);
		z-index: 9;
	}

	.career .career-item .button button {
		background-color: #6dc8be;
		color: #ffffff;
		font-size: 15px;
		font-weight: 500;
		padding: 6px 30px;
		border-radius: 3px;
		transition: all linear 0.2s;
		border: 0;
	}

	.career .career-item .button button:hover {
		background-color: #007f3d;
	}

	.col-lg-9,
	.col-lg-3 {
		padding: 0 12px !important;
	}

	@media (max-width: 575.98px) {
		.career-item .icon {
			width: 100px;
			margin: 0 auto;
			margin-bottom: 23px;
		}
	}
</style>