<template>
	<div class="customer-profile">
		<h3 class="my-4">Delivery Address</h3>
		<div class="row">
			<div
				class="col-md-4 mb-1"
				v-for="(address, aindex) in deliveryAddressList"
				:key="aindex"
			>
				<div
					class="address-body custom-bg-shadow-redius"
					:class="{ checked: address.selected == 1 }"
				>
					<label :for="'address' + aindex">
						<div class="d-flex justify-content-between align-items-center">
							<div class>
								<input
									:id="'address' + aindex"
									type="checkbox"
									v-model="defualtAddress"
									@change="selectedAddress(address.id)"
									name="shippingAddress"
									:true-value="address.id"
									:false-value="null"
								/>
								<strong class="badge badge-primary"
									>Address {{ aindex + 1 }}</strong
								>
								<strong class="badge badge-danger" v-if="aindex == 0"
									>Last Address</strong
								>
							</div>
						</div>

						<p><b>Name: </b>{{ address.name }}</p>
						<p><b>Phone: </b>{{ address.phone }}</p>
						<p><b>Address: </b>{{ address.shipping_address }}</p>
						<div class="d-flex justify-content-between align-items-center">
							<div class="action-button">
								<a @click="editAddress(address.id)" href="javascript:void(0)">
									<i class="fa fa-edit"></i>Edit
								</a>
							</div>
							<div class="action-button">
								<a
									@click="removeAddress(address.id)"
									class="text-danger"
									href="javascript:void(0)"
								>
									<i class="fa fa-trash"></i>Remove
								</a>
							</div>
						</div>
					</label>
				</div>
			</div>
		</div>
		<form autocomplete="off" @submit.prevent="submit()" class="mt-3">
			<div class="col-md-4 mt-3">
				<a href="javascript:void(0)" @click="addAdress" class="text-dark">
					<i class="fa fa-plus"></i> Add New Address
				</a>
			</div>
			<hr />
			<div class="row px-4" v-if="isAddressEditOrAdd">
				<div class="col-lg-6 p-0">
					<div class="form-group">
						<label>
							Full Name
							<i class="text-danger">*</i>
						</label>
						<input
							type="text"
							name="name"
							v-model="data.name"
							class="form-control"
							placeholder="Fist Name"
						/>
						<div class="invalid-feedback">
							{{ validation.firstError("data.name") }}
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="form-group">
						<label>
							Email
							<i class="text-danger">*</i>
						</label>
						<input
							type="email"
							name="email"
							v-model="data.email"
							:readonly="data.email"
							class="form-control"
							placeholder="ie:example@gmail.com"
						/>
						<div class="invalid-feedback">
							{{ validation.firstError("data.email") }}
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="form-group">
						<label>
							Phone
							<i class="text-danger">*</i>
						</label>
						<input
							type="number"
							name="phone"
							v-model="data.phone"
							class="form-control"
							placeholder="ie:01**********"
						/>
						<div class="invalid-feedback">
							{{ validation.firstError("data.phone") }}
						</div>
					</div>
				</div>
				<div class="col-lg-3 p-0">
					<div class="form-group">
						<label>
							Division
							<i class="text-danger">*</i>
						</label>
						<select v-model="data.division" class="form-control">
							<option :value="null">Select One</option>
							<option
								v-for="(div, divindex) in locations.division"
								:value="div.id"
								:key="divindex"
							>
								{{ div.name }}
							</option>
						</select>
						<div class="invalid-feedback">
							{{ validation.firstError("data.division") }}
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="form-group">
						<label>
							District
							<i class="text-danger">*</i>
						</label>
						<select v-model="data.district" class="form-control">
							<option :value="null">Select One</option>
							<option
								v-for="(dist, distindex) in locations.district"
								:value="dist.id"
								:key="distindex"
							>
								{{ dist.name }}
							</option>
						</select>
						<div class="invalid-feedback">
							{{ validation.firstError("data.district") }}
						</div>
					</div>
				</div>

				<div class="col-lg-3">
					<div class="form-group">
						<label>
							Thana
							<i class="text-danger">*</i>
						</label>
						<select v-model="data.upazila" class="form-control">
							<option :value="null">Select One</option>
							<option
								v-for="(upazila, upaindex) in locations.upazila"
								:value="upazila.id"
								:key="upaindex"
							>
								{{ upazila.name }}
							</option>
						</select>
						<div class="invalid-feedback">
							{{ validation.firstError("data.upazila") }}
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="form-group">
						<label>
							Post Code
							<i class="text-danger">*</i>
						</label>
						<select v-model="data.post_code" class="form-control">
							<option :value="null">Select One</option>
							<option
								v-for="(code, code_index) in postalcodes"
								:value="code.name"
								:key="code_index"
							>
								{{ code.name }}
							</option>
						</select>
						<div class="invalid-feedback">
							{{ validation.firstError("data.post_code") }}
						</div>
					</div>
				</div>
				<div class="col-lg-3 p-0">
					<div class="form-group">
						<label>
							Area
							<i class="text-danger">*</i>
						</label>
						<select v-model="data.area" class="form-control">
							<option :value="null">Select One</option>
							<option
								v-for="(area, area_index) in areas"
								:value="area.name"
								:key="area_index"
							>
								{{ area.name }}
							</option>
						</select>
						<div class="invalid-feedback">
							{{ validation.firstError("data.area") }}
						</div>
					</div>
				</div>

				<div class="col-lg-12 p-0">
					<div class="form-group">
						<label>
							Shipping Address
							<i class="text-danger">*</i>
						</label>
						<textarea
							v-model="data.shipping_address"
							name="shipping_address"
							placeholder="Shipping Address"
							class="form-control"
							rows="3"
						></textarea>
						<div class="invalid-feedback">
							{{ validation.firstError("data.shipping_address") }}
						</div>
					</div>
				</div>
				<button class="btn btn-primary mt-4 ml-3">
					<span v-if="exit_id">Save Change</span>
					<span v-else>Create</span>
				</button>
			</div>
		</form>
	</div>
</template>

<script>
	import SimpleVueValidation from "simple-vue-validator";

	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		name: "DeliveryAddress",
		data() {
			return {
				isAddressEditOrAdd: false,
				data: {
					name: "",
					phone: "",
					email: "",
					shipping_address: "",
					division: null,
					district: null,
					upazila: null,
					post_code: null,
					area: null,
				},
				exit_id: null,
				deliveryAddressList: {},
				selectedAddressBox: null,
				defualtAddress: "",
				locations: {
					division: {},
					district: {},
					upazila: {},
				},

				districts: [],
				thanas: [],
				postalcodes: [],
				areas: [],
			};
		},
		methods: {
			submit() {
				this.$validate().then((res) => {
					if (res) {
						this.$eventBus.$emit("ecomloading", true);
						let datas = {
							data: this.data,
							exit_id: this.exit_id,
						};
						axios
							.post(
								"ecommerce/store-delivery-address/" +
									this.$root.loggedCustomer.id,
								datas
							)
							.then((respons) => {
								if (respons.data.code == 200) {
									this.isAddressEditOrAdd = false;
									this.$toastr("success", respons.data.msg, "Success!!");
									this.getShippingAddress();
									// this.$router.push({ name: "ecommerce.dashboard" });
								}
							})
							.catch((error) => {})
							.then((alw) => this.$eventBus.$emit("ecomloading", false));
					}
				});
			},

			addAdress() {
				this.data.name = "";
				this.data.phone = "";
				this.data.shipping_address = "";
				this.data.division = "";
				this.data.district = "";
				this.data.upazila = "";
				this.data.post_code = "";
				this.data.area = "";
				this.exit_id = null;
				this.isAddressEditOrAdd = true;
			},
			editAddress(id) {
				this.isAddressEditOrAdd = true;
				this.selectCheckAddressList(id);
			},
			removeAddress(id) {
				axios.delete("/ecommerce/remove-address/" + id).then((res) => {
					if (res.status == 200) {
						this.$toastr("success", "Address has been removed!", "Success!!");
						this.getShippingAddress();
					}
				});
			},
			selectedAddress(id) {
				// master default address set
				this.defualtAddressSet(id);
			},
			selectCheckAddressList(id) {
				this.deliveryAddressList.map((value) => {
					if (value.id == id) {
						this.exit_id = value.id;
						this.data.name = value.name;
						this.data.phone = value.phone;
						this.data.email = value.email;
						this.data.shipping_address = value.shipping_address;
						this.data.division = value.division;
						this.data.district = value.district;
						this.data.upazila = value.upazila;
						this.data.post_code = value.post_code;
						this.data.area = value.area;

						setTimeout(() => {
							let found_upazilla = this.locations.upazila.find(
								(item) => item.id == value.upazila
							).name;

							let found_district = this.locations.district.find(
								(item) => item.id == value.district
							).name;

							if (found_upazilla) {
								this.getPostCode(found_district, found_upazilla);
							}
						}, 3000);
					}
				});
			},
			async getShippingAddress() {
				this.$eventBus.$emit("ecomloading", true);
				await axios.get("/ecommerce/shipping-address").then((res) => {
					this.deliveryAddressList = res.data;
					let found = res.data.find((address) => address.selected == 1);
					if (found) {
						this.defualtAddress = found.id;
					}

					setTimeout(() => {
						this.$eventBus.$emit("ecomloading", false);
					}, 1000);
				});
			},
			async getDivision() {
				await axios.get("/ecommerce/get-division").then((res) => {
					this.locations.division = res.data;
				});
			},
			getPostCode(district, upazilla) {
				let params = {
					url: "postcode-list",
					field1: "city",
					field2: "thana",
					value1: district,
					value2: upazilla,
				};

				axios
					.get("/ecommerce/get-ecourier-location", { params: params })
					.then((res) => {
						this.postalcodes = res.data;
					});
			},
			defualtAddressSet(id) {
				let data = { id: id };
				axios
					.post("/ecommerce/shipping-address-default-selected", data)
					.then((res) => {
						this.getShippingAddress();
						this.$toastr("success", "Update Default Select Address");
					});
			},
		},
		created() {
			this.data.name = this.$root.loggedCustomer.name;
			this.data.email = this.$root.loggedCustomer.email;
			this.data.phone = this.$root.loggedCustomer.mobile;
			this.data.shipping_address = this.$root.loggedCustomer.address;
			this.getShippingAddress();
			this.getDivision();
		},
		watch: {
			"data.division": function (newValue, old) {
				axios
					.get("/ecommerce/get-district", { params: { name: newValue } })
					.then((res) => {
						this.locations.district = res.data;
					});
			},
			"data.district": function (newValue, old) {
				axios
					.get("/ecommerce/get-upazila", { params: { name: newValue } })
					.then((res) => {
						this.locations.upazila = res.data;
					});
			},

			"data.upazila": function (newValue, old) {
				let found_district = this.locations.district.find(
					(item) => item.id == this.data.district
				).name;

				let found_upazilla = this.locations.upazila.find(
					(item) => item.id == newValue
				).name;

				if (found_upazilla) {
					this.getPostCode(found_district, found_upazilla);
				}
			},
			"data.post_code": function (newValue, old) {
				if (!newValue) {
					return false;
				}
				let params = { url: "area-list", field: "postcode", name: newValue };
				axios
					.get("/ecommerce/get-ecourier-location", { params: params })
					.then((res) => (this.areas = res.data));
			},
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		validators: {
			"data.name": function (value = null) {
				return Validator.value(value).required("Name is required");
			},
			"data.email": function (value = null) {
				return Validator.value(value).required("Email is required").email();
			},
			"data.phone": function (value = null) {
				return Validator.value(value).required("Phone is required");
			},
			"data.division": function (value = null) {
				return Validator.value(value).required("Division is required");
			},
			"data.district": function (value = null) {
				return Validator.value(value).required("District is required");
			},
			"data.upazila": function (value = null) {
				return Validator.value(value).required("Upazila is required");
			},
			"data.post_code": function (value = null) {
				return Validator.value(value).required("Post Code is required");
			},
			"data.area": function (value = null) {
				return Validator.value(value).required("Area is required");
			},
			"data.shipping_address": function (value = null) {
				return Validator.value(value).required("Shipping Address is required");
			},
		},
	};
</script>

<style scoped>
</style>
