<template>
	<div id="careerDetails">
		<slot v-if="!$root.spinner">
			<section class="career-details">
				<div class="container">
					<div v-if="careerDetails.id">
						<div class="row" v-if="careerDetails.image">
							<div class="col-lg-12">
								<div class="career-banner">
									<b-img
										class="img-fluid w-100"
										:src="imageUrl(careerDetails.image)"
										:alt="careerDetails.job_title"
									></b-img>
								</div>
							</div>
						</div>

						<div>
							<div class="career-text-heading mt-5 pb-5">
								<div class="row">
									<div class="col-lg-8 col-sm-8 order-2 order-sm-1">
										<div class="title">
											<h1>
												{{ careerDetails.job_title }}
											</h1>
										</div>
									</div>
									<div class="col-lg-4 col-sm-4 order-1 order-sm-2">
										<div class="apply text-right">
											<a :href="careerDetails.apply_now" target="_blank"
												><i
													class="fa fa-check-circle-o mr-2"
													aria-hidden="true"
												></i>
												Apply Now</a
											>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-8 mt-4">
										<div class="title">
											<h3>
												<span class="small-heading">
													{{ careerDetails.industry_name }}</span
												>
											</h3>
										</div>
									</div>
								</div>
							</div>

							<div class="job-overview p-5 border">
								<div class="row">
									<div class="col-md-6">
										<div class="d-flex mb-4">
											<div class="sidebar-icon-item">
												<i class="fa fa-hospital-o mr-3" aria-hidden="true"></i>
												<span class="text-description industry-icon mb-10"
													>Job level
												</span>
											</div>
											<div class="sidebar-text-info">
												<span class="small-heading">{{
													careerDetails.job_level
												}}</span>
											</div>
										</div>
										<div class="d-flex mb-4">
											<div class="sidebar-icon-item">
												<i
													class="fa fa-credit-card-alt mr-3"
													aria-hidden="true"
												></i>
												<span class="text-description industry-icon mb-10"
													>Salary</span
												>
											</div>
											<div class="sidebar-text-info">
												<span class="small-heading">{{
													careerDetails.salary
												}}</span>
											</div>
										</div>
										<div class="d-flex mb-4">
											<div class="sidebar-icon-item">
												<i class="fa fa-bar-chart mr-3" aria-hidden="true"></i>
												<span class="text-description industry-icon mb-10">
													Job type
												</span>
											</div>
											<div class="sidebar-text-info">
												<span class="small-heading">{{
													careerDetails.job_type
												}}</span>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="d-flex mb-4">
											<div class="sidebar-icon-item">
												<i class="fa fa-bars mr-3" aria-hidden="true"></i>
												<span class="text-description industry-icon mb-10"
													>Experience</span
												>
											</div>
											<div class="sidebar-text-info">
												<span class="small-heading">{{
													careerDetails.experience
												}}</span>
											</div>
										</div>
										<div class="d-flex mb-4">
											<div class="sidebar-icon-item">
												<i class="fa fa-calendar mr-3" aria-hidden="true"></i>
												<span class="text-description industry-icon mb-10">
													Deadline</span
												>
											</div>
											<div class="sidebar-text-info">
												<span class="small-heading">{{
													careerDetails.deadline
												}}</span>
											</div>
										</div>
										<div class="d-flex mb-0">
											<div class="sidebar-icon-item">
												<i class="fa fa-map-marker mr-3" aria-hidden="true"></i>
												<span class="text-description industry-icon mb-10"
													>Location</span
												>
											</div>
											<div class="sidebar-text-info">
												<span class="small-heading">
													{{ careerDetails.location }}</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="content-single mt-5">
								<h4>
									{{
										careerDetails.company_name ? careerDetails.company_name : ""
									}}
								</h4>
								<p
									class="text-justify"
									v-html="
										careerDetails.company_intro
											? careerDetails.company_intro
											: ''
									"
								></p>
								<h4>
									{{
										careerDetails.section_title
											? careerDetails.section_title
											: ""
									}}
								</h4>
								<ul
									class="text-justify"
									v-html="
										careerDetails.job_description
											? careerDetails.job_description
											: ''
									"
								></ul>
								<h4>
									{{
										careerDetails.preferred_experience
											? careerDetails.preferred_experience
											: ""
									}}
								</h4>
								<ul
									class="text-justify"
									v-html="
										careerDetails.experience_description
											? careerDetails.experience_description
											: ''
									"
								></ul>
							</div>

							<div class="single-apply mt-5 pt-5">
								<div class="row">
									<div class="col-lg-6 col-sm-4">
										<div class="apply">
											<a :href="careerDetails.apply_now" target="_blank"
												><i
													class="fa fa-check-circle-o mr-2"
													aria-hidden="true"
												></i>
												Apply Now</a
											>
										</div>
									</div>
									<div class="col-lg-6 col-sm-8 align-self-center social-left">
										<div class="social text-left text-sm-right">
											<h5>
												<span class="mr-4">Share this</span>
												<a href="javascript:void(0);"
													><i class="fa fa-facebook career-social facebook"></i
												></a>
												<a href="javascript:void(0);"
													><i class="fa fa-twitter career-social twitter"></i
												></a>
												<a href="javascript:void(0);"
													><i class="fa fa-linkedin career-social linkedin"></i
												></a>
												<a href="javascript:void(0);"
													><i
														class="fa fa-instagram career-social instagram"
													></i
												></a>
											</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</slot>
		<spinner v-if="$root.spinner" />
	</div>
</template>

<script>
	export default {
		name: "careerDetails",
		data() {
			return {
				careerDetails: {},
				breadcrumbTitle: "",
				breadcumb: [],
			};
		},
		methods: {
			get_data() {
				this.$root.spinner = true;
				var id = this.$route.params.id;
				axios
					.get("/career/" + id + "/details")
					.then((respons) => {
						this.careerDetails = respons.data;
						this.$root.spinner = false;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			imageUrl(imagePath) {
				return this.$root.baseurl + "/public/" + imagePath;
			},
		},
		mounted() {
			this.get_data();
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
	};
</script>

<style scoped>
	.career-details .career-banner {
		width: 100%;
		border-radius: 20px;
		height: 300px;
	}

	.career-details .career-banner img {
		border-radius: 2px;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	.career-details .career-text-heading .title h1 {
		font-size: 28px;
		font-weight: 700;
		margin-bottom: 6px;
	}

	.career-details .career-text-heading .title {
		font-size: 2.4rem;
		font-weight: 700;
		letter-spacing: 0.05em;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	}

	.career-details .apply a {
		background-color: #6dc8be;
		padding: 12px 20px;
		border-radius: 5px;
		color: #ffffff;
		transition: all linear 0.2s;
	}

	.career-details .apply a:hover {
		background-color: #070919;
		padding: 12px 20px;
		border-radius: 5px;
		color: #ffffff;
		transition: all linear 0.2s;
	}

	.career-details .sidebar-icon-item {
		min-width: 140px;
		font-weight: bold;
		font-size: 15px;
	}

	.career-details .job-overview .sidebar-icon-item i {
		color: #6dc8be;
		font-size: 18px;
	}

	.career-details .content-single h4 {
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 20px;
		color: #6dc8be;
	}

	.career-details .content-single p {
		margin-bottom: 20px;
	}

	.career-details .content-single ul {
		padding-left: 30px;
		margin-bottom: 20px;
	}

	.career-details .content-single ul li {
		list-style: disc;
		margin-bottom: 14px;
	}

	.career-details .content-single ul li:last-child {
		margin-bottom: 0;
	}

	.career-details .single-apply .social span {
		font-size: 16px;
		font-weight: 400;
	}

	.career-social {
		width: 35px;
		height: 35px;
		font-size: 12px;
		text-align: center;
		line-height: 35px;
		border-radius: 50%;
		/* background-color: #6dc8be; */
		color: #ffffff;
		transition: all linear 0.2s;
	}

	.career-social:hover {
		background-color: #070919;
	}

	.facebook {
		background-color: #166fe5;
	}

	.twitter {
		background-color: #1d9bf0;
	}

	.linkedin {
		background-color: #0a66c2;
	}

	.instagram {
		background-color: #ff00b9;
	}

	.small-heading {
		font-size: 18px;
	}

	@media (max-width: 576px) {
		.social-left {
			text-align: center !important;
			margin-top: 40px;
			margin-right: 30px;
		}
		.career-text-heading .apply {
			text-align: left !important;
			margin-bottom: 30px;
		}
		.social-left {
			text-align: left !important;
		}
	}
</style>