<template>
	<div class="product-details container-wrapper">
		<div class="row py-5">
			<div class="col-lg-5">
				<div class="preview-image">
					<!--          <img class="product-image" :src="productDetails.image1" alt="" />-->
					<ProductZoomer
						v-if="images && Object.keys(images.thumbs).length > 0"
						:base-images="images"
						:base-zoomer-options="zoomerOptions"
					/>
				</div>
			</div>
			<div class="col-lg-7">
				<div class="product-details">
					<h3 class="product_name">{{ productDetails.product_name }}</h3>
					<h5 class="product_id">Product id: {{ productDetails.code }}</h5>
					<div class="star-review mb-2">
						<span class="mx-1" v-for="star in ratingLimit" :key="star">
							<i
								:class="
									Object.keys(allReview).length >= star
										? 'fa fa-star text-primary'
										: 'fa fa-star-o text-primary'
								"
							></i>
						</span>
						<span>| {{ Object.keys(allReview).length }} Ratings</span>
						<span>
							|
							<span v-if="allQuestions">
								{{ Object.keys(allQuestions.feedback).length }}
							</span>
							Answered Questions
						</span>
					</div>
					<div class="productid-brand mb-3" v-if="productDetails.brand">
						<span>
							<b>Brand:</b>
							<span>{{ productDetails.brand.name }}</span>
						</span>
					</div>
					<hr />
					<div class="price">
						<h2
							class="mb-3"
							v-if="
								productDetails.discount &&
								productDetails.discount.discount_type == 1
							"
						>
							৳
							{{
								(productDetails.price - productDetails.discount.discount)
									| currency
							}}
						</h2>
						<h2
							class="mb-3"
							v-else-if="
								productDetails.discount &&
								productDetails.discount.discount_type == 2
							"
						>
							৳
							{{
								(productDetails.price -
									(productDetails.discount.discount / 100) *
										productDetails.price)
									| currency
							}}
						</h2>
						<h2 class="mb-3" v-else>৳ {{ productDetails.price | currency }}</h2>

						<h4 class="discout_price" v-if="productDetails.discount">
							<strike style="color: #ff5f65 !important"
								>৳{{ productDetails.price }}</strike
							>-
							<span
								v-if="
									productDetails.discount &&
									productDetails.discount.discount_type == 1
								"
								>৳{{ productDetails.discount.discount | currency }}</span
							>
							<span
								v-else-if="
									productDetails.discount &&
									productDetails.discount.discount_type == 2
								"
								>{{ productDetails.discount.discount }}%</span
							>
						</h4>
					</div>

					<p v-if="productDetails.weight">
						<b>Weight:</b>
						{{ productDetails.weight }}
					</p>

					<slot v-if="productDetails.criteria">
						<p v-for="(item, index) in productDetails.criteria" :key="index">
							<b v-if="item.field">{{ item.field }}:</b>
							{{ item.value }}
						</p>
					</slot>

					<div
						class="color-falimy d-flex align-items-center"
						v-if="productDetails.color && productDetails.color.length > 0"
					>
						<b>Color Family:</b>
						<div
							class="colors"
							v-for="(color, cindex) in productDetails.color"
							:key="cindex"
						>
							<b-form-checkbox
								:id="color"
								v-model="data.color_family"
								:value="color"
								:name="color"
								>{{ color }}</b-form-checkbox
							>
						</div>
					</div>
					<p>
						<small v-if="colorMsg" class="text-danger">{{ colorMsg }}</small>
					</p>
					<div
						class="product-size d-flex align-items-center"
						v-if="productDetails.size && productDetails.size.length > 0"
					>
						<b>Size:</b>
						<div
							class="size"
							v-for="(size, sindex) in productDetails.size"
							:key="sindex"
						>
							<input
								type="radio"
								v-model="data.size"
								:value="size"
								class="d-none"
								:id="size"
								name="size"
							/>
							<label
								class="size-box1"
								:class="data.size === size ? 'active' : ''"
								:for="size"
								>{{ size }}</label
							>
						</div>
					</div>
					<p>
						<small v-if="sizeMsg" class="text-danger">{{ sizeMsg }}</small>
					</p>

					<p v-if="productDetails.type">
						<span>
							<b>Type:</b>
							{{ productDetails.type }}
						</span>
					</p>

					<div class="quantity d-flex align-items-center">
						<span>
							<b>Quantity:</b>
						</span>
						<div class="input-group ml-3">
							<div class="input-group-prepend">
								<span @click="decrement()" class="input-group-text">
									<i class="fa fa-minus"></i>
								</span>
							</div>
							<input
								type="number"
								min="1"
								class="form-control"
								name="quantity"
								v-model="data.quantity"
								aria-label="Amount (to the nearest dollar)"
							/>
							<div class="input-group-append">
								<span @click="increment()" class="input-group-text">
									<i class="fa fa-plus"></i>
								</span>
							</div>
						</div>
					</div>
					<br />
					<h5 v-if="!inStock">
						<span class="badge badge-danger">Stock out</span>
					</h5>
					<div class="buttons my-5">
						<button
							:class="!inStock ? 'disabled' : ''"
							@click.prevent="buyNow(productDetails, stock)"
							class="btn btn-buynow"
						>
							<i class="fa fa-shopping-bag"></i>Buy Now
						</button>

						<button
							:class="!inStock ? 'disabled' : ''"
							@click="addToCart(productDetails, stock)"
							class="btn-add-to-cart"
						>
							<i class="fa fa-shopping-cart"></i> Add to Cart
						</button>
						<!-- <button
							:class="!inStock ? 'disabled' : ''"
							@click="cartUpdate(productDetails, stock)"
							v-if="editCart"
							class="btn-add-to-cart"
						>
							<i class="fa fa-edit"></i> Update Cart
						</button>-->
						<button
							@click="wishList(productDetails.id)"
							class="btn-add-to-favourite mt-lg-0 mt-3"
						>
							<i class="fa fa-heart"></i> Add to Favourite
						</button>
					</div>
				</div>
			</div>
			<div class="description mt-5 w-100">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
						<a
							class="nav-link active"
							id="home-tab"
							data-toggle="tab"
							href="#home"
							role="tab"
							aria-controls="home"
							aria-selected="true"
							>Product detials</a
						>
					</li>
					<li class="nav-item" role="presentation">
						<a
							class="nav-link"
							id="profile-tab"
							data-toggle="tab"
							href="#profile"
							role="tab"
							aria-controls="profile"
							aria-selected="false"
							>Ratings & Reviews ({{ Object.keys(allReview).length }})</a
						>
					</li>
					<li class="nav-item" role="presentation">
						<a
							class="nav-link"
							id="contact-tab"
							data-toggle="tab"
							href="#contact"
							role="tab"
							aria-controls="contact"
							aria-selected="false"
						>
							Questions
							<span v-if="allQuestions"
								>({{ Object.keys(allQuestions.feedback).length }})</span
							>
						</a>
					</li>
				</ul>
				<div class="tab-content p-4" id="myTabContent">
					<div
						class="tab-pane fade show active"
						id="home"
						role="tabpanel"
						aria-labelledby="home-tab"
					>
						<b>Description:</b>
						<p v-html="productDetails.product_details"></p>
					</div>
					<div
						class="tab-pane fade w-100"
						id="profile"
						role="tabpanel"
						aria-labelledby="profile-tab"
					>
						<div
							class="rating-review"
							v-if="allReview && Object.keys(allReview).length > 0"
						>
							<div
								class="py-2 px-4 border-bottom mb-3"
								v-for="(review, index) in allReview"
								:key="index"
							>
								<div class="d-flex align-content-center">
									<b-avatar variant="primary" text="CM"></b-avatar>
									<h4 class="ml-3 mt-1 mb-0">{{ review.customer_name }}</h4>
								</div>
								<div class="ml-5 rate d-flex">
									<span class="mx-1" v-for="star in ratingLimit" :key="star">
										<i
											:class="
												review.rating >= star
													? 'mdi mdi-star text-warning'
													: 'mdi mdi-star-outline'
											"
										></i>
									</span>
									<h5 class="mb-0">{{ review.rating }}.0</h5>
									<small class="ml-3 mt-1 text-muted">{{
										review.created_at
									}}</small>
								</div>
								<div class="comment ml-5">{{ review.review }}</div>
							</div>
						</div>
						<div class="text-center my-5" v-else>
							<h2 class="text-danger">No Rating and review</h2>
						</div>
						<div class="review-form">
							<h4>Write a review</h4>
							<form @submit.prevent="submitReview">
								<div class="form-group required">
									<div class="col-sm-6">
										<label class="control-label" for="input-name"
											>Your Name</label
										>
										<input
											type="text"
											required
											name="customer_name"
											v-model="review.customer_name"
											id="input-name"
											class="form-control"
										/>
									</div>
								</div>
								<div class="form-group required">
									<div class="col-sm-12 d-flex">
										<label class="control-label">Rating</label>
										<div
											class="star mx-2"
											v-for="star in ratingLimit"
											:key="star"
										>
											<input
												type="checkbox"
												v-model="review.rating"
												name="rating"
												@change="changeStar(star)"
												class="d-none"
												:id="'start' + star"
											/>
											<label :for="'start' + star">
												<i
													class="h4"
													:class="
														review.rating >= star
															? 'mdi mdi-star text-warning'
															: 'mdi mdi-star-outline'
													"
												></i>
											</label>
										</div>
									</div>
								</div>
								<div class="form-group required">
									<div class="col-sm-12">
										<label class="control-label" for="input-review"
											>Your Review</label
										>
										<textarea
											required
											name="review"
											v-model="review.review"
											rows="3"
											id="input-review"
											class="form-control"
											spellcheck="false"
										></textarea>
									</div>
								</div>
								<div class="form-group required">
									<div class="col-sm-12">
										<button class="btn btn-primary" type="submit">Send</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div
						class="tab-pane fade"
						id="contact"
						role="tabpanel"
						aria-labelledby="contact-tab"
					>
						<QuestionAns />
					</div>
				</div>
			</div>

			<div class="contact-info my-4 w-100">
				<div class="card card-body p-4">
					<h2>Have questions about this product ({{ productDetails.code }})</h2>
					<h3>
						<b> <i class="mdi mdi-phone"></i>+8801953331124 </b>
					</h3>
					<h3>
						<b> <i class="mdi mdi-email-outline"></i>web@basis.org.bd </b>
					</h3>
				</div>
			</div>
			<RelatedProduct />
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import RelatedProduct from "../elements/RelatedProduct";
	import ProductZoomer from "vue-product-zoomer";
	import QuestionAns from "../elements/QuestionAns";
	import addToCartMixin from "../../../../addToCartMixin";
	Vue.use(ProductZoomer);
	export default {
		name: "ProductDetails",
		components: { QuestionAns, RelatedProduct },
		mixins: [addToCartMixin],

		data() {
			return {
				data: {
					color_family: "",
					quantity: 1,
					size: "",
				},
				ratingLimit: 5,
				colorMsg: "",
				sizeMsg: "",

				productDetails: [],
				editCart: false,

				images: {
					thumbs: [],
					normal_size: [],
				},
				zoomerOptions: {
					zoomFactor: 3,
					pane: "pane",
					hoverDelay: 100,
					namespace: "zoomer-left",
					move_by_click: false,
					scroll_items: 4,
					choosed_thumb_border_color: "#dd2c00",
					scroller_position: "left",
					zoomer_pane_position: "right",
				},
				review: {
					customer_name: "",
					product_id: "",
					rating: "",
					review: "",
				},
				allReview: [],
				questionStore: {
					product_id: "",
					customer_name: "",
					customer_question: "",
				},
				allQuestions: {
					feedback: [],
				},
				relatedProducts: [],
				stock: {},
				inStock: true,
			};
		},
		methods: {
			changeStar(star) {
				return (this.review.rating = star);
			},
			color_size_set() {
				let slug = this.$route.params.slug;
				let product = this.$store.state.cart.find((e) => e.slug == slug);
				if (product) {
					this.data.size = product.size_select;
					this.data.color_family = product.color_select;
					if (product.qty) {
						this.data.quantity = product.qty;
					}

					this.editCart = true;
				}
			},

			submitReview() {
				axios.post("ecommerce/rating-review-store", this.review).then((res) => {
					if (res.data.status == 200) {
						this.$toastr("success", res.data.message, "Success!!");
						this.review.customer_name = "";
						this.review.rating = "";
						this.review.review = "";
						this.getReview();
					} else if (res.data.status == 300) {
						this.$toastr("error", res.data.message, "Error!!");
					}
				});
			},

			getReview() {
				axios
					.get("ecommerce/get-rating-review/" + this.$route.params.slug)
					.then((res) => {
						this.allReview = res.data;
					});
			},
			productQuestionSubmit() {
				axios
					.post("ecommerce/product-question-store", this.questionStore)
					.then((res) => {
						if (res.data.status == 200) {
							this.$toastr("success", res.data.message, "Success!!");
							this.questionStore.customer_name = "";
							this.questionStore.customer_question = "";
							this.getQuestions();
						} else if (res.data.status == 300) {
							this.$toastr("error", res.data.message, "Error!!");
						}
					});
			},
			getQuestions() {
				axios
					.get("ecommerce/get-produt-question/" + this.$route.params.slug)
					.then((res) => {
						this.allQuestions = res.data;
					});
			},
			async getProductDetails() {
				let slug = this.$route.params.slug;
				if (slug) {
					await axios.get(`ecommerce/product-details/${slug}`).then((res) => {
						this.productDetails = res.data.product;
						this.relatedProducts = res.data.related_products;
						this.review.product_id = res.data.product.id;
						this.questionStore.product_id = res.data.product.id;

						this.images = res.data.images;

						this.$root.loading = false;
						this.$parent.loading = false;
					});
				}

				this.color_size_set();
				this.getReview();
				this.getQuestions();
			},
			checkStock(size, color, product_id) {
				let data = {
					size: size,
					color: color,
					product_id: product_id,
				};
				axios.post("ecommerce/check-stock", data).then((res) => {
					this.stock = res.data;
					if (Object.keys(res.data).length > 0) {
						this.inStock = true;
						this.productDetails.price = res.data.sales_price;
					} else {
						this.inStock = false;
					}
				});
			},
		},
		watch: {
			$route: {
				handler: "getProductDetails",
				immediate: true,
			},
			"data.size": function (size) {
				if (this.productDetails.id && size && this.data.color_family) {
					this.checkStock(size, this.data.color_family, this.productDetails.id);
				}
			},
			"data.color_family": function (color) {
				if (this.productDetails.id && color && this.data.color_family) {
					this.checkStock(this.data.size, color, this.productDetails.id);
				}
			},
		},

		created() {
			this.$eventBus.$emit("ecomloading", true);
			setTimeout(() => {
				this.$eventBus.$emit("ecomloading", false);
			}, 1000);
		},

		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
	};
</script>

<style>
	.zoomer-left-base-container {
		width: 100% !important;
	}
	div#zoomer-left-pane-container {
		height: 398px !important;
		background: #fff !important;
	}
	.zoomer-left-base-container .preview-box {
		width: 400px;
		height: 400px;
		object-fit: contain;
	}
	.thumb-list {
		width: 100% !important;
		height: 400px !important;
	}
	.thumb-list .responsive-image,
	.scroller-at-left {
		width: auto;
		height: 80px !important;
		object-fit: contain;
	}
	img.zoomer-control.responsive-image {
		height: 50px !important;
	}
</style>
