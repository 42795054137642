<template>
	<div>
		<h4 class="mt-3">Ticket ID: {{data.helpDesk.ticket_id}}</h4>
		<p>
			<b>Subject:</b>
			<span>{{ data.helpDesk.subject }}</span>
		</p>

		<p class="mb-0">
			<b>Message:</b>
		</p>
		<p>{{ data.helpDesk.description }}</p>
		<div class="card mt-3">
			<div class="card-body card-chatbox">
				<ul class="chat">
					<li v-for="(item,index) in data.messages" :key="index">
						<div class="left text-body clearfix" v-if="item.admin_id">
							<div class="d-flex align-content-center justify-content-end">
								<div class="username ml-3">
									<strong class="primary-font">BASIS Support</strong>
									<br />
									<small class="pull-right text-muted">
										<span class="fa fa-clock"></span>
										{{item.created_at}}
									</small>
								</div>
								<b-avatar variant="warning" text="AM"></b-avatar>
							</div>
							<div class="chat-body clearfix admin_chatbox">
								<p>{{item.description}}</p>
							</div>
						</div>
						<div class="right text-right text-body clearfix" v-if="item.user_id">
							<div class="d-flex align-content-center">
								<b-avatar variant="primary" text="CM"></b-avatar>
								<div class="username mr-3">
									<strong class="primary-font">{{item.user.name}}</strong>
									<br />
									<small class="pull-right text-muted">
										<span class="fa fa-clock"></span>
										{{item.created_at }}
									</small>
								</div>
							</div>
							<div class="chat-body clearfix customer_chatbox">
								<p>{{item.description}}</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="card-footer" v-if="data.helpDesk.reply_status == 'open'">
				<div class="input-group">
					<input
						id="btn-input"
						type="text"
						@keyup.enter="msgSend()"
						class="form-control border-0"
						v-model="storeMsg.description"
						placeholder="Type your message here..."
					/>
					<span class="input-group-btn">
						<button
							@click.prevent="msgSend()"
							style="height: 32px;"
							class="btn btn-warning btn-sm"
							id="btn-chat"
						>Send</button>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "HelpDeskView",
		data() {
			return {
				data: {
					helpDesk: {
						ticket_id: ""
					}
				},
				storeMsg: {
					parent_id: ""
				}
			};
		},
		methods: {
			getHelpDeskInfo() {
				// My Order
				axios
					.get("/ecommerce/get-help-desk-info/" + this.$route.params.id)
					.then(res => (this.data = res.data));
			},
			msgSend() {
				axios
					.post("/ecommerce/ticket-resply-message-store", this.storeMsg)
					.then(res => {
						this.storeMsg.description = "";
						this.getHelpDeskInfo();
					});
			}
		},

		created() {
			this.getHelpDeskInfo();
			this.storeMsg.parent_id = this.$route.params.id;
		},

		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		}
	};
</script>

<style scoped>
	th {
		background: rgb(244 244 244);
		border: 0;
		box-shadow: 0px 1px 1px #4444;
	}
	td {
		border: 0;
		background: rgb(255 255 255);
		box-shadow: 0px 0px 1px #4444;
	}
	.card-chatbox {
		height: 25em;
		overflow: hidden;
		overflow-y: scroll;
	}
	.admin_chatbox {
		background: #ace2cb;
		height: auto;
		border-radius: 0px 20px 0px 20px;
		width: 80%;
		padding: 10px;
		margin-left: 156px !important;
	}
	.customer_chatbox {
		background: #75bfea;
		height: auto;
		border-radius: 20px 2px;
		width: 80%;
		padding: 10px;
	}
</style>
