<template>
  <div class="member-services" v-if="!$parent.loading && $root.variable.aLogId">
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="px-3 mb-0">Add Service</h5>
        </div>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="submit" id="form">
          <div class="row">
            <div class="col-lg-6 mb-3">
              <label for="comments">Service <span class="requierd-star"></span></label>
              <select
                  name="legal_structure"
                  v-model="data.service_id"
                  class="form-control"
                >
                  <option :value="null">Choose...</option>
                  <option
                    v-for="(service,index) in extraData.paidServices"
                    :key="index"
                    :value="service.id"
                  >{{ service.title }}</option>
                  <option
                    v-for="(service1,id,index) in extraData.freeServices"
                    :key="index"
                    :value="id"
                  >{{ service1.title }}</option>
                </select>
            </div>
            <div class="col-lg-6 mb-3">
              <label for="comments">Amount</label>
              <input
                type="number"
                class="form-control shadow-none"
                placeholder="Amount"
                v-model.number="data.amount"
              />
            </div>
            <div class="col-lg-6 mb-3">
              <label for="comments">Application Date</label>
              <Datepicker
                  class="form-control shadow-none"
                  placeholder="e.g. 12-12-2022"
                  v-model="data.date"
                />
            </div>
            <div class="col-lg-6 mb-3">
              <label for="comments">Card Holder No.</label>
              <input
                type="text"
                class="form-control shadow-none"
                placeholder="Card Holder No"
                v-model="data.card_holder_no"
              />
            </div>
          </div>
          <button
            class="btn btn-success"
            type="submit"
          >Add Service</button>
        </form>
      </div>
      <!-- <pre>{{data}}</pre> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "CreateExistingService",
  data() {
    return {
      data:{service_id:null, amount:0},
      extraData: {
        paidServices: {},
        freeServices: {}
      },
      errors: {}
    };
  },
  methods: {
     submit: function() {
      if(!this.data.service_id){
        this.$toastr("warning", "Please Select Service", "Warning!!");
        return false;
      }
      this.data.form_type = this.$route.name;
      axios
        .post("/member/store-company-info", this.data)
        .then(response => {
          this.$toastr(
            "success",
            "Service Added Successfull",
            "Success!!"
          );
          this.$router.push({ name: "myService" }); 
        })
        .catch(error => {
          console.log(error);
        });
    },
    getMemberService: function() {
      axios
        .get("/service/member")
        .then(response => {
          this.extraData.paidServices = response.data.paid;
          this.extraData.freeServices = response.data.free;
        })
        .catch(error => {
          console.log(error.response);
        })
        .then(always => {
          setTimeout(
            () => (this.$parent.loading = false),
            this.$root.minloadtimefd
          );
        });
    },
  },
  mounted() {
    this.memberLoggedIn();
    this.getMemberService();
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
};
</script>

