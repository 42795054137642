<template>
  <div class="about">
    <PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
    <div class="market-body py-4">
      <div class="container-wrapper">
        <div class="row">
          <!-- <div class="col-lg-3">
            <div class="leftside-menu" v-if="leftmenu">
              <div class="card shadow-sm py-4 mb-3 rounded-10">
                <span class="shape"></span>
                <div class="card-header bg-transparent">
                  <h3 class="mb-0">{{title}}</h3>
                </div>
                <ul class="nav flex-column">
                  <li class="nav-item" v-for="(item, index) in leftmenu" :key="index">
                    <router-link
                      class="nav-link"
                      v-if="item.title == 'Events'"
                      :to="{name:'allEvents', params:{market:'local',type:'events'}}"
                    >{{item.title}}</router-link>
                    <router-link
                      class="nav-link"
                      v-else-if="item.title == 'Awards'"
                      :to="{name:'allEvents', params:{market:'local',type:'awards'}}"
                    >{{item.title}}</router-link>
                    <a
                      class="nav-link"
                      v-else-if="item.id == 2"
                      href="http://nbr.gov.bd/nbr-enlisted/eng"
                      target="_blank"
                    >{{item.title}}</a>
                    <a
                      class="nav-link"
                      v-else-if="item.id == 3"
                      href="https://basisegovhub.info"
                      target="_blank"
                    >{{item.title}}</a>
                    <router-link
                      class="nav-link"
                      v-else
                      :to="{ name:'market_detail', params: { id: item.id }}"
                    >{{item.title}}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>-->
          <div class="col-lg-12">
            <div id="example-content">
              <div class="row">
                <slot v-if="market.image">
                  <div class="col-xs-12 col-lg-12 text-center">
                    <img v-lazy="$root.baseurl+'/public'+market.image" class="img-fluid" alt="..." />
                  </div>
                  <div class="col-xs-12 col-lg-12" data-aos="fade-right">
                    <!--Heading-->
                    <!--Text-->
                    <p class="text-justify" v-html="market.description"></p>
                  </div>
                </slot>
                <slot v-else>
                  <div class="col-xs-12 col-lg-12" data-aos="fade-right">
                    <p class="text-justify" v-html="market.description"></p>
                    <div data-aos="fade-right" v-if="market.market_details.length > 0">
                      <table class="table table-striped table-fixed table-bordered">
                        <thead>
                          <tr class="thead-dark">
                            <th>SL</th>
                            <th>Title</th>
                            <th>File</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(market_file, index) in market.market_details" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{ market_file.title }}</td>
                            <td>
                              <a
                                :href="$root.baseurl+'/public'+market_file.file"
                                target="_blank"
                                class="nav-link"
                              >View PDF</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import PageBanner from "../../components/elements/PageBanner";
import axios from "axios";
const breadcumb = [
  {
    title: "Market"
  }
];
export default {
  name: "About",
  components: {
    PageBanner
  },
  data() {
    return {
      title: "",
      market: {
        market_details: [],
        breadcrumbTitle: "",
        breadcumb: []
      },
      leftmenu: {},
      breadcrumbTitle: "",
      breadcumb: []
    };
  },
  methods: {
    asyncData() {
      this.get_data();
    },
    get_data() {
      axios
        .get("/get-market-details/" + this.$route.params.id)
        .then(respons => {
          this.title = respons.data.result.type;
          this.market = respons.data.result;
          this.breadcrumbTitle = respons.data.result.title;
          this.breadcumb = [
            {
              title: respons.data.result.type,
              route_name: "market",
              params: respons.data.result.type
            },
            { title: this.breadcrumbTitle }
          ];
          this.leftmenu = respons.data.leftmenu;
        })
        .catch(error => {
          console.log(error);
        })
        .then(res => {
          setTimeout(
            () => (this.$root.loading = false),
            this.$root.minloadtimefd
          );
        });
    }
  },
  mounted() {},
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    }
  }
};
</script>
