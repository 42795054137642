<template>
	<div id="example-content">
		<div class="service-tab" v-if="!$root.spinner">
			<div class="content-header mb-5">
				<ul
					class="nav nav-tabs justify-content-center"
					id="myTab"
					role="tablist"
				>
					<li class="nav-item">
						<a
							class="nav-link"
							:class="
								$route.params.type == 'events' || activeEvets
									? 'active'
									: ''
							"
							id="events-tab"
							data-toggle="tab"
							href="#events"
							role="tab"
							aria-controls="events"
							aria-selected="true"
							>Events</a
						>
					</li>
					<li class="nav-item">
						<a
							class="nav-link"
							:class="
								$route.params.type == 'awards' ? 'active' : ''
							"
							id="awards-tab"
							data-toggle="tab"
							href="#awards"
							role="tab"
							aria-controls="awards"
							aria-selected="false"
							>Awards</a
						>
					</li>
				</ul>
			</div>
			<div class="service-item tab-content" id="myTabContent">
				<!-- events -->
				<div
					class="events tab-pane fade"
					:class="
						$route.params.type == 'events' || activeEvets
							? 'show active'
							: ''
					"
					id="events"
					role="tabpanel"
					aria-labelledby="events-tab"
				>
					<div class="row" v-if="events">
						<div
							class="col-lg-6 mb-3"
							v-for="(item, index) in events"
							:key="index"
						>
							<div class="card shadow-sm mb-3">
								<span class="shape2"></span>
								<div class="row no-gutters h-100">
									<div class="col-md-4">
										<img
											v-if="item.image"
											v-lazy="
												$root.baseurl +
												'/public' +
												item.image
											"
											class="card-img"
											:alt="item.title"
										/>
									</div>
									<div class="col-md-8">
										<div class="card-body h-100">
											<h5 class="card-title">
												{{
													getPostBody(item.title, 70)
												}}
											</h5>
											<div
												class="
													eventDateTime
													d-flex
													align-items-center
													justify-content-between
													mb-2
												"
											>
												<span class="date text-danger">
													<b>
														<i
															class="
																mdi
																mdi-calendar-month
																pr-1
															"
														></i>
														{{
															item.start_date
																| formatDate(
																	"Do MMM"
																)
														}}
														to
														{{
															item.endDate
																| formatDate(
																	"Do MMM YYYY"
																)
														}}
													</b>
												</span>
												<span class="location">
													<b>
														<i
															class="
																mdi
																mdi-map-marker-outline
																pr-1
															"
														></i>
														{{ item.location }}
													</b>
												</span>
											</div>
											<p class="card-text">
												{{
													getPostBody(
														item.description,
														130
													)
												}}
											</p>
											<router-link
												:to="{
													name: 'event.detail',
													params: { slug: item.slug },
												}"
												class="
													btn
													btn-sm
													btn-outline-primary
												"
												>Details</router-link
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row" v-else>
						<h5 class="text-danger">No events yet</h5>
					</div>
				</div>

				<!-- awards -->
				<div
					class="awards tab-pane fade"
					:class="$route.params.type == 'awards' ? 'show active' : ''"
					id="awards"
					role="tabpanel"
					aria-labelledby="awards-tab"
					v-if="awards"
				>
					<div class="row" v-if="awards">
						<div
							class="col-lg-6 mb-3"
							v-for="(item, index) in awards"
							:key="index"
						>
							<div class="card shadow-sm mb-3">
								<span class="shape2"></span>
								<div class="row no-gutters h-100">
									<div class="col-md-4">
										<img
											v-if="item.image"
											v-lazy="
												$root.baseurl +
												'/public' +
												item.image
											"
											class="card-img"
											:alt="item.title"
										/>
									</div>
									<div class="col-md-8">
										<div class="card-body h-100">
											<h5 class="card-title">
												{{
													getPostBody(item.title, 70)
												}}
											</h5>
											<div
												class="
													eventDateTime
													d-flex
													align-items-center
													justify-content-between
													mb-2
												"
											>
												<span class="date text-danger">
													<b>
														<i
															class="
																mdi
																mdi-calendar-month
																pr-1
															"
														></i>
														{{
															item.start_date
																| formatDate(
																	"Do MMM"
																)
														}}
														to
														{{
															item.endDate
																| formatDate(
																	"Do MMM YYYY"
																)
														}}
													</b>
												</span>
												<span class="location">
													<b>
														<i
															class="
																mdi
																mdi-map-marker-outline
																pr-1
															"
														></i>
														{{ item.location }}
													</b>
												</span>
											</div>
											<p class="card-text">
												{{
													getPostBody(
														item.description,
														150
													)
												}}
											</p>
											<router-link
												:to="{
													name: 'event.detail',
													params: { slug: item.slug },
												}"
												class="
													btn
													btn-sm
													btn-outline-primary
												"
												>Details</router-link
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row" v-else>
						<center v-if="!$root.spinner">
							<h5 class="text-danger">No events yet</h5>
						</center>
					</div>
				</div>
			</div>
		</div>
		<spinner v-if="$root.spinner" />
	</div>
</template>
<script>
	const breadcumb = [
		{
			title: "Event"
		}
	];
	export default {
		name: "Event",
		data() {
			return {
				breadcumb: breadcumb,
				breadcrumbTitle: "Event",
				international: false,
				local: false,
				events: null,
				awards: null,
				activeEvets: false
			};
		},
		methods: {
			asyncData() {
				this.get_datas(this.$route.params.market, this.$route.params.type);
				this.$root.loading = false;
			},
			get_datas: function(market = null, type = null, date = null) {
				if (market == "international") {
					this.local = false;
					this.international = true;
				} else {
					this.local = true;
					this.international = false;
				}
				let url = "/get-ea/" + market + "/" + type;
				this.$root.spinner = true;
				axios
					.get(url, { params: { date: date } })
					.then(respons => {
						this.events = respons.data.events;
						this.awards = respons.data.awards;
						this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
						this.$root.spinner = false;
					})
					.catch(error => {});
			},
			getEvent(market, type) {
				var date = this.$route.params.date ? this.$route.params.date : "";
				this.get_datas(market, type, date);
			}
		},
		mounted() {
			var date = this.$route.params.date ? this.$route.params.date : "";
			this.get_datas(
				this.$route.params.market,
				this.$route.params.type,
				date
			);
			if (date) {
				this.activeEvets = true;
			}
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		watch: {
			$route: {
				handler: "asyncData",
				immediate: true
			}
		}
	};
</script>
<style scoped>
	.sidebar-menu.vue-affix {
		top: 0px !important;
		width: 280px;
		animation: FadeUp 1s ease forwards;
	}
	.sidebar-menu.vue-affix.affix {
		top: 70px !important;
		z-index: 1030;
	}

	@keyframes FadeUp {
		form {
			top: 0;
		}
		to {
			top: 70px !important;
		}
	}
</style>
