<template >
  <div v-if="service">
    <h4 class="text-center">
      <span class="text-success">Service Information</span>
    </h4>
    <table class="table">
      <tr>
        <td>Description of the service</td>
        <td>:</td>
        <td style="white-space: pre-wrap;">{{ service.service }}</td>
      </tr>
      <tr>
        <td>Target Industry</td>
        <td>:</td>
        <td>{{ industryLists[service.industry] }}</td>
      </tr>
      <tr>
        <td>Export/Local</td>
        <td>:</td>
        <td>{{ service.type }}</td>
      </tr>
      <tr>
        <td>Scale of the proposed service</td>
        <td>:</td>
        <td>{{ service.scale }}</td>
      </tr>
      <tr>
        <td>Platform</td>
        <td>:</td>
        <td>{{ service.member_platforms }}</td>
      </tr>
      <tr>
        <td>Marketing material attached</td>
        <td>:</td>
        <td>{{ service.attachment }}</td>
      </tr>
    </table>
  </div>
</template>


<script>
export default {
  name: "ModalService",
  props: ["service", "industryLists"],
};
</script>

<style>
</style>
