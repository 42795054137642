import VueRouter from 'vue-router';
Vue.use(VueRouter)
require('./components.js');
import Vue from 'vue';
import App from './AppFrontend.vue';
import FrontendLayout from './views/FrontendLayout';
import ContentLayout from './views/Frontend/ContentLayout';
import CareerLayout from './views/Frontend/CareerLayout';

import Home from './views/Frontend/Home';
import GlobelSearch from './components/elements/GlobelSearch.vue';
import Contact from './views/Frontend/Contact';
import WhatsNew from './views/Frontend/WhatsNew';
import WhatsNewDetails from './views/Frontend/WhatsNewDetails';
import DiscountServices from './views/Frontend/DiscountServices';
import DiscountServiceDetails from './views/Frontend/DiscountServiceDetails';
import FormValidation from './components/FormValidation.vue';
import MemberRegistration from './views/Frontend/Member/BacomeAMember/MemberRegistration';
import MemberRegistrationWeb from './components/elements/MemberWeb';
import MemberRegisterLogin from './views/Frontend/Login/MemberRegisterLogin';
import MemberRegisterDashboard from './views/Frontend/Member/BacomeAMember/Layout';
import MemberRegisterDashboardPage from './views/Frontend/Member/BacomeAMember/Dashboard';
import MemberRegisterForm from './views/Frontend/Member/BacomeAMember/Form';
import regMemberInvoce from './views/Frontend/Member/BacomeAMember/Invoice';
import regMemberPaymentOption from './views/Frontend/Member/BacomeAMember/PaymentOption';
import memberPdf from './views/Frontend/Member/BacomeAMember/PDFForm';
import ApprovedReferenece from './views/Frontend/Member/SSL/ApprovedReferenece';
import FailedReferenece from './views/Frontend/Member/SSL/FailedReferenece';
import TimeExpired from './views/Frontend/Member/SSL/TimeExpiredReference';
import sslSuccessBecome from './views/Frontend/Member/SSL/success_become';
import sslSuccess from './views/Frontend/Member/SSL/success';
import sslSuccessNonMember from './views/Frontend/Member/SSL/successNonMember';
import sslCancel from './views/Frontend/Member/SSL/cancel';
import sslFail from './views/Frontend/Member/SSL/failed';
import LoanFacility from './views/Frontend/LoanFacility';
import LoanFacilityDetails from './views/Frontend/LoanFacilityDetails';

import MemberLogin from './views/Frontend/Login/MemberLogin';
import Authenticate from './views/Frontend/Login/Authenticate';
import Activate from './views/Frontend/Login/Activate';
import MemberDashboard from './views/Frontend/Member/Layout';
import MemberDashboardPage from './views/Frontend/Member/Dashboard';
import PaymentHistory from './views/Frontend/Member/PaymentsInvoice/PaymentHistory'
import MemberInvoce from './views/Frontend/Member/PaymentsInvoice/Invoice';
import MoneyReceipt from './views/Frontend/Member/PaymentsInvoice/MoneyReceipt';
// paid service
import Rapst from './views/Frontend/Member/services/Paid/Rapst';
import ScbAccountOfCompany from './views/Frontend/Member/services/Paid/ScbAccountOfCompany';
import PrimebankAccountOfCompany from './views/Frontend/Member/services/Paid/PrimebankAccountOfCompany';
import CreditCardAuditServiceForm from './views/Frontend/Member/services/Paid/CreditCardAuditServiceForm';
import BracCreditCardForm from './views/Frontend/Member/services/Paid/BracCreditCardForm';
import NrbCreditCardForm from './views/Frontend/Member/services/Paid/NrbCreditCardForm';
import MtbCreditCardForm from './views/Frontend/Member/services/Paid/MtbVisaCreditCardForm';
import EblCreditCardForm from './views/Frontend/Member/services/Paid/EblCreditCardForm';
import UcbCreditCardForm from './views/Frontend/Member/services/Paid/UcbCreditCardForm';
import EblPrepaidCardForm from './views/Frontend/Member/services/Paid/EblPrepaidCardForm';
import CashIncentiveForm from './views/Frontend/Member/services/Paid/CashIncentiveForm';
import RentalFacilitiesForm from './views/Frontend/Member/services/Paid/RentalFacilitiesForm';
import TrainingForm from './views/Frontend/Member/services/Paid/TrainingForm';
import EventForm from './views/Frontend/Member/services/Paid/EventForm';
import ServiceForm from './views/Frontend/Member/services/Paid/CommonServiceForm';
// paid event
import DefaultEventForm from './views/Frontend/Member/events/Paid/CommonEventForm';
// free service
import AccountOfCompany from './views/Frontend/Member/services/Free/AccountOfCompany';
import AccountOfIndividual from './views/Frontend/Member/services/Free/AccountOfIndividual';
import ScbAccountOfIndividual from './views/Frontend/Member/services/Free/ScbAccountOfIndividual';
import PrimebankAccountOfIndividual from './views/Frontend/Member/services/Free/PrimebankAccountOfIndividual';
import VirtualCardDBBL from './views/Frontend/Member/services/Free/VirtualCardDBBL';
import PrivilegeCardForm from './views/Frontend/Member/services/Free/PrivilegeCardForm';

import VoterInfo from './views/Frontend/Member/Voter/Create';
import ChangeRequestForm from './views/Frontend/Member/services/ChangeRequestForm';
import JoinCommitteeForum from './views/Frontend/Member/services/JoinCommitteeForum';
import CreateExistingService from './views/Frontend/Member/services/CreateExistingService';
import MyService from './views/Frontend/Member/services/MyService';
import ChangeHistory from './views/Frontend/Member/services/ChangeRequestHistory';
import ChangeRequestHistoryPending from './views/Frontend/Member/services/ChangeRequestHistoryPending';
import AllNotice from './views/Frontend/Member/Notifications/AllNotice';
import AllNoticeResource from './views/Frontend/Member/Notifications/NotificationResource';

import PaymentMethod from './views/Frontend/Member/PaymentsInvoice/PaymentMethod';
import PaymentOption from './views/Frontend/Member/PaymentsInvoice/PaymentOption';

import Content from './views/Frontend/Content.vue'
import ContentDetail from './views/Frontend/ContentDetail.vue'

import Event from './views/Frontend/Event'
import ViewEvent from './views/Frontend/ViewDetails/ViewEvent'

import News from './views/Frontend/News.vue'
import ViewNews from './views/Frontend/ViewDetails/ViewNews.vue'
import ViewRecentCeremony from './views/Frontend/ViewRecentCeremony.vue'

import PhotoGallery from './views/Frontend/Gallery/PhotoGallery.vue'
import Photo from './views/Frontend/Gallery/Photo.vue'
import VideoGallery from './views/Frontend/Gallery/VideoGallery.vue'
import Video from './views/Frontend/Gallery/Video.vue'

import MemberList from './views/Frontend/MemberList.vue'
import CompanyProfile from './views/Frontend/CompanyProfile.vue'
import EcSecretariat from './views/Frontend/EcSecretariat.vue'
import PreviousEcs from './views/Frontend/PreviousEcs.vue'

// member form
import ComplianceForm from './views/Frontend/Member/MemberForm/ComplianceForm'
import CompanyInfo from './views/Frontend/Member/MemberForm/CompanyInfo'
import RepresentativeForm from './views/Frontend/Member/MemberForm/RepresentativeForm'
import BusinessActivityForm from './views/Frontend/Member/MemberForm/BusinessActivityForm'
import VerticalExperienceForm from './views/Frontend/Member/MemberForm/VerticalExperienceForm'
import ProductsServicesForm from './views/Frontend/Member/MemberForm/ProductsServicesForm'
// import HumanResourceForm from './views/Frontend/Member/MemberForm/HumanResourceForm'
import UploadPhotosFiles from './views/Frontend/Member/MemberForm/UploadPhotosFiles'
import MemberSettings from './views/Frontend/Member/MemberForm/Settings'
import ChangePassword from './views/Frontend/Member/MemberForm/ChangePassword'


import onlineHelpdesk from './views/Frontend/Member/HelpDesk/OnlineHelpdesk'
import createTicket from './views/Frontend/Member/HelpDesk/CreateTicket'
import viewTicket from './views/Frontend/Member/HelpDesk/ViewTicket'

import viewNotification from './views/Frontend/Member/Notifications/viewNotification'
import viewResource from './views/Frontend/Member/Notifications/viewResource'

import PressKit from './views/Frontend/PressKit.vue'

import Service from './views/Frontend/Services/Service.vue'
import ViewService from './views/Frontend/ViewDetails/ViewService.vue'
import Training from './views/Frontend/Services/Training.vue'
import SeminarsWorkshops from './views/Frontend/Services/SeminarsWorkshops.vue'
import TrainingSeminarDetails from './views/Frontend/Services/TrainingSeminarDetails.vue'
import RentService from './views/Frontend/Services/RentService.vue'

import Market from './views/Frontend/Market.vue'
import ViewMarket from './views/Frontend/ViewMarket.vue'
import ViewPublication from './views/Frontend/Publication.vue'
import ViewAllPolicy from './views/Frontend/Policy.vue'
import ViewAllGazette from './views/Frontend/Gazette.vue'
import ViewAnnualReport from './views/Frontend/AnnualReport.vue'
import B2B from './views/Frontend/B2B.vue'
import B2BDetail from './views/Frontend/B2BDetail.vue'
import StandingCommittees from './views/Frontend/StandingCommittees.vue'

// non-member payment
import NonMemberPayment from './views/Frontend/NonMember/Payment.vue'
import CertificateList from './views/Frontend/Member/CertificateList.vue'
import CertificateEcourier from './views/Frontend/Member/CertificateEcourier.vue'

//E-Commerce
import EcommerceLayout from './views/Frontend/Ecommerce/Layout';
import EcommerceHome from './views/Frontend/Ecommerce/Home';
import EcommerceSubcategory from './views/Frontend/Ecommerce/elements/SubcategoryList';
import ProductPage from './views/Frontend/Ecommerce/pages/Products';
import ProductsDetails from './views/Frontend/Ecommerce/pages/ProductDetails';
import EcommerceLogin from './views/Frontend/Ecommerce/auth/Login';
import EcommerceMemberLogin from './views/Frontend/Ecommerce/auth/EcommerceMemberLogin';
import EcommerceRegistration from './views/Frontend/Ecommerce/auth/Registration';
import AddToCart from './views/Frontend/Ecommerce/pages/AddToCart';
import Checkout from './views/Frontend/Ecommerce/pages/Checkout';
//customer dashboard
import EcommerceDashboardLayout from './views/Frontend/Ecommerce/profile/Layout';
import EcommerceDashboard from './views/Frontend/Ecommerce/profile/Dashboard';
import EcommerceProfile from './views/Frontend/Ecommerce/profile/Profile';
import EcommerceAddressbook from './views/Frontend/Ecommerce/profile/Profile';
import EcommerceMyOrder from './views/Frontend/Ecommerce/profile/MyOrder';
import EcommerceCancelOrder from './views/Frontend/Ecommerce/profile/CancelOrder';
import EcommerceDelivertAddress from './views/Frontend/Ecommerce/profile/DeliveryAddress';
import EcommercePasswordChange from './views/Frontend/Ecommerce/profile/PasswordChange';
import EcommerceFeedBack from './views/Frontend/Ecommerce/profile/FeedBack';
import EcommerceHelpDesk from './views/Frontend/Ecommerce/profile/HelpDesk';
import EcommerceWishlist from './views/Frontend/Ecommerce/profile/Wishlist';
import EcommerceHelpDeskList from './views/Frontend/Ecommerce/profile/HelpDeskList';
import HelpDeskView from './views/Frontend/Ecommerce/profile/HelpDeskView';
import OrderInvoice from './views/Frontend/Ecommerce/pages/OrderInvoice';
import EcomPaymentMethod from './views/Frontend/Ecommerce/pages/PaymentMethod';

// event Calender
import EventCalender from './views/Frontend/EventCalender';
import PreviousEvent from './views/Frontend/PreviousEvent';

// career
import Career from './views/Frontend/Career';
import CareerDetails from './views/Frontend/CareerDetails';

const router = new VueRouter({
    // base: '/',
    base: process.env.MIX_VUE_ROUTER_BASE,
    mode: 'history',
    linkExactActiveClass: "active",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [{
        path: '/',
        component: FrontendLayout,
        children: [
            { path: '/', name: 'home', component: Home },
            { path: '/globelSearch', name: 'globelSearch', component: GlobelSearch },
            { path: '/contact', name: 'contact', component: Contact },
            { path: '/whats-new', name: 'whatsnew', component: WhatsNew },
            { path: '/whats-new-details/:id', name: 'whatsnewDetails', component: WhatsNewDetails },
            { path: '/discount-services/:id/:type', name: 'discount-services', component: DiscountServices },
            { path: '/discount-service-details/:id', name: 'discountServiceDetails', component: DiscountServiceDetails },
            { path: '/form-validation', name: 'form-validation', component: FormValidation },
            { path: '/memberRegistration', name: 'memberRegistration', component: MemberRegistration },
            { path: '/memberWeb', name: 'memberWeb', component: MemberRegistrationWeb },
            { path: '/reg-member-login', name: 'reg-member-login', component: MemberRegisterLogin },
            {
                path: '/reg-member/',
                component: MemberRegisterDashboard,
                children: [
                    { path: '/reg-member/dashboard', name: 'reg-member.dashboard', component: MemberRegisterDashboardPage },
                    { path: '/reg-member/form', name: 'reg-member.dashboardForm', component: MemberRegisterForm },
                    { path: '/reg-member/invoice', name: 'reg-member.invoice', component: regMemberInvoce },
                    { path: '/reg-member/payment-category', name: 'reg-member.paymentOption', component: regMemberPaymentOption },
                    { path: '/reg-member/pdf', name: 'reg-member.pdf', component: memberPdf },
                ]
            },
            {
                path: '/',
                component: ContentLayout,
                children: [
                    { path: '/content/:slug', name: 'content', component: Content },
                    { path: '/member-list', name: 'member-list', component: MemberList },
                    { path: '/committees-forums/:type', name: 'committees-forums', component: StandingCommittees },
                    // services
                    { path: '/service/:type', name: 'service', component: Service },
                    { path: '/service-details/:id/:type', name: 'service_detail', component: ViewService },
                    { path: '/non-member-payment/:id', name: 'NonMemberPayment', component: NonMemberPayment },
                    // ec / secretariat
                    { path: '/executive-council', name: 'executive-council', component: EcSecretariat },
                    { path: '/secretariat', name: 'secretariat', component: EcSecretariat },
                    { path: '/previous-ecs/', name: 'previous-ecs', component: PreviousEcs },
                    //news
                    { path: '/news/:type', name: 'news', component: News },
                    { path: '/news-details/:slug', name: 'news_detail', component: ViewNews },
                    // gallery
                    { path: '/photo-gallery', name: 'photo-gallery', component: PhotoGallery },
                    { path: '/photos/:photo_gallery_id/:type', name: 'photo_details', component: Photo },
                    { path: '/video-gallery', name: 'video-gallery', component: VideoGallery },
                    { path: '/videos/:video_gallery_id/:type', name: 'video_details', component: Video },

                    { path: '/press-kit', name: 'press-kit', component: PressKit },
                    { path: '/publication', name: 'publication', component: ViewPublication },
                    { path: '/annual-report', name: 'annual-report', component: ViewAnnualReport },
                    { path: '/policy/:type', name: 'policy', component: ViewAllPolicy },
                    { path: '/gazette/:type', name: 'gazette', component: ViewAllGazette },
                    // event
                    { path: '/ea/:market/:type', name: 'allEvents', component: Event },
                    { path: '/event-details/:slug', name: 'event.detail', component: ViewEvent },
                    { path: '/event-datewise/:date', name: 'eventDatewise', component: Event },
                    //training / seminar
                    { path: '/training/:id/:type', name: 'training', component: Training },
                    { path: '/details/:id/:type', name: 'TrainingSeminarDetails', component: TrainingSeminarDetails },
                    { path: '/seminars-workshops/:id/:type', name: 'seminars-workshops', component: SeminarsWorkshops },

                ]
            },
            // event
            { path: '/event-calender', name: 'allEventCalender', component: EventCalender },
            { path: '/get-prev-events', name: 'allPreviousEvent', component: PreviousEvent },

            { path: '/content-details/:slug', name: 'content_detail', component: ContentDetail },
            { path: '/b2b-details/:slug', name: 'b2b_detail', component: B2BDetail },
            { path: '/recent_ceremony_detail/:id', name: 'recent_ceremony_detail', component: ViewRecentCeremony },
            { path: '/b2b', name: 'b2b', component: B2B },
            { path: '/market/:type', name: 'market', component: Market },
            { path: '/rent-service/:id/:type', name: 'rentService', component: RentService },
            { path: '/loan-facility/:id/:type', name: 'loan-facility', component: LoanFacility },
            { path: '/loan-facility-details/:type', name: 'loan-facility-details', component: LoanFacilityDetails },

            // careeer
            {
                path: '/',
                component: CareerLayout,
                children: [
                    { path: '/career', name: 'career', component: Career },
                    { path: '/career/:id', name: 'career-details', component: CareerDetails },
                ]
            },



            { path: '/market-details/:id', name: 'market_detail', component: ViewMarket },
            { path: '/member-login', name: 'member-login', component: MemberLogin },
            { path: '/member-forgot-pass', name: 'member-forgotPass', component: MemberLogin },
            { path: '/authenticate', name: 'authenticate', component: Authenticate },
            { path: '/activate', name: 'activate', component: Activate },
            {
                path: '/member/',
                component: MemberDashboard,
                children: [
                    { path: '/member/dashboard', name: 'member-dashboard', component: MemberDashboardPage, meta: { isLogged: true } },
                    //service=======
                    { path: '/member/invioce/:id', name: 'memberInvoice', component: MemberInvoce },
                    { path: '/member/money-receipt/:id', name: 'moneyReceipt', component: MoneyReceipt },
                    { path: '/member/payment-method/:id/:amount', name: 'paymentMethod', component: PaymentMethod },
                    { path: '/member/payment-category/:id', name: 'paymentOption', component: PaymentOption },

                    { path: '/member/account-company/:id', name: 'account-company', component: AccountOfCompany },
                    { path: '/member/account-individual', name: 'account-individual', component: AccountOfIndividual },
                    { path: '/member/rapst/:id', name: 'rapst', component: Rapst },
                    { path: '/member/account-scb-company/:id', name: 'account-scb-company', component: ScbAccountOfCompany },
                    { path: '/member/account-scb-individual', name: 'account-scb-individual', component: ScbAccountOfIndividual },
                    { path: '/member/account-primebank-company/:id', name: 'account-primebank-company', component: PrimebankAccountOfCompany },
                    { path: '/member/account-primebank-individual', name: 'account-primebank-individual', component: PrimebankAccountOfIndividual },
                    { path: '/member/virtual-card-DBBL/:id', name: 'virtual-card-DBBL', component: VirtualCardDBBL },
                    { path: '/member/credit-card-audit-service/:id', name: 'credit-card-audit-service', component: CreditCardAuditServiceForm },
                    { path: '/member/brac-bank-visa-credit-card/:id', name: 'brac-bank-visa-credit-card', component: BracCreditCardForm },
                    { path: '/member/nrb-bank-visa-credit-card/:id', name: 'nrb-bank-visa-credit-card', component: NrbCreditCardForm },
                    { path: '/member/mtb-bank-visa-credit-card/:id', name: 'mtb-bank-visa-credit-card', component: MtbCreditCardForm },
                    { path: '/member/ebl-mastarcard-credit-card/:id', name: 'ebl-mastarcard-credit-card', component: EblCreditCardForm },
                    { path: '/member/ucb-visa-credit-card/:id', name: 'ucb-visa-credit-card', component: UcbCreditCardForm },
                    { path: '/member/ebl-prepaid-card/:id', name: 'ebl-prepaid-card', component: EblPrepaidCardForm },
                    { path: '/member/cash-incentive/:id', name: 'cash-incentive', component: CashIncentiveForm },
                    { path: '/member/privilege-card/:id', name: 'privilege-card', component: PrivilegeCardForm },
                    { path: '/member/rental-facilities/:id', name: 'rental-facilities', component: RentalFacilitiesForm },
                    { path: '/member/training-form/:id', name: 'training-form', component: TrainingForm },
                    { path: '/member/event-form/:id', name: 'event-form', component: EventForm },
                    { path: '/member/service-form/:id', name: 'service-form', component: ServiceForm },
                    { path: '/member/default-event-form/:id', name: 'default-event-form', component: DefaultEventForm },
                    { path: '/member/changeRequest/create', name: 'changeRequest', component: ChangeRequestForm },
                    { path: '/member/JoinCommitteeForum/create', name: 'JoinCommitteeForum', component: JoinCommitteeForum },
                    { path: '/member/voter-info/create', name: 'voterInfo', component: VoterInfo },

                    { path: '/member/my-service', name: 'myService', component: MyService },
                    { path: '/member/change-history', name: 'changeHistory', component: ChangeHistory }, { path: '/member/pending-change-request', name: 'pendingChangeRequest', component: ChangeRequestHistoryPending },
                    { path: '/member/all-notice', name: 'allNotice', component: AllNotice },
                    { path: '/member/all-notice-resource', name: 'allNoticeResource', component: AllNoticeResource },
                    { path: '/member/create-existing-service', name: 'CreateExistingService', component: CreateExistingService },

                    // member form=======
                    { path: '/member/change-password', name: 'changePassword', component: ChangePassword },
                    { path: '/member/settings', name: 'memberSettings', component: MemberSettings },
                    { path: '/member/edit-company-info', name: 'EditCompanyInfo', component: CompanyInfo },
                    { path: '/member/representative-info', name: 'RepresentativeInformation', component: RepresentativeForm },
                    { path: '/member/business-activity-info', name: 'BusinessActivityInformation', component: BusinessActivityForm },
                    { path: '/member/vertical-experience-info', name: 'VerticalExperienceInformation', component: VerticalExperienceForm },
                    { path: '/member/compliance-information', name: 'ComplianceInfo', component: ComplianceForm },
                    { path: '/member/products-services', name: 'ProductsServices', component: ProductsServicesForm },
                    // { path: '/member/human-resource', name: 'HumanResource', component: HumanResourceForm },
                    { path: '/member/upload-photos-files', name: 'uploadPhotosFiles', component: UploadPhotosFiles },

                    { path: '/member/payment-history', name: 'PaymentHistory', component: PaymentHistory },

                    { path: '/member/online-helpdesk', name: 'onlineHelpdesk', component: onlineHelpdesk },
                    { path: '/member/create-ticket', name: 'createTicket', component: createTicket },
                    { path: '/member/view-ticket/:tID', name: 'viewTicket', component: viewTicket },

                    { path: '/member/view-notification/:nID', name: 'viewNotification', component: viewNotification },
                    { path: '/member/view-resource/:nID', name: 'viewResource', component: viewResource },

                    { path: '/member/certificate-list', name: 'certificateList', component: CertificateList },
                    { path: '/member/certificate-eCourier/:id', name: 'certify.eCourier', component: CertificateEcourier },
                ]
            },


            // E-COMMERCE PAGES START
            {
                path: '/digitalshop',
                name: 'ecommerce',
                component: EcommerceLayout,
                children: [
                    { path: '/digitalshop', name: 'ecommerce.home', component: EcommerceHome },
                    { path: '/digitalshop/category/:slug', name: 'ecommerce.all-subcategory', component: EcommerceSubcategory },
                    { path: '/digitalshop/product/:slug', name: 'ecommerce.products', component: ProductPage },
                    { path: '/digitalshop/products-details/:slug', name: 'ecommerce.products.details', component: ProductsDetails },
                    { path: '/digitalshop/login', name: 'ecommerce.login', component: EcommerceLogin },
                    { path: '/digitalshop/member-login', name: 'ecommerce.memberLogin', component: EcommerceMemberLogin },
                    { path: '/digitalshop/registration', name: 'ecommerce.registration', component: EcommerceRegistration },
                    { path: '/digitalshop/add-to-cart', name: 'ecommerce.addtocart', component: AddToCart },
                    { path: '/digitalshop/checkout', name: 'ecommerce.checkout', component: Checkout },
                    { path: '/digitalshop/order-invoice/:id', name: 'ecommerce.OrderInvoice', component: OrderInvoice },
                    { path: '/digitalshop/payment-method/:id', name: 'ecommerce.paymentmethod', component: EcomPaymentMethod },
                    {
                        path: '/digitalshop/dashboard', component: EcommerceDashboardLayout,
                        children: [
                            { path: '/digitalshop/dashboard', name: 'ecommerce.dashboard', component: EcommerceDashboard },
                            { path: '/digitalshop/profile', name: 'ecommerce.profile', component: EcommerceProfile },
                            { path: '/digitalshop/address-book', name: 'ecommerce.addressbook', component: EcommerceAddressbook },
                            { path: '/digitalshop/myorders', name: 'ecommerce.myorders', component: EcommerceMyOrder },
                            { path: '/digitalshop/cancel-order', name: 'ecommerce.cancelorder', component: EcommerceCancelOrder },
                            { path: '/digitalshop/delivery-address', name: 'ecommerce.deliveryaddress', component: EcommerceDelivertAddress },
                            { path: '/digitalshop/password-change', name: 'ecommerce.password-change', component: EcommercePasswordChange },
                            { path: '/digitalshop/feed-back', name: 'ecommerce.feed-back', component: EcommerceFeedBack },
                            { path: '/digitalshop/help-desk', name: 'ecommerce.help-desk', component: EcommerceHelpDesk },
                            { path: '/digitalshop/whishlist', name: 'ecommerce.wishlist', component: EcommerceWishlist },
                            { path: '/digitalshop/help-desk-list', name: 'ecommerce.help-desklist', component: EcommerceHelpDeskList },
                            { path: '/digitalshop/help-desk-view/:id', name: 'ecommerce.helpDeskView', component: HelpDeskView },
                            { path: '/digitalshop/invoice/:id', name: 'ecommerce.invoice', component: OrderInvoice },
                        ]
                    },
                ]
            }
            // E-COMMERCE PAGES END
        ]
    },
    { path: '/company-profile/:slug', name: 'company-profile', component: CompanyProfile },
    { path: '/approved-reference/:id', name: 'approved-reference', component: ApprovedReferenece },
    { path: '/failed-reference', name: 'failed-reference', component: FailedReferenece },
    { path: '/time-expired', name: 'time-expired', component: TimeExpired },
    ///ssl routes
    { path: '/ssl_success', name: 'reg-member.success', component: sslSuccess },
    { path: '/ssl-success-non-member', name: 'non-member.success', component: sslSuccessNonMember },
    { path: '/ssl-payment-success', name: 'reg-member.sslsuccess', component: sslSuccessBecome },
    { path: '/ssl_cancel', name: 'reg-member.cancel', component: sslCancel },
    { path: '/ssl_fail', name: 'reg-member.fail', component: sslFail },


        // {
        //     path: '*',
        //     // redirect: '/dashboard',
        //     component: () => import('./views/NotFoundComponent')
        // }
    ]
});
import store from "./vuex/store";


const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus


var app = new Vue({
    el: '#app',
    components: { App },
    router,
    store: store,
    data: {
        search_result: {},
        loading: true,
        spinner: false,
        loading_full: true,
        minloadtimefd: 50,
        minloadtimefs: 300,
        debug_status: false,
        debug_data: { message: 'To change debug mood', file_location: 'frontend_router.js', debug_status: 'true|false', setDebugData: 'this.$root.debug_data="your data"' },
        baseurl: laravel.baseUrl,
        variable: [],
        site: {},
        socialLink: {},
        aboutUs: {},
        memberLoginCheck: false,
        memberData: [],
        regMemberLoginCheck: false,
        ecomLogged: false,
        loggedCustomer: {},
        access: {},
        regMemberData: []
    },
    methods: {
        goBack() {
            window.history.length > 1 ?
                this.$router.go(-1) :
                this.$router.push('/')
        },
        site_info() {
            axios
                .get('/all_layout_data/topHead')
                .then(respons => {
                    this.site = respons.data.site_data
                    this.socialLink = respons.data.socialLink
                    this.aboutUs = respons.data.aboutUs
                })
                .catch(error => {
                    // console.log(error)
                })
        },
        getAllSystemVariable() {
            axios
                .get("/variablelist")
                .then(res => this.variable = res.data)
        },
        regMemberLogin() {
            axios.get("/member/loggedOrNot/members-registration")
                .then(res => {
                    if (res.data) {
                        this.regMemberLoginCheck = true;
                        this.getRegMember();
                    }
                })
        },
        getRegMember() {
            axios.get("/reg-member/user").then(res => this.regMemberData = res.data)
        },
        memberLogin() {
            axios.get("/member/loggedOrNot/members").then(response => {
                if (response.data) {
                    this.memberLoginCheck = true;
                    this.getAlternateAccess();
                    this.getMember();
                }
            })
        },
        getMember() {
            axios.get("/member/info").then(res => this.memberData = res.data)
        },
        getAlternateAccess() {
            axios.get("/member/alternate-access").then(res => {
                this.access = res.data.access;
            })
        },
        ecomCustomerLogin() {
            axios.get("/ecommerce/loggedOrNot").then(res => {
                if (res.data) {
                    this.ecomLogged = true;
                    this.getEcomCustomer();
                } else {
                    this.ecomLogged = false;
                }
            })
        },
        getEcomCustomer() {
            axios.get("/ecommerce/info").then(res => this.loggedCustomer = res.data)
        },
    },
    beforeMount() {
        this.site_info();
    },
    mounted() {
        this.getAllSystemVariable();
        this.regMemberLogin();
        this.memberLogin();
        this.ecomCustomerLogin();
    },
    created() {
        // active parent menu
        setTimeout(function () {
            $('.nav-item').children('.nav-link').removeClass('router-link-active')
            $('.nav-item').children('.nav-link').removeClass('parent-active')
            $('.router-link-active').parents('.subMenu').parents('.dropdown-menu').siblings('#navbarDropdown').addClass('parent-active');
        }, 5000);
        this.$store.dispatch("countWishList");
    },
    updated() {
    },
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    // console.log(from);
    // console.log(to);
    app.loading = true
    app.$emit("scrollBeforeEnter");

    // if (!$root.memberLoginCheck) {
    //     if (!to.meta.isLogged) {
    //         next('/member-login')
    //     } else {
    //         next()
    //     }
    // }
    // else {
    //     next()
    // }
    next();

    // active parent menu
    $('.nav-item').children('.nav-link').removeClass('router-link-active')
    $('.nav-item').children('.nav-link').removeClass('parent-active')
    setTimeout(() => {
        $('.router-link-active').parents('.subMenu').parents('.dropdown-menu').siblings('#navbarDropdown').addClass('parent-active');
    }, 100);
    $('#navbarSupportedContent').removeClass('show');
    $('.mMenu').attr('aria-expanded', 'false');
    $('.mMenu').removeClass('show');
})

router.afterEach((to, from, next) => {
    // app.loading = false
    // console.log(router)
    // router.back()
    //setTimeout(() => app.loading = false, 200)
})

global.vm = app;
