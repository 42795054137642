<template>
  <div
    class="member-services"
    v-if="
      (!$parent.loading && $root.access && $root.access['social_links']) ||
      $root.variable.rep_login ||
      $root.variable.aLogId
    "
  >
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">
            Update Information
          </h5>
        </div>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="submit" id="form">
          <div class="row">
            <div class="col-lg-6 mb-3">
              <div class="form-row">
                <label for="comments">Facebook URL</label>
                <input
                  v-if="memberInfo"
                  :class="{
                    'is-invalid': validation.hasError('memberInfo.fb_url'),
                    'is-valid': memberInfo.fb_url,
                  }"
                  type="text"
                  class="form-control shadow-none"
                  placeholder="i.e. https://www.facebook.com/"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'i.e. https://www.facebook.com/'"
                  v-model="memberInfo.fb_url"
                  name="fb_url"
                />
              </div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="form-row">
                <label for="comments">Twitter URL</label>
                <input
                  v-if="memberInfo"
                  :class="{
                    'is-invalid': validation.hasError('memberInfo.tw_url'),
                    'is-valid': memberInfo.tw_url,
                  }"
                  type="text"
                  class="form-control shadow-none"
                  placeholder="i.e. https://twitter.com/"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'i.e. https://twitter.com/'"
                  v-model="memberInfo.tw_url"
                  name="tw_url"
                />
              </div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="form-row">
                <label for="comments">Youtube URL</label>
                <input
                  v-if="memberInfo"
                  :class="{
                    'is-invalid': validation.hasError('memberInfo.yt_url'),
                    'is-valid': memberInfo.yt_url,
                  }"
                  type="text"
                  class="form-control shadow-none"
                  placeholder="i.e. https://www.youtube.com/"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'i.e. https://www.youtube.com/'"
                  v-model="memberInfo.yt_url"
                  name="yt_url"
                />
              </div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="form-row">
                <label for="comments">LinkedIn URL</label>
                <input
                  v-if="memberInfo"
                  :class="{
                    'is-invalid': validation.hasError('memberInfo.le_url'),
                    'is-valid': memberInfo.le_url,
                  }"
                  type="text"
                  class="form-control shadow-none"
                  placeholder="i.e. https://www.linkedin.com/"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'i.e. https://www.linkedin.com/'"
                  v-model="memberInfo.le_url"
                  name="le_url"
                />
              </div>
            </div>

            <div class="col-lg-12 mb-3">
              <div class="form-row">
                <label for="comments">Google Map</label>
                <textarea
                  v-if="memberInfo"
                  name="map_url"
                  placeholder="i.e. Embed a map"
                  v-model="memberInfo.map_url"
                  rows="3"
                  class="form-control"
                ></textarea>
              </div>
            </div>
          </div>

          <br />
          <button class="btn btn-success" type="submit">
            Update Information
          </button>
        </form>
      </div>
    </div>
  </div>
  <div v-else class="row">
    <div class="col-12">
      <div class="card">
        <div v-if="Object.keys($root.access).length > 0" class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! you cannot access this page
          </h4>
        </div>
        <div v-else class="card-body">
          <div
            v-if="!$parent.loading"
            class="
              card-body
              d-flex
              align-items-center
              justify-content-center
              flex-column
            "
          >
            <i
              class="fa fa-spinner fa-spin"
              style="font-size: 24px; color: rgb(0, 127, 62)"
            ></i>
            <span class="processing">processing</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
const breadcumb = [
  {
    title: "Information",
  },
];
export default {
  name: "Settings",
  props: ["memberInfo"],
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Update Information",
      images: { image: null },
      member: {
        info: null,
      },
      errors: {},
    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      var form = document.getElementById("form");
      var formData = new FormData(form);
      this.$validate().then((res) => {
        if (res) {
          this.$parent.loading = true;
          axios
            .post("member/updateSettings", formData, config)
            .then((response) => {
              this.$toastr("success", "Update Successfully", "Success!!");
              this.$parent.loading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  mounted() {
    this.loggedIn();
    setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefd);
  },
  validators: {
    "memberInfo.fb_url": function (value = null) {
      return Validator.value(value).url();
    },
    "memberInfo.tw_url": function (value = null) {
      return Validator.value(value).url();
    },
    "memberInfo.yt_url": function (value = null) {
      return Validator.value(value).url();
    },
    "memberInfo.le_url": function (value = null) {
      return Validator.value(value).url();
    },
  },
};
</script>

