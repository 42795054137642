<template>
  <div class="category-menu">
    <div class="container-wrapper">
      <div class="d-flex align-items-center">
        <div class="dropdown">
          <a
            class="category-dropdown dropdown-toggle"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-list-ul"></i> Categories
          </a>
          <div
            v-if="Object.keys(categories).length > 0"
            class="dropdown-menu p-0 category-dropdown-menu"
            aria-labelledby="dropdownMenuLink"
          >
            <router-link
              class="dropdown-item"
              v-for="(category, key) in categories"
              :key="key"
              :to="{
                name: 'ecommerce.products',
                params: { slug: category.slug },
                query: { slug: category.slug },
              }"
            >
              {{ category.name }}
              <i class="fa fa-angle-right text-muted"></i>
              <ul
                v-if="
                  category.childs && Object.keys(category.childs).length > 0
                "
                class="child-category"
              >
                <li v-for="(child, chIndex) in category.childs" :key="chIndex">
                  <router-link
                    :to="{
                      name: 'ecommerce.products',
                      params: { slug: child.slug },
                      query: {
                        slug: category.slug,
                        type: 'sub-category',
                      },
                    }"
                  >
                    <span>{{ child.name }}</span>
                    <img style="width: 1em" :src="child.image" alt />
                  </router-link>
                </li>
              </ul>
            </router-link>
          </div>
        </div>
        <div class="topcategory d-none d-lg-block">
          <ul>
            <li v-for="(popular, key) in popularCategories" :key="key">
              <router-link
                :to="{
                  name: 'ecommerce.products',
                  params: { slug: popular.slug },
                  query: { slug: popular.slug },
                }"
                >{{ popular.name }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadMenu",
  data() {
    return {
      categories: [],
      popularCategories: [],
    };
  },
  methods: {
    getCategories() {
      axios
        .get("/ecommerce/get-categories")
        .then((res) => (this.categories = res.data));
    },
    getTopCategories() {
      axios
        .get("/ecommerce/get-popular-categories")
        .then((res) => (this.popularCategories = res.data));
    },
  },
  created() {
    this.getCategories();
    this.getTopCategories();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
};
</script>
<style scoped lang="scss">
ul.child-category {
  position: absolute;
  right: -277px;
  background: #fff;
  height: 100%;
  top: 0;
  padding: 11px;
  width: 100%;
  border: 1px solid #4444;
  display: none;
  opacity: 0;
  visibility: hidden;
  box-shadow: 3px 0px 25px 5px #4444;
  border-radius: 4px;
  transition: 0.6s;
  li {
    a {
      width: 100%;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      padding: 10px 6px;
      display: flex;
      justify-content: space-between;
      &:before {
        content: unset !important;
      }
    }
  }
}
.category-dropdown-menu {
  transition: 0.6s;
  a {
    &:hover {
        color: #6dc8be;
      ul.child-category {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.ecommerce .category-menu .topcategory ul li a{
  font-size: 15px !important;
}
</style>
