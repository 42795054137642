<template>
  <div class="member-services" v-if="!$root.spinner">
    <div class="col-12 form-row m-4 justify-content-center">
      <div class="col-3 text-center">
        <button @click="downloadMoneyReceipt()" class="btn btn-info">
          <i class="fa fa-download"></i> Download
        </button>
      </div>
    </div>
    <div
      class="print-layout"
      :style="{ backgroundImage: `url(${$root.baseurl+'/public/images/BASIS-Money-Receipt.jpg'})` }"
      style="background-size: cover; background-repeat: no-repeat"
    >
      <div class="print-content">
        <table class="money-receipt-table" width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td>
              <table width="100%">
                <tr>
                  <td width="30%">
                    <p class="text-md font-weight-bold dash-text">
                      Sl:
                      <span class="dash" v-if="invoice">BASIS-{{invoice.invoice_number}}</span>
                    </p>
                  </td>
                  <td width="30%"></td>
                  <td width="40%">
                    <p class="text-md font-weight-bold dash-text">
                      Date:
                      <span
                        class="dash"
                        v-if="invoice"
                      >{{ invoice.payment_date?invoice.payment_date:invoice.invoice_date | formatDate("MMMM, DD YYYY") }}</span>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <table width="100%">
                <tr>
                  <td width="10%">
                    <p class="text-md font-weight-bold dash-text">Invoice:</p>
                  </td>
                  <td width="30%">
                    <p class="text-md font-weight-bold">
                      <span
                        class="dash"
                      >BASIS-{{invoice.invoice_date | formatDate("DDMMYYYY")}}-{{invoice.invoice_number}}</span>
                    </p>
                  </td>
                  <td width="20%">
                    <p class="text-md font-weight-bold dash-text">Transaction ID:</p>
                  </td>
                  <td width="40%">
                    <p class="text-md font-weight-bold">
                      <span class="dash">{{ invoice.bank_trans_id }}</span>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%">
                <tr>
                  <td width="30%">
                    <p class="text-md font-weight-bold dash-text">Received with thanks from:</p>
                  </td>
                  <td>
                    <p class="text-md font-weight-bold">
                      <span class="dash" v-if="memberInfo">{{memberInfo.company_name}}</span>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%">
                <tr>
                  <td width="20%">
                    <p class="text-md font-weight-bold dash-text">
                      Tk.:
                      <span class="dash">{{invoice.amount|currency}}/-</span>
                    </p>
                  </td>
                  <td width="12%">
                    <p class="text-md font-weight-bold dash-text">In Words</p>
                  </td>
                  <td width="68%">
                    <p class="text-md font-weight-bold">
                      <span
                        class="dash"
                        v-if="total_amount"
                      >{{Math.round(total_amount)|inWords}} Taka Only</span>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%">
                <tr>
                  <!-- <td width="20%">
                    <p class="text-md font-weight-bold dash-text">Transaction Date:</p>
                  </td>
                  <td width="30%">
                    <p class="text-md font-weight-bold">
                      <span class="dash">{{ invoice.payment_date | formatDate("MMM-d-YYYY") }}</span>
                    </p>
                  </td>-->
                  <td width="20%">
                    <p class="text-md font-weight-bold dash-text">Payment Method:</p>
                  </td>
                  <td width="30%">
                    <p class="text-md font-weight-bold text-uppercase">
                      <span class="dash">{{ invoice.payment_method }}</span>
                    </p>
                  </td>

                  <td width="20%">
                    <p class="text-md font-weight-bold dash-text">Payment Type:</p>
                  </td>
                  <td width="30%">
                    <p class="text-md font-weight-bold">
                      <span
                        class="dash"
                        v-if="invoice.payment_method == 'ssl' || invoice.payment_method=='ucbl'"
                      >ONLINE</span>
                      <span class="dash" v-else>MANUAL</span>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%">
                <tr>
                  <td width="18%">
                    <p class="text-md font-weight-bold dash-text">Payment For:</p>
                  </td>
                  <td width="88%">
                    <p class="text-md font-weight-bold">
                      <span class="dash">
                        <span v-if="invoice.account_head">{{invoice.account_head.name}}</span>
                        <slot v-if="invoice.type == 'training' ">
                          <span v-if="invoice.training">{{invoice.training.name}}</span>
                        </slot>
                        <slot v-else-if="invoice.type == 'event' ">
                          <span v-if="invoice.event">{{invoice.event.title}}</span>
                        </slot>
                        <slot v-else>
                          <span v-if="invoice.service">{{invoice.service.title}}</span>
                        </slot>
                      </span>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="10"></td>
          </tr>
          <tr>
            <td>
              <table width="100%">
                <tr>
                  <td>
                    <p class="text-sm font-weight-bold">
                      <i>
                        <b>Disclaimer:-</b> This is a system-generated electronic receipt, hence no physical signature is required for the purpose of authentication.
                      </i>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-xs text-center">Please reserve the money receipt copy</p>
            </td>
          </tr>
          <!-- <tr>
					<td>
						<table width="100%">
							<tr>
								<td width="50%"></td>
								<td width="50%" class="text-center">
									For <b>Bangladesh Association of Software</b>
								</td>
							</tr>
							<tr>
								<td width="50%"></td>
								<td width="50%" class="text-center">
									<b>& Information Services (BASIS)</b>
								</td>
							</tr>
							<tr>
								<td width="50%"></td>
								<td width="50%" class="text-center">
									<img src="./signature.png" alt="" height="30">
								</td>
							</tr>
							<tr>
								<td width="50%"></td>
								<td width="50%" class="text-center">
									<p class="font-weight-bold">Authorized Signature</p>
								</td>
							</tr>
						</table>
					</td>
          </tr>-->
        </table>
      </div>
    </div>
    <!-- <pre>{{invoice}}</pre> -->
  </div>
</template>
<script>
import sign from "../../../../assets/img/sign/HashimAhmed.png";
export default {
  name: "MoneyReceipt",
  props: ["memberInfo"],
  data() {
    return {
      sign: this.$root.baseurl + sign,
      invoiceNo: null,
      invoice: {},
      member: {
        info: null
      },
      total_amount: null
    };
  },
  methods: {
    downloadMoneyReceipt() {
      this.$root.spinner = true;
      window.location.href =
        this.$root.baseurl +
        "/member/generate-money-receipt-pdf/" +
        this.$route.params.id;
      setTimeout(() => {
        this.$root.spinner = false;
      }, 200);
    },
    getAccount: function() {
      this.$root.spinner = true;
      axios
        .get("/member/get-money-receipt/" + this.$route.params.id)
        .then(response => {
          if (Object.keys(response.data).length > 0) {
            this.invoice = response.data;
            this.total_amount = response.data.amount;
          }
        })
        .catch(error => {})
        .then(always => {
          this.$root.spinner = false;
        });
    }
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  created() {
    this.loggedIn();
    this.getAccount();
  }
};
</script>
<style>
body {
  font-family: sans-serif;
  font-size: 14px;
  -webkit-print-color-adjust: exact;
}

.money-receipt-table tr td {
  font-size: 14px;
  font-family: sans-serif;
  font-weight: normal;
  overflow: hidden;
  padding: 4px;
  color: #333;
  vertical-align: bottom;
  border: 0;
}

p {
  margin: 0;
}

.text-center {
  text-align: center;
}
.text-xs {
  font-size: 12px;
  font-weight: 700;
}
.text-sm {
  font-size: 14px;
  font-weight: 700;
}
.text-md {
  font-size: 16px;
}

.font-weight-bold {
  font-family: sans-serif;
  font-weight: 500;
}

.dash-text {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  font-weight: 100 !important;
}

.dash {
  /* position: absolute; */
  bottom: 0;
  border-bottom: 2px dotted #aaa;
  float: right;
  width: 100%;
  margin: 0 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}

/**print-layout*/

.print-layout {
  width: 768px;
  height: 450px;
  display: block;
  margin: 0 auto;
  border: 1px solid #000;
}
.print-content {
  padding: 0 30px;
  margin-top: 110px;
  page-break-after: always;
}
</style>