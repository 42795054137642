<template>
	<div class="customer-profile">
		<h3 class="my-4">Wishlist</h3>
		<div class="row pt-3">
			<div class="col-md-12">
				<table
					class="table"
					v-if="
						$store.state.wishLists &&
							Object.keys($store.state.wishLists).length > 0
					"
				>
					<tr class="bg-blue-theme">
						<th class="text-center">Image</th>
						<th>Product Name</th>
						<th>Price</th>
						<th>Add on</th>
						<th width="20%"></th>
						<th width="5%"></th>
					</tr>
					<tr v-for="(item, index) in $store.state.wishLists" :key="index">
						<td class="d-flex justify-content-around align-items-center">
							<img style="width: 4em" :src="item.product.image1" alt />
						</td>
						<td>{{ item.product.product_name }}</td>
						<td>৳ {{ item.product.price | currency }}</td>
						<td>{{ item.created_at | formatDate }}</td>
						<td>
							<button @click="productShowModal(item.product)" class="btn btn-sm btn-primary rounded-50">
								<i class="fa fa-shopping-cart"></i> Add to Cart
							</button>
						</td>
						<td>
							<a @click="removeWishlist(item)">
								<span class="btn btn-danger btn-sm">
									<i class="fa fa-trash-alt"></i>
								</span>
							</a>
						</td>
					</tr>
				</table>

				<EmptyCart listName="Wishlist" v-else />
			</div>
		</div>

		<!-- modal productDetails -->
		<b-modal id="productDetails" title="Product Details" size="lg" hide-footer>
			<div class="product-details" v-if="productDetails">
				<div class="row py-5">
					<div class="col-lg-3">
						<div class="preview-image">
							<img class="product-image" :src="productDetails.image1" alt />
						</div>
					</div>
					<div class="col-lg-9">
						<div class="product-details">
							<h3 class="product_name">{{ productDetails.product_name }}</h3>
							<h5 class="product_id">Product id: {{ productDetails.code }}</h5>

							<div class="productid-brand mb-3">
								<span>
									<b>Brand:</b>
									<span v-if="productDetails.brand">
										{{
										productDetails.brand.name
										}}
									</span>
									<span v-else>No Brand</span>
								</span>
							</div>
							<hr />
							<div class="price">
								<h2
									class="mb-3"
									v-if="
										productDetails.discount &&
											productDetails.discount.discount_type == 1
									"
								>
									৳
									{{
									(productDetails.price - productDetails.discount.discount)
									| currency
									}}
								</h2>
								<h2
									class="mb-3"
									v-else-if="
										productDetails.discount &&
											productDetails.discount.discount_type == 2
									"
								>
									৳
									{{
									(productDetails.price -
									(productDetails.discount.discount / 100) *
									productDetails.price)
									| currency
									}}
								</h2>
								<h2 class="mb-3" v-else>৳ {{ productDetails.price | currency }}</h2>

								<h4 class="discout_price" v-if="productDetails.discount">
									<strike style="color:#ff5f65 !important;">৳{{ productDetails.price | currency }}</strike>-
									<span
										v-if="
											productDetails.discount &&
												productDetails.discount.discount_type == 1
										"
									>৳{{ productDetails.discount.discount | currency }}</span>
									<span
										v-else-if="
											productDetails.discount &&
												productDetails.discount.discount_type == 2
										"
									>{{ productDetails.discount.discount }}%</span>
								</h4>
							</div>

							<div
								class="color-falimy d-flex align-items-center"
								v-if="
									productDetails.color &&
										Object.keys(productDetails.color).length > 0
								"
							>
								<b>Color Family:</b>
								<div class="colors" v-for="(color, cindex) in productDetails.color" :key="cindex">
									<b-form-checkbox
										:id="color"
										v-model="data.color_family"
										:value="color"
										:name="color"
									>{{ color }}</b-form-checkbox>
								</div>
							</div>
							<p>
								<small v-if="colorMsg" class="text-danger">
									{{
									colorMsg
									}}
								</small>
							</p>
							<div
								class="product-size d-flex align-items-center"
								v-if="
									productDetails.size &&
										Object.keys(productDetails.size).length > 0
								"
							>
								<b>Size:</b>
								<div class="size" v-for="(size, sindex) in productDetails.size" :key="sindex">
									<input
										type="radio"
										v-model="data.size"
										:value="size"
										class="d-none"
										:id="size"
										name="size"
									/>
									<label class="size-box1" :class="data.size === size ? 'active' : ''" :for="size">{{ size }}</label>
								</div>
							</div>
							<p>
								<small v-if="sizeMsg" class="text-danger">{{ sizeMsg }}</small>
							</p>

							<div class="quantity d-flex align-items-center">
								<span>
									<b>Quantity:</b>
								</span>
								<div class="input-group ml-3">
									<div class="input-group-prepend">
										<span @click="decrement()" class="input-group-text">
											<i class="fa fa-minus"></i>
										</span>
									</div>
									<input
										type="number"
										min="1"
										class="form-control"
										name="quantity"
										v-model="data.quantity"
										aria-label="Amount (to the nearest dollar)"
									/>
									<div class="input-group-append">
										<span @click="increment()" class="input-group-text">
											<i class="fa fa-plus"></i>
										</span>
									</div>
								</div>
							</div>
							<h4 v-if="!inStock">
								<span class="badge badge-danger px-5 py-3">Stock out</span>
							</h4>
							<div class="buttons my-5">
								<button
									:class="!inStock ? 'disabled' : ''"
									@click.prevent="buyNow(productDetails,stock)"
									class="btn btn-buynow"
								>
									<i class="fa fa-shopping-bag"></i>Buy Now
								</button>

								<button
									:class="!inStock ? 'disabled' : ''"
									@click="addToCart(productDetails,stock)"
									class="btn-add-to-cart"
								>
									<i class="fa fa-shopping-cart"></i> Add to Cart
								</button>
								<!-- <button
									:class="!inStock ? 'disabled' : ''"
									@click="cartUpdate(productDetails,stock)"
									v-if="editCart"
									class="btn-add-to-cart"
								>
									<i class="fa fa-edit"></i> Update Cart
								</button>-->
								<button @click="wishList(productDetails.id)" class="btn-add-to-favourite">
									<i class="fa fa-heart"></i> Add to Favourite
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import EmptyCart from "../elements/EmptyCart";
	import swal from "sweetalert";
	import addToCartMixin from "../../../../addToCartMixin";
	export default {
		name: "Wishlist",
		components: { EmptyCart },
		mixins: [addToCartMixin],
		data() {
			return {
				data: {
					color_family: "",
					quantity: 1,
					size: ""
				},
				colorMsg: "",
				sizeMsg: "",

				productDetails: [],
				editCart: false,
				stock: {},
				inStock: true
			};
		},
		methods: {
			removeWishlist(item) {
				swal({
					title: "Are you sure?",
					text: "You want to delete this ",
					icon: "warning",
					buttons: true,
					dangerMode: true
				}).then(willDelete => {
					if (willDelete) {
						swal("Poof! Your Product has been deleted!", {
							icon: "success"
						});
						let data = {
							product_id: item.product_id,
							customer_id: item.customer_id
						};
						axios
							.post("ecommerce/remove-wishlist", data)
							.then(res => {
								if (res.status === 200) {
									this.$toastr("success", res.data.message, "Success!!");
									this.$store.dispatch("countWishList");
								}
							})
							.catch(error => console.log(error));
					} else {
						swal("Your Product is safe!");
					}
				});
			},

			productShowModal(product) {
				let slug = product.slug;
				if (slug) {
					axios.get(`ecommerce/product-details/${slug}`).then(res => {
						this.productDetails = res.data.product;
						this.color_size_set(slug);
						this.$bvModal.show("productDetails");
					});
				}
			},
			color_size_set(slug) {
				let product = this.$store.state.cart.find(e => e.slug == slug);
				if (product) {
					this.data.size = product.size_select;
					this.data.color_family = product.color_select;
					if (product.qty) {
						this.data.quantity = product.qty;
					}
					this.editCart = true;
				}
			}
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		},
		watch: {
			"data.size": function(newValue, oldValue) {
				let data = {
					size: newValue,
					color: this.data.color_family,
					product_id: this.productDetails.id
				};
				axios.post("ecommerce/check-stock", data).then(res => {
					this.stock = res.data;
					if (Object.keys(res.data).length > 0) {
						this.inStock = true;
					} else {
						this.inStock = false;
					}
				});
			},
			"data.color_family": function(newValue, oldValue) {
				let data = {
					size: this.data.size,
					color: newValue,
					product_id: this.productDetails.id
				};
				axios.post("ecommerce/check-stock", data).then(res => {
					this.stock = res.data;
					if (Object.keys(res.data).length > 0) {
						this.inStock = true;
					} else {
						this.inStock = false;
					}
				});
			}
		}
	};
</script>

<style scoped>
	.product a.add-to-cart-button {
		cursor: pointer;
	}
	.product-details h3.product_name {
		font-size: 32px;
		margin: 0px;
		text-align: left;
		padding-bottom: 10px;
		font-weight: 600;
		text-transform: uppercase;
		color: #333;
	}
	.product-details h5.product_id {
		margin: 0;
		text-decoration: none;
		color: #666;
		font-size: 12px;
		margin-bottom: 4px;
	}
	.product-details .price h2 {
		font-size: 24px;
		color: #000;
		font-weight: bold;
	}
	.product-details h4.discout_price {
		font-size: 20px;
		color: #666;
		font-weight: bold;
	}
	.product-details .star-review {
		margin: 0;
		text-decoration: none;
		color: #666;
		font-size: 12px;
	}
	.product-details .colors {
		position: relative;
		top: -8px;
		margin: 5px 17px;
		color: #666;
	}
	.product-details .colors label {
		height: 20px;
		width: 20px;
		border-radius: 3px;
		position: relative;
		top: 6px;
	}
	.product-details .colors label.active:after {
		content: "";
		font-family: "Font Awesome 5 Free";
		position: absolute;
		left: 4px;
		top: 2px;
		color: white;
		font-size: 12px;
	}
	.product-details .size {
		margin: 20px 0px;
	}
	.product-details .size label {
		border: 1px solid #6abfaa;
		text-align: center;
		padding: 1px 5px;
		border-radius: 4px;
		font-size: 15px;
		color: #666;
		position: relative;
		margin-left: 7px;
		min-width: 30px;
		min-height: 20px;
	}
	.product-details .size label.active {
		background: #2b8686;
		color: #fff;
	}
	.product-details .buttons button,
	.product-details .buttons a {
		font-size: 16px;
		text-shadow: none;
		padding: 9px 20px;
		float: left;
		margin-top: 0px;
		font-weight: bold;
		transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s,
			background-position 300ms ease-in-out 0s;
		margin-left: 12px;
		border: none;
		height: 40px;
		text-transform: uppercase;
		border-radius: 0px;
	}
	.product-details .buttons button i,
	.product-details .buttons a i {
		position: relative;
		left: -10px;
		transition: 0.6s;
	}
	.product-details .buttons button:hover i,
	.product-details .buttons a:hover i {
		left: 3px;
	}
	.product-details .buttons .btn-buynow {
		background: #1fc0a0;
		color: #fff;
	}
	.product-details .buttons .btn-add-to-cart {
		background: #ff7200;
		color: white;
	}
	.product-details .buttons .btn-add-to-favourite {
		background: #b23cfd;
		color: white;
	}

	.quantity .input-group {
		width: 20%;
	}
	.quantity .input-group input {
		border: 1px solid #d5d5d5;
		background: #f4f4f45e;
		text-align: center;
	}
</style>
