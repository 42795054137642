<template>
  <!--mission & vision-->
  <div class="missionVission">
    <div class="container-wrapper">
      <div class="content-header text-center">
        <h1 class="title">Mission & Vision</h1>
      </div>
      <div class="row py-4">
        <div class="col-lg-6" data-aos="fade-right" data-aos-offset="100" data-aos-duration="1000">
          <div class="box border-right-green">
            <div class="box-body" v-if="data">
              <h3 v-if="data.mission">{{data.mission.title}}</h3>
              <p v-if="data.mission">{{ getPostBody(data.mission.description,200) }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left" data-aos-offset="100" data-aos-duration="1500">
          <div class="box">
            <div class="box-body" v-if="data">
              <h3 v-if="data.vision">{{data.vision.title}}</h3>
              <p v-if="data.vision">{{ getPostBody(data.vision.description,200) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--//mission & vision-->
</template>
<script>
export default {
  data() {
    return {
      data: {}
    };
  },
  methods: {
    getMission() {
      axios.get("/get-mission-vision").then(response => {
        this.data = response.data;
      });
    }
  },
  mounted() {
    setTimeout(() => this.getMission(), 50);
  }
};
</script>