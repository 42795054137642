<template>
  <div
    class="member-services"
    v-if="
      (!$parent.loading &&
        member.info &&
        member.info.status == 1 &&
        $root.access &&
        $root.access['online_helpdesk']) ||
      $root.variable.rep_login ||
      $root.variable.aLogId
    "
  >
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">
            Create A New Ticket
          </h5>
          <div class="px-3 mb-0">
            <router-link
              :to="{ name: 'onlineHelpdesk' }"
              class="btn btn-primary btn-sm"
              >Back to list</router-link
            >
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row align-items-center justify-content-end mb-4">
          <div
            v-if="Object.keys(errors).length > 0"
            class="col-lg-12 py-2"
            style="background: #fddede"
          >
            <div class="error">
              <span
                v-for="(err, errIndex) in errors"
                :key="errIndex"
                class="text-danger"
              >
                {{ err[0] }}
                <br />
              </span>
            </div>
          </div>
        </div>

        <form v-on:submit.prevent="submit" id="form">
          <div class="row justify-content-center">
            <div class="col-lg-8 mb-3">
              <div class="form-row mb-3">
                <label class="col-2" for="comments">
                  Ticket ID
                  <span class="text-danger">*</span>
                </label>
                <div class="col-9">
                  <input
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError('data.ticket'),
                      'is-valid': data.ticket,
                    }"
                    placeholder="Ticket ID"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Ticket ID'"
                    v-model="data.ticket"
                    name="ticket"
                    required
                    readonly
                  />
                  <small v-if="errors && errors.ticket" class="text-danger">{{
                    errors.ticket[0]
                  }}</small>
                </div>
              </div>
              <div class="form-row mb-3">
                <label class="col-2" for="comments">
                  Topics
                  <span class="text-danger">*</span>
                </label>
                <div class="col-9">
                  <select
                    name="topics"
                    v-model="data.topics"
                    :class="{
                      'is-invalid': validation.hasError('data.topics'),
                      'is-valid': data.topics,
                    }"
                    class="form-control"
                  >
                    <option selected :value="null">Choose one...</option>
                    <option
                      v-for="(topic, index) in $root.variable.topics"
                      :key="index"
                      :value="topic"
                    >
                      {{ topic }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.topics") }}
                  </div>
                  <small v-if="errors && errors.topics" class="text-danger">{{
                    errors.topics[0]
                  }}</small>
                </div>
              </div>
              <div class="form-row mb-3">
                <label class="col-2" for="comments">
                  Subject
                  <span class="text-danger">*</span>
                </label>
                <div class="col-9">
                  <input
                    type="text"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError('data.subject'),
                      'is-valid': data.subject,
                    }"
                    placeholder="Subject"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Subject'"
                    v-model="data.subject"
                    name="subject"
                    required
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.subject") }}
                  </div>
                  <small v-if="errors && errors.subject" class="text-danger">{{
                    errors.subject[0]
                  }}</small>
                </div>
              </div>
              <div class="form-row mb-3">
                <label class="col-2" for="comments">
                  Message
                  <span class="text-danger">*</span>
                </label>
                <div class="col-9">
                  <textarea
                    required
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Write your message here'"
                    class="form-control shadow-none"
                    placeholder="Write your message here"
                    rows="10"
                    v-model="data.message"
                    name="message"
                    :class="{
                      'is-invalid': validation.hasError('data.message'),
                      'is-valid': data.message,
                    }"
                  ></textarea>
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.message") }}
                  </div>
                  <small v-if="errors && errors.message" class="text-danger">{{
                    errors.message[0]
                  }}</small>
                </div>
              </div>
              <div class="form-row mb-3">
                <label class="col-2" for="comments">File</label>
                <div class="col-9">
                  <b-form-file
                    v-model="data.file"
                    size="sm"
                    placeholder="Choose pdf file, image or drop it here..."
                    :class="{
                      'is-invalid': validation.hasError('data.file'),
                      'is-valid': data.file,
                    }"
                    drop-placeholder="Drop pdf file here..."
                    accept="application/pdf, image/*"
                  ></b-form-file>
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.file") }}
                  </div>
                </div>
              </div>

              <div class="text-center">
                <button class="btn btn-sm btn-success" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-else-if="member.info && member.info.status == 0" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! your account is suspend
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="member.info && member.info.status == 2" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! your account is cancel
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="row">
    <div class="col-12">
      <div class="card">
        <div v-if="Object.keys($root.access).length > 0" class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! you cannot access this page
          </h4>
        </div>
        <div v-else class="card-body">
          <div
            v-if="!$parent.loading"
            class="card-body d-flex align-items-center justify-content-center flex-column"
          >
            <i
              class="fa fa-spinner fa-spin"
              style="font-size: 24px; color: rgb(0, 127, 62)"
            ></i>
            <span class="processing">processing</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
const breadcumb = [
  {
    title: "Create Ticket",
  },
];
export default {
  name: "CreateTicket",
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Create Ticket",
      data: { ticket: null, file: null, topics: null },
      member: {
        info: {},
      },
      errors: {},
    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      var form = document.getElementById("form");
      var formData = new FormData(form);
      if (this.data.file) {
        formData.append("file", this.data.file);
      } else {
        formData.append("file", "");
      }
      this.$validate().then((res) => {
        if (this.validation.countErrors() > 0) {
          this.$toastr(
            "warning",
            'You need to fill <span class="font-weight-bold">' +
              this.validation.countErrors() +
              " more empty</span> mandatory fields",
            "Empty Mandatory Fields!!"
          );
        } else {
          this.$parent.loading = true;
          axios
            .post("member/ticketStore", formData, config)
            .then((response) => {
              this.errors = {};
              this.$toastr(
                "success",
                "Ticket Create Successfully",
                "Success!!"
              );
              this.$router.push({ name: "onlineHelpdesk" });
            })
            .catch((error) => {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors || {};
                this.scrollTop();
              }
            })
            .then((always) => {
              setTimeout(
                () => (this.$parent.loading = false),
                this.$root.minloadtimefd
              );
            });
        }
      });
    },
    getTicket() {
      axios
        .get("member/getTicketNumber")
        .then((response) => {
          this.data.ticket = response.data;
        })
        .catch((error) => {})
        .then((always) => {
          setTimeout(
            () => (this.$parent.loading = false),
            this.$root.minloadtimefd
          );
        });
    },
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  mounted() {
    this.loggedIn();
    this.getTicket();
  },
  validators: {
    "data.topics": function (value = null) {
      return Validator.value(value).required("Topics is required");
    },
    "data.subject": function (value = null) {
      return Validator.value(value).required("Subject is required");
    },
    "data.message": function (value = null) {
      return Validator.value(value).required("Message is required");
    },
    "data.file": function (value = null) {
      // return Validator.value(value);
    },
  },
};
</script>

