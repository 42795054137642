<template>
  <div class="about">
    <PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row">
          <slot v-if="recent_ceremony.image">
            <div class="col-xs-12 col-lg-5">
              <img v-lazy="$root.baseurl+'/public'+recent_ceremony.image" class="img-fluid" alt="...">
            </div>
            <div class="col-xs-12 col-lg-7" data-aos="fade-right">
              <!--Heading-->
              <h5 class="font-weight-bold text-uppercase">{{recent_ceremony.title}}</h5>
              <!--Text-->
              <p class="text-justify" v-html='recent_ceremony.description'>
              </p>
            </div>
          </slot>
          <slot v-else>
            <div class="col-xs-12 col-lg-12" data-aos="fade-right">
              <h5 class="font-weight-bold text-uppercase">{{recent_ceremony.title}}</h5>
              <p class="text-justify" v-html='recent_ceremony.description'>
              </p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import PageBanner from "../../components/elements/PageBanner";
import axios from 'axios'
const breadcumb = [
  {
    title: 'recent_ceremony'
  }
]
export default {
  name: 'About',
  components: {
    PageBanner
  },
  data () {
    return {
      recent_ceremony: {},
      breadcrumbTitle: '',
      breadcumb: [],
    }
  },
  methods: {
    get_data () {
      axios
        .get('/recent_ceremony_detail/' + this.$route.params.id)
        .then(respons => {
          this.recent_ceremony = respons.data.result
          this.breadcrumbTitle=respons.data.result.title
          this.breadcumb= [{ title: this.breadcrumbTitle}]
        })
        .catch(error => {
          console.log(error)
        })
        .then(res =>{
            setTimeout(() => this.$root.loading = false, this.$root.minloadtimefd)
        })
    }
  },
  mounted () {
    this.get_data()
  }
}
</script>
