<template>
	<div class="ecommerceHome">
		<!-- header menu -->
		<HeaderMenu />
		<div class="container-wrapper">
			<CategoryProduct :categories="categories" />
		</div>

		<div class="container-wrapper">
			<div class="footer-banner px-3">
				<div class="row">
					<div class="col-md-12" v-for="(banner,index) in banner" :key="index">
						<img class="img-fluid w-100 first-banner" style="height:auto" v-lazy="banner.image" alt />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CategoryProduct from "./elements/CategoryProduct";
	import HeaderMenu from "./elements/HeaderMenu";

	export default {
		name: "Home",
		components: { CategoryProduct, HeaderMenu },
		data() {
			return {
				categories: [],
				banner: []
			};
		},
		methods: {
			getCategories() {
				axios
					.get("/ecommerce/get-popular-categories-home")
					.then(res => (this.categories = res.data))
					.catch(error => {
						console.log(error);
					});
			},
			getBanner() {
				axios
					.get("/ecommerce/get-banner")
					.then(res => (this.banner = res.data))
					.catch(error => {
						console.log(error);
					});
			}
		},
		created() {
			this.$eventBus.$emit("ecomloading", true);
			this.getCategories();
			this.getBanner();
			setTimeout(() => {
				this.$eventBus.$emit("ecomloading", false);
			}, 1000);
		}
	};
</script>
