<template>
  <div class="row py-3 px-4 mx-0" v-if="$parent.meta">
    <div class="col-sm-12 col-md-5 px-0">
      <div
        role="status"
        aria-live="polite"
      >Showing {{$parent.meta.from}} to {{$parent.meta.to}} of {{$parent.meta.total}} entries</div>
    </div>
    <div class="col-sm-12 col-md-7 p-0">
      <nav aria-label="Page navigation example">
        <ul class="pagination pagination-sm justify-content-end">
          <li class="page-item">
            <a class="page-link" v-on:click="get_datas(1)" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item" v-if="$parent.meta.current_page>5">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page-5)"
            >{{$parent.meta.current_page-5}}</a>
          </li>
          <li class="page-item" v-if="$parent.meta.current_page>4">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page-4)"
            >{{$parent.meta.current_page-4}}</a>
          </li>
          <li class="page-item" v-if="$parent.meta.current_page>3">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page-3)"
            >{{$parent.meta.current_page-3}}</a>
          </li>
          <li class="page-item" v-if="$parent.meta.current_page>2">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page-2)"
            >{{$parent.meta.current_page-2}}</a>
          </li>
          <li class="page-item" v-if="$parent.meta.current_page>1">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page-1)"
            >{{$parent.meta.current_page-1}}</a>
          </li>
          <li class="page-item active">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page)"
            >{{$parent.meta.current_page}}</a>
          </li>
          <li class="page-item" v-if="$parent.meta.current_page<$parent.meta.last_page">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page+1)"
            >{{$parent.meta.current_page+1}}</a>
          </li>
          <li class="page-item" v-if="$parent.meta.current_page+1<$parent.meta.last_page">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page+2)"
            >{{$parent.meta.current_page+2}}</a>
          </li>
          <li class="page-item" v-if="$parent.meta.current_page+2<$parent.meta.last_page">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page+3)"
            >{{$parent.meta.current_page+3}}</a>
          </li>
          <li class="page-item" v-if="$parent.meta.current_page+3<$parent.meta.last_page">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page+4)"
            >{{$parent.meta.current_page+4}}</a>
          </li>
          <li class="page-item" v-if="$parent.meta.current_page+4<$parent.meta.last_page">
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page+5)"
            >{{$parent.meta.current_page+5}}</a>
          </li>
          <li
            class="page-item"
            v-if="$parent.meta.current_page+5<$parent.meta.last_page && $parent.meta.current_page+6<12"
          >
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page+6)"
            >{{$parent.meta.current_page+6}}</a>
          </li>
          <li
            class="page-item"
            v-if="$parent.meta.current_page+6<$parent.meta.last_page &&$parent.meta.current_page+7<12"
          >
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page+7)"
            >{{$parent.meta.current_page+7}}</a>
          </li>
          <li
            class="page-item"
            v-if="$parent.meta.current_page+7<$parent.meta.last_page &&$parent.meta.current_page+8<12"
          >
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page+8)"
            >{{$parent.meta.current_page+8}}</a>
          </li>
          <li
            class="page-item"
            v-if="$parent.meta.current_page+8<$parent.meta.last_page && $parent.meta.current_page+9<12"
          >
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page+9)"
            >{{$parent.meta.current_page+9}}</a>
          </li>
          <li
            class="page-item"
            v-if="$parent.meta.current_page+9<$parent.meta.last_page && $parent.meta.current_page+10<12"
          >
            <a
              class="page-link"
              v-on:click="get_datas($parent.meta.current_page+10)"
            >{{$parent.meta.current_page+10}}</a>
          </li>
          <li class="page-item">
            <a class="page-link" v-on:click="get_datas($parent.meta.last_page)" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["url", "search_data", "scrollNone"],
  methods: {
    get_datas: function(pageindex) {
      this.$root.spinner = true;
      let url = this.url;
      if (pageindex > 0) {
        url = this.url + "?page=" + pageindex;
      }
      axios.get(url, { params: this.search_data }).then(respons => {
        if (!this.scrollNone) {
          this.scrollTopPagination();
        }
        this.$parent.table.datas = respons.data.data;
        this.$parent.meta = respons.data.meta;
        this.$parent.links = respons.data.links;
        this.$root.spinner = false;
      });
    }
  }
};
</script>
