var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formValidation py-5"},[_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"card shadow-sm"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('form',[_c('div',{staticClass:"form-row"},[_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('div',{staticClass:"col-md-4 align-self-center mb-2"},[_c('info',[_c('i',{staticClass:"fas fa-info-circle"}),_vm._v(" "),_c('span',[_c('i',{staticClass:"fas fa-info pr-2"}),_vm._v("Hover Me\n                ")])])],1)]),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_vm._m(7),_vm._v(" "),_vm._m(8),_vm._v(" "),_vm._m(9),_vm._v(" "),_vm._m(10),_vm._v(" "),_vm._m(11),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Submit form")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"title m-0"},[_vm._v("Form Validation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 mb-3"},[_c('label',{attrs:{"for":"validationServer01"}},[_vm._v("First name")]),_vm._v(" "),_c('input',{staticClass:"form-control shadow-none",attrs:{"type":"text","id":"validationServer01","placeholder":"First name","value":"Mark","required":""}}),_vm._v(" "),_c('div',{staticClass:"valid-feedback"},[_vm._v("Looks good!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 mb-3"},[_c('label',{attrs:{"for":"validationServer02"}},[_vm._v("Last name")]),_vm._v(" "),_c('input',{staticClass:"form-control shadow-none is-valid",attrs:{"type":"text","id":"validationServer02","placeholder":"Last name","value":"Otto","required":""}}),_vm._v(" "),_c('div',{staticClass:"valid-feedback"},[_vm._v("Looks good!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_c('label',{attrs:{"for":"validationServer02"}},[_vm._v("\n                Email\n                "),_c('span',{staticClass:"requierd-star"})]),_vm._v(" "),_c('input',{staticClass:"form-control shadow-none is-valid",attrs:{"type":"text","id":"validationServer02","placeholder":"Email","value":"@example.com","required":""}}),_vm._v(" "),_c('div',{staticClass:"valid-feedback"},[_vm._v("Looks good!")])]),_vm._v(" "),_c('div',{staticClass:"col-md-4 mb-3"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_vm._v(" "),_c('input',{staticClass:"form-control shadow-none",attrs:{"type":"password","id":"password","placeholder":"Password","value":"Password","required":""}}),_vm._v(" "),_c('div',{staticClass:"valid-feedback"},[_vm._v("Looks good!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_c('label',{attrs:{"for":"validationServerUsername"}},[_vm._v("Username")]),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"inputGroupPrepend3"}},[_vm._v("@")])]),_vm._v(" "),_c('input',{staticClass:"form-control shadow-none is-invalid",attrs:{"type":"text","id":"validationServerUsername","placeholder":"Username","aria-describedby":"inputGroupPrepend3","required":""}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please choose a username.")])])]),_vm._v(" "),_c('div',{staticClass:"col-md-4 mb-3"},[_c('label',{attrs:{"for":"validationServerUsername"}},[_vm._v("Phone")]),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"inputGroupPrepend3"}},[_vm._v("+88")])]),_vm._v(" "),_c('input',{staticClass:"form-control shadow-none is-invalid",attrs:{"type":"text","id":"validationServerUsername","placeholder":"Phone","aria-describedby":"inputGroupPrepend3","required":""}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please choose a Phone Number.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{attrs:{"for":"validationServer03"}},[_vm._v("City")]),_vm._v(" "),_c('input',{staticClass:"form-control shadow-none is-invalid",attrs:{"type":"text","id":"validationServer03","placeholder":"City","required":""}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please provide a valid city.")])]),_vm._v(" "),_c('div',{staticClass:"col-md-3 mb-3"},[_c('label',{attrs:{"for":"validationServer04"}},[_vm._v("State")]),_vm._v(" "),_c('input',{staticClass:"form-control shadow-none is-invalid",attrs:{"type":"text","id":"validationServer04","placeholder":"State","required":""}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please provide a valid state.")])]),_vm._v(" "),_c('div',{staticClass:"col-md-3 mb-3"},[_c('label',{attrs:{"for":"validationServer05"}},[_vm._v("Zip")]),_vm._v(" "),_c('input',{staticClass:"form-control is-invalid",attrs:{"type":"text","id":"validationServer05","placeholder":"Zip","required":""}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please provide a valid zip.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input is-invalid",attrs:{"type":"checkbox","value":"","id":"invalidCheck3","required":""}}),_vm._v(" "),_c('label',{staticClass:"form-check-label",attrs:{"for":"invalidCheck3"}},[_vm._v("Agree to terms and conditions")]),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("You must agree before submitting.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-file"},[_c('input',{staticClass:"custom-file-input",attrs:{"type":"file","id":"customFile"}}),_vm._v(" "),_c('label',{staticClass:"custom-file-label",attrs:{"for":"customFile"}},[_vm._v("Choose file")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customCheck1"}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customCheck1"}},[_vm._v("Check this custom checkbox")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-radio"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"radio","id":"customRadio1","name":"customRadio"}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customRadio1"}},[_vm._v("Toggle this custom radio")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control shadow-none",attrs:{"list":"browsers","placeholder":"Search with History mode"}}),_vm._v(" "),_c('datalist',{attrs:{"id":"browsers"}},[_c('option',{attrs:{"value":"Internet Explorer"}}),_vm._v(" "),_c('option',{attrs:{"value":"Firefox"}}),_vm._v(" "),_c('option',{attrs:{"value":"Chrome"}}),_vm._v(" "),_c('option',{attrs:{"value":"Opera"}}),_vm._v(" "),_c('option',{attrs:{"value":"Safari"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-switch"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customSwitch1"}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customSwitch1"}},[_vm._v("Toggle this switch element")])])])
}]

export { render, staticRenderFns }