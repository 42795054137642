<template>
	<div class="memberLogin py-5">
		<div class="container-wrapper">
			<div class="row justify-content-md-center">
				<div class="col-lg-8 p-0">
					<div class="card login-body shadow-lg rounded-10">
						<div class="card-body p-0">
							<span class="shape"></span>
							<div class="row">
								<div class="col-lg-4 p-0">
									<div class="loginSidetext h-100">
										<div class="body">
											<img :src="basislogo" class="basisLogo" alt />
										</div>
									</div>
								</div>
								<div class="col-lg-8">
									<div class="loginForm py-5 my-5">
										<!-- <div class="titleText text-center">
                      <i class="mdi mdi-account-circle-outline mdi-48px"></i>
                      <h3 class="text-center mb-4">Member Login</h3>
                    </div>-->
										<div class="titleText text-center">
											<slot v-if="!reset_password_request">
												<i class="mdi mdi-account-circle-outline mdi-48px"></i>
												<h3 class="text-center mb-4">Member Login</h3>
											</slot>
											<slot v-else>
												<div class="text-success font-weight-bold">
													Please put your representative email for
												</div>
												<!-- <i class="mdi mdi-account-circle-outline mdi-48px"></i> -->
												<h3 class="text-center mb-4">Reset Password</h3>
											</slot>
										</div>
										<div :class="overlay" class="row" v-if="overlay">
											<span class="shape"></span>
											<div class="w-100 row">
												<a
													v-if="otpsection"
													@click="back()"
													href="javascript:void(0);"
													class="btn btn-primary btn-sm font-weight-bold"
													style="margin-left: 10px"
													>⬅ Back</a
												>
											</div>
											<div
												v-if="otpmethodsection"
												class="
													row
													w-100
													justify-content-center
													align-items-center
												"
											>
												<div class="col-lg-10">
													<fieldset class="border border-dark rounded p-3">
														<table class="table table-borderless">
															<tr v-if="!reset_password_request">
																<td class="text-center text-danger">
																	You need to set a password with OTP
																	verification.
																</td>
															</tr>
															<tr>
																<td class="text-center">
																	Please select your prefered method for
																	verification code.
																</td>
															</tr>
															<tr>
																<td>
																	<div class="form-group col-12">
																		<div
																			class="
																				d-flex
																				align-items-center
																				justify-content-center
																			"
																		>
																			<span class="pr-2">OTP Type</span>
																			<div
																				class="
																					custom-control
																					custom-radio
																					custom-control-inline
																				"
																			>
																				<input
																					type="radio"
																					id="sms"
																					name="sms"
																					value="sms"
																					class="custom-control-input"
																					v-model="otp_method"
																				/>
																				<label
																					class="custom-control-label"
																					for="sms"
																					>SMS</label
																				>
																			</div>
																			<div
																				class="
																					custom-control
																					custom-radio
																					custom-control-inline
																				"
																			>
																				<input
																					type="radio"
																					id="email"
																					name="email"
																					value="email"
																					class="custom-control-input"
																					v-model="otp_method"
																				/>
																				<label
																					class="custom-control-label"
																					for="email"
																					>Email</label
																				>
																			</div>
																		</div>
																	</div>
																</td>
															</tr>
															<tr>
																<td class="text-center">
																	<button
																		class="btn btn-sm btn-success mt-3"
																		type="submit"
																		@click="otp_method_select()"
																	>
																		<span v-if="spinner">
																			<i class="fa fa-spinner fa-spin"></i>
																			processing...
																		</span>
																		<span v-else>Submit</span>
																	</button>
																</td>
															</tr>
														</table>
													</fieldset>
												</div>
											</div>
											<div
												v-if="otpsection"
												class="
													row
													w-100
													justify-content-center
													align-items-center
												"
											>
												<div class="col-lg-9">
													<fieldset class="border rounded p-3">
														<div class="text-success font-weight-bold">
															Please check your
															<span v-if="otp_method == 'sms'">mobile</span>
															<span v-else>email</span>
														</div>
														<table class="table table-borderless">
															<tr>
																<th>Company Name:</th>
																<td>{{ memberinfo.company_name }}</td>
															</tr>
															<tr>
																<th>Email Address:</th>
																<td v-if="email">{{ email }}</td>
															</tr>
															<tr>
																<th>Mobile No:</th>
																<td v-if="phone">{{ phone }}</td>
															</tr>
															<tr>
																<td colspan="2">
																	<div class="row align-items-center m-0">
																		<div class="col-8 p-0">
																			<input
																				v-model="data.otp"
																				type="text"
																				class="form-control form-control-sm"
																				placeholder="OTP"
																				name="otp"
																				v-on:keyup.enter="verify_otp"
																			/>
																		</div>
																		<div class="col-4 p-0">
																			<button
																				type="submit"
																				id="verify-otp"
																				class="
																					btn btn-sm btn-primary
																					text-white
																				"
																				@click="verify_otp"
																			>
																				Verify OTP
																			</button>
																		</div>
																		<div class="col-12 p-0">
																			<a
																				v-if="time == 0"
																				@click="otp_method_select()"
																				href="javascript:void(0);"
																				class="
																					btn-custom
																					text-primary
																					font-weight-bold
																				"
																			>
																				<span v-if="spinner">
																					<i class="fa fa-spinner fa-spin"></i>
																					processing...
																				</span>
																				<span v-else>Resend OTP</span>
																			</a>
																			<span v-else class="text-danger">
																				<b>
																					OTP will expire in:
																					{{ prettyTime | prettify }}
																				</b>
																			</span>
																		</div>
																	</div>
																</td>
															</tr>
														</table>
													</fieldset>
												</div>
											</div>
											<div
												v-if="resetpassword"
												class="row justify-content-center align-items-center"
											>
												<div class="col-lg-12" v-if="!$root.spinner">
													<fieldset class="border border-dark rounded p-3">
														<div class="row">
															<div class="col-lg-12">
																<table class="table table-borderless">
																	<tr>
																		<th width="10%">Company</th>
																		<th width="1%">:</th>
																		<td>{{ memberinfo.company_name }}</td>
																	</tr>
																	<tr>
																		<th width="10%">Email</th>
																		<th width="1">:</th>
																		<td v-if="email">{{ email }}</td>
																	</tr>
																	<tr>
																		<th width="10%">Mobile</th>
																		<th width="1">:</th>
																		<td v-if="phone">{{ phone }}</td>
																	</tr>
																</table>
															</div>
															<div class="col-lg-12">
																<div class="form-group">
																	<label
																		class="
																			col-form-label-sm
																			font-weight-bold
																			mb-0
																		"
																		for="applicant_id"
																		>New Password</label
																	>
																	<input
																		v-model="data.reset_password"
																		type="password"
																		class="form-control form-control-sm"
																		:class="{
																			'is-invalid': validation.hasError(
																				'data.reset_password'
																			),
																			'is-valid': data.reset_password,
																		}"
																		placeholder="e.g. ie: A-Z, a-z,digit(0-9),special character"
																		onfocus="this.placeholder = ''"
																		onblur="this.placeholder = 'e.g. ie: A-Z, a-z,digit(0-9),special character'"
																		name="otp3"
																		required
																	/>
																	<div class="invalid-feedback">
																		{{
																			validation.firstError(
																				"data.reset_password"
																			)
																		}}
																	</div>
																</div>
																<div class="w-100"></div>
																<div class="form-group">
																	<label
																		class="
																			col-form-label-sm
																			font-weight-bold
																			mb-0
																		"
																		for="applicant_id"
																		>Confirm Password</label
																	>
																	<input
																		v-model="data.confirm_password"
																		type="password"
																		v-on:keyup.enter="reset_member_password"
																		class="form-control form-control-sm"
																		:class="{
																			'is-invalid': validation.hasError(
																				'data.confirm_password'
																			),
																			'is-valid': data.confirm_password,
																		}"
																		placeholder="e.g. ie: A-Z, a-z,digit(0-9),special character"
																		onfocus="this.placeholder = ''"
																		onblur="this.placeholder = 'e.g. ie: A-Z, a-z,digit(0-9),special character'"
																		name="otp2"
																		required
																	/>
																	<div class="invalid-feedback">
																		{{
																			validation.firstError(
																				"data.confirm_password"
																			)
																		}}
																	</div>
																</div>
																<div class="w-100"></div>
																<div class="col-md-12 form-group">
																	<button
																		class="
																			btn btn-sm btn-success
																			mt-3
																			float-right
																		"
																		type="submit"
																		@click="reset_member_password"
																	>
																		Submit
																	</button>
																</div>
															</div>
														</div>
													</fieldset>
												</div>
												<spinner v-if="$root.spinner" />
											</div>
										</div>
										<div class="form-row px-3" id="form">
											<div class="col-12" v-if="!$root.spinner">
												<div class="form-group col-12">
													<label
														class="col-form-label-sm font-weight-bold mb-0"
														for="applicant_id"
														>Email</label
													>
													<div class="loginBox">
														<input
															type="text"
															v-model="data.email"
															required
															ref="email_focus"
															v-on:keyup.enter="verify_email"
															placeholder="Email"
															onfocus="this.placeholder = ''"
															onblur="this.placeholder = 'e.g. Email'"
															:class="{
																'is-invalid': validation.hasError('data.email'),
																'is-valid': data.email,
															}"
															class="form-control shadow-none email_input"
														/>
														<span class="mdi mdi-email"></span>
													</div>
													<div class="invalid-feedback">
														{{ validation.firstError("data.email") }}
													</div>
													<small
														v-if="errors && errors.email"
														class="text-danger"
														>{{ errors.email[0] }}</small
													>
												</div>

												<div
													class="form-group col-12"
													v-if="
														data.email && validemail && !reset_password_request
													"
												>
													<label
														class="col-form-label-sm font-weight-bold mb-0"
														for="Password"
														>Password</label
													>
													<input
														type="password"
														v-model="data.password"
														v-on:keyup.enter="submit"
														required
														ref="password_focus"
														placeholder="******"
														onfocus="this.placeholder = ''"
														onblur="this.placeholder = '******'"
														:class="{
															'is-invalid': validation.hasError('data.email'),
															'is-valid': data.email,
														}"
														class="form-control shadow-none"
													/>
													<small
														v-if="errors && errors.password"
														class="text-danger"
														>{{ errors.password[0] }}</small
													>
												</div>

												<div
													class="form-group col-12"
													v-if="!reset_password_request"
												>
													<a
														style="font-weight: 100 !important"
														href="javascript:void(0)"
														@click="change_reset_password"
														class="
															text-success
															btn btn-outline-success
															rounded-50
														"
														>Forgot Password?</a
													>
												</div>

												<div
													class="form-group col-12 text-right"
													v-if="!validemail || reset_password_request"
												>
													<button
														class="btn btn-success mt-3"
														type="button"
														v-on:click="verify_email"
													>
														Next
													</button>
												</div>

												<div
													id="login_button"
													class="form-group col-12"
													v-if="
														data.email && validemail && !reset_password_request
													"
												>
													<button
														v-on:click="submit()"
														ref="login_button"
														class="btn btn-success mt-3 float-right"
														type="submit"
													>
														Login
													</button>
												</div>
											</div>
											<spinner v-if="$root.spinner" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <pre>{{ memberinfo }}</pre> -->
			<!-- <pre>{{ otp }}</pre> -->
		</div>
	</div>
</template>
<style>
	.zindex {
		z-index: 1 !important;
		opacity: 0.9;
	}
</style>
<script>
	import Vue from "vue";
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	const Validator1 = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		name: "MemberLogin",
		data() {
			return {
				spinner: false,
				reset_password_request: false,
				basislogo: this.$root.baseurl + "/public/images/logo.svg",
				resetpassword: null,
				otpmethodsection: true,
				otpsection: null,
				overlay: null,
				validemail: null,
				minutes: 0,
				secondes: 0,
				time: 180,
				otp: 0,
				data: {
					id: null,
					email: null,
					password: null,
					otp: null,
					reset_password: null,
					confirm_password: null,
				},
				memberinfo: [],
				flashMsg: false,
				responseMsg: false,
				text: "",
				errors: {},
				otp_method: "sms",
				phone: "",
				email: "",
			};
		},
		computed: {
			prettyTime() {
				let time = this.time / 60;
				let minutes = parseInt(time);
				let secondes = Math.round((time - minutes) * 60);
				return minutes + ":" + secondes;
			},
		},
		methods: {
			countDownTimer() {
				if (this.time > 0) {
					setTimeout(() => {
						this.time -= 1;
						this.countDownTimer();
					}, 1000);
				} else {
					this.otp = 0;
				}
			},
			setTime(payload) {
				this.time = payload.minutes * 60 + payload.secondes;
			},

			// submit request
			submit: function (e) {
				this.$root.spinner = true;
				axios
					.post("/member/login/submit", this.data)
					.then((respons) => {
						if (respons.data.code == "202") {
							this.$toastr("success", respons.data.msg, "Success!!");
							window.location.href = this.$root.baseurl + "/member/dashboard";
						} else if (respons.data.code == "401") {
							this.$root.spinner = false;
							this.$toastr("error", respons.data.msg, "Sorry!!");
							this.$nextTick(function () {
								this.$refs.password_focus.focus();
							});
						}
					})
					.catch((error) => {
						if (error) {
							this.$toastr("error", "Invalid email or password", "Sorry!!");
							this.$root.spinner = false;
						}
					});
			},
			change_reset_password: function () {
				this.reset_password_request = true;
			},
			// verify email
			verify_email() {
				var email = $(".email_input").val();
				if (email == "") {
					$(".email_input").css("border", "1px solid #ED1C24");
					$(".email_input").css("background-color", "rgba(237, 28, 36, 0.1)");
					this.$toastr("warning", "Email is required", "Warning!!");
					return false;
				}
				this.$root.spinner = true;
				axios
					.get("/memberemail_verify", {
						params: {
							email: this.data.email,
							reset_password_request: this.reset_password_request,
						},
					})
					.then((response) => {
						if (response.data.code == 202) {
							this.$root.spinner = false;
							if (
								response.data.datas.reset_password == 0 ||
								this.reset_password_request
							) {
								this.overlay = "ottp-body";
								this.memberinfo = response.data.datas;
								this.phone = response.data.phone;
								this.email = response.data.email;
							} else {
								this.validemail = 1;
								$(".email_input").css("border", "1px solid #007F3D");
								$(".email_input").css("background-color", "");
								this.$nextTick(function () {
									this.$refs.password_focus.focus();
								});
							}
						} else if (response.data.code == 201) {
							this.$toastr("error", response.data.msg, "Sorry!!");
							this.$root.spinner = false;
							this.$nextTick(function () {
								this.$refs.email_focus.focus();
							});
						} else {
							this.$toastr("error", "Something went wrong", "Sorry!!");
							this.$root.spinner = false;
							this.$nextTick(function () {
								this.$refs.email_focus.focus();
							});
						}
					});
			},
			otp_method_select() {
				this.spinner = true;
				this.countDownTimer();
				if (this.otp_method == "sms") {
					this.send_sms();
				} else {
					this.send_mail();
				}
			},
			verify_otp() {
				axios.post("otp-check", { otp: this.data.otp }).then((res) => {
					if (res.data) {
						this.otpsection = null;
						this.resetpassword = true;
					} else {
						this.$toastr("warning", "OTP doesn't match", "Sorry!!");
					}
				});
			},
			reset_member_password() {
				if (this.data.reset_password == this.data.confirm_password) {
					this.$validate().then((res) => {
						if (res) {
							this.memberinfo.password = this.data.reset_password;
							this.memberinfo.reset_password = 1;
							this.$root.spinner = true;
							axios
								.post("/reset_member_password", this.memberinfo)
								.then((res) => {
									if (res.data.code == 202) {
										window.location.href =
											this.$root.baseurl + "/member/dashboard";
									} else if (res.data.code == 401) {
										this.$toastr("error", res.data.msg, "Sorry!!");
										this.$root.spinner = false;
									}
								})
								.catch((error) => {
									if (error) {
										this.$toastr("error", "Password reset failed", "Sorry!!");
										this.$root.spinner = false;
									}
								});
						}
					});
				}
			},
			send_sms() {
				axios
					.post("send-otp-sms", {
						email: this.data.email,
					})
					.then((res) => {
						if (res.data.code == 200) {
							this.otpmethodsection = null;
							this.otpsection = true;
							this.$toastr("success", res.data.msg, res.data.type);
						} else {
							this.$toastr("error", res.data.msg, res.data.type);
						}
					})
					.catch((error) => {})
					.then((alw) => setTimeout(() => (this.spinner = false), 300));
			},
			send_mail() {
				axios
					.post("/send-otp-mail", {
						email: this.data.email,
						dear: "Respected Member",
					})
					.then((res) => {
						if (res.data.code == 200 || res.data) {
							this.otpmethodsection = null;
							this.otpsection = true;
							this.$toastr(
								"success",
								"A OTP Passcode has been sent to your mail.Please enter the OTP below to verify your Email Address. If you cannot see the email in your inbox, make sure to check your SPAM/Promotions folder",
								"Email Sent!"
							);
						} else {
							this.$toastr("error", res.data.msg, res.data.type);
						}
					})
					.catch((error) => console.log(error))
					.then((alw) => setTimeout(() => (this.spinner = false), 300));
			},
			back() {
				this.otpsection = false;
				this.otpmethodsection = true;
			},
		},
		created() {
			axios.get("/member/loggedOrNot/members").then((response) => {
				if (response.data) {
					window.location.href = this.$root.baseurl + "/member/dashboard";
				}
			});

			if (this.$route.name == "member-forgotPass") {
				this.reset_password_request = true;
			}
		},
		validators: {
			"data.email": function (value = null) {
				return Validator.value(value).required("Email is required").email();
			},
			"data.reset_password": function (value = null) {
				return Validator1.value(value)
					.required("Password required")
					.regex("(?=.*?[A-Z])", "at least one uppercase letter required")
					.regex("(?=.*?[a-z])", "at least one lowercase letter required")
					.regex("(?=.*?[0-9])", "at least one digit required")
					.regex("(?=.*?[#?!@$%^&*-])", "at least one special charater required")
					.minLength(6);
			},
			"data.confirm_password, data.reset_password": function (
				confirm_password,
				reset_password
			) {
				return Validator1.value(confirm_password)
					.required("Password confirmation required")
					.match(reset_password);
			},
		},
		mounted() {
			setTimeout(() => (this.$root.loading = false), this.$root.minloadtimefd);
			if (this.$route.params.loadBrowser) {
				location.reload();
			}
		},
	};
</script>
