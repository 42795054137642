<template>
  <div class="loader">
    <!-- <img class="loderLogo" :src="loaderImage" alt /> -->
    <!-- <span class="fas fa-spinner fa-spin fa-5x" style="color: #007f3e!important;"></span> -->
    <!--Loding Anime-->
    <div class="d-flex align-items-center justify-content-center flex-column">
      <div class="loading-anime">
        <span class="shape shape1"></span>
        <span class="shape shape2"></span>
        <span class="shape shape3"></span>
      </div>
      <h4 class="loading">Loading</h4>
    </div>
    <!--/Loding Anime-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaderImage: this.$root.baseurl + "/public/images/logo.svg"
    };
  }
};
</script>

<style lang="scss">
.loader {
  background-color: rgba($color: #fff, $alpha: 1);
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: self-start;
  position: fixed;
}

// .header.show .loader {
//   padding-top: 40px;
// }

// .loderLogo {
//   height: 150px;
// }

.loading-anime {
  position: relative;
  padding: 1.5rem 5rem;
  .shape {
    display: block;
    border-top: 20px solid transparent;
    border-left: 30px solid #f4f4f4;
    border-bottom: 20px solid transparent;
    &.shape1 {
      border-left: 30px solid #007f3d;
    }
    &.shape2 {
      border-left: 30px solid #6dc8be;
    }
    &.shape3 {
      position: absolute;
      top: 40px;
      margin-left: 20px;
      border-left: 30px solid #ed1c24;
    }
  }
}
// Animation

/* loading dots */

.loading {
  font-size: 30px;
  line-height: 20px;
}

.loading:after {
  content: ".";
  font-size: 70px;
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: #ed1c24;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #007f3d;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #007f3d, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #007f3d, 0.5em 0 0 #007f3d;
  }
}
</style>

