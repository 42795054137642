<template>
	<div
		v-if="!$parent.loading && member.info && member.info.status == 1"
		class="member-services"
	>
		<div class="col-lg-12">
			<div class="card shadow-sm">
				<div class="card-header">
					<h4 class="text-center">Credit Card's Audit Service Form</h4>
				</div>
				<div class="card-body">
					<div class="alert alert-danger" role="alert" v-if="!showServiceForm">
						<h4 class="alert-heading">Warning!</h4>
						<p>This service is not availabe</p>
						<hr />
					</div>
					<div class="row align-items-center justify-content-end mb-4">
						<div
							v-if="Object.keys(errors).length > 0"
							class="col-lg-12 py-2"
							style="background: #fddede"
						>
							<div class="error">
								<span
									v-for="(err, errIndex) in errors"
									:key="errIndex"
									class="text-danger"
								>
									{{ err[0] }}
									<br />
								</span>
							</div>
						</div>
					</div>
					<form v-on:submit.prevent="submit" id="form" v-if="showServiceForm">
						<div class="row">
							<div class="col-md-6 mb-3">
								<label for="applicant_name">Company Name</label>
								<input
									type="text"
									readonly
									class="form-control shadow-none"
									v-if="member.info"
									v-model="member.info.company_name"
								/>
							</div>
							<div class="col-md-6 mb-3">
								<label for="membership_id">Membership ID</label>
								<div class="input-group">
									<input
										type="text"
										readonly
										class="form-control shadow-none"
										v-if="member.info"
										v-model="member.info.membership_no"
									/>
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="card">
									Select Card
									<span class="requierd-star"></span>
								</label>

								<!-- v-on:click="getCardInfo($event.target.value)" -->
								<select
									v-model="data.card_name"
									class="form-control"
									:class="{
										'is-invalid': validation.hasError('data.card_name'),
										'is-valid': data.card_name,
									}"
									name="card_name"
								>
									<option value>Choose...</option>
									<!-- <slot v-if="existingCard"> -->
									<option
										v-for="(card, id) in $root.variable.credit_card"
										:key="id"
										:value="id"
									>
										{{ card }}
									</option>
									<!-- </slot> -->
								</select>
								<div class="invalid-feedback">
									{{ validation.firstError("data.card_name") }}
								</div>
							</div>
							<div class="col-md-6">
								<label for="comments"
									>Bill/Invoice copy of your previous Transaction</label
								>
								<span class="text-danger requierd-star"></span>
								<b-form-file
									v-model="data.prev_invoice"
									accept=".pdf"
									:placeholder="
										data.prev_invoice ? 'File attached ' : 'Choose a pdf file'
									"
									drop-placeholder="Drop file here..."
								></b-form-file>
								<small>File size must be smaller than 500kb</small>
								<div class="invalid-feedback">
									{{ validation.firstError("data.prev_invoice") }}
								</div>
							</div>
							<div class="w-100"></div>
							<div class="col-md-6 mb-3">
								<label for="cardholder_name">
									Card Holder Name
									<span class="requierd-star"></span>
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="applicant_name"
									name="applicant_name"
									placeholder="e.g. Jane Done"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. Jane Done'"
									:class="{
										'is-invalid': validation.hasError('data.applicant_name'),
										'is-valid': data.applicant_name,
									}"
									v-model="data.applicant_name"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.applicant_name") }}
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="CardHolderNo">
									Card No.
									<span class="requierd-star"></span>
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									placeholder="e.g. 13245xxxxxxx"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 13245xxxxxxx'"
									:class="{
										'is-invalid': validation.hasError('data.card_holder_no'),
										'is-valid': data.card_holder_no,
									}"
									v-model="data.card_holder_no"
									name="card_holder_no"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.card_holder_no") }}
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="cardholder_name">
									Passport No.
									<span class="requierd-star"></span>
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="passport_no"
									placeholder="e.g. 13245xxxxxxx"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 13245xxxxxxx'"
									:class="{
										'is-invalid': validation.hasError('data.passport_no'),
										'is-valid': data.passport_no,
									}"
									v-model="data.passport_no"
									name="passport_no"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.passport_no") }}
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="cardholder_name">
									Place of Issue
									<span class="requierd-star"></span>
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="issue_place"
									placeholder="e.g. Dhaka"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. Dhaka'"
									:class="{
										'is-invalid': validation.hasError('data.issue_place'),
										'is-valid': data.issue_place,
									}"
									v-model="data.issue_place"
									name="issue_place"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.issue_place") }}
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="cardholder_name">
									Passport Issue Date
									<span class="requierd-star"></span>
								</label>
								<Datepicker
									class="form-control shadow-none"
									id="issue_date"
									placeholder="e.g. 01-Jan-2020"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 01-Jan-2020'"
									:class="{
										'is-invalid': validation.hasError('data.issue_date'),
										'is-valid': data.issue_date,
									}"
									v-model="data.issue_date"
									name="issue_date"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.issue_date") }}
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="cardholder_name">
									Passport Expire Date
									<span class="requierd-star"></span>
								</label>
								<Datepicker
									class="form-control shadow-none"
									id="expire_date"
									placeholder="e.g. 01-Jan-2020"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 01-Jan-2020'"
									:class="{
										'is-invalid': validation.hasError('data.expire_date'),
										'is-valid': data.expire_date,
									}"
									v-model="data.expire_date"
									name="expire_date"
								/>
								<div class="invalid-feedback">
									{{ validation.firstError("data.expire_date") }}
								</div>
							</div>
						</div>
						<button
							class="btn btn-success"
							type="submit"
							:disabled="validation.countErrors() > 0"
						>
							Submit
						</button>
					</form>
				</div>

				<!-- <pre>{{ validation.allErrors() }}</pre> -->
			</div>
		</div>
	</div>
	<div v-else-if="member.info && member.info.status == 0" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">
						Sorry!! your account is suspend
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import noImage from "../../../../../assets/img/male_avatar.png";
	import Vue from "vue";
	import SimpleVueValidation from "simple-vue-validator";
	Vue.use(SimpleVueValidation);
	const Validator = SimpleVueValidation.Validator;
	export default {
		name: "CreditCardAuditServiceForm",
		data() {
			return {
				noImage: this.$root.baseurl + noImage,
				showServiceForm: false,
				data: {
					card_name: "",
					applicant_name: "",
					card_holder_no: "",
					passport_no: "",
					expire_date: "",
					issue_date: "",
					issue_place: "",
					prev_invoice: null,
				},
				existingCard: {},
				errors: {},
				member: {
					info: null,
				},
			};
		},
		methods: {
			submit: function (e) {
				e.preventDefault();
				const config = {
					headers: { "content-type": "multipart/form-data" },
				};
				var form = document.getElementById("form");
				var formData = new FormData(form);

				this.$validate().then((res) => {
					if (this.validation.countErrors() > 0) {
						this.$toastr(
							"warning",
							'You need to fill <span class="font-weight-bold">' +
								this.validation.countErrors() +
								" more empty</span> mandatory fields",
							"Empty Mandatory Fields!!"
						);
					} else {
						if (res) {
							this.$parent.loading = true;
							if (this.data.prev_invoice) {
								formData.append("prev_invoice", this.data.prev_invoice);
							}
							formData.append("form_type", this.$route.name);
							formData.append("service_id", this.$route.params.id);
							axios
								.post("/member/store-company-info", formData, config)
								.then((response) => {
									console.log("dsad", response);
									this.$toastr(
										"success",
										"Your information has been submitted, please payment",
										"Success!!"
									);
									if (response.data) {
										localStorage.setItem("recp_id", response.data.recp_id);
										this.$router.push({
											name: "paymentMethod",
											params: {
												id: this.data.service_id,
												amount: response.data.amount,
											},
										});
									}
								})
								.catch((error) => {
									console.log(error);
								})
								.then((alw) => {
									this.$parent.loading = false;
								});
						}
					}
				});
			},
			getExistingCard() {
				axios.get("/member/get-existing-card", this.data).then((response) => {
					this.existingCard = response.data;
				});
			},
			getCardInfo(id) {
				if (!id) {
					return false;
				}
				axios.get("/member/get-card-info/" + id).then((response) => {
					this.data.issue_date = response.data.issue_date;
					this.data.expire_date = response.data.expire_date;
					this.data.issue_place = response.data.issue_place;
					this.data.passport_no = response.data.passport_no;
					this.data.applicant_name = response.data.applicant_name;
					this.data.card_holder_no = response.data.card_holder_no;
				});
			},
			getServiceInfo() {
				axios
					.get("/member/get-serviceinfo/" + this.$route.params.id)
					.then((res) => {
						this.showServiceForm = res.data;
					});
			},
		},
		beforeCreate: function () {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
		created() {
			this.loggedIn();
			this.getExistingCard();
			setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);
			this.data.service_id = this.$route.params.id;
			this.getServiceInfo();
		},
		validators: {
			"member.info.company_name": function (value = null) {
				return Validator.value(value).required("Company name is required");
			},
			"member.info.membership_no": function (value = null) {
				return Validator.value(value).required("Membership ID required");
			},
			"data.card_name": function (value = null) {
				return Validator.value(value).required("Card is required");
			},
			"data.card_holder_no": function (value = null) {
				return Validator.value(value).required("Card holder no. required");
			},
			"data.applicant_name": function (value = null) {
				return Validator.value(value)
					.required("Card holder Name required")
					.minLength(2);
				// .regex("^[a-zA-Z_. ]*$", "Must only contain alphabetic characters.");
			},
			"data.passport_no": function (value = null) {
				return Validator.value(value).required("Passport No.is required");
			},
			"data.issue_place": function (value = null) {
				return Validator.value(value).required("Place of issue is required");
			},
			"data.issue_date": function (value = null) {
				return Validator.value(value).required("Date of issue is required");
			},
			"data.expire_date": function (value = null) {
				return Validator.value(value).required("Date of expiry is required");
			},
			"data.prev_invoice": function (value = null) {
				return Validator.value(value).required("Bill/Invoice copy is required");
			},
		},
	};
</script>

<style >
	.invalid-feedback {
		display: block !important;
	}
</style>
