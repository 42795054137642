<template>
	<div class="ecommerce">
		<EcommerceHeader v-if="$route.name !== 'ecommerce.login'" />
		<div class="body" style="background: #f2f4f8;min-height: 600px;">
			<div class="container-wrapper">
				<Breadcrumb v-if="$route.name !== 'ecommerce.home'" />
			</div>
			<router-view></router-view>
		</div>
        <div class="ecommerce-loader" v-if="ecomLoader">
            <Spinner />
        </div>
        <div class="cart-box">
            <router-link :to="{ name: 'ecommerce.addtocart' }">
            <div class="f-btn mc-toggler" id="cart">
                <img :src="$root.baseurl +'/public/images/icon/basket.png'" alt="">
                <div class="label">Cart</div>
                <span class="counter">{{ $store.state.cartCount }}</span>
            </div>
            </router-link>
        </div>
	</div>
</template>

<script>
	import EcommerceHeader from "./elements/EcommerceHeader";
	import Breadcrumb from "./elements/Breadcumb";
	export default {
		name: "Home",
		components: {Breadcrumb, EcommerceHeader },
        data(){
		    return{
		        ecomLoader:false,
            }
        },
		mounted() {
			this.breadcumb = [{ title: "BASIS E-Commerce" }];
		},
        created() {
            this.$eventBus.$on("ecomloading", playload => {
                this.ecomLoader = playload;
            });
        },
        beforeCreate: function() {
            this.$root.loading = false;
            this.$parent.loading = false;
        }
    };
</script>

<style lang="scss">
.ecommerce-loader {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1234;
    left: 0;
    top: 0;
    background: #fffffff0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-box .f-btn{
    position: fixed;
    bottom: 150px;
    right: 20px;
    z-index: 99999;
    background: #707070;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    cursor: pointer;
    width: 66px;
    height: 62px;
    box-shadow: 0 0 15px rgb(0 0 0 / 10%), -5px 5px 10px rgb(0 0 0 / 10%);
    border-radius: 4px;
}
.cart-box .f-btn span.counter {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -10px;
    top: -10px;
    background: #ed1c24;
    line-height: 20px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.cart-box .f-btn.mc-toggler img {
    height: 40px;
}
</style>
