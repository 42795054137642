<template>
  <affix class="sidebar-menu" relative-element-selector="#example-content">
    <slot v-if="!$root.spinner || !spinner">
      <!--==================== policy / acts/ laws left menu ====================-->
      <!--==================== policy / acts/ laws left menu ====================-->
      <div
        class="leftside-menu"
        v-if="$route.params.type == 'Policies' || 
      $route.params.type == 'Acts' || 
      $route.params.type == 'Laws'"
      >
        <div class="card shadow-sm py-4 mb-3 rounded-10">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Policies, Acts & Laws</h3>
          </div>
          <span class="shape"></span>
          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link
                :to="{ name:'policy', params: { type: 'Policies' }}"
                class="nav-link"
              >Policies</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name:'policy', params: { type: 'Acts' }}" class="nav-link">Acts</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name:'policy', params: { type: 'Laws' }}" class="nav-link">Laws</router-link>
            </li>
          </ul>
        </div>
      </div>
      <!--==================== policy / acts/ laws left menu ====================-->

      <!--==================== event ====================-->
      <!--==================== event ====================-->
      <div
        class="leftside-menu"
        v-else-if="$route.params.market == 'local' || 
      $route.params.market == 'international' || 
      $route.name == 'event.detail'"
      >
        <div class="card shadow-sm py-4 mb-3 rounded-10">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Events & Awards</h3>
          </div>
          <span class="shape"></span>
          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link
                :to="{ name:'allEvents', params: { market: 'local', type: 'events' }}"
                :class="($route.params.market == 'local')?'active':''"
                class="nav-link"
              >Local</router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name:'allEvents', params: { market: 'international', type: 'events' }}"
                class="nav-link"
                :class="($route.params.market == 'international')?'active':''"
              >International</router-link>
            </li>
          </ul>
        </div>
      </div>
      <!--==================== /event ====================-->

      <!--==================== main left side ====================-->
      <!--==================== main left side ====================-->
      <div class="leftside-menu" v-else>
        <div class="card shadow-sm py-4 mb-3 rounded-10">
          <span class="shape"></span>
          <span class="shape2"></span>
          <div class="card-header bg-transparent" v-if="title">
            <h3 class="mb-0">{{title}}</h3>
          </div>
          <ul class="nav flex-column" v-if="leftside">
            <li class="nav-item" v-for="(item, index) in leftside" :key="index">
              <router-link
                v-if="item.type==='content'"
                :to="{ name:'content', params: { slug: item.slug }}"
                class="nav-link"
              >{{item.title}}</router-link>
              <a
                class="nav-link"
                v-else-if="item.type==='outside_website'"
                :href=" item.url "
                target="_blank"
              >{{item.title}}</a>
              <router-link
                class="nav-link"
                v-else-if="item.params !== null && item.type==='external_link'"
                :to="{ name:item.url, params: { type: item.params }}"
              >{{item.title}}</router-link>
              <router-link v-else :to="{ name: item.url }" class="nav-link">{{item.title}}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <!--==================== /main left side ====================-->
    </slot>
    <div v-if="$root.spinner && spinner" class="leftside-menu">
      <div class="card shadow-sm py-4 mb-3 rounded-10">
        <span class="shape"></span>
        <span class="shape2"></span>
        <spinner v-if="$root.spinner" />
      </div>
    </div>
  </affix>
</template>

<script>
import { Affix } from "vue-affix";
export default {
  name: "leftmenu",
  components: {
    Affix
  },
  data() {
    return {
      leftside: "",
      title: "",
      spinner: true
    };
  },
  methods: {
    asyncData() {
      this.get_datas();
    },
    get_datas: function(page_index) {
      this.$root.spinner = true;
      axios
        .get("/left-menu", {
          params: {
            route_name: this.$route.name,
            slug: this.$route.params.slug,
            type: this.$route.params.type
          }
        })
        .then(respons => {
          this.spinner = false;
          this.$root.spinner = false;
          this.leftside = respons.data.parent_menus;
          this.title = respons.data.parent_title;
        })
        .catch(error => {
          console.log(error.respons);
        });
    }
  },
  mounted() {},
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    }
  }
};
</script>
