<template>
  <div class="contact">
    <PageBanner :msg="breadcrumbTitle" :data="breadcumb"></PageBanner>
    <div class="container-wrapper">
      <div class="card card-body discount-details my-5 bg-transparent">
        <span class="shape"></span>
        <div class="col-lg-10 m-auto">
          <div class="row">
            <div class="col-lg-3">
              <img
                class="discount-details"
                :src="$root.baseurl + '/public' + this.datas.company_logo"
                alt
              />
            </div>
            <div class="col-lg-9 mt-4">
              <h2>{{ datas.organization_name }}</h2>
              <p style="font-size: 15px">
                <b>Agreement Date:</b>
                {{ datas.agreement_date }}
              </p>
              <p style="font-size: 15px">
                <b>Expiry Date:</b>
                {{ datas.expiry_date ? datas.expiry_date : datas.comments }}
              </p>
            </div>
            <div class="col-lg-4"></div>
          </div>
        </div>
        <div class="col-lg-11 m-auto">
          <hr
            class="border-0"
            style="padding: 2px; background-color: rgb(139 223 203)"
          />
        </div>
        <div class="row mb-5">
          <div class="col-md-10 m-auto">
            <div class="d-flex justify-content-between page-title mt-4 px-4">
              <div class="DiscountCategory" v-if="datas.discout_category">
                <b style="font-size: 18px">Discount Category:</b>
                <span
                  v-if="datas.discout_category.name"
                  style="font-size: 16px"
                  >{{ datas.discout_category.name }}</span
                >
              </div>
              <div class="DiscountPercentage">
                <b style="font-size: 18px">Discount Percentage:</b>
                <span v-if="datas.discount_percentage" style="font-size: 16px"
                  >{{ datas.discount_percentage }} %</span
                >
              </div>
            </div>

            <div class="col-md-12 p-0 mt-4" v-if="datas.description">
              <h2 class="item-title">Description:</h2>
              <div
                class="discount-service-description"
                v-html="datas.description"
              ></div>
            </div>
            <div class="col-md-12 p-0 mt-4" v-if="datas.service_sector">
              <h2 class="item-title">Services</h2>
              <div class="discount-service-description">
                <ul>
                  <li
                    class="pb-0"
                    v-for="(value, index) in datas.service_sector"
                    :key="index"
                  >
                    <p v-if="value.item">{{ value.item }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>{{ datas }}</pre> -->
  </div>
</template>
<script>
import PageBanner from "../../components/elements/PageBanner";
import axios from "axios";

export default {
  components: {
    PageBanner,
  },
  data() {
    return {
      success: false,
      data: { department: "" },
      breadcrumbTitle: "Discount Details",
      breadcumb: [{ title: "Discount Details" }],
      datas: {},
      discount_category: {},
    };
  },

  methods: {
    get_datas() {
      var id = this.$route.params.id;
      axios
        .get("/get-discount-services/" + id)
        .then((response) => {
          this.datas = response.data.data;
        })
        .catch((error) => console.log(error.respons))
        .then((alw) => setTimeout(() => (this.$root.loading = false), 200));
    },
    get_discountCategory: function () {
      axios
        .get("get-discount-category")
        .then((respons) => {
          this.discount_category = respons.data.data;
        })
        .catch((error) => console.log(error.respons))
        .then((alw) => setTimeout(() => (this.$root.loading = false), 200));
    },
  },
  created() {
    this.get_datas();
    this.get_discountCategory();
  },
};
</script>

<style>
img.discount-details {
  height: 10em;
  object-fit: contain;
}
h2.item-title {
  font-size: 21px;
  background: rgb(153 211 186);
  display: block;
  padding: 2px 5px;
}
.discount-service-description {
  margin-left: 30px;
  font-size: 14px;
}
</style>

