<template>
  <div id="example-content">
    <div class="row" v-if="!$root.spinner">
      <div class="col-lg-3 text-center" v-for="(item, index) in table.datas" :key="index">
        <a :href="$root.baseurl+'/public'+item.image" target="_blank">
          <div class="card-gallery">
            <img v-lazy="$root.baseurl+'/public'+item.image" class="img-fluid" :alt="item.title" />
          </div>
        </a>
        <div class="col-lg-12">{{ getPostBody(item.title,100) }}</div>
      </div>
    </div>
    <spinner v-if="$root.spinner" />
  </div>
</template>
<script>
export default {
  name: "pressKit",
  data() {
    return {
      breadcrumbTitle: "Press Kit",
      breadcumb: [{ title: "Press Kit" }],
      table: {
        datas: []
      }
    };
  },
  methods: {
    get_datas: function(pageindex) {
      this.$root.spinner = true;
      axios
        .get("/get-press-kit")
        .then(respons => {
          this.table.datas = respons.data;
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
          this.$root.spinner = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_datas();
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  }
};
</script>
