<template>
	<div v-if="!$parent.loading && member.info && member.info.status == 1" class="row">
		<div class="col-lg-12">
			<div class="card shadow-sm">
				<div class="card-header bg-transparent">
					<h5 class="text-uppercase font-weight-bold px-3 mb-0">Rental Facilities</h5>
				</div>
				<div class="card-body">
					<div class="alert alert-danger" role="alert" v-if="!showServiceForm">
						<h4 class="alert-heading">Warning!</h4>
						<p>This service is not availabe</p>
						<hr />
					</div>
					<form v-on:submit.prevent="submit" id="form" class="form-row" v-if="showServiceForm">
						<div class="form-group col-lg-4">
							<label class="control-label">
								Company Name
								<span class="text-danger requierd-star"></span>
							</label>
							<input
								type="text"
								v-if="memberInfo"
								v-model="memberInfo.company_name"
								class="form-control"
								readonly
							/>
							<div class="invalid-feedback">{{ validation.firstError("memberInfo.company_name") }}</div>
						</div>
						<div class="col-lg-12"></div>
						<div class="form-group col-lg-6">
							<label class="control-label">
								Address:
								<span class="text-danger requierd-star"></span>
							</label>
							<textarea
								rows="5"
								v-model="data.mailing_address"
								placeholder="Address"
								class="form-control shadow-none"
								:class="{
                  'is-invalid': validation.hasError('data.mailing_address'),
                  'is-valid': data.mailing_address,
                }"
							></textarea>
							<div class="invalid-feedback">{{ validation.firstError("data.mailing_address") }}</div>
						</div>
						<div class="col-lg-12"></div>
						<div class="form-group col-lg-4">
							<label class="control-label">
								BASIS Membership ID:
								<span class="text-danger requierd-star"></span>
							</label>
							<input
								type="text"
								v-if="memberInfo"
								v-model="memberInfo.membership_no"
								readonly
								placeholder="BASIS Membership ID"
								class="form-control shadow-none"
							/>
						</div>
						<div class="form-group col-lg-4">
							<label class="control-label">
								Name of Contact Person:
								<span class="text-danger requierd-star"></span>
							</label>
							<input
								type="text"
								v-model="data.applicant_name"
								placeholder="Name of Contact Person"
								class="form-control shadow-none"
								:class="{
                  'is-invalid': validation.hasError('data.applicant_name'),
                  'is-valid': data.applicant_name,
                }"
							/>
							<div class="invalid-feedback">{{ validation.firstError("data.applicant_name") }}</div>
						</div>
						<div class="form-group col-lg-4">
							<label class="control-label">
								Contact Number:
								<span class="text-danger requierd-star"></span>
							</label>
							<input
								type="text"
								v-model="data.phone"
								placeholder="Contact Number"
								class="form-control shadow-none"
								:class="{
                  'is-invalid': validation.hasError('data.phone'),
                  'is-valid': data.phone,
                }"
							/>
							<div class="invalid-feedback">{{ validation.firstError("data.phone") }}</div>
						</div>
						<div class="form-group col-lg-12">
							<label class="control-label">
								Room/Hall Name:
								<span class="text-danger requierd-star"></span>
							</label>
							<div class="form-check">
								<label class="form-check-label font-weight-normal">
									<input
										type="radio"
										v-model="data.room"
										class="form-check-input"
										value="Board Room"
										:class="{
                      'is-invalid': validation.hasError('data.room'),
                      'is-valid': data.room,
                    }"
									/>Board Room
								</label>
							</div>
							<div class="form-check">
								<label class="form-check-label font-weight-normal">
									<input
										type="radio"
										v-model="data.room"
										class="form-check-input"
										value="Auditorium"
										:class="{
                      'is-invalid': validation.hasError('data.room'),
                      'is-valid': data.room,
                    }"
									/>Auditorium
								</label>
							</div>
							<div class="form-check">
								<label class="form-check-label font-weight-normal">
									<input
										type="radio"
										v-model="data.room"
										class="form-check-input"
										value="Lounge"
										:class="{
                      'is-invalid': validation.hasError('data.room'),
                      'is-valid': data.room,
                    }"
									/>Lounge
								</label>
								<div class="invalid-feedback">{{ validation.firstError("data.room") }}</div>
							</div>
						</div>
						<div class="form-group col-lg-12">
							<label class="control-label">
								Time Slot:
								<span class="text-danger requierd-star"></span>
							</label>
							<div
								class="form-check"
								v-for="(amount, index) in $root.variable.rentalAmount[
                  data.room
                ]"
								:key="index"
							>
								<label class="form-check-label font-weight-normal">
									<input
										type="radio"
										v-model="data.amount"
										class="form-check-input"
										:value="amount"
										:class="{
                      'is-invalid': validation.hasError('data.amount'),
                      'is-valid': data.amount,
                    }"
									/>
									{{ index }} Day-
									<span class="font-weight-bold text-success">BDT {{ amount }}</span>
								</label>
							</div>
							<!-- <div class="form-check">
                <label class="form-check-label font-weight-normal">
                  <input type="radio" v-model="data.amount" class="form-check-input" value="11500" 
                  :class="{'is-invalid':validation.hasError('data.amount'),'is-valid':data.amount}"
                  />Half Day-
                  <span class="font-weight-bold text-success">BDT 11500</span> (4 hours)
                </label>
							</div>-->
							<div class="invalid-feedback">{{ validation.firstError("data.amount") }}</div>
						</div>
						<div class="form-group col-lg-12 text-center">
							<button name type="submit" class="btn btn-primary">Next</button>
						</div>
					</form>
				</div>
			</div>
			<!-- <pre>{{data}}</pre> -->
		</div>
	</div>
	<div v-else-if="member.info && member.info.status == 0" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">Sorry!! your account is suspend</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Vue from "vue";
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);
	export default {
		name: "RentalFacilitiesForm",
		props: ["memberInfo"],
		data() {
			return {
				declaration: false,
				showServiceForm: false,
				data: {},
				errors: {},
				member: {
					info: null
				}
			};
		},
		methods: {
			submit: function() {
				this.data.form_type = this.$route.name;
				this.$validate().then(res => {
					if (this.validation.countErrors() > 0) {
						this.$toastr(
							"warning",
							'You need to fill <span class="font-weight-bold">' +
								this.validation.countErrors() +
								" more empty</span> mandatory fields",
							"Empty Mandatory Fields!!"
						);
					} else {
						if (res) {
							this.$parent.loading = true;
							axios
								.post("/member/store-company-info", this.data)
								.then(response => {
									this.$toastr(
										"success",
										"Your information has been submitted, please payment",
										"Success!!"
									);
									if (response.data) {
										localStorage.setItem("recp_id", response.data.recp_id);
										localStorage.setItem("service_amount", this.data.amount);
										this.$router.push({
											name: "paymentMethod",
											params: {
												id: this.data.service_id,
												amount: this.data.amount
											}
										});
									}
								})
								.catch(error => {
									console.log(error);
								})
								.then(alw => {
									this.$parent.loading = false;
								});
						}
					}
				});
			},
			getServiceInfo() {
				axios
					.get("/member/get-serviceinfo/" + this.$route.params.id)
					.then(res => {
						this.showServiceForm = res.data;
					});
			}
		},
		created() {
			this.loggedIn();
			setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefd);
			this.data.service_id = this.$route.params.id;
			this.getServiceInfo();
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
		validators: {
			"data.mailing_address": function(value = null) {
				return Validator.value(value).required("Address is required");
			},
			"data.applicant_name": function(value = null) {
				return Validator.value(value).required("Contact Person is required");
			},
			"data.phone": function(value = null) {
				return Validator.value(value)
					.required("Contact Number in required")
					.digit()
					.regex("01+[0-9]*$", "Must start with 01.")
					.minLength(11)
					.maxLength(11);
			},
			"data.room": function(value = null) {
				return Validator.value(value).required("Room/Hall Name is required ");
			},
			"data.amount": function(value = null) {
				return Validator.value(value).required("Time Slot is required ");
			}
		}
	};
</script>

