<template>
	<div v-if="!$parent.loading && member.info && member.info.status == 1" class="row">
		<div class="col-lg-9">
			<div class="card shadow-sm">
				<div class="card-header bg-transparent border-0">
					<h4 class="text-center">Application Form for SCB ERQ Account for Company</h4>
					<p class="font-size-md text-center desc-m">
						Professionals involved in outsourced service exports, will be
						offered from Standard Chartered a bundle offer of a Taka Savings
						Account and an US Dollar ERQ (Exporter Retention Quota) account.
						Individuals, firms, and professionals involved in service exports in
						non-physical from such as outsourcing, BPO, business services,
						professional, research and advisory services etc.
					</p>
				</div>
				<div class="card-body">
					<div class="alert alert-danger" role="alert" v-if="!showServiceForm">
						<h4 class="alert-heading">Warning!</h4>
						<p>This service is not availabe</p>
						<hr />
					</div>
					<form @submit.prevent="submit()" v-if="showServiceForm">
						<input type="hidden" v-model="data.service_id" />
						<div class="form-row">
							<div class="col-md-12 mb-3">
								<label for="name_organization">
									Name of the Company
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									:class="{
                    'is-invalid': validation.hasError('data.name_organization'),
                    'is-valid': data.name_organization,
                  }"
									id="name_organization"
									placeholder="e.g. Nogor"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. Nogor'"
									v-model="data.name_organization"
								/>
								<small
									v-if="errors && errors.name_organization"
									class="invalid-feedback"
								>{{ errors.name_organization[0] }}</small>
								<div class="invalid-feedback">{{ validation.firstError("data.name_organization") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="mailing_address">
									Mailing Address of the Applicant Company
									<span class="text-danger requierd-star"></span>
								</label>
								<textarea
									rows="5"
									v-model="data.mailing_address"
									placeholder="e.g. 221b, Baker Street, London"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 221b, Baker Street, London'"
									class="form-control shadow-none"
									:class="{
                    'is-invalid': validation.hasError('data.mailing_address'),
                    'is-valid': data.mailing_address,
                  }"
								></textarea>
								<div class="invalid-feedback">{{ validation.firstError("data.mailing_address") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="permanent_address">
									Permanent address of the Applicant Company
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<textarea
									rows="5"
									v-model="data.permanent_address"
									placeholder="e.g. 221b, Baker Street, London"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 221b, Baker Street, London'"
									class="form-control shadow-none"
									:class="{
                    'is-invalid': validation.hasError('data.permanent_address'),
                    'is-valid': data.permanent_address,
                  }"
								></textarea>
								<div class="invalid-feedback">{{ validation.firstError("data.permanent_address") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="y_o_establishment">
									Year of Establishment
									<span class="text-danger requierd-star"></span>
								</label>
								<div class="input-group">
									<Datepicker
										class="form-control shadow-none"
										:class="{
                      'is-invalid': validation.hasError(
                        'data.year_of_establishment'
                      ),
                      'is-valid': data.year_of_establishment,
                    }"
										id="year_of_establishment"
										placeholder="e.g. 12-12-2022."
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 12-12-2022'"
										v-model="data.year_of_establishment"
									/>
									<div class="invalid-feedback">{{ validation.firstError("data.year_of_establishment") }}</div>
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="noc_employees">
									Number of current employees
									<span class="text-danger requierd-star"></span>
								</label>
								<div class="input-group">
									<input
										type="text"
										class="form-control shadow-none"
										id="no_of_employees"
										placeholder="e.g. 15"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 15'"
										:class="{
                      'is-invalid': validation.hasError('data.no_of_employees'),
                      'is-valid': data.no_of_employees,
                    }"
										v-model="data.no_of_employees"
									/>
									<div class="invalid-feedback">{{ validation.firstError("data.no_of_employees") }}</div>
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="name_representative">
									Name of Representative
									<span class="text-danger requierd-star"></span>
								</label>
								<div class="input-group">
									<input
										type="text"
										class="form-control shadow-none"
										id="name_representative"
										placeholder="e.g. Jane Doe"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. Jane Doe'"
										:class="{
                      'is-invalid': validation.hasError(
                        'data.name_representative'
                      ),
                      'is-valid': data.name_representative,
                    }"
										v-model="data.name_representative"
									/>
									<div class="invalid-feedback">{{ validation.firstError("data.name_representative") }}</div>
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="designation_representative">
									Designation of Representative
									<span class="text-danger requierd-star"></span>
								</label>
								<div class="input-group">
									<input
										type="text"
										class="form-control shadow-none"
										id="designation_representative"
										placeholder="e.g. CEO"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. CEO'"
										:class="{
                      'is-invalid': validation.hasError(
                        'data.designation_representative'
                      ),
                      'is-valid': data.designation_representative,
                    }"
										v-model="data.designation_representative"
									/>
									<div class="invalid-feedback">
										{{
										validation.firstError("data.designation_representative")
										}}
									</div>
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="email">
									Email Address
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="email"
									placeholder="e.g. yourname@mail.com"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. yourname@mail.com'"
									:class="{
                    'is-invalid': validation.hasError('data.email'),
                    'is-valid': data.email,
                  }"
									v-model="data.email"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.email") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="phone">
									Cell Phone
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<div class="input-group">
									<div class="input-group-prepend">
										<span class="input-group-text" id="inputGroupPrepend3">+88</span>
									</div>
									<input
										type="text"
										class="form-control shadow-none"
										id="phone"
										placeholder="e.g. 01xxxxxxxxx"
										onfocus="this.placeholder = ''"
										onblur="this.placeholder = 'e.g. 01xxxxxxxxx'"
										:class="{
                      'is-invalid': validation.hasError('data.phone'),
                      'is-valid': data.phone,
                    }"
										v-model="data.phone"
									/>
									<div class="invalid-feedback">{{ validation.firstError("data.phone") }}</div>
								</div>
							</div>

							<div class="col-md-6 mb-3">
								<label for="trade_license">
									Trade License Number
									<span class="text-danger requierd-star"></span>
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="trade_license"
									placeholder="e.g. 12345"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 12345'"
									:class="{
                    'is-invalid': validation.hasError('data.trade_license'),
                    'is-valid': data.trade_license,
                  }"
									v-model="data.trade_license"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.trade_license") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="rjsc_registation">
									Registration Number (RJSC)
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="registration_number"
									placeholder="e.g. 12345"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. 12345'"
									:class="{
                    'is-invalid': validation.hasError(
                      'data.registration_number'
                    ),
                    'is-valid': data.registration_number,
                  }"
									v-model="data.registration_number"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.registration_number") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="business_sector">
									Business Sector / Expertise on
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="business_sector"
									:class="{
                    'is-invalid': validation.hasError('data.business_sector'),
                    'is-valid': data.business_sector,
                  }"
									placeholder="e.g. IT"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. IT'"
									v-model="data.business_sector"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.business_sector") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="offshore_development">
									Performing Offshore development for
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<input
									type="text"
									class="form-control shadow-none"
									id="offshore_development"
									:class="{
                    'is-invalid': validation.hasError(
                      'data.offshore_development'
                    ),
                    'is-valid': data.offshore_development,
                  }"
									placeholder="e.g. demo company"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'e.g. demo company'"
									v-model="data.offshore_development"
								/>
								<div class="invalid-feedback">{{ validation.firstError("data.offshore_development") }}</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="major_countries">
									Major five exporting countries
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<div class="row">
									<div class="col-md-12">
										<input
											type="text"
											class="form-control shadow-none"
											id="major_countries1"
											:class="{
                        'is-invalid': validation.hasError(
                          'data.major_countries1'
                        ),
                        'is-valid': data.major_countries1,
                      }"
											placeholder="1."
											v-model="data.major_countries1"
										/>
										<div class="invalid-feedback">
											<!--{{ validation.firstError('data.major_countries1') }}-->
										</div>
									</div>
									<div class="col-md-12">
										<input
											type="text"
											class="form-control shadow-none"
											id="major_countries2"
											:class="{
                        'is-invalid': validation.hasError(
                          'data.major_countries2'
                        ),
                        'is-valid': data.major_countries2,
                      }"
											placeholder="2."
											v-model="data.major_countries2"
										/>
										<div class="invalid-feedback">
											<!-- {{ validation.firstError('data.major_countries2') }}-->
										</div>
									</div>
									<div class="col-md-12">
										<input
											type="text"
											class="form-control shadow-none"
											id="major_countries3"
											:class="{
                        'is-invalid': validation.hasError(
                          'data.major_countries3'
                        ),
                        'is-valid': data.major_countries3,
                      }"
											placeholder="3."
											v-model="data.major_countries3"
										/>
										<div class="invalid-feedback">
											<!-- {{ validation.firstError('data.major_countries3') }}-->
										</div>
									</div>
									<div class="col-md-12">
										<input
											type="text"
											class="form-control shadow-none"
											id="major_countries4"
											:class="{
                        'is-invalid': validation.hasError(
                          'data.major_countries4'
                        ),
                        'is-valid': data.major_countries4,
                      }"
											placeholder="4."
											v-model="data.major_countries4"
										/>
										<div class="invalid-feedback">
											<!-- {{ validation.firstError('data.major_countries4') }}-->
										</div>
									</div>
									<div class="col-md-12">
										<input
											type="text"
											class="form-control shadow-none"
											id="major_countries5"
											:class="{
                        'is-invalid': validation.hasError(
                          'data.major_countries5'
                        ),
                        'is-valid': data.major_countries5,
                      }"
											placeholder="5."
											v-model="data.major_countries5"
										/>
										<div class="invalid-feedback">
											<!--{{ validation.firstError('data.major_countries5') }}-->
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 mb-3">
								<label for="domain_outsourcing">
									Major five domain in outsourcing
									<!-- <span class="text-danger requierd-star"></span> -->
								</label>
								<div class="row">
									<div class="col-md-12">
										<input
											type="text"
											class="form-control shadow-none"
											id="domain_outsourcing1"
											:class="{
                        'is-invalid': validation.hasError(
                          'data.domain_outsourcing1'
                        ),
                        'is-valid': data.domain_outsourcing1,
                      }"
											placeholder="1."
											v-model="data.domain_outsourcing1"
										/>
										<div class="invalid-feedback">
											<!--{{ validation.firstError('data.domain_outsourcing1') }}-->
										</div>
									</div>

									<div class="col-md-12">
										<input
											type="text"
											class="form-control shadow-none"
											id="domain_outsourcing2"
											:class="{
                        'is-invalid': validation.hasError(
                          'data.domain_outsourcing2'
                        ),
                        'is-valid': data.domain_outsourcing2,
                      }"
											placeholder="2."
											v-model="data.domain_outsourcing2"
										/>
										<div class="invalid-feedback">
											<!--{{ validation.firstError('data.domain_outsourcing2') }}-->
										</div>
									</div>

									<div class="col-md-12">
										<input
											type="text"
											class="form-control shadow-none"
											id="domain_outsourcing3"
											:class="{
                        'is-invalid': validation.hasError(
                          'data.domain_outsourcing3'
                        ),
                        'is-valid': data.domain_outsourcing3,
                      }"
											placeholder="3."
											v-model="data.domain_outsourcing3"
										/>
										<div class="invalid-feedback">
											<!-- {{ validation.firstError('data.domain_outsourcing3') }}-->
										</div>
									</div>

									<div class="col-md-12">
										<input
											type="text"
											class="form-control shadow-none"
											id="domain_outsourcing4"
											:class="{
                        'is-invalid': validation.hasError(
                          'data.domain_outsourcing4'
                        ),
                        'is-valid': data.domain_outsourcing4,
                      }"
											placeholder="4."
											v-model="data.domain_outsourcing4"
										/>
										<div class="invalid-feedback">
											<!-- {{ validation.firstError('data.domain_outsourcing4') }}-->
										</div>
									</div>

									<div class="col-md-12">
										<input
											type="text"
											class="form-control shadow-none"
											id="domain_outsourcing5"
											:class="{
                        'is-invalid': validation.hasError(
                          'data.domain_outsourcing5'
                        ),
                        'is-valid': data.domain_outsourcing5,
                      }"
											placeholder="5."
											v-model="data.domain_outsourcing5"
										/>
										<div class="invalid-feedback">
											<!-- {{ validation.firstError('data.domain_outsourcing5') }} -->
										</div>
									</div>
								</div>
							</div>
							<!-- <div class="col-md-12 mb-3">
                <label for="payment_method">Payment Method</label>
                <select
                  v-model="data.payment_method"
                  class="form-control"
                  :class="{'is-invalid': validation.hasError('data.payment_method'),'is-valid': data.payment_method}"
                >
                  <option selected value="null">Choose...</option>
                  <option>UCBL (Cash Deposite / Payorder)</option>
                  <option>SSL Commerce (Online Payment)</option>
                </select>
                <div class="invalid-feedback">{{ validation.firstError('data.payment_method') }}</div>
							</div>-->
							<div class="col-md-12 mb-3">
								<label for="is_confirm"></label>
								<div class="form-group">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											id="is_confirm"
											:value="true"
											:class="{
                        'is-invalid': validation.hasError('data.is_confirm'),
                      }"
											v-model="data.is_confirm"
											class="custom-control-input"
											:unchecked-value="false"
										/>
										<label class="custom-control-label" for="is_confirm">
											I confirm that the information provided above, and in any
											attachment, are correct and I understand that any false
											statement or information could result in my application
											being terminated.
										</label>
									</div>
								</div>
								<div class="invalid-feedback">{{ validation.firstError("data.is_confirm") }}</div>
							</div>
						</div>
						<button class="btn btn-primary" type="submit" :disabled="validation.countErrors() > 0">Submit</button>
					</form>
				</div>

				<!-- <pre>{{ data }}</pre> -->
				<!-- <pre>{{validation.countErrors() }}</pre> -->
			</div>
		</div>
		<right-side-scb></right-side-scb>
	</div>
	<div v-else-if="member.info && member.info.status == 0" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="text-center text-danger">Sorry!! your account is suspend</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import RightSideScb from "../../../../../components/Frontend/partials/member/RightSideScb";

	import Vue from "vue";
	import SimpleVueValidation from "simple-vue-validator";
	const Validator = SimpleVueValidation.Validator;
	Vue.use(SimpleVueValidation);

	export default {
		name: "AccountOfCompany",
		components: {
			RightSideScb
		},
		props: ["memberInfo"],
		data() {
			return {
				showServiceForm: false,
				title: "Account Of Company",
				count: 0,
				submitted: false,
				seen: false,
				seen1: false,
				data: {
					service_id: null,
					name_organization: null,
					phone: null,
					email: null,
					mailing_address: null,
					permanent_address: null
				},
				member: {
					info: null
				},
				text: "",
				errors: {}
			};
		},
		methods: {
			submit() {
				this.data.form_type = this.$route.name;
				this.$validate().then(res => {
					if (this.validation.countErrors() > 0) {
						this.$toastr(
							"warning",
							'You need to fill <span class="font-weight-bold">' +
								this.validation.countErrors() +
								" more empty</span> mandatory fields",
							"Empty Mandatory Fields!!"
						);
					} else {
						if (res) {
							this.$parent.loading = true;
							axios
								.post("/member/store-company-info", this.data)
								.then(response => {
									this.$toastr(
										"success",
										"Your information has been submitted, please payment",
										"Success!!"
									);
									if (response.data) {
										localStorage.setItem("recp_id", response.data.recp_id);
										this.$router.push({
											name: "paymentMethod",
											params: {
												id: this.data.service_id,
												amount: response.data.amount
											}
										});
									}
								})
								.catch(error => {
									console.log(error);
								})
								.then(alw => {
									this.$parent.loading = false;
								});
						}
					}
				});
			},
			notAfterToday(date) {
				return date > Date.now();
			},
			getServiceInfo() {
				axios
					.get("/member/get-serviceinfo/" + this.$route.params.id)
					.then(res => {
						this.showServiceForm = res.data;
					});
			}
		},
		created() {
			this.loggedIn();
			// primary
			axios.get("member/get-represent-info/primary").then(response => {
				this.data.name_representative = response.data.name;
				this.data.designation_representative = response.data.designation;
				this.data.email = response.data.email;
				this.data.phone = response.data.mobile;
			});
			setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);
			this.data.service_id = this.$route.params.id;
			this.getServiceInfo();
		},
		updated() {
			if (this.count <= 4) {
				if (this.member.info) {
					this.data.name_organization = this.member.info.company_name;
					this.data.mailing_address = this.member.info.address;
					this.data.permanent_address = this.member.info.address;
					this.data.fb_profile = this.member.info.fb_url;
					this.count++;
				}
			}
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = true;
		},
		validators: {
			"data.name_organization": function(value = null) {
				return Validator.value(value)
					.required("Company name is required")
					.minLength(2);
			},
			"data.mailing_address": function(value = null) {
				return Validator.value(value).required("Mailing Address is required");
			},

			"data.permanent_address": function(value = null) {
				// return Validator.value(value).required("Permanent Address is required");
			},

			"data.email": function(value = null) {
				return Validator.value(value)
					.required("Email address required")
					.email();
			},
			"data.phone": function(value = null) {
				return (
					Validator.value(value)
						// .required("Cell phone number required")
						.digit()
						.regex("01+[0-9]*$", "Must start with 01.")
						.minLength(11)
						.maxLength(40)
				);
			},
			"data.year_of_establishment": function(value = null) {
				return Validator.value(value).required("Year of establsihment required");
			},
			"data.no_of_employees": function(value = null) {
				return Validator.value(value)
					.required("Number of Employees required")
					.digit();
			},
			"data.name_representative": function(value = null) {
				return Validator.value(value)
					.required("Name of representative required")
					.minLength(2);
			},
			"data.trade_license": function(value = null) {
				return Validator.value(value).required("Trade licence number required");
			},
			"data.business_sector": function(value = null) {
				// return Validator.value(value).required("Business Sector Required");
			},
			"data.designation_representative": function(value = null) {
				return Validator.value(value).required(
					"Designation of representative required"
				);
			},
			"data.registration_number": function(value = null) {
				// return Validator.value(value).required(
				//   "Registration Number (RJSC) required"
				// );
			},
			"data.offshore_development": function(value = null) {
				// return Validator.value(value).required("Offshore development required");
			},
			"data.major_countries1": function(value = null) {
				// return Validator.value(value).required("Exporting country required");
			},
			"data.major_countries2": function(value = null) {
				// return Validator.value(value).required("Exporting country required");
			},
			"data.major_countries3": function(value = null) {
				// return Validator.value(value).required("Exporting country required");
			},
			"data.major_countries4": function(value = null) {
				// return Validator.value(value).required("Exporting country required");
			},
			"data.major_countries5": function(value = null) {
				// return Validator.value(value).required("Exporting country required");
			},
			"data.domain_outsourcing1": function(value = null) {
				// return Validator.value(value).required("Domain in outsourcing required");
			},
			"data.domain_outsourcing2": function(value = null) {
				// return Validator.value(value).required("Domain in outsourcing required");
			},
			"data.domain_outsourcing3": function(value = null) {
				// return Validator.value(value).required("Domain in outsourcing required");
			},
			"data.domain_outsourcing4": function(value = null) {
				// return Validator.value(value).required("Domain in outsourcing required");
			},
			"data.domain_outsourcing5": function(value = null) {
				// return Validator.value(value).required("Domain in outsourcing required");
			},
			"data.is_confirm": function(value = null) {
				return Validator.value(value)
					.required("Confirmation is required")
					.custom(function() {
						if (value == false) {
							return "You must check";
						}
					});
			}
		}
	};
</script>

<style >
	.invalid-feedback {
		display: block !important;
	}
	.mx-datepicker {
		width: 100%;
	}
</style>
