<template>
  <div class="footer-top">
    <div class="container-wrapper">
      <div class="row align-items-center justify-content-center" v-if="parentData.content">
        <div class="col-lg-3 border-right border-danger">
          <div class="d-flex">
            <i class="fas fa-headset fa-4x text-danger"></i>
            <p class="quetion ml-3">
              HAVE QUESTIONS?
              <br />ASK A SPECIALIST
            </p>
          </div>
        </div>
        <div class="col-lg-4 border-right border-danger text-center">
          <p class="number">{{parentData.content.questions.title}}</p>
        </div>
        <div class="col-lg-3">
          <p class="shedule text-center" v-html="parentData.content.questions.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footertop",
  props: {
    parentData: {
      type: Object,
      default: () => {},
      description: "News"
    }
  },
  data() {
    return {
      news1: require("../../assets/img/gallery/gallery1.jpeg"),
      news2: require("../../assets/img/gallery/gallery2.jpg"),
      news3: require("../../assets/img/gallery/gallery3.jpg")
    };
  }
};
</script>