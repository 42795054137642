<template>
  <div
    v-if="!$parent.loading && member.info && member.info.status == 1"
    class="member-services"
  >
    <div class="card shadow-sm mb-3">
      <div class="card-header px-0">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="text-uppercase font-weight-bold px-3 mb-0">Event Form</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="alert alert-danger" role="alert" v-if="!showServiceForm">
          <h4 class="alert-heading">Warning!</h4>
          <p>This service is not availabe</p>
          <hr />
        </div>
        <form v-on:submit.prevent="submit" id="form" v-if="showServiceForm">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Event Name
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  placeholder="Event Name"
                  name="event_name"
                  readonly
                  v-model="data.event_name"
                  :class="{
                    'is-invalid': validation.hasError('data.event_name'),
                    'is-valid': data.event_name,
                  }"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.event_name") }}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Company Name
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  placeholder="Company Name"
                  name="company_name"
                  v-model="data.company_name"
                  :class="{
                    'is-invalid': validation.hasError('data.company_name'),
                    'is-valid': data.company_name,
                  }"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.company_name") }}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Company Address
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  placeholder="Company Address"
                  name="company_address"
                  v-model="data.company_address"
                  :class="{
                    'is-invalid': validation.hasError('data.company_address'),
                    'is-valid': data.company_address,
                  }"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.company_address") }}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Company Website
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  placeholder="Company Website"
                  name="company_website"
                  v-model="data.company_website"
                  :class="{
                    'is-invalid': validation.hasError('data.company_website'),
                    'is-valid': data.company_website,
                  }"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.company_website") }}
                </div>
              </div>
            </div>

            <fieldset class="col-12 border border-primary p-3 mb-3">
              <legend class="w-50 text-center">
                <small class="text-uppercase font-weight-bold"
                  >1st Participants Information</small
                >
              </legend>
              <div class="row">
                <div class="form-group col-lg-6 mb-3">
                  <label for="comments">Name</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Name"
                    name="participant_one.name"
                    v-model="data.participant_one.name"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.participant_one.name'
                      ),
                      'is-valid': data.participant_one.name,
                    }"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.participant_one.name") }}
                  </div>
                </div>
                <div class="form-group col-lg-6 mb-3">
                  <label for="comments">Mobile</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Mobile"
                    name="participant_one.mobile"
                    v-model="data.participant_one.mobile"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.participant_one.mobile'
                      ),
                      'is-valid': data.participant_one.mobile,
                    }"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.participant_one.mobile") }}
                  </div>
                </div>
                <div class="form-group col-lg-6 mb-3">
                  <label for="comments">Email</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    type="email"
                    class="form-control shadow-none"
                    placeholder="Email"
                    name="participant_one.email"
                    v-model="data.participant_one.email"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.participant_one.email'
                      ),
                      'is-valid': data.participant_one.email,
                    }"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.participant_one.email") }}
                  </div>
                </div>
                <div class="form-group col-lg-6 mb-3">
                  <label for="comments">Passport No.</label>
                  <span class="text-danger requierd-star"></span>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Passport No."
                    name="participant_one.passport"
                    v-model="data.participant_one.passport"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.participant_one.passport'
                      ),
                      'is-valid': data.participant_one.passport,
                    }"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.participant_one.passport") }}
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset class="col-12 border border-primary p-3 mb-3">
              <legend class="w-50 text-center">
                <small class="text-uppercase font-weight-bold"
                  >2nd Participants Information</small
                >
              </legend>
              <div class="row">
                <div class="form-group col-lg-6 mb-3">
                  <label for="comments">Name</label>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Name"
                    v-model="data.participant_second.name"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.participant_second.name'
                      ),
                      'is-valid': data.participant_second.name,
                    }"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.participant_second.name") }}
                  </div>
                </div>
                <div class="form-group col-lg-6 mb-3">
                  <label for="comments">Mobile</label>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Mobile"
                    name="participant_second.mobile"
                    v-model="data.participant_second.mobile"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.participant_second.mobile'
                      ),
                      'is-valid': data.participant_second.mobile,
                    }"
                  />
                  <div class="invalid-feedback">
                    {{
                      validation.firstError("data.participant_second.mobile")
                    }}
                  </div>
                </div>
                <div class="form-group col-lg-6 mb-3">
                  <label for="comments">Email</label>
                  <input
                    type="email"
                    class="form-control shadow-none"
                    placeholder="Email"
                    name="participant_second.email"
                    v-model="data.participant_second.email"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.participant_second.email'
                      ),
                      'is-valid': data.participant_second.email,
                    }"
                  />
                  <div class="invalid-feedback">
                    {{ validation.firstError("data.participant_second.email") }}
                  </div>
                </div>
                <div class="form-group col-lg-6 mb-3">
                  <label for="comments">Passport No.</label>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Passport No."
                    name="participant_second.passport"
                    v-model="data.participant_second.passport"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.participant_second.passport'
                      ),
                      'is-valid': data.participant_second.passport,
                    }"
                  />
                  <div class="invalid-feedback">
                    {{
                      validation.firstError("data.participant_second.passport")
                    }}
                  </div>
                </div>
              </div>
            </fieldset>

            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Product or services you want to showcase
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  placeholder="Product or services you want to showcase"
                  name="services"
                  v-model="data.services"
                  :class="{
                    'is-invalid': validation.hasError('data.services'),
                    'is-valid': data.services,
                  }"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.services") }}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Why are you interested in this respective event?
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  placeholder="Respective Event"
                  name="respective_event"
                  v-model="data.respective_event"
                  :class="{
                    'is-invalid': validation.hasError('data.respective_event'),
                    'is-valid': data.respective_event,
                  }"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.respective_event") }}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Does your company receive any local and international
                  recognition/award previously?
                  <span class="text-danger requierd-star"></span>
                </label>
                <input
                  type="text"
                  class="form-control shadow-none"
                  placeholder="Award previously"
                  name="previously_award"
                  v-model="data.previously_award"
                  :class="{
                    'is-invalid': validation.hasError('data.previously_award'),
                    'is-valid': data.previously_award,
                  }"
                />
                <div class="invalid-feedback">
                  {{ validation.firstError("data.previously_award") }}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments">
                  Did you participate in any international events through BASIS?
                  If yes please mention the event name and year
                  <span class="text-danger requierd-star"></span>
                </label>
                <div class="custom-control custom-radio">
                  >
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="participate_events_yes"
                    name="participate_events"
                    :value="true"
                    v-model="participate_events"
                  />
                  <label
                    class="custom-control-label"
                    for="participate_events_yes"
                    >Yes</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  >
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="participate_events_no"
                    name="participate_events"
                    v-model="participate_events"
                    :value="false"
                  />
                  <label
                    class="custom-control-label"
                    for="participate_events_no"
                    >No</label
                  >
                </div>
              </div>
              <div class="row form-row mb-3" v-if="participate_events">
                <div class="col-md-4">
                  <select
                    name="participate_events_year"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.participate_events_year'
                      ),
                      'is-valid': data.participate_events_year,
                    }"
                    v-model="data.participate_events_year"
                  >
                    <option value selected disabled>Select Year</option>
                    <option
                      v-for="(value, name, index) in years"
                      :key="index"
                      v-bind:value="value"
                    >
                      {{ value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Participate Events"
                    name="services"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.participate_events_text'
                      ),
                      'is-valid': data.participate_events_text,
                    }"
                    v-model="data.participate_events_text"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label>
                  Do you have experience in export? If yes please mention the
                  total experience year and export Countries
                  <span class="text-danger requierd-star"></span>
                </label>
                <div class="custom-control custom-radio">
                  >
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="experience_yes"
                    name="export_experience"
                    :value="true"
                    v-model="export_experience"
                  />
                  <label class="custom-control-label" for="experience_yes"
                    >Yes</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  >
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="experience_no"
                    name="export_experience"
                    v-model="export_experience"
                    :value="false"
                  />
                  <label class="custom-control-label" for="experience_no"
                    >No</label
                  >
                </div>
              </div>
              <div class="form-row mb-3" v-if="export_experience">
                <div class="col-md-4">
                  <select
                    name="export_experience_year"
                    class="form-control shadow-none"
                    v-model="data.export_experience_year"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.export_experience_year'
                      ),
                      'is-valid': data.export_experience_year,
                    }"
                  >
                    <option value selected disabled>Select Year</option>
                    <option
                      v-for="(value, name, index) in years"
                      :key="index"
                      v-bind:value="value"
                    >
                      {{ value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Export Experience"
                    name="services"
                    v-model="data.export_experience_text"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.export_experience_text'
                      ),
                      'is-valid': data.export_experience_text,
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label class="col-12">
                  Please mention your firm’s last year’s total revenue
                  <span class="text-danger requierd-star"></span>
                </label>
                <div class="custom-control custom-radio">
                  >
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="total_revenue_yes"
                    name="total_revenue"
                    :value="true"
                    v-model="total_revenue"
                  />
                  <label class="custom-control-label" for="total_revenue_yes"
                    >Yes</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  >
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="total_revenue_no"
                    name="total_revenue"
                    v-model="total_revenue"
                    :value="false"
                  />
                  <label class="custom-control-label" for="total_revenue_no"
                    >No</label
                  >
                </div>
              </div>
              <div class="row form-row mb-3" v-if="total_revenue">
                <div class="col-md-4">
                  <select
                    name="total_revenue_year"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.total_revenue_year'
                      ),
                      'is-valid': data.total_revenue_year,
                    }"
                    v-model="data.total_revenue_year"
                  >
                    <option value selected disabled>Select Year</option>
                    <option
                      v-for="(value, name, index) in years"
                      :key="index"
                      v-bind:value="value"
                    >
                      {{ value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-7">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="total_revenue_usd"
                        >$</span
                      >
                    </div>
                    <input
                      type="number"
                      class="form-control shadow-none"
                      placeholder="USD"
                      id="total_revenue_usd"
                      v-model="data.total_revenue_usd"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-row mb-3">
                <label for="comments" class="col-12">
                  Please mention your firm’s last year’s yearly export earning
                  <span class="text-danger requierd-star"></span>
                </label>
                <div class="custom-control custom-radio">
                  >
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="export_earning_yes"
                    :value="true"
                    v-model="export_earning"
                  />
                  <label class="custom-control-label" for="export_earning_yes"
                    >Yes</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  >
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="export_earning_no"
                    v-model="export_earning"
                    :value="false"
                  />
                  <label class="custom-control-label" for="export_earning_no"
                    >No</label
                  >
                </div>
                <div class="invalid-feedback">
                  {{ validation.firstError("export_earning") }}
                </div>
              </div>

              <div class="row form-row mb-3" v-if="export_earning">
                <div class="col-md-4">
                  <select
                    name="export_earning_year"
                    class="form-control shadow-none"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.export_earning_year'
                      ),
                      'is-valid': data.export_earning_year,
                    }"
                    v-model="data.export_earning_year"
                  >
                    <option value selected disabled>Select Year</option>
                    <option
                      v-for="(value, name, index) in years"
                      :key="index"
                      v-bind:value="value"
                    >
                      {{ value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-7">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="inputGroupPrepend3"
                        >$</span
                      >
                    </div>
                    <input
                      type="number"
                      class="form-control shadow-none"
                      placeholder="USD"
                      id="export_earning_usd"
                      v-model="data.export_earning_usd"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label for="comment">
                Did you attend in any local event of BASIS?
                <span class="text-danger requierd-star"></span>
              </label>
              <div class="form-row mb-3">
                <div class="custom-control custom-radio">
                  >
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="local_event_yes"
                    :value="true"
                    v-model="local_event"
                  />
                  <label class="custom-control-label" for="local_event_yes"
                    >Yes</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  >
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="local_event_no"
                    v-model="local_event"
                    :value="false"
                  />
                  <label class="custom-control-label" for="local_event_no"
                    >No</label
                  >
                </div>
              </div>
              <div class="row form-row mb-3" v-if="local_event">
                <div class="col-md-4">
                  <select
                    name
                    id
                    class="form-control shadow-none"
                    v-model="data.local_event_year"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.local_event_year'
                      ),
                      'is-valid': data.local_event_year,
                    }"
                  >
                    <option value selected disabled>Select Year</option>
                    <option
                      v-for="(value, name, index) in years"
                      :key="index"
                      v-bind:value="value"
                    >
                      {{ value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Local Event"
                    name="services"
                    v-model="data.local_event_text"
                    :class="{
                      'is-invalid': validation.hasError(
                        'data.local_event_text'
                      ),
                      'is-valid': data.local_event_text,
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <button class="btn btn-success text-center" type="submit">
            Next
          </button>
        </form>
      </div>
    </div>
    <!-- <pre>{{ validation.allErrors() }}</pre>
		<pre>{{data}}</pre>-->
  </div>

  <div v-else-if="member.info && member.info.status == 0" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center text-danger">
            Sorry!! your account is suspend
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  name: "EventForm",
  data() {
    return {
      showServiceForm: false,
      data: {
        amount: 0,
        export_experience_year: "",
        participate_events_year: "",
        total_revenue_year: "",
        local_event_year: "",
        export_earning_year: "",
        participant_one: {},
        participant_second: {},
      },
      local_event: false,
      export_experience: false,
      participate_events: false,
      total_revenue: false,
      export_earning: false,
      errors: {},
      member: {
        info: null,
      },
    };
  },

  methods: {
    submit: function (e) {
      this.$validate().then((res) => {
        if (this.validation.countErrors() > 0) {
          this.$toastr(
            "warning",
            'You need to fill <span class="font-weight-bold">' +
              this.validation.countErrors() +
              " more empty</span> mandatory fields",
            "Empty Mandatory Fields!!"
          );
        } else {
          if (res) {
            this.$parent.loading = true;
            axios
              .post("member/internationalEvent", this.data)
              .then((response) => {
                this.$toastr(
                  "success",
                  "Your information has been submitted, please payment",
                  "Success!!"
                );
                if (response.data) {
                  localStorage.setItem("recp_id", response.data.recp_id);
                  localStorage.setItem("serv_type", "event");
                  localStorage.setItem("service_amount", this.data.amount);
                  this.$router.push({
                    name: "paymentMethod",
                    params: {
                      id: this.data.event_id,
                      amount: this.data.amount,
                    },
                  });
                }
              })
              .catch((error) => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                  this.focus_field = Object.keys(this.errors)[0];
                }
              })
              .then((alw) => {
                this.$parent.loading = false;
              });
          }
        }
      });
    },
  },
  mounted() {
    this.loggedIn();
    setTimeout(() => (this.$parent.loading = false), this.$root.minloadtimefs);

    axios.get("/member/get-event-info/" + this.$route.params.id).then((res) => {
      this.data.amount = res.data.amount ? res.data.amount : 0;
      this.data.event_id = res.data.id;
      this.data.event_name = res.data.title;
      this.data.company_name = res.data.company_name;
      this.data.company_address = res.data.address;
      this.data.company_website = res.data.website;
      this.showServiceForm = res.data;
    });
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
  },
  validators: {
    "data.event_name": function (value = null) {
      return Validator.value(value).required("Event name is required");
    },
    "data.company_name": function (value = null) {
      return Validator.value(value).required("Company name is required");
    },
    "data.company_address": function (value = null) {
      return Validator.value(value).required("Company Address is required");
    },
    "data.company_website": function (value = null) {
      return Validator.value(value).required("Company Website is required");
    },
    "data.participant_one.name": function (value = null) {
      return Validator.value(value).required("Name is required");
    },
    "data.participant_one.mobile": function (value = null) {
      return Validator.value(value)
        .required("Mobile is required")
        .digit()
        .regex("01+[0-9+-]*$", "Must start with 01.")
        .minLength(11)
        .maxLength(30);
    },
    "data.participant_one.email": function (value = null) {
      return Validator.value(value).required("Email is required").email();
    },
    "data.participant_one.passport": function (value = null) {
      return Validator.value(value).required("Passport is required");
    },
    "data.services": function (value = null) {
      return Validator.value(value).required("Product Services is required");
    },
    "data.export_experience_year": function (value = null) {
      if (this.export_experience) {
        return Validator.value(value).required(
          "export experience year is required"
        );
      }
    },
    "data.export_experience_text": function (value = null) {
      if (this.export_experience) {
        return Validator.value(value).required("export experience is required");
      }
    },
    "data.participate_events_year": function (value = null) {
      if (this.participate_events) {
        return Validator.value(value).required(
          "participate events year is required"
        );
      }
    },
    "data.participate_events_text": function (value = null) {
      if (this.participate_events) {
        return Validator.value(value).required(
          "participate events is required"
        );
      }
    },
    "data.previously_award": function (value = null) {
      return Validator.value(value).required("Previously Award is required");
    },
    total_revenue: function (value = null) {
      return Validator.value(value).required("Total Revenue  is required");
    },
    export_earning: function (value = null) {
      return Validator.value(value).required("Export Earning is required");
    },
    "data.local_event_year": function (value = null) {
      if (this.LocalEvent) {
        return Validator.value(value).required("local event year is required");
      }
    },
    "data.local_event_text": function (value = null) {
      if (this.LocalEvent) {
        return Validator.value(value).required("local event is required");
      }
    },
    "data.respective_event": function (value = null) {
      return Validator.value(value).required("Respective Event is required");
    },
  },
};
</script>


<style>
.mx-datepicker {
  width: 403px;
}
</style>
