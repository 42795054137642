export default {
    data() {
        return {}
    },
    created: function () { },
    methods: {
        // ecommerce mixin add to cart
        addToCart(productDetails, stock) {
            if (productDetails.color.length > 0 && productDetails.size.length > 0) {
                if (this.data.size != "" && this.data.color_family != "") {
                    productDetails.size_select = this.data.size;
                    productDetails.color_select = this.data.color_family;
                    if (stock) {
                        productDetails.price = stock.sales_price;
                    }

                    this.addTocartDataAssign(productDetails);
                } else {
                    this.colorMsg = "Color Field is required";
                    this.sizeMsg = "Size Field is required";
                    this.$toastr("error", "Please Select color & size", "Info!!");
                }
            } else if (productDetails.color.length > 0) {
                if (this.data.color_family != "") {
                    productDetails.color_select = this.data.color_family;
                    if (stock) {
                        productDetails.price = stock.sales_price;
                    }
                    this.addTocartDataAssign(productDetails);
                } else {
                    this.colorMsg = "Color Field is required";

                    this.$toastr("error", "Please Select color", "Info!!");
                }
            } else if (productDetails.size.length > 0) {
                if (this.data.size != "") {
                    productDetails.size_select = this.data.size;
                    if (stock) {
                        productDetails.price = stock.sales_price;
                    }

                    this.addTocartDataAssign(productDetails);
                } else {
                    this.sizeMsg = "Size Field is required";
                    this.$toastr("error", "Please Select size", "Info!!");
                }
            } else {
                if (stock) {
                    productDetails.price = stock.sales_price;
                }

                this.addTocartDataAssign(productDetails);
            }
        },
        buyNow(productDetails, stock) {
            if (productDetails.color.length > 0 && productDetails.size.length > 0) {
                if (this.data.size != "" && this.data.color_family != "") {
                    productDetails.size_select = this.data.size;
                    productDetails.color_select = this.data.color_family;
                    if (stock) {
                        productDetails.price = stock.sales_price;
                    }
                    this.addTocartDataAssign(productDetails, "buy_now");
                } else {
                    this.colorMsg = "Color Field is required";
                    this.sizeMsg = "Size Field is required";
                    this.$toastr("error", "Please Select color & size", "Info!!");
                }
            } else if (productDetails.color.length > 0) {
                if (this.data.color_family != "") {
                    if (stock) {
                        productDetails.price = stock.sales_price;
                    }
                    productDetails.color_select = this.data.color_family;
                    this.addTocartDataAssign(productDetails, "buy_now");
                } else {
                    this.colorMsg = "Color Field is required";

                    this.$toastr("error", "Please Select color", "Info!!");
                }
            } else if (productDetails.size.length > 0) {
                if (this.data.size != "") {
                    if (stock) {
                        productDetails.price = stock.sales_price;
                    }
                    productDetails.size_select = this.data.size;

                    this.addTocartDataAssign(productDetails, "buy_now");
                } else {
                    this.sizeMsg = "Size Field is required";
                    this.$toastr("error", "Please Select size", "Info!!");
                }
            } else {
                if (stock) {
                    productDetails.price = stock.sales_price;
                }
                productDetails.price = stock.sales_price;
                this.addTocartDataAssign(productDetails, "buy_now");
            }
        },
        wishList(product_id) {
            let data = { product_id: product_id };
            if (this.$root.ecomLogged) {
                axios.post("ecommerce/store-product-wishlist", data).then(res => {
                    console.log(res);
                    if (res.status == 200) {
                        this.$toastr("success", res.data.message, "Success!!");
                        this.$store.dispatch("countWishList");
                    } else if (res.status == 201) {
                        this.$toastr("error", res.data.message, "Info!!");
                    } else if (res.status == 401) {
                        this.$toastr("error", res.data.message, "Unauthenticate!!");
                    }
                });
            } else {
                this.$toastr(
                    "error",
                    "You are not login,Please login & try again",
                    "Unauthenticate!!"
                );
            }
        },
        cartUpdate(product, stock) {
            if (this.data.size != "") {
                product.size_select = this.data.size;
            }
            if (this.data.color_family != "") {
                product.color_select = this.data.color_family;
            }
            if (stock) {
                product.price = stock.sales_price;
            }
            product.total_cart = this.data.quantity;
            product.update = "edit";
            this.$store.commit("addToCart", product);
            this.$toastr("success", "Cart Update successfully!", "Success!!");
            this.$bvModal.hide("productDetails");
        },
        increment() {
            if (this.data.quantity > 0) {
                this.data.quantity++;
            }
        },
        decrement() {
            if (this.data.quantity > 1) {
                this.data.quantity--;
            }
        },
        addTocartDataAssign(productDetails, buy_now = "") {
            productDetails.discount_price = 0.0;

            if (
                productDetails.discount &&
                productDetails.discount.discount_type == 1
            ) {
                productDetails.discount_price = productDetails.discount.discount;
            }
            if (
                productDetails.discount &&
                productDetails.discount.discount_type == 2
            ) {
                productDetails.discount_price =
                    (productDetails.discount.discount / 100) * productDetails.price;
            }

            if (productDetails.discount) {
                productDetails.discount_start_date = productDetails.discount.start_date;
                productDetails.discount_end_date = productDetails.discount.end_date;
            }
            if (productDetails.discount) {
                productDetails.discount_id = productDetails.discount.id;
            } else {
                productDetails.discount_id = null;
            }

            if (buy_now) {
                productDetails.buy_now = true;
            } else {
                productDetails.buy_now = false;
            }
            productDetails.total_cart = this.data.quantity;

            this.$store.commit("addToCart", productDetails);
            this.$toastr("success", "Add to cart successfully!", "Success!!");

            this.colorMsg = "";
            this.sizeMsg = "";
            this.$bvModal.hide("productDetails");
            if (buy_now) {
                this.$router.push({ name: "ecommerce.addtocart" });
            } else {
                //refresh page
                this.$router.go()
            }

        }
    }
}
