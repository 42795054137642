<template>
	<div class="row">
		<div class="col-md-12">
			<div class="panel panel-primary">
				<h4>Questions</h4>
				<div
					class="panel-body"
					v-if="$parent.allQuestions && Object.keys($parent.allQuestions.feedback).length>0"
				>
					<ul class="chat border-0">
						<li v-for="(question,index) in $parent.allQuestions.feedback" :key="index">
							<div class="text-body clearfix border-bottom">
								<div class="d-flex align-content-center">
									<div class="username mr-3">
										<b-avatar variant="warning" text="U"></b-avatar>
										<strong class="primary-font">{{question.customer_name}}</strong>
										<br />
										<small class="pull-right text-muted" style="position: relative;top: -9px;left: 39px;">
											<span class="fa fa-clock"></span>
											{{question.created_at}}
										</small>
									</div>
								</div>
								<div class="chat-body clearfix ml-5">
									<p>
										<b>Que:</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<b>{{question.customer_question}}</b>
										<br />
										<slot v-for="(answer,index1) in question.question_answers">
											<b :key="index1 +'ans'">Ans:</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											<span :key="index1 +'ans1'">{{answer.admin_answer}}</span>
											<br :key="index1+'ans2'" />
										</slot>
									</p>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="text-center my-5" v-else>
					<h2 class="text-danger">No Questions available</h2>
				</div>
				<div class="panel-footer">
					<div class="review-form">
						<form @submit.prevent="$parent.productQuestionSubmit">
							<div class="form-group required">
								<div class="col-sm-6">
									<label class="control-label" for="input-name">Your Name</label>
									<input
										required
										type="text"
										name="customer_name"
										v-model="$parent.questionStore.customer_name"
										value
										id="input-name"
										class="form-control"
									/>
								</div>
							</div>
							<div class="form-group required">
								<div class="col-sm-12">
									<label class="control-label" for="input-review">Question</label>
									<textarea
										required
										name="text"
										v-model="$parent.questionStore.customer_question"
										rows="3"
										id="input-review"
										class="form-control"
										spellcheck="false"
									></textarea>
								</div>
							</div>
							<div class="form-group required">
								<div class="col-sm-12">
									<button type="submit" class="btn btn-primary">Send</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "QuestionAns"
	};
</script>

<style lang="scss">
	.chat {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			margin-bottom: 10px;
			padding-bottom: 5px;

			.left .chat-body {
				margin-left: 50px;
			}

			.right .chat-body {
				margin-right: 60px;
			}

			.chat-body p {
				margin: 0;
				color: #000000;
			}
		}

		.text-body {
			margin: 3px 0px;
			padding: 7px;
			border-radius: 8px;
		}
	}
	.panel-body {
		height: 25em;
		overflow: hidden;
		overflow-y: scroll;
		border: 1px solid #44444408;
		background: #f6f6f6;
	}
</style>
