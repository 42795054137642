Vue.mixin({
    computed: {
        years() {
            const year = new Date().getFullYear();
            // const yearCount = (year+1) - (year - 1950);
            const yearCount = year - 1950;
            return Array.from(
                { length: yearCount + 1 },
                (value, index) => 1950 + index
            );
        },
    },
    data() {
        return {};
    },
    created: function () {},
    methods: {
        logout: function (redir_url) {
            axios
                .post("/logout")
                .then((response) => {
                    this.$router.push(redir_url);
                    this.$router.go();
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        getPaginateDatas(url, params) {
            axios
                .get(url, { params: params })
                .then((respons) => {
                    this.table.datas = respons.data.data;
                    this.meta = respons.data.meta;
                    this.links = respons.data.links;
                })
                .catch((error) => {
                    console.log(error.respons);
                });
        },
        getAllData(model_name, object_key, params) {
            let url = "/admin/getAll" + model_name + "Data";
            axios
                .get(url, { params: params })
                .then((response) => {
                    this.extraData[object_key] = response.data;
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
        getList(model_name, object_key, prefix = false, value, field_name) {
            let url = prefix ? "/admin" : "";

            if (!((value == null) & (field_name == null))) {
                url += "/get" + model_name + "List/" + value + "/" + field_name;
            } else {
                url += "/get" + model_name + "List";
            }
            axios
                .get(url)
                .then((res) => {
                    if (object_key == "districts") {
                        this.districts = res.data;
                    } else if (object_key == "upazilas") {
                        this.upazilas = res.data;
                    } else {
                        this.extraData[object_key] = res.data;
                    }
                })
                .catch((errors) => {
                    console.log(errors.response);
                });
        },
        getData(model_name, id, object_key, url_prefix) {
            let url = "get" + model_name + "Data/" + id;
            if (url_prefix) {
                url = "admin/get" + model_name + "Data/" + id;
            }
            let index = model_name;
            if (object_key) {
                index = object_key;
            }
            axios
                .get(url)
                .then((response) => {
                    this.extraData[index] = response.data;
                })
                .catch((errors) => {
                    console.log(errors.response);
                });
        },
        removePlaceholder: function () {},
        showImage(obj, putFile = null) {
            if (obj) {
                const src = URL.createObjectURL(obj);
                if (putFile) {
                    this[putFile] = src;
                } else {
                    this.image_src = src;
                }
            } else {
                if (putFile) {
                    this[putFile] = null;
                } else {
                    this.image_src = null;
                }
            }
        },
        showPdf(fileSrc, putFile = null) {
            if (fileSrc) {
                const src = URL.createObjectURL(fileSrc);
                if (putFile) {
                    this[putFile] = src;
                } else {
                    this.pdf = src;
                }
            } else {
                if (putFile) {
                    this[putFile] = null;
                } else {
                    this.pdf = null;
                }
            }
        },
        ecomCustomerLoginCheck() {
            axios.get("/ecommerce/loggedOrNot").then((res) => {
                if (res.data) {
                    return true;
                } else {
                    return false;
                }
            });
        },
        memberLoggedIn: function (get = null, sec = 3000) {
            setTimeout(() => {
                if (this.$root.memberLoginCheck) {
                    if (get == "get") {
                        this.member["info"] = this.$root.memberData;
                    }
                } else {
                    // window.location.href = this.$root.baseurl + "/authenticate";
                    window.location.href = this.$root.baseurl + "/member-login";
                }
            }, sec);
        },
        loggedIn: function () {
            axios.get("/member/loggedOrNot/members").then((response) => {
                if (!response.data) {
                    // this.$router.push({
                    //     name: "authenticate",
                    //     params: { auth: false }
                    // });
                    window.location.href = this.$root.baseurl + "/member-login";
                } else {
                    this.loggedMember();
                }
            });
        },
        loggedMember: function () {
            axios.get("/member/info").then((response) => {
                this.member["info"] = response.data;
            });
        },
        logoutMember: function () {
            this.scrollTop;
            this.$root.loading = true;
            axios
                .get("/member/logout")
                .then((response) => {
                    if (response.data) {
                        window.location.href =
                            this.$root.baseurl + "/member-login";
                    } else {
                        window.location.href =
                            this.$root.baseurl + "/member/dashboard";
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.$root.loading = false;
                });
        },
        LoggedInRegMember: function () {
            axios
                .get("/member/loggedOrNot/members-registration")
                .then((response) => {
                    if (!response.data) {
                        this.$router.push("/reg-member-login");
                    } else {
                        this.loggedRegMember();
                    }
                });
        },
        loggedRegMember: function () {
            axios
                .get("/reg-member/info")
                .then((response) => {
                    this.member["info"] = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        logoutRegMember: function () {
            this.scrollTop;
            this.$root.loading = true;
            axios
                .get("/reg-member/logout")
                .then((response) => {
                    if (response.data) {
                        window.location.href =
                            this.$root.baseurl + "/reg-member-login";
                    } else {
                        window.location.href =
                            this.$root.baseurl + "/reg-member/dashboard";
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.$root.loading = false;
                });
        },

        logoutCustomer: function () {
            this.scrollTop;
            this.$root.loading = true;
            axios.get("/ecommerce/logout").then((res) => {
                if (res.data) {
                    window.location.href =
                        this.$root.baseurl + "/digitalshop/login";
                } else {
                    window.location.href =
                        this.$root.baseurl + "/digitalshop/dashboard";
                }
            });
        },
        getPostBody(description, character) {
            let details = this.stripTags(description);
            return details.length > character
                ? details.substring(0, character) + "..."
                : details;
        },
        stripTags(text = null) {
            if (text) {
                return text.replace(/(<([^>]+)>)/gi, "");
            } else {
                return "";
            }
        },
        scrollTop() {
            window.scrollTo(0, 0);
        },
        scrollTopPagination() {
            window.scrollTo(0, 130);
        },
        clickToCheckLogin(path, param = null) {
            if (path == "member-login") {
                localStorage.removeItem("memberLogin");
                window.location.href = this.$root.baseurl + "/member-login";
                return false;
            }
            axios.get("/member/loggedOrNot/members").then((response) => {
                if (!response.data) {
                    var service = { name: path, params: { id: param } };
                    localStorage.setItem(
                        "memberLogin",
                        JSON.stringify(service)
                    );
                    window.location.href = this.$root.baseurl + "/member-login";
                } else {
                    localStorage.removeItem("memberLogin");
                    this.$router.push({ name: path, params: { id: param } });
                }
            });
        },
        clickToApiLoginCheckLogin(redir_url) {
            axios.get("/member/loggedOrNot/members").then((response) => {
                if (!response.data) {
                    var api_info = { name: redir_url, token: "" };
                    localStorage.setItem(
                        "memberApiLogin",
                        JSON.stringify(api_info)
                    );
                    window.location.href = this.$root.baseurl + "/member-login";
                } else {
                    //    for jwt token
                    axios.get("/member/check-member-jwt-token").then((res) => {
                        if (res) {
                            return window.location.replace(
                                redir_url + "/login?token=" + res.data
                            );
                        }
                    });
                }
            });
        },
        picnicRegistrationLink(redir_url) {
            axios.get("/member/loggedOrNot/members").then((response) => {
                if (!response.data) {
                    var api_info = { name: redir_url, token: "" };
                    localStorage.setItem(
                        "memberApiLogin",
                        JSON.stringify(api_info)
                    );
                    window.location.href = this.$root.baseurl + "/member-login";
                } else {
                    //    for jwt token
                    axios.get("/member/member-picnic-jwt-token").then((res) => {
                        console.log(res);
                        if (res) {
                            return window.location.replace(
                                redir_url + "/login?token=" + res.data
                            );
                        }
                    });
                }
            });
        },
        is_selected: function (obj) {
            let selected = false;
            obj.data.forEach((element) => {
                if (element[obj.field_name] == obj.value) {
                    selected = true;
                }
            });
            return selected;
        },

        storeLocalStg(breadcrumbTitle, breadcumb) {
            localStorage.setItem("breadcrumbTitle", breadcrumbTitle);
            localStorage.setItem("breadcumb", JSON.stringify(breadcumb));
        },
        getLocalStgArr(path) {
            if (localStorage.getItem(path)) {
                try {
                    return JSON.parse(localStorage.getItem(path));
                } catch (e) {
                    return [];
                }
            }
        },
        getLocalStgString(path) {
            if (localStorage.getItem(path)) {
                try {
                    return localStorage.getItem(path);
                } catch (e) {
                    return "";
                }
            }
        },
        // ecommerce mixin add to cart
        addToCart(productDetails) {
            if (
                productDetails.color.length > 0 &&
                productDetails.size.length > 0
            ) {
                if (this.data.size != "" && this.data.color_family != "") {
                    productDetails.size_select = this.data.size;
                    productDetails.color_select = this.data.color_family;
                    this.addTocartDataAssign(productDetails);
                } else {
                    this.colorMsg = "Color Field is required";
                    this.sizeMsg = "Size Field is required";
                    this.$toastr(
                        "error",
                        "Please Select color & size",
                        "Info!!"
                    );
                }
            } else if (productDetails.color.length > 0) {
                if (this.data.color_family != "") {
                    productDetails.color_select = this.data.color_family;
                    this.addTocartDataAssign(productDetails);
                } else {
                    this.colorMsg = "Color Field is required";

                    this.$toastr("error", "Please Select color", "Info!!");
                }
            } else if (productDetails.size.length > 0) {
                if (this.data.size != "") {
                    productDetails.size_select = this.data.size;

                    this.addTocartDataAssign(productDetails);
                } else {
                    this.sizeMsg = "Size Field is required";
                    this.$toastr("error", "Please Select size", "Info!!");
                }
            } else {
                this.addTocartDataAssign(productDetails);
            }
        },
        buyNow(productDetails) {
            if (
                productDetails.color.length > 0 &&
                productDetails.size.length > 0
            ) {
                if (this.data.size != "" && this.data.color_family != "") {
                    productDetails.size_select = this.data.size;
                    productDetails.color_select = this.data.color_family;
                    this.addTocartDataAssign(productDetails, "buy_now");
                } else {
                    this.colorMsg = "Color Field is required";
                    this.sizeMsg = "Size Field is required";
                    this.$toastr(
                        "error",
                        "Please Select color & size",
                        "Info!!"
                    );
                }
            } else if (productDetails.color.length > 0) {
                if (this.data.color_family != "") {
                    productDetails.color_select = this.data.color_family;
                    this.addTocartDataAssign(productDetails, "buy_now");
                } else {
                    this.colorMsg = "Color Field is required";

                    this.$toastr("error", "Please Select color", "Info!!");
                }
            } else if (productDetails.size.length > 0) {
                if (this.data.size != "") {
                    productDetails.size_select = this.data.size;

                    this.addTocartDataAssign(productDetails, "buy_now");
                } else {
                    this.sizeMsg = "Size Field is required";
                    this.$toastr("error", "Please Select size", "Info!!");
                }
            } else {
                this.addTocartDataAssign(productDetails, "buy_now");
            }
        },
        wishList(product_id) {
            let data = { product_id: product_id };
            if (this.$root.ecomLogged) {
                axios
                    .post("ecommerce/store-product-wishlist", data)
                    .then((res) => {
                        console.log(res);
                        if (res.status == 200) {
                            this.$toastr(
                                "success",
                                res.data.message,
                                "Success!!"
                            );
                            this.$store.dispatch("countWishList");
                        } else if (res.status == 201) {
                            this.$toastr("error", res.data.message, "Info!!");
                        } else if (res.status == 401) {
                            this.$toastr(
                                "error",
                                res.data.message,
                                "Unauthenticate!!"
                            );
                        }
                    });
            } else {
                this.$toastr(
                    "error",
                    "You are not login,Please login & try again",
                    "Unauthenticate!!"
                );
            }
        },
        cartUpdate(product) {
            if (this.data.size != "") {
                product.size_select = this.data.size;
            }
            if (this.data.color_family != "") {
                product.color_select = this.data.color_family;
            }
            product.total_cart = this.data.quantity;
            product.update = "edit";
            this.$store.commit("addToCart", product);
            this.$toastr("success", "Cart Update successfully!", "Success!!");
            this.$bvModal.hide("productDetails");
        },
        productShowModal(product) {
            let slug = product.slug;
            if (slug) {
                axios.get(`ecommerce/product-details/${slug}`).then((res) => {
                    this.productDetails = res.data.product;
                    this.color_size_set(slug);
                    this.$bvModal.show("productDetails");
                });
            }
        },
        color_size_set(slug) {
            let product = this.$store.state.cart.find((e) => e.slug == slug);
            if (product) {
                this.data.size = product.size_select;
                this.data.color_family = product.color_select;
                this.data.quantity = product.qty;
                this.editCart = true;
            }
        },
        increment() {
            if (this.data.quantity > 0) {
                this.data.quantity++;
            }
        },
        decrement() {
            if (this.data.quantity > 1) {
                this.data.quantity--;
            }
        },
        addTocartDataAssign(productDetails, buy_now = "") {
            productDetails.discount_price = 0.0;

            if (
                productDetails.discount &&
                productDetails.discount.discount_type == 1
            ) {
                productDetails.discount_price =
                    productDetails.discount.discount;
            }
            if (
                productDetails.discount &&
                productDetails.discount.discount_type == 2
            ) {
                productDetails.discount_price =
                    (productDetails.discount.discount / 100) *
                    productDetails.price;
            }

            if (productDetails.discount) {
                productDetails.discount_start_date =
                    productDetails.discount.start_date;
                productDetails.discount_end_date =
                    productDetails.discount.end_date;
            }

            if (buy_now) {
                productDetails.buy_now = true;
            } else {
                productDetails.buy_now = false;
            }
            productDetails.total_cart = this.data.quantity;
            this.$store.commit("addToCart", productDetails);
            this.$toastr("success", "Add to cart successfully!", "Success!!");
            this.colorMsg = "";
            this.sizeMsg = "";
            this.$bvModal.hide("productDetails");
            if (buy_now) {
                this.$router.push({ name: "ecommerce.checkout" });
            }
        },
    },
});
