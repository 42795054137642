<template>
	<div class="member-services" v-if="!$root.spinner">
		<div class="card shadow-sm mb-3">
			<div class="card-header px-0">
				<div class="d-flex align-items-center justify-content-between">
					<h5 class="px-3 mb-0">{{ breadcrumbTitle }}</h5>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-3">
						<input type="text" class="form-control" v-model="search_data.title" placeholder="Title" />
					</div>
					<div class="col-1">
						<button type="button" class="btn btn-success" @click="search()">Search</button>
					</div>
				</div>
				<table class="table table-striped mt-3">
					<thead class="bg-success text-light">
						<tr>
							<th scope="col">#</th>
							<th scope="col">Date</th>
							<th scope="col">Title</th>
						</tr>
					</thead>
					<tbody>
						<slot v-if="datas && Object.keys(datas).length > 0">
							<tr v-for="(notice, index) in datas" :key="index">
								<th>{{ Number(index) + 1 }}</th>
								<td>{{ notice.date | formatDate("MMMM, DD YYYY") }}</td>
								<td>
									<a
										v-if="notice.type == 'File'"
										target="_blank"
										:href="$root.baseurl+'/public'+notice.file"
									>{{ notice.title }}</a>
									<router-link
										v-else
										:to="{ name: 'viewResource', params: { nID: notice.id } }"
										class="nav-link"
									>{{ notice.title }}</router-link>
								</td>
							</tr>
						</slot>
						<slot v-else>
							<tr v-if="!$root.spinner">
								<td colspan="6" class="text-center">No notices yet</td>
							</tr>
						</slot>
					</tbody>
				</table>

				<div class="row">
					<div class="col-6"></div>
					<div class="col-6 d-flex justify-content-end">
						<nav>
							<ul class="pagination">
								<li class="page-item">
									<button class="page-link" :disabled="search_data.limit == 0" @click="preData">
										<i class="fas fa-backward"></i>
									</button>
								</li>

								<li class="page-item active">
									<span class="page-link">
										{{ this.pageNumber }}
										<span class="sr-only">(current)</span>
									</span>
								</li>

								<li class="page-item">
									<button class="page-link" @click="nextData">
										<i class="fas fa-forward"></i>
									</button>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	const breadcumb = [
		{
			title: "All Notices"
		}
	];
	export default {
		name: "AllNotice",
		data() {
			return {
				breadcumb: breadcumb,
				breadcrumbTitle: "All Notices Resource",
				pageNumber: 1,
				member: {
					info: {}
				},
				datas: {},
				search_data: { limit: 0, title: null }
			};
		},
		methods: {
			getNotices: function() {
				this.$root.spinner = true;
				axios
					.get("/member/get-all-notice-resource", { params: this.search_data })
					.then(response => {
						this.datas = response.data;
						this.scrollTop();
					})
					.catch(error => {})
					.then(always => {
						this.$root.spinner = false;
					});
			},
			search() {
				this.getNotices();
			},
			nextData() {
				this.search_data.limit += 10;
				this.pageNumber += 1;
				this.search();
			},
			preData() {
				this.search_data.limit -= 10;
				if (this.pageNumber > 1) {
					this.pageNumber -= 1;
				}

				this.search();
			}
		},
		mounted() {
			this.loggedIn();
			this.getNotices();
		},
		beforeCreate: function() {
			this.$root.loading = false;
			this.$parent.loading = false;
		}
	};
</script>

