var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"example-content"}},[(!_vm.$root.spinner)?_c('div',{staticClass:"service-tab"},[_c('div',{staticClass:"content-header mb-5"},[_c('ul',{staticClass:"nav nav-tabs justify-content-center",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:_vm.$route.params.type == 'events' || _vm.activeEvets
								? 'active'
								: '',attrs:{"id":"events-tab","data-toggle":"tab","href":"#events","role":"tab","aria-controls":"events","aria-selected":"true"}},[_vm._v("Events")])]),_vm._v(" "),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:_vm.$route.params.type == 'awards' ? 'active' : '',attrs:{"id":"awards-tab","data-toggle":"tab","href":"#awards","role":"tab","aria-controls":"awards","aria-selected":"false"}},[_vm._v("Awards")])])])]),_vm._v(" "),_c('div',{staticClass:"service-item tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"events tab-pane fade",class:_vm.$route.params.type == 'events' || _vm.activeEvets
						? 'show active'
						: '',attrs:{"id":"events","role":"tabpanel","aria-labelledby":"events-tab"}},[(_vm.events)?_c('div',{staticClass:"row"},_vm._l((_vm.events),function(item,index){return _c('div',{key:index,staticClass:"col-lg-6 mb-3"},[_c('div',{staticClass:"card shadow-sm mb-3"},[_c('span',{staticClass:"shape2"}),_vm._v(" "),_c('div',{staticClass:"row no-gutters h-100"},[_c('div',{staticClass:"col-md-4"},[(item.image)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
											_vm.$root.baseurl +
											'/public' +
											item.image
										),expression:"\n\t\t\t\t\t\t\t\t\t\t\t$root.baseurl +\n\t\t\t\t\t\t\t\t\t\t\t'/public' +\n\t\t\t\t\t\t\t\t\t\t\titem.image\n\t\t\t\t\t\t\t\t\t\t"}],staticClass:"card-img",attrs:{"alt":item.title}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card-body h-100"},[_c('h5',{staticClass:"card-title"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.getPostBody(item.title, 70))+"\n\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"eventDateTime d-flex align-items-center justify-content-between mb-2"},[_c('span',{staticClass:"date text-danger"},[_c('b',[_c('i',{staticClass:"mdi mdi-calendar-month pr-1"}),_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("formatDate")(item.start_date,
																"Do MMM"
															))+"\n\t\t\t\t\t\t\t\t\t\t\t\t\tto\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("formatDate")(item.endDate,
																"Do MMM YYYY"
															))+"\n\t\t\t\t\t\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('span',{staticClass:"location"},[_c('b',[_c('i',{staticClass:"mdi mdi-map-marker-outline pr-1"}),_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(item.location)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")])])]),_vm._v(" "),_c('p',{staticClass:"card-text"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.getPostBody(
													item.description,
													130
												))+"\n\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-sm btn-outline-primary",attrs:{"to":{
												name: 'event.detail',
												params: { slug: item.slug },
											}}},[_vm._v("Details")])],1)])])])])}),0):_c('div',{staticClass:"row"},[_c('h5',{staticClass:"text-danger"},[_vm._v("No events yet")])])]),_vm._v(" "),(_vm.awards)?_c('div',{staticClass:"awards tab-pane fade",class:_vm.$route.params.type == 'awards' ? 'show active' : '',attrs:{"id":"awards","role":"tabpanel","aria-labelledby":"awards-tab"}},[(_vm.awards)?_c('div',{staticClass:"row"},_vm._l((_vm.awards),function(item,index){return _c('div',{key:index,staticClass:"col-lg-6 mb-3"},[_c('div',{staticClass:"card shadow-sm mb-3"},[_c('span',{staticClass:"shape2"}),_vm._v(" "),_c('div',{staticClass:"row no-gutters h-100"},[_c('div',{staticClass:"col-md-4"},[(item.image)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
											_vm.$root.baseurl +
											'/public' +
											item.image
										),expression:"\n\t\t\t\t\t\t\t\t\t\t\t$root.baseurl +\n\t\t\t\t\t\t\t\t\t\t\t'/public' +\n\t\t\t\t\t\t\t\t\t\t\titem.image\n\t\t\t\t\t\t\t\t\t\t"}],staticClass:"card-img",attrs:{"alt":item.title}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card-body h-100"},[_c('h5',{staticClass:"card-title"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.getPostBody(item.title, 70))+"\n\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"eventDateTime d-flex align-items-center justify-content-between mb-2"},[_c('span',{staticClass:"date text-danger"},[_c('b',[_c('i',{staticClass:"mdi mdi-calendar-month pr-1"}),_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("formatDate")(item.start_date,
																"Do MMM"
															))+"\n\t\t\t\t\t\t\t\t\t\t\t\t\tto\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("formatDate")(item.endDate,
																"Do MMM YYYY"
															))+"\n\t\t\t\t\t\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('span',{staticClass:"location"},[_c('b',[_c('i',{staticClass:"mdi mdi-map-marker-outline pr-1"}),_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(item.location)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")])])]),_vm._v(" "),_c('p',{staticClass:"card-text"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.getPostBody(
													item.description,
													150
												))+"\n\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-sm btn-outline-primary",attrs:{"to":{
												name: 'event.detail',
												params: { slug: item.slug },
											}}},[_vm._v("Details")])],1)])])])])}),0):_c('div',{staticClass:"row"},[(!_vm.$root.spinner)?_c('center',[_c('h5',{staticClass:"text-danger"},[_vm._v("No events yet")])]):_vm._e()],1)]):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.$root.spinner)?_c('spinner'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }