<template>
  <div v-if="subCategory && Object.keys(subCategory).length > 0" class="row">
    <div v-for="(subcat, key) in subCategory" :key="key" class="col-lg-3 my-4">
      <div class="sub-category-product">
        <router-link
          :to="{
            name: 'ecommerce.products',
            params: { slug: subcat.slug },

            query: {
              slug: category_slug,
              type: 'sub-category',
            },
          }"
        >
          <div class="image">
            <img class="product-image" v-lazy="subcat.image" alt />
          </div>
          <h5 class="product-title">{{ subcat.name }}</h5>
        </router-link>
        <router-link
          :to="{
            name: 'ecommerce.products',
            params: { slug: subcat.slug },
            query: {
              slug: category_slug,
              type: 'sub-category',
            },
          }"
          class="add-to-cart-button"
        >
          View All
          <i class="fa fa-long-arrow-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["subCategory", "category_slug"],
  name: "SubCategory",
};
</script>

<style scoped>
</style>
