<template>
  <div id="example-content">
    <slot v-if="!$root.spinner">
      <div class="col-xs-12 col-lg-12" data-aos="fade-right">
        <h5 class="page-title font-weight-bold text-uppercase">
          {{ service.title }}
        </h5>

        <div
          v-if="service.banner"
          class="pageBanner rounded-10 shadow-sm bg-light mb-3"
        >
          <img v-lazy="$root.baseurl + '/public' + service.banner" alt="..." />
        </div>
      </div>
      <slot>
        <div class="col-xs-12 col-lg-12" data-aos="fade-right">
          <p class="text-justify" v-html="service.description"></p>
          <slot v-if="service.is_form == 1 && $route.params.type == 'member'">
            <button
              class="btn btn-success"
              @click="clickToCheckLogin(service.form_link, service.id)"
            >
              Get It Now!
            </button>
          </slot>
          <slot
            v-if="
              service.transaction_type == 'Paid' &&
              $route.params.type == 'non_member' &&
              $route.params.id != 14 &&
              $route.params.id != 41 &&
              $route.params.id != 65
            "
          >
            <router-link
              :to="{
                name: 'NonMemberPayment',
                params: { id: $route.params.id },
              }"
              class="btn btn-success"
              >Get It Now!</router-link
            >
          </slot>
          <slot
            v-if="service.default_form == 1 && $route.params.type == 'member'"
          >
            <button
              class="btn btn-success mt-5"
              @click="clickToCheckLogin('service-form', service.id)"
            >
              Get It Now!
            </button>
          </slot>

          <!-- for login api -->
          <slot
            v-if="
              service.login_api == 1 &&
              service.api_login_url != null &&
              $route.params.type == 'member'
            "
          >
            <button
              class="btn btn-primary"
              @click="clickToApiLoginCheckLogin(service.api_login_url)"
            >
              Get It Now!
            </button>
          </slot>
        </div>
      </slot>
    </slot>
    <spinner v-if="$root.spinner" />
  </div>
</template>

<script>
export default {
  name: "ViewService",
  data() {
    return {
      service: {},
      breadcrumbTitle: "",
      breadcumb: [],
    };
  },
  methods: {
    get_data() {
      this.$root.spinner = true;
      var id = this.$route.params.id;
      var type = this.$route.params.type;
      axios
        .get("/get-service-details/" + id + "/" + type)
        .then((respons) => {
          this.service = respons.data.result;
          this.breadcrumbTitle = respons.data.result.title;
          this.breadcumb = [
            {
              title: this.$route.params.type + " Service",
              route_name: "service",
              params: this.$route.params.type,
            },
            { title: this.breadcrumbTitle },
          ];
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
          this.$root.spinner = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.get_data();
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
};
</script>
