<template>
	<div class="member-services" v-if="!$parent.loading">
		<div class="row mb-3">
			<div class="col-lg-12">
				<div class="card member-services-header shadow-sm">
					<span class="shape"></span>
					<div class="card-body px-3 py-1">
						<div class="row align-items-center">
							<div class="col-lg-6">
								<p class="font-weight-bold mb-0 text-blink" v-if="extraData.warning">
									<span class="badge badge-warning">Warning!</span>
									{{ extraData.warning }}
								</p>
							</div>
							<div class="col-lg-6">
								<ul class="nav align-items-center justify-content-end">
									<li class="nav-item" v-if="certificate_request_button">
										<a href="javascript:void(0)" @click="requestForCertificate()" class="nav-link">
											<span class="badge badge-light">Certificate Req.</span>
										</a>
									</li>
									<!-- <li v-else class="nav-item opacity">
                    <a class="nav-link">
                      <span class="badge badge-light">Certificate Req.</span>
                    </a>
									</li>-->
									<li v-if="memberInfo" class="nav-item">
										<router-link :to="{ name: 'certificateList' }" class="nav-link nav-link pl-0 pr-0">
											<span class="notify" title="Certificate List">
												Certificate
												<i class="mdi mdi-certificate"></i>
											</span>
										</router-link>
									</li>
									<li class="nav-item dropdown">
										<a href="#" class="nav-link">
											<span class="notify">
												<i class="mdi mdi-bell-outline"></i>
												<span>{{ unread }}</span>
											</span>
										</a>
										<div class="dropdown-menu">
											<ul class="nav flex-column service-menu" v-if="unread != 0">
												<li class="nav-item" v-for="(
																	notice, index
																) in extraData.unread_notifications" :key="index">
													<router-link :to="{
														name: 'viewNotification',
														params: { nID: notice.id },
													}">{{ notice.title }}</router-link>
												</li>
											</ul>
											<h3 v-else class="text-danger text-center py-3">
												No notification yet
											</h3>
										</div>
									</li>
									<li class="nav-item">
										<router-link :to="{ name: 'onlineHelpdesk' }" class="nav-link">
											<span class="notify">
												<i class="mdi mdi-email-outline" title="Helpdesk"></i>
												<span class="bg-danger">{{ countHelpDesk }}</span>
											</span>
										</router-link>
									</li>
									<li class="nav-item">
										<a href="#" class="nav-link">
											<span v-if="memberInfo && memberInfo.status == 1"
												class="badge badge-light">Active</span>
											<span style="background: #ed1c24 !important"
												v-if="memberInfo && memberInfo.status == 0"
												class="badge badge-light">Suspended</span>
										</a>
									</li>
									<li class="nav-item">
										<a v-on:click="logout()" style="cursor: pointer" class="nav-link">
											<i class="mdi mdi-logout-variant"></i> Logout
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-lg-6">
				<div class="card company-info shadow-sm mb-3">
					<div class="card-header px-0">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="text-uppercase font-weight-bold px-3 mb-0">
								Company information
							</h5>
						</div>
					</div>
					<div class="card-body">
						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="comInfo" role="tabpanel"
								aria-labelledby="comInfo-tab" style="min-height: 282px">
								<table v-if="memberInfo" class="table table-sm table-borderless">
									<tbody>
										<tr>
											<th width="40%">Name</th>
											<td width="2%">:</td>
											<td width="58%">{{ memberInfo.company_name }}</td>
										</tr>
										<tr>
											<th>BASIS Membership No</th>
											<td>:</td>
											<td>{{ memberInfo.membership_id }}</td>
										</tr>
										<tr v-if="memberInfo && memberInfo.prev_membership_no">
											<th>Prev. Membership No</th>
											<td>:</td>
											<td>{{ memberInfo.prev_membership_no }}</td>
										</tr>
										<tr>
											<th>Membership Type</th>
											<td>:</td>
											<td>{{ memberInfo.legal_structure }}</td>
										</tr>
										<tr>
											<th>Year of Establishment</th>
											<td>:</td>
											<td>{{ memberInfo.establishment_year }}</td>
										</tr>
										<tr>
											<th>Contact No</th>
											<td>:</td>
											<td>{{ memberInfo.phone }}</td>
										</tr>
										<tr>
											<th>E-mail</th>
											<td>:</td>
											<td>{{ memberInfo.email }}</td>
										</tr>
										<tr>
											<th>Website</th>
											<td>:</td>
											<td>
												<a :href="memberInfo.website" target="_blank">{{
													memberInfo.website
												}}</a>
											</td>
										</tr>
										<tr>
											<th>Address</th>
											<td>:</td>
											<td>{{ memberInfo.address }}</td>
										</tr>
									</tbody>
								</table>
								<spinner v-else />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="card payment-info shadow-sm mb-3">
					<div class="card-header px-0">
						<h5 class="text-uppercase font-weight-bold px-3 mb-0">
							BASIS America Desk 
							<!-- EOI For BASIS Standing Committee & Forum  -->
							<!-- BASIS EC Election (2024-2026) -->
						</h5>
					</div>
					<div class="card-body">
						<table class="table table-sm table-borderless">
							<tbody>
								<!-- <tr>
									<th width="40%">Picnic Date</th>
									<td width="2%">:</td>
									<td width="58%"><p class="text-danger">21th January</p></td>
								</tr>
								<tr>
									<th>Registration Last date</th>
									<td>:</td>
									<td><p class="text-danger">11th January</p></td>
								</tr>
								<h6 class="text-uppercase font-weight-bold mb-3 mt-3">
									Registration Fee:
								</h6>
								<tr>
									<th><p>BASIS Member Representative</p></th>
									<td>:</td>
									<td><p class="text-danger">BDT 2,500.00</p></td>
								</tr>
								<tr>
									<th>Guest</th>
									<td>:</td>
									<td><p class="text-danger">BDT 2,500.00</p></td>
								</tr>
								<tr>
									<th>Child (from 3 to 12 years)</th>
									<td>:</td>
									<td><p class="text-danger">BDT 1,500.00</p></td>
								</tr> -->
							</tbody>
						</table>
						<div>
							<slot>
								<button
									class="btn btn-sm btn-success text-white"
									@click="picnicRegistrationLink('https://americadesk.basis.org.bd')"
									
									>
									Register Now
								</button>
								<!-- @click="picnicRegistrationLink('https://eoi.basis.org.bd')" -->
							
									<!-- <router-link :to="'voter-info/create'" class="btn btn-sm btn-success text-white">Register Now</router-link> -->
							</slot>
						</div>
					</div>
				</div>
				<div class="card payment-info shadow-sm mb-3">
					<div class="card-header px-0">
						<h5 class="text-uppercase font-weight-bold px-3 mb-0">
							Payment information
						</h5>
					</div>
					<div class="card-body">
						<table class="table table-sm table-borderless">
							<!-- <tr>
                <td width="40%">Service Payment</td>
                <td width="2%">:</td>
                <td width="58%">
                  <span class="badge badge-warning">Due</span>
                </td>
              </tr>
              <tr>
                <td width="40%">Member Payment</td>
                <td width="2%">:</td>
                <td width="58%">
                  <span class="badge badge-primary">Paid</span>
                </td>
							</tr>-->
							<tr class="text-danger">
								<td width="30%">
									<p @click="goToPaymentHistory" class="text-black">Total Dues</p>
								</td>
								<td width="2%">:</td>
								<td width="20%">
									<p @click="goToPaymentHistory" class="text-black">
									<h4 v-if="totalDues">{{ totalDues | currency }}</h4>
									<h4 v-else>0.00</h4>
									</p>
								</td>
								<td width="30%" class="text-right">
									<button v-if="totalDues" @click="goToPaymentHistory" class="btn btn-sm btn-success">
										Pay Now
									</button>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="card notice shadow-sm mb-3">
					<div class="card-header px-0">
						<div class="row">
							<div class="col-8">
								<div class="d-flex align-items-center justify-content-between">
									<h5 class="text-uppercase font-weight-bold px-3 mb-0">
										Your SERVICES STATUS
									</h5>
								</div>
							</div>
							<div class="col-4 text-right pr-5">
								<router-link v-if="this.$root.variable.aLogId" :to="{ name: 'CreateExistingService' }"
									class="btn btn-sm btn-success">Add Service</router-link>
							</div>
						</div>
					</div>
					<div class="service-tab">
						<div class="content-header">
							<ul class="nav nav-tabs nav-fill justify-content-center" id="myTab" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="existing-tab" data-toggle="tab" href="#existing"
										role="tab" aria-controls="existing" aria-selected="true">Existing Service</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" id="requested-tab" data-toggle="tab" href="#requested" role="tab"
										aria-controls="requested" aria-selected="false">Requested Service</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="card-body pt-1 pb-0">
						<div class="service-item tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="existing" role="tabpanel"
								aria-labelledby="existing-tab">
								<div class="service-item">
									<ul class="nav flex-column service-menu" v-if="extraData.existingServices.exist">
										<li class="nav-item" v-for="(serv, index) in extraData.existingServices.exist"
											:key="index">
											<a href="#" class="nav-link" v-if="serv.service">
												{{ serv.service.title }}
											</a>
										</li>
									</ul>
									<h3 class="text-center py-4 text-danger" v-else>
										No Existing Services Yet
									</h3>
								</div>
							</div>
							<div class="tab-pane fade show" id="requested" role="tabpanel" aria-labelledby="requested-tab">
								<div class="service-item">
									<ul class="nav flex-column service-menu" v-if="extraData.existingServices.pending">
										<li class="nav-item" v-for="(serv, index) in extraData.existingServices
											.pending" :key="index">
											<a href="#" class="nav-link" v-if="serv.service">
												{{ serv.service.title }}
												<span v-if="serv.comments">{{
													" - " + serv.comments
												}}</span>
											</a>
										</li>
									</ul>
									<h3 class="text-center py-4 text-danger" v-else>
										No Pending Services Yet
									</h3>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-12 mb-2" v-if="extraData.existingServices">
						<center>
							<router-link :to="{ name: 'myService' }" class="btn btn-info btn-sm">View All</router-link>
						</center>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4">
				<div class="card shadow-sm mb-3 h-100">
					<div class="card-header px-0">
						<h5 class="text-uppercase font-weight-bold px-3 mb-0">
							Get Services
						</h5>
					</div>
					<div class="service-tab">
						<div class="content-header">
							<ul class="nav nav-tabs nav-fill justify-content-center" id="myTab" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="paid-tab" data-toggle="tab" href="#paid" role="tab"
										aria-controls="paid" aria-selected="true">Paid Service</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" id="free-tab" data-toggle="tab" href="#free" role="tab"
										aria-controls="free" aria-selected="false">Free Service</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="card-body custom-scroll">
						<div class="service-item tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="paid" role="tabpanel" aria-labelledby="paid-tab">
								<div class="service-item">
									<ul class="nav flex-column service-menu" v-if="extraData.paidServices">
										<li class="nav-item" v-for="(service, index) in extraData.paidServices"
											:key="index">
											<span v-if="service.login_api == 1">
												<a href="javascript:void(0)" @click="clickToApiLoginCheckLogin(service.api_login_url)" target="_blank" class="nav-link">{{ service.title }}</a>
											</span>
											<span v-else>
												<router-link v-if="service.default_form == 1" :to="{
													name: 'service-form',
													params: { id: service.id },
												}" class="nav-link">{{ service.title }}</router-link>
												<router-link v-else-if="service.external_page == 0" :to="{
													name: service.form_link,
													params: { id: service.id },
												}" class="nav-link">{{ service.title }}</router-link>
												<router-link v-else :to="
													'/' + service.form_link + '/' + service.id + '/member'
												" class="nav-link">{{ service.title }}</router-link>
											</span>
										</li>
									</ul>
								</div>
							</div>

							<div class="tab-pane fade" id="free" role="tabpanel" aria-labelledby="free-tab">
								<div class="service-item">
									<ul class="nav flex-column service-menu" v-if="extraData.freeServices">
										<li class="nav-item" v-for="(service, index) in extraData.freeServices"
											:key="index">
											<router-link v-if="service.external_page == 0" :to="{
												name: service.form_link,
												params: { id: service.id },
											}" class="nav-link">{{ service.title }}</router-link>
											<router-link v-else :to="
												'/' + service.form_link + '/' + service.id + '/member'
											" class="nav-link">{{ service.title }}</router-link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="card shadow-sm mb-3 h-100">
					<div class="card-header px-0">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="text-uppercase font-weight-bold px-3 mb-0">
								Member Events
							</h5>
						</div>
					</div>
					<div class="card-body custom-scroll">
						<ul class="nav flex-column service-menu" v-if="extraData.events">
							<li class="nav-item" v-for="(event, index) in extraData.events" :key="index">
								<router-link class="nav-link" target="_blank" :to="{
									name: 'allEvents',
									params: { market: event.market, type: event.event_awards },
								}">{{ event.title }}</router-link>
							</li>
						</ul>
						<h3 class="text-center py-5 text-danger" v-else>No Events Yet</h3>
					</div>
				</div>
			</div>
			<!-- <div class="col-lg-4">
				<div class="card shadow-sm mb-3 h-100">
					<div class="card-header px-0">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="text-uppercase font-weight-bold px-3 mb-0 w-100">
								Member Notices
								<router-link :to="{ name: 'allNotice' }" class="btn btn-info btn-sm float-right">View All</router-link>
							</h5>
						</div>
					</div>
					<div class="card-body custom-scroll">
						<ul class="nav flex-column service-menu" v-if="extraData.notifications">
							<li class="nav-item" v-for="(notice, index) in extraData.notifications" :key="index">
								<router-link
									:class="notice.read == null ? 'text-danger' : ''"
									:to="{ name: 'viewNotification', params: { nID: notice.id } }"
									class="nav-link"
								>{{ notice.title }}</router-link>
							</li>
						</ul>
						<h3 class="text-center py-5 text-danger" v-else>No Notice Yet</h3>
					</div>
				</div>
			</div>-->

			<div class="col-lg-4">
				<div class="card shadow-sm mb-3 h-100">
					<div class="card-header px-0">
						<h5 class="text-uppercase font-weight-bold px-3 mb-0">
							Resourcs and Notices
						</h5>
					</div>
					<div class="service-tab">
						<div class="content-header">
							<ul class="nav nav-tabs nav-fill justify-content-center" id="NoticeMyTab" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="resource-tab" data-toggle="tab" href="#resource"
										role="tab" aria-controls="resource" aria-selected="false">Resource</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" id="member-notification-tab" data-toggle="tab"
										href="#member-notification" role="tab" aria-controls="member-notification"
										aria-selected="true">Member Notices</a>
								</li>

							</ul>
						</div>
					</div>
					<div class="card-body custom-scroll">
						<div class="service-item tab-content" id="NoticeMyTabContent">

							<div class="tab-pane fade show active" id="resource" role="tabpanel"
								aria-labelledby="resource-tab">
								<div class="service-item mb-1">
									<ul class="nav flex-column service-menu" v-if="extraData.notificationResources">
										<slot v-for="(notice, index) in extraData.notificationResources">
											<li class="nav-item" :key="index">
												<a v-if="notice.type == 'File'" target="_blank"
													:href="$root.baseurl + '/public' + notice.file" class="nav-link">{{
														notice.title }}</a>
												<router-link v-else :to="{
													name: 'viewResource',
													params: { nID: notice.id },
												}" class="nav-link">{{ notice.title }}</router-link>
											</li>
										</slot>
									</ul>
									<router-link v-if="
										extraData.notificationResources &&
										Object.keys(extraData.notificationResources).length >= 6
									" :to="{ name: 'allNoticeResource' }" class="btn btn-info btn-sm mb-2 float-right">View All</router-link>
								</div>
							</div>

							<div class="tab-pane fade" id="member-notification" role="tabpanel"
								aria-labelledby="member-notification-tab">
								<div class="service-item">
									<ul class="nav flex-column service-menu" v-if="extraData.notifications">
										<slot v-for="(notice, index) in extraData.notifications">
											<li v-if="index <= 6" class="nav-item" :key="index">
												<router-link :class="notice.read == null ? 'text-danger' : ''" :to="{
													name: 'viewNotification',
													params: { nID: notice.id },
												}" class="nav-link">{{ notice.title }}</router-link>
											</li>
										</slot>
									</ul>
									<router-link v-if="
										extraData.notifications &&
										Object.keys(extraData.notifications).length > 6
									" :to="{ name: 'allNotice' }" class="btn btn-info btn-sm my-1 float-right">View All</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- when alter native member login -->
		<b-modal centered id="alternative-member-login-message" hide-footer hide-header>
			<div class="position-relative">
				<p style="margin-bottom:5px;font-size:20px">You are logged in with an alternate representative's email. If
					you are unable to update the profile or make payment, please contact your representative to grant you
					access. Or login with a representative email to get full access.</p>
				<button @click="removeAlternativeMemberMessage" class="position-absolute" style="
									bottom: -19px;
									right: -14px;
									background: #ed1c24;
									color: white;
									padding: 1px 9px;
									border: none;
									">
					Close
				</button>
			</div>
		</b-modal>
		<!--end-->

	</div>
</template>
<script>
import axios from "axios";
export default {
  name: "Dashboard",
  props: ["memberInfo"],
  data() {
    return {
      certificate: 0,
      certificate_request_button: 0,
      data: {},
      member: {
        info: null,
      },
      extraData: {
        notifications: {},
        unread_notifications: {},
        events: {},
        existingServices: {},
        paidServices: {},
        freeServices: {},
        warning: "",
        notificationResources: {},
      },
      totalDues: 0,
      countHelpDesk: 0,
      unread: 0,
      errors: {},
    };
  },
  methods: {
    getNotifications: function () {
      axios
        .get("/member/notifications")
        .then((response) => {
          this.extraData.notifications = response.data.notifications;
          this.extraData.unread_notifications =
            response.data.unread_notifications;
          this.unread = response.data.unread;
        })
        .catch((error) => console.log(error.response))
        .then((alw) => {
          setTimeout(
            () => (this.$parent.loading = false),
            this.$root.minloadtimefd
          );
        });
    },
    getNotificationResources: function () {
      axios
        .get("/member/notification-resource")
        .then((response) => {
          this.extraData.notificationResources = response.data;
        })
        .catch((error) => console.log(error.response))
        .then((alw) => {
          setTimeout(
            () => (this.$parent.loading = false),
            this.$root.minloadtimefd
          );
        });
    },
    getEvents: function () {
      axios
        .get("/member/events")
        .then((res) => (this.extraData.events = res.data.data));
    },
    getWarning: function () {
      axios
        .get("/member/get-warning")
        .then((res) => (this.extraData.warning = res.data));
    },
    getMemberService: function () {
      axios.get("/member/get-services/member").then((res) => {
        this.extraData.paidServices = res.data.paid;
        this.extraData.freeServices = res.data.free;
      });
    },
    getExistMemberService: function () {
      axios
        .get("/member/existingService")
        .then((res) => (this.extraData.existingServices = res.data));
    },
    getHelpDesk: function () {
      axios
        .get("/member/getCountHelpDesk")
        .then((res) => (this.countHelpDesk = res.data));
    },
    getDues: function () {
      axios.get("/member/getDues").then((res) => (this.totalDues = res.data));
    },
    getCertificateAccess: function () {
      axios
        .get("/member/certificate-access")
        .then((res) => (this.certificate = res.data));
    },
    requestForCertificate: function () {
      if (this.certificate == 0) {
        this.$toastr(
          "warning",
          "To apply for Membership Certificate, your profile must be updated up to 90% and also the annual subscription must be cleared.",
          "Warning!!"
        );
        return false;
      }

      this.certificate = 0;
      this.certificate_request_button = 0;
      axios.get("/member/certificate-request").then((res) => {
        this.$toastr(
          "success",
          "Certificate Request Send Successfully",
          "Success!!"
        );
      });
    },
    showCertificateRequiestButton() {
      axios
        .get("/member/certificate-request-access")
        .then((res) => (this.certificate_request_button = res.data));
    },
    logout: function () {
      this.logoutMember();
    },
    goToPaymentHistory() {
      if (this.$root.variable.alt_login) {
        this.$toastr(
          "warning",
          "You are logged in with an alternate representative's email. If you are unable to update the profile or make payment, please contact your representative to grant you access. Or login with a representative email to get full access.",
          "Warning!!"
        );

        return false;
      }
      this.$router.push({ name: "PaymentHistory" });
    },
    showAlternativeMemberMessage() {
      this.$bvModal.show("alternative-member-login-message");
    },
    removeAlternativeMemberMessage() {
      this.$bvModal.hide("alternative-member-login-message");
    },
  },
  created() {
    if (this.$route.params.msg) {
      this.$toastr(
        "success",
        "Your information has been submitted",
        "Success!!"
      );
    }
  },
  mounted() {
    this.getCertificateAccess();
    this.showCertificateRequiestButton();
    this.getDues();
    this.getNotifications();
    this.getNotificationResources();
    this.getEvents();
    this.getMemberService();
    this.getWarning();
    this.getExistMemberService();
    this.getHelpDesk();
    setTimeout(() => {
      if (this.$root.variable.alt_login) {
        this.showAlternativeMemberMessage();
      }
    }, 4000);
  },
  beforeCreate: function () {
    this.$root.loading = false;
    this.$parent.loading = true;
    if (localStorage.getItem("memberLogin")) {
      try {
        var service = JSON.parse(localStorage.getItem("memberLogin"));
        localStorage.removeItem("memberLogin");
        this.$router.push(service);
      } catch (e) {
        ("");
      }
    }

    //for service login api
    if (localStorage.getItem("memberApiLogin")) {
      try {
        //for jwt token
        var api_info = JSON.parse(localStorage.getItem("memberApiLogin"));
        var token = api_info.token;
        if (!token) {
          axios.get("/member/check-member-jwt-token").then((res) => {
            if (res) {
              localStorage.removeItem("memberApiLogin");
              window.location.replace(
                api_info.name + "/login?token=" + res.data
              );
            }
          });
        } else {
          localStorage.removeItem("memberApiLogin");
          window.location.replace(api_info.name + "/login?token=" + token);
        }
      } catch (e) {
        ("");
      }
    }
  },
  updated() {
    if (this.$parent.member.info) {
      this.$parent.loading = false;
    }
  },
};
</script>

<style >
.opacity {
  opacity: 0.5;
}
</style>
