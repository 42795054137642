<template>
  <div id="example-content">
    <div class="row justify-content-center">
      <div class="col-lg-7 mb-3">
        <div class="member-search">
          <div class="search-body">
            <h1>Search News</h1>
            <div class="searchBox">
              <input
                type="text"
                placeholder="Search by news title"
                v-on:keyup.enter="get_datas()"
                v-model="search_data.title"
                id="search"
              />
              <span class="mdi mdi-magnify" @click="get_datas()"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row news" v-if="!$root.spinner">
      <div class="col-lg-12" v-for="(item, index) in table.datas" :key="index">
        <div class="card shadow-sm mb-3">
          <div class="row no-gutters">
            <div class="col-md-4 text-center">
              <slot v-if="item.image">
                <img
                  v-lazy="$root.baseurl+'/public'+item.image"
                  class="img-fluid"
                  :alt="item.title"
                />
              </slot>
              <slot v-else>
                <img
                  v-lazy="$root.baseurl+'/public/img/no-image.png'"
                  class="img-fluid"
                  :alt="item.title"
                />
              </slot>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h3>{{item.title}}</h3>
                <p>{{ getPostBody(item.description,200) }}</p>
                <router-link
                  v-if="item.type==='content'"
                  :to="{ name:'news_detail', params: { slug: item.slug }}"
                  class="btn btn-outline-primary"
                >Details</router-link>
                <a
                  v-else
                  :href="item.FullUrl+ item.file"
                  target="_blank"
                  class="btn btn-outline-primary"
                >
                  <span class="fab fa fa-download"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <spinner v-if="$root.spinner" />
    <!-- pagination -->
    <Pagination
      :url="'/get-news/'+ this.$route.params.type"
      :search_data="search_data"
      v-if="!$root.spinner"
    />
    <!-- /pagination -->
  </div>
</template>
<script>
const routesname = {
  view: "slider_content.show"
};
export default {
  name: "About",
  data() {
    return {
      breadcrumbTitle: "News",
      breadcumb: [{ title: "News" }],
      search_data: { title: "" },
      table: {
        datas: []
      },
      pagination: [],
      meta: [],
      links: []
    };
  },
  methods: {
    asyncData() {
      this.get_datas();
      this.$root.loading = false;
    },
    get_datas: function() {
      let url = "/get-news/" + this.$route.params.type;
      this.$root.spinner = true;
      axios
        .get(url, { params: this.search_data })
        .then(respons => {
          if (this.$route.params.type == "IndustryNews") {
            this.breadcrumbTitle = "Industry News";
          } else if (this.$route.params.type == "PressRelease") {
            this.breadcrumbTitle = "Press Release";
          } else {
            this.breadcrumbTitle = "Current News";
          }
          this.breadcumb = [{ title: this.breadcrumbTitle }];
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
          this.$root.spinner = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {},
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    }
  }
};
</script>
