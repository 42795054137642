<template>
  <div id="example-content">
    <div class="row gallery video" v-if="!$root.spinner">
      <div class="col-lg-4 text-center" v-for="(item, index) in table.datas" :key="index">
        <router-link
          :to="{ name:'video_details', params: {video_gallery_id: item.id,type:'album' }}"
        >
          <div class="card shadow-sm mb-3">
            <div class="card-gallery">
              <img v-lazy="item.FullUrl+item.image" class="img-fluid" alt="..." />
            </div>
            <div class="card-body">
              <h4>{{ getPostBody(item.title,100) }}</h4>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <spinner v-if="$root.spinner" />
  </div>
</template>
<script>
export default {
  name: "VideoGallery",
  data() {
    return {
      breadcrumbTitle: "Video Gallery",
      breadcumb: [{ title: "Video Gallery" }],
      table: {
        datas: []
      }
    };
  },
  methods: {
    get_datas: function(pageindex) {
      this.$root.spinner = true;
      axios
        .get("/get-video-gallery")
        .then(respons => {
          this.table.datas = respons.data.data;
          this.storeLocalStg(this.breadcrumbTitle, this.breadcumb);
          this.$root.spinner = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_datas();
  },
  beforeCreate: function() {
    this.$root.loading = false;
    this.$parent.loading = false;
  }
};
</script>
